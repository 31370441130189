import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import Reports from '../views/Reports/Reports';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Inbox from '../views/Communication/whatsApp';
import Help from '../views/Help/Help';
import GrowerInformationForm from '../GrowerForm/growerInformationForm';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'dark',
    loginPageImage: 'myLogin',
    loginButtonStyle: 'myLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    paiChart: 'picCard',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },
      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '/grower-information-form',
        componentName: GrowerInformationForm
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    uploadSheetButton: [
      {
        title: `+${t('UPLOAD A FILE (BULK UPLOAD)')}`,
        key: 'bulkUpload'
      }
    ],
    sampleTemplate:
      'https://grower-console-production-assets.s3.ap-southeast-1.amazonaws.com/sample-files/Sample-my.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      {
        key: 'help',
        label: t('Help')
      }
    ],
    amplitude: 'IN',
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },
        { key: 'Crop', value: t('Crop') },
        { key: 'Pic', value: t('PIC') },
        { key: 'mobileNumber', value: t('Mobile Number') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        Pic: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some crops')
          }
        ]
      },

      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        fieldName: 'GrowersRegion',
        initialValue: 'GrowersRegion',
        placeholder: t('Select Some Regions'),
        handler: 'handleRegionSelectAll',
        rules: []
      },
      {
        key: 'allLanguage',
        optionValue: 'childrenLanguage',
        fieldName: 'GrowerLanguage',
        initialValue: 'GrowerLanguage',
        placeholder: t('Select Some Language'),
        handler: 'handleLanguageSelectAll',
        rules: []
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (Hectare)'),
        dataIndex: 'landSize'
      }
    ],
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (Hectare)'),
        dataIndex: 'landsize'
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Hectare)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        },
        {
          label: t('Crop Type'),
          fieldName: 'cropType',
          initialValue: 'cropType',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter crop type'),
            whitespace: true
          }
        },
        {
          label: t('Language'),
          fieldName: 'language',
          initialValue: 'language',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter language'),
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('State'),
          fieldName: 'state',
          initialValue: 'state',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter state')
          }
        },
        {
          label: t('Zone'),
          fieldName: 'Zone',
          initialValue: 'zone',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter zone'),
            whitespace: true
          }
        },
        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter district'),
            whitespace: true
          }
        },
        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        }
      ],
      dividerHeight: '580px',
      growerSourcing: [
        {
          label: t('PIC'),
          fieldName: 'pic',
          initialValue: 'pic',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter PIC'),
            whitespace: true
          }
        },
        {
          label: t('Retailer Name'),
          fieldName: 'franchisee',
          initialValue: 'franchisee',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter retailer name'),
            whitespace: true
          }
        },
        {
          label: t('Company No / No KP'),
          fieldName: 'frnCode',
          initialValue: 'frnCode',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter company name'),
            whitespace: true
          }
        }
      ]
    },
    replaceTitle: ['0-20 hectares', '21-50 hectares', '51-100 hectares', '> 100 hectares'],
    optInOutVisible: true,
    saveButtonPosition: 'myButtonPosition'
  };
};

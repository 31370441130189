import {
  DOWNLOAD_SOURCE_RESULT_PENDING,
  DOWNLOAD_SOURCE_RESULT_SUCESS,
  DOWNLOAD_SOURCE_RESULT_ERROR
} from '../constants';

const initialState = {
  data: {
    downloadSourceResult: {},
    sourceResultError: {},
    users: [],
    completed: false
  }
};

function downloadSourceResult(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_SOURCE_RESULT_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case DOWNLOAD_SOURCE_RESULT_SUCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          downloadSourceResult: action.payload,
          completed: false
        }
      });
    case DOWNLOAD_SOURCE_RESULT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          sourceResultError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default downloadSourceResult;

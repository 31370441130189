import styled from 'styled-components';
import { Row, Card, Modal, Input, Form } from 'antd';
export const StyledInput = styled(Input)`
  &:hover {
    color: #238661 !important;
    border-color: #238661 !important;
  }
  &:focus {
    color: #238661 !important;
    border-color: #238661 !important;
  }
  input[type='text'] {
    font-weight: 500;
  }
   {
    font-weight: 500;
  }
`;

export const StyledCard = styled(Card)`
  .ant-card-head-title {
    font-weight: 700 !important;
  }
`;
export const StyledForm = styled(Form)`
  label {
    color: #5c6575 !important;
    font-size: 12px !important;
  }
`;

export const GrowerDetailFormStyle = styled(Row)`
  .growerDetailInput {
    background: #f3f4f6;
  }

  .growerDetail > .ant-col .ant-form-item-label {
    text-transform: uppercase;
  }
  .buttonPosition {
    position: absolute;
    right: 28px;
    top: 120px;
  }
  .buttonStyle {
    border-radius: 8px;
    color: #fff;
    background-color: #238661;
    width: 100px;
  }
  .colHeight {
    /* height: 450px;
      overflow-y: scroll; */
  }
  .ant-modal-body {
    padding-top: 0px !important;
    padding-bottom: 5px;
  }
  .growerDetails > .ant-card-head {
    padding: 0px;
  }
  .growerDetails > .ant-card-body {
    padding: 0px;
  }
  .growerDetails > .ant-card-head-title {
    padding: 12px 0;
  }
  .cropHistory {
    position: relative;
    top: -10px;
  }
  .cropHistory > .ant-card-head {
    padding: 0px;
  }
  .cropHistory > .ant-card-body {
    padding: 0px;
  }
  .cropHistory > .ant-card-head-title {
    padding: 12px 0;
  }
  .ant-input[disabled] {
    color: black;
  }
`;
export const GrowerModelStyle = styled(Modal)`
  .ant-modal-body {
    padding-top: 0px !important;
    padding-bottom: 15px;
    height: 450px;
    overflow-y: scroll;
  }
  .ant-modal-title {
    font-weight: 700;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

import {
  FETCH_DASHBOARD_PENDING,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_ERROR,
  SESSION_TIMEOUT_ERROR
} from '../constants';

const initialState = {
  data: {
    dashboardResult: {},
    dashboardError: {},
    visible: false,
    completed: false
  }
};

function dashboardList(state = initialState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_DASHBOARD_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          dashboardResult: action.payload,
          completed: false
        }
      });

    case FETCH_DASHBOARD_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          dashboardError: action.payload,
          completed: false
        }
      });
    case SESSION_TIMEOUT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          visible: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default dashboardList;

import React from 'react';
import ReactDOM from 'react-dom';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Steps, Button, Form, Modal, DatePicker } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import FirstStep from './updateFormSteps/firstStep';
import SecondStep from './updateFormSteps/secondStep';
import ThirdStep from './updateFormSteps/thirdStep';
import isEmpty from 'lodash/isEmpty';
import config from '../../../../../../src/config';
import './updateStepForm.css';
import { fetchCostEstimator, fetchCostEstimatorClear } from '../../../../../actions/costEstimator';
import { fetchwhatsAppTemplateDataClear } from '../../../../../actions/whatsAppTemplateData';
import OutsideAlerter from './OutsideAlerter';

const Step = Steps.Step;
const { MonthPicker, RangePicker } = DatePicker;

class UpdateStepsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      messageType: 'SMS',
      newMessageData: {},
      formData: {},
      pickerOpen: false,
      selectedDate: this.props.editData.scheduleDate,
      checkDate: null,
      scheduleMessageData: {},
      costEstimatorData: this.props.costEstimatorData,
      fetchCostEstimator: this.props.fetchCostEstimator,
      visible: false,
      selectedMedia: '',
      channel: '',
      disableButton: false,
      showError: false,
      imageSize: false,
      imageType: false,
      groupId: '',
      files: [],
      isButtonDisabled: false
    };
    this.wrapperRef = React.createRef();
    //  this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  getBase64 = (file) => {
    console.log('files get', file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  getBase64Image = (img) => {
    // Create an empty canvas element
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    // Copy the image contents to the canvas
    var ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // Get the data-URL formatted image
    // Firefox supports PNG and JPEG. You could check img.src to
    // guess the original format, but be aware the using "image/jpg"
    // will re-encode the image.
    var dataURL = canvas.toDataURL('image/png');

    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  };
  toDataURL = async (url) => {
    console.log('Downloading image...');
    var res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    });
    var blob = await res.blob();

    const result = await new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.addEventListener(
        'load',
        function() {
          resolve(reader.result);
        },
        false
      );

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });

    return result;
  };
  onDateChange = (value, dateString) => {
    console.log('dateString>', dateString);
    if (moment(dateString).isValid()) {
      this.setState({
        selectedDate: moment(dateString)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        checkDate: dateString
      });
    } else {
      this.setState({
        selectedDate: '',
        checkDate: dateString
      });
    }
  };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  myRef = React.createRef();
  handleClickOutside = (e) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ pickerOpen: false });
    }
  };
  handleClickInside = () => this.setState({ pickerOpen: true });
  onOk = (value) => {
    this.setState({
      pickerOpen: false
    });
  };
  togglePicker = () => {
    this.setState({ pickerOpen: !this.state.pickerOpen });
  };
  next() {
    if (this.state.current === 0) {
      this.props.form.validateFieldsAndScroll(['mesageTxtMms'], (err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    } else if (this.state.current === 0) {
      this.props.form.validateFieldsAndScroll(
        ['mesageTxt', 'template', 'upload', 'mesageTxtMms'],
        (err) => {
          if (!err) {
            const current = this.state.current + 1;

            this.setState({ current });
          }
        }
      );
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(['template'], (err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    } else if (this.state.current === 0) {
      this.props.form.validateFieldsAndScroll(['mesageTxtMms', 'upload'], (err) => {
        if (!err) {
          const current = this.state.current + 1;
          this.setState({ current, showError: true });
        }
      });
    } else if (this.state.current === 2) {
      this.props.form.validateFields((err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    }
  }
  prev() {
    const current = this.state.current - 1;
    // this.props.fetchCostEstimatorClear();
    // this.props.fetchwhatsAppTemplateDataCleared();
    this.setState({ current });
  }

  handleSubmit = (e, props) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // const emailId = props.Context.AuthContext._user.userName;
        const emailId = props.Context.auth.account.userName;
        if (this.props.editData.Platform) {
          const emailId = props.Context.auth.account.userName;
          // const emailId = props.Context.AuthContext._user.userName;
          const messageId = this.props.editData.key;
          if (this.state.selectedDate) {
            const scheduleMessageData = {
              message: {
                subject: 'Test Syngenta',
                content:
                  this.props.editData.Platform === 'MMS' ? values.mesageTxtMms : values.mesageTxt,
                whatsappTemplateLanguageCode: this.props.templateListContent
                  .whatsappTemplateLanguageCode,
                whatssappTemplateName: values.template,
                whatssappTemplateParams: {
                  image:
                    this.props.templateListContent &&
                    this.props.templateListContent.whatssappTemplateParams
                      ? this.props.templateListContent.whatssappTemplateParams.image
                      : '',
                  text:
                    this.props.templateListContent &&
                    this.props.templateListContent.whatssappTemplateParams
                      ? this.props.templateListContent.whatssappTemplateParams.text
                      : ''
                },
                group: {
                  id: this.state.groupId
                },
                channel: this.props.editData.Platform,
                sender: emailId,
                type: this.props.editData.Platform === 'MMS' ? 'Image' : 'Text',
                files:
                  this.state.files.length > 0
                    ? this.state.files.map((file) => ({
                        fileKey: file.fileKey,
                        fileUrl: file.fileUrl,
                        fileName: file.fileName,
                        fileSize: file.fileSize
                      }))
                    : []
              },

              scheduleAt: this.state.selectedDate
            };
            this.setState({ scheduleMessageData });
            if (this.props.editData.Platform === 'MMS') {
              const checkFileList =
                values.upload && values.upload.fileList ? values.upload.fileList : [];
              console.log('checkFileList>', checkFileList);
              let listFileList = checkFileList;
              if (checkFileList.length > 3) {
                listFileList = checkFileList.splice(-3);
              } else {
                listFileList = checkFileList;
              }

              // const defaultImages = this.props.editData.files;
              // const convertIamge = this.getBase64(
              //   'https://grower-console-devlop-assets.s3.ap-southeast-1.amazonaws.com/upload-mms-files/1642435750038_blast1.jpg'
              // );
              // const imageSrcString = this.toDataURL(
              //   'https://grower-console-devlop-assets.s3.ap-southeast-1.amazonaws.com/upload-mms-files/1642435750038_blast1.jpg'
              // );

              const formData = new FormData();
              // var img = new Image();
              // img.onload = function () {
              //   var data = getBase64Image(
              //     'https://grower-console-devlop-assets.s3.ap-southeast-1.amazonaws.com/upload-mms-files/1642435750038_blast1.jpg'
              //   );
              //   formData.append('files', data);
              //   // xhr.send(formData);
              // };
              // const messageParse = JSON.parse(newMessageData);
              // formData.append('files', checkFileList);
              // for (let i = 0; i < defaultImages.length; i++) {
              //   formData.append('files', defaultImages[i]);
              // }
              for (let i = 0; i < listFileList.length; i++) {
                formData.append('files', listFileList[i].originFileObj);
              }
              formData.append(
                'message',
                new Blob([JSON.stringify(scheduleMessageData)], { type: 'application/json' })
              );
              // console.log('message>', message);
              this.setState({ formData });
              this.props.scheduleMMSUpdate(formData, emailId, messageId, this.props.pageNumber);
              if (this.state.isButtonDisabled) {
                return;
              }
              this.setState({
                isButtonDisabled: true
              });
            } else {
              this.props.scheduleMessageUpdate(
                scheduleMessageData,
                emailId,
                messageId,
                this.props.pageNumber
              );
              this.props.fetchwhatsAppTemplateDataCleared();
              this.props.changeKey();
              if (this.state.disableButton) {
                return;
              }
              this.setState({ disableButton: true });
            }
          } else {
            this.showModal();
          }
        } else {
        }
      }
    });
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e, props) => {
    this.setState({
      visible: false
    });

    // const emailId = props.Context.AuthContext._user.userName;
    const emailId = props.Context.auth.account.userName;
    if (this.state.messageType === 'MMS') {
      this.props.sendMMS(this.state.formData);
    } else {
      this.props.sendMessage(this.state.newMessageData);
      this.props.clearScheduleMessageStatus();
      this.props.fetchwhatsAppTemplateDataCleared();
    }
  };
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  onOutsideClick = () => {
    this.setState({
      pickerOpen: false
    });
  };
  onFocusClick = () => {
    this.setState({
      pickerOpen: false
    });
  };
  disabledDateTime = (current) => {
    console.log('current?', current);
    let currentDateTime = new Date();
    let currentDate = moment(current);
    let date = moment(currentDateTime);
    let now = moment(this.state.checkDate);
    let hours = currentDateTime.getHours();
    let minutes = currentDateTime.getMinutes();
    if (currentDate > date) {
      return {
        disabledHours: () => this.range(0, hours),
        // disabledHours: () => this.range(24, 0),
        disabledMinutes: () => this.range(60, 0)
      };
    } else {
      return {
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(0, minutes)
      };
    }
  };
  render() {
    const { current, selectedMedia, messageType, selectedDate, files, groupId } = this.state;
    const { t, templateListContent, communicationModes, costEstimatorData } = this.props;
    console.log('costEstimatorData>', costEstimatorData.length);
    console.log('files count>', files);
    console.log('groupId>', groupId);
    // console.log('edit files>', this.state.files);
    // const editedFiles = files.map((file) => ({
    //   fileKey: file.fileKey,
    //   fileUrl: file.fileUrl
    // }));
    // console.log('editedFiles>', editedFiles);
    let steps = [
      // {
      //   title: '1',
      //   content: (
      //     <FirstStep
      //       form={this.props.form}
      //       {...this.props}
      //       countryData={this.props.countryData}
      //       messageType={this.state.messageType}
      //       checkMessageType={(value) => {
      //         this.setState({
      //           messageType: value
      //         });
      //       }}
      //       disabled={true}
      //     />
      //   )
      // },
      {
        title: '2',
        content: (
          <SecondStep
            form={this.props.form}
            {...this.props}
            callback={(value) => {
              this.setState({
                imageSize: value
              });
            }}
            callbackType={(value) => {
              this.setState({
                imageType: value
              });
            }}
            callbackFile={(value) => {
              this.setState({
                files: value
              });
            }}
            countryData={this.props.countryData}
            messageType={this.state.messageType}
            fetchWhatsAppTemplateData={this.props.fetchWhatsAppTemplateData}
            templateList={this.props.templateList}
            templateListContent={this.props.templateListContent}
            fetchwhatsAppTemplateDataClear={this.props.fetchwhatsAppTemplateDataClear}
          />
        )
      },
      {
        title: '3',
        content: (
          <ThirdStep
            groupList={this.props.groupList}
            form={this.props.form}
            {...this.props}
            callbackGroupId={(value) => {
              this.setState({
                groupId: value
              });
            }}
            countryData={this.props.countryData}
            messageType={this.state.messageType}
            fetchCostEstimator={this.state.fetchCostEstimator}
            costEstimatorData={this.props.costEstimatorData}
            costEstimatorError={this.props.costEstimatorError}
            fetchCostEstimatorClear={this.props.fetchCostEstimatorClear}
            checkMediaType={(value) => {
              this.setState({
                selectedMedia: value
              });
            }}
          />
        )
      }
    ];

    return (
      <div>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} />
          ))}
        </Steps>

        <div className='steps-content'>
          {steps.map(({ title, content }, i) => (
            <div key={title} className={i === this.state.current ? 'foo fade-in' : 'foo'}>
              {content}
            </div>
          ))}
        </div>
        <div className='steps-action'>
          {this.state.current > 0 && (
            <Button type='default' style={{}} className='StepsprevBtn' onClick={() => this.prev()}>
              {t('Previous')}
            </Button>
          )}
          {this.state.current < steps.length - 1 && (
            <Button
              className='interactNxt'
              disabled={this.state.imageSize || this.state.imageType}
              style={{}}
              onClick={() => this.next()}>
              {t('Next')}
            </Button>
          )}

          {this.state.current === steps.length - 1 && (
            <Button
              className='schedule'
              onClick={(e) => this.handleSubmit(e, this.props)}
              disabled={
                this.props.costEstimatorData.length === 0 ||
                this.state.selectedDate === '' ||
                this.state.isButtonDisabled
              }>
              {t('Update')}
            </Button>
          )}

          {this.state.current === steps.length - 1 && (
            <div
              style={{
                position: 'relative',
                top: '-32px',
                left: '128px',
                width: '195px'
              }}>
              {/* <OutsideClickHandler
                onOutsideClick={(event) => {
                  event.preventDefault();
                  this.setState({
                    pickerOpen: false
                  });
                }}> */}
              {/* <div ref={this.myRef}> */}
              <DatePicker
                type='primary'
                open={this.state.pickerOpen}
                defaultValue={moment(this.props.editData.SentOn)}
                disabledTime={this.disabledDateTime}
                onMouseLeave={this.onOutsideClick}
                // onFocus={this.onFocusClick}
                disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current;
                }}
                showTime={{ format: 'HH:mm' }}
                showNow={false}
                showToday={false}
                format='YYYY-MM-DD HH:mm'
                use12Hours
                placeholder={t('Schedule for later')}
                onChange={this.onDateChange}
                onOk={this.onOk}
              />
              {/* </div> */}
              {/* </OutsideClickHandler> */}
              {this.state.selectedDate ? (
                ''
              ) : (
                <Button
                  className='scheduleLater'
                  onClick={this.togglePicker}
                  disabled={
                    this.props.countryData === 'ph'
                      ? false
                      : this.props.costEstimatorData.length === 0
                      ? this.props.costEstimatorData.length === 0
                      : this.props.costEstimatorData.length === 0 ||
                        this.props.countryData === 'idn'
                      ? this.props.costEstimatorData.target_audience > 200000 &&
                        this.state.messageType === 'WHATSAPP'
                      : this.props.costEstimatorData.length === 0 || this.props.countryData === 'bd'
                      ? this.props.costEstimatorData.target_audience > 500 &&
                        this.state.messageType === 'WHATSAPP'
                      : false
                  }
                  style={{ width: '195px', top: '-32px', left: '0px' }}>
                  {t('Schedule for later')}
                </Button>
              )}
            </div>
          )}
          {/* <Modal
            visible={this.props.costEstimatorData ? this.state.visible : false}
            onOk={(e) => this.handleOk(e, this.props)}
            onCancel={this.handleCancel}
            closable={false}
            footer={[
              <Button
                key='submit'
                type='primary'
                size='small'
                onClick={(e) => this.handleOk(e, this.props)}>
                {t('Confirm')}
              </Button>,
              <Button key='back' size='small' onClick={this.handleCancel}>
                {t('Cancel')}
              </Button>
            ]}>
            {this.props.countryData === 'ph' ? (
              false
            ) : Object.keys(this.props.costEstimatorData).length > 0 ? (
              <p>
                {t('Are you sure you want to proceed ? This message will be sent to')}{' '}
                {this.props.costEstimatorData.target_audience || ''}{' '}
                {t('growers and it would cost')} {this.props.costEstimatorData.cost || ''}{' '}
                {this.props.costEstimatorData.cost_unit || ''}
              </p>
            ) : Object.keys(this.props.costEstimatorError).length > 0 ? (
              <p>{this.props.costEstimatorError} </p>
            ) : null}
          </Modal> */}
        </div>
      </div>
    );
  }
}
UpdateStepsForm.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object,
  sendMessage: PropTypes.func
};

const UpdateStepsFormData = Form.create({})(UpdateStepsForm);

export default UpdateStepsFormData;

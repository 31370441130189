import fetch from 'cross-fetch';
import { formatUrl } from '../services/commonMethods';
import config from '../config';
import { UPDATE_GROWER_DRAWER } from '../constants/index';
import {
  fetchRegionCropPending,
  fetchRegionCropSuccess,
  fetchCropSuccess,
  fetchZoneSuccess,
  fetchRegionCropError,
  fetchTagsSuccess,
  fetchFranchiseCodeSuccess,
  fetchCropHistorySuccess,
  fetchPicSuccess,
  fetchLanguageSuccess,
  fetchSourceSuccess,
  fetchCitySuccess,
  fetchAreaSuccess,
  fetchDistrictSuccess,
  fetchRetailerNameSucess,
  fetachBrandSucess,
  fetchActivityTypeSuccess,
  fetachStockistCodeSucess,
  fetachLandSizeSucess,
  fetchMobileNumberSucess,
  fetchAcaresSuccess,
  fetachTMHQSucess,
  fetchChannelSuccess,
  fetchClassificationSuccess,
  fetchSegmentSuccess,
  fetchProvinceSuccess,
  fetchPramukhTagSuccess,
  fetchStateSuccess,
  fetchVillageSuccess,
  fetchTalukaSuccess,
  fetchMdohqSuccess,
  fetchTVCSuccess,
  fetchWASuccess,
  fetchFBSuccess,
  fetchYBSuccess,
  fetchIGSuccess,
  fetchFacebookSuccess,
  fetchSyngentaProductSucess,
  fetchZaloSuccess,
  fetchseasonSegmentationSuccess,
  fetchsytGrowerCornSuccess,
  fetchprefferedHybridCornSuccess,
  fetchsytGrowerRiceSuccess,
  fetchprefferedHybridRiceSuccess,
  fetchRetailerCodeSucess
} from '../actions/groupFilter';
import { handleErrors } from '../services/handleErrors';
import { toggleSpinStatus } from '../actions/globalSpinner';
export const fetchRegionAndCrops = () => {
  const apiUri = formatUrl(config.growerRegion, 'regions');

  return (dispatch) => {
    // dispatch(toggleSpinStatus());
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((region) => {
        if (region) dispatch(fetchRegionCropSuccess(region));
        else {
        }
        // dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        // dispatch(toggleSpinStatus());
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchCrops = () => {
  const apiUri = formatUrl(config.growerRegion, 'crops');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((region) => {
        dispatch(fetchCropSuccess(region));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchZone = () => {
  const apiUri = formatUrl(config.growerRegion, 'zone');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((zone) => {
        dispatch(fetchZoneSuccess(zone));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchAcares = () => {
  const apiUri = formatUrl(config.growerRegion, 'acare');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((acare) => {
        dispatch(fetchAcaresSuccess(acare));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchArea = () => {
  const apiUri = formatUrl(config.growerRegion, 'area');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((zone) => {
        dispatch(fetchAreaSuccess(zone));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const ToggeleGroupDrawer = () => {
  return (dispach) => {
    dispach({
      type: UPDATE_GROWER_DRAWER,
      value: ''
    });
  };
};

export const fetchTags = () => {
  const apiUri = formatUrl(config.growerRegion, 'tags');

  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((tags) => {
        if (tags) dispatch(fetchTagsSuccess(tags));
        else {
        }
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchFranchiseCode = () => {
  const apiUri = formatUrl(config.growerRegion, 'frnCode');

  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((franchiseCode) => {
        if (franchiseCode) dispatch(fetchFranchiseCodeSuccess(franchiseCode));
        else {
        }
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchCropHistory = () => {
  const apiUri = formatUrl(config.growerRegion, 'cropHistory');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((cropHistory) => {
        dispatch(fetchCropHistorySuccess(cropHistory));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchPic = () => {
  const apiUri = formatUrl(config.growerRegion, 'pic');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((pic) => {
        dispatch(fetchPicSuccess(pic));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchLanguage = () => {
  const apiUri = formatUrl(config.growerRegion, 'Language');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((language) => {
        dispatch(fetchLanguageSuccess(language));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchSource = () => {
  const apiUri = formatUrl(config.growerRegion, 'salesUnit');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((source) => {
        dispatch(fetchSourceSuccess(source));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchCity = () => {
  const apiUri = formatUrl(config.growerRegion, 'city');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((city) => {
        dispatch(fetchCitySuccess(city));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchDistrict = () => {
  const apiUri = formatUrl(config.growerRegion, 'district');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((district) => {
        dispatch(fetchDistrictSuccess(district));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchRetailerName = () => {
  const apiUri = formatUrl(config.growerRegion, 'retailerName');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((retailerName) => {
        dispatch(fetchRetailerNameSucess(retailerName));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchRetailerCode = () => {
  const apiUri = formatUrl(config.growerRegion, 'retailerCode');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((retailerCode) => {
        dispatch(fetchRetailerCodeSucess(retailerCode));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchBrand = () => {
  const apiUri = formatUrl(config.growerRegion, 'brand');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((brand) => {
        dispatch(fetachBrandSucess(brand));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchActivityType = () => {
  const apiUri = formatUrl(config.growerRegion, 'activitiesType');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((activityType) => {
        dispatch(fetchActivityTypeSuccess(activityType));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchStockistCode = () => {
  const apiUri = formatUrl(config.growerRegion, 'salesUnit');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((stockistCode) => {
        dispatch(fetachStockistCodeSucess(stockistCode));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchLandSize = () => {
  const apiUri = formatUrl(config.growerRegion, 'landSize');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((landSize) => {
        dispatch(fetachLandSizeSucess(landSize));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchMobileNumber = () => {
  const apiUri = formatUrl(config.growerRegion, 'mobileNumber');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((number) => {
        dispatch(fetchMobileNumberSucess(number));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchTMHQ = () => {
  const apiUri = formatUrl(config.growerRegion, 'tmhq');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((number) => {
        dispatch(fetachTMHQSucess(number));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchChannel = () => {
  const apiUri = formatUrl(config.growerRegion, 'Channel');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((channel) => {
        dispatch(fetchChannelSuccess(channel));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchClassification = () => {
  const apiUri = formatUrl(config.growerRegion, 'Classification');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((channel) => {
        dispatch(fetchClassificationSuccess(channel));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchSegment = () => {
  const apiUri = formatUrl(config.growerRegion, 'Segment');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((channel) => {
        dispatch(fetchSegmentSuccess(channel));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchProvince = () => {
  const apiUri = formatUrl(config.growerRegion, 'Province');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchProvinceSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchPramukhTag = () => {
  const apiUri = formatUrl(config.growerRegion, 'pramukhTag');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchPramukhTagSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchState = () => {
  const apiUri = formatUrl(config.growerRegion, 'state');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchStateSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchVillage = () => {
  const apiUri = formatUrl(config.growerRegion, 'village');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchVillageSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchTaluka = () => {
  const apiUri = formatUrl(config.growerRegion, 'taluka');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchTalukaSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchMDOHQ = () => {
  const apiUri = formatUrl(config.growerRegion, 'mdohq');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchMdohqSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchTVC = () => {
  const apiUri = formatUrl(config.growerRegion, 'TVC');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchTVCSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchWA = () => {
  const apiUri = formatUrl(config.growerRegion, 'WA');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchWASuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchFB = () => {
  const apiUri = formatUrl(config.growerRegion, 'FB');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchFBSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchYB = () => {
  const apiUri = formatUrl(config.growerRegion, 'YB');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchYBSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchIG = () => {
  const apiUri = formatUrl(config.growerRegion, 'IG');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchIGSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchFacebook = () => {
  const apiUri = formatUrl(config.growerRegion, 'facebook');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((facebook) => {
        dispatch(fetchFacebookSuccess(facebook));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchZalo = () => {
  const apiUri = formatUrl(config.growerRegion, 'zalo');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((zalo) => {
        dispatch(fetchZaloSuccess(zalo));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

export const fetchSyngentaProduct = () => {
  const apiUri = formatUrl(config.growerRegion, 'syngentaProductUsing');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((syngentaProduct) => {
        dispatch(fetchSyngentaProductSucess(syngentaProduct));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchSeasonSegmentation = () => {
  const apiUri = formatUrl(config.growerRegion, 'seasonSegmentation');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchseasonSegmentationSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchsytGrowerCorn = () => {
  const apiUri = formatUrl(config.growerRegion, 'sytGrowerCorn');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchsytGrowerCornSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchprefferedHybridCorn = () => {
  const apiUri = formatUrl(config.growerRegion, 'prefferedHybridCorn');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchprefferedHybridCornSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchsytGrowerRice = () => {
  const apiUri = formatUrl(config.growerRegion, 'sytGrowerRice');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchsytGrowerRiceSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};
export const fetchprefferedHybridRice = () => {
  const apiUri = formatUrl(config.growerRegion, 'prefferedHybridRice');
  return (dispatch) => {
    dispatch(fetchRegionCropPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((province) => {
        dispatch(fetchprefferedHybridRiceSuccess(province));
      })
      .catch((error) => {
        dispatch(fetchRegionCropError(error));
      });
  };
};

import {
  ALL_GROWERS_COUNT_PENDING,
  ALL_GROWERS_COUNT_SUCCESS,
  ALL_GROWERS_COUNT_FAILURE,
  ALL_GROWERS_COUNT_LOADING
} from '../constants/index';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import AdalConfigService from '../services/adalConfigService';
export const allGrowersCountLoading = (val) => {
  return { type: ALL_GROWERS_COUNT_LOADING, payload: val };
};

export const allGrowersCountPending = (value) => {
  return {
    type: ALL_GROWERS_COUNT_PENDING,
    payload: value
  };
};
export const allGrowersCountSuccess = (value) => {
  return {
    type: ALL_GROWERS_COUNT_SUCCESS,
    payload: value
  };
};

export const allGrowersCountFailure = (error) => {
  return {
    type: ALL_GROWERS_COUNT_FAILURE,
    payload: error
  };
};

export const allGrowersCount = () => {
  const apiUri = config.allGrowersCount;
  return (dispatch) => {
    dispatch(allGrowersCountPending(true));
    dispatch(allGrowersCountLoading(true));
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        let eventProperties = {
          PK_Count: result.growerCount
        };
        window.amplitude
          .getInstance()
          .logEvent(`${AdalConfigService.countryName}_Growers_Count`, eventProperties);
        dispatch(allGrowersCountPending(false));
        dispatch(allGrowersCountSuccess(result));
        dispatch(allGrowersCountLoading(false));
      })
      .catch((e) => {
        dispatch(allGrowersCountFailure(e));
      });
  };
};

import {
  WhATSAP_TEMPLATE_PENDING,
  WhATSAP_TEMPLATE_SUCESS,
  WhATSAP_TEMPLATE_ERROR,
  FETCH_TEMPLATE_CLEAR
} from '../constants';

const initialState = {
  data: {
    whatsAppTemplate: {},
    whatsAppTemplateError: {},
    messageDrawerVisible: false,
    completed: false,
    loading: true
  }
};

function whatsAppTemplate(state = initialState, action) {
  switch (action.type) {
    case WhATSAP_TEMPLATE_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case WhATSAP_TEMPLATE_SUCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          whatsAppTemplate: action.payload,
          completed: false
        }
      });
    case WhATSAP_TEMPLATE_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          whatsAppTemplateError: action.payload,
          completed: false
        }
      });
      case FETCH_TEMPLATE_CLEAR:
        return Object.assign({}, state, {
          data: {
            ...state.data,
            whatsAppTemplate: [],
            whatsAppTemplateError:{}
          }
        });
    default:
      return state;
  }
}

export default whatsAppTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { Layout } from 'antd';
import TitleSection from '../../commonComponents/TitleSection/titleSection';
import GrowerGroupData from './GrowerGroupData';
import CardGroupTable from './CardGroupTable';
import NewGroupSVG from '../../../assets/icons/svg/new_group.svg';
import { toggleGroupDrawer } from '../../../actions/groupCreate';
import { withTranslation } from 'react-i18next';
import { alertBox } from '../../commonComponents/Notification/alertBox';
import './growerGroup.css';
import GroupDrawer from './GroupDrawer/groupDrawer';
import { RegionalMetaContext } from '../../../context/region';
import Draggable from 'react-draggable';
const { Content } = Layout;
class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      memberCurrentPage: 0
    };
    this.CloseGroupDrawer = this.CloseGroupDrawer.bind(this);
  }

  CloseGroupDrawer() {
    this.props.onCloseGroupDrawer();
  }
  setCurrentPage = (current) => {
    this.setState({
      currentPage: current
    });
  };
  setMemberCurrentPage = (current) => {
    this.setState({
      memberCurrentPage: current
    });
  };

  render() {
    const { t } = this.props;

    const title = t('Groups');
    const searchTitle = t('Search Group');

    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <Layout className='groupLayout' style={{ overflow: 'hidden' }}>
            <TitleSection title={title} searchTitle={searchTitle} />
            <Content className='mainContent' style={{}}>
              <GrowerGroupData
                setCurrentPage={this.setCurrentPage}
                currentPage={this.state.currentPage}
                setMemberCurrentPage={this.setMemberCurrentPage}
                memberCurrentPage={this.state.memberCurrentPage}
                columns={data.groupListTable}
                country={country}
                {...this.props}
                drawerData={data}
                acresVisible={data.acresVisible}
                memberColumns={data.groupMemberTable}
                toggleAlert={this.props.toggleAlert}
                resetPage={() => {
                  this.setState({
                    currentPage: 0
                  });
                }}
                resetMemberPage={() => {
                  this.setState({
                    memberCurrentPage: 0
                  });
                }}
              />
              {/* <CardGroupTable
                setCurrentPage={this.setCurrentPage}
                currentPage={this.state.currentPage}
                setMemberCurrentPage={this.setMemberCurrentPage}
                memberCurrentPage={this.state.memberCurrentPage}
                columns={data.groupListTable}
                country={country}
                {...this.props}
                drawerData={data}
                acresVisible={data.acresVisible}
                memberColumns={data.groupMemberTable}
                toggleAlert={this.props.toggleAlert}
                resetPage={() => {
                  this.setState({
                    currentPage: 1
                  });
                }}
                resetMemberPage={() => {
                  this.setState({
                    memberCurrentPage: 0
                  });
                }}
              /> */}
              <Draggable>
                <div className='handle' style={{ zIndex: '1', position: 'relative' }}>
                  <a
                    className='creaGroup'
                    onClick={this.props.toggleGroupDrawer}
                    title={t('New')}
                    style={{ zIndex: '1' }}>
                    <img src={NewGroupSVG} id='fixedbutton' />
                  </a>
                </div>
              </Draggable>
            </Content>

            {this.props.toggleAlert
              ? alertBox({
                  message: this.props.alertMessage,
                  type: this.props.alertType
                })
              : null}
            <GroupDrawer
              {...this.props}
              resetPage={() => {
                this.setState({
                  currentPage: 1
                });
              }}
              drawerData={data}
              country={country}
              acresVisible={data.acresVisible}
            />
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    toggleGroupDrawer() {
      dispatch(toggleGroupDrawer());
    }
  };
};

const mapStateToProps = function(state) {
  return {
    groupDrawerVisible: _get(state, 'creatGroupReducer.groupDrawerVisible', true),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', ''),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true)
  };
};

Groups.propTypes = {
  onCloseGroupDrawer: PropTypes.func,
  groupDrawerVisible: PropTypes.bool,
  toggleAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  toggleGroupDrawer: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Groups));

import React from 'react';
import { Layout } from 'antd';

import PropTypes from 'prop-types';
import './style.css';
// import { Wrapper, Header } from "./styles";
import MainHeader from '../header/header';
import Drawer from '../drawer/drawer';
// import MainFooter from "../Footer/footer";
// import ErrorBoundry from "../../commonComponents/errorboundry/errorboundry";

const { Content } = Layout;
export default function AuthLayout(props) {
  return (
    <Layout style={{ minHeight: '100vh', background: 'transparent' }}>
      <MainHeader {...props} />

      <Layout style={{ background: 'transparent' }}>
        <Drawer {...props} />
        <Layout style={{ background: 'transparent' }}>
          <Content
            style={{
              background: 'transparent',
              minHeight: 280
            }}>
            {props.children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired
};

import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

class SearchUpdatedDate extends React.Component {
  constructor(props) {
    super(props);
  }
  handleChange = (user, dateString) => {
    const value = [moment(dateString).format('YYYY-MM-DD')];
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.callback(newUsers);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item>
        {getFieldDecorator(`${this.props.updateValue}`, {
          //   validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              type: 'object',
              required: true,
              message: 'Please Select Date'
            }
          ]
        })(
          <DatePicker
            size='small'
            format='YYYY-MM-DD'
            placeholder='Select Date'
            value={moment(this.props.user.value[0]).format('YYYY-MM-DD')}
            onChange={(dateString) => {
              this.handleChange(this.props.user, dateString);
            }}
          />
        )}
      </Form.Item>
    );
  }
}

export default SearchUpdatedDate;

import React from 'react';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Input, Form, Select, Radio, Icon, Modal, Button, Spin, DatePicker, Calendar } from 'antd';
import PropTypes from 'prop-types';
import { communicationLists } from '../../../actions/modesOfCommunication';
import { fetchAllGroupList } from '../../../actions/groupList';
import { fetchCostEstimator } from '../../../actions/costEstimator';
import './msgForm.css';

const FormItem = Form.Item;

const { Option } = Select;

class MsgForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      msgData: [],
      radioVal: 'SMS',
      channelMode: '',
      selectedSchema: '',
      visible: false,
      startDate: new Date(),
      datePickerIsOpen: false,
      selectedDate: null,
      costEstimatorData: {},
      isEnable: false,
      mdata: { channels: ['SMS'], groupId: '' },
      cdata: { channel: 'SMS', groupId: '' }
      // mdata: []
    };
    this.modes = [];
    /****** Drawer function declarations */
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount = () => {
    this.props.fetchCostEstimatorClear();
    // this.props.form.validateFields();
    this.props.fetchAllGroupList(0);
    if (this.props.region) {
      this.props.communicationLists(this.props.region.country_code);
    }
  };
  componentDidUpdate(prevProps) {
    if (this.props.region && this.props.region !== prevProps.region) {
      this.props.communicationLists(this.props.region.country_code);
    }
  }
  handleButtonEnable(event) {
    const value = this.target.value;
    if (value.length > 0) {
      // set the state of isEnable to be true to make the button to be enable
      this.setState({ isEnable: true });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const mdata = {
          channels: [values.SocialPlatform],
          groupId: values.groupType
        };
        this.setState({ mdata });
        // this.props.fetchCostEstimator(mdata);
        this.showModal();
        // this.props.fetchCostEstimatorClear();
      }
    });
  }

  /***** Select dropdown Functions */
  onDateChange = (value, dateString) => {
    this.setState({
      selectedDate: dateString
    });
  };

  onOk = (value) => {};
  onChange(value) {
    this.setState({
      selectedSchema: value
    });
  }

  onRadioChange(e) {
    this.setState({
      radioVal: e.target.value
    });
  }
  validateMessageType = (rule, value, callback) => {
    if (value && value !== 'SMS' && value !== 'WHATSAPP' && value !== 'MMS') {
      callback('This service will come soon.');
    } else {
      callback();
    }
    callback();
  };

  handleChange = () => {
    this.props.form.validateFields((error, value) => {
      this.props.fetchCostEstimatorClear();
      this.setState({ channelMode: value.SocialPlatform });
      if (!error) {
        const mdata = {
          channels: [value.SocialPlatform],
          groupId: value.groupType
        };
        const cdata = {
          channel: value.SocialPlatform,
          groupId: value.groupType
        };
        this.setState({ mdata });
        this.setState({ cdata });
        this.props.fetchCostEstimator(mdata);
        this.props.fetchCostEstimatorClear();
      }
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = (e, props) => {
    this.setState({
      visible: false
    });
    // const emailId = props.Context.AuthContext._user.userName;
    const emailId = props.Context.auth.account.userName;
    this.props.sendAgroServiceMessage(this.state.cdata, emailId, this.props.widgetdata.id);
    this.props.fetchCostEstimatorClear();
  };
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { communicationModes, region, groupListData, costEstimator, t } = this.props;
    this.modes =
      communicationModes &&
      communicationModes.map((item) => {
        return (
          <Radio.Button value={item.name}>
            <img src={item.image_url} />
            <label>{item.name}</label>
          </Radio.Button>
        );
      });
    const optionsData =
      groupListData &&
      groupListData.map((item, i) => {
        return (
          <Option key={i} value={item.id}>
            {item.name}
          </Option>
        );
      });
    return (
      <>
        <Form onSubmit={this.handleSubmit} className='webstore-form' method='post'>
          <FormItem>
            {getFieldDecorator('groupType', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{ required: true, message: t('Please select a group!') }]
            })(
              <Select
                placeholder={t('Select Group Of Grower(s)')}
                onSelect={this.handleChange}
                className='messagegroupType'
                showSearch
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                {optionsData}
                {/* <Option value="Dynamic">Dynamic</Option>
              <Option value="Static">Static</Option> */}
              </Select>
            )}
          </FormItem>
          <FormItem style={{ marginBottom: '0' }}>
            {getFieldDecorator('subject', {
              rules: [
                {
                  required: true,
                  message: t('Please enter subject!')
                }
              ],
              initialValue: this.props.widgetdata ? this.props.widgetdata.name : ''
            })(<Input placeholder='Subject' disabled />)}
          </FormItem>
          <FormItem style={{ marginBottom: '0' }} onChange={this.handleChange}>
            {getFieldDecorator('SocialPlatform', {
              rules: [
                {
                  required: true,
                  message: t('Please select platform type!')
                },
                {
                  validator: this.validateMessageType
                }
              ],
              initialValue: 'SMS'
            })(
              <Radio.Group
                size='medium'
                className='webstoreRadioGroup'
                // onChange={this.handleChange}>
              >
                {this.modes}
              </Radio.Group>
            )}
          </FormItem>
          <FormItem>
            {this.state.channelMode === 'SMS' || this.state.channelMode === 'WHATSAPP' ? (
              Object.keys(this.props.costEstimatorData).length > 0 ? (
                <span className='costMsg'>
                  {t('This agronomy service will be sent to')}{' '}
                  {this.props.costEstimatorData.target_audience} {'growers and it would cost'}{' '}
                  {this.props.costEstimatorData.cost} {this.props.costEstimatorData.cost_unit || ''}
                </span>
              ) : Object.keys(this.props.costEstimatorError).length > 0 ? (
                <span className='costErrorMsgText'> {this.props.costEstimatorError}</span>
              ) : this.state.mdata.groupId.length > 0 ? (
                <span className='loadingCost'>{this.props.costEstimatorLoader}</span>
              ) : null
            ) : null}
            {this.state.selectedDate ? (
              <Button
                className='schedule'
                onClick={this.handleSubmit}
                disabled={Object.keys(this.props.costEstimatorData).length === 0}>
                {t('Schedule')}
              </Button>
            ) : (
              <Button
                className='sendNow'
                onClick={this.handleSubmit}
                disabled={Object.keys(this.props.costEstimatorData).length === 0}>
                {t('Send Now')}
              </Button>
            )}

            {/* <Button
            type="primary"
            style={{
              float: "left",
              marginLeft: "5%",
              textTransform: "uppercase"
            }}
            onClick={this.handleSubmit}
          >
            Send Later
          </Button> */}
          </FormItem>
          {/* <div style={{ position: 'relative', top: '-45px' }}>
            <DatePicker
              type='primary'
              // showTime
              showTime={{ format: 'HH:mm' }}
              format='YYYY-MM-DD HH:mm'
              use24Hours={true}
              placeholder='Schedule for later'
              onChange={this.onDateChange}
              onOk={this.onOk}
            />
          </div> */}
        </Form>
        <Modal
          // title='Cost Estimation'
          visible={this.props.costEstimatorData ? this.state.visible : false}
          onOk={(ok) => this.handleOk(e, this.props)}
          onCancel={this.handleCancel}
          closable={false}
          footer={[
            <Button
              key='submit'
              type='primary'
              size='small'
              onClick={(e) => this.handleOk(e, this.props)}>
              {t('Confirm')}
            </Button>,
            <Button key='back' size='small' onClick={this.handleCancel}>
              {t('Cancel')}
            </Button>
          ]}>
          {Object.keys(this.props.costEstimatorData).length > 0 ? (
            <p>
              {t('Are you sure you want to proceed ? This agronomy service will be sent to')}{' '}
              {this.props.costEstimatorData.target_audience} {t('growers and it would cost')}{' '}
              {this.props.costEstimatorData.cost} {this.props.costEstimatorData.cost_unit || ''}
            </p>
          ) : Object.keys(this.props.costEstimatorError).length > 0 ? (
            <p>{this.props.costEstimatorError} </p>
          ) : null}
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    communicationLists: (regionCode) => dispatch(communicationLists(regionCode)),
    fetchAllGroupList: (pagenum) => dispatch(fetchAllGroupList(pagenum))
  };
};
const mapStateToProps = function(state) {
  return {
    communicationModes: get(
      state,
      'communicationList.communicationLists.supported_channel.channels',
      []
    ),
    region: get(state, 'webStoreListReducer.regionListData.current_region', {}),
    groupListData: get(state, 'groupListReducer.data.groupListData.items', []),
    costEstimator: get(state, 'costEstimatorList.costEstimatorResult', {}),
    costEstimatorLoader: get(state, 'costEstimatorList.data.costEstimatorLoader', {})
  };
};
MsgForm.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
const MsgFormApp = Form.create({ name: 'normal_login' })(MsgForm);

// export default MsgFormApp;
export default connect(mapStateToProps, mapDispatchToProps)(MsgFormApp);

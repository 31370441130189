import React, { useEffect, useState } from 'react';
import { Avatar, Icon, List, Badge, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { textualSVG, imageSVG, whatsappSVG } from '../Growers/Interact/messageSvg/messageSvgIcons';
import { connect } from 'react-redux';
import cloneDeep from 'lodash/cloneDeep';
import { openConversationResult, clearData } from '../../actions/openConversation';
import { clearUnReadMessages } from '../../actions/getConversation';
import _get from 'lodash.get';
import moment from 'moment';
import './SidebarChat.css';
import MainStepsForm from '../Growers/Interact/newMessageDrawer/stepForm/stepsForm';
import wifiNotConnected from '../../assets/img/MessageSvg/wifi-notConnected.svg';
import { clearYoutubeVideoStatus } from '../../actions/uploadYouTubeVideos';
// import { customStyles } from './CustomModalStyles';

class SidebarChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      loadingData: true,
      count: 0,
      page: 1
    };
  }

  openConversation = async (id) => {
    const response = await fetch(
      `https://dev-msg-api.grower-console.syn-dpe.com/v1/conversations/${id}?pageNo=0`
    );
    const result = await response.json();
    // this.props.history.push(`/inbox/rooms/${id}`);
    this.props.chatClickHandler(result.items);
    this.props.getUser(this.props.name);
    this.props.getChatId(id);
    // this.clearNotification();
    // setMessages((preResult) => [...preResult, ...Result]);
    if (this.state.loadingData) {
      this.setState({
        loadingData: false,
        messages: result.items
      });
    }
  };

  openChatConversation = (id) => {
    this.props.getChatId(id);
    this.props.clearData();
    this.props.openConversationResult(id, 0, this.props.countryCode);
    this.props.chatClickHandler(this.props.openConversationList);
    this.props.getUser(this.props.name);
    this.props.clearUnReadMessages(id);
  };

  render() {
    const {
      openConversationList,
      conversationId,
      conversationResult,
      unReadMessage,
      id,
      name,
      dateTime,
      count
    } = this.props;
    return (
      //   <List
      //     dataSource={this.props.channels}
      //     renderItem={(item) => (
      //       <List.Item key={item.id} onClick={() => this.openConversation(item.id)}>
      //         <List.Item.Meta
      //           avatar={<Avatar style={{ backgroundColor: '#87d068' }} icon='user' />}
      //           title={item.contact.msisdn}
      //         />
      //       </List.Item>
      //     )}></List>

      <div onClick={(id) => this.openChatConversation(this.props.id)}>
        <div className='sidebarChat'>
          <Avatar style={{ backgroundColor: '#87d068' }} icon='user' />
          <div className='sidebarChat__info'>
            <div className='displayName'>
              <h2 className='sidebarChatName'>{`+${name}`}</h2>
            
              {conversationResult.count ? (
                <p className='dateTime'>{moment(conversationResult.notificationMsg.message.createdDatetime).format('LT')}</p>
              ) : (
                ''
              )}
            </div>
            <div className='notificationContainer'>
              {conversationResult.count ? (
                <>
                  <p className='notificationBar'>
                    {conversationResult.notificationMsg.message.type === 'image'
                      ? 'Photo'
                      : conversationResult.notificationMsg.message.type === 'video'
                      ? 'Video'
                      : conversationResult.notificationMsg.message.type === 'audio'
                      ? 'Audio'
                      : conversationResult.notificationMsg.message.content.text}
                  </p>
                  <p className='showCount'>
                    <Badge
                      count={conversationResult.count}
                      style={{
                        backgroundColor: '#4AC959',
                        color: '#fffff',
                        boxShadow: '0 0 0 1px #25D366 inset'
                      }}
                    />
                  </p>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    //   getConversationResult: () => dispatch(getConversationResult()),
    openConversationResult: (id, page, countryCode) => dispatch(openConversationResult(id, page, countryCode)),
    clearData: () => dispatch(clearData()),
    clearUnReadMessages: (id) => dispatch(clearUnReadMessages(id))
  };
};
// export default OnBoard;
const mapStateToProps = function(state) {
  return {
    // conversationResult: _get(state, 'getConversationList.data.conversationList.items', []),
    openConversationList: _get(state, 'openConversationList.data.openConversationList.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SidebarChat);

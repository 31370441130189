import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Tabs, Icon } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { withTranslation } from 'react-i18next';
import './interact.css';
import TitleSection from '../../commonComponents/TitleSection/titleSection';
import MessageListData from '../Interact/IntractList';
import ScheduleMessageList from '../Interact/scheduleMessageList';
import NewGroupSVG from '../../../assets/icons/svg/new_group.svg';
import NewMessageDrawer from './newMessageDrawer/newMessageDrawer';
import { toggleDrawer } from '../../../actions/messageSending';
import { sentMessageListWithFetchAPI } from '../../../services/messageListService';
import { fetchGroupListWithFetchAPI } from '../../../services/groupListService';
import { fetchWhatsAppTemplate } from '../../../actions/whatsAppTemplate';
import { clearScheduleMessageStatus } from '../../../actions/scheduleMessage';
import { alertBox } from '../../commonComponents/Notification/alertBox';
import GlobalSpinner from '../../commonComponents/globalSpinner/globalSpinner';
import { RegionalMetaContext } from '../../../context/region';
import { findCountryandEnvironment } from '../../../services/commonMethods';
const { Content } = Layout;
const { TabPane } = Tabs;

class Interact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      key: '1',
      activeTab: '1',
      sentActive: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      this.selectNew();
    }
  }

  componentDidMount() {
    const countryHostName = findCountryandEnvironment(window.location.host);
    if (countryHostName.country === 'idn' || countryHostName.country === 'bd') {
      this.props.fetchGroupListWithFetchAPI(0);
      this.props.fetchWhatsAppTemplate();
    } else {
      this.props.fetchGroupListWithFetchAPI(0);
      this.props.clearScheduleMessageStatus();
    }
  }

  setCurrentPage = (current) => {
    this.setState({
      currentPage: current
    });
  };
  callback = (activeTab) => {
    this.setState({
      activeTab,
      currentPage: 0
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.sendMessage === 'sent') {
      this.setState({
        activeTab: '1'
      });
    }
    if (nextProps.sendMMS === 'sentMms') {
      this.setState({
        activeTab: '1'
      });
    }
    if (nextProps.scheduedMsg === 'schedule') {
      this.setState({
        activeTab: '2'
      });
    } else if (nextProps.deleteScheduleStatus === 'deleteSchedule') {
      this.setState({
        activeTab: '2'
      });
    } else if (nextProps.updateMmsScheduleStatus === 'updateMmsSchedule') {
      this.setState({
        activeTab: '2'
      });
    } else if (nextProps.updateScheduleStatus === 'updateSchedule') {
      this.setState({
        activeTab: '2'
      });
    } else if (nextProps.scheduleMMSStatus === 'scheduleMms') {
      this.setState({
        activeTab: '2'
      });
    } else
      this.setState({
        activeTab: '1'
      });
  }
  sentActiveFalse = () => {
    this.setState({
      sentActive: false
    });
  };
  render() {
    const { t, templateList, scheduleMessageDelete } = this.props;
    const title = t('Interactions');
    const searchTitle = t('Search Interact');
    console.log('activeTab>', this.state.activeTab);
    console.log('scheduleMessageDelete>', scheduleMessageDelete);
    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <Layout className='interactLayout'>
            <TitleSection title={title} searchTitle={searchTitle} />
            <Content className='mainContent'>
              <div style={{ padding: '0 0', background: 'transparent' }}>
                <Tabs activeKey={this.state.activeTab} onChange={this.callback}>
                  <TabPane
                    tab={
                      <span>
                        <Icon type='message' />
                        {t('Sent')}
                      </span>
                    }
                    key='1'>
                    <MessageListData
                      resetPage={() => {
                        this.setState({
                          currentPage: 0
                        });
                      }}
                      setCurrentPage={this.setCurrentPage}
                      currentPage={this.state.currentPage}
                      sentActive={this.state.sentActive}
                      scheduedMsg={this.props.scheduedMsg}
                      columns={data.interactTable}
                      sentActiveFalse={this.sentActiveFalse}
                      activeTab={this.state.activeTab}
                    />
                  </TabPane>
                  <TabPane
                    tab={
                      <span>
                        <Icon type='calendar' />
                        {t('Scheduled')}
                      </span>
                    }
                    key='2'>
                    <ScheduleMessageList
                      resetPage={() => {
                        this.setState({
                          currentPage: 0
                        });
                      }}
                      {...this.props}
                      setCurrentPage={this.setCurrentPage}
                      currentPage={this.state.currentPage}
                      columns={data.scheduleMessageTable}
                      hideUpdateScheduleMessage={data.hideUpdateScheduleMessage}
                      t={t}
                      activeTab={this.state.activeTab}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Content>
            <a className='creaGroup' onClick={this.props.toggleDrawer} title={t('New')}>
              <img src={NewGroupSVG} id='Messagefixedbutton' />
            </a>
            <NewMessageDrawer
              resetPage={() => {
                this.setState({
                  currentPage: 0
                });
              }}
              {...this.props}
              countryData={country}
              changeKey={() => {
                this.setState({
                  activeTab: '2'
                });
              }}
              templateList={this.props.templateList}
            />
            {this.props.toggleAlert
              ? alertBox({
                  message: this.props.alertMessage,
                  type: this.props.alertType
                })
              : null}
            <GlobalSpinner />
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    toggleDrawer() {
      dispatch(toggleDrawer());
    },
    fetchGroupListWithFetchAPI: (pagenum) => dispatch(fetchGroupListWithFetchAPI(pagenum)),
    sentMessageListWithFetchAPI: (pagenum) => dispatch(sentMessageListWithFetchAPI(pagenum)),
    fetchWhatsAppTemplate: () => dispatch(fetchWhatsAppTemplate()),
    clearScheduleMessageStatus: () => dispatch(clearScheduleMessageStatus())
  };
};
const mapStateToProps = (state) => {
  return {
    sendMessage: _get(state, 'messageSendingFormReducer.messageStatus', ''),
    sendMMS: _get(state, 'sendMMSReducer.data.messageStatus', ''),
    scheduedMsg: _get(state, 'scheduleMessage.data.messageStatus', ''),
    drawerVisible: _get(state, 'creatGroupReducer.drawerVisible', true),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', ''),
    templateList: _get(state, 'whatsAppTemplate.data.whatsAppTemplate', {}),
    deleteScheduleStatus: _get(state, 'scheduleMessageDelete.messageStatus', {}),
    updateMmsScheduleStatus: _get(state, 'scheduleMMSUpdate.data.messageStatus', {}),
    updateScheduleStatus: _get(state, 'scheduleMessageUpdateReducer.messageStatus', {}),
    scheduleMessageDelete: _get(state, 'scheduleMessageDelete.deleted', {}),
    scheduleMMSStatus: _get(state, 'scheduleMMSReducer.data.messageStatus', {})
  };
};
Interact.propTypes = {
  fetchGroupListWithFetchAPI: PropTypes.func,
  toggleDrawer: PropTypes.func,
  toggleAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Interact));

import {
  SCHEDULE_MESSAGE_PENDING,
  SCHEDULE_MESSAGE_SUCCESS,
  SCHEDULE_MESSAGE_ERROR,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW,
  CLEAR_SCHEDULE_MESSAGE_STATUS
} from '../constants/index';

const initialState = {
  data: {
    message: {},
    messageError: {},
    messageDrawerVisible: false,
    messageStatus: '',
    rowHighlight: false,
    completed: false,
    loading: true
  }
};

function scheduleMessage(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MESSAGE_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case SCHEDULE_MESSAGE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageList: action.payload,
          messageStatus: 'schedule',
          completed: false
        }
      });
    case SCHEDULE_MESSAGE_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageListError: action.payload,
          messageStatus: 'schedule',
          completed: false
        }
      });
    case CLEAR_SCHEDULE_MESSAGE_STATUS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageStatus: '',
          completed: false
        }
      });
    case TOGGLE_DRAWER_STATUS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageDrawerVisible: !state.messageDrawerVisible
        }
      });
    case HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };
    default:
      return state;
  }
}

export default scheduleMessage;

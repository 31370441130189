import {
    GROUP_UPDATE_PENDING,
    GROUP_UPDATE_SUCCESS,
    GROUP_UPDATE_FAILURE,
    UPDATE_GROUP_DRAWER
  } from '../constants/index';
  import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
  import { toggleSpinStatus } from '../actions/globalSpinner';
  import { fetchGroupListWithFetchAPI } from '../services/groupListService';
  import { handleErrors } from '../services/handleErrors';
  import { formatUrl } from '../services/commonMethods';
  import config from '../config';

  export const groupUpdatePending = (value) => {
    return {
      type: GROUP_UPDATE_PENDING,
      payload: value
    };
  };
  export const groupUpdateSuccess = (value) => {
    return {
      type: GROUP_UPDATE_SUCCESS,
      payload: value
    };
  };
  
  export const groupUpdateFailure = (error) => {
    return {
      type: GROUP_UPDATE_FAILURE,
      payload: error
    };
  };

  export function toggleGroupUpdateDrawer() {
    return {
      type: UPDATE_GROUP_DRAWER
    };
  }

  
  export const groupUpdate = (groupData, userId, groupId, pageNumber) => {
    const checkPageNumber = pageNumber <= 0 ? pageNumber : pageNumber - 1;
    const apiUri = formatUrl(config.updateGroup, groupId);
    return (dispatch) => {
      dispatch(groupUpdatePending());
      dispatch(toggleSpinStatus());
      // dispatch(clearAlertState());
      return fetch(apiUri, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'temp-created-by': userId,
          Authorization: 'Bearer ' + config.userToken
        },
        body: JSON.stringify(groupData)
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          if (result && result.code === '200') {
            dispatch(clearAlertState());
            dispatch(toggleSpinStatus());
            dispatch(toggleGroupUpdateDrawer());
            dispatch(groupUpdateSuccess(result));
            // setTimeout(
            //   function() {
            dispatch(fetchGroupListWithFetchAPI(0))
        //   }.bind(),
        //   3000
        // );
            dispatch(
              successNotification({
                type: 'success',
                message: result.message,
                toggleAlert: true
              })
            );
            dispatch(clearAlertState());
          } else {  
            dispatch(toggleSpinStatus());
            dispatch(clearAlertState());
            dispatch(groupUpdateFailure(result));
            dispatch(
              errorNotification({
                type: 'error',
                message: result.message,
                toggleAlert: true
              })
            );
  
            dispatch(clearAlertState());
          }
        })
        .catch((e) => {
          dispatch(toggleSpinStatus());
          dispatch(toggleGroupUpdateDrawer());
          dispatch(groupUpdateFailure(e));
  
          dispatch(
            errorNotification({
              type: 'error',
              message: e.message,
              toggleAlert: true
            })
          );
  
          dispatch(clearAlertState());
        });
    };
  };
  
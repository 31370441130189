import config from '../config';
import isEmpty from 'lodash/isEmpty';
import { fetchGroupListWithFetchAPI } from './groupListService';
import {
  createGroupPending,
  createGroupSuccess,
  createGroupFailure,
  toggleGroupDrawer,
  highlightGroupTableRow
} from '../actions/groupCreate';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { handleErrors } from '../services/handleErrors';
import { toggleSpinStatus } from '../actions/globalSpinner';
import AdalConfigService from '../services/adalConfigService';
export function createGroup(groupData, userId) {
  const apiUri = config.createGroup;
  return (dispatch) => {
    dispatch(toggleSpinStatus());
    dispatch(createGroupPending());
    dispatch(clearAlertState());

    return fetch(apiUri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'temp-created-by': userId,
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(groupData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          window.amplitude.getInstance().logEvent(`${AdalConfigService.countryName}_Group_Create`);
          dispatch(fetchGroupListWithFetchAPI(0));
          dispatch(createGroupSuccess(result));
          dispatch(toggleSpinStatus());
          dispatch(toggleGroupDrawer());
          dispatch(highlightGroupTableRow(true));
          dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          if (result === false) {
            dispatch(toggleSpinStatus());
            dispatch(clearAlertState());
            return false;
          }
          dispatch(createGroupFailure(result));
          dispatch(
            errorNotification({
              type: 'error',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(toggleSpinStatus());
          dispatch(clearAlertState());
        }
      })
      .catch((e) => {
        dispatch(clearAlertState());
        dispatch(createGroupFailure(e));
        dispatch(toggleSpinStatus());
        dispatch(toggleGroupDrawer());
        dispatch(
          errorNotification({
            type: 'error',
            message: 'Create Group Failure',
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
}

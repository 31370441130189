import {
  FETCH_REGION_CROP_PENDING,
  FETCH_REGION_CROP_SUCCESS,
  FETCH_CROP_SUCCESS,
  FETCH_ZONE_SUCCESS,
  FETCH_AREA_SUCCESS,
  FETCH_TAGS_SUCCESS,
  FETCH_REGION_CROP_ERROR,
  FETCH_FRANCHISE_CODE_SUCCESS,
  FETCH_CROP_HISTORY_SUCCESS,
  FETCH_PIC_SUCCESS,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_SOURCE_SUCCESS,
  FETCH_CITY_SUCCESS,
  FETCH_DISTRICT_SUCCESS,
  FETCH_RETAILER_NAME_SUCCESS,
  FETCH_RETAILER_CODE_SUCCESS,
  FETCH_BRAND_SUCCESS,
  FETCH_STOCKIST_CODE_SUCCESS,
  FETCH_LAND_SIZE_SUCCESS,
  FETCH_MOBILE_NUMBER_SUCCESS,
  FETCH_ACARES_SUCCESS,
  FETCH_TMHQ_SUCCESS,
  FETCH_CHANNEL_SUCCESS,
  FETCH_CLASSIFICATION_SUCCESS,
  FETCH_SEGMENT_SUCCESS,
  FETCH_PROVINCE_SUCCESS,
  FETCH_PRAMUKHTAG_SUCCESS,
  FETCH_STATE_SUCCESS,
  FETCH_VILLAGE_SUCCESS,
  FETCH_TALUKA_SUCCESS,
  FETCH_MDOHQ_SUCCESS,
  FETCH_TVC_SUCCESS,
  FETCH_WA_SUCCESS,
  FETCH_FB_SUCCESS,
  FETCH_YB_SUCCESS,
  FETCH_IG_SUCCESS,
  FETCH_SYT_GROWER_CORN_SUCCESS,
  FETCH_SYT_GROWER_RICE_SUCCESS,
  FETCH_PREFFERED_HYBRID_CORN_SUCCESS,
  FETCH_PREFFERED_HYBRID_RICE_SUCCESS,
  FETCH_SEASON_SEGEMENTATION_SUCCESS,
  FETCH_FACEBOOK_SUCCESS,
  FETCH_ZALO_SUCCESS,
  FETCH_SYNGENTA_PRODUCT_SUCCESS,
  FETCH_ACTIVITITY_TYPE_SUCCESS
} from '../constants/index';
import { fetchLanguage } from '../services/groupFilterService';

export function fetchRegionCropPending() {
  return {
    type: FETCH_REGION_CROP_PENDING
  };
}
export function fetchActivityTypeSuccess(activityType) {
  return {
    type: FETCH_ACTIVITITY_TYPE_SUCCESS,
    payload:activityType
  };
}

export function fetchRegionCropSuccess(region) {
  return {
    type: FETCH_REGION_CROP_SUCCESS,
    payload: region
  };
}
export function fetchCropSuccess(crops) {
  return {
    type: FETCH_CROP_SUCCESS,
    payload: crops
  };
}
export function fetchZoneSuccess(zone) {
  return {
    type: FETCH_ZONE_SUCCESS,
    payload: zone
  };
}
export function fetchAreaSuccess(area) {
  return {
    type: FETCH_AREA_SUCCESS,
    payload: area
  };
}
export function fetchAcaresSuccess(acare) {
  return {
    type: FETCH_ACARES_SUCCESS,
    payload: acare
  };
}
export function fetchRegionCropError(error) {
  return {
    type: FETCH_REGION_CROP_ERROR,
    payload: error
  };
}
export function fetchTagsSuccess(tags) {
  return {
    type: FETCH_TAGS_SUCCESS,
    payload: tags
  };
}
export function fetchFranchiseCodeSuccess(code) {
  return {
    type: FETCH_FRANCHISE_CODE_SUCCESS,
    payload: code
  };
}
export function fetchCropHistorySuccess(cropHistory) {
  return {
    type: FETCH_CROP_HISTORY_SUCCESS,
    payload: cropHistory
  };
}
export function fetchPicSuccess(pic) {
  return {
    type: FETCH_PIC_SUCCESS,
    payload: pic
  };
}
export function fetchLanguageSuccess(language) {
  return {
    type: FETCH_LANGUAGE_SUCCESS,
    payload: language
  };
}
export function fetchSourceSuccess(source) {
  return {
    type: FETCH_SOURCE_SUCCESS,
    payload: source
  };
}
export function fetchChannelSuccess(channel) {
  return {
    type: FETCH_CHANNEL_SUCCESS,
    payload: channel
  };
}
export function fetchCitySuccess(city) {
  return {
    type: FETCH_CITY_SUCCESS,
    payload: city
  };
}
export function fetchDistrictSuccess(district) {
  return {
    type: FETCH_DISTRICT_SUCCESS,
    payload: district
  };
}
export function fetchRetailerNameSucess(retailerName) {
  return {
    type: FETCH_RETAILER_NAME_SUCCESS,
    payload: retailerName
  };
}
export function fetchRetailerCodeSucess(retailerCode) {
  return {
    type: FETCH_RETAILER_CODE_SUCCESS,
    payload: retailerCode
  };
}
export function fetachBrandSucess(brand) {
  return {
    type: FETCH_BRAND_SUCCESS,
    payload: brand
  };
}
export function fetachStockistCodeSucess(stockistCode) {
  return {
    type: FETCH_STOCKIST_CODE_SUCCESS,
    payload: stockistCode
  };
}
export function fetachLandSizeSucess(stockistCode) {
  return {
    type: FETCH_LAND_SIZE_SUCCESS,
    payload: stockistCode
  };
}
export function fetachTMHQSucess(tmhq) {
  return {
    type: FETCH_TMHQ_SUCCESS,
    payload: tmhq
  };
}
export function fetchMobileNumberSucess(number) {
  return {
    type: FETCH_MOBILE_NUMBER_SUCCESS,
    payload: number
  };
}
export function fetchClassificationSuccess(classification) {
  return {
    type: FETCH_CLASSIFICATION_SUCCESS,
    payload: classification
  };
}

export function fetchSegmentSuccess(segment) {
  return {
    type: FETCH_SEGMENT_SUCCESS,
    payload: segment
  };
}
export function fetchProvinceSuccess(province) {
  return {
    type: FETCH_PROVINCE_SUCCESS,
    payload: province
  };
}

export function fetchPramukhTagSuccess(pramukhTag) {
  return {
    type: FETCH_PRAMUKHTAG_SUCCESS,
    payload: pramukhTag
  };
}
export function fetchStateSuccess(state) {
  return {
    type: FETCH_STATE_SUCCESS,
    payload: state
  };
}

export function fetchVillageSuccess(village) {
  return {
    type: FETCH_VILLAGE_SUCCESS,
    payload: village
  };
}
export function fetchTalukaSuccess(taluka) {
  return {
    type: FETCH_TALUKA_SUCCESS,
    payload: taluka
  };
}
export function fetchMdohqSuccess(mdohq) {
  return {
    type: FETCH_MDOHQ_SUCCESS,
    payload: mdohq
  };
}
export function fetchTVCSuccess(tvc) {
  return {
    type: FETCH_TVC_SUCCESS,
    payload: tvc
  };
}
export function fetchWASuccess(wa) {
  return {
    type: FETCH_WA_SUCCESS,
    payload: wa
  };
}
export function fetchFBSuccess(fb) {
  return {
    type: FETCH_FB_SUCCESS,
    payload: fb
  };
}
export function fetchYBSuccess(yb) {
  return {
    type: FETCH_YB_SUCCESS,
    payload: yb
  };
}
export function fetchIGSuccess(ig) {
  return {
    type: FETCH_IG_SUCCESS,
    payload: ig
  };
}
export function fetchFacebookSuccess(facebook) {
  return {
    type: FETCH_FACEBOOK_SUCCESS,
    payload: facebook
  };
}
export function fetchZaloSuccess(zalo) {
  return {
    type: FETCH_ZALO_SUCCESS,
    payload: zalo
  };
}
export function fetchSyngentaProductSucess(syngentaProduct) {
  return {
    type: FETCH_SYNGENTA_PRODUCT_SUCCESS,
    payload: syngentaProduct
  };
}
export function fetchseasonSegmentationSuccess(seasonSegmentation) {
  return {
    type: FETCH_SEASON_SEGEMENTATION_SUCCESS,
    payload: seasonSegmentation
  };
}
export function fetchsytGrowerCornSuccess(sytGrowerCorn) {
  return {
    type: FETCH_SYT_GROWER_CORN_SUCCESS,
    payload: sytGrowerCorn
  };
}
export function fetchprefferedHybridCornSuccess(prefferedHybridCorn) {
  return {
    type: FETCH_PREFFERED_HYBRID_CORN_SUCCESS,
    payload: prefferedHybridCorn
  };
}
export function fetchsytGrowerRiceSuccess(sytGrowerRice) {
  return {
    type: FETCH_SYT_GROWER_RICE_SUCCESS,
    payload: sytGrowerRice
  };
}
export function fetchprefferedHybridRiceSuccess(prefferedHybridRice) {
  return {
    type: FETCH_PREFFERED_HYBRID_RICE_SUCCESS,
    payload: prefferedHybridRice
  };
}

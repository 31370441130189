export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'pklogin',
    loginButtonStyle: 'pkLoginbtn',
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Acres)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Taluka'),
          fieldName: 'Taluka',
          initialValue: 'taluka',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter taluka'),
            whitespace: true
          }
        },
        {
          label: t('Zone'),
          fieldName: 'Zone',
          initialValue: 'zone',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter zone'),
            whitespace: true
          }
        },
        {
          label: t('Area'),
          fieldName: 'area',
          initialValue: 'area',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter area'),
            whitespace: true
          }
        },
        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter district'),
            whitespace: true
          }
        },
        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Sales Unit'),
          fieldName: 'Unit',
          initialValue: 'unit',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter sales unit'),
            whitespace: true
          }
        },
        {
          label: t('Franchisee'),
          fieldName: 'franchisee',
          initialValue: 'franchisee',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter franchisee'),
            whitespace: true
          }
        },
        {
          label: t('Franchisee Code'),
          fieldName: 'frnCode',
          initialValue: 'frnCode',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter franchisee code'),
            whitespace: true
          }
        }
      ]
    }
  };
};

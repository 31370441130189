import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
  }
  handleChange = (dateString) => {
    const value = moment(dateString).format('YYYY-MM-DD');
    this.props.callback(value);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item style={{marginTop:'8px'}}>
      
          <DatePicker
          
            format='YYYY-MM-DD'
            placeholder='Select Date'
           
            onChange={(dateString) => {
              this.handleChange(dateString);
            }}
          />
        
      </Form.Item>
    );
  }
}

export default SelectDate;

import React from 'react';
import { Form, Input, InputNumber } from 'antd';
import { number } from 'prop-types';

class SearchAcares extends React.Component {
  constructor(props) {
    super(props);
    this.kayvalue;

    this.state = {
      keyValue: ''
    };
  }
  handleChange = (user, event) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    if (event.target.value.length == 0) {
      currentUser.value = [];
    } else {
      currentUser.value[0] = event.target.value;
    }
    this.props.callback(newUsers);
  };
countDecimals=(value)=> {
    if(Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
}
onKeyDownEvent = (e)=>{
 if(this.countDecimals(e.target.value) > 1 ){
   e.preventDefault();
  return false
 }
}
  validateField = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  avoidSpace = (event) => {
    let key = event ? event.which : event.keyCode;
    this.setState({
      keyValue: key
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, searchHectaresMessage } = this.props;
    return (
      <Form.Item>
        {getFieldDecorator(`${this.props.acresValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: searchHectaresMessage && searchHectaresMessage.message
            },
            {
              validator: this.validateField
            }
          ]
        })(
          <Input
            name='acres'
            type='number'
            size='small'
            min='0'
            step='0.01'
            placeholder={searchHectaresMessage && searchHectaresMessage.placeholder}
            onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
            onKeyPress={(evt) => this.onKeyDownEvent(evt) }
            value={this.props.user.value[0] || ''}
            onChange={(event) => {
              this.handleChange(this.props.user, event);
            }}
          />
        )}
      </Form.Item>
    );
  }
}

export default SearchAcares;

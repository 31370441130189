import React from 'react';
import { Drawer, Form, Button, Col, Row, Select, Tooltip, Card, Icon } from 'antd';
import { connect } from 'react-redux';
import TitleSection from '../../commonComponents/TitleSection/titleSection';
import { fetchUsersWithFetchAPI } from '../../../services/growerListService';
import { get } from 'lodash';
import './advanceSearch.css';
const { Option } = Select;
// const filterData = ['Region', 'Zone', 'Acres', 'Crop', 'Tags', 'frnCode','CropHistory'];
// const dropDownMenu = {
//   Region: ['in'],
//   Acres: ['equals to', 'greater than', 'less than'],
//   Zone: ['in'],
//   Crop: ['in'],
//   Tags: ['in'],
//   frnCode: ['in'],
//   CropHistory: ['in']
// };
import {
  fetchSearchResult,
  clearSearchResult,
  setSearchState
} from '../../../actions/advanceSearch';
import { downloadSearchResult } from '../../../actions/downloadSearchResult';

import {
  PlusIconCircle,
  MinusIconCircle,
  SearchIconCircle,
  RefreshIcon
} from '../../_layouts/drawer/drawerIcons';
import SearchAcares from './searchAcares';
import SearchRegion from './searchRegion';
import SearchCrop from './searchCrop';
import SearchZone from './searchZone';
import SearchArea from './searchArea';
import SearchTags from './searchTags';
import SearchCode from './searchFrenchiseCode';
import SearchCropHistory from './searchCropHistory';
import SearchPic from './searchPic';
import SearchSource from './searchSource';
import SearchCity from './searchCity';
import SearchDistrict from './searchDistrict';
import SearchRetailerName from './searchRetailerName';
import SearchRetailerCode from './searchRetailerCode';
import SearchBrand from './searchBrand';
import SearchLandSize from './searchLandSize';
import SearchStockistCode from './searchStockistCode';
import SearchMobileNumber from './searchMobileNumber';
import SearchTHMQ from './searchTMHQ';
import SearchChannel from './searchChannel';
import SearchClassification from './searchClassification';
import SearchSegment from './searchSegment';
import SearchProvince from './searchProvince';
import OptInOptOut from './searchOptInOptOut';
import SearchPramukhTag from './searchPramukhTag';
import SearchState from './searchState';
import SearchVillage from './searchVillage';
import SearchTaluka from './searchTaluka';
import SearchMDOHQ from './searchMDOHQ';
import SearchFB from './searchFB';
import SearchYB from './searchYB';
import SearchIG from './searchIG';
import SearchTVC from './searchTVC';
import SearchWA from './searchWA';
import SearchSytGrowerCorn from './searchSytGrowerCorn';
import SearchSytGrowerRiceNew from './searchSytGrowerRiceNew';
import SearchSeasonSegementation from './searchSeasonSegementation';
import SearchPrefferedCorn from './searchPrefferedCorn';
import SearchPrefferedRice from './searchPrefferedRice';
import SearchUpdatedDate from './searchUpdatedDate';
import SearchFacebook from './searchFacebook';
import SearchSyngentaProduct from './searchSyngentaProduct';
import SearchZalo from './searchZalo';
import SearchActivityType from './searchActivityType';

let count = 1;
class DrawerForm extends React.Component {
  constructor(props) {
    super(props);

    this.filterRegion = [];
    this.renderFilter;
    this.toggleButton;
    this.downloadButton;
    this.state = {
      // users: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      users: props.defaulState,
      errors: '',
      predicates: props.dropDownMenu[props.filterData[0].key],
      secondPredicates: props.dropDownMenu[props.filterData[0].key][0].key
    };
  }

  getRow = ({ Form, searchOptions, id, user, index }) => {
    this.filterRegion = {
      Region: (
        <SearchRegion
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          regionValue={'region' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      Crop: (
        <SearchCrop
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          errors={this.state.errors}
          cropValue={'crop' + id}
          errorCallback={this.errorCallback}
        />
      ),
      Acres: (
        <SearchAcares
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          acresValue={'acres' + id}
          form={this.props.form}
          errors={this.state.errors}
        />
      ),
      Zone: (
        <SearchZone
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          zoneValue={'zone' + id}
          errors={this.state.errors}
          searchZone={this.props.searchZone}
        />
      ),
      Area: (
        <SearchArea
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          areaValue={'area' + id}
          errors={this.state.errors}
          searchArea={this.props.searchArea}
        />
      ),
      Tags: (
        <SearchTags
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          tagsValue={'tags' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      frnCode: (
        <SearchCode
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          codeValue={'code' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      CropHistory: (
        <SearchCropHistory
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          cropHistoryValue={'cropHistory' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
          searchCropHistoryMessage={this.props.searchCropHistoryMessage}
        />
      ),
      Pic: (
        <SearchPic
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          picValue={'pic' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      salesUnit: (
        <SearchSource
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          sourceValue={'source' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      city: (
        <SearchCity
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          cityValue={'city' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      activitiesType: (
        <SearchActivityType
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          activityTypeValue={'SearchActivityType' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      district: (
        <SearchDistrict
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          districtValue={'city' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      retailerName: (
        <SearchRetailerName
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          retailerValue={'retailer' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      retailerCode: (
        <SearchRetailerCode
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          retailerCodeValue={'retailer' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      brand: (
        <SearchBrand
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          brandValue={'brand' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      landSize: (
        <SearchLandSize
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          landSizeValue={'landSize' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      // salesUnit: (
      //   <SearchStockistCode
      //     callback={(newUsers) => {
      //       this.setState({
      //         users: newUsers
      //       });
      //     }}
      //     {...this.props}
      //     users={this.state.users}
      //     user={user}
      //     form={this.props.form}
      //     stockistCodeValue={'stockistCode' + id}
      //     errors={this.state.errors}
      //     errorCallback={this.errorCallback}
      //   />
      // ),
      tmhq: (
        <SearchTHMQ
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          mobileNumber={'tmhq' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
          searchTmhqMessage={this.props.searchTmhqMessage}
        />
      ),
      mobileNumber: (
        <SearchMobileNumber
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          mobileNumber={'mobileNumber' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      channel: (
        <SearchChannel
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          channelValue={'channel' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      classification: (
        <SearchClassification
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          classificationValue={'classification' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      segment: (
        <SearchSegment
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          segmentValue={'segment' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      province: (
        <SearchProvince
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          provinceValue={'province' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      PramukhTag: (
        <SearchPramukhTag
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          pramukhTagValue={'pramukhTag' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      OptIn: (
        <OptInOptOut
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          provinceValue={'province' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      state: (
        <SearchState
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          stateValue={'state' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      village: (
        <SearchVillage
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          villageValue={'village' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      taluka: (
        <SearchTaluka
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          talukaValue={'taluka' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      mdohq: (
        <SearchMDOHQ
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          mdohqValue={'mdohq' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      TVC: (
        <SearchTVC
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          tvcValue={'tvc' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      WA: (
        <SearchWA
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          waValue={'wa' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      FB: (
        <SearchFB
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          fbValue={'fb' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      YB: (
        <SearchYB
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          ybValue={'yb' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      IG: (
        <SearchIG
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          igValue={'ig' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      sytGrowerCorn: (
        <SearchSytGrowerCorn
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          sytCornValue={'sytGrowerCorn' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      sytGrowerRice: (
        <SearchSytGrowerRiceNew
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          sytRiceValue={'sytGrowerRice' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      sytPrefferedCorn: (
        <SearchPrefferedCorn
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          prefferedCornValue={'sytPrefferedCorn' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      sytPrefferedRice: (
        <SearchPrefferedRice
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          prefferedRiceValue={'sytPrefferedRice' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      seasonSegementation: (
        <SearchSeasonSegementation
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          seasonSegementationValue={'seasonSegementation' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      updatedDate: (
        <SearchUpdatedDate
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          updateValue={'updateDate' + id}
          errors={this.state.errors}
        />
      ),
      createdDate: (
        <SearchUpdatedDate
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          updateValue={'createdDate' + id}
          errors={this.state.errors}
        />
      ),

      facebook: (
        <SearchFacebook
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          facebookValue={'facebook' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      zalo: (
        <SearchZalo
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          zaloValue={'zalo' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      ),
      syngentaProductUsing: (
        <SearchSyngentaProduct
          callback={(newUsers) => {
            this.setState({
              users: newUsers
            });
          }}
          {...this.props}
          users={this.state.users}
          user={user}
          form={this.props.form}
          syngentaProductValue={'syngentaProductUsing' + id}
          errors={this.state.errors}
          errorCallback={this.errorCallback}
        />
      )
    };

    this.renderFilter = this.filterRegion[this.state.users[index].field.name];
    const data = this.props.dropDownMenu[user.field.name];
    const operatorOptions = data.map((Operators) => (
      <Option key={Operators.key}>{Operators.value}</Option>
    ));
    return (
      <Row gutter={16} id={id}>
        <Col span={7}>
          <Form.Item>
            <Select
              size='small'
              style={{ width: '100%', border: '1 solid black' }}
              placeholder='select value'
              onChange={(value) => {
                this.handleProvinceChange(value, user);
              }}
              defaultValue={this.props.filterData[0].value}
              className='SelectBox'
              value={user.field.name || 'Select Options'}>
              {searchOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item>
            <Select
              size='small'
              style={{ width: '100%' }}
              placeholder='select value'
              onChange={(value) => {
                this.onSecondCityChange(value, user);
              }}
              value={user.operator.name || 'Select Options'}
              className='SelectBox'>
              {operatorOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>{this.renderFilter}</Col>
        <Col span={1}>
          <Form.Item>
            <Button
              id={id}
              type='default'
              size='small'
              onClick={() => {
                this.removeClick(id);
              }}
              className='removeButton'>
              <MinusIconCircle />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  handleProvinceChange = (value, user) => {
    const newUsers = [...this.state.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.field.name = value;
    currentUser.operator.name = this.props.dropDownMenu[`${value}`][0].key;

    this.setState({
      users: newUsers
    });
  };
  onSecondCityChange = (value, user) => {
    const newUsers = [...this.state.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.operator.name = value;
    this.setState({
      users: newUsers
    });
  };
  errorCallback = () => {
    this.setState({
      errors: []
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { users } = this.state;
    this.props.setSearchState(users);
    let isValid = true;
    this.props.form.validateFields((err, values) => {

      if (!err) {
        this.props.fetchSearchResult(0, users);
       
        // setTimeout(
        //   function() {
        this.props.downloadSearchResult(users);
        //   }.bind(),
        //   3000
        // );
        this.props.resetPage();
        this.setState({
          visible: false
        });
      } else {
      }
    });
  };

  addClick = () => {
    if (this.state.users.length > 7) {
      return;
    }
    this.setState((prevState) => ({
      users: [
        ...prevState.users,
        {
          id: ++count,
          field: { name: this.props.addNewFilter },
          operator: { name: 'in' },
          value: []
        }
      ]
    }));
  };

  removeClick = (id) => {
    let users = [...this.state.users];
    let removeItem = users.filter((el) => el.id != id);
    this.setState({
      users: removeItem
    });
  };
  clearAllFilters = (users) => {
    this.setState({ users: [] });
  };
  downloadSerchResult = () => {
    const { users } = this.state;
    this.props.fetchSearchResult(0, users);
  };
  downloadSourceResult = () => {
    this.props.downloadSourceResult();
  };
  createUI(searchOptions, users) {
    return users.map((user, i) => (
      <div key={user.id}>
        {this.getRow({
          Form,
          searchOptions,
          user,
          index: i,
          id: user.id
        })}
      </div>
    ));
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };
  realoadButton = () => {
    this.props.clearSearchResult();
    this.props.resetPage();
    this.props.fetchUsersWithFetchAPI(0);
  };

  render() {
    const { t } = this.props;
    const title = t('Find Grower');
    let reload = this.props.searchResults || this.props.searchResults;
    const searchOptions = this.props.filterData.map((province) => (
      <Option key={province.key}>{province.value}</Option>
    ));
    this.toggleButton = reload ? (
      <Button className='searchButton' onClick={this.realoadButton}>
        {/* <RefreshIcon /> */}
        {/* <Icon type='clear' /> */}
        {t('Clear Filters')}
      </Button>
    ) : (
      ''
    );
    this.downloadButton = reload ? (
      <Button
        className='searchButton'
        target='_blank'
        title='Download'
        disabled={this.props.downloadResultCode !== '200'}
        href={this.props.downloadResult}
        // onClick={this.downloadSerchResult}
      >
        <Icon type='download' />
        {t('Download Search Result')}
      </Button>
    ) : (
      ''
    );
    return (
      <div className='SearchButton'>
        <TitleSection title={title} />
        <span className='RealoadButton'>{this.toggleButton}</span>
        <span className='downloadButton'>{this.downloadButton}</span>
        {this.props.country === 'idn' ? (
          <span>
            {!reload ? (
              <Button
                className='searchSourceButton'
                // onClick={this.downloadSourceResult}
                disabled={!this.props.sourceCountResult.length > 0}
                href={this.props.sourceCountResult}>
                <Icon type='download' />
                {t('Source Count')}
              </Button>
            ) : (
              ''
            )}
          </span>
        ) : (
          ''
        )}
        <div>
          <Button onClick={this.showDrawer} className='searchButton'>
            <SearchIconCircle />
            {t('Search')}
          </Button>
          {/* <Button className='searchSourceButton'>
            <Icon type='download' />
            {t('Download Source Data')}
          </Button> */}
        </div>

        <Drawer
          title={t('Advanced Search')}
          width={720}
          onClose={this.onClose}
          maskClosable={false}
          visible={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}>
          <Form layout='vertical' name='control-hooks' onSubmit={this.handleSubmit}>
            {this.createUI(searchOptions, this.state.users)}
            <Button size='small' onClick={this.addClick} className='addButton'>
              <PlusIconCircle />
            </Button>
            <div
              style={{
                position: 'absolute',
                right: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right'
              }}>
              <Button
                onClick={this.clearAllFilters}
                style={{ marginRight: 8 }}
                disabled={!this.state.users.length}>
                {t('Clear Filters')}
              </Button>
              <Button
                onClose={this.onClose}
                className='submitBtn'
                htmlType='submit'
                disabled={!this.state.users.length}>
                {t('Search')}
              </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchSearchResult: (pagenum, users) => dispatch(fetchSearchResult(pagenum, users)),
    clearSearchResult: () => dispatch(clearSearchResult()),
    fetchUsersWithFetchAPI: (num) => dispatch(fetchUsersWithFetchAPI(num)),
    setSearchState: (users) => dispatch(setSearchState(users)),
    downloadSearchResult: (users) => dispatch(downloadSearchResult(users)),
   
    // fetchUsersWithFetchAPI:(0) => dispatch(fetchUsersWithFetchAPI(0))
  };
};
const mapStateToProps = function(state) {
  return {
    searchResult: get(state, 'searchList.data.advanceSearchResult.items', []),
    serachError: get(state, 'searchList.data.searchListError', ''),
    searchResults: get(state, 'searchList.data.advanceSearchResult.resultArr', ''),
    searchStatus: get(state, 'searchList.data.advanceSearchResult.status', ''),
    downloadResult: get(state, 'downloadSearchResult.data.downloadSearchResult.message', ''),
    downloadResultCode: get(state, 'downloadSearchResult.data.downloadSearchResult.code', ''),
    sourceCountResult: get(state, 'downloadSourceResult.data.downloadSourceResult.url', ''),
    sourceCountResultCode: get(state, 'downloadSourceResult.data.downloadSourceResult.code', '')
  };
};
const DrawerData = Form.create()(DrawerForm);
export default connect(mapStateToProps, mapDispatchToProps)(DrawerData);

import React, { Component } from 'react';
import { Layout, Card,  Button, Input, Select, Form,Icon } from 'antd';
import { connect } from 'react-redux';
import { RegionalMetaContext } from '../../context/region';

import TitleSection from '../commonComponents/TitleSection/titleSection';
import { StyledCard } from '../utility/utilityStyle';
import { downloadReportResult } from '../../actions/downloadReport';
import SelectDate from './selectDate';
import _get from 'lodash.get';

const { Option } = Select;
/*** Ant Design Components */
const { Content, Header } = Layout;
const { Meta } = Card;
const { Search } = Input;

class DownloadReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: '',
    };
  }
  handleChange = (val) => this.setState({ value: val });
  handleSubmit = (e) => {
    e.preventDefault();
    const { date } = this.state;
        this.props.downloadReportResult(date);
  };
  render() {
    return (
      <RegionalMetaContext.Consumer>
        {({ data }) => (
          <Layout className='utilityLayout'>
            <>
              <Content>
                <TitleSection title=' Download Communication Report' />
              </Content>
              {/* <StyledCard bordered={false}> */}
                <Form style={{display:'flex'}} onSubmit={this.handleSubmit}>
                <SelectDate form={this.props.form} 
                callback={(newDate) => {
            this.setState({
              date: newDate
            });
          }}/>
                  <Form.Item style={{paddingLeft:'40px'}}>
                   
                  <Button
                onClose={this.onClose}
                className='submitBtn'
                htmlType='submit'
                disabled={!this.state.date.length}
                >
                Search
              </Button>
             
                
              </Form.Item>
              <Form.Item style={{marginTop:'8px', paddingLeft:'40px'}}>
              <Button
                target='_blank'
                 disabled={!this.props.downloadReport.length > 0}
                 href={this.props.downloadReport}
                >
                <Icon type='download' />
                Download Report
              </Button>
              </Form.Item>
                </Form>
              {/* </StyledCard> */}
            </>
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}

const DownloadReportForm = Form.create()(DownloadReport);
const mapDispatchToProps = function(dispatch) {
    return {
        downloadReportResult:(users) => dispatch(downloadReportResult(users))
     
    };
  };
  const mapStateToProps = (state) => {
    return {
      downloadReport: _get(state, 'downloadReportResult.data.downloadReport.message', ''),
     
    };
  };
 
  export default connect(mapStateToProps, mapDispatchToProps)(DownloadReportForm);

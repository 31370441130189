import {
    YOUTUBE_LIST_PENDING,
    YOUTUBE_LIST_SUCCESS,
    YOUTUBE_LIST_FAILURE,
    YOUTUBE_LIST_LOADING
  } from '../constants/index';
  
  const initialState = {
    data: {
     youTubeList: {},
     youTubeListError: {},
     youTubeListStatus: '',
      completed: false,
      loading: true
    }
  };
  
  function youTubeLists(state = initialState, action) {
    switch (action.type) {
      case YOUTUBE_LIST_PENDING:
        return {
          data: {
            ...state.data,
            completed: true
          }
        };
      case YOUTUBE_LIST_SUCCESS:
        return Object.assign({}, state, {
          data: {
            ...state.data,
           youTubeList: action.payload,
            completed: false
          }
        });
      case YOUTUBE_LIST_FAILURE:
        return Object.assign({}, state, {
          data: {
            ...state.data,
           youTubeListError: action.payload,
            completed: false
          }
        });
      case YOUTUBE_LIST_LOADING:
        return {
          data: {
            ...state.data,
            loading: action.payload
          }
        };
      default:
        return state;
    }
  }
  
  export default youTubeLists;
  
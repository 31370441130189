import {
  GROWER_UPDATE_PENDING,
  GROWER_UPDATE_SUCCESS,
  GROWER_UPDATE_FAILURE,
  GROWER_UPDATE_LOADING,
  HIGHLIGHT_GROWER_TABEL_ROW,
  UPDATE_GROUP_DRAWER,
  CLEAR_GROWER_UPDATE
} from '../constants/index';

const initialState = {
  growerUpdateSucess: [],
  growerUpdateError: [],
  growerUpdatePending: false,
  loading: false,
  highlightGrowerRow: false,
  visible: false,
  updateDrawerVisible:false
};

function growerUpdate(state = initialState, action) {
  switch (action.type) {
    case GROWER_UPDATE_PENDING:
      return {
        ...state,
        growerUpdatePending: action.payload
      };
    case GROWER_UPDATE_SUCCESS:
      return {
        ...state,
        growerUpdateSucess: action.payload
      };
    case GROWER_UPDATE_FAILURE:
      return {
        ...state,
        growerUpdateError: action.payload
      };
    case UPDATE_GROUP_DRAWER:
      return {
        ...state,
        updateDrawerVisible: !state.updateDrawerVisible
      };
    case GROWER_UPDATE_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case HIGHLIGHT_GROWER_TABEL_ROW:
      return {
        ...state,
        highlightGrowerRow: action.payload
      };
    case CLEAR_GROWER_UPDATE:
      return {
        ...state,
        growerUpdateSucess: []
      };

    default:
      return state;
  }
}

export default growerUpdate;

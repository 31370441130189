import {
  MESSAGE_COUNT_LIST_PENDING,
  MESSAGE_COUNT_LIST_SUCCESS,
  MESSAGE_COUNT_LIST_FAILURE,
  MESSAGE_COUNT_LIST_LOADING
} from '../constants/index';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export const messageCountListLoading = (val) => {
  return { type: MESSAGE_COUNT_LIST_LOADING, payload: val };
};

export const messageCountListingPending = () => {
  return {
    type: MESSAGE_COUNT_LIST_PENDING
  };
};
export const messageCountListingSuccess = (value) => {
  return {
    type: MESSAGE_COUNT_LIST_SUCCESS,
    payload: value
  };
};

export const messageCountListingFailure = (error) => {
  return {
    type: MESSAGE_COUNT_LIST_FAILURE,
    payload: error
  };
};
export const getMessageCountList = (startDate, endDate) => {
  const apiUri = formatUrl(config.messageCountList, startDate, endDate);
  return (dispatch) => {
    dispatch(messageCountListingPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(messageCountListingSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(messageCountListingFailure(error));
      });
  };
};

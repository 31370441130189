import React, { Component } from 'react';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import ReceivedChatMessages from './ReceivedChatMessages';

// const URL = 'ws://localhost:';
var stompClient = null;
class Chat extends Component {
  state = {
    name: 'Pravin',
    messages: [],
    ReceivedMessages: [],
    ReceiverName: '',
    checkConnect: false
  };

  //   ws = new WebSocket(URL);

  connect = () => {
    const Stomp = require('stompjs');
    var SockJS = require('sockjs-client');
    SockJS = new SockJS('https://dev-msg-api.grower-console.syn-dpe.com/ws');
    stompClient = Stomp.over(SockJS);
    stompClient.connect(
      {},
      setTimeout(() => {
        this.onConnected();
      }, 5000),
      this.onError()
    );
  };
  onConnected = () => {
    if (stompClient.connected == true) {
      stompClient.subscribe('/topic/chat', this.onMessageReceived);
    } else {
      console.log('not subscribed');
    }
  };
  onError = (err) => {
    console.log('error', err);
  };
  onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);
    this.setState((state) => ({
      ReceivedMessages: [
        {
          name: message.contact.displayName,

          message: message.message.content.text
        }
      ]

      //   ReceiverName: message.contact.displayName,
      //   ReceivedMessages: [message.message.content.text, ...state.ReceivedMessages]
    }));
  };
  componentDidMount() {
    this.connect();
    // this.ws.onopen = () => {
    //   // on connecting, do nothing but log it to the console
    //   console.log('connected');
    // };

    this.onmessage = (evt) => {
      // on receiving a message, add it to the list of messages
      const message = JSON.parse(evt.data);
      stompClient.send('/app/chat', {}, JSON.stringify(message));
    };

    // this.ws.onclose = () => {
    //   console.log('disconnected');
    //   // automatically try to reconnect on connection loss
    //   this.setState({
    //     ws: new WebSocket(URL)
    //   });
    // };
  }

  addMessage = (message) => this.setState((state) => ({ messages: [message, ...state.messages] }));

  submitMessage = (messageString) => {
    // on submitting the ChatInput form, send the message, add it to the list and reset the input
    const message = { name: this.state.name, content: messageString };
    // stompClient.send(JSON.stringify(message));
    stompClient.send('/app/chat', {}, JSON.stringify(message));
    this.addMessage(message);
  };

  render() {
    return (
      <>
        <div style={{ padding: '15px' }}>
          <label htmlFor='name' style={{ color: '#000' }}>
            Name:&nbsp;
            <input
              type='text'
              id={'name'}
              placeholder={'Enter your name...'}
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </label>
          <ChatInput
            ws={this.ws}
            onSubmitMessage={(messageString) => this.submitMessage(messageString)}
          />
          {this.state.messages.map((message, index) => (
            <ChatMessage key={index} message={message.content} name={message.name} />
          ))}
          {this.state.ReceivedMessages.map((message, index) => (
            <ReceivedChatMessages key={index} message={message.message} name={message.name} />
          ))}
        </div>
      </>
    );
  }
}

export default Chat;

import {
  FETCH_BRAND_PENDING,
  FETCH_BRAND_SUCCESS,
  FETCH_BRAND_ERROR,
  SESSION_TIMEOUT_ERROR
} from '../constants';

const initialState = {
  data: {
    brandResult: {},
    brandError: {},
    visible: false,
    completed: false
  }
};

function brandList(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRAND_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_BRAND_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          brandResult: action.payload,
          completed: false
        }
      });

    case FETCH_BRAND_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          brandError: action.payload,
          completed: false
        }
      });
    case SESSION_TIMEOUT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          visible: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default brandList;

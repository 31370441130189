import React from 'react';
import { notification } from 'antd';

export const alertBox = (data) => {
  return (
    <div>
      {notification[data.type]({
        message: data.message
      })}
    </div>
  );
};

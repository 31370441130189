import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Icon, Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import './webstore.css';

const { Header } = Layout;
const { Title } = Typography;
function WebStoreTitleSection(props) {
  return (
    <Header className='webstoreTitleDiv'>
      <Row style={{ width: '100%', padding: '0 10px' }}>
        <Col span={12} style={{ textAlign: 'start' }}>
          <div className='webstoreLeftLink'>
            <Title
              className='webStoreTitle'
              level={4}
              style={{ color: '#238661', fontWeight: 'bold' }}>
              {props.pagetitle}
            </Title>

            <Link to='/on-board-growers' className='addmoreGrowers'>
              <Icon type='plus' />
              <span>
                <Translation>{(t) => <>{t('add more growers')}</>}</Translation>
              </span>
            </Link>
          </div>
        </Col>
        <Col span={12} style={{ textAlign: 'end' }}>
          <div className='webstoreRightLink'>
            <Title level={4} style={{ color: '#238661', fontWeight: 'bold' }}>
              {props.connetectedUsersCount}
            </Title>
            <Link to='/find-grower' className='viewDetailsEngage'>
              <span>
                <Translation>{(t) => <>{t('View Details')}</>}</Translation>
              </span>
            </Link>
          </div>
        </Col>
      </Row>
    </Header>
  );
}
WebStoreTitleSection.propTypes = {
  pagetitle: PropTypes.string,
  connetectedUsersCount: PropTypes.number
};
export default WebStoreTitleSection;

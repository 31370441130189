import {
  WhATSAP_TEMPLATEDATA_PENDING,
  WhATSAP_TEMPLATEDATA_SUCESS,
  WhATSAP_TEMPLATEDATA_ERROR,
  WhATSAP_TEMPLATEDATA_CLEAR
} from '../constants';

const initialState = {
  data: {
    whatsAppTemplateData: {},
    whatsAppTemplateDataError: {},
    messageDrawerVisible: false,
    completed: false,
    loading: true,
    
  }
};

function whatsAppTemplateData(state = initialState, action) {
  switch (action.type) {
    case WhATSAP_TEMPLATEDATA_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case WhATSAP_TEMPLATEDATA_SUCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          whatsAppTemplateData: action.payload,
          completed: false
        }
      });
    case WhATSAP_TEMPLATEDATA_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          whatsAppTemplateDataError: action.payload,
          completed: false
        }
      });
    case WhATSAP_TEMPLATEDATA_CLEAR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          whatsAppTemplateData: []
        }
      });
    default:
      return state;
  }
}

export default whatsAppTemplateData;

import React, { Component } from 'react';
import {
  Table,
  Modal,
  Button,
  Popconfirm,
  Divider,
  Icon,
  Tooltip,
  List,
  Avatar,
  Card,
  Pagination,
  Typography
} from 'antd';
import { connect } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import _get from 'lodash.get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import ShowMore from 'react-show-more-button';
// import ShowMore from 'react-show-more';
// import ShowMoreText from 'react-show-more-text';
import ShowMoreText from './ShowMoreText';
import moment from 'moment';
import PropTypes from 'prop-types';
import GroupMemberData from './groupMemberList';
import { Translation } from 'react-i18next';
import { fetchGroupListWithFetchAPI } from '../../../services/groupListService';
import { deleteGroup } from '../../../actions/groupDelete';
import { defaultGrowers } from '../../../actions/growersAction';
import { fetchGroupMemberList, clearGroupMember } from '../../../actions/groupMember';
import { toggleGroupUpdateDrawer } from '../../../actions/groupUpdate';
import UpdateGroupDrawer from './GroupDrawer/updateGroupDrawer';
import './GrowerGroupData.css';
import { EyeOutlined } from '@ant-design/icons';
import datatable from '../../commonComponents/Datatabel/datatable';
import { StyledCard } from './CardGroupTableStyle';
import ReadMore from './ReadMore';
import Truncate from 'react-truncate';

import { filter } from '@antv/util';
const { Paragraph } = Typography;
const { Meta } = Card;
let operatorValues = {
  lessThanEqualTo: '<=',
  greaterThanEqualsTo: '>='
};

const listData = [];

class CardGroupData extends Component {
  constructor(props) {
    super(props);
    // this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      selectedRowKeys: [],
      isFetching: false,
      users: [],
      loading: false,
      pagination: {},
      visible: false,
      showMoreVisible: false,
      Rowdata: [],
      groupId: '',
      currentPage: 0,
      EditRowdata: [],
      showMore: false,
      deleteGroupStatus: false,
      overflow: false,
      expand: false,
      expanded: false,
      truncated: false,
      showMoreData: []
    };
    this.divRef = React.createRef(); // Create a ref object
  }
  showModal = (record) => {
    this.props.fetchGroupMemberList(0, record.key);
    // this.props.resetPage();
    this.setState({
      visible: true,
      Rowdata: record
    });
  };
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
    this.props.clearGroupMember();
    // this.props.resetPage();
    this.props.resetMemberPage();
  };
  onShowMore = (filter) => {
    this.setState({
      expand: false,
      overflow: !this.state.overflow,
      showMoreVisible: true,
      showMoreData: filter
    });
  };
  showMoreCancel = () => {
    this.setState({
      showMoreVisible: false
    });
  };
  handleTableChange = (pagination) => {
    this.props.setCurrentPage(pagination);
    this.props.fetchGroupListWithFetchAPI(pagination - 1);
  };
  getValues = (values, valueField) => {
    if (
      valueField === 'regions' ||
      valueField === 'crops' ||
      valueField === 'tags' ||
      valueField === 'cropHistory' ||
      valueField === 'language' ||
      valueField === 'zone' ||
      valueField === 'city' ||
      valueField === 'area' ||
      valueField === 'brand' ||
      valueField === 'retailerName' ||
      valueField === 'salesUnit' ||
      valueField === 'district' ||
      valueField === 'tmhq'
    ) {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).items.join(', ')) ||
        []
      );
    } else if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).value) ||
        ''
      );
    } else {
      return '';
    }
  };
  getEditGroupValues = (values, valueField) => {
    if (
      valueField === 'regions' ||
      valueField === 'crops' ||
      valueField === 'tags' ||
      valueField === 'cropHistory' ||
      valueField === 'language' ||
      valueField === 'zone' ||
      valueField === 'city' ||
      valueField === 'area' ||
      valueField === 'brand' ||
      valueField === 'retailerName' ||
      valueField === 'salesUnit' ||
      valueField === 'district' ||
      valueField === 'tmhq'
    ) {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).items) ||
        []
      );
    } else if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).value) ||
        ''
      );
    } else {
      return '';
    }
  };
  getOperatorValues = (values, valueField) => {
    if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).operator.name.trim()) ||
        ''
      );
    } else {
      return '';
    }
  };

  getWrapperWidth(filter) {
    if (this.wrapper) {
      console.log(
        'get wrapper width',
        window.getComputedStyle(this.wrapper).getPropertyValue('width')
      );
      this.onShowMore(filter);
    } else {
      console.log('get wrapper - no wrapper');
    }
  }
  handleTruncate = (truncated) => {
    if (this.state.truncated !== truncated) {
      this.setState({
        truncated
      });
    }
  };

  toggleLines = (event) => {
    event.preventDefault();
    this.setState({
      expanded: !this.state.expanded
    });
  };
  scrollToBottom = () => {
    if (this.state.deleteGroupStatus) {
      return false;
    }
    var scrollTableTop = document.querySelector('.ant-list-item');
    scrollTableTop &&
      scrollTableTop.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };
  showDrawerData = (record) => {
    this.props.toggleGroupUpdateDrawer();
    this.setState({
      EditRowdata: record
    });
  };
  deleteGroupData = (record) => {
    this.props.deleteGroup(
      record,
      this.props.currentPage,
      this.props.groupList,
      this.props.groupOffset
    );
    this.setState({
      deleteGroupStatus: true
    });
    // this.props.resetPage();
  };

  componentWillReceiveProps(newProps) {
    if (newProps.groupList !== this.props.groupList) {
      this.setState({
        deleteGroupStatus: false
      });
      this.scrollToBottom();
    }
  }
  componentDidUpdate = (prevProps, nextProps) => {
    if (!isEqual(prevProps.groupList, this.props.groupList) && isEmpty(this.props.groupList)) {
      if (this.props.groupList.length === 0 && this.props.currentPage > 0) {
        this.props.setCurrentPage(this.props.currentPage - 1);
      }
    }
  };
  componentDidMount() {
    this.props.fetchGroupListWithFetchAPI(0);
    // setTimeout(
    //   function () {
    //     const element = document.querySelector('.line-clamp');
    //     const myStyle1 = document.getElementsByClassName('.line-clamp');

    //     console.log('styles>', element); // rgb(0, 0, 0)
    //     if (
    //       element.offsetHeight < element.scrollHeight ||
    //       element.offsetWidth < element.scrollWidth
    //     ) {

    //       document.querySelector('#read-more').style.visibility = 'visible';
    //     }
    //   }.bind(),
    //   3000
    // );
  }

  render() {
    const {
      searchText,
      searchResults,
      groupList,
      currentPage,
      columns,
      memberColumns,
      t,
      country,
      deletedStatus
    } = this.props;
    const { expanded, truncated } = this.state;

    const data = [];

    const list = this.props.groupList;
    if (list) {
      list.forEach((value, index) => {
        let cropCheck = this.getValues(value.filters, 'crops').length;
        let valueCheck = this.getValues(value.filters, 'regions').length;
        let tagsValueCheck = this.getValues(value.filters, 'tags').length;
        let cropHistoryValueCheck = this.getValues(value.filters, 'cropHistory').length;
        let languageCheck = this.getValues(value.filters, 'language').length;
        let provinceCheck = this.getValues(value.filters, 'zone').length;
        let areaCheck = this.getValues(value.filters, 'area').length;
        let cityCheck = this.getValues(value.filters, 'city').length;
        let acresCheck = this.getValues(value.filters, 'acres').length;
        let retailerCheck = this.getValues(value.filters, 'retailerName').length;
        let stockistCheck = this.getValues(value.filters, 'salesUnit').length;
        let tmhqCheck = this.getValues(value.filters, 'tmhq').length;
        let brandCheck = this.getValues(value.filters, 'brand').length;
        let districtCheck = this.getValues(value.filters, 'district').length;
        data.push({
          key: value && value.id ? value.id : '',
          groupName: value && value.name ? value.name : '',
          groupType: value && value.type ? value.type : '',
          // noOfMembers: value && value.totalMembers ? value.totalMembers : 0,
          NonArray: value.filters ? value.filters : '',
          createdBy:
            (value && value.userCreated) || (value && value.createdBy)
              ? value.userCreated
                ? value.userCreated
                : value.createdBy
              : '',
          creationDate:
            (value && value.createdDate) || (value && value.created)
              ? moment
                  .utc(value && value.createdDate ? value.createdDate : value.created)
                  .local()
                  .format('lll')
              : '',
          modifiedBy:
            (value && value.lastModifiedBy) || (value && value.updatedBy)
              ? value.lastModifiedBy
                ? value.lastModifiedBy
                : value.updatedBy
              : '',
          modifiedDate:
            (value && value.lastModifiedDate) || (value && value.updated)
              ? moment
                  .utc(value.lastModifiedDate ? value.lastModifiedDate : value.updated)
                  .local()
                  .format('lll')
              : '',
          GrowersCrop: value.filters ? this.getEditGroupValues(value.filters, 'crops') : '',
          OperatorValue: value.filters ? this.getOperatorValues(value.filters, 'acres') : '',
          GrowersZone: value.filters ? this.getEditGroupValues(value.filters, 'zone') : '',
          acresValue: value.filters ? this.getEditGroupValues(value.filters, 'acres') : '',
          GrowersTags: value.filters ? this.getEditGroupValues(value.filters, 'tags') : '',
          GrowersCropHistory: value.filters
            ? this.getEditGroupValues(value.filters, 'cropHistory')
            : '',
          GrowersArea: value.filters ? this.getEditGroupValues(value.filters, 'area') : '',
          GrowersRegion: value.filters ? this.getEditGroupValues(value.filters, 'regions') : '',
          GrowersDistrict: value.filters ? this.getEditGroupValues(value.filters, 'district') : '',
          GrowersBrand: value.filters ? this.getEditGroupValues(value.filters, 'brand') : '',
          GrowersRetailerName: value.filters
            ? this.getEditGroupValues(value.filters, 'retailerName')
            : '',
          GrowersStockistCode: value.filters
            ? this.getEditGroupValues(value.filters, 'salesUnit')
            : '',
          GrowerLanguage: value.filters ? this.getEditGroupValues(value.filters, 'language') : '',
          GrowersCity: value.filters ? this.getEditGroupValues(value.filters, 'city') : '',
          GrowersTmhq: value.filters ? this.getEditGroupValues(value.filters, 'tmhq') : '',
          scrollFilter: value.filters ? value.filters.items : '',

          filters: (
            <div className='FilterLetter'>
              {cropCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crops')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {cropCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'crops') : ''}
              {cropCheck ? <br /> : ''}
              {valueCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Regions')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {valueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'regions') : ''}{' '}
              {valueCheck ? <br /> : ''}
              {acresCheck ? <b>Acres:</b> : ''}
              {acresCheck ? <span>&nbsp;</span> : ''}
              {acresCheck ? operatorValues[this.getOperatorValues(value.filters, 'acres')] : ''}
              {acresCheck ? this.getValues(value.filters, 'acres') : ''}
              {tagsValueCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Tags')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {tagsValueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'tags') : ''}{' '}
              {tagsValueCheck ? <br /> : ''}
              {cropHistoryValueCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crop History')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {cropHistoryValueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'cropHistory') : ''}{' '}
              {cropHistoryValueCheck ? <br /> : ''}
              {languageCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Language')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {languageCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'language') : ''}{' '}
              {languageCheck ? <br /> : ''}
              {provinceCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {provinceCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'zone') : ''}{' '}
              {provinceCheck ? <br /> : ''}
              {areaCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {areaCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'area') : ''} {areaCheck ? <br /> : ''}
              {cityCheck ? (
                <b>
                  <Translation>{(t) => <>{t('City')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {cityCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'city') : ''} {cityCheck ? <br /> : ''}
              {/* {acresCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Acre')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {acresCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'acres') : ''}{' '} */}
              {acresCheck ? <br /> : ''}
              {retailerCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Retailer Name')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {retailerCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'retailerName') : ''}{' '}
              {retailerCheck ? <br /> : ''}
              {brandCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Brand')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {brandCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'brand') : ''}{' '}
              {brandCheck ? <br /> : ''}
              {stockistCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Stockist Code')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {stockistCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'salesUnit') : ''}{' '}
              {stockistCheck ? <br /> : ''}
              {tmhqCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Sales Unit')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {tmhqCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'tmhq') : ''} {tmhqCheck ? <br /> : ''}
              {districtCheck ? (
                <b>
                  <Translation>{(t) => <>{t('District')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {districtCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'district') : ''}{' '}
              {districtCheck ? <br /> : ''}
            </div>
          )
        });
      });
    }
    const { Rowdata } = this.state;
    const pagination = {
      total: this.props.totalPages * 9
    };
    const getTotalPage = this.props.totalPages * 9;

    return (
      <>
        <div style={{ height: '80vh', overflow: 'auto' }}>
          <List
            // itemLayout='horizontal'
            grid={{ gutter: 16, column: 3, type: 'flex' }}
            size='large'
            // pagination={{
            //   total: getTotalPage,
            //   pageSize: 9,

            //   paginationCurrent: { current: currentPage || 1 },
            //   onChange: this.handleTableChange
            // }}
            dataSource={data}
            // footer={
            //   <div>
            //     <b>ant design</b> footer part
            //   </div>
            // }
            style={{ paddingRight: '15px' }}
            renderItem={(item) => (
              //   <List.Item
              //     key={item.title}
              //     actions={[
              //       //   <IconText type='star-o' text='156' key='list-vertical-star-o' />,
              //       //   <IconText type='like-o' text='156' key='list-vertical-like-o' />,
              //       //   <IconText type='message' text='2' key='list-vertical-message' />,
              //       <Icon type='eye' style={{ color: '#238661' }} />,
              //       <Icon type='edit' style={{ color: '#1890ff' }} />,
              //       <Icon type='question-circle-o' style={{ color: '#ff4d4f' }} />
              //     ]}
              //     // extra={
              //     //   <img
              //     //     width={272}
              //     //     alt='logo'
              //     //     src='https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png'
              //     //   />
              //     // }
              //   >
              //     <List.Item.Meta
              //       //   avatar={<Avatar src={item.avatar} />}
              //       title={<a href={item.href}>{item.title}</a>}
              //       description={item.description}
              //     />
              //     {item.content}
              //   </List.Item>
              <List.Item key={item.key}>
                <StyledCard
                  className='GroupCard'
                  overflow={this.state.overflow}
                  title={item.groupName}
                  style={{ marginBottom: '10px', backgroundColor: '#fff' }}
                  key={item.key}
                  actions={[
                    <Tooltip title='View'>
                      <Button
                        className='viewButton'
                        type='link'
                        icon='eye'
                        onClick={() => this.showModal(item)}
                      />
                    </Tooltip>,
                    // <Icon type='edit' style={{ color: '#1890ff' }}  />,
                    <Tooltip title='Edit'>
                      <Button
                        className='editButton'
                        type='link'
                        icon='edit'
                        onClick={() => this.showDrawerData(item)}
                      />
                    </Tooltip>,
                    // <Icon type='delete' style={{ color: '#ff4d4f' }} />
                    <Popconfirm
                      className='groupDelete'
                      placement='top'
                      title='Are you sure delete this group?'
                      icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
                      onConfirm={() => this.deleteGroupData(item.key)}
                      style={{ padding: '12px 16px' }}>
                      <Tooltip title='Delete'>
                        <Button className='deleteButton' type='link' icon='delete' />
                      </Tooltip>
                    </Popconfirm>
                  ]}>
                  {/* {item.description} */}
                  {/* <Meta
                description={this.state.showMore ? item.filters : item.filters}
              /> */}
                  <ShowMoreText
                    maxHeight={100}
                    lines={3}
                    className='showMoreButton'
                    anchor='Show more'
                    expanded={this.state.expand}
                    // less='Show less'
                    // button={<Button>show more</Button>}
                    anchorClass=''
                    defaultAnchor={false}
                    onClick={() => this.onShowMore(item.filters)}
                    onChange={() => this.onShowMore(item.filters)}>
                    {item.filters}
                  </ShowMoreText>
                  {/* <div>
                  <p className='line-clamp'> {item.filters} </p>
                  <a id='read-more' href='#'>
                  <Button id='read-more' type='link' onClick={() => this.onShowMore(item.filters)}>
                    More
                  </Button>
                  </a>
                </div> */}
                  {/* <Button>Show More</Button> */}
                  {/* <Icon type='right' style={{ color: '#ff4d4f' }} /> */}
                  {/* <div ref={(node) => (this.wrapper = node)}>
                  <ReadMore
                    text={item.filters}
                    numberOfLines={3}
                    lineHeight={1.4}
                    showLessButton={true}
                    onContentChange={() => this.getWrapperWidth(item.filters)}
                  />
                </div> */}
                </StyledCard>
              </List.Item>
            )}
          />
          <Pagination
            total={getTotalPage}
            pageSize={9}
            position='bottomCenter'
            current={currentPage}
            style={{ position: 'relative', float: 'right', right: '15px' }}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          onCancel={this.handleCancel}
          title={`${'Group Name'} : ${Rowdata.groupName}`}
          style={{ top: 20 }}
          width={1200}
          visible={this.state.visible}
          footer={null}>
          <div className='modifiedDetails'>
            <div>
              <p style={{ marginBottom: '0px' }}>
                <b>Created By/On : </b>
                {this.state.Rowdata.createdBy}, {this.state.Rowdata.creationDate}
              </p>
              {/* <p>
                <b>Created By :</b> {this.state.Rowdata.createdBy}
              </p> */}
            </div>
            <div>
              <p style={{ marginBottom: '0px' }}>
                <span style={{ fontWeight: '600' }}>Modified By/On :</span>{' '}
                {this.state.Rowdata.modifiedBy}, {this.state.Rowdata.modifiedDate}
              </p>
              {/* <p>
                <b>Modified By :</b> {this.state.Rowdata.modifiedBy}
              </p> */}
            </div>
          </div>
          {/* <Divider /> */}
          <GroupMemberData
            groupid={this.state.Rowdata.key || ''}
            groupDetails={this.state.Rowdata}
            setMemberCurrentPage={this.props.setMemberCurrentPage}
            memberCurrentPage={this.props.memberCurrentPage}
            columns={memberColumns}
            country={country}
          />
        </Modal>
        <UpdateGroupDrawer
          {...this.props}
          editData={this.state.EditRowdata}
          groupID={this.state.groupId}
          drawerData={this.props.drawerData}
          pageNumber={currentPage}
        />
        <Modal
          onCancel={this.showMoreCancel}
          title='Grouped By'
          visible={this.state.showMoreVisible}
          footer={null}>
          {this.state.showMoreData}
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    fetchGroupMemberList(pagnum, groupId) {
      dispatch(fetchGroupMemberList(pagnum, groupId));
    },
    growersByGroupId(pagnum, groupId) {
      dispatch(growersByGroupId(pagnum, groupId));
    },
    defaultGrowers(pagenum, text) {
      dispatch(defaultGrowers(pagenum, text));
    },
    deleteGroup: (id, pageNumber, dataLength, groupOffset) =>
      dispatch(deleteGroup(id, pageNumber, dataLength, groupOffset)),
    fetchGroupListWithFetchAPI: (pagenum) => dispatch(fetchGroupListWithFetchAPI(pagenum)),
    clearGroupMember: () => dispatch(clearGroupMember()),
    toggleGroupUpdateDrawer() {
      dispatch(toggleGroupUpdateDrawer());
    }
  };
};
const mapStateToProps = function (state) {
  return {
    totalPages: _get(state, 'groupListReducer.data.groupList.totalPages', 0),
    groupList: _get(state, 'groupListReducer.data.groupList.items', []),
    groupOffset: _get(state, 'groupListReducer.data.groupList.offset', []),
    groupFilter: _get(state, 'groupListReducer.data.groupList.items', []),
    updateRowClassFlag: _get(state, 'creatGroupReducer.rowHighlight', true),
    searchResults: _get(state, 'groupListReducer.data.searchResult.result.items', []),
    searchText: _get(state, 'groupListReducer.data.searchResult.searchText', ''),
    groupDeleteSucess: _get(state, 'deleteGroupReducer.data.groupDeleteSucess', ''),
    deletedStatus: _get(state, 'deleteGroupReducer.deleted', '')
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CardGroupData);

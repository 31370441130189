import {
  SCHEDULE_MESSAGE_PENDING,
  SCHEDULE_MESSAGE_SUCCESS,
  SCHEDULE_MESSAGE_ERROR,
  CLEAR_SCHEDULE_MESSAGE_STATUS,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW
} from '../constants';
import config from '../config';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { handleErrors } from '../services/handleErrors';
import { scheduleMessageListService } from '../actions/scheduleMessageList';
export function scheduleMessagePending(MessageStatus) {
  return {
    type: SCHEDULE_MESSAGE_PENDING,
    payload: MessageStatus
  };
}
export function scheduleMessageSuccess(mdata) {
  return {
    type: SCHEDULE_MESSAGE_SUCCESS,
    payload: mdata
  };
}

export function scheduleMessageFailure(merror) {
  return {
    type: SCHEDULE_MESSAGE_ERROR,
    payload: merror
  };
}

export function clearScheduleMessageStatus() {
  return {
    type: CLEAR_SCHEDULE_MESSAGE_STATUS
  };
}
export function toggleDrawerStatus() {
  return {
    type: TOGGLE_DRAWER_STATUS
  };
}
export function highlightMessageTableRow(rowStatus) {
  return {
    type: HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW,
    payload: rowStatus
  };
}

export const messageSchedulingService = (formData) => {
  const apiUri = config.scheduleMessage;

  return (dispatch) => {
    dispatch(scheduleMessagePending());
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((response) => {
        console.log('response>', response);
        if (response && response.code === '200') {
          dispatch(scheduleMessageListService(0));

          //   dispatch(scheduleMessagePending(false));
          dispatch(toggleSpinStatus());
          dispatch(toggleDrawerStatus());
          dispatch(highlightMessageTableRow(true));
          dispatch(clearAlertState());
          dispatch(
            scheduleMessageSuccess({
              alert: true,
              res: response
            })
          );
          dispatch(
            successNotification({
              type: 'success',
              message: response.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          if (!response.length) {
            dispatch(toggleSpinStatus());
            dispatch(clearAlertState());
            return false;
          }
          //   dispatch(toggleSpinStatus());
          dispatch(
            errorNotification({
              type: 'error',
              message: response.message,
              toggleAlert: true
            })
          );
          dispatch(scheduleMessageFailure(response));
          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        // dispatch(scheduleMessagePending(false));
        dispatch(toggleSpinStatus());

        dispatch(
          errorNotification({
            type: 'error',
            message: 'Message Scheduling Failed',
            toggleAlert: true
          })
        );
        dispatch(scheduleMessageFailure({ res: error }));
        dispatch(clearAlertState());
      });
  };
};

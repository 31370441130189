import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { fetchGroupMemberList } from '../../../actions/groupMember';
class GroupMemberData extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      isFetching: false,
      users: [],
      loading: false,
      pagination: {}
    };
  }
  handleTableChange(pagination) {
    this.props.setMemberCurrentPage(pagination.current);
    this.props.fetchGroupMemberList(pagination.current - 1, this.props.groupid);
  }

  render() {
    const { memberCurrentPage, columns, growerCount } = this.props;
    const pagination = {
      total: growerCount,
      showTotal: (total, range) => `${range[0]} to ${range[1]} of ${total}`,
      current: this.props.currentPage + 1
    };

    let mdata = this.props.userList;
    mdata = mdata
      .filter(({ growerId, mobile }) => {
        return growerId && mobile;
      })
      .map((value) => {
        console.log('value', value);
        return {
          key: value.growerId,
          growerName:
            this.props.country === 'in'
              ? `${get(value, 'additionalFields.Farmer Name', '')} ${get(
                  value,
                  'additionalFields.Last Name',
                  ''
                )}`
              : value.name
              ? value.name
              : 'NA',
          contactNo: value.mobile,
          location:
            this.props.country === 'bd'
              ? `${get(value, 'address.village', '')}, ${get(value, 'address.taluka', '')}, ${get(
                  value,
                  'address.district',
                  ''
                )}`
              : this.props.country === 'in'
              ? `${get(value, 'address.state', '')} `
              : this.props.country === 'vn'
              ? `${get(value, 'address.region', '')} `
              : this.props.country === 'ph'
              ? `${get(value, 'address.city', '')}, ${get(value, 'address.region', '')} `
              : this.props.country === 'kr'
              ? `${get(value, 'address.province', '')}, ${get(value, 'address.city', '')}`
              : `${get(value, 'address.region', '')}, ${
                  get(value, 'address.zone', '') ? `${get(value, 'address.zone', '')} ` : ''
                } `,
          locationState: get(value, 'address.state', ''),
          otherCrops: `${get(value, 'additionalFields.SYT grower (Corn)', '')}, ${get(
            value,
            'additionalFields.SYT grower (Rice)',
            ''
          )}`,
          crops: get(value, 'cropInfo.current.name', null),
          otherCrops: `${get(value, 'additionalFields.Crop 1 Planted', '')}, ${get(
            value,
            'additionalFields.Crop 2 Planted',
            ''
          )}`,
          landsize: `${get(value, 'cropInfo.current.acres', null)} `,
          source: 'coming soon'
        };
      });
    return (
      <Table
        rowKey={mdata.key}
        pagination={{ ...pagination, current: memberCurrentPage }}
        loading={this.state.loading}
        handleTableChange
        onChange={this.handleTableChange}
        size='Small'
        bordered={true}
        columns={columns}
        dataSource={mdata}
      />
    );
  }
}

// export default Datatable;
const mapDispatchToProps = function(dispatch) {
  return {
    fetchGroupMemberList: (pagenum, groupid) => dispatch(fetchGroupMemberList(pagenum, groupid))
  };
};
const mapStateToProps = function(state) {
  return {
    totalPages: get(state, 'groupMemberList.data.groupMemberList.totalPages', 0),
    defaultResults: get(state, 'groupMemberList.data.groupMemberList.items', []),
    userList: get(state, 'groupMemberList.data.groupMemberList.items', []),
    growerCount: get(state, 'groupMemberList.data.groupMemberList.growerCount', [])
  };
};
GroupMemberData.propTypes = {
  userList: PropTypes.array,
  totalPages: PropTypes.number
};
export default connect(mapStateToProps, mapDispatchToProps)(GroupMemberData);

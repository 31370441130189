import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Layout, Card } from 'antd';
import AddGrower from './addGrowerForm';
import AddGrowerTH from './addGrowerFormTh';
import AddGrowerMy from './addGrowerFormMy';
import AddGrowerBd from './addGrowerFormBd';
import AddGrowerPh from  './addGrowerFormPh';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
import { StyledCard, StyledFooter } from './addGrowerStyle';
import { RegionalMetaContext } from '../context/region';
const { Content, Footer } = Layout;
class Growers extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const title = 'Add Grower';
    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          
      <Layout className='growerFormLayout'>
        {
        country === 'idn' ? <AddGrower title={title} history={this.props.history} />
        : country === 'th' ? <AddGrowerTH title={title} history={this.props.history}/> 
        : country === 'my' ? <AddGrowerMy title={title} history={this.props.history}/>
        : country === 'bd' ? <AddGrowerBd title={title} history={this.props.history}/>
        : country === 'ph' ? <AddGrowerPh title={title} history={this.props.history}/>
        :  '' }
      </Layout>
       )}
       </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    alertBox() {
      dispatch(alertBox());
    }
  };
};

const mapStateToProps = function(state) {
  return {
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};
Growers.propTypes = {
  toggleAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string
};
export default connect(mapStateToProps, mapDispatchToProps)(Growers);

import {
  FETCH_OPTIN_PENDING,
  FETCH_OPTIN_SUCCESS,
  FETCH_OPTIN_UPDATE,
  FETCH_OPTIN_ERROR,
  CLEAR_OPTIN_RESULT,
  GET_OPTIN_STATE
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import { fetchUsersWithFetchAPI } from '../services/growerListService';
import { fetchSearchResult } from '../actions/advanceSearch';
export function fetchOptInResultPending() {
  return {
    type: FETCH_OPTIN_PENDING
  };
}
export function fetchOptInResultSuccess(searchList) {
  return {
    type: FETCH_OPTIN_SUCCESS,
    payload: searchList
  };
}
export function fetchOptInResultUpdate(searchList) {
  return {
    type: FETCH_OPTIN_UPDATE,
    payload: searchList
  };
}
export function clearOptInResult() {
  return {
    type: CLEAR_OPTIN_RESULT
  };
}
export function fetchOptInResultError(error) {
  return {
    type: FETCH_OPTIN_ERROR,
    payload: error
  };
}
export function setOptInState(users) {
  return {
    type: GET_OPTIN_STATE,
    payload: users
  };
}
export const fetchOptInResult = (
  users,
  pageNumber,
  dataLength,
  groupOffset,
  searchResultArr,
  searchResult
) => {
  console.log('searchResultArr>>', searchResultArr);
  console.log('searchResult>>', searchResult);
  let pageCount = 0;
  if (pageNumber > 0 && dataLength.length === 1) {
    pageCount = groupOffset - 1;
  } else if (pageNumber === 0 && dataLength.length === 1) {
    pageCount = groupOffset;
  } else {
    pageCount = groupOffset;
  }
  const apiUri = formatUrl(config.optIn);
  return (dispatch) => {
    dispatch(fetchOptInResultPending());
    dispatch(toggleSpinStatus());
    dispatch(clearAlertState());
    return fetch(apiUri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
        // 'Time-Zone-Offset': new Date().getTimezoneOffset()
      },
      body: JSON.stringify(users)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          dispatch(clearAlertState());
          dispatch(toggleSpinStatus());
          dispatch(fetchOptInResultSuccess(result));
          dispatch(fetchUsersWithFetchAPI(pageCount));
          // if (searchResultArr.length) {
          //   dispatch(fetchSearchResult(pageCount - 1, searchResult));
          // }
          dispatch(
            successNotification({
              type: 'success',
              message: 'Successfully OptIn for communication mode SMS and MMS.',
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          dispatch(toggleSpinStatus());
          dispatch(fetchOptInResultError(error));
          dispatch(
            errorNotification({
              type: 'error',
              message: 'Failed to OptIn for communication mode SMS and MMS.',
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(fetchOptInResultError(error));
        dispatch(
          errorNotification({
            type: 'error',
            message: 'Failed to OptIn for communication mode SMS and MMS.',
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};

import config from '../config';
import axios from 'axios';
import {
  UPLOAD_FACEBOOK_PENDING,
  UPLOAD_FACEBOOK_SUCCESS,
  UPLOAD_FACEBOOK_ERROR
} from '../constants/index';
import { handleErrors } from '../services/handleErrors';
import { toggleSpinStatus } from './globalSpinner';
export const uploadFacebookPending = () => {
  return {
    type: UPLOAD_FACEBOOK_PENDING
  };
};
export const uploadFacebookSuccess = (value) => {
  return {
    type: UPLOAD_FACEBOOK_SUCCESS,
    payload: value
  };
};

export const uploadFacebookError = (error) => {
  return {
    type: UPLOAD_FACEBOOK_ERROR,
    payload: error
  };
};
export const facebookListWithFetchAPI = (nextPageToken) => {
  const channelUri = 'https://graph.facebook.com/v7.0/2254913651400212/posts?';
  return (dispatch) => {
    dispatch(uploadFacebookPending());
    dispatch(toggleSpinStatus());
    axios
      .get(channelUri, {
        params: {
          fields: `captions,shares,created_time,likes.summary(true),comments.summary(true),attachments,full_picture`,
          access_token: config.facebookToken
        }
      })
      .then((result) => {
        dispatch(uploadFacebookSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);
        if (errorResponse.message.indexOf('403') > -1) {
          const response = {
            status: 403
          };
          return handleErrors(response, dispatch);
        }
        dispatch(uploadFacebookError(error));
      });
  };
};

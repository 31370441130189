import {
  FETCH_ESTIMATOR_PENDING,
  FETCH_ESTIMATOR_SUCCESS,
  FETCH_ESTIMATOR_ERROR,
  FETCH_ESTIMATOR_CLEAR,
  FETCH_ESTIMATOR_LOADER
} from '../constants';

const initialState = {
  data: {
    costEstimatorResult: [],
    costEstimatorError: {},
    costEstimatorLoader: '',
    completed: false
  }
};

function costEstimatorList(state = initialState, action) {
  switch (action.type) {
    case FETCH_ESTIMATOR_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_ESTIMATOR_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          costEstimatorResult: action.payload,
          completed: false
        }
      });

    case FETCH_ESTIMATOR_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          costEstimatorError: action.payload,
          completed: false
        }
      });
    case FETCH_ESTIMATOR_LOADER:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          costEstimatorLoader: 'Estimating Cost...',
          completed: false
        }
      });
    case FETCH_ESTIMATOR_CLEAR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          costEstimatorResult: [],
          costEstimatorError: {},
          costEstimatorLoader: ''
        }
      });
    default:
      return state;
  }
}
export default costEstimatorList;

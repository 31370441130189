import {
  FETCH_ESTIMATOR_PENDING,
  FETCH_ESTIMATOR_SUCCESS,
  FETCH_ESTIMATOR_ERROR,
  FETCH_ESTIMATOR_CLEAR,
  FETCH_ESTIMATOR_LOADER
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus, estamatingCostStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchCostEstimatorPending() {
  return {
    type: FETCH_ESTIMATOR_PENDING
  };
}
export function fetchCostEstimatorSuccess(result) {
  return {
    type: FETCH_ESTIMATOR_SUCCESS,
    payload: result
  };
}

export function fetchCostEstimatorError(error) {
  return {
    type: FETCH_ESTIMATOR_ERROR,
    payload: error
  };
}
export function fetchCostEstimatorLoader() {
  return {
    type: FETCH_ESTIMATOR_LOADER
  };
}
export function fetchCostEstimatorClear() {
  return {
    type: FETCH_ESTIMATOR_CLEAR
  };
}
export const fetchCostEstimator = (groupData) => {
  const apiUri = formatUrl(config.costEstimator);

  return (dispatch) => {
    dispatch(fetchCostEstimatorPending());
    // dispatch(toggleSpinStatus());
    dispatch(fetchCostEstimatorLoader());
    fetch(apiUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(groupData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result.error_msg) {
          dispatch(fetchCostEstimatorError(result));
        } else {
          dispatch(fetchCostEstimatorSuccess(result));
        }

        // dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(fetchCostEstimatorError(error));
      });
  };
};

import React from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes, { resetWarningCache } from 'prop-types';
import { Form, Upload, Button, Icon, Select, Progress } from 'antd';
import './myupload.css';
const { Option } = Select;

class FileUploadWithSchemaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      filevalid: false,
      selectedSchema: '',
      isButtonDisabled: false
    };

    /****** Drawer function declarations */
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.fileValidation = this.fileValidation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e, props) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const emailId = props.Context.auth.account.userName;
      // const emailId = props.Context.AuthContext._user.userName;

      if (!err) {
        const formData = new FormData();
        formData.append('file', values.Upload.file);
        // formData.append('schema', values.schema);
        formData.append('userId', emailId);
        this.props.uploadWithSchemaService(formData);
        this.props.resetPage();
        this.setState({
          isButtonDisabled: true
        });
      }
    });
  }

  /***** Select dropdown Functions */

  onChange(value) {
    this.setState({
      selectedSchema: value
    });
  }

  fileValidation(rule, val, callback) {
    if (
      val.file.type !== 'application/vnd.ms-excel' &&
      val.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      callback('Please select only excel-Sheet');
      return;
    }
    callback();
  }

  handleChange(info) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
    if (fileList.length > 0) {
      this.setState({
        isButtonDisabled: false
      });
    }
  }

  render() {
    const { uploading, fileList } = this.state;
    const { t } = this.props;
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },
      onChange: this.handleChange,
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },

      multiple: false,
      fileList,
      accept: '.xlsx,.xls,.csv'
    };
    const { getFieldDecorator, resetFields } = this.props.form;

    return (
      <Form onSubmit={(e) => this.handleSubmit(e, this.props)} className='login-form' method='post'>
        <Form.Item></Form.Item>
        {/* <Form.Item>
          {getFieldDecorator('schema', {
            rules: [{ required: true, message: t('Please select your schema!') }]
          })(
            <Select
              showSearch
              className='schemaSelect'
              name='schema'
              placeholder={t('Select a Schema')}
              optionFilterProp='children'
              onChange={this.onChange}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              setFieldsValue={this.state.selectedSchema}>
              <Option value=''> </Option>
              <Option value='SIND'>{t('Schema-Default')}</Option>
              <Option value='STHAI'>{t('Schema-Thailand')}</Option>
            </Select>
          )}
        </Form.Item> */}
        <Form.Item>
          {getFieldDecorator('Upload', {
            getValueFromEvent: this.handlePhotoChange,
            rules: [
              { required: true, message: t('Please select your file!') },
              { filevalid: true, validator: this.fileValidation }
            ]
          })(
            <Upload {...props} fileList={this.state.fileList}>
              <Button
                onClick={(e) => {
                  this.props.clearProgressStatus();
                  this.setState({
                    fileList: []
                  });
                }}>
                {t('Select a File')}
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            // type="primary"
            size='medium'
            htmlType='submit'
            disabled={fileList.length === 0 || this.state.isButtonDisabled}
            loading={uploading}
            className='uploadBtn'
            block>
            <Icon type='upload' /> {uploading ? t('Uploading') : t('Upload File')}
          </Button>
        </Form.Item>
        <Form.Item>
          {fileList.length > 0 ? (
            <Progress
              percent={fileList.length === 0 ? 0 : this.props.progress}
              showInfo={true}
              status={fileList.length === 0 ? 'active' : this.props.progressStatus}
            />
          ) : null}
        </Form.Item>
      </Form>
    );
  }
}

const FileUploadWithSchema = Form.create({ name: 'normal_login' })(FileUploadWithSchemaForm);

FileUploadWithSchemaForm.propTypes = {
  form: PropTypes.any,
  getFieldDecorator: PropTypes.object,
  fileValidation: PropTypes.object,
  progress: PropTypes.number,
  progressStatus: PropTypes.string,
  uploadWithSchemaService: PropTypes.func
};
export default FileUploadWithSchema;

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import AuthLayout from '../views/_layouts/auth';
import iff from '../utils/iff';
import { AuthContext } from '../context/auth-context';
export default function RouteWrapper({ component: Component, Context }) {
  const auth = useContext(AuthContext);
  console.log('Context>>>', Context);
  return (
    <Route
      render={(props) => {
        // iff(
        // auth.isAuthenticated === true,
        // <Component {...props} Context={Context} />;
        // <Redirect
        //   to={{
        //     pathname: '/login',
        //     state: { from: props.location }
        //   }}
        // />
        // );
        return (
          <AuthLayout Context={Context}>
            <Component {...props} Context={Context} />
          </AuthLayout>
        );
      }}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  Context: PropTypes.object
};

RouteWrapper.defaultProps = {
  isPrivate: false
};

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchChannel } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchChannel extends React.Component {
  constructor(props) {
    super(props);
    this.childrenChannel = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchChannel();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, searchSourceMessage } = this.props;
    let channelData = this.props.groupFilterReducer || [];
    let channelText = channelData.length > 0 ? channelData[0].items : [];
    this.childrenChannel = channelText.map((channel) => <Option key={channel}>{channel}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.channelValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some channel')}]
        })(
          <Select
            name='channel'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Channel')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenChannel}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchChannel: () => dispatch(fetchChannel())
  };
};
const mapStateToProps = function(state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupChannel.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchChannel);

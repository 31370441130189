import React from 'react';
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import './drawer.css';
import { RegionalMetaContext } from '../../../context/region';

/****** Drawer Icons *********/
import {
  GrowerSVGIcon,
  SocialSVGIcon,
  ReportSVGIcon,
  MangUserSVGIcon,
  WebstoreSVGIcon,
  HelpSVGIcon,
  HomeSVGIcon,
  UtilitySVGIcon,
  InboxSvgIcon,
  WhatsAppSVGIcon
} from './drawerIcons';

const { Sider } = Layout;
const { SubMenu } = Menu;

class Drawer extends React.Component {
  static contextType = RegionalMetaContext;
  constructor(props) {
    super(props);
    this.state = {
      openKeys: ['sub1'],
      collapsed: true,
      navData: []
    };
    this.rootSubmenuKeys = ['sub1', 'sub2'];
  }

  closeDrawer = () => {
    this.setState({
      collapsed: true
    });
  };
  openDrawer = () => {
    this.setState({
      collapsed: false
    });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };
  onOpenChange(openKeys) {
    const latestOpenKey = openKeys.find((key) => this.state.openKeys.indexOf(key) === -1);

    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      });
    }
  }

  setIcon(iconType) {
    switch (iconType) {
      case 'dashboard':
        return <HomeSVGIcon style={{ fontSize: '32px' }} />;

      case 'engagegrowers':
        return <WebstoreSVGIcon style={{ fontSize: '32px' }} />;

      case 'on-board-growers':
        return <GrowerSVGIcon style={{ fontSize: '32px' }} />;

      case 'social-media':
        return <SocialSVGIcon style={{ fontSize: '32px' }} />;

      case 'reports':
        return <ReportSVGIcon style={{ fontSize: '32px' }} />;

      case 'users':
        return <MangUserSVGIcon style={{ fontSize: '32px' }} />;

      case 'help':
        return <HelpSVGIcon style={{ fontSize: '32px' }} />;

      case 'utility':
        return <UtilitySVGIcon style={{ fontSize: '32px' }} />;
      case 'inbox':
        return <InboxSvgIcon style={{ fontSize: '32px' }} />;
      default:
        return '';
    }
  }

  render() {
    const chooseLang = localStorage.getItem('i18nextLng');
    return (
      <RegionalMetaContext.Consumer>
        {({ data }) => (
          <div onMouseEnter={this.openDrawer} onMouseLeave={this.closeDrawer}>
            <Sider
              onBreakpoint={(broken) => {}}
              className='mainDrawer'
              style={{ overflow: 'auto' }}
              collapsible
              collapsedWidth={70}
              width={data.drawerWidth}
              collapsed={this.state.collapsed}
              onCollapse={this.toggle}
              trigger={null}>
              <Menu
                className='Drawermenu'
                mode='inline'
                defaultSelectedKeys={['/']}
                // openKeys={this.state.openKeys}
                // subMenuCloseDelay={0.1}
                onOpenChange={this.onOpenChange}
                selectedKeys={[location.pathname]}>
                {data &&
                  data.navigation &&
                  data.navigation.map((n, i) =>
                    n.children ? (
                      <SubMenu
                        className='DrawerSubmenu'
                        {...this.props}
                        openKeys={this.state.openKeys}
                        // onOpenChange={this.onOpenChange}
                        key={n.itemKey}
                        title={
                          <Link className='growerLabel nav-text' to={n.key}>
                            <Menu.Item key={n.key}>
                              {this.setIcon(n.key)}
                              <span className='growerLabel nav-text'>{n.label}</span>
                            </Menu.Item>
                          </Link>
                        }>
                        {n.children &&
                          n.children.map((child) => (
                            <Menu.Item key={child.key}>
                              <Link to={child.key} className='nav-text'>
                                {child.label}
                              </Link>
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={n.key} className='growerMenuitem'>
                        <Link className='growerLabel nav-text' to={n.key}>
                          {this.setIcon(n.key)}
                          <span className='nav-text'>{n.label}</span>
                        </Link>
                      </Menu.Item>
                    )
                  )}
              </Menu>
            </Sider>
          </div>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}

export default Drawer;

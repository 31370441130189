import React from 'react';
import { Avatar, Button, Icon, Input, Form, Upload, Tooltip } from 'antd';
import './connected.css';
import messageFirstBgSVG from '../../assets/img/MessageSvg/wifi-svgrepo-com.svg';

class Connected extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0
    };
  }

  render() {
    return (
      <div className='connected'>
        <div className='connected_body'>
          <div className='wifi-connect'>
            <img style={{ height: '240px' }} alt='Creat A New Message' src={messageFirstBgSVG} />
          </div>
          <div className='connect-text'>
            <h1>Keep your Computer connected</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Connected;

import React, { Component } from 'react';
import { Form, Select, Radio, Icon, Modal, Button } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { communicationLists } from '../../../../../../actions/modesOfCommunication';
import { fetchAllGroupList } from '../../../../../../actions/groupList';
import {
  fetchCostEstimator,
  fetchCostEstimatorClear
} from '../../../../../../actions/costEstimator';
import { fetchWhatsappLimit } from '../../../../../../actions/whatsAppLimit';

// import './stepForm.css';
import './thirdStep.css';
const Option = Select.Option;
const FormItem = Form.Item;

class Third extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      mdata: { channels: '', groupId: '' },
      channelMode: ''
    };
    this.modes = [];
  }
  componentDidMount = () => {
    this.props.fetchAllGroupList(0);
    // if (this.props.region) {
    //   this.props.communicationLists(this.props.region.country_code);
    // }
    // this.props.fetchCostEstimatorClear();
  };
  // componentDidUpdate(prevProps) {
  //   if (this.props.region && this.props.region !== prevProps.region) {
  //     this.props.communicationLists(this.props.region.country_code);
  //   }
  // }
  // validateMessageType = (rule, value, callback) => {
  //   if (value && value !== 'SMS' && value !== 'WHATSAPP') {
  //     callback('This service will come soon.');
  //   } else {
  //     callback();
  //   }
  //   callback();
  // };
  handleChange1 = () => {
    this.props.form.validateFields((error, value) => {
      this.props.fetchCostEstimatorClear();
      // this.setState({ channelMode: value.SocialPlatform });
      if (!error) {
        const mdata = {
          channels: [value.SocialPlatform],
          groupId: value.groupType
        };
        this.setState({ mdata });
        //  this.props.checkMediaType(value.SocialPlatform);
        this.props.fetchCostEstimator(mdata);
        // this.props.fetchWhatsappLimit(value.groupType);
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { groupList, groupListData, t } = this.props;
    const { communicationModes } = this.props;
    this.modes =
      communicationModes &&
      communicationModes.map((item) => {
        return (
          <Radio.Button value={item.name}>
            <img src={item.image_url} />
            <label>{item.name}</label>
          </Radio.Button>
        );
      });
    const optionsData =
      groupListData &&
      groupListData.map((item, i) => {
        return (
          <Option key={i} value={item.id}>
            {item.name}
          </Option>
        );
      });

    return (
      <div className='thirdDiv'>
        <p style={{ textAlign: 'center' }}>{t('Select recipient ')}</p>
        <FormItem onChange={this.handleChange1}>
          {getFieldDecorator('groupType', {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{ required: true, message: t('Please select a group') }]
          })(
            <Select
              placeholder={t('Select Group Of Grower(s)')}
              onSelect={this.handleChange1}
              className='messagegroupType'
              showSearch
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              {optionsData}
              {/* <Option value="Dynamic">Dynamic</Option>
              <Option value="Static">Static</Option> */}
            </Select>
          )}
        </FormItem>
        {/* 
        <FormItem onChange={this.handleChange}>
          {getFieldDecorator('SocialPlatform', {
            rules: [
              {
                required: true,
                message: t('Please select platform type!')
              },
              {
                validator: this.validateMessageType
              }
            ],
            initialValue: 'SMS'
          })(
            <Radio.Group size='large' className='thirdstepRadioGroup'>
              {this.modes}
            </Radio.Group>
          )}
        </FormItem> */}
        <p>Selected Mode : {this.props.messageType}</p>
        {this.props.countryData !== 'ph' ? (
          this.props.messageType === 'SMS' ||
          this.props.messageType === 'WHATSAPP' ||
          this.props.messageType === 'MMS' ? (
            Object.keys(this.props.costEstimatorData).length > 0 ? (
              <span className='costMsgText'>
                {t('Message will be sent to')} {this.props.costEstimatorData.target_audience}{' '}
                {t('growers and it would cost')} {this.props.costEstimatorData.cost}{' '}
                {this.props.costEstimatorData.cost_unit || ''}
              </span>
            ) : Object.keys(this.props.costEstimatorError).length > 0 ? (
              <span className='costErrorMsgText'> {this.props.costEstimatorError}</span>
            ) : this.state.mdata.groupId.length > 0 ? (
              <span className='loadingCost'>{this.props.costEstimatorLoader}</span>
            ) : null
          ) : null
        ) : null}
        {this.props.countryData === 'idn' ? (
          this.props.costEstimatorData.target_audience > 200000 &&
          this.state.mdata.channels[0] === 'WHATSAPP' ? (
            <span className='WhatsAppText'>
              As per our current WhatsApp limits we can reach up to 200000 members in a day
            </span>
          ) : (
            ''
          )
        ) : (
          ''
        )}
        {this.props.countryData === 'bd' ? (
          this.props.costEstimatorData.target_audience > 1000 &&
          this.state.mdata.channels[0] === 'WHATSAPP' ? (
            <span className='WhatsAppText'>
              As per our current WhatsApp limits we can reach up to 1000 members in a day
            </span>
          ) : (
            ''
          )
        ) : (
          ''
        )}
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    // communicationLists: (regionCode) => dispatch(communicationLists(regionCode)),
    fetchAllGroupList: (pagenum) => dispatch(fetchAllGroupList(pagenum)),
    fetchCostEstimator: (mdata) => dispatch(fetchCostEstimator(mdata)),
    fetchWhatsappLimit: (mdata) => dispatch(fetchWhatsappLimit(mdata))
  };
};
const mapStateToProps = function(state) {
  return {
    communicationModes: get(
      state,
      'communicationList.communicationLists.supported_channel.channels',
      []
    ),
    region: get(state, 'webStoreListReducer.regionListData.current_region', '{}'),
    groupListData: get(state, 'groupListReducer.data.groupListData.items', []),
    costEstimatorLoader: get(state, 'costEstimatorList.data.costEstimatorLoader', {})
  };
};
Third.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
// export default Third;
export default connect(mapStateToProps, mapDispatchToProps)(Third);

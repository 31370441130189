import React from 'react';
import PropTypes from 'prop-types';
import './titleSection.css';

function TitleSection(props) {
  return <h1 className='titlSection'>{props.title}</h1>;
}
TitleSection.propTypes = {
  title: PropTypes.string
};
export default TitleSection;

import { FETCH_UPDATE_PENDING, FETCH_UPDATE_SUCCESS, FETCH_UPDATE_ERROR } from '../constants';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import { fetchDashboardResult } from '../actions/dashboard';
import { fetchPicResult } from '../actions/pic';
import { fetchSalesUnitResult } from '../actions/salesUnit';
import { fetchBrandResult } from '../actions/brand';
import { findCountryandEnvironment } from '../services/commonMethods';
export function fetchUpdateStatisticsPending() {
  return {
    type: FETCH_UPDATE_PENDING
  };
}
export function fetchUpdateStatisticsSuccess(updateList) {
  return {
    type: FETCH_UPDATE_SUCCESS,
    payload: updateList
  };
}

export function fetchUpdateStatisticsError(error) {
  return {
    type: FETCH_UPDATE_ERROR,
    payload: error
  };
}

export const fetchUpdateStatistics = () => {
  const apiUri = formatUrl(config.updateStatistics);
  return (dispatch) => {
    // dispatch(toggleSpinStatus());
    dispatch(fetchUpdateStatisticsPending());
    dispatch(clearAlertState());

    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          dispatch(fetchUpdateStatisticsSuccess(result));
          const countryHostName = findCountryandEnvironment(window.location.host);
          if (countryHostName.country === 'bd') {
            dispatch(fetchDashboardResult());
            dispatch(fetchBrandResult());
          } else if (countryHostName.country === 'th') {
            dispatch(fetchDashboardResult());
            dispatch(fetchSalesUnitResult());
          } else if (countryHostName.country === 'my') {
            dispatch(fetchDashboardResult());
            dispatch(fetchPicResult());
          } else dispatch(fetchDashboardResult());
          // dispatch(fetchDashboardResult());
          // dispatch(fetchPicResult());
          // dispatch(fetchSalesUnitResult());
          // dispatch(fetchBrandResult());
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        dispatch(clearAlertState());
        dispatch(fetchUpdateStatisticsError(error));
        dispatch(
          errorNotification({
            type: 'error',
            message: error.message,
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};

import { TOGGLE_SPIN_STATUS, COST_STATUS } from '../constants';

const initialState = {
  loading: false,
  text: ''
};

function toggelSpin(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SPIN_STATUS:
      return {
        loading: !state.loading
      };
    case COST_STATUS:
      return {
        text: 'Estamating Cost'
      };
    default:
      return state;
  }
}
export default toggelSpin;

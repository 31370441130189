import React, { useState, useEffect } from 'react';
// import { connect } from 'react-redux';
import { Input } from 'antd';
import PropTypes from 'prop-types';
// import { fetchSearchResult } from '../../../services/growerListService';
import './autosearch.css';

const { Search } = Input;

const AutoSearch = (props) => {
  const [searchText, setSearchText] = useState('');
  const placeholder = props.searchTitle;
  const setText = (event) => {
    if (event.target.value !== '') {
      setSearchText(event.target.value);
    } else {
      setSearchText(event.target.value);
      props.defaultGrowers(0, '');
    }
  };

  const searchGrower = () => {
    if (searchText.length > 0) {
      props.defaultGrowers(0, searchText);
    } else {
      props.defaultGrowers(0, '');
    }
  };
  return (
    <Search
      className='searchInput'
      name='searchText'
      placeholder={placeholder}
      loading={false}
      onChange={(event) => setText(event)}
      onSearch={(event) => searchGrower(event)}
      value={searchText}
    />
  );
};

AutoSearch.propTypes = {
  fetchSearchResult: PropTypes.func,
  searchTitle: PropTypes.string
};
// export default connect(() => ({}), mapDispatchToProps)(AutoSearch);
export default AutoSearch;

import {
    DOWNLOAD_REPORT_PENDING,
    DOWNLOAD_REPORT_SUCESS,
    DOWNLOAD_REPORT_ERROR
  } from '../constants';
  
  import { formatUrl } from '../services/commonMethods';
  import { toggleSpinStatus } from '../actions/globalSpinner';
  import config from '../config';
  import { handleErrors } from '../services/handleErrors';
  
  export function downloadReportResultPending() {
    return {
      type: DOWNLOAD_REPORT_PENDING
    };
  }
  export function downloadReportResultSuccess(searchList) {
    return {
      type: DOWNLOAD_REPORT_SUCESS,
      payload: searchList
    };
  }
  
  export function downloadReportResultError(error) {
    return {
      type: DOWNLOAD_REPORT_ERROR,
      payload: error
    };
  }
  
  export const downloadReportResult = (users) => {
    const apiUri = formatUrl(config.downloadReport, users);
    return (dispatch) => {
      dispatch(downloadReportResultPending());
  
      return fetch(apiUri, {
        method: 'GET',
        headers: {
          // Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + config.userToken,
          'Time-Zone-Offset': new Date().getTimezoneOffset()
        },
        // body: JSON.stringify(users)
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          dispatch(downloadReportResultSuccess(result));
        })
        .catch((error) => {
          dispatch(downloadReportResultError(error));
        });
    };
  };
  
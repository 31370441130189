import config from '../config';
import { sentMessageListWithFetchAPI } from './messageListService';
import {
  sendMessagePending,
  sendMessageSuccess,
  sendMessageFailure,
  toggleDrawer,
  highlightMessageTableRow
} from '../actions/messageSending';

import { toggleSpinStatus } from '../actions/globalSpinner';
import AdalConfigService from '../services/adalConfigService';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { handleErrors } from '../services/handleErrors';
export const messageSendingService = (formData) => {
  const apiUri = config.sendMessageFromInteract;
  return (dispatch) => {
    dispatch(sendMessagePending(true));
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(formData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((response) => {
        console.log('Message response', response);
        if (response && response.code === '200') {
          window.amplitude.getInstance().logEvent(`${AdalConfigService.countryName}_Message_Sent`);
          dispatch(sentMessageListWithFetchAPI(0));
          dispatch(sendMessagePending(false));
          dispatch(toggleSpinStatus());
          dispatch(toggleDrawer());
          dispatch(highlightMessageTableRow(true));
          dispatch(clearAlertState());
          dispatch(
            sendMessageSuccess({
              alert: true,
              res: response
            })
          );
          dispatch(
            successNotification({
              type: 'success',
              message: response.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          // if (!response.length) {
          //   dispatch(toggleSpinStatus());
          //   dispatch(clearAlertState());
          //   return false;
          // }
          dispatch(sendMessagePending(false));
          dispatch(toggleSpinStatus());
          dispatch(
            errorNotification({
              type: 'error',
              message: response.message,
              toggleAlert: true
            })
          );
          dispatch(sendMessageFailure({ alert: true, res: response }));
          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        console.log('Message error', error);
        dispatch(sendMessagePending(false));
        dispatch(toggleSpinStatus());
        dispatch(sendMessageFailure({ res: error }));

        dispatch(clearAlertState());
        dispatch(
          errorNotification({
            type: 'error',
            message: response.message,
            toggleAlert: true
          })
        );
      });
  };
};

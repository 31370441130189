import 'regenerator-runtime/runtime';
import React, { Component } from 'react';
import { Table, Button, Popconfirm, Row, Col, Icon, Upload, Layout, Card } from 'antd';
import TitleSection from '../commonComponents/TitleSection/titleSection';
import { ExcelRenderer } from 'react-excel-renderer';
import XLSX from 'xlsx';
import { get } from 'lodash';
import { EditableFormRow, EditableCell } from './editable';
import data from './data.json';
import './utility.css';
const { Content } = Layout;
export default class BuzzBee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      rows: [],
      excelData: [],
      errorMessage: null,
      columns: [
        {
          title: 'NAME',
          dataIndex: 'Name',
          editable: false
        },
        {
          title: 'Mobile No.',
          dataIndex: 'Mobile No.',
          editable: false
        },
        {
          title: 'Retailer Name',
          dataIndex: 'Retailer Name',
          editable: false
        },
        {
          title: 'Retailer Code',
          dataIndex: 'Retailer Code',
          editable: false
        },
        {
          title: 'Region',
          dataIndex: 'Region',
          editable: false
        },
        {
          title: 'Area',
          dataIndex: 'Area',
          editable: false
        },
        {
          title: 'Province',
          dataIndex: 'Province',
          editable: false
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          editable: false
        },
        {
          title: 'Crop',
          dataIndex: 'Crop',
          editable: false
        },
        {
          title: 'Line ID',
          dataIndex: 'Line ID',
          editable: false
        },
        {
          title: 'Source',
          dataIndex: 'Source',
          editable: false
        },
        {
          title: 'Consent',
          dataIndex: 'Consent',
          editable: false
        },
        {
          title: 'BPID',
          dataIndex: 'BPID',
          editable: false
        }
      ],
      sheetHeader: [
        {
          title: 'User__first_name'
        },
        {
          title: 'User__mobile'
        },
        {
          title: 'Retailer Name'
        },
        {
          title: 'Retailer Code'
        },
        {
          title: 'user_cf_region'
        },
        {
          title: 'user_cf_area'
        },
        {
          title: 'User__primary_crop'
        },
        {
          title: 'Line ID'
        },
        {
          title: 'Source'
        },
        {
          title: 'Consent'
        },
        {
          title: 'Status'
        },
        {
          title: 'Province'
        },
        {
          title: 'User__last_name'
        },
        {
          title: 'User__secondary_crop'
        },
        {
          title: 'BPID'
        }
      ]
    };
    this.tableRef = React.createRef;
  }

  handleSave = (row) => {
    const newData = [...this.state.rows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = '';
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      errorMessage = 'You can only upload Excel file!';
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = 'File must be smaller than 2MB!';
    }
    return errorMessage;
  }
  findColumnValue = (headers, row, headerName, defaultValue) => {
    const isHeaderAvailable = headers.find((value) => value.value === headerName);
    // const isUndefined = typeof row[isHeaderAvailable.index] === 'undefined';
    return isHeaderAvailable && isHeaderAvailable.index && row[isHeaderAvailable.index]
      ? row[isHeaderAvailable.index]
      : defaultValue;
  };

  ExportUSSDExcel = () => {
    const sheetData = Object.assign(this.state.columns, this.state.excelData);
    const ws = XLSX.utils.json_to_sheet(this.state.excelData);
    /* build a workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'buzzBee.xlsx');
  };

  fileHandler = (fileList) => {
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'No file uploaded!'
      });
      return false;
    }
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Unknown file format. Only Excel files are uploaded!'
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        let headers = resp.rows[0];
        let sheetColumns = this.state.sheetHeader.map((value) => value.title);

        let headerData = headers
          .map((value, index) => ({
            index: index,
            value: value
          }))
          .filter((value, index) => sheetColumns.includes(value.value));
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== 'undefined') {
            let bpidSheetData = this.findColumnValue(headerData, row, 'BPID', 'N/A');
            const provinceJsonData = data;
            let isRegionAvailable = '';
            let isAreaAvailable = '';
            let isRetailerCodeAvailable = '';
            let isRetailerNameAvailable = '';
            provinceJsonData.forEach((item) => {
              if (bpidSheetData === item.BPID) {
                isRegionAvailable = get(item, 'Region', 'NOT-CAPTURED');
                isAreaAvailable = get(item, 'Area', '');
                isRetailerCodeAvailable = get(item, 'RetailerCode', '');
                isRetailerNameAvailable = get(item, 'RetailerName', '');
              }
            });

            newRows.push({
              Name: `${this.findColumnValue(
                headerData,
                row,
                'User__first_name',
                'NOT-CAPTURED'
              )} ${this.findColumnValue(headerData, row, 'User__last_name', '')}`,
              'Mobile No.': this.findColumnValue(headerData, row, 'User__mobile', ''),
              'Retailer Name': isRetailerNameAvailable ? isRetailerNameAvailable : '',
              'Retailer Code': isRetailerCodeAvailable ? isRetailerCodeAvailable : '',
              Region: isRegionAvailable ? isRegionAvailable : 'NOT-CAPTURED',
              Area: isAreaAvailable ? isAreaAvailable : '',
              Province: this.findColumnValue(headerData, row, 'Province', 'NOT-CAPTURED'),
              Status: this.findColumnValue(headerData, row, 'Status', ''),
              Crop: `${this.findColumnValue(
                headerData,
                row,
                'User__primary_crop',
                ''
              )}, ${this.findColumnValue(headerData, row, 'User__secondary_crop', '')}`,
              'Line ID': this.findColumnValue(headerData, row, 'Line ID', ''),
              Source: this.findColumnValue(headerData, row, 'Source', 'Buzzbees'),
              Consent: this.findColumnValue(headerData, row, 'Consent', ''),
              BPID: this.findColumnValue(headerData, row, 'BPID', '')
            });
          }
        });
        this.setState({
          excelData: newRows
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: 'No data found in file!'
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {};

  handleDelete = (key) => {
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter((item) => item.key !== key) });
  };

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <>
        <Upload
          name='file'
          className='utilityUploadButton'
          beforeUpload={this.fileHandler}
          onRemove={() => this.setState({ rows: [] })}
          multiple={false}>
          <Button className='uploadButton'>
            <Icon type='upload' /> Upload File
          </Button>
        </Upload>
        <Button className='buzzbeeDownloadButton' onClick={() => this.ExportUSSDExcel()}>
          <Icon type='download' /> Download
        </Button>
        <div style={{ marginTop: 14 }}>
          {this.state.rows.length ? (
            <Table
              ref={this.tableRef}
              components={components}
              bordered
              scroll={{ y: 340 }}
              rowClassName={() => 'editable-row'}
              dataSource={this.state.rows}
              columns={columns}
            />
          ) : null}
        </div>
      </>
    );
  }
}

import styled from 'styled-components';
import { Card, Layout, Form } from 'antd';
const { Footer } = Layout;
export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 27px;
    background-color: #ebebeb;
  }
`;
export const StyledFooter = styled(Footer)`
  .ant-layout-footer {
    padding: 10px 50px;
  }
`;

export const StyledGrowerForm = styled(Form)`
.ant-select-selection--single {
    display: flex !important;
}
`;

export const StyledFormItem = styled(Form.Item)`
.ant-form label {
    font-size: 14px;
   }
`;

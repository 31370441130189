import React, { Component } from 'react';
import { Table, Modal, Button, Popconfirm, Divider, Icon, Tooltip } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import GroupMemberData from './groupMemberList';
import { Translation } from 'react-i18next';
import { fetchGroupListWithFetchAPI } from '../../../services/groupListService';
import { deleteGroup } from '../../../actions/groupDelete';
import { defaultGrowers } from '../../../actions/growersAction';
import { fetchGroupMemberList, clearGroupMember } from '../../../actions/groupMember';
import { toggleGroupUpdateDrawer } from '../../../actions/groupUpdate';
import UpdateGroupDrawer from './GroupDrawer/updateGroupDrawer';
import './GrowerGroupData.css';
import { EyeOutlined } from '@ant-design/icons';
import datatable from '../../commonComponents/Datatabel/datatable';
import { GroupModelStyle } from './GrowerGroupDataStyle';
import { downloadGroupData } from '../../../actions/downloadGroupData';
let operatorValues = {
  lessThanEqualTo: '<=',
  greaterThanEqualsTo: '>='
};

class ListGroupData extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      selectedRowKeys: [],
      isFetching: false,
      users: [],
      loading: false,
      pagination: {},
      visible: false,
      deleteGroupStatus: false,
      Rowdata: [],
      groupId: '',
      currentPage: 0,
      EditRowdata: []
    };
    this.scrollHere = React.createRef(); // Create a ref object
  }

  showModal = (record) => {
    this.props.fetchGroupMemberList(0, record.key);
    this.props.downloadGroupData(record.key);
    // this.props.resetPage();
    this.setState({
      visible: true,
      Rowdata: record
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    });
    this.props.clearGroupMember();
    // this.props.resetPage();
    this.props.resetMemberPage();
  };
  handleTableChange(pagination) {
    this.props.setCurrentPage(pagination.current);
    this.props.fetchGroupListWithFetchAPI(pagination.current - 1);
  }
  scrollToBottom = () => {
    if (this.state.deleteGroupStatus) {
      return false;
    }
    var scrollTableTop = document.querySelector('.ant-table-row-level-0');
    scrollTableTop &&
      scrollTableTop.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };
  componentDidMount() {
    this.props.fetchGroupListWithFetchAPI(0);
  }

  getValues = (values, valueField) => {
    if (
      valueField === 'regions' ||
      valueField === 'crops' ||
      valueField === 'tags' ||
      valueField === 'cropHistory' ||
      valueField === 'language' ||
      valueField === 'zone' ||
      valueField === 'city' ||
      valueField === 'area' ||
      valueField === 'brand' ||
      valueField === 'retailerName' ||
      valueField === 'salesUnit' ||
      valueField === 'district' ||
      valueField === 'tmhq' ||
      valueField === 'classification' ||
      valueField === 'segment' ||
      valueField === 'province' ||
      valueField === 'pramukhTag' ||
      valueField === 'mdohq' ||
      valueField === 'block' ||
      valueField === 'state' ||
      valueField === 'village' ||
      valueField === 'TVC' ||
      valueField === 'WA' ||
      valueField === 'FB' ||
      valueField === 'YB' ||
      valueField === 'IG' ||
      valueField === 'sytGrowerCorn' ||
      valueField === 'sytGrowerRice' ||
      valueField === 'prefferedHybridCorn' ||
      valueField === 'prefferedHybridRice' ||
      valueField === 'seasonSegmentation'
    ) {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).items.join(', ')) ||
        []
      );
    } else if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).value) ||
        ''
      );
    } else {
      return '';
    }
  };
  getEditGroupValues = (values, valueField) => {
    if (
      valueField === 'regions' ||
      valueField === 'crops' ||
      valueField === 'tags' ||
      valueField === 'cropHistory' ||
      valueField === 'language' ||
      valueField === 'zone' ||
      valueField === 'city' ||
      valueField === 'area' ||
      valueField === 'brand' ||
      valueField === 'retailerName' ||
      valueField === 'salesUnit' ||
      valueField === 'district' ||
      valueField === 'tmhq' ||
      valueField === 'classification' ||
      valueField === 'segment' ||
      valueField === 'province' ||
      valueField === 'pramukhTag' ||
      valueField === 'mdohq' ||
      valueField === 'block' ||
      valueField === 'state' ||
      valueField === 'village' ||
      valueField === 'TVC' ||
      valueField === 'WA' ||
      valueField === 'FB' ||
      valueField === 'YB' ||
      valueField === 'IG' ||
      valueField === 'sytGrowerCorn' ||
      valueField === 'sytGrowerRice' ||
      valueField === 'prefferedHybridCorn' ||
      valueField === 'prefferedHybridRice' ||
      valueField === 'seasonSegmentation'
    ) {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).items) ||
        []
      );
    } else if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).value) ||
        ''
      );
    } else {
      return '';
    }
  };
  getOperatorValues = (values, valueField) => {
    if (valueField === 'acres') {
      return (
        (values.find((filter) => filter.name === valueField) &&
          values.find((filter) => filter.name === valueField).operator.name.trim()) ||
        ''
      );
    } else {
      return '';
    }
  };
  showDrawerData = (record) => {
    this.props.toggleGroupUpdateDrawer();
    this.setState({
      EditRowdata: record
    });
  };
  deleteGroupData = (record) => {
    this.props.deleteGroup(
      record,
      this.props.currentPage,
      this.props.groupList,
      this.props.groupOffset
    );
    this.setState({
      deleteGroupStatus: true
    });
  };
  componentWillReceiveProps(newProps) {
    // tableContainer.scrollTo(0);

    if (newProps.groupList !== this.props.groupList) {
      this.setState({
        deleteGroupStatus: false
      });
      this.scrollToBottom();
    }
  }
  componentDidUpdate = (prevProps, nextProps) => {
    if (!isEqual(prevProps.groupList, this.props.groupList) && isEmpty(this.props.groupList)) {
      if (this.props.groupList.length === 0 && this.props.currentPage > 0) {
        this.props.setCurrentPage(this.props.currentPage - 1);
      }
    }
  };
  render() {
    const {
      searchText,
      searchResults,
      groupList,
      currentPage,
      memberColumns,
      t,
      country,
      downloadGroupDetails
    } = this.props;

    const groupListTable = [
      {
        title: t('GROUP'),
        dataIndex: 'groupName',
        width: '10%'
      },
      // {
      //   title: t('CREATED BY'),
      //   dataIndex: 'createdBy',
      //   width: '15%'
      // },
      // {
      //   title: t('CREATED ON'),
      //   dataIndex: 'creationDate',
      //   width: '1%5'
      // },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters',
        width: '20%'
      },
      {
        title: t('LAST MODIFIED BY'),
        dataIndex: 'modifiedBy',
        width: '15%'
      },
      {
        title: t('LAST MODIFIED ON'),
        dataIndex: 'modifiedDate',
        width: '15%'
      },
      {
        title: t('ACTION'),
        dataIndex: 'action',
        //  fixed:'right',
        width: '10%',
        render: (text, record) => (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip title='View'>
              <Button
                className='viewButton'
                type='link'
                icon='eye'
                onClick={() => this.showModal(record)}
              />
            </Tooltip>
            <Divider type='vertical' style={{ height: 'auto' }} />
            <Tooltip title='Edit'>
              <Button
                className='editButton'
                type='link'
                icon='edit'
                onClick={() => this.showDrawerData(record)}
              />
            </Tooltip>
            <Divider type='vertical' style={{ height: 'auto' }} />
            <Popconfirm
              className='groupDelete'
              placement='left'
              title='Are you sure delete this group?'
              icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
              onConfirm={() => this.deleteGroupData(record.key)}
              style={{ padding: '12px 16px' }}>
              <Tooltip title='Delete'>
                <Button className='deleteButton' type='link' icon='delete' />
              </Tooltip>
            </Popconfirm>
          </span>
        )
      }
    ];
    const pagination = {
      total: this.props.totalPages * 10
    };
    const list =
      (searchResults && searchResults.length > 0) || searchText.length > 0
        ? searchResults
        : groupList;
    const data = [];
    if (list) {
      list.forEach((value, index) => {
        let cropCheck = this.getValues(value.filters, 'crops').length;
        let valueCheck = this.getValues(value.filters, 'regions').length;
        let tagsValueCheck = this.getValues(value.filters, 'tags').length;
        let cropHistoryValueCheck = this.getValues(value.filters, 'cropHistory').length;
        let languageCheck = this.getValues(value.filters, 'language').length;
        let provinceCheck = this.getValues(value.filters, 'zone').length;
        let areaCheck = this.getValues(value.filters, 'area').length;
        let cityCheck = this.getValues(value.filters, 'city').length;
        let acresCheck = this.getValues(value.filters, 'acres').length;
        let retailerCheck = this.getValues(value.filters, 'retailerName').length;
        let stockistCheck = this.getValues(value.filters, 'salesUnit').length;
        let tmhqCheck = this.getValues(value.filters, 'tmhq').length;
        let brandCheck = this.getValues(value.filters, 'brand').length;
        let districtCheck = this.getValues(value.filters, 'district').length;
        let classificationCheck = this.getValues(value.filters, 'classification').length;
        let segmentCheck = this.getValues(value.filters, 'segment').length;
        let provinceKrCheck = this.getValues(value.filters, 'province').length;
        let pramukhTagCheck = this.getValues(value.filters, 'pramukhTag').length;
        let mdohqCheck = this.getValues(value.filters, 'mdohq').length;
        let talukaCheck = this.getValues(value.filters, 'block').length;
        let villageCheck = this.getValues(value.filters, 'village').length;
        let stateCheck = this.getValues(value.filters, 'state').length;
        let tvcCheck = this.getValues(value.filters, 'TVC').length;
        let waCheck = this.getValues(value.filters, 'WA').length;
        let fbCheck = this.getValues(value.filters, 'FB').length;
        let ybCheck = this.getValues(value.filters, 'YB').length;
        let igCheck = this.getValues(value.filters, 'IG').length;
        let sytGrowerCornCheck = this.getValues(value.filters, 'sytGrowerCorn').length;
        let sytGrowerRiceCheck = this.getValues(value.filters, 'sytGrowerRice').length;
        let prefferedHybridCornCheck = this.getValues(value.filters, 'prefferedHybridCorn').length;
        let prefferedHybridRiceCheck = this.getValues(value.filters, 'prefferedHybridRice').length;
        let seasonSegmentationCheck = this.getValues(value.filters, 'seasonSegmentation').length;
        data.push({
          key: value && value.id ? value.id : '',
          groupName: value && value.name ? value.name : '',
          groupType: value && value.type ? value.type : '',
          // noOfMembers: value && value.totalMembers ? value.totalMembers : 0,
          NonArray: value.filters ? value.filters : '',
          createdBy:
            (value && value.userCreated) || (value && value.createdBy)
              ? value.userCreated
                ? value.userCreated
                : value.createdBy
              : '',
          creationDate:
            value && value.createdDate
              ? moment
                  .utc(value.createdDate)
                  .local()
                  .format('lll')
              : value.created
              ? moment(value.created).format('lll')
              : '',
          modifiedBy:
            value && value.lastModifiedBy
              ? value.lastModifiedBy
              : value.updatedBy
              ? value.updatedBy
              : value.createdBy
              ? value.createdBy
              : '',
          modifiedDate:
            value && value.lastModifiedDate
              ? moment
                  .utc(value.lastModifiedDate)
                  .local()
                  .format('lll')
              : value.updated
              ? moment(value.updated).format('lll')
              : value.createdDate
              ? moment
                  .utc(value.createdDate)
                  .local()
                  .format('lll')
              : value.created
              ? moment(value.created).format('lll')
              : '',
          GrowersCrop: value.filters ? this.getEditGroupValues(value.filters, 'crops') : '',
          OperatorValue: value.filters ? this.getOperatorValues(value.filters, 'acres') : '',
          GrowersZone: value.filters ? this.getEditGroupValues(value.filters, 'zone') : '',
          acresValue: value.filters ? this.getEditGroupValues(value.filters, 'acres') : '',
          GrowersTags: value.filters ? this.getEditGroupValues(value.filters, 'tags') : '',
          GrowersCropHistory: value.filters
            ? this.getEditGroupValues(value.filters, 'cropHistory')
            : '',
          GrowersArea: value.filters ? this.getEditGroupValues(value.filters, 'area') : '',
          GrowersRegion: value.filters ? this.getEditGroupValues(value.filters, 'regions') : '',
          GrowersDistrict: value.filters ? this.getEditGroupValues(value.filters, 'district') : '',
          GrowersBrand: value.filters ? this.getEditGroupValues(value.filters, 'brand') : '',
          GrowersRetailerName: value.filters
            ? this.getEditGroupValues(value.filters, 'retailerName')
            : '',
          GrowersStockistCode: value.filters
            ? this.getEditGroupValues(value.filters, 'salesUnit')
            : '',
          GrowerLanguage: value.filters ? this.getEditGroupValues(value.filters, 'language') : '',
          GrowersCity: value.filters ? this.getEditGroupValues(value.filters, 'city') : '',
          GrowersTmhq: value.filters ? this.getEditGroupValues(value.filters, 'tmhq') : '',
          GrowersClassification: value.filters
            ? this.getEditGroupValues(value.filters, 'classification')
            : '',
          GrowersSegment: value.filters ? this.getEditGroupValues(value.filters, 'segment') : '',
          GrowersProvince: value.filters ? this.getEditGroupValues(value.filters, 'province') : '',
          GrowersPramukhTag: value.filters
            ? this.getEditGroupValues(value.filters, 'pramukhTag')
            : '',
          GrowersMdohq: value.filters ? this.getEditGroupValues(value.filters, 'mdohq') : '',
          GrowersTaluka: value.filters ? this.getEditGroupValues(value.filters, 'block') : '',
          GrowersVillage: value.filters ? this.getEditGroupValues(value.filters, 'village') : '',
          GrowersState: value.filters ? this.getEditGroupValues(value.filters, 'state') : '',
          GrowersTVC: value.filters ? this.getEditGroupValues(value.filters, 'TVC') : '',
          GrowersWA: value.filters ? this.getEditGroupValues(value.filters, 'WA') : '',
          GrowersFB: value.filters ? this.getEditGroupValues(value.filters, 'FB') : '',
          GrowersYB: value.filters ? this.getEditGroupValues(value.filters, 'YB') : '',
          GrowersIG: value.filters ? this.getEditGroupValues(value.filters, 'IG') : '',
          GrowersSytGrowerCorn: value.filters
            ? this.getEditGroupValues(value.filters, 'sytGrowerCorn')
            : '',
          GrowersSytGrowerRice: value.filters
            ? this.getEditGroupValues(value.filters, 'sytGrowerRice')
            : '',
          GrowersPrefferedCorn: value.filters
            ? this.getEditGroupValues(value.filters, 'prefferedHybridCorn')
            : '',
          GrowersPrefferedRice: value.filters
            ? this.getEditGroupValues(value.filters, 'prefferedHybridRice')
            : '',
          GrowersSeasonSegementation: value.filters
            ? this.getEditGroupValues(value.filters, 'seasonSegmentation')
            : '',
          filters: (
            <div className='FilterLetter'>
              {cropCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crops')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {cropCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'crops') : ''}
              {cropCheck ? <br /> : ''}
              {valueCheck ? (
                country === 'ph' ? (
                  <b>
                    <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                  </b>
                ) : (
                  <b>
                    <Translation>{(t) => <>{t('Regions')}</>}</Translation>:
                  </b>
                )
              ) : (
                ''
              )}
              {valueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'regions') : ''}{' '}
              {valueCheck ? <br /> : ''}
              {acresCheck ? <b>Acres:</b> : ''}
              {acresCheck ? <span>&nbsp;</span> : ''}
              {acresCheck ? operatorValues[this.getOperatorValues(value.filters, 'acres')] : ''}
              {acresCheck ? this.getValues(value.filters, 'acres') : ''}
              {tagsValueCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Tags')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {tagsValueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'tags') : ''}{' '}
              {tagsValueCheck ? <br /> : ''}
              {cropHistoryValueCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crop History')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {cropHistoryValueCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'cropHistory') : ''}{' '}
              {cropHistoryValueCheck ? <br /> : ''}
              {languageCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Language')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {languageCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'language') : ''}{' '}
              {languageCheck ? <br /> : ''}
              {provinceCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {provinceCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'zone') : ''}{' '}
              {provinceCheck ? <br /> : ''}
              {areaCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {areaCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'area') : ''} {areaCheck ? <br /> : ''}
              {cityCheck ? (
                country === 'th' ? (
                  <b>
                    <Translation>{(t) => <>{t('Sub District')}</>}</Translation>:
                  </b>
                ) : (
                  <b>
                    <Translation>{(t) => <>{t('City')}</>}</Translation>:
                  </b>
                )
              ) : (
                ''
              )}
              {cityCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'city') : ''} {cityCheck ? <br /> : ''}
              {/* {acresCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Acre')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {acresCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'acres') : ''}{' '} */}
              {acresCheck ? <br /> : ''}
              {retailerCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Retailer Name')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {retailerCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'retailerName') : ''}{' '}
              {retailerCheck ? <br /> : ''}
              {brandCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Brand')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {brandCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'brand') : ''}{' '}
              {brandCheck ? <br /> : ''}
              {stockistCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Stockist Code')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {stockistCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'salesUnit') : ''}{' '}
              {stockistCheck ? <br /> : ''}
              {tmhqCheck ? (
                <b>
                  <Translation>
                    {(t) => <>{this.props.country === 'in' ? 'TMHQ' : 'Sales Unit'}</>}
                  </Translation>
                  :
                </b>
              ) : (
                ''
              )}
              {tmhqCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'tmhq') : ''} {tmhqCheck ? <br /> : ''}
              {districtCheck ? (
                <b>
                  <Translation>{(t) => <>{t('District')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {districtCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'district') : ''}{' '}
              {districtCheck ? <br /> : ''}
              {classificationCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Classification')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {classificationCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'classification') : ''}{' '}
              {classificationCheck ? <br /> : ''}
              {segmentCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Segment')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {segmentCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'segment') : ''}{' '}
              {segmentCheck ? <br /> : ''}
              {provinceKrCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Province')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {provinceKrCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'province') : ''}{' '}
              {provinceKrCheck ? <br /> : ''}
              {pramukhTagCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Pramukh Tag')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {pramukhTagCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'pramukhTag') : ''}{' '}
              {pramukhTagCheck ? <br /> : ''}
              {mdohqCheck ? (
                <b>
                  <Translation>{(t) => <>{t('MDOHQ')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {mdohqCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'mdohq') : ''}{' '}
              {mdohqCheck ? <br /> : ''}
              {stateCheck ? (
                <b>
                  <Translation>{(t) => <>{t('State')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {stateCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'state') : ''}{' '}
              {stateCheck ? <br /> : ''}
              {villageCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Village')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {villageCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'village') : ''}{' '}
              {villageCheck ? <br /> : ''}
              {talukaCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Block')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {talukaCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'block') : ''}{' '}
              {talukaCheck ? <br /> : ''}
              {tvcCheck ? (
                <b>
                  <Translation>{(t) => <>{t('TVC')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {tvcCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'TVC') : ''} {tvcCheck ? <br /> : ''}
              {waCheck ? (
                <b>
                  <Translation>{(t) => <>{t('WA')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {waCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'WA') : ''} {waCheck ? <br /> : ''}
              {fbCheck ? (
                <b>
                  <Translation>{(t) => <>{t('FB')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {fbCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'FB') : ''} {fbCheck ? <br /> : ''}
              {ybCheck ? (
                <b>
                  <Translation>{(t) => <>{t('YB')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {ybCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'YB') : ''} {ybCheck ? <br /> : ''}
              {igCheck ? (
                <b>
                  <Translation>{(t) => <>{t('IG')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {igCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'IG') : ''} {igCheck ? <br /> : ''}
              {sytGrowerCornCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crop 1 Planted')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {sytGrowerCornCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'sytGrowerCorn') : ''}{' '}
              {sytGrowerCornCheck ? <br /> : ''}
              {sytGrowerRiceCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Crop 2 Planted')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {sytGrowerRiceCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'sytGrowerRice') : ''}{' '}
              {sytGrowerRiceCheck ? <br /> : ''}
              {prefferedHybridCornCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Hybrid Corn Acreage')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {prefferedHybridCornCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'prefferedHybridCorn') : ''}{' '}
              {prefferedHybridCornCheck ? <br /> : ''}
              {prefferedHybridRiceCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Hybrid Rice Acreage')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {prefferedHybridRiceCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'prefferedHybridRice') : ''}{' '}
              {prefferedHybridCornCheck ? <br /> : ''}
              {seasonSegmentationCheck ? (
                <b>
                  <Translation>{(t) => <>{t('Season Segmentation')}</>}</Translation>:
                </b>
              ) : (
                ''
              )}
              {seasonSegmentationCheck ? <span>&nbsp;</span> : ''}
              {value.filters ? this.getValues(value.filters, 'seasonSegmentation') : ''}{' '}
              {seasonSegmentationCheck ? <br /> : ''}
            </div>
          )
        });
      });
    }

    const { Rowdata } = this.state;
    return (
      <>
        <Table
          rowKey={data.key}
          className='groupTableScroll'
          pagination={{ ...pagination, current: currentPage }}
          loading={false}
          tableLayout='auto'
          onChange={this.handleTableChange}
          size='Small'
          scroll={{ y: '65vh' }}
          // ref={e => this.scrollHere = e}
          bordered={true}
          columns={groupListTable}
          dataSource={data}
          // scroll={{ y: 340 }}
          // className='scroll'
          // onRow={(record) => ({
          //   onClick: () => {
          //     // this.props.growersByGroupId(0, record.key);
          //     this.showModal(record);
          //   }
          // })}
          rowClassName={(record, index) => {
            return this.props.currentPage === 0 && index === 0 && this.props.updateRowClassFlag
              ? 'highLight'
              : '';
          }}
        />
        <GroupModelStyle
          onCancel={this.handleCancel}
          title={
            <>
              <div>Group Name : {Rowdata.groupName}</div>{' '}
              <div style={{ marginRight: '30px' }}>
                {this.props.country === 'kr' || this.props.country === 'in' ? (
                  <Button
                    className='searchButton'
                    target='_blank'
                    title='Download'
                    disabled={
                      this.props.downloadGroupDataStatus !== '200' ||
                      !this.props.userList.length > 0
                    }
                    href={this.props.downloadGroupDetails}>
                    {' '}
                    <Icon type='download' />
                    Download growers of this group
                  </Button>
                ) : (
                  ''
                )}
              </div>
            </>
          }
          style={{ top: 20 }}
          width={1200}
          visible={this.state.visible}
          footer={null}>
          <div className='modifiedDetails'>
            <div>
              <p style={{ marginBottom: '0px' }}>
                <b>Created By/On : </b>
                {this.state.Rowdata.createdBy}, {this.state.Rowdata.creationDate}
              </p>
            </div>
            <div>
              <p style={{ marginBottom: '0px' }}>
                <span style={{ fontWeight: '600' }}>Last Modified By/On :</span>{' '}
                {this.state.Rowdata.modifiedBy}, {this.state.Rowdata.modifiedDate}
              </p>
            </div>
          </div>
          <GroupMemberData
            groupid={this.state.Rowdata.key || ''}
            setMemberCurrentPage={this.props.setMemberCurrentPage}
            memberCurrentPage={this.props.memberCurrentPage}
            columns={memberColumns}
            country={country}
          />
        </GroupModelStyle>
        <UpdateGroupDrawer
          {...this.props}
          editData={this.state.EditRowdata}
          groupID={this.state.groupId}
          drawerData={this.props.drawerData}
          pageNumber={currentPage}
        />
      </>
    );
  }
}

// export default Datatable;
const mapDispatchToProps = function(dispatch) {
  return {
    fetchGroupMemberList(pagnum, groupId) {
      dispatch(fetchGroupMemberList(pagnum, groupId));
    },
    growersByGroupId(pagnum, groupId) {
      dispatch(growersByGroupId(pagnum, groupId));
    },
    defaultGrowers(pagenum, text) {
      dispatch(defaultGrowers(pagenum, text));
    },
    deleteGroup: (id, pageNumber, dataLength, groupOffset) =>
      dispatch(deleteGroup(id, pageNumber, dataLength, groupOffset)),
    fetchGroupListWithFetchAPI: (pagenum) => dispatch(fetchGroupListWithFetchAPI(pagenum)),
    clearGroupMember: () => dispatch(clearGroupMember()),
    toggleGroupUpdateDrawer() {
      dispatch(toggleGroupUpdateDrawer());
    },
    downloadGroupData: (groupId) => dispatch(downloadGroupData(groupId))
  };
};
const mapStateToProps = function(state) {
  return {
    totalPages: _get(state, 'groupListReducer.data.groupList.totalPages', 0),
    groupList: _get(state, 'groupListReducer.data.groupList.items', []),
    groupOffset: _get(state, 'groupListReducer.data.groupList.offset', []),
    groupFilter: _get(state, 'groupListReducer.data.groupList.items', []),
    updateRowClassFlag: _get(state, 'creatGroupReducer.rowHighlight', true),
    searchResults: _get(state, 'groupListReducer.data.searchResult.result.items', []),
    searchText: _get(state, 'groupListReducer.data.searchResult.searchText', ''),
    groupDeleteSucess: _get(state, 'deleteGroupReducer.data.groupDeleteSucess', ''),
    downloadGroupDetails: _get(state, 'downloadGroupData.data.downloadGroupData.details', ''),
    downloadGroupDataStatus: _get(state, 'downloadGroupData.data.downloadGroupData.code', ''),
    userList: _get(state, 'groupMemberList.data.groupMemberList.items', [])
  };
};
ListGroupData.propTypes = {
  fetchGroupListWithFetchAPI: PropTypes.func,
  growersByGroupId: PropTypes.func,
  totalPages: PropTypes.number,
  groupFilter: PropTypes.array,
  searchResults: PropTypes.array,
  searchText: PropTypes.string,
  updateRowClassFlag: PropTypes.bool,
  groupList: PropTypes.array
};
export default connect(mapStateToProps, mapDispatchToProps)(ListGroupData);

const initialState = {
  data: {
    searchResult: {
      result: [],
      searchText: ''
    },
    userList: {},
    completed: false
  }
};

function growerList(state = initialState, action) {
  switch (action.type) {
    case 'USERS_LOADING':
      return {
        data: {
          ...state.data,
          // ...state.data.userList,
          completed: true
        }
      };
    case 'USERS_LIST':
      return Object.assign({}, state, {
        data: {
          ...state.data,
          userList: action.value,
          completed: false
        }
      });
    case 'SEARCH_LIST':
      return Object.assign({}, state, {
        data: {
          ...state.data,
          searchResult: action.value,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default growerList;

import React, { Component } from 'react';
import { Table, Modal, Card, Row, Col, Descriptions, List, Tag, Button, Switch } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { fetchUsersWithFetchAPI } from '../../../services/growerListService';
import { fetchSearchResult, setSearchState } from '../../../actions/advanceSearch';
import { fetchOptInResult } from '../../../actions/optIn';

import { fetchOptOutResult } from '../../../actions/optOut';
import { clearGrowerUpdate } from '../../../actions/growerUpdate';
import { alertBox } from '../../commonComponents/Notification/alertBox';
import GrowerDetailForm from './growerDetailForm';
import { GrowerModelStyle } from './growerDetailStyle';
import OptInOptOutSwitch from './optInOut';
import './growerDetailForm.css';

class ListGrowerData extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.handleTableChange = this.handleTableChange.bind(this);

    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      isFetching: false,
      users: [],
      loading: false,
      visible: false,
      Rowdata: [],
      currentPage: 0,
      switch: false
    };
  }
  handleTableChange(pagination) {
    this.props.setCurrentPage(pagination.current);
    if (this.props.searchResultArr.length > 0) {
      this.props.fetchSearchResult(pagination.current - 1, this.props.usersData);
    } else {
      this.props.fetchUsersWithFetchAPI(pagination.current - 1);
    }
  }
  toggleSwitch = () => {
    this.setState({ switch: !this.state.switch });
    if (this.state.switch) {
      const modeSwitch = this.state.Rowdata.modeSwitch;

      const mobileNumbers = {
        mobileNumbers: [Number(this.state.Rowdata.contactNo)]
      };

      this.props.fetchOptOutResult(mobileNumbers);
    } else {
      // const mobileNumbers = this.state.Rowdata.contactNo;
      const mobileNumbers = {
        mobileNumbers: [Number(this.state.Rowdata.contactNo)]
      };
      console.log('mobileNumbers in>', mobileNumbers);
      this.props.fetchOptInResult(mobileNumbers);
      console.log('Opt in called');
    }
  };
  showModal = (record) => {
    console.log('currentPage', this.props.currentPage);
    // if (this.props.searchResultArr.length > 0) {
    //   this.props.fetchSearchResult(this.props.currentPag, record.key);
    // }
    if (this.props.currentPage === 0 || this.props.currentPage === 1) {
      this.props.fetchUsersWithFetchAPI(this.props.currentPage, record.key);
    } else {
      this.props.fetchUsersWithFetchAPI(this.props.currentPage - 1, record.key);
    }

    this.setState(
      {
        // visible: true,
        Rowdata: record
      },
      () => {
        this.afterSetStateFinished();
      }
    );
  };
  afterSetStateFinished = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    this.setState({
      visible: false
    });
  };
  handleEdit = (e) => {
    this.setState({
      enableEdit: false,
      enableTagEdit: true
    });
  };
  handleSave = () => {
    this.setState({
      enableEdit: true,
      enableTagEdit: false,
      visible: false
    });
  };
  setError = () => {
    this.setState({ visible: true });
  };
  handleCancel = (e) => {
    this.props.clearGrowerUpdate();
    this.setState({
      visible: false
    });
  };

  render() {
    const {
      userList,
      searchStatus,
      searchResultArr,
      totalSearch,
      totalPages,
      currentPage,
      growerCount,
      growerFormData,
      tagVisible,
      columns,
      hideNewTag,
      t,
      seedVarityVisible,
      brandVisible,
      productUsedLastSeasonVisible,
      optInOutVisible
    } = this.props;
    const paginationResult = {
      total: totalPages * 10,
      showTotal: 0
    };

    const paginationSearch = {
      total: growerCount,
      showTotal: (total, range) => `${range[0]} - ${range[1]} of ${total}`
    };
    const paginationSearchEmpty = {
      total: 0,
      showTotal: 0
    };
    const pagination = searchStatus
      ? paginationSearchEmpty
      : searchResultArr.length
      ? paginationSearch
      : paginationResult;

    let mdata = searchStatus ? [] : searchResultArr.length ? searchResultArr : userList;

    mdata = mdata
      .filter(({ growerId, mobile }) => {
        return growerId && mobile;
      })
      .map((value) => {
        return {
          key: value.growerId,
          growerName: value.name
            ? value.name
            : this.props.country === 'bd' || this.props.country === 'ph'
            ? `${get(value, 'additionalFields.First Name', '')} ${get(
                value,
                'additionalFields.Last Name',
                ''
              )}`
            : this.props.country === 'in'
            ? `${get(value, 'additionalFields.Farmer Name', '')} ${get(
                value,
                'additionalFields.Last Name',
                ''
              )}`
            : 'NA',
          contactNo: value.mobile,
          surname: value.surname,
          location:
            this.props.country === 'bd'
              ? `${get(value, 'address.village', '')}
               ${get(value, 'address.taluka', '')}, ${get(value, 'address.district', '')}`
              : this.props.country === 'in'
              ? `${get(value, 'address.state', '')} `
              : this.props.country === 'vn'
              ? `${get(value, 'address.region', '')} `
              : this.props.country === 'my'
              ? `${get(value, 'address.state', '')}, ${get(value, 'address.region', '')}, ${
                  get(value, 'address.zone', '') ? `${get(value, 'address.zone', '')}` : ''
                } `
              : this.props.country === 'ph'
              ? `${get(value, 'address.city', '')}, ${get(value, 'address.region', '')} `
              : this.props.country === 'kr'
              ? `${get(value, 'address.province', '')},${get(value, 'address.city', '')}`
              : `${get(value, 'address.state', '')} ${get(value, 'address.region', '')}, ${get(
                  value,
                  'address.zone',
                  ''
                )}, ${get(value, 'address.area', '') ? `${get(value, 'address.area', '')} ` : ''} `,

          crops: get(value, 'cropInfo.current.name', ''),
          update:
            (value && value.lastModifiedDate) || (value && value.updated)
              ? moment
                  .utc(value && value.lastModifiedDate ? value.lastModifiedDate : value.updated)
                  .local()
                  .format('lll')
              : '',
          landSize: value.cropInfo.current.acres.toString(),
          source: 'coming soon',
          taluka: `${get(value, 'address.taluka', '')}`,
          zipCode: `${get(value, 'address.zipCode', '')}`,
          houseNumber: `${get(value, 'address.houseNumber', '')}`,
          district: `${get(value, 'address.district', '')}`,
          subDistrict: `${get(value, 'address.subDistrict', '')}`,
          village: `${get(value, 'address.village', '')}`,
          state: `${get(value, 'address.state', '')}`,
          city: `${get(value, 'address.city', '')}`,
          region: `${get(value, 'address.region', '')}`,
          zone: `${get(value, 'address.zone', '')}`,
          area: `${get(value, 'address.area', '')}`,
          province: `${get(value, 'address.province', '')}`,
          cultivationArea: `${get(value, 'address.cultivationArea', '')}`,
          addressLine: `${get(value, 'address.addressLine', '')}`,
          classification: `${get(value, 'classification', '')}`,
          unit: `${get(value, 'sourcing.salesUnit', '')}`,
          franchisee: `${get(value, 'sourcing.franchise', '')}`,
          frnCode: `${get(value, 'sourcing.frnCode', '')}`,
          retailerName: `${get(value, 'additionalFields.Retailer Name', '')}`,
          retailerCode: `${get(value, 'additionalFields.Retailer Code', '')}`,
          language: `${get(value, 'additionalFields.Language', '')}`,
          stockist: `${get(value, 'additionalFields.Stockist', '')}`,
          Channel: `${get(value, 'additionalFields.Channel', '')}`,
          activitiesType: `${get(value, 'additionalFields.activitiesType', '')}`,
          mdohq: `${get(value, 'additionalFields.MDOHQ', '')}`,
          tmhq: `${get(value, 'additionalFields.TMHQ', '')}`,
          sytGrowerCorn: `${get(value, 'additionalFields.Crop 1 Planted', '')}`,
          sytGrowerRice: `${get(value, 'additionalFields.Crop 2 Planted', '')}`,
          otherCrops: `${get(value, 'additionalFields.Crop 1 Planted', '')}, ${get(
            value,
            'additionalFields.Crop 2 Planted',
            ''
          )}`,
          preferredHybridCorn: `${get(value, 'additionalFields.Hybrid Corn Acreage', '')}`,
          preferredHybridRice: `${get(value, 'additionalFields.Hybrid Rice Acreage', '')}`,
          seasonSegementation: `${get(value, 'additionalFields.season segmentation', '')}`,
          TVC: `${get(value, 'additionalFields.TVC', '')}`,
          WA: `${get(value, 'additionalFields.WA', '')}`,
          FB: `${get(value, 'additionalFields.FB', '')}`,
          YB: `${get(value, 'additionalFields.YB', '')}`,
          IG: `${get(value, 'additionalFields.IG', '')}`,
          remark: `${get(value, 'additionalFields.Remark', '')}`,
          modeSwitch: `${get(value, 'communicationModeSwitch.SMS', true)}`,
          remarkHistory:
            value && value.additionalFields ? value.additionalFields['remarkHistory'] || [] : '',
          personalInformationHistory:
            value && value.additionalFields
              ? value.additionalFields['personalInformationHistory'] || []
              : '',
          segment: `${get(value, 'additionalFields.Segment', '')}`,
          customerName: `${get(value, 'additionalFields.Customer Name', '')}`,
          sapCode: `${get(value, 'additionalFields.SAP Code', '')}`,
          personalInformation: `${get(value, 'additionalFields.Personal Information', '')}`,
          cropType: `${get(value, 'additionalFields.Crop Type', '')}`,
          pic: `${get(value, 'additionalFields.PIC', '')}`,
          latestBrand: `${get(value, 'additionalFields.latest.Brand', '')}`,
          // Vn Additional Fields
          email: `${get(value, 'additionalFields.Email', '')}`,
          facebook: `${get(value, 'additionalFields.Facebook', '')}`,
          growerBirthdate: `${get(value, 'additionalFields.Grower Birthdate', '')}`,
          homePhone: `${get(value, 'additionalFields.Home Phone', '')}`,
          mdoName: `${get(value, 'additionalFields.MDO Name', '')}`,
          TotalFarmSquare: `${get(value, 'additionalFields.Total Farm Square', '')}`,
          totalIncome: `${get(value, 'additionalFields.Total Income', '')}`,
          zaloNumber: `${get(value, 'additionalFields.Zalo Number', '')}`,
          syngentaProduct: `${get(value, 'additionalFields.Syngenta Product Using', '')}`,
          // productUsedLastSeason: `${get(
          //   value,
          //   'additionalFields.latest.Product Used Last Season',
          //   ''
          // )}`,
          productUsed:
            value && value.additionalFields
              ? value.additionalFields['Product Used Last Season'] || []
              : '',
          seedVariety:
            value && value.additionalFields ? value.additionalFields['Seed Variety'] || [] : '',
          brandUsed: value && value.additionalFields ? value.additionalFields['Brand'] || [] : '',
          dateofBirth: value && value.additionalFields ? value.additionalFields['Date of Birth(mm/dd/yyyy)'] : '',
          dateofEvent: value && value.additionalFields ? value.additionalFields['Date of Event'] : '',
          eventAttended: value && value.additionalFields ? value.additionalFields['Event Attended'] : '',
          firstName: `${get(value, 'additionalFields.First Name', '')}`,
          farmerName: `${get(value, 'additionalFields.Farmer Name', '')}`,
          lastName: `${get(value, 'additionalFields.Last Name', '')}`,
          middleName: `${get(value, 'additionalFields.Middle Initial', '')}`,
          dateOfBirth: `${get(value, 'additionalFields.Date of Birth(mm/dd/yyyy)', '')}`,
          // seedVariety: `${get(value, 'additionalFields.Seed Variety', [])}`,
          noOfBagsPlanted: `${get(value, 'additionalFields.latest.No# of Bags Planted', '')}`,
          tags: value.tags || [],
          // productUsedLastSeason:
          //   `${get(value, 'additionalFields.Product Used Last Season', '')}` || [],
          cropInfo:
            value.cropInfo && value.cropInfo.cultivatedCrops
              ? value.cropInfo.cultivatedCrops.map((item, index) => item)
              : null,
          // cropInfo: value.cropInfo.cultivatedCrops.map((item, index) => (
          //   <span>{(index ? ', ' : '') + item.name}</span>
          // )),
          acres:
            value.cropInfo && value.cropInfo.cultivatedCrops
              ? value.cropInfo.cultivatedCrops.map((item) => (
                  <li>
                    <b>Acres: &nbsp;</b>
                    {item.acres.toString()}
                  </li>
                ))
              : null
          // cropInfo: `${get(value, 'cropInfo.cultivatedCrops.map((item)=> item)', null)} `
        };
      });
    const { Rowdata } = this.state;
    return (
      <div>
        <Table
          rowKey={mdata.key}
          pagination={{ ...pagination, current: currentPage }}
          loading={false}
          handleTableChange
          onChange={this.handleTableChange}
          size='Small'
          bordered={true}
          columns={columns}
          dataSource={mdata}
          onRow={(record) => ({
            onClick: () => {
              // this.props.growersByGroupId(0, record.key);
              this.showModal(record);
            }
          })}
          rowClassName={(record) => {
            record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : '';
          }}
        />
        <GrowerModelStyle
          onCancel={this.handleCancel}
          title={
            <>
              <div>Growers Profile</div>
              <div style={{ marginRight: '30px' }}>
                {/* {this.props.country === 'kr' ||
                this.props.country === 'in' ||
                this.props.country === 'vn' ||
                this.props.country === 'my' ? ( */}
                {optInOutVisible ? (
                  <OptInOptOutSwitch
                    Rowdata={this.state.Rowdata}
                    userList={this.props.userList}
                    userListOffset={this.props.userListOffset}
                    {...this.props}
                    currentPage={this.props.currentPage}
                    searchResultArr={this.props.searchResultArr}
                    usersData={this.props.usersData}
                  />
                ) : (
                  ''
                )}
              </div>
            </>
          }
          style={{ top: 20 }}
          width={1200}
          destroyOnClose={true}
          visible={this.state.visible}
          onOk={this.handleOk}
          footer={false}
          className='detailFormModal'>
          <GrowerDetailForm
            {...this.props}
            Rowdata={this.state.Rowdata}
            setError={this.setError}
            enableEdit={this.state.enableEdit}
            enableTagEdit={this.state.enableTagEdit}
            country={this.props.country}
            dividerHeight={this.props.dividerHeight}
            hideSaveButton={this.props.hideSaveButton}
            removeTags={this.props.removeTags}
            isModalVisble={() => {
              this.setState({
                visible: false
              });
            }}
            resetPage={this.props.resetPage}
            growerFormData={growerFormData}
            tagVisible={tagVisible}
            hideNewTag={hideNewTag}
            seedVarityVisible={seedVarityVisible}
            brandVisible={brandVisible}
            productUsedLastSeasonVisible={productUsedLastSeasonVisible}
          />
        </GrowerModelStyle>
      </div>
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    fetchUsersWithFetchAPI: (pagenum) => dispatch(fetchUsersWithFetchAPI(pagenum)),
    fetchSearchResult: (pagenum, result) => dispatch(fetchSearchResult(pagenum, result)),
    setSearchState: (users) => dispatch(setSearchState(users)),
    fetchOptInResult: (user) => {
      dispatch(fetchOptInResult(user));
    },
    fetchOptOutResult: (user) => {
      dispatch(fetchOptOutResult(user));
    },
    clearGrowerUpdate: () => dispatch(clearGrowerUpdate())
  };
};
const mapStateToProps = function(state) {
  return {
    totalPages: get(state, 'growerList.data.userList.totalPages', 0),
    userList: get(state, 'growerList.data.userList.items', []),
    searchResult: get(state, 'searchList.data.advanceSearchResult.items', ''),
    totalSearch: get(state, 'searchList.data.advanceSearchResult.totalPagesSearch', 1),
    searchResultArr: get(state, 'searchList.data.advanceSearchResult.resultArr', ''),
    growerCount: get(state, 'searchList.data.advanceSearchResult.growersCount', ''),
    searchStatus: get(state, 'searchList.data.advanceSearchResult.status', ''),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', false),
    usersData: get(state, 'searchList.data.users', ''),
    modalVisible: get(state, 'growerUpdateReducer.visible', ''),
    userListOffset: get(state, 'growerList.data.userList.offset', [])
  };
};
ListGrowerData.propTypes = {
  searchText: PropTypes.string,
  fetchSearchResult: PropTypes.func,
  fetchUsersWithFetchAPI: PropTypes.func,
  userList: PropTypes.array,
  searchResults: PropTypes.array,
  totalPages: PropTypes.number
  // alertType: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(ListGrowerData);

import {
  SCHEDULE_MMS_PENDING,
  SCHEDULE_MMS_SUCCESS,
  SCHEDULE_MMS_ERROR,
  CLEAR_SCHEDULE_MMS_STATUS,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_MESSAGE_TABEL_ROW
} from '../constants/index';

const initialState = {
  data: {
    message: {},
    messageError: {},
    messageDrawerVisible: false,
    messageStatus: '',
    rowHighlight: false,
    completed: false,
    loading: true
  }
};

function scheduleMMS(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MMS_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case SCHEDULE_MMS_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageList: action.payload,
          messageStatus: 'scheduleMms',
          completed: false
        }
      });
    case SCHEDULE_MMS_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageError: action.payload,
          messageStatus: 'scheduleMms',
          completed: false
        }
      });
    case CLEAR_SCHEDULE_MMS_STATUS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageStatus: '',
          completed: false
        }
      });
    case TOGGLE_DRAWER_STATUS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageDrawerVisible: !state.messageDrawerVisible
        }
      });
    case HIGHLIGHT_MESSAGE_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };
    default:
      return state;
  }
}

export default scheduleMMS;

import {
  UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING,
  UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS,
  UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR
} from '../constants';

const initialState = {
  file: [],
  progress: 0,
  drawerVisible: false,
  rowHighlight: false,
  selectedSchema: '',
  uploadStatus: '',
  progressStatus: 'active',
  postUploaded: '',
  progressBarStatus: false,
  completed: false
};

export default function uploadYouTubeVideoDetails(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING:
      return {
        ...state,
        completed: true
      };
    case UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS:
      return {
        ...state,
        file: action.payload.res,
        postUploaded: 'youtube',
        completed: false
      };
    case UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR:
      return {
        ...state,
        file: action.payload.res,
        postUploaded: 'youtube',
        completed: false
      };

    default:
      return state;
  }
}

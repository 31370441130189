import React from 'react';
import { Icon } from 'antd';

const growerSVG = () => (
  <svg
    aria-hidden='true'
    focusable='false'
    width='1em'
    height='1em'
    style={{ transform: 'rotate(360deg)' }}
    preserveAspectRatio='xMidYMid meet'
    viewBox='0 0 64 64'>
    <circle cx='47.203' cy='50' r='2.769' fill='#626262' />
    <path
      d='M62 43.901l-.281-.655c-1.581-3.679-4.295-6.494-7.63-8.117L52.04 19.415h.247c.738 0 1.619-.299 1.619-1.723v-1.969c0-1.424-.881-1.723-1.619-1.723h-23.82c-.739 0-1.62.299-1.62 1.723v1.969c0 1.424.881 1.723 1.62 1.723h.063l-1.363 10.451l-20.77 3.146l-.179.211c-.716.846-1.862 3.458-2.373 4.668v-8.968H2v13.846h1.845v-.848c.156 0 .308.017.457.041l-.018 2.694c-.017.013-.035.021-.052.033H2v2.891h2.693A8.283 8.283 0 0 0 2 53.691C2 58.282 5.718 62 10.303 62c4.46 0 8.091-3.522 8.284-7.941h2.407c.01-.186.009-.368.01-.551h14.73C37.234 58.423 41.801 62 47.203 62c6.622 0 11.992-5.373 11.992-12c0-1.548-.303-3.021-.836-4.381L62 43.901M4.313 40.109c-.155-.016-.309-.035-.468-.035v-.577h.472l-.004.612m24.154-22.171a.967.967 0 0 1-.128-.007a1.286 1.286 0 0 1-.017-.239v-1.969c0-.118.008-.194.017-.239a.967.967 0 0 1 .128-.007h23.82a.95.95 0 0 1 .127.007c.008.045.017.121.017.239v1.969c0 .118-.009.194-.017.239a.95.95 0 0 1-.127.007h-23.82m14.562 14.185c-2.533 3.622-7.3.394-7.3 4.954h-6.977l1.845-17.538h1.173v1.799a1.689 1.689 0 0 0-.769 1.416v6.231a1.69 1.69 0 1 0 3.38 0v-6.231a1.69 1.69 0 0 0-1.689-1.692v-1.523h17.277l1.537 14.616a15.764 15.764 0 0 0-4.251-.586l-.054.002v-8.652c-5.102-.001-2.256 4.454-4.172 7.204M7.19 34.385l19.901-3.014v6.648H5.395c.576-1.333 1.325-2.935 1.795-3.634m-1.417 8.338a2.8 2.8 0 0 1 .561.775c-.192.082-.379.187-.568.281l.007-1.056m4.53 15.584a4.613 4.613 0 0 1-4.612-4.615a4.613 4.613 0 1 1 9.225 0a4.616 4.616 0 0 1-4.613 4.615m25.092-6.277H20.918c-.439-3.567-2.538-6.566-5.802-8.177c-2.269-1.119-4.703-1.432-7.009-.923a4.628 4.628 0 0 0-2.32-2.422l.001-.204h19.965l4.312 6.277h5.646a11.977 11.977 0 0 0-.5 3.418c0 .694.071 1.37.184 2.031m11.808 4.431a6.459 6.459 0 0 1-6.457-6.459a6.46 6.46 0 0 1 6.457-6.464a6.462 6.462 0 0 1 0 12.923m.054-18.984c-4.478 0-8.562 2.621-10.821 6.893l-2.123-1.156c2.371-5.128 7.163-8.167 12.944-8.167c5.609 0 10.349 3.026 12.811 8.135l-2.093.988c-2.282-4.149-6.328-6.693-10.718-6.693'
      fill='#ffffff'
    />
    <circle cx='10.303' cy='53.692' r='1.846' fill='#626262' />
  </svg>
);

export function GrowerSVGIcon(props) {
  return <Icon component={growerSVG} {...props} />;
}

const socialSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M625.9 115c-5.9 0-11.9 1.6-17.4 5.3L254 352H90c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h164l354.5 231.7c5.5 3.6 11.6 5.3 17.4 5.3c16.7 0 32.1-13.3 32.1-32.1V147.1c0-18.8-15.4-32.1-32.1-32.1zM586 803L293.4 611.7l-18-11.7H146V424h129.4l17.9-11.7L586 221v582zm348-327H806c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16zm-41.9 261.8l-110.3-63.7a15.9 15.9 0 0 0-21.7 5.9l-19.9 34.5c-4.4 7.6-1.8 17.4 5.8 21.8L856.3 800a15.9 15.9 0 0 0 21.7-5.9l19.9-34.5c4.4-7.6 1.7-17.4-5.8-21.8zM760 344a15.9 15.9 0 0 0 21.7 5.9L892 286.2c7.6-4.4 10.2-14.2 5.8-21.8L878 230a15.9 15.9 0 0 0-21.7-5.9L746 287.8a15.99 15.99 0 0 0-5.8 21.8L760 344z'
        fill='#ffffff'
      />
    </svg>
  );
};
export function SocialSVGIcon(props) {
  return <Icon component={socialSVG} {...props} />;
}

const reportSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M888 792H200V168c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v688c0 4.4 3.6 8 8 8h752c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM305.8 637.7c3.1 3.1 8.1 3.1 11.3 0l138.3-137.6L583 628.5c3.1 3.1 8.2 3.1 11.3 0l275.4-275.3c3.1-3.1 3.1-8.2 0-11.3l-39.6-39.6a8.03 8.03 0 0 0-11.3 0l-230 229.9L461.4 404a8.03 8.03 0 0 0-11.3 0L266.3 586.7a8.03 8.03 0 0 0 0 11.3l39.5 39.7z'
        fill='#ffffff'
      />
    </svg>
  );
};
export function ReportSVGIcon(props) {
  return <Icon component={reportSVG} {...props} />;
}

const mangUserSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4c0-15.9 1.5-31.4 4.3-46.5c.7-3.6-1.2-7.3-4.5-8.8c-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 0 1-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6c24.7-25.3 57.9-39.1 93.2-38.7c31.9.3 62.7 12.6 86 34.4c7.9 7.4 14.7 15.6 20.4 24.4c2 3.1 5.9 4.4 9.3 3.2c17.6-6.1 36.2-10.4 55.3-12.4c5.6-.6 8.8-6.6 6.3-11.6c-32.5-64.3-98.9-108.7-175.7-109.9c-110.8-1.7-203.2 89.2-203.2 200c0 62.8 28.9 118.8 74.2 155.5c-31.8 14.7-61.1 35-86.5 60.4c-54.8 54.7-85.8 126.9-87.8 204a8 8 0 0 0 8 8.2h56.1c4.3 0 7.9-3.4 8-7.7c1.9-58 25.4-112.3 66.7-153.5c29.4-29.4 65.4-49.8 104.7-59.7c3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 0 0-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 0 0 8 8.2h56c4.3 0 7.9-3.4 8-7.7c1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 0 1-37.5-91.8c.3-32.8 13.4-64.5 36.3-88c24-24.6 56.1-38.3 90.4-38.7c33.9-.3 66.8 12.9 91 36.6c24.8 24.3 38.4 56.8 38.4 91.4c-.1 34.2-13.4 66.3-37.6 90.5z'
        fill='#ffffff'
      />
    </svg>
  );
};
export function MangUserSVGIcon(props) {
  return <Icon component={mangUserSVG} {...props} />;
}
const webstoreSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M882 272.1V144c0-17.7-14.3-32-32-32H174c-17.7 0-32 14.3-32 32v128.1c-16.7 1-30 14.9-30 31.9v131.7a177 177 0 0 0 14.4 70.4c4.3 10.2 9.6 19.8 15.6 28.9v345c0 17.6 14.3 32 32 32h676c17.7 0 32-14.3 32-32V535a175 175 0 0 0 15.6-28.9c9.5-22.3 14.4-46 14.4-70.4V304c0-17-13.3-30.9-30-31.9zM214 184h596v88H214v-88zm362 656.1H448V736h128v104.1zm234 0H640V704c0-17.7-14.3-32-32-32H416c-17.7 0-32 14.3-32 32v136.1H214V597.9c2.9 1.4 5.9 2.8 9 4c22.3 9.4 46 14.1 70.4 14.1s48-4.7 70.4-14.1c13.8-5.8 26.8-13.2 38.7-22.1c.2-.1.4-.1.6 0a180.4 180.4 0 0 0 38.7 22.1c22.3 9.4 46 14.1 70.4 14.1c24.4 0 48-4.7 70.4-14.1c13.8-5.8 26.8-13.2 38.7-22.1c.2-.1.4-.1.6 0a180.4 180.4 0 0 0 38.7 22.1c22.3 9.4 46 14.1 70.4 14.1c24.4 0 48-4.7 70.4-14.1c3-1.3 6-2.6 9-4v242.2zm30-404.4c0 59.8-49 108.3-109.3 108.3c-40.8 0-76.4-22.1-95.2-54.9c-2.9-5-8.1-8.1-13.9-8.1h-.6c-5.7 0-11 3.1-13.9 8.1A109.24 109.24 0 0 1 512 544c-40.7 0-76.2-22-95-54.7c-3-5.1-8.4-8.3-14.3-8.3s-11.4 3.2-14.3 8.3a109.63 109.63 0 0 1-95.1 54.7C233 544 184 495.5 184 435.7v-91.2c0-.3.2-.5.5-.5h655c.3 0 .5.2.5.5v91.2z'
        fill='#ffffff'
      />
    </svg>
  );
};
export const WebstoreSVGIcon = (props) => {
  return <Icon component={webstoreSVG} {...props} />;
};
const homestoreSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M946.5 505L560.1 118.8l-25.9-25.9a31.5 31.5 0 0 0-44.4 0L77.5 505a63.9 63.9 0 0 0-18.8 46c.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8a63.6 63.6 0 0 0 18.7-45.3c0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204zm217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7l23.1 23.1L882 542.3h-96.1z'
        fill='#ffffff'
      />
      <rect x='0' y='0' width='1024' height='1024' fill='rgba(0, 0, 0, 0)' />
    </svg>
  );
};
export const HomeSVGIcon = (props) => {
  return <Icon component={homestoreSVG} {...props} />;
};
const helpSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z'
        fill='#ffffff'
      />
      <path
        d='M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7c-21.2 8.1-39.2 22.3-52.1 40.9c-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5c.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0a40 40 0 1 0-80 0z'
        fill='#ffffff'
      />
    </svg>
  );
};
export const HelpSVGIcon = (props) => {
  return <Icon component={helpSVG} {...props} />;
};

const myProfileSVG = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='3em'
      height='3em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 50 50'>
      <path
        d='M25.1 42c-9.4 0-17-7.6-17-17s7.6-17 17-17s17 7.6 17 17s-7.7 17-17 17zm0-32c-8.3 0-15 6.7-15 15s6.7 15 15 15s15-6.7 15-15s-6.8-15-15-15z'
        fill='#ffffff'
      />
      <path
        d='M15.3 37.3l-1.8-.8c.5-1.2 2.1-1.9 3.8-2.7c1.7-.8 3.8-1.7 3.8-2.8v-1.5c-.6-.5-1.6-1.6-1.8-3.2c-.5-.5-1.3-1.4-1.3-2.6c0-.7.3-1.3.5-1.7c-.2-.8-.4-2.3-.4-3.5c0-3.9 2.7-6.5 7-6.5c1.2 0 2.7.3 3.5 1.2c1.9.4 3.5 2.6 3.5 5.3c0 1.7-.3 3.1-.5 3.8c.2.3.4.8.4 1.4c0 1.3-.7 2.2-1.3 2.6c-.2 1.6-1.1 2.6-1.7 3.1V31c0 .9 1.8 1.6 3.4 2.2c1.9.7 3.9 1.5 4.6 3.1l-1.9.7c-.3-.8-1.9-1.4-3.4-1.9c-2.2-.8-4.7-1.7-4.7-4v-2.6l.5-.3s1.2-.8 1.2-2.4v-.7l.6-.3c.1 0 .6-.3.6-1.1c0-.2-.2-.5-.3-.6l-.4-.4l.2-.5s.5-1.6.5-3.6c0-1.9-1.1-3.3-2-3.3h-.6l-.3-.5c0-.4-.7-.8-1.9-.8c-3.1 0-5 1.7-5 4.5c0 1.3.5 3.5.5 3.5l.1.5l-.4.5c-.1 0-.3.3-.3.7c0 .5.6 1.1.9 1.3l.4.3v.5c0 1.5 1.3 2.3 1.3 2.4l.5.3v2.6c0 2.4-2.6 3.6-5 4.6c-1.1.4-2.6 1.1-2.8 1.6z'
        fill='#ffffff'
      />
    </svg>
  );
};

export const MyProfileSVGIcon = (props) => {
  return <Icon component={myProfileSVG} {...props} />;
};

const plusIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='22px'
      height='22px'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z'
        fill-opacity='.8'
        fill='#40a9ff'
      />
      <path
        d='M512 140c-205.4 0-372 166.6-372 372s166.6 372 372 372s372-166.6 372-372s-166.6-372-372-372zm192 396c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z'
        fill-opacity='.1'
        fill='#40a9ff'
      />
      <path
        d='M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z'
        fill-opacity='.8'
        fill='#40a9ff'
      />
    </svg>
  );
};

export const PlusIconCircle = (props) => {
  return <Icon component={plusIcon} {...props} />;
};
const minusIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='22px'
      height='22px'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M696 480H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h368c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z'
        fill='#808080'
      />
      <path
        d='M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372s372 166.6 372 372s-166.6 372-372 372z'
        fill='#808080'
      />
    </svg>
  );
};

export const MinusIconCircle = (props) => {
  return <Icon component={minusIcon} {...props} />;
};
const searchIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='1em'
      height='1em'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1c-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z'
        fill='currentColor'
      />
      <rect x='0' y='0' width='1024' height='1024' fill='rgba(0, 0, 0, 0)' />
    </svg>
  );
};

export const SearchIconCircle = (props) => {
  return <Icon component={searchIcon} {...props} />;
};
const refershIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='32px'
      height='32px'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92C290 92 102.3 279.5 102 511.5C101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1c1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 0 0-10.1 4.8c-1.8 5-3.8 10-5.9 14.9c-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 0 1 655.9 829c-42.3 17.9-87.4 27-133.8 27c-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 0 1 279 755.2a342.16 342.16 0 0 1-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4c31.6-31.6 68.4-56.4 109.3-73.8c42.3-17.9 87.4-27 133.8-27c46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 0 1 109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 0 0 3 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z'
        fill='#40a9ff'
      />
    </svg>
  );
};

export const RefreshIcon = (props) => {
  return <Icon component={refershIcon} {...props} />;
};
const inboxSvg = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='32px'
      height='32px'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M885.2 446.3l-.2-.8l-112.2-285.1c-5-16.1-19.9-27.2-36.8-27.2H281.2c-17 0-32.1 11.3-36.9 27.6L139.4 443l-.3.7l-.2.8c-1.3 4.9-1.7 9.9-1 14.8c-.1 1.6-.2 3.2-.2 4.8V830a60.9 60.9 0 0 0 60.8 60.8h627.2c33.5 0 60.8-27.3 60.9-60.8V464.1c0-1.3 0-2.6-.1-3.7c.4-4.9 0-9.6-1.3-14.1zm-295.8-43l-.3 15.7c-.8 44.9-31.8 75.1-77.1 75.1c-22.1 0-41.1-7.1-54.8-20.6S436 441.2 435.6 419l-.3-15.7H229.5L309 210h399.2l81.7 193.3H589.4zm-375 76.8h157.3c24.3 57.1 76 90.8 140.4 90.8c33.7 0 65-9.4 90.3-27.2c22.2-15.6 39.5-37.4 50.7-63.6h156.5V814H214.4V480.1z'
        fill='#ffffff'
      />
    </svg>
  );
};

export const InboxSvgIcon = (props) => {
  return <Icon component={inboxSvg} {...props} />;
};
const utilityIcon = () => {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      width='32px'
      height='32px'
      style={{ transform: 'rotate(360deg)' }}
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 1024 1024'>
      <path
        d='M688 264c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48zm-8 136H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM480 544H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm-48 308H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h264c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm356.8-74.4c29-26.3 47.2-64.3 47.2-106.6c0-79.5-64.5-144-144-144s-144 64.5-144 144c0 42.3 18.2 80.3 47.2 106.6c-57 32.5-96.2 92.7-99.2 162.1c-.2 4.5 3.5 8.3 8 8.3h48.1c4.2 0 7.7-3.3 8-7.6C564 871.2 621.7 816 692 816s128 55.2 131.9 124.4c.2 4.2 3.7 7.6 8 7.6H880c4.6 0 8.2-3.8 8-8.3c-2.9-69.5-42.2-129.6-99.2-162.1zM692 591c44.2 0 80 35.8 80 80s-35.8 80-80 80s-80-35.8-80-80s35.8-80 80-80z'
        fill='#ffffff'
      />
    </svg>
  );
};
export const UtilitySVGIcon = (props) => {
  return <Icon component={utilityIcon} {...props} />;
};

const whatsAppIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <path
          fill='#4CAF50'
          d='M30.008 0h-.015C13.45 0 0 13.455 0 30a29.778 29.778 0 0 0 5.711 17.584L1.972 58.729l11.532-3.687A29.74 29.74 0 0 0 30.008 60C46.547 60 60 46.541 60 30S46.549 0 30.008 0z'
        />
        <path
          fill='#FAFAFA'
          d='M47.464 42.364c-.724 2.044-3.596 3.739-5.888 4.233-1.567.334-3.615.6-10.507-2.257-8.816-3.653-14.494-12.611-14.936-13.193-.424-.58-3.563-4.743-3.563-9.048s2.186-6.402 3.067-7.302c.724-.738 1.92-1.076 3.068-1.076.371 0 .705.019 1.005.034.881.037 1.324.09 1.905 1.481.724 1.744 2.486 6.049 2.696 6.491.214.443.428 1.043.128 1.624-.282.6-.529.867-.971 1.377-.443.51-.863.9-1.305 1.447-.405.476-.863.986-.353 1.867.51.863 2.273 3.74 4.867 6.05 3.35 2.98 6.064 3.933 7.035 4.338.724.3 1.587.229 2.115-.334.672-.723 1.5-1.923 2.344-3.105.6-.847 1.358-.952 2.153-.652.81.281 5.096 2.4 5.977 2.839.881.442 1.463.652 1.676 1.023.21.371.21 2.115-.513 4.163z'
        />
      </g>
    </svg>
  );
};
export const WhatsAppSVGIcon = (props) => {
  return <Icon component={whatsAppIcon} {...props} />;
};

const wifiIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='48' height='48'>
      <path
        fill='currentColor'
        d='M24.154 2C11.919 2 2 11.924 2 24.165S11.919 46.33 24.154 46.33s22.154-9.924 22.154-22.165S36.389 2 24.154 2zm-.169 32.138L12.976 19.459c3.028-2.294 6.881-3.67 11.009-3.67 4.129 0 7.982 1.376 11.009 3.67L23.985 34.138zm-.945-5.65h1.981v-1.981H23.04v1.981zm0-3.962h1.981v-5.017H23.04v5.017z'></path>
    </svg>
  );
};
export const wifiSVGIcon = (props) => {
  return <Icon component={wifiIcon} {...props} />;
};

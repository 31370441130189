import React from 'react';
import { Avatar, Button, Icon, Input, Form, Upload, Tooltip } from 'antd';
import './Chat.css';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import SearchOutlined from '@ant-design/icons';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { SmileOutlined, SendOutlined, AudioOutlined } from '@ant-design/icons';
import facebook_page from '../../assets/img/facebook-page-icon.png';
import InfiniteScroll from 'react-infinite-scroll-component';
import GlobalSpinner from '../commonComponents/globalSpinner/globalSpinner';
//import InfiniteScroll from 'react-infinite-scroller';
import { openConversationResult, openConversationSuccess } from '../../actions/openConversation';
import moment from 'moment';
const { TextArea } = Input;
class ChatForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedMedia: '',
      channel: '',
      disableButton: false,
      messages: [],
      chatId: '',
      fileList: [],
      chosenEmoji: [],
      conversation: [],
      pageNumber: 0,
      hasMoreData: true
    };
  }

  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      messages: this.state.messages + emoji,
      chosenEmoji: emoji
    });
  };
  onEmojiClick = (event, emojiObject) => {
    this.setState({
      chosenEmoji: emojiObject
    });
  };
  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener('click', this.closeMenu)
      );
    }
  };
  showEmojis = (e) => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener('click', this.closeMenu)
    );
  };
  
  componentWillReceiveProps(nextProps) {
    // nextProps &&
    //   nextProps.conversation &&
    //   nextProps.conversation.map((id) => {
    //     this.setState({
    //       chatId: id.conversationId
    //     });
    //   });
    if (this.props.conversation !== nextProps.conversation) {
      this.setState({
        conversation: [...nextProps.conversation]
      });
    }
  }

  resetForm = () => {
    this.setState({ messages: '' });
  };
  onEnterPress = (e) => {
    if(e.charCode === 13 && !e.shiftKey) {
       this.submitMessage(e);
      e.preventDefault();
    }
    
  }
  submitMessage = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      //   const message = { name: this.state.name, content: values.message };
      //   const formData = new FormData();
      //   formData.append('message', this.state.postDescription);
      const message = {
        // chatId: this.state.chatId,
        // senderId: '',
        // senderName: '',
        // recipientName: '',
        // content: this.state.messages,
        // type: 'text'

        providerChatId: this.props.conversation[0].conversationId,
        senderId: '',
        recipientId: '',
        content: this.state.messages.trim(),
        type: 'text',
        countryCode: this.props.countryCode
      };
      // const sentMessages = {
      //   channelId: this.state.chatId,
      //   content: {
      //     text: this.state.messages
      //   },
      //   direction: 'sent'
      // };

      // stompClient.send(JSON.stringify(message));
      //   this.setState({
      //     messages: values.message
      //     conversation: [...this.state.conversation, sentMessages]
      //   });
      this.props.stompClient.send(`/inbox/chat/${this.props.countryCode}`, {}, JSON.stringify(message));
      // this.setState({
      //   messages: sentMessages,
      //   conversation: [...this.state.conversation, ...this.state.messages]
      // });
      //   this.props.openConversation(this.state.chatId);
      // this.props.getChatId(this.state.chatId);
      this.resetForm();

      //   this.openConversation(this.state.chatId);
    });
    // this.props.form.resetFields();
    // on submitting the ChatInput form, send the message, add it to the list and reset the input
  };
  fetchMoreData = () => {
    setTimeout(() => {
      this.setState((prevState, props) => {
        return { pageNumber: prevState.pageNumber + 1 };
      });
    }, 1500);
    this.props.openConversationResult(
      this.props.conversation[0].conversationId,
      this.state.pageNumber,
      this.props.countryCode
    );
  };
  onKeyPress = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      this.submitMessage();
    } else if (e.keyCode === 13 && e.shiftKey) {
      // block enter
      e.preventDefault();
    }
  };
  render() {
    const { conversation, totalCount, loading, offsetCount } = this.props;
    const { uploading, fileList } = this.state;
    const arr = conversation.map((item) => item);
   
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },
      onChange: this.handleChange,
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },

      multiple: false,
      fileList,
      accept: '.xlsx,.xls,.csv'
    };

    const { getFieldDecorator } = this.props.form;
    return (
      <div className='chat'>
        <div className='chat_header'>
          {/* <Avatar>
            <Icon type='user' />
          </Avatar> */}
          <Avatar style={{ backgroundColor: '#87d068' }} icon='user' />
          <div className='chat_headerInfo'>
            <h3>{`+${this.props.chatNumber}`}</h3>
            {/* <p>Last seen 4/12/2021, 10:26:12 AM</p> */}
          </div>
          <div className='chat_headerRight'>
            {/* <Button style={{ background: 'none', border: 'none' }}>
              <Icon type='search' />
            </Button>
            <Button style={{ background: 'none', border: 'none' }}>
              <Icon type='paper' />
            </Button>
            <Button style={{ background: 'none', border: 'none' }}>
              <Icon type='more' />
            </Button> */}
          </div>
        </div>
        <div className='chat_body' id='scrollableDiv'>
          {/* <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchMoreData}
            hasMore={offsetCount < totalCount && !loading}
            useWindow={false}
            loader={<div className='loader'>Loading ...</div>}> */}
          <InfiniteScroll
            dataLength={conversation.length}
            next={this.fetchMoreData}
            style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            inverse={true} //
            hasMore={conversation.length < totalCount && !loading}
            loader={<h4 style={{ color: '#000000' }}>Loading...</h4>}
            scrollableTarget='scrollableDiv'>
            {this.props &&
              this.props.conversation &&
              this.props.conversation.map((message) =>
                message.status !== 'deleted' || !message.status ? (
                  message.direction === 'sent' ? (
                    message.type === 'image' ? (
                      <div className='chat_messageSent'>
                        <img src={message.content.image.url} alt='Loading...' />
                        <span className='caption'>{message.content.image.caption}</span>
                        <div className='chat_timestamp_Sent'>
                          {moment(message.createdDatetime).format('LT')}{' '}
                          {message.status === 'pending' ? '✓' : '✓'}
                        </div>
                      </div>
                    ) : message.type === 'video' ? (
                      <p className={`chat_message ${message.name} 'chat_receiver'}`}>
                        <video width='400' controls alt='Loading...'>
                          <source
                            src={message.content.video.url}
                            // type={this.state.selectedAttachment.file.type}
                          />
                        </video>
                        <span className='caption'>{message.content.video.caption}</span>
                        <div className='chat_timestamp_Sent'>
                          {moment(message.createdDatetime).format('LT')}{' '}
                          {message.status === 'pending' ? '✓' : '✓'}
                        </div>
                      </p>
                    ) : message.type === 'audio' ? (
                      <p className={`chat_message ${message.name} 'chat_receiver'}`}>
                        <audio controls>
                          <source
                            src={message.content.audio.url}
                            // type={this.state.selectedAttachment.file.type}
                          />
                        </audio>
                        <div className='chat_timestamp_Sent'>
                          {moment(message.createdDatetime).format('LT')}{' '}
                          {message.status === 'pending' ? '✓' : '✓'}
                        </div>
                      </p>
                    ) : message.type === 'hsm' ? (
                      <div className='chat_messageSent' style={{ color: '#000' }}>
                        {/* <img
                          src={_get(
                            message,
                            'content.hsm.components[0].parameters[0].image.url',
                            ''
                          )}
                          alt={_get(message, 'content.hsm.templateName', '')}
                        /> */}
                        <span className='chat_messageText'>
                          Template Name: {_get(message, 'content.hsm.templateName', '')}
                        </span>
                        <span style={{ display: 'inline-block', width: '54px' }}></span>
                        <div className='chat_timestamp_Sent'>
                          {moment(message.createdDatetime).format('LT')}{' '}
                          {message.status === 'pending' ? '✓' : '✓'}
                        </div>
                       
                      </div>
                    ) : (
                      <div className='chat_messageSent'>
                         
                        <div>
                          <span className='chat_messageText'>
                            {message.content && message.content.text}
                          </span>
                          <span style={{ display: 'inline-block', width: '54px' }}></span>
                        </div>
                        <div className='chat_timestamp_Sent'>
                          {moment(message.createdDatetime).format('LT')}{' '}
                          {message.status === 'pending' ? '✓' : '✓'}
                        </div>
                        
                      </div>
                    )
                  ) : message.type === 'image' ? (
                    <p className={`chat_message ${message.name} 'chat_receiver'}`}>
                      <img src={message.content.image.url} alt='Loading...' />
                      <span className='caption'>{message.content.image.caption}</span>
                      <div className='chat_timestamp_Media'>
                        {moment(message.createdDatetime).format('LT')}
                      </div>
                    </p>
                  ) : message.type === 'video' ? (
                    <p className={`chat_message ${message.name} 'chat_receiver'}`}>
                      <video width='400' height controls alt='Loading...'>
                        <source
                          src={message.content.video.url}
                          // type={this.state.selectedAttachment.file.type}
                        />
                      </video>
                      <span className='caption'>{message.content.video.caption}</span>
                      <div className='chat_timestamp_Media'>
                        {moment(message.createdDatetime).format('LT')}
                      </div>
                    </p>
                  ) : message.type === 'audio' ? (
                    <p className={`chat_message ${message.name} 'chat_receiver'}`}>
                      <audio controls>
                        <source
                          src={message.content.audio.url}
                          // type={this.state.selectedAttachment.file.type}
                        />
                      </audio>
                      <div className='chat_timestamp_Sent'>
                        {moment(message.createdDatetime).format('LT')}{' '}
                        {message.status === 'pending' ? '✓' : '✓'}
                      </div>
                     
                    </p>
                  ) : message.type === 'hsm' ? (
                    <div className={`chat_message ${message.name} 'chat_receiver'}`}>
                      {/* <img
                        src={_get(message.content.hsm.components[0].parameters[0].image.url, '')}
                        alt={_get(message, 'content.hsm.templateName', '')}
                      /> */}
                      <span className='chat_messageText'>
                        {_get(message, 'content.hsm.templateName', '')}
                      </span>
                      <span style={{ display: 'inline-block', width: '54px' }}></span>
                      <div className='chat_timestamp'>
                        {moment(message.createdDatetime).format('LT')}
                      </div>
                    </div>
                  ) : (
                    <div className={`chat_message ${message.name} 'chat_receiver'}`}>
                      <div>
                        {/* <span className='chat_name'>{message} </span> */}
                        <span className='chat_messageText'>
                          {message.content && message.content.text}
                        </span>
                        <span style={{ display: 'inline-block', width: '54px' }}></span>
                      </div>
                      <div className='chat_timestamp'>
                        {moment(message.createdDatetime).format('LT')}
                      </div>
                    </div>
                  )
                ) : (
                  ''
                )
              )}
          </InfiniteScroll>
          <GlobalSpinner />
        </div>

        {/* <Button style={{ background: 'none', border: 'none' }}>
            <SmileOutlined />
          </Button> */}
        <div className='chat_footer'>
          {this.state.showEmojis && (
            <div style={styles.emojiPicker} ref={(el) => (this.emojiPicker = el)}>
              <Picker onSelect={this.addEmoji} emojiTooltip={true} title='whatsapp' />
            </div>
          )}
          <p style={styles.getEmojiButton} onClick={this.showEmojis}>
            {String.fromCodePoint(0x1f60a)}
          </p>

          <Form onSubmit={this.submitMessage} layout='inline' ref={el => this.myFormRef = el}>
            {/* <Form.Item style={{ width: '5%' }}></Form.Item> */}
            <Form.Item style={{ width: '100%' }}>
              {/* {getFieldDecorator(
                'message',
                {}
              )( */}
              <TextArea
                // value={input}
                className='chatInputBox'
                style={{ height: 'fit-content', resize: 'none' }}
                type='text'
                rows={1}
                placeholder='Type a message'
                value={this.state.messages}
                onKeyPress={this.onEnterPress}
                // onPressEnter={this.onKeyPress}
                //   suffix={<Icon type='paper-clip' />}
                onChange={(e) => this.setState({ messages: e.target.value })}
              />
              {/* )} */}
            </Form.Item>
            {/* <Form.Item>
              {getFieldDecorator(
                'upload',
                {}
              )(
                <Upload
                  name='logo'
                  action='/upload.do'
                  {...props}
                  fileList={this.state.fileList}
                  listType='picture'>
                  <Button
                    onClick={(e) => {
                      //   this.props.clearProgressStatus();
                      this.setState({
                        fileList: []
                      });
                    }}>
                    <Icon type='paper-clip' />
                  </Button>
                </Upload>
              )}
            </Form.Item> */}
            <Form.Item>
              <Tooltip title='Send Message'>
                <Button
                  // disabled={this.state.message}
                  //   disabled={this.state.messages}
                  id='btnSendMessage'
                  style={{ backgroundColor: '#87d068', color: '#ffffff' }}
                  htmlType='submit'>
                  <SendOutlined />
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
          {/* <Button style={{ background: 'none', border: 'none' }}>
            <AudioOutlined />
          </Button> */}
        </div>
      </div>
    );
  }
}
const Chat = Form.create({ name: 'coordinated' })(ChatForm);
const mapDispatchToProps = function(dispatch) {
  return {
    getConversationResult: () => dispatch(getConversationResult()),
    openConversationResult: (id, page, countryCode) => dispatch(openConversationResult(id, page, countryCode))
  };
};
// export default OnBoard;
const mapStateToProps = function(state) {
  return {
    conversationResult: _get(state, 'getConversationList.data.conversationList.items', []),
    openConversationList: _get(state, 'openConversationList.data.openConversationList.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chat);

const styles = {
  getEmojiButton: {
    // cssFloat: 'left',
    border: 'none',
    margin: 0,
    cursor: 'pointer',
    display: 'flex',
    // border: '1px solid #f2f2f2',
    // backgroundColor: '#fff',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  emojiPicker: {
    position: 'absolute',
    // bottom: 31,
    top: 150,
    // right: 0,
    zIndex: 99
    // height: '24px',
    // width: '24px',
    // cssFloat: 'right',
    // marginLeft: '200px'
  }
};

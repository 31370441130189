import React, { Component } from 'react';
import {
  Form,
  Icon,
  Radio,
  Button,
  Modal,
  Upload,
  Input,
  Progress,
  Card,
  Skeleton,
  Switch,
  Typography,
  Col,
  Row,
  message,
  Divider,
  Collapse,
  Select,
  Avatar
} from 'antd';
import PropTypes from 'prop-types';
import socialSVG from '../../../assets/img/MessageSvg/social.svg';
import { Translation } from 'react-i18next';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import facebook_page from '../../../assets/img/facebook-page-icon.png';

import './selectMedia.css';
import { LikeOutlined, CommentOutlined, ShareAltOutlined } from '@ant-design/icons';
import LocationSearch from './locationSearch';
/****** MessgeType Icons *********/
import {
  facebookIconSVG,
  youtubeIconSVG,
  instagramIconSVG,
  twitterIconSVG,
  imageSVG,
  location,
  media,
  textSVGIcon
} from '../messageSvg/messageSvgIcons';

const FormItem = Form.Item;
const { Meta } = Card;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
// const Link = Scroll.Link;
const { Text, Paragraph } = Typography;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class SelectMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      width: '320',
      fileName: '',
      visible: false,
      drawerVisible: false,
      fileList: [],
      fbFileList: [],
      uploading: false,
      filevalid: false,
      isUpdated: false,
      footerVisible: false,
      showEmojis: false,
      locationName: '',
      accordionVisible: 'false',
      emoji: [],
      chosenEmoji: [],
      mediaType: 'youtube',
      loading: true,
      title: '',
      postDescription: '',
      previewVisible: false,
      previewObj: '',
      previewImage: '',
      previewVideo: '',
      selectedAttachment: null,
      imageUrl: '',
      textAreaValue: '',
      snippet: {
        title: {},
        description: {}
      },
      thumbnailData: {}
    };
    this.thumbnailValue = [];
  }
  onMediaChange = (e) => {
    this.setState({
      mediaType: e.target.value
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (value) => {
    const fileData = this.state.fileList;
    const videoStatus = {
      status: {
        privacyStatus: 'unlisted'
      },
      fileData
    };
    this.props.uploadYouTubeVideoService(videoStatus);
  };
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };
  handlePreviewCancel = (e) => {
    this.setState({
      visible: false
    });
  };
  switchChange = (checked) => {
    this.setState({ loading: !checked });
  };
  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      textAreaValue: this.state.postDescription + emoji,
      postDescription: this.state.postDescription + emoji,
      chosenEmoji: emoji
    });
  };
  showEmojis = (e) => {
    this.setState(
      {
        showEmojis: true
      },
      () => document.addEventListener('click', this.closeMenu)
    );
  };
  closeMenu = (e) => {
    if (this.emojiPicker !== null && !this.emojiPicker.contains(e.target)) {
      this.setState(
        {
          showEmojis: false
        },
        () => document.removeEventListener('click', this.closeMenu)
      );
    }
  };
  viewAttachment = (file) => {
    let reader = new FileReader();

    //if reading completed
    reader.onload = (e) => {
      //set values of selected attachment
      let newSelectedAttachment = {};
      newSelectedAttachment.file = file;
      newSelectedAttachment.blobData = e.target.result;

      //if file type is image then show the attachment or download the same
      if (file.type.includes('video')) {
        this.setState({
          selectedAttachment: newSelectedAttachment
        });
      }
    };

    //read the file
    reader.readAsDataURL(file);
  };
  next() {
    this.showModal();
    this.props.clearYoutubeVideoStatus();
    this.setState({
      fileList: []
    });
    // this.props.toggleDrawer();
  }
  getThumbnailValue = (e, values) => {
    e.preventDefault();
    const selectedThumbnail = { [e.target.value]: values[e.target.value] };
    this.setState({
      thumbnailData: selectedThumbnail
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const data = {
          id: this.props.videoId,
          snippet: {
            title: values.Title,
            description: values.Description,
            categoryId: this.props.categoryId,
            thumbnails: this.state.thumbnailData
          }
        };

        this.props.uploadYouTubeVideoDetailService(data);
        this.setState({
          visible: false
        });
        this.props.toggleDrawer();
        // this.props.clearYoutubeVideoStatus();
      }
    });
  };
  handleFacebookSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        const fileType = values.file !== undefined ? values.file.file.type : '';
        const formData = new FormData();

        // formData.append('message', values.message);
        // formData.append('source', values.file.file.originFileObj);

        if (values.file !== undefined) {
          formData.append('message', this.state.postDescription);
          //formData.append('caption', 'sample caption');
          formData.append('source', values.file.file.originFileObj);
        } else {
          formData.append('message', this.state.postDescription);
        }

        const data = {
          message: this.state.postDescription,
          source: this.state.fileList
        };

        this.props.uploadFacebookPost(formData, fileType);
        this.setState({
          visible: false
        });
        this.props.toggleDrawer();
        // this.props.clearYoutubeVideoStatus();
      }
    });
  };
  onEmojiClick = (event, emojiObject) => {
    this.setState({
      chosenEmoji: emojiObject
    });
  };
  enableFooter = () => {
    if (this.props.checkStatus === 'uploaded') {
      this.setState({ footerVisible: true });
    }
  };
  handleClick = (e) => {
    this.props.clearYoutubeVideoStatus();
    this.setState({
      fileList: []
    });
  };
  handlePostChange = (e) => {
    this.setState({
      postDescription: e.target.value,
      loading: false
    });
  };
  handleLocationChange = (value) => {
    this.setState({
      locationName: value
    });
  };
  addLocation = (e) => {
    this.setState({
      addLocation: true
    });
  };
  handleUploadCancel = () => this.setState({ previewVisible: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    if (file.originFileObj.type.includes('image')) {
      this.setState({
        previewImage: file.url || file.preview,
        previewVisible: true
      });
    } else if (file.originFileObj.type.includes('video')) {
      this.setState({
        previewVideo: file.url || file.preview,
        previewVisible: true
      });
    }
  };
  handleRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: [],
        filevalid: false,
        previewImage: '',
        previewVideo: ''
      };
    });
  };
  handleUploadChange = (fileList) => {
    if (fileList.length > 1) {
      fileList = [fileList[fileList.length - 1]];
    }
    this.handlePreview(fileList.file);
    this.setState({ ...fileList, loading: false });
  };
  handleChange = (info) => {
    this.setState({
      fileName: info.file.name
    });

    const file = info.file;
    let fileList = [...info.fileList];
    // fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList.length > 1) {
      fileList = [fileList[fileList.length - 1]];
    }
    this.setState({ fileList });

    const videoStatus = {
      status: {
        privacyStatus: 'unlisted'
      },
      file
    };
    this.props.uploadYouTubeVideoService(videoStatus);
  };
  fbhandleChange = (info) => {
    this.setState({
      fileName: info.file.name
    });

    if (info.file.status === 'done') {
      this.setState({ fileList });
    }
  };
  render() {
    const {
      uploading,
      fileList,
      fileName,
      mediaType,
      chosenEmoji,
      loading,
      emoji,
      postDescription,
      previewVisible,
      previewImage,
      previewVideo,
      previewObj
    } = this.state;

    const {
      thumbnail,
      uploadStatus,
      progress,
      progressStatus,
      categoryId,
      videoId,
      checkStatus,
      processingStatus
    } = this.props;

    const props2 = {
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onRemove: this.handleRemove,
      onChange: this.handleUploadChange,
      defaultFileList: [...fileList],
      className: 'upload-list-inline',
      multiple: false,
      name: 'file',
      listType: 'picture',
      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068'
        },
        strokeWidth: 3,
        format: (percent) => `${parseFloat(percent.toFixed(2))}%`
      }
    };
    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },

      onChange: this.handleChange,
      beforeUpload: (file) => {
        this.setState({
          fileList: []
        });
        return false;
      },

      multiple: false,
      fileList,
      accept: '.mp4'
    };

    const props3 = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },

      onChange: this.fbhandleChange,
      beforeUpload: (file) => {
        // this.setState((state) => ({
        //   fileList: [...state.fileList, file]
        // }));
        this.setState({
          fileList: []
        });
        return false;
      },
      // onProgress: () => {
      // },
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      multiple: false,
      fileList,
      showUploadList: true,
      listType: 'picture'
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className='thirdDiv'>
          <p style={{ marginBottom: '2em', textAlign: 'center' }}>
            <Translation>{(t) => <>{t('Select Media')}</>}</Translation>
          </p>
          <img
            style={{ height: '210px', position: 'relative', left: '48px' }}
            alt='Create New Content'
            src={socialSVG}
          />
          <FormItem className='messagtype'>
            {getFieldDecorator('messageType', {
              rules: [
                {
                  required: true,
                  message: 'Please select  media'
                }
              ],
              initialValue: 'youtube'
            })(
              <Radio.Group size='large' onChange={this.onMediaChange}>
                <Radio.Button title='youtube' value='youtube'>
                  <Icon
                    title='youtube'
                    className='firstStepTextualIcon'
                    component={youtubeIconSVG}
                  />
                  <p>
                    <Translation>{(t) => <>{t('YouTube')}</>}</Translation>
                  </p>
                </Radio.Button>
                <Radio.Button title='facebook' value='facebook'>
                  <Icon
                    title='facebook'
                    className='firstStepTextualIcon'
                    component={facebookIconSVG}
                  />
                  <p>
                    <Translation>{(t) => <>{t('Facebook')}</>}</Translation>
                  </p>
                </Radio.Button>
              </Radio.Group>
            )}
          </FormItem>
          <FormItem>
            <Button
              className='interactNxt'
              onClick={() => this.next()}
              style={{ position: 'relative', left: '10px' }}>
              <Translation>{(t) => <>{t('Next')}</>}</Translation>
            </Button>
          </FormItem>
        </div>
        {mediaType === 'youtube' ? (
          <Modal
            title={checkStatus === 'uploaded' ? fileName.replace('.mp4', '') : 'Upload Videos'}
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            closable={true}
            width={920}
            centered={true}
            bodyStyle={{ height: 460, padding: 20 }}
            footer={
              checkStatus.length
                ? [
                    <Button type='primary' htmlType='submit' onClick={this.handleSubmit}>
                      <Translation>{(t) => <>{t('Post Now')}</>}</Translation>
                    </Button>
                  ]
                : false
            }>
            {checkStatus.length ? null : (
              <>
                <Row gutter={16}>
                  <Col span={12} offset={6}>
                    <Form.Item>
                      <Dragger
                        {...props}
                        fileList={this.state.fileList}
                        onDragEnter={(e) => {
                          this.props.clearYoutubeVideoStatus();
                          this.setState({
                            fileList: []
                          });
                        }}>
                        <p className='ant-upload-drag-icon'>
                          <Icon type='upload' />
                        </p>
                        <p className='ant-upload-text'>
                          <Translation>
                            {(t) => <>{t('Drag and drop video file to upload')}</>}
                          </Translation>
                        </p>
                        <Button
                          type='primary'
                          htmlType='submit'
                          style={{ position: 'relative', top: '120px', width: '400px' }}
                          onClick={(e) => {
                            this.props.clearYoutubeVideoStatus();
                            this.setState({
                              fileList: []
                            });
                          }}
                          onChange={this.handleOk}>
                          <Translation>{(t) => <>{t('SELECT A VIDEO')}</>}</Translation>
                        </Button>
                      </Dragger>
                    </Form.Item>

                    <Form.Item>
                      {fileList.length > 0 ? (
                        <Progress
                          className='progressPostion'
                          percent={fileList.length === 0 ? 0 : progress}
                          showInfo={true}
                          status={fileList.length === 0 ? 'active' : progressStatus}
                        />
                      ) : null}
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}

            {checkStatus === 'uploaded' ? (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <p
                      style={{
                        textAlign: 'left',
                        fontWeight: 'bold',
                        marginBottom: '0px',
                        fontSize: '14px'
                      }}>
                      <Translation>{(t) => <>{t('Details')}</>}</Translation>
                    </p>
                    <Form onSubmit={this.handleSubmit} className='login-form' method='post'>
                      <Form.Item>
                        {getFieldDecorator('Title', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{ required: true, message: 'Please enter a title' }]
                        })(<Input placeholder='Enter Post Title (Required)' size='small' />)}
                      </Form.Item>
                      <Form.Item>
                        {getFieldDecorator('Description', {
                          validateTrigger: ['onChange', 'onBlur'],
                          rules: [{ required: false, message: 'Please enter a description' }]
                        })(
                          <TextArea
                            placeholder='Tell viewers about your video'
                            size='large'
                            rows={8}
                          />
                        )}
                      </Form.Item>

                      <FormItem className=''>
                        <p
                          style={{
                            textAlign: 'left',
                            fontWeight: 'bold',
                            marginBottom: '0px',
                            fontSize: '14px'
                          }}>
                          <Translation>{(t) => <>{t('Thumbnail')}</>}</Translation>
                        </p>
                        {getFieldDecorator('thumbnailType', {
                          initialValue: 'default'
                        })(
                          <Radio.Group
                            size='large'
                            className='thumbnail'
                            onChange={(e) => this.getThumbnailValue(e, thumbnail)}>
                            {processingStatus === 'succeeded'
                              ? Object.entries(thumbnail).map(([key, value]) => {
                                  return (
                                    <Radio.Button value={key}>
                                      <img
                                        src={value.url}
                                        width={value.width}
                                        height={value.height}
                                      />
                                      <label>{key}</label>
                                    </Radio.Button>
                                  );
                                })
                              : 'loading...'}
                          </Radio.Group>
                        )}
                      </FormItem>
                    </Form>
                  </Col>

                  <Col span={12}>
                    <Card
                      style={{
                        padding: '0px',
                        border: 'none'
                      }}>
                      <iframe
                        width='343'
                        height='200'
                        src={`https://www.youtube.com/embed/${videoId}`}
                        controls='0'></iframe>
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          padding: '5px',
                          flexDirection: 'column'
                        }}>
                        <span>
                          <Translation>{(t) => <>{t('Video Link')}</>}</Translation>
                        </span>
                        <span>
                          <Text copyable>
                            {' '}
                            <a>{`https://www.youtu.be.com/${videoId}`}</a>
                          </Text>
                        </span>
                      </div>
                      <div style={{ padding: '5px' }}>
                        <p style={{ marginBottom: '0px' }}>
                          <Translation>{(t) => <>{t('Filename')}</>}</Translation>
                        </p>
                        {fileName}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : null}
          </Modal>
        ) : (
          <Modal
            title='New Post'
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            closable={true}
            width={920}
            centered={true}
            bodyStyle={{ height: 380, padding: 0 }}
            footer={[
              <Button type='primary' htmlType='submit' onClick={this.handleFacebookSubmit}>
                <Translation>{(t) => <>{t('Post Now')}</>}</Translation>
              </Button>
            ]}>
            {checkStatus.length ? null : (
              <>
                <Row gutter={16}>
                  <Col span={12}>
                    <div className='postContent'>
                      {/* <Card title='Post to Syngenta Pakistan'></Card> */}
                      <span style={{ paddingLeft: '13px', fontWeight: '500', fontSize: 'medium' }}>
                        <Translation>{(t) => <>{t('Post to Syngenta Pakistan')}</>}</Translation>
                      </span>
                      <Card className='writePost' title='Text' bordered={false}>
                        <Icon
                          component={textSVGIcon}
                          style={{ position: 'absolute', top: '21px' }}
                        />
                        <Form.Item>
                          <TextArea
                            placeholder='Enter your text and links..'
                            size='large'
                            rows={8}
                            onChange={this.handlePostChange}
                            value={this.state.postDescription}
                          />
                          {/* )} */}
                        </Form.Item>
                        <Form.Item style={{ top: '-14px' }}>
                          {this.state.showEmojis ? (
                            <div style={styles.emojiPicker} ref={(el) => (this.emojiPicker = el)}>
                              <Picker
                                onSelect={this.addEmoji}
                                emojiTooltip={true}
                                title='facebook'
                              />
                            </div>
                          ) : (
                            <p style={styles.getEmojiButton} onClick={this.showEmojis}>
                              {String.fromCodePoint(0x1f60a)}
                            </p>
                          )}
                        </Form.Item>
                      </Card>
                      <Card title='Media' className='uploadMedia' bordered={false}>
                        <Icon component={media} style={{ position: 'absolute', top: '16px' }} />

                        {/* <Modal
                          visible={previewVisible}
                          footer={null}
                          onCancel={this.handlePreviewCancel}>
                          <img alt='example' style={{ width: '100%' }} src={previewImage} />
                        </Modal> */}
                        <Form.Item>
                          {getFieldDecorator('file', {
                            validateTrigger: ['onChange', 'onBlur'],
                            rules: [{ required: false, message: 'Select Media' }]
                          })(
                            <Dragger
                              {...props2}
                              // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
                              // onPreview={this.handlePreview}
                              // listType='picture'
                              multiple={false}
                              // onRemove={this.handleRemove}
                              // onChange={this.handleUploadChange}
                              fileList={fileList}>
                              <p className='ant-upload-drag-icon'>
                                <Icon type='upload' />
                              </p>
                              <p className='ant-upload-text'>
                                <Translation>
                                  {(t) => <>{t('Drag and drop files here')}</>}
                                </Translation>{' '}
                                <span style={{ color: '#40a9ff', fontWeight: '300' }}>
                                  <Translation>
                                    {(t) => <>{t('or select files to upload')}</>}
                                  </Translation>
                                </span>
                              </p>
                            </Dragger>
                          )}
                        </Form.Item>

                        {/* <Form.Item>
                          {fileList.length > 0 ? (
                            <Progress
                              className='progressPostion'
                              percent={fileList.length === 0 ? 0 : progress}
                              showInfo={true}
                              status={fileList.length === 0 ? 'active' : progressStatus}
                            />
                          ) : null}
                        </Form.Item> */}
                      </Card>

                      <Form.Item>
                        <Divider />
                        <Card
                          className='location'
                          title='Location'
                          bordered={false}
                          suffix='d'
                          prefix='dd'
                          extra={
                            <Button type='link' onClick={this.addLocation}>
                              <Translation>{(t) => <>{t('Add')}</>}</Translation>
                            </Button>
                          }>
                          <Icon
                            component={location}
                            style={{ position: 'absolute', top: '18px', left: '5px' }}
                          />

                          {this.state.addLocation ? (
                            // <Collapse accordion>
                            //   <Panel key='1' showArrow={false}>
                            <Card style={{ backgroundColor: '#fff' }}>
                              <Input
                                placeholder='Search for a location'
                                prefix={<Icon type='facebook' />}
                                allowClear
                                onChange={this.handleLocationChange}
                              />

                              <LocationSearch />
                            </Card>
                          ) : (
                            <p style={{ marginLeft: '30px' }}>
                              <Translation>{(t) => <>{t('No Locations applied')}</>}</Translation>.
                            </p>
                          )}
                        </Card>
                        <Divider />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col span={12} className='fbPreview'>
                    <FormItem>
                      <Card
                        title={
                          <span style={{ color: '#fff' }}>
                            <Icon type='facebook' theme='filled' />
                            <span style={{ marginLeft: '6px', fontWeight: '400' }}>
                              <Translation>{(t) => <>{t('Facebook Page')}</>}</Translation>
                            </span>
                          </span>
                        }
                        className='postPreview'
                        style={{
                          backgroundColor: '#35382F',
                          height: '380px',
                          paddingTop: '30px',
                          overflowY: 'scroll'
                        }}>
                        <Card
                          type='inner'
                          style={{ backgroundColor: '#fff' }}
                          // loading={loading}
                          actions={[
                            <div>
                              <LikeOutlined theme='filled' />
                              <Translation>{(t) => <>{t('Like')}</>}</Translation>
                            </div>,
                            <div>
                              <CommentOutlined />
                              <Translation>{(t) => <>{t('Comment')}</>}</Translation>
                            </div>,
                            <div>
                              <ShareAltOutlined />
                              <Translation>{(t) => <>{t('Share')}</>}</Translation>
                            </div>
                          ]}>
                          {' '}
                          <Skeleton avatar active loading={loading}>
                            <Meta
                              avatar={<Avatar src={facebook_page} />}
                              title='Syngenta Pakistan'
                              description='just Now'
                            />
                            <div>{this.state.postDescription}</div>
                            {fileList.length > 0 ? (
                              <div>
                                {previewImage.length ? (
                                  <img style={{ width: '100%' }} src={previewImage} />
                                ) : (
                                  ''
                                )}
                              </div>
                            ) : (
                              ''
                            )}
                            {previewVideo.length ? (
                              <video width='400' controls>
                                <source
                                  src={previewVideo}
                                  // type={this.state.selectedAttachment.file.type}
                                />
                                <Translation>
                                  {(t) => <>{t('Your browser does not support HTML5 video')}</>}
                                </Translation>
                                .
                              </video>
                            ) : (
                              ''
                            )}
                          </Skeleton>
                        </Card>
                      </Card>
                    </FormItem>
                  </Col>
                </Row>
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
}
SelectMedia.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
const SelectMediaForm = Form.create({})(SelectMedia);

export default SelectMediaForm;
const styles = {
  getEmojiButton: {
    // cssFloat: 'left',
    border: 'none',
    margin: 0,
    cursor: 'pointer',
    display: 'flex',
    border: '1px solid #f2f2f2',
    backgroundColor: '#fff',
    paddingLeft: '10px'
  },
  emojiPicker: {
    position: 'relative',
    bottom: 0,
    top: 7,
    right: 0
    // height: '24px',
    // width: '24px',
    // cssFloat: 'right',
    // marginLeft: '200px'
  }
};

import React, { Component } from 'react';
import { Drawer, Card, Form, Modal } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { toggleDrawer } from '../../../../actions/messageSending';
import { toggleDrawerStatus } from '../../../../actions/scheduleMessage';
import GlobalSpinner from '../../../commonComponents/globalSpinner/globalSpinner';
import { messageSendingService } from '../../../../services/messageSendingService';
import {
  messageSchedulingService,
  clearScheduleMessageStatus
} from '../../../../actions/scheduleMessage';
import {
  fetchWhatsAppTemplateData,
  fetchwhatsAppTemplateDataClear
} from '../../../../actions/whatsAppTemplateData';
import { fetchWhatsAppTemplate } from '../../../../actions/whatsAppTemplate';
import { fetchCostEstimator, fetchCostEstimatorClear } from '../../../../actions/costEstimator';
import { sendMMS } from '../../../../actions/sendMMS';
import { scheduleMMS } from '../../../../actions/scheduleMMS';
import StepsForm from './stepForm/stepsForm';

const { confirm } = Modal;

// import "./newMessageDrawer.css";

class NewMessageDrawer extends Component {
  state = {
    current: 0,
    visible: false
  };

  constructor(props) {
    super(props);

    this.DrawerOnClose = this.DrawerOnClose.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
  }

  handleGtypeSelectChange = (Gvalue) => {
    // this.props.form.setFieldsValue({
    //   note: `Hi, ${value === "male" ? "man" : "lady"}!`
    // });
  };

  DrawerOnClose() {
    if (this.props.messageDrawerVisible) {
      this.props.toggleDrawer();
    } else if (this.props.messageDrawer) {
      this.props.toggleDrawerStatus();
    }
    this.props.fetchCostEstimatorClear();
    this.props.fetchwhatsAppTemplateDataClear();
    // this.showDeleteConfirm(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.messageDrawerVisible === 'true') {
      this.setState({
        visible: messageDrawerVisible
      });
    } else if (nextProps.messageDrawer === 'true') {
      this.setState({
        visible: messageDrawer
      });
    }
  }
  showDeleteConfirm(propsdata) {
    confirm({
      title: 'Are you sure close this task?',
      content: 'After closing you loose your data',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        propsdata.toggleDrawer();
      },
      onCancel() {}
    });
  }

  render() {
    const { t, templateList, templateListContent, communicationModes } = this.props;
    console.log('communicationModes new>', communicationModes);
    return (
      <div>
        <Drawer
          title={t('CREATE A NEW MESSAGE')}
          placement='right'
          closable={true}
          onClose={this.DrawerOnClose}
          visible={this.props.messageDrawerVisible}
          mask={true}
          maskClosable={false}
          destroyOnClose={true}
          width={this.props.countryData === 'latam' ? 465 : 440}>
          <Card hoverable={false} bordered={false} className='NewMessgeCard'>
            <StepsForm
              {...this.props}
              groupList={this.props.groupList}
              communicationModes={this.props.communicationModes}
              sendMessage={this.props.sendMessage}
              sendMMS={this.props.sendMMS}
              scheduleMMS={this.props.scheduleMMS}
              messageSchedulingService={this.props.messageSchedulingService}
              resetPage={this.props.resetPage}
              countryData={this.props.countryData}
              fetchCostEstimator={this.props.fetchCostEstimator}
              costEstimatorData={this.props.countryData === 'ph' ? '' : this.props.costEstimator}
              costEstimatorError={
                this.props.countryData === 'ph' ? '' : this.props.costEstimatorError
              }
              fetchCostEstimatorClear={this.props.fetchCostEstimatorClear}
              changeKey={this.props.changeKey}
              fetchWhatsAppTemplateData={this.props.fetchWhatsAppTemplateData}
              fetchwhatsAppTemplateDataCleared={this.props.fetchwhatsAppTemplateDataClear}
              clearScheduleMessageStatus={this.props.clearScheduleMessageStatus}
              templateList={this.props.templateList}
              templateListContent={this.props.templateListContent}
            />
          </Card>
        </Drawer>
        <GlobalSpinner />
      </div>
    );
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    toggleDrawer: () => dispatch(toggleDrawer()),
    toggleDrawerStatus: () => dispatch(toggleDrawerStatus()),
    sendMessage: (mdata) => dispatch(messageSendingService(mdata)),
    messageSchedulingService: (mdata) => dispatch(messageSchedulingService(mdata)),
    fetchCostEstimator: (mdata) => dispatch(fetchCostEstimator(mdata)),
    fetchCostEstimatorClear: () => dispatch(fetchCostEstimatorClear()),
    fetchWhatsAppTemplate: () => dispatch(fetchWhatsAppTemplate()),
    fetchWhatsAppTemplateData: (name) => dispatch(fetchWhatsAppTemplateData(name)),
    communicationLists: (regionCode) => dispatch(communicationLists(regionCode)),
    fetchwhatsAppTemplateDataClear: () => dispatch(fetchwhatsAppTemplateDataClear()),
    clearScheduleMessageStatus: () => dispatch(clearScheduleMessageStatus()),
    sendMMS: (formData) => dispatch(sendMMS(formData)),
    scheduleMMS: (formData) => dispatch(scheduleMMS(formData))
  };
};
const mapStateToProps = function (state) {
  return {
    messageDrawerVisible: _get(state, 'messageSendingFormReducer.messageDrawerVisible', true),
    messageDrawer: _get(state, 'scheduleMessage.data.messageDrawerVisible', true),
    groupList: _get(state, 'groupListReducer.data.groupList.items', []),
    costEstimator: _get(state, 'costEstimatorList.data.costEstimatorResult', {}),
    costEstimatorError: _get(state, 'costEstimatorList.data.costEstimatorError.error_msg', {}),
    templateListContent: _get(state, 'whatsAppTemplateData.data.whatsAppTemplateData', {}),
    communicationModes: _get(
      state,
      'communicationList.communicationLists.supported_channel.channels',
      []
    )
  };
};

NewMessageDrawer.propTypes = {
  form: PropTypes.object,
  groupList: PropTypes.array,
  toggleDrawer: PropTypes.func,
  messageSendingService: PropTypes.func,
  messageDrawerVisible: PropTypes.bool,
  sendMessage: PropTypes.func,
  groupCrops: PropTypes.array,
  fetchCrops: PropTypes.func
};
const NewMessageApp = Form.create({ name: 'coordinated' })(NewMessageDrawer);
export default connect(mapStateToProps, mapDispatchToProps)(NewMessageApp);

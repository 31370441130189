import { findCountryandEnvironment } from '../services/commonMethods';

class AdalConfigService {
  static AdalConfig;
  static GrowerAPI;
  static ClientId;
  static countryName;
  static hostName;
  static getAuthTokens = (hostName, country) => {
    AdalConfigService.countryName = country.toUpperCase();
    if (hostName == 'localhost') {
      return {
        TENANT_ID: `${process.env.TENANT_ID}`,
        CLIENT_ID: `${process.env.CLIENT_ID}`,
        DOMAIN: `${process.env.DOMAIN}`,
        GROWER_API: `${process.env.GROWER_API}`,
        GET_CONVERSATION: `${process.env.GET_CONVERSATION}`,
        GET_SOCKET: `${process.env.GET_SOCKET}`
      };
    } else {
      let encodedData;
      encodedData =
        hostName == 'staging'
          ? `${process.env.ADAL_CONFIGURATION_STAGING}`
          : `${process.env.ADAL_CONFIGURATION_PRODUCTION}`;
      const processEnv = JSON.parse(atob(encodedData));
      const tenantID = `${processEnv[country]['TENANT_ID']}`;
      const clientID = `${processEnv[country]['CLIENT_ID']}`;
      const domain = `${processEnv[country]['DOMAIN']}`;
      const growerApi = `${processEnv[country]['GROWER_API']}`;
      const getConversation = `${processEnv[country]['GET_CONVERSATION']}`;
      const socketConnection = `${processEnv[country]['GET_SOCKET']}`;
      return {
        TENANT_ID: tenantID,
        CLIENT_ID: clientID,
        DOMAIN: domain,
        GROWER_API: growerApi,
        GET_CONVERSATION: getConversation,
        GET_SOCKET: socketConnection
      };
    }
  };
  static setAdalConfig = () => {
    const countryHostName = findCountryandEnvironment(window.location.host);
    const authTokens = AdalConfigService.getAuthTokens(
      countryHostName.hostName,
      countryHostName.country
    );
    const msalConfig = {
      auth: {
        clientId: authTokens.CLIENT_ID,
        authority: `https://login.microsoftonline.com/${authTokens.TENANT_ID}`,
        redirectUri: authTokens.DOMAIN,
        postLogoutRedirectUri: authTokens.DOMAIN,
        navigateToLoginRequestUrl: true,
        scopes: ['User.Read']
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false
      }
    };
    AdalConfigService.AdalConfig = msalConfig;
    AdalConfigService.GrowerAPI = authTokens.GROWER_API;
    AdalConfigService.ClientId = authTokens.CLIENT_ID;
    AdalConfigService.getConversation = authTokens.GET_CONVERSATION;
    AdalConfigService.socketConnection = authTokens.GET_SOCKET;
  };
}
export default AdalConfigService;

import React from 'react';
import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import Unauthorization from '../views/commonComponents/errorboundry/unauthorizationHandle';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import SocialMedia from '../views/SocialMedia/SocialMedia';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import Utility from '../views/utility/utility';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'idnLogin',
    loginButtonStyle: 'idnLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    drawerWidth: '200',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },

      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },

      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    uploadSheetButton: [
      {
        title: `+${t('UPLOAD A FILE (BULK UPLOAD)')}`,
        key: 'bulkUpload'
      }
    ],
    sampleTemplate:
      'https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/sampl-file/Sample-in.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },

      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },

      {
        key: 'help',
        label: t('Help')
      }
    ],
    amplitude: 'IN',
    advaceSearch: {
      filterData: [
        { key: 'sytGrowerCorn', value: t('Crop 1 Planted') },
        { key: 'sytPrefferedCorn', value: t('Hybrid Corn Acreage') },
        { key: 'sytGrowerRice', value: t('Crop 2 Planted') },
        // { key: 'sytPrefferedRice', value: t('Preferred Hybrid Rice') },
        { key: 'tmhq', value: t('TMHQ') },
        { key: 'mdohq', value: t('MDOHQ') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'PramukhTag', value: t('Pramukh Tag') },
        { key: 'retailerName', value: t('Retailer Name') },
        { key: 'district', value: t('District') },
        { key: 'taluka', value: t('Block') },
        { key: 'village', value: t('Village') },
        { key: 'state', value: t('State') },
        { key: 'CropHistory', value: t('Other Crops') },
        { key: 'Acres', value: t('Land Size') },
        { key: 'TVC', value: t('TVC') },
        { key: 'WA', value: t('WhatsApp') },
        { key: 'FB', value: t('Facebook') },
        { key: 'YB', value: t('YouTube') },
        { key: 'IG', value: t('Instagram') },
        { key: 'seasonSegementation', value: t('Season Segmentation') }
      ],
      dropDownMenu: {
        sytGrowerCorn: [{ key: 'in', value: t('in') }],
        sytGrowerRice: [{ key: 'in', value: t('in') }],
        sytPrefferedCorn: [{ key: 'in', value: t('in') }],
        sytPrefferedRice: [{ key: 'in', value: t('in') }],
        seasonSegementation: [{ key: 'in', value: t('in') }],
        district: [{ key: 'in', value: t('in') }],
        PramukhTag: [{ key: 'in', value: t('in') }],
        CropHistory: [{ key: 'in', value: t('in') }],
        state: [{ key: 'in', value: t('in') }],
        village: [{ key: 'in', value: t('in') }],
        taluka: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ],
        retailerName: [{ key: 'in', value: t('in') }],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        tmhq: [{ key: 'in', value: t('in') }],
        mdohq: [{ key: 'in', value: t('in') }],
        TVC: [{ key: 'in', value: t('in') }],
        WA: [{ key: 'in', value: t('in') }],
        FB: [{ key: 'in', value: t('in') }],
        YB: [{ key: 'in', value: t('in') }],
        IG: [{ key: 'in', value: t('in') }]
      },
      defaulState: [
        { id: 1, field: { name: 'sytGrowerCorn' }, operator: { name: 'in' }, value: [] }
      ],
      addNewFilter: 'sytGrowerCorn'
    },

    groupDrawer: [
      {
        key: 'allSytGrowerCorn',
        optionValue: 'childrenSytGrowerCorn',
        fieldName: 'GrowersSytGrowerCorn',
        initialValue: 'GrowersSytGrowerCorn',
        placeholder: t('Select Some Crop 1 Planted'),
        handler: 'handleSytGrowerCornSelectAll',
        rules: []
      },
      {
        key: 'allPrefferedCorn',
        optionValue: 'childrenPrefferedCorn',
        fieldName: 'GrowersPrefferedCorn',
        initialValue: 'GrowersPrefferedCorn',
        placeholder: t('Select Some Hybrid Corn Acreage'),
        handler: 'handlePrefferedCornSelectAll',
        rules: []
      },
      {
        key: 'allSytGrowerRice',
        optionValue: 'childrenSytGrowerRice',
        fieldName: 'GrowersSytGrowerRice',
        initialValue: 'GrowersSytGrowerRice',
        placeholder: t('Select Some Crop 2 Planted'),
        handler: 'handleSytGrowerRiceSelectAll',
        rules: []
      },

      {
        key: 'allPrefferedRice',
        optionValue: 'childrenPrefferedRice',
        fieldName: 'GrowersPrefferedRice',
        initialValue: 'GrowersPrefferedRice',
        placeholder: t('Select Some Hybrid Rice Acreage'),
        handler: 'handlePrefferedRiceSelectAll',
        rules: []
      },
      {
        key: 'allTmhq',
        optionValue: 'childrenTmhq',
        fieldName: 'GrowersTmhq',
        initialValue: 'GrowersTmhq',
        placeholder: t('Select Some TMHQ'),
        handler: 'handleTmqhSelectAll',
        rules: []
      },
      {
        key: 'allMdohq',
        optionValue: 'childrenMdohq',
        fieldName: 'GrowersMdohq',
        initialValue: 'GrowersMdohq',
        placeholder: t('Select Some MDOHQ'),
        handler: 'handleMdohqSelectAll',
        rules: []
      },
      {
        key: 'allPramukhTag',
        optionValue: 'childrenPramukhTag',
        fieldName: 'GrowersPramukhTag',
        initialValue: 'GrowersPramukhTag',
        message: t('Please select some pramukh tag'),
        placeholder: t('Select Some Pramukh Tag'),
        handler: 'handlePramukhTagSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some pramukh tag')
          }
        ]
      },

      {
        key: 'allDistrict',
        optionValue: 'childrenDistrict',
        fieldName: 'GrowersDistrict',
        initialValue: 'GrowersDistrict',
        placeholder: t('Select Some District'),
        handler: 'handleDistrictSelectAll',
        rules: []
      },

      {
        key: 'allTaluka',
        optionValue: 'childrenTaluka',
        fieldName: 'GrowersTaluka',
        initialValue: 'GrowersTaluka',
        placeholder: t('Select Some Block'),
        handler: 'handleTalukaSelectAll',
        rules: []
      },
      {
        key: 'allVillage',
        optionValue: 'childrenVillage',
        fieldName: 'GrowersVillage',
        initialValue: 'GrowersVillage',
        placeholder: t('Select Some Village'),
        handler: 'handleVillageSelectAll',
        rules: []
      },
      {
        key: 'allState',
        optionValue: 'childrenState',
        fieldName: 'GrowersState',
        initialValue: 'GrowersState',
        placeholder: t('Select Some State'),
        handler: 'handleStateSelectAll',
        rules: []
      },
      {
        key: 'allCropHistory',
        optionValue: 'childrenCropHistory',
        fieldName: 'GrowersCropHistory',
        initialValue: 'GrowersCropHistory',
        message: t('Please select some crops'),
        placeholder: t('Select Other Crops'),
        handler: 'handleCropHistorySelectAll',
        rules: [
          {
            required: false,
            message: t('Please select other crops')
          }
        ]
      },

      {
        key: 'allTVC',
        optionValue: 'childrenTVC',
        fieldName: 'GrowersTVC',
        initialValue: 'GrowersTVC',
        placeholder: t('Select Some TVC'),
        handler: 'handleTVCSelectAll',
        rules: []
      },
      {
        key: 'allWA',
        optionValue: 'childrenWA',
        fieldName: 'GrowersWA',
        initialValue: 'GrowersWA',
        placeholder: t('Select Some WhatsApp'),
        handler: 'handleWASelectAll',
        rules: []
      },
      {
        key: 'allFB',
        optionValue: 'childrenFB',
        fieldName: 'GrowersFB',
        initialValue: 'GrowersFB',
        placeholder: t('Select Some Facebook'),
        handler: 'handleFBSelectAll',
        rules: []
      },
      {
        key: 'allYB',
        optionValue: 'childrenYB',
        fieldName: 'GrowersYB',
        initialValue: 'GrowersYB',
        placeholder: t('Select Some YouTube'),
        handler: 'handleYBSelectAll',
        rules: []
      },
      {
        key: 'allIG',
        optionValue: 'childrenIG',
        fieldName: 'GrowersIG',
        initialValue: 'GrowersIG',
        placeholder: t('Select Some Instagram'),
        handler: 'handleIGSelectAll',
        rules: []
      },
      {
        key: 'allSeasonSegementation',
        optionValue: 'childrenSeasonSegementation',
        fieldName: 'GrowersSeasonSegementation',
        initialValue: 'GrowersSeasonSegementation',
        placeholder: t('Select Some Season Segmentation'),
        handler: 'handleSeasonSegementationSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Farmer Name'),
          fieldName: 'farmerName',
          initialValue: 'farmerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter farmer name'),
            whitespace: true
          }
        },
        {
          label: t('Last Name'),
          fieldName: 'lastName',
          initialValue: 'lastName',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter last name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          style: { display: 'none' },
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Acres)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        },
        {
          label: t('Crop 1 Planted'),
          fieldName: 'sytGrowerCorn',
          initialValue: 'sytGrowerCorn',
          type: 'text',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter crop 1 planted'),
            whitespace: true
          }
        },
        {
          label: t('Crop 2 Planted'),
          fieldName: 'sytGrowerRice',
          initialValue: 'sytGrowerRice',
          type: 'text',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter crop 2 planted'),
            whitespace: true
          }
        },
        {
          label: t('Hybrid Corn Acreage'),
          fieldName: 'preferredHybridCorn',
          initialValue: 'preferredHybridCorn',
          type: 'number',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter hybrid corn acreage '),
            whitespace: true
          }
        },
        {
          label: t('Hybrid Rice Acreage'),
          fieldName: 'preferredHybridRice',
          initialValue: 'preferredHybridRice',
          type: 'number',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter hybrid rice acreage'),
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Block'),
          fieldName: 'Taluka',
          initialValue: 'taluka',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter block'),
            whitespace: true
          }
        },
        {
          label: t('Village'),
          fieldName: 'Village',
          initialValue: 'village',
          type: 'text',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter village'),
            whitespace: true
          }
        },

        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter district'),
            whitespace: true
          }
        },
        {
          label: t('State'),
          fieldName: 'state',
          initialValue: 'state',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter state'),
            whitespace: true
          }
        },
        {
          label: t('MDOHQ'),
          fieldName: 'mdohq',
          initialValue: 'mdohq',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter mdohq '),
            whitespace: true
          }
        },
        {
          label: t('TMHQ'),
          fieldName: 'tmhq',
          initialValue: 'tmhq',
          type: 'text',
          disable: false,
          rules: {
            required: true,
            message: t('Please enter TMHQ '),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Retailer Name'),
          fieldName: 'retailerName',
          initialValue: 'retailerName',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter retailer name'),
            whitespace: true
          }
        },

        {
          label: t('TVC'),
          fieldName: 'TVC',
          initialValue: 'TVC',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter TVC '),
            whitespace: true
          }
        },
        {
          label: t('Whatsapp'),
          fieldName: 'WA',
          initialValue: 'WA',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter whatsapp '),
            whitespace: true
          }
        },
        {
          label: t('Facebook'),
          fieldName: 'FB',
          initialValue: 'FB',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter facebook '),
            whitespace: true
          }
        },
        {
          label: t('YouTube'),
          fieldName: 'YB',
          initialValue: 'YB',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter youtube '),
            whitespace: true
          }
        },
        {
          label: t('Instagram'),
          fieldName: 'IG',
          initialValue: 'IG',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter instagram '),
            whitespace: true
          }
        },
        {
          label: t('Season Segmentation'),
          fieldName: 'seasonSegementation',
          initialValue: 'seasonSegementation',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter season segmentation '),
            whitespace: true
          }
        }
      ]
    },
    tagVisible: true,
    cropHistoryVisible: true,
    hideNewTag: true,
    campignTable: [
      {
        title: t('MODE'),
        dataIndex: 'mode'
      },
      {
        title: t('CAMPAIGN NAME'),
        dataIndex: 'name'
      },
      {
        title: t('REACH'),
        dataIndex: 'reach'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'otherCrops'
      },
      {
        title: t('LAND SIZE (acres)'),
        dataIndex: 'landSize'
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName'
      },
      // {
      //   title: `#${t('MEMBERS')}`,
      //   dataIndex: 'noOfMembers'
      // },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters'
      }
    ],
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'locationState'
      },
      {
        title: t('CROP'),
        dataIndex: 'otherCrops'
      },
      {
        title: t('LAND SIZE (acres)'),
        dataIndex: 'landsize'
      }
    ],
    interactTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('SENDER'),
        dataIndex: 'Sender'
      },
      {
        title: t('SENT ON'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    scheduleMessageTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    searchZoneMessage: {
      message: t('Please select some zone'),
      placeholder: t('Select Some Zone')
    },
    searchCropHistoryMessage: {
      message: t('Please select other crops'),
      placeholder: t('Select Other Crops')
    },
    searchTmhqMessage: {
      message: t('Please select some TMHQ'),
      placeholder: t('Select Some TMHQ')
    },
    replaceTitle: ['0-2 acres', '3-10 acres', '11-20 acres', '> 20 acres'],
    paiChart: 'inDistributionCard',
    acresVisible: true,
    dividerHeight: '760px',
    optInOutVisible: true,
    saveButtonPosition: 'inButtonPosition'
  };
};

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchZone } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchZone extends React.Component {
  constructor(props) {
    super(props);
    this.childrenZone = [];
  }
  handleChange = (user, event) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = event;
    this.props.callback(newUsers);
  };
  componentDidMount() {
    this.props.fetchZone();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, searchZoneMessage, searchCityMessage } = this.props;
    let zoneData = this.props.groupZone || [];
    let zoneText = zoneData.length > 0 ? zoneData[0].items : [];
    this.childrenZone = zoneText.map((region) => <Option key={region}>{region}</Option>);
    return (
      <Form.Item label='' className='growersCrop'>
        {getFieldDecorator(`${this.props.zoneValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: this.props.searchZoneMessage && this.props.searchZoneMessage.message
            }
          ]
        })(
          <Select
            name='zone'
            size='small'
            mode='multiple'
            showArrow={true}
            placeholder={this.props.searchZoneMessage && this.props.searchZoneMessage.placeholder}
            maxTagCount={5}
            onChange={(event) => {
              this.handleChange(this.props.user, event);
            }}>
            {this.childrenZone}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchZone: () => dispatch(fetchZone())
  };
};
const mapStateToProps = function(state) {
  return {
    groupZone: get(state, 'groupFilterReducer.data.groupZone.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchZone);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { Layout, List, Card, Row, Col, Drawer, Modal, Button, Icon, Anchor } from 'antd';
import './addGrower.css';
import { uploadWithSchemaService } from '../../../../../services/fileUploadService';
import FileUploadWithSchema from '../fileUploadWithSchema/fileUploadWithSchema';
import { StyledButton } from './addGrowers.jsx';
import {
  toggleDrawer,
  createUploadProgress,
  clearProgressStatus
} from '../../../../../actions/uploadForm';
/****** Icons file */
import zendesk from '../../../../../assets/icons/zendesk.png';
import salesforce from '../../../../../assets/icons/salesforce.png';
import uploadbg from '../../../../../assets/img/upload-a-file.png';
import { alertBox } from '../../../../commonComponents/Notification/alertBox';

/**** Ant Design Components */
const { Content } = Layout;
const { confirm } = Modal;
const { Link } = Anchor;

const data = [
  {
    title: '+UPLOAD A FILE (BULK UPLOAD)',
    key: 'bulkUpload'
  }
  // {
  //   title: 'DOWNLOAD SAMPLE UPLOAD SHEET'
  //    key: 'downloadsheet'
  // }
  // {
  //   title: 'IMPORT FORM',
  //   icon1: zendesk,
  //   icon2: salesforce,
  //   key: 'importForm'
  // },
  // {
  //   title: '+ADD A GROWER (Single Entry)',
  //   key: 'singleEntry'
  // }
];

class AddGrower extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: this.props.visible,
      selectedSchema: this.props.selectedSchema,
      steps: this.props.progress,
      closeBox: this.props.closeBox
    };
    this.onClose = this.onClose.bind(this);
    this.changeSteps = this.changeSteps.bind(this);

    /****** Drawer function declarations */
    this.onChange = this.onChange.bind(this);

    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
  }

  onClose() {
    this.setState({
      selectedSchema: []
    });
    this.props.toggleDrawer();
  }

  handleClick(val) {
    if (data[0].key === val) {
      let progressData = {
        progressStatus: 'active',
        progressBarStatus: false,
        progress: 0
      };
      this.props.createUploadProgress(progressData);
      this.props.toggleDrawer();
    }
  }

  /***** Drawer Functions */

  onChange(value) {
    this.setState({
      selectedSchema: value
    });
  }

  changeSteps(val) {
    let perc = val + 10;

    if (perc > 100) {
      perc = 100;
    }
    this.setState({
      steps: perc
    });
  }

  showDeleteConfirm(propsdata) {
    confirm({
      title: 'Are you sure close this task?',
      content: 'After closing you loose your data',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        propsdata.toggleDrawer();
      }
    });
  }

  render() {
    const { sampleTemplate, t, uploadSheetButton } = this.props;
    return (
      <Content className='mainaddGrower'>
        {this.props.toggleAlert
          ? alertBox({
              message: this.props.alertMessage,
              type: this.props.alertType
            })
          : null}
        <Drawer
          title={t('UPLOAD A FILE')}
          placement='right'
          closable={false}
          onClose={this.onClose}
          visible={this.props.visible}
          closable={true}
          mask={true}
          maskClosable={false}
          destroyOnClose={true}>
          <Card
            hoverable={false}
            bordered={false}
            style={{ width: 235, padding: '10 0' }}
            cover={<img alt={t('UPLOAD A FILE')} src={uploadbg} />}
            className='uploaCard'>
            <FileUploadWithSchema
              {...this.props}
              uploadWithSchemaService={this.props.uploadWithSchemaService}
              progress={this.props.progress}
              progressStatus={this.props.progressStatus}
              progressBarStatus={this.props.progressBarStatus}
              selectedSchema={this.props.selectedSchema}
              allGrowerCount={this.props.growersCount}
              uploadStatus={this.props.uploadStatus}
              visible={this.props.visible}
              clearProgressStatus={this.props.clearProgressStatus}
              setCurrentPage={this.props.setCurrentPage}
              currentPage={this.props.currentPage}
              resetPage={this.props.resetPage}
            />
          </Card>
        </Drawer>

        <StyledButton
          type='link'
          target='_blank'
          href={sampleTemplate}
          style={{ position: 'absolute', right: '20px', textDecoration: 'underline' }}>
          <Icon type='download' />
          {t('Download Sample Template')}
        </StyledButton>

        <List
          className='addgrowerList'
          grid={{ gutter: [{ xs: 8, sm: 10, md: 4, lg: 16 }], column: 2 }}
          style={{ width: '70%' }}
          dataSource={uploadSheetButton}
          renderItem={(item) => (
            <List.Item>
              <Row>
                <Col xs={100} sm={100} md={100} lg={100} xl={100}>
                  <Card onClick={this.handleClick.bind(this, item.key)} className='addGrowerCard'>
                    <span>{item.title}</span>
                  </Card>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Content>
    );
  }
}

const mapStateToProps = function(state) {
  return {
    selectedSchema: _get(state, 'uploadFormReducer.selectedSchema', ''),
    file: _get(state, 'uploadFormReducer.file'),
    progress: _get(state, 'uploadFormReducer.progress', 0),
    progressStatus: _get(state, 'uploadFormReducer.progressStatus', ''),
    progressBarStatus: _get(state, 'uploadFormReducer.progressBarStatus', true),
    visible: _get(state, 'uploadFormReducer.drawerVisible', true),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', 'info'),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true),
    uploadStatus: _get(state, 'uploadFormReducer.uploadStatus', true),
    growersCount: _get(state, 'allGrowersCountReducer.allGrowersCount.growerCount', 0)
  };
};

const mapDispatchToProps = function(dispatch) {
  return {
    toggleDrawer() {
      dispatch(toggleDrawer());
    },
    createUploadProgress(progressData) {
      dispatch(createUploadProgress(progressData));
    },
    uploadWithSchemaService(formdata) {
      dispatch(uploadWithSchemaService(formdata));
    },
    clearProgressStatus() {
      dispatch(clearProgressStatus());
    }
  };
};
AddGrower.propTypes = {
  selectedSchema: PropTypes.string,
  progress: PropTypes.number,
  closeBox: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  createUploadProgress: PropTypes.func,
  toggleAlert: PropTypes.bool,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  visible: PropTypes.bool,
  uploadWithSchemaService: PropTypes.func,
  progressStatus: PropTypes.string,
  progressBarStatus: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(AddGrower);

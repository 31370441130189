import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tag, Button } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { facebookListWithFetchAPI } from '../../actions/uploadFacebookList';

class FacebookTable extends Component {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    this.state = {
      selectedRowKeys: [],
      data: this.props.data,
      pagination: {},
      currentPage: 0
    };
  }

  handleTableChange(pagination, pageSize) {
    this.setState({
      currentPage: pagination.current
    });
    this.props.facebookListWithFetchAPI(this.props.facebookAuthToken);
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.facebookListWithFetchAPI();
  }

  render() {
    const columns = [
      {
        title: 'Post',
        align: 'center',
        dataIndex: 'url',
        render: (text) => <img src={text} />
      },
      {
        title: 'Title',
        align: 'center',
        dataIndex: 'title'
      },

      {
        title: 'Likes',
        align: 'center',
        dataIndex: 'likeCount'
      },
      {
        title: 'Shares',
        align: 'center',
        dataIndex: 'shareCount'
      },
      {
        title: 'Comments',
        align: 'center',
        dataIndex: 'commentCount'
      },
      {
        title: 'Published On',
        dataIndex: 'publishedAt'
      }
    ];

    const pagination = {
      total: this.props.totalPages,
      pageSize: this.props.resultsPerPage,
      current: this.state.currentPage
    };

    const data = [];
    if (this.props.data) {
      this.props.data.forEach((value, index) => {
        data.push({
          dataIndex: index,
          key: index,
          url: value.picture || '',
          title: value.attachments.data[0].title
            ? value.attachments.data[0].title
            : value.attachments.data[0].type || '',
          shareCount: value.shares ? value.shares.count : 0,
          likeCount: value.likes.summary.total_count || 0,
          commentCount: value.comments.summary.total_count || 0,
          publishedAt: moment(value.created_time || '').format('lll')
        });
      });
    }
    return (
      <Table
        rowKey={data.key}
        pagination={pagination}
        loading={false}
        handleTableChange
        onChange={this.handleTableChange}
        size='Small'
        bordered={true}
        columns={columns}
        dataSource={data}
        rowClassName={(record) =>
          record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : ''
        }
      />
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    facebookListWithFetchAPI: (pagenum, nextPageToken) =>
      dispatch(facebookListWithFetchAPI(pagenum, nextPageToken))
  };
};
const mapStateToProps = (state) => {
  return {
    data: get(state, 'uploadFacebookList.data.uploadPostResult.data.data', [])
  };
};

FacebookTable.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number,
  resultsPerPage: PropTypes.number,
  nextPageToken: PropTypes.string,
  prevPageToken: PropTypes.string,
  facebookListWithFetchAPI: PropTypes.func,
  updateRowClassFlag: PropTypes.bool
};
// Datatable.displayName = 'Datatable';
export default connect(mapStateToProps, mapDispatchToProps)(FacebookTable);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { Card, Modal } from 'antd';
import Chart from 'react-apexcharts';
import { RegionalMetaContext } from '../../context/region';
import CropPieCharts from './cropPieCharts';
class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      crop: '',
      corpType: '',
      checkLegendClick: false
    };
  }
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  createSeriesData = (data, obj) => {
    let key;
    let value;
    // if (obj === 'area' || obj === 'land_size') {
    key = Object.keys(data);
    value = Object.values(data);
    // } else {
    //   key = Object.keys(data).sort(function (a, b) {
    //     return data[b] - data[a];
    //   });
    //   value = Object.values(data).sort(function (a, b) {
    //     return b - a;
    //   });
    // }
    return {
      series: value,
      labels: key
    };
  };
  capitalize = (str) => {
    var i,
      splitValues = str.split('_');
    for (i = 0; i < splitValues.length; i++) {
      splitValues[i] = splitValues[i].charAt(0).toUpperCase() + splitValues[i].slice(1);
    }
    return splitValues.join(' ');
    // return str[0].toUpperCase() + str.slice(1);
  };
  render() {
    const checkTitle = ['area0to20', 'area21to50', 'area51to100', 'areaGreaterThan100'];
    const idnTitle = ['area0', 'area0to1', 'area1to5', 'areaAbove5'];
    const replaceIdnTitle = ['0 hectares', '0.1-1 hectares', '1-5 hectares', '> 5 hectares'];
    const replaceTitle = ['0-20 acres', '21-50 acres', '51-100 acres', '> 100 acres'];
    const renderCount = (corpType) => {
      switch (corpType) {
        case 'rice':
          return ricePieChart;
        case 'corn':
          return cornPieChart;
        default:
          return fruitsPieChart;
      }
    };
    const showModal = (event, chartContext, config, showTrue) => {
      const defaultPrevented = event.defaultPrevented;
      //   const checkClickIndex = config.config.labels[config.dataPointIndex];
      if (showTrue) {
        const checkClickIndex = config.w.config.labels[config.dataPointIndex];
        this.setState({
          corpType: checkClickIndex,
          visible: true
        });
      }
    };
    const disableLegendClick = (event, seriesIndex, config) => {
      this.setState({
        visible: false
      });
    };
    const ricePieChart = Object.entries(this.props.idnRicePieChart).map(([key, value]) => (
      <CropPieCharts
        values={this.createSeriesData(value, key)}
        index={key}
        title={`Grower Distribution by ${this.capitalize(key)}`}
      />
    ));
    const cornPieChart = Object.entries(this.props.idnCornPieChart).map(([key, value]) => (
      <CropPieCharts
        values={this.createSeriesData(value, key)}
        index={key}
        title={`Grower Distribution by ${this.capitalize(key)}`}
      />
    ));
    const fruitsPieChart = Object.entries(this.props.idnFriutsPieChart).map(([key, value]) => (
      <CropPieCharts
        values={this.createSeriesData(value, key)}
        index={key}
        title={`Grower Distribution by ${this.capitalize(key)}`}
      />
    ));

    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <>
            <Card>
              <div className='donut'>
                <div>{this.props.title}</div>
                <Chart
                  options={{
                    chart: {
                      type: 'pie',
                      events: {
                        dataPointSelection: function (event, chartContext, config) {
                          const showTrue = true;
                          showModal(event, chartContext, config, showTrue);
                        },
                        legendClick: function (chartContext, seriesIndex, config) {
                          const showTrue = false;
                          showModal(chartContext, seriesIndex, config, showTrue);
                        }
                      }
                    },
                    plotOptions: {
                      pie: {}
                    },

                    legend: {
                      position: 'bottom',
                      // fontSize: '11px'
                      horizontalAlign: 'left'
                    },
                    markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: '#fff',
                      fillColors: undefined,
                      radius: 12,
                      customHTML: undefined,
                      onClick: undefined,
                      offsetX: 0,
                      offsetY: 0
                    },
                    // colors: [
                    //   '#003f5c',
                    //   '#2f4b7c',
                    //   '#665191',
                    //   '#a05195',
                    //   '#d45087',
                    //   '#f95d6a',
                    //   '#ff7c43',
                    //   '#ffa600'
                    // ],
                    // Rainbow color
                    // colors: ['#52D726', '#FFEC00', '#FF7300', '#FF0000', '#007ED6', '#7CDDDD'],
                    // Colourful pie chart
                    // colors: ['#FFEC21', '#378AFF', '#FFA32F', '#F54F52', '#93F03B', '#9552EA'],

                    formatter: function (val) {
                      return val;
                    },
                    theme: {
                      mode: 'light',
                      palette: 'palette1'
                    },

                    labels:
                      country === 'idn'
                        ? this.props.values.labels.map((element) =>
                            idnTitle.indexOf(element) > -1
                              ? replaceIdnTitle[idnTitle.indexOf(element)]
                              : element
                          )
                        : this.props.values.labels.map((element) =>
                            checkTitle.indexOf(element) > -1
                              ? data.replaceTitle[checkTitle.indexOf(element)]
                              : element
                          )
                  }}
                  // series={this.props.values.series.sort((a, b) => b - a)}
                  series={this.props.values.series}
                  type='pie'
                />
              </div>
            </Card>
            <Modal
              title='Grower Distribution'
              visible={this.state.visible}
              footer={null}
              style={{ top: '60px' }}
              onCancel={this.handleCancel}>
              <>
                {' '}
                {/* {ricePieChart} */}
                {renderCount(this.state.corpType)}
                {/* <Card bordered={false}>{renderCount(this.state.country)}</Card> */}
              </>
            </Modal>
          </>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardResult: () => dispatch(fetchDashboardResult())
  };
};

const mapStateToProps = function (state) {
  return {
    growerDistribution: _get(state, 'dashboardList.data.dashboardResult.growerDistribution', {}),
    idnRicePieChart: {
      rice: _get(
        state,
        'dashboardList.data.dashboardResult.growerDistribution.cropsWithArea.rice',
        {}
      )
    },
    idnCornPieChart: {
      corn: _get(
        state,
        'dashboardList.data.dashboardResult.growerDistribution.cropsWithArea.corn',
        {}
      )
    },
    idnFriutsPieChart: {
      'fruit And Vegetable': _get(
        state,
        'dashboardList.data.dashboardResult.growerDistribution.cropsWithArea.fruitAndVegetable',
        {}
      )
    }
    // source: _get(state, 'salesUnitList.data.salesUnitResult', {})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PieChart);

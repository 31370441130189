import React from 'react';
import { Form, DatePicker } from 'antd';
import moment from 'moment';

class SelectDate extends React.Component {
  constructor(props) {
    super(props);
  }
  handleChange = (dateString) => {
    const value = moment(dateString).format('MM-DD-YYYY');
    this.props.callback(value);
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form.Item style={{ marginTop: '8px' }}>
        <DatePicker
          format='MM-DD-YYYY'
          style={{ width: '107.5%' }}
          placeholder='Select Date'
          defaultValue={moment()}
          onChange={(dateString) => {
            this.handleChange(dateString);
          }}
        />
      </Form.Item>
    );
  }
}

export default SelectDate;

import {
  GET_CONVERSATION_PENDING,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_ERROR,
  GET_UN_READ_MESSAGES,
  CLEAR_UN_READ_MESSAGES
} from '../constants/index';
import cloneDeep from 'lodash/cloneDeep';
const initialState = {
  data: {
    conversationList: [],
    totalCount: '',
    conversationListError: {},
    conversationListStatus: '',
    unReadMessages: {},
    unReadMessagesList: [],
    unReadCount: '',
    completed: false,
    loading: true
  }
};

function getConversationList(state = initialState, action) {
  switch (action.type) {
    case GET_CONVERSATION_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case GET_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          conversationList: [...state.data.conversationList, ...action.payload.items],
          totalCount: action.payload,
          completed: false
        }
      });
    case GET_CONVERSATION_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          conversationListError: action.payload,
          completed: false
        }
      });
    case GET_UN_READ_MESSAGES:
      let cloneData = cloneDeep(state.data.conversationList);
      let cloneMessageData = cloneDeep(action.payload.message);

      let contactIdIndex = cloneData.findIndex(
        (item) => item.id === action.payload.message.conversationId
      );
      let contactId = cloneData[contactIdIndex];

      if (contactId.count) {
        contactId.count = contactId.count + 1;
        contactId.notificationMsg = action.payload;
      } else {
        contactId.count = 1;
        contactId.notificationMsg = action.payload;
      }
      cloneData[contactIdIndex] = contactId;

      return Object.assign({}, state, {
        data: {
          ...state.data,
          unReadMessage: action.payload,
          conversationList: cloneData,
          completed: false
        }
      });
    case CLEAR_UN_READ_MESSAGES:
      let cloneDataClear = cloneDeep(state.data.conversationList);
      let contactIdIndexClear = cloneDataClear.findIndex((item) => item.id === action.payload);
      let contactIdClear = cloneDataClear[contactIdIndexClear];
      if (contactIdClear.count) {
        contactIdClear.count = 0;
      } else {
        contactIdClear.count = 0;
      }
      cloneDataClear[contactIdIndexClear] = contactIdClear;
      return Object.assign({}, state, {
        data: {
          ...state.data,
          unReadMessage: action.payload,
          conversationList: cloneDataClear,
          completed: false
        }
      });
    default:
      return state;
  }
}

export default getConversationList;

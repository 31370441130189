import {
  FETCH_OPTOUT_PENDING,
  FETCH_OPTOUT_SUCCESS,
  FETCH_OPTOUT_UPDATE,
  FETCH_OPTOUT_ERROR,
  CLEAR_OPTOUT_RESULT,
  GET_OPTOUT_STATE
} from '../constants';
const initialState = {
  data: {
    optOutResult: {},
    optOutError: {},
    users: [],
    completed: false
  }
};

function optOutReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OPTOUT_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_OPTOUT_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optOutResult: action.payload,
          completed: false
        }
      });
    }

    case CLEAR_OPTOUT_RESULT:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optOutResult: []
        }
      });

    case FETCH_OPTOUT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optOutError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default optOutReducer;

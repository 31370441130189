import axios from 'axios';
import config from '../config';
import {
  UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING,
  UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS,
  UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR
} from '../constants';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
import { youTubeListWithFetchAPI } from '../actions/uploadYouTubeList';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
export function uploadVideoDetailPending(Progressstatus) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING,
    payload: Progressstatus
  };
}
export function uploadVideoDetailSuccess(gdata) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS,
    payload: gdata
  };
}

export function uploadVideoDetailFailure(gerror) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR,
    payload: gerror
  };
}

export const uploadYouTubeVideoDetailService = (data) => {
  const apiUri = config.uploadYouTubeVideoDetail;
  return (dispatch) => {
    dispatch(uploadVideoDetailPending(true));
    axios
      .put(apiUri, data, {
        params: {
          part: 'snippet'
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('youTubeAuthToken')
        },
        body: JSON.stringify(data)
      })

      .then((response) => {
        dispatch(uploadVideoDetailPending());
        dispatch(
          uploadVideoDetailSuccess({
            alert: true,
            res: response
          })
        );
        dispatch(
          successNotification({
            type: 'success',
            message: 'Video Uploaded Sucessfully',
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
        dispatch(youTubeListWithFetchAPI());
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);
        if (errorResponse.message.indexOf('403') > -1) {
          const response = {
            status: 403
          };
          return handleErrors(response, dispatch);
        }
        dispatch(uploadVideoDetailPending());
        dispatch(uploadVideoDetailFailure({ error }));
        dispatch(
          errorNotification({
            type: 'error',
            message: error.message,
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};

import React, { Component } from 'react';
import { Table, Modal, Button, Tooltip, Popconfirm, Divider, Icon } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import moment, { utc } from 'moment';
import PropTypes from 'prop-types';
import '../../commonComponents/Datatabel/datatable.css';
import { sentMessageListWithFetchAPI } from '../../../services/messageListService';
import { toggleScheduleMessageDrawer } from '../../../actions/scheduleMessageUpdate';
import { scheduleMessageListService } from '../../../actions/scheduleMessageList';
import { scheduleMessageUpdate } from '../../../actions/scheduleMessageUpdate';
import { scheduleMessageDelete } from '../../../actions/scheduleMessageDelete';
import UpdateMessageDrawer from './updateMessageDrawer/updateMessageDrawer';
class ScheduleMessageList extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      isFetching: false,
      users: [],
      loading: false,
      pagination: {},
      scheduleMessageDeleteStatus: false,
      EditRowdata: []
    };
  }
  handleTableChange(pagination) {
    this.props.setCurrentPage(pagination.current);
    // this.props.sentMessageListWithFetchAPI(pagination.current - 1);
    this.props.scheduleMessageListService(pagination.current - 1);
  }
  showDrawerData = (record) => {
    this.props.toggleScheduleMessageDrawer();
    this.setState({
      EditRowdata: record
    });
  };
  deleteGroupData = (record) => {
    console.log('record>', record);
    this.props.scheduleMessageDelete(
      record,
      this.props.currentPage,
      this.props.scheduleMessageList,
      this.props.scheduleMessageListOffset
    );
    // this.setState({
    //   scheduleMessageDeleteStatus: true
    // });
  };
  componentDidMount() {
    // this.props.sentMessageListWithFetchAPI(0);
    this.props.resetPage();
    this.props.scheduleMessageListService(0);
  }
  componentDidUpdate(preProps, preState) {
    if (preProps.activeTab !== this.props.activeTab && this.props.activeTab === '2') {
      this.props.scheduleMessageListService(0);
    }
  }
  render() {
    const { currentPage, scheduleMessageList, hideUpdateScheduleMessage, columns, t } = this.props;
    const pagination = {
      total: this.props.totalPages * 10
    };

    const scheduleMessageTable = [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      },
      {
        title: t('ACTION'),
        dataIndex: 'action',
        hidden: hideUpdateScheduleMessage,
        //  fixed:'right',
        width: '10%',
        render: (text, record) => (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            {/* <Tooltip title='View'>
              <Button
                className='viewButton'
                type='link'
                icon='eye'
                onClick={() => this.showModal(record)}
              />
            </Tooltip>
            <Divider type='vertical' style={{ height: 'auto' }} /> */}
            <Tooltip title='Edit'>
              <Button
                className='editButton'
                type='link'
                icon='edit'
                style={{ height: '22px' }}
                onClick={() => this.showDrawerData(record)}
              />
            </Tooltip>
            <Divider type='vertical' style={{ height: 'auto' }} />
            <Popconfirm
              className='groupDelete'
              placement='left'
              title='Are you sure delete this schedule message?'
              icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
              onConfirm={() => this.deleteGroupData(record.key)}
              style={{ padding: '12px 16px' }}>
              <Tooltip title='Delete'>
                <Button
                  className='deleteButton'
                  type='link'
                  icon='delete'
                  style={{ height: '22px' }}
                />
              </Tooltip>
            </Popconfirm>
          </span>
        )
      }
    ].filter((item) => !item.hidden);
    let list = [];
    list = scheduleMessageList;
    let listData = [];
    listData = scheduleMessageList;
    const listDataReduce = list && list.find((value) => value.message);
    // list = listDataReduce;
    const data = [];
    if (list && list.length) {
      list.map((value, index) => {
        if (value.message) {
          data.push({
            key: value && value.id ? value.id : '',
            groupId: value && value.message.group.id ? value.message.group.id : '',
            messageId: value && value.id ? value.id : '',
            Group: value && value.message.group.id ? value.message.group.name : '',
            msgType: value && value.message.type ? value.message.type : '',
            message:
              value && value.message.content
                ? value.message.content
                : value.message.whatssappTemplateName,
            Sender: value && value.message.sender ? value.message.sender : '',
            SentOn:
              value && value.scheduleDate
                ? moment(new Date(value.scheduleDate)).local().format('lll')
                : '',
            scheduleDate:
              value && value.scheduleDate
                ? moment(value.scheduleDate).utc().format('YYYY-MM-DD HH:mm:ss')
                : '',
            Platform:
              value && value.message.channels ? value.message.channels[0] : value.message.channel,
            deletedGroup: value && value.group ? value.group.deleted : false,
            files:
              value && value.message.files
                ? value.message.files.map((data) => {
                    return {
                      uid: data.fileKey ? data.fileKey : 1,
                      name: data.fileName,
                      url: data.fileUrl,
                      fileKey: data.fileKey,
                      fileUrl: data.fileUrl,
                      fileName: data.fileName,
                      fileSize: data.fileSize
                    };
                  })
                : ''
          });
        }
      });
    }
    return (
      <>
        <Table
          rowKey={data.key}
          pagination={{ ...pagination, current: currentPage }}
          loading={this.state.loading}
          onChange={this.handleTableChange}
          size='Small'
          bordered={true}
          columns={scheduleMessageTable}
          dataSource={data}
          rowClassName={(record, index) => {
            // record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : 'test1'
            return this.props.currentPage === 0 && index === 0 && this.props.rowHighlight
              ? 'highLight'
              : '';
          }}
        />
        <UpdateMessageDrawer
          {...this.props}
          editData={this.state.EditRowdata}
          countryData={this.props.countryData}
          // groupID={this.state.groupId}
          // drawerData={this.props.drawerData}
          pageNumber={currentPage}
        />
      </>
    );
  }
}

// export default Datatable;
const mapDispatchToProps = function (dispatch) {
  return {
    sentMessageListWithFetchAPI: (pagenum) => dispatch(sentMessageListWithFetchAPI(pagenum)),
    scheduleMessageListService: (pagenum) => dispatch(scheduleMessageListService(pagenum)),
    toggleScheduleMessageDrawer: () => dispatch(toggleScheduleMessageDrawer()),
    scheduleMessageDelete: (id, pageNumber, dataLength, groupOffset) =>
      dispatch(scheduleMessageDelete(id, pageNumber, dataLength, groupOffset))
  };
};
const mapStateToProps = function (state) {
  return {
    totalPages: _get(state, 'scheduleMessageList.data.scheduleMessageList.totalPages', 0),
    rowHighlight: _get(state, 'scheduleMessage.data.rowHighlight', true),
    messageList: _get(state, 'messageLists.data.messageList.items', []),
    scheduleMessageList: _get(state, 'scheduleMessageList.data.scheduleMessageList.items', []),
    scheduleMessageListOffset: _get(
      state,
      'scheduleMessageList.data.scheduleMessageList.offset',
      []
    ),
    deletedRowHighLight: _get(
      state,
      'scheduleMessageList.data.scheduleMessageList.items.group.deleted',
      false
    )
  };
};
ScheduleMessageList.propTypes = {
  sentMessageListWithFetchAPI: PropTypes.func,
  totalPages: PropTypes.number,
  messageList: PropTypes.array,
  updateRowClassFlag: PropTypes.bool
  // alertType: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(ScheduleMessageList);

import React from 'react';
import { Form, Select } from 'antd';
import { fetchRegionAndCrops } from '../../../services/groupFilterService';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchRegion extends React.Component {
  constructor(props) {
    super(props);
    this.childrenRegion = [];
  }
  handleChange = (user, value) => {
    console.log('value', value);
    const newUsers = [...this.props.users];
    console.log('newuser', newUsers);
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchRegionAndCrops();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    let regionData = this.props.groupFilterReducer || [];
    let regionText = regionData.length > 0 ? regionData[0].items : [];
    this.childrenRegion = regionText.map((region) => <Option key={region}>{region}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.regionValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some region') }]
        })(
          <Select
            name='regions'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Region')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenRegion}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchRegionAndCrops: () => dispatch(fetchRegionAndCrops())
  };
};
const mapStateToProps = function(state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupRegion.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchRegion);

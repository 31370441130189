import {
  SCHEDULE_MESSAGE_UPDATE_PENDING,
  SCHEDULE_MESSAGE_UPDATE_SUCCESS,
  SCHEDULE_MESSAGE_UPDATE_ERROR,
  UPDATE_SCHEDULE_MESSAGE_DRAWER,
  HIGHLIGHT_GROWER_TABEL_ROW,
  CREATE_GROWER_MODAL,
  CLEAR_GROWER_UPDATE
} from '../constants/index';

const initialState = {
  scheduleMessageUpdateSucess: [],
  scheduleMessageError: [],
  scheduleMessagePending: false,
  loading: false,
  messageStatus: '',
  highlightGrowerRow: false,
  visible: false,
  updateScheduleMessageDrawerVisible: false
};

function scheduleMessageUpdate(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MESSAGE_UPDATE_PENDING:
      return {
        ...state,
        scheduleMessageUpdatePending: action.payload
      };
    case SCHEDULE_MESSAGE_UPDATE_SUCCESS:
      return {
        ...state,
        scheduleMessageUpdateSucess: action.payload,
        messageStatus: 'updateSchedule'
      };
    case SCHEDULE_MESSAGE_UPDATE_ERROR:
      return {
        ...state,
        scheduleMessageUpdateError: action.payload,
        messageStatus: 'updateSchedule'
      };
    case UPDATE_SCHEDULE_MESSAGE_DRAWER:
      return {
        ...state,
        updateScheduleMessageDrawerVisible: !state.updateScheduleMessageDrawerVisible
      };
    case SCHEDULE_MESSAGE_UPDATE_ERROR:
      return {
        ...state,
        loading: action.payload
      };
    case HIGHLIGHT_GROWER_TABEL_ROW:
      return {
        ...state,
        highlightGrowerRow: action.payload
      };
    case CLEAR_GROWER_UPDATE:
      return {
        ...state,
        growerUpdateSucess: []
      };

    default:
      return state;
  }
}

export default scheduleMessageUpdate;

import React, { useContext, useEffect } from 'react';
import App from './App';
import AuthProvider from './provider/auth-provider';
import { AuthContext } from './context/auth-context';
import Loginpage from './views/Login/Loginpage';
import UnAuthorized from './unAuthorized';
import { msalApp } from './utils/auth-utils';
const AppWrapper = () => {
  const auth = useContext(AuthContext);
  console.log("auth", auth);
  return auth.isAuthenticated ? <App auth={auth} /> : <UnAuthorized auth={auth} />;
};

export default AuthProvider(AppWrapper);

  import {
    CREATE_GROUP,
    CREATE_GROUP_SUCCESS,
    CREATE_GROUP_FAILURE,
    CREATE_GROUP_DRAWER,
    HIGHLIGHT_GROUP_TABEL_ROW
  } from '../constants';

export function createGroupPending(Progressstatus) {
  return {
    type: CREATE_GROUP,
    payload: Progressstatus
  };
}
export function createGroupSuccess(gdata) {
  return {
    type: CREATE_GROUP_SUCCESS,
    payload: gdata
  };
}

export function createGroupFailure(gerror) {
  return {
    type: CREATE_GROUP_FAILURE,
    payload: gerror
  };
}
export function toggleGroupDrawer() {
  return {
    type: CREATE_GROUP_DRAWER
  };
}

export function highlightGroupTableRow(rowStatus) {
  return {
    type: HIGHLIGHT_GROUP_TABEL_ROW,
    payload: rowStatus
  };
}

import {
  MESSAGE_COUNT_LIST_PENDING,
  MESSAGE_COUNT_LIST_SUCCESS,
  MESSAGE_COUNT_LIST_FAILURE,
  MESSAGE_COUNT_LIST_LOADING
} from '../constants/index';

const initialState = {
  data: {
    messageCountList: {},
    messageCountListError: {},
    messageCountListStatus: '',
    completed: false,
    loading: true
  }
};

function messageCountLists(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_COUNT_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case MESSAGE_COUNT_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageCountList: action.payload,
          completed: false
        }
      });
    case MESSAGE_COUNT_LIST_FAILURE:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageCountListError: action.payload,
          completed: false
        }
      });
    case MESSAGE_COUNT_LIST_LOADING:
      return {
        data: {
          ...state.data,
          loading: action.payload
        }
      };
    default:
      return state;
  }
}

export default messageCountLists;

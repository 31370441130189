import React from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes, { resetWarningCache } from 'prop-types';
import { Form, Upload, Button, Icon, Select, Progress, Input, Radio } from 'antd';
import './postUpload.css';
const { Option } = Select;
const { TextArea } = Input;
class VideoUploadForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      filevalid: false,
      title: '',
      snippet: {
        title: {},
        description: {}
      }
    };

    /****** Drawer function declarations */
    this.onChange = this.onChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    //this.fileValidation = this.fileValidation.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      const userlogin = localStorage.getItem(
        'adal.access.token.key123ed289-af53-42df-b8ed-a7acfde7f3e9'
      );
      if (userlogin === 'null' || userlogin === null) {
        return null;
      }
      const decoded = jwtDecode(userlogin);
      const emailId = decoded ? decoded.unique_name : '';

      if (!err) {
        let snippet = {
          title: values.Title,
          description: values.Description,
          media: values.Upload.file
        };

        const formData = new FormData();
        //formData.append('resource', JSON.stringify(snippet));
        //formData.append('media', values.Upload.file);
        // formData.append('description', values.description);
        //formData.append('userId', emailId);
        this.props.videoUploadService(snippet);
      }
    });
  }

  /***** Select dropdown Functions */

  onChange(value) {
    this.setState({
      selectedSchema: value
    });
  }

  // fileValidation(rule, val, callback) {
  //   if (
  //     val.file.type !== 'application/vnd.ms-excel' &&
  //     val.file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  //   ) {
  //     callback('Please select only excel-Sheet');
  //     return;
  //   }
  //   callback();
  // }

  handleChange(info) {
    let fileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show one recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    this.setState({ fileList });
  }

  render() {
    const { uploading, fileList } = this.state;

    const props = {
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },
      onChange: this.handleChange,
      beforeUpload: (file) => {
        this.setState((state) => ({
          fileList: [...state.fileList, file]
        }));
        return false;
      },
      // onProgress: () => {
      // },
      multiple: false,
      fileList
    };
    const { getFieldDecorator, resetFields } = this.props.form;

    return (
      <Form onSubmit={this.handleSubmit} className='login-form' method='post'>
        <Form.Item>
          {getFieldDecorator('Title', {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{ required: true, message: 'Please enter a title' }]
          })(<Input placeholder='Enter Post Title' size='small' />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('Description', {
            validateTrigger: ['onChange', 'onBlur'],
            rules: [{ required: true, message: 'Please enter a description' }]
          })(<TextArea placeholder='Enter Post Description' size='large' rows={5} />)}
        </Form.Item>
        <Form.Item>
          <Radio.Group
            size='medium'
            className='webstoreRadioGroup'
            // onChange={this.handleChange}>
          >
            {/* {this.modes} */}
          </Radio.Group>
          Select Media
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('Upload', {
            getValueFromEvent: this.handlePhotoChange,
            rules: [
              { required: true, message: 'Please select your file!' }
              // { filevalid: true, validator: this.fileValidation }
            ]
          })(
            <Upload {...props} fileList={this.state.fileList}>
              <Button
                onClick={(e) => {
                  this.props.clearProgressStatus();
                  this.setState({
                    fileList: []
                  });
                }}>
                Add Media
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item>
          <Button
            type='primary'
            size='medium'
            htmlType='submit'
            // disabled={fileList.length === 0}
            loading={uploading}
            className='uploadBtn'
            block>
            <Icon type='upload' /> {uploading ? 'Uploading' : 'Post Now'}
          </Button>
        </Form.Item>
        <Form.Item>
          {fileList.length > 0 ? (
            <Progress
              percent={fileList.length === 0 ? 0 : this.props.progress}
              showInfo={true}
              status={fileList.length === 0 ? 'active' : this.props.progressStatus}
            />
          ) : null}
        </Form.Item>
      </Form>
    );
  }
}

const VideoUpload = Form.create({ name: 'normal_login' })(VideoUploadForm);

VideoUploadForm.propTypes = {
  form: PropTypes.any,
  getFieldDecorator: PropTypes.object,
  fileValidation: PropTypes.object,
  progress: PropTypes.number,
  progressStatus: PropTypes.string,
  videoUploadService: PropTypes.func
};
export default VideoUpload;

import React, { Component } from 'react';
import { Form, Icon, Radio, Spin } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';

import PropTypes from 'prop-types';
import messageFirstBgSVG from '../../../../../../assets/img/MessageSvg/create-msg-illustration.svg';
import { communicationLists } from '../../../../../../actions/modesOfCommunication';
/****** MessgeType Icons *********/
import { textualSVG, imageSVG } from '../../../messageSvg/messageSvgIcons';
import './firstStep.css';
const FormItem = Form.Item;

class First extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      messageType: '',
      channelMode: ['SMS']
    };
  }
  onChange1 = (e) => {
    this.props.checkMessageType(e.target.value);
    this.setState({
      messageType: e.target.value
    });
  };
  validateMessageType = (rule, value, callback) => {
    if (value && value !== 'SMS' && value !== 'WHATSAPP' && value !== 'MMS') {
      callback('This service will come soon.');
    } else {
      callback();
    }
    callback();
  };
  handleChange = () => {
    this.props.form.validateFields((error, value) => {
      this.props.checkMessageType(value.SocialPlatform);
      this.setState({
        messageType: value.SocialPlatform
      });
      this.props.fetchCostEstimatorClear();
      this.setState({ channelMode: value.SocialPlatform });
      if (!error) {
        const mdata = {
          channels: [value.SocialPlatform]
          // groupId: value.groupType
        };
        this.setState({ mdata });

        // this.props.fetchCostEstimator(mdata);
        // this.props.fetchWhatsappLimit(value.groupType);
      }
    });
  };
  componentDidMount = () => {
    // this.props.fetchAllGroupList(0);
    if (this.props.region) {
      this.props.communicationLists(this.props.region.country_code);
    }
    // this.props.fetchCostEstimatorClear();
  };
  componentDidUpdate(prevProps) {
    if (this.props.region && this.props.region !== prevProps.region) {
      this.props.communicationLists(this.props.region.country_code);
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { communicationModes } = this.props;
    this.modes =
      communicationModes &&
      communicationModes.map((item) => {
        return (
          <Radio.Button value={item.name}>
            <img src={item.image_url} />
            <label>{item.name}</label>
          </Radio.Button>
        );
      });
    const { t } = this.props;
    return (
      <div className='thirdDiv'>
        {/* <p style={{ marginBottom: '2em', textAlign: 'center' }}>
          {t('Select the type of message')}
        </p> */}
        <p>{t('Select mode of communication')}</p>
        {/* <img style={{ height: '180px' }} alt={t('Creat A New Message')} src={messageFirstBgSVG} /> */}
        {/* <FormItem className='messagtype'>
          {getFieldDecorator('messageType', {
            rules: [
              {
                required: true,
                message: t('Please select message type!')
              },
              {
                validator: (rule, value, callback) => {
                  try {
                    if (this.props.countryData === 'idn' ? false : value === 'imageMessage') {
                      throw new Error(t('This service will come soon.'));
                    }

                    callback();
                  } catch (err) {
                    callback(err);
                  }
                }
              }
            ],
            initialValue: 'textMessage'
          })(
            <Radio.Group size='large' onChange={this.onChange1}>
              <Radio.Button value='textMessage'>
                <Icon className='firstStepTextualIcon' component={textualSVG} />
                <p>{t('Textual')}</p>
              </Radio.Button>
              <Radio.Button title='coming soon' value='imageMessage'>
                <Icon title='coming soon' className='firstStepTextualIcon' component={imageSVG} />
                <p>{t('Image')}</p>
              </Radio.Button>
            </Radio.Group>
          )}
        </FormItem> */}
        <FormItem onChange={this.handleChange}>
          {getFieldDecorator('SocialPlatform', {
            // validateTrigger: ['onChange', 'onBlur'],
            rules: [
              {
                required: true,
                message: t('Please select platform type')
              },
              {
                validator: this.validateMessageType
              }
            ]
            // initialValue: 'SMS'
          })(
            <Radio.Group size='large' className='thirdstepRadioGroup'>
              {this.modes.length ? this.modes : <Spin />}
            </Radio.Group>
          )}
        </FormItem>
      </div>
    );
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    communicationLists: (regionCode) => dispatch(communicationLists(regionCode))
    // fetchAllGroupList: (pagenum) => dispatch(fetchAllGroupList(pagenum)),
    // fetchCostEstimator: (mdata) => dispatch(fetchCostEstimator(mdata)),
    // fetchWhatsappLimit: (mdata) => dispatch(fetchWhatsappLimit(mdata))
  };
};
const mapStateToProps = function (state) {
  return {
    communicationModes: get(
      state,
      'communicationList.communicationLists.supported_channel.channels',
      []
    ),
    region: get(state, 'webStoreListReducer.regionListData.current_region', '{}'),
    groupListData: get(state, 'groupListReducer.data.groupListData.items', []),
    costEstimatorLoader: get(state, 'costEstimatorList.data.costEstimatorLoader', {})
  };
};
First.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
const FirstForm = Form.create({})(First);

// export default FirstForm;
export default connect(mapStateToProps, mapDispatchToProps)(FirstForm);

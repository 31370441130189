import {
  SCHEDULE_MESSAGE_DELETE_PENDING,
  SCHEDULE_MESSAGE_DELETE_SUCCESS,
  SCHEDULE_MESSAGE_DELETE_ERROR,
  DELETE_SCHEDULE_MESSAGE_DRAWER
} from '../constants/index';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { scheduleMessageListService } from './scheduleMessageList';
import { handleErrors } from '../services/handleErrors';
import { formatUrl } from '../services/commonMethods';
import config from '../config';

export const scheduleMessageDeletePending = (value) => {
  return {
    type: SCHEDULE_MESSAGE_DELETE_PENDING,
    payload: value
  };
};
export const scheduleMessageDeleteSuccess = (value) => {
  return {
    type: SCHEDULE_MESSAGE_DELETE_SUCCESS,
    payload: value
  };
};

export const scheduleMessageDeleteFailure = (error) => {
  return {
    type: SCHEDULE_MESSAGE_DELETE_ERROR,
    payload: error
  };
};

export function toggleScheduleMessageDrawer() {
  return {
    type: DELETE_SCHEDULE_MESSAGE_DRAWER
  };
}

export const scheduleMessageDelete = (messageId, pageNumber, dataLength, groupOffset) => {
  let pageCount = 0;
  if (pageNumber > 0 && dataLength.length === 1) {
    pageCount = groupOffset - 1;
  } else if (pageNumber === 0 && dataLength.length === 1) {
    pageCount = groupOffset;
  } else {
    pageCount = groupOffset;
  }
  const checkPageNumber = pageNumber <= 0 ? pageNumber : pageNumber - 1;
  const apiUri = formatUrl(config.deleteScheduleMessage, messageId);
  return (dispatch) => {
    dispatch(scheduleMessageDeletePending());
    dispatch(toggleSpinStatus());
    // dispatch(clearAlertState());
    return fetch(apiUri, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',

        Authorization: 'Bearer ' + config.userToken
      }
      //   body: JSON.stringify(groupData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          dispatch(clearAlertState());
          dispatch(toggleSpinStatus());
          //   dispatch(toggleScheduleMessageDrawer());
          dispatch(scheduleMessageDeleteSuccess(result));
          // setTimeout(
          //   function() {
          dispatch(scheduleMessageListService(pageCount));
          //   }.bind(),
          //   3000
          // );
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          dispatch(toggleSpinStatus());
          dispatch(clearAlertState());
          dispatch(scheduleMessageDeleteFailure(result));
          dispatch(
            errorNotification({
              type: 'error',
              message: result.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        }
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
        dispatch(toggleGroupDeleteDrawer());
        dispatch(scheduleMessageDeleteFailure(e));

        dispatch(
          errorNotification({
            type: 'error',
            message: e.message,
            toggleAlert: true
          })
        );

        dispatch(clearAlertState());
      });
  };
};

import React from 'react';
import { Layout } from 'antd';
import './help.css';
import ComminSoon from '../ComingSoon/Comingsoon';
/*** Ant Design Components */

// const { Content } = Layout;

export default function Help() {
  return (
    <Layout className="helpLayout">
      <ComminSoon />
    </Layout>
  );
}

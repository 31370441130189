import styled from 'styled-components';
import { Row, Card, Modal, Input, Form, Upload, Button } from 'antd';
export const StyledUpload = styled(Upload)`
.ant-upload.ant-upload-select {
    display: block;
}
`;
export const StyledUploadButton = styled(Button)`
.ant-upload .ant-btn {
    width: 255px;
  }
`;
 
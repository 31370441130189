import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import GrowerInformationForm from '../GrowerForm/growerInformationForm';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'dark',
    loginPageImage: 'phLogin',
    loginButtonStyle: 'phLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    paiChart: 'phDistributionCard',
    sampleTemplate:
      'https://grower-console-qa-assets.s3-ap-southeast-1.amazonaws.com/sampl-file/Sample-ph.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      // {
      //   key: 'engagegrowers',
      //   label: t('Engage Growers')
      // },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },
      // {
      //   key: 'users',
      //   label: t('Users')
      // },
      {
        key: 'help',
        label: t('Help')
      }
    ],
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },
      // {
      //   pathName: '/users',
      //   componentName: Users
      // },
      // {
      //   pathName: '/engagegrowers',
      //   componentName: WebStore
      // },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/grower-information-form',
        componentName: GrowerInformationForm
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Province') },
        { key: 'Acres', value: t('Hectares') },
        { key: 'Crop', value: t('Crop') },
        { key: 'city', value: t('City') },
        { key: 'Tags', value: t('Tags') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'salesUnit', value: t('Source') },
        { key: 'createdDate', value: t('Created Date') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        Crop: [
          { key: 'in', value: t('in') },
          { key: 'inOnly', value: t('in only') }
        ],
        city: [{ key: 'in', value: t('in') }],
        Tags: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'equals to', value: t('equals to') },
          { key: 'contains', value: t('contains') }
        ],
       
        salesUnit: [{ key: 'in', value: t('in') }],
        createdDate: [
          { key: 'to', value: t('to') },
          { key: 'from', value: t('from') }
        ]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some crops')
          }
        ]
      },
      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        fieldName: 'GrowersRegion',
        initialValue: 'GrowersRegion',
        placeholder: t('Select Some Province'),
        handler: 'handleRegionSelectAll',
        rules: []
      },
      {
        key: 'allCity',
        optionValue: 'childrenCity',
        fieldName: 'GrowersCity',
        initialValue: 'GrowersCity',
        placeholder: t('Select Some City'),
        handler: 'handleCitySelectAll',
        rules: []
      },
      {
        key: 'allTags',
        optionValue: 'childrenTags',
        fieldName: 'GrowersTags',
        initialValue: 'GrowersTags',
        placeholder: t('Select Some Tags'),
        handler: 'handleTagsSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('First Name'),
          fieldName: 'firstName',
          initialValue: 'firstName',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter first name'),
            whitespace: true
          }
        },
        {
          label: t('Middle Initial'),
          fieldName: 'middleName',
          initialValue: 'middleName',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter middle initial'),
            whitespace: true
          }
        },
        {
          label: t('Last Name'),
          fieldName: 'lastName',
          initialValue: 'lastName',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter last name'),
            whitespace: true
          }
        },
        {
          label: t('Date Of Birth'),
          fieldName: 'dateOfBirth',
          initialValue: 'dateOfBirth',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter date of birth'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Hectare)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        // {
        //   label: t('Taluka'),
        //   fieldName: 'Taluka',
        //   initialValue: 'taluka',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter taluka'),
        //     whitespace: true
        //   }
        // },
        // {
        //   label: t('Zone'),
        //   fieldName: 'Zone',
        //   initialValue: 'zone',
        //   type: 'text',
        //   rules: {
        //     required: true,
        //     message: t('Please enter zone'),
        //     whitespace: true
        //   }
        // },
        {
          label: t('City'),
          fieldName: 'City',
          initialValue: 'city',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter city'),
            whitespace: true
          }
        },
        {
          label: t('Province'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter province'),
            whitespace: true
          }
        },
        // {
        //   label: t('Municipality'),
        //   fieldName: 'Municipality',
        //   initialValue: 'municipality',
        //   type: 'text',
        //   disable: true,
        //   rules: {
        //     required: true,
        //     message: t('Please enter municipality'),
        //     whitespace: true
        //   }
        // },
        {
          label: t('Barangay'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter barangay'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Date of Birth'),
          fieldName: 'dateofBirth',
          initialValue: 'dateofBirth',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter date of birth'),
            whitespace: true
          }
        },
        {
          label: t('Date of Event'),
          fieldName: 'dateofEvent',
          initialValue: 'dateofEvent',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter date of event'),
            whitespace: true
          }
        },
        
        {
          label: t('Event Attended'),
          fieldName: 'eventAttended',
          initialValue: 'eventAttended',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter event attended'),
            whitespace: true
          }
        },
        // {
        //   label: t('Seed Variety'),
        //   fieldName: 'seedVariety',
        //   initialValue: 'seedVariety',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter seed variety'),
        //     whitespace: true
        //   }
        // },
        // {
        //   label: t('No# of Bags Planted'),
        //   fieldName: 'noOfBagsPlanted',
        //   initialValue: 'noOfBagsPlanted',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter number of bags')
        //   }
        // }
      ]
    },
    seedVarityVisible: true,
    productUsedLastSeasonVisible: true,
    dividerHeight: '630px',
    hideSaveButton: false,
    tagVisible: true,
    hideNewTag: false,
    removeTags: false,
    // acresVisible: true,
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('FARM SIZE/FBT(hectare)'),
        dataIndex: 'landsize'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('FARM SIZE/FBT(hectare)'),
        dataIndex: 'landSize'
      }
    ],
    replaceTitle: ['0-20 hectares', '21-50 hectares', '51-100 hectares', '> 100 hectares'],
    searchCityMessage: {
      message: t('Please select some city'),
      placeholder: t('Select Some City')
    },
    searchHectaresMessage: {
      message: t('Please enter hectares value'),
      placeholder: t('Enter Hectares Value')
    },
    searchSourceMessage: {
      message: t('Please select some source'),
      placeholder: t('Select Some Source')
    },
  };
};

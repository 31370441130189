import { ADD_GROWER_PENDING, ADD_GROWER_SUCCESS, ADD_GROWER_ERROR } from '../constants/index';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
import { toggleSpinStatus } from './globalSpinner';
import { formatUrl } from '../services/commonMethods';
import { updateSingleGrower } from './updateSingleGrower';
import config from '../config';

export const addGrowerPending = () => {
  return {
    type: ADD_GROWER_PENDING
  };
};
export const addGrowerSuccess = (value) => {
  return {
    type: ADD_GROWER_SUCCESS,
    payload: value
  };
};

export const addGrowerError = (error) => {
  return {
    type: ADD_GROWER_ERROR,
    payload: error
  };
};
export const fetchAddSingleGrowers = (growers) => {
  const apiUri = formatUrl(config.addGrower);

  return (dispatch) => {
    dispatch(addGrowerPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(growers)
    })
      .then((response) => response.json())
      .then((result) => {
        if (result && result.code === '200') {
          dispatch(addGrowerSuccess(result));
          dispatch(toggleSpinStatus());
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } 
        else if (result && result.code === '400' || result.code === '4025') {
          dispatch(
            errorNotification({
              type: 'error',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        dispatch(addGrowerError(error));
        dispatch(toggleSpinStatus());
        dispatch(clearAlertState());
        dispatch(
          errorNotification({
            type: 'error',
            message: error.message,
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};

import React from 'react';
import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import Unauthorization from '../views/commonComponents/errorboundry/unauthorizationHandle';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import SocialMedia from '../views/SocialMedia/SocialMedia';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Inbox from '../views/Communication/whatsApp';
import Help from '../views/Help/Help';
import Utility from '../views/utility/utility';
import GrowerInformationForm from '../GrowerForm/growerInformationForm';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'dark',
    loginPageImage: 'bdLogin',
    loginButtonStyle: 'idnLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    drawerWidth: '200',

    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },

      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },

      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      },
      {
        pathName: '/inbox',
        componentName: Inbox
      },
      
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '/grower-information-form',
        componentName: GrowerInformationForm
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    uploadSheetButton: [
      {
        title: `+${t('UPLOAD A FILE (BULK UPLOAD)')}`,
        key: 'bulkUpload'
      }
    ],
    sampleTemplate:
      'https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/sampl-file/sample_template_bd.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      {
        key: 'inbox',
        label: t('Inbox'),
        itemKey: 'sub2',
        children: [
          {
            key: 'inbox',
            label: t('WhatsApp')
          },
          {
            key: 'comingsoon',
            label: t('Line')
          }
        ]
      },
      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },

      {
        key: 'help',
        label: t('Help')
      }
    ],
    amplitude: 'IN',
    advaceSearch: {
      filterData: [
        { key: 'Crop', value: t('Crop') },
        { key: 'district', value: t('District') },
        { key: 'Acres', value: t('Land Size') },
        { key: 'brand', value: t('Brand') },
        { key: 'salesUnit', value: t('Stockist Code') },
        { key: 'retailerName', value: t('Retailer Name') },
        { key: 'retailerCode', value: t('Retailer Code') },
        { key: 'Tags', value: t('Tags') },
        { key: 'tmhq', value: t('Sales Unit') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'createdDate', value: t('Created Date') }
      ],
      dropDownMenu: {
        Crop: [{ key: 'in', value: t('in') }],
        district: [{ key: 'in', value: t('in') }],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        brand: [{ key: 'in', value: t('in') }],
        salesUnit: [{ key: 'in', value: t('in') }],
        retailerName: [{ key: 'in', value: t('in') }],
        retailerCode: [{ key: 'in', value: t('in') }],
        Tags: [{ key: 'in', value: t('in') }],
        tmhq: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ],
        createdDate: [
          { key: 'to', value: t('to') },
          { key: 'from', value: t('from') }
        ],
      },
      defaulState: [{ id: 1, field: { name: 'Crop' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Crop'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: []
      },
      {
        key: 'allDistrict',
        optionValue: 'childrenDistrict',
        fieldName: 'GrowersDistrict',
        initialValue: 'GrowersDistrict',
        placeholder: t('Select Some District'),
        handler: 'handleDistrictSelectAll',
        rules: []
      },

      {
        key: 'allBrand',
        optionValue: 'childrenBrand',
        fieldName: 'GrowersBrand',
        initialValue: 'GrowersBrand',
        placeholder: t('Select Some Brand'),
        handler: 'handleBrandSelectAll',
        rules: []
      },
      {
        key: 'allRetailerName',
        optionValue: 'childrenRetailerName',
        fieldName: 'GrowersRetailerName',
        initialValue: 'GrowersRetailerName',
        placeholder: t('Select Some Retailer Name'),
        handler: 'handleRetailerNameSelectAll',
        rules: []
      },
      {
        key: 'allStockistCode',
        optionValue: 'childrenStockistCode',
        fieldName: 'GrowersStockistCode',
        initialValue: 'GrowersStockistCode',
        placeholder: t('Select Some Stockist Code'),
        handler: 'handleStockistCodeSelectAll',
        rules: []
      },
      {
        key: 'allTmhq',
        optionValue: 'childrenTmhq',
        fieldName: 'GrowersTmhq',
        initialValue: 'GrowersTmhq',
        placeholder: t('Select Some Sales Unit'),
        handler: 'handleTmqhSelectAll',
        rules: []
      },
      {
        key: 'allTags',
        optionValue: 'childrenTags',
        fieldName: 'GrowersTags',
        initialValue: 'GrowersTags',
        placeholder: t('Select Some Tags'),
        handler: 'handleTagsSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('First Name'),
          fieldName: 'firstName',
          initialValue: 'firstName',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter first name'),
            whitespace: true
          }
        },
        {
          label: t('Last Name'),
          fieldName: 'lastName',
          initialValue: 'lastName',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter last name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Decimal)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Village'),
          fieldName: 'Village',
          initialValue: 'village',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter village'),
            whitespace: true
          }
        },

        {
          label: t('Taluka'),
          fieldName: 'Taluka',
          initialValue: 'taluka',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter taluka'),
            whitespace: true
          }
        },

        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter district'),
            whitespace: true
          }
        },
        {
          label: t('Upazila'),
          fieldName: 'subDistrict',
          initialValue: 'subDistrict',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter Upazila'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        // {
        //   label: t('Stockist Code'),
        //   fieldName: 'Unit',
        //   initialValue: 'unit',
        //   type: 'text',
        //   disable: true,
        //   rules: {
        //     required: true,
        //     message: t('Please enter stockist code'),
        //     whitespace: true
        //   }
        // },
        {
          label: t('Stockist Name'),
          fieldName: 'stockist',
          initialValue: 'stockist',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter stockist Name'),
            whitespace: true
          }
        },

        {
          label: t('Retailer Name'),
          fieldName: 'retailerName',
          initialValue: 'retailerName',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter retailer name'),
            whitespace: true
          }
        },
        {
          label: t('Retailer Code'),
          fieldName: 'retailerCode',
          initialValue: 'retailerCode',
          type: 'text',
          disable: true,
          rules: {
            required: false,
            message: t('Please enter retailer code'),
            whitespace: true
          }
        },
        // {
        //   label: t('MDOHQ'),
        //   fieldName: 'mdohq',
        //   initialValue: 'mdohq',
        //   type: 'text',
        //   disable: true,
        //   rules: {
        //     required: false,
        //     message: t('Please enter mdohq '),
        //     whitespace: true
        //   }
        // },
        {
          label: t('Sales Unit'),
          fieldName: 'Unit',
          initialValue: 'unit',
          type: 'text',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter sales unit '),
            whitespace: true
          }
        }
      ]
    },
    tagVisible: true,
    acresVisible: true,
    hideSaveButton: false,
    cropHistoryVisible: true,
    hideNewTag: false,
    campignTable: [
      {
        title: t('MODE'),
        dataIndex: 'mode'
      },
      {
        title: t('CAMPAIGN NAME'),
        dataIndex: 'name'
      },
      {
        title: t('REACH'),
        dataIndex: 'reach'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (Decimal)'),
        dataIndex: 'landSize'
      },
      {
        title: t('BRAND'),
        dataIndex: 'latestBrand'
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName'
      },
      // {
      //   title: `#${t('MEMBERS')}`,
      //   dataIndex: 'noOfMembers'
      // },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters'
      }
    ],
    brandVisible: true,
    removeTags: false,
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (Decimal)'),
        dataIndex: 'landsize'
      }
    ],
    interactTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('SENDER'),
        dataIndex: 'Sender'
      },
      {
        title: t('SENT ON'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    scheduleMessageTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group'
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    searchZoneMessage: {
      message: t('Please select some zone'),
      placeholder: t('Select Some Zone')
    },
    searchHectaresMessage: {
      message: t('Please enter land size'),
      placeholder: t('Enter Land Size')
    },
    searchSourceMessage: {
      message: t('Please select some stockist code'),
      placeholder: t('Select Some Stockist Code')
    },
    searchTmhqMessage: {
      message: t('Please select some sales unit'),
      placeholder: t('Select Some Sales Unit')
    },
    replaceTitle: ['0-20 decimal', '21-50 decimal', '51-100 decimal', '> 100 decimal'],
    paiChart: 'bdDistributionCard',
    dividerHeight: '590px'
  };
};

import React from 'react';

export default ({ name, message }) => (
  <>
    <h3>Sent Messages:</h3>
    <p style={{ color: '#000' }}>
      <strong>{name}</strong> <em>{message}</em>
    </p>
  </>
);

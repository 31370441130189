import {
  UPLOAD_YOUTUBE_VIDEO_PENDING,
  UPLOAD_YOUTUBE_VIDEO_SUCESS,
  UPLOAD_YOUTUBE_VIDEO_ERROR,
  CLEAR_YOUTUBE_VIDEO_PROGRESS,
  UPLOAD_YOUTUBE_VIDEO_PROGRESS,
  TOGGLE_DRAWER_STATUS,
  VIDEO_PROGRESS_SUCESS,
  VIDEO_PROGRESS_ERROR,
  CLEAR_YOUTUBE_VIDEO_STATUS
} from '../constants';

const initialState = {
  file: {},
  progress: 0,
  drawerVisible: false,
  rowHighlight: false,
  selectedSchema: '',
  uploadStatus: '',
  progressStatus: 'active',
  progressBarStatus: false,
  processingStatus: {},
  processingError: {},
  completed: false
};

export default function uploadYouTubeVideo(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_YOUTUBE_VIDEO_PENDING:
      return {
        ...state,
        uploadStatus: action.payload.uploadStatus,
        completed: true
      };
    case UPLOAD_YOUTUBE_VIDEO_SUCESS:
      return {
        ...state,
        file: action.payload,
        completed: false
      };
    case UPLOAD_YOUTUBE_VIDEO_ERROR:
      return {
        ...state,
        file: action.payload,
        completed: false
      };
    case CLEAR_YOUTUBE_VIDEO_PROGRESS:
      return {
        ...state,
        progressStatus: 'active',
        progress: 0
      };
    case UPLOAD_YOUTUBE_VIDEO_PROGRESS:
      return {
        ...state,
        progressStatus: action.payload.progressStatus,
        progressBarStatus: action.payload.progressBarStatus,
        progress: action.payload.progress
      };
    case VIDEO_PROGRESS_SUCESS:
      return {
        ...state,
        processingStatus: action.payload
      };
    case VIDEO_PROGRESS_ERROR:
      return {
        ...state,
        processingError: action.payload
      };
    case CLEAR_YOUTUBE_VIDEO_STATUS:
      return {
        ...state,
        file: {}
      };

    case TOGGLE_DRAWER_STATUS:
      return {
        ...state,
        drawerVisible: !state.drawerVisible
      };

    default:
      return state;
  }
}

import { TOGGLE_SPIN_STATUS, COST_STATUS } from '../constants';

export function toggleSpinStatus() {
  return {
    type: TOGGLE_SPIN_STATUS
  };
}
export function estamatingCostStatus() {
  return {
    type: COST_STATUS
  };
}

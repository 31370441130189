import {
  DOWNLOAD_SEARCH_RESULT_PENDING,
  DOWNLOAD_SEARCH_RESULT_SUCESS,
  DOWNLOAD_SEARCH_RESULT_ERROR
} from '../constants';

const initialState = {
  data: {
    downloadSearchResult: {},
    searchResultError: {},
    users: [],
    completed: false
  }
};

function downloadSearchResult(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_SEARCH_RESULT_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case DOWNLOAD_SEARCH_RESULT_SUCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          downloadSearchResult: action.payload,
          completed: false
        }
      });
    case DOWNLOAD_SEARCH_RESULT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          searchResultError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default downloadSearchResult;

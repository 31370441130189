import React from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Collapse,
  Card,
  Modal,
  Layout,
  Row,
  Col,
  Checkbox,
  Result
} from 'antd';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import jsonData from './dataTh.json';
import newJsonData from './newDataTh.json';
import { fetchAddGrowers } from '../actions/addUpdatesGrowerForm';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
import { uniq } from 'lodash';
import './addGrowerForm.css';
const { Option } = Select;
const { Panel } = Collapse;
const { Footer } = Layout;
const AutoCompleteOption = AutoComplete.Option;
const cropValues = [
  { key: 'ข้าว', value: 'Rice' },
  { key: 'ข้าวโพด', value: 'Corn' },
  { key: 'อ้อย', value: 'Sugarcane' },
  { key: 'มันสำปะหลัง', value: 'Cassava' },
  { key: 'ปาล์ม/ยาง', value: 'oil Plam' },
  { key: 'ไม้ผล', value: 'Fruit' },
  { key: 'ผักกินใบ', value: 'Leafy Veg' },
  { key: 'ผักให้ผล/หัว', value: 'Veg' },
  { key: 'พืชอื่นๆ', value: 'Others' }
];
const landValues = ['1-5', '5-10', '10-20', '20-50', '>50 rai'];
const activityTypes = [
  { key: 'FMN', value: 'FMN' },
  { key: 'FML', value: 'FML' },
  { key: 'BFD', value: 'BFD' },
  { key: 'FD', value: 'FD' },
  { key: 'SPOT', value: 'SPOT' },
  { key: 'LD', value: 'LD' },
  { key: 'EXPO', value: 'EXPO' },
  { key: 'Mkting Event', value: 'Mkting Event' },
  { key: 'Testimonials etc. Picture,VDO,Clip', value: 'Testimonials etc. Picture,VDO,Clip' },
  { key: 'Trials Stage6.1', value: 'Trials Stage6.1' },
  { key: 'Trials Stage6.2', value: 'Trials Stage6.2' }
];
class AddGrowerTH extends React.Component {
  state = {
    formData: {},
    confirmDirty: false,
    autoCompleteResult: [],
    checked: true,
    visible: false,
    getProvince: '',
    getDistrict: '',
    getRegion: '',
    provinceDataVal: [],
    zipCodeVale: [],
    districteDataVal: [],
    getZipCode: '',
    done: null,
    message: '',
    isError: false,
    thaiValue: '',
    disabled:false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let growers = {
          name: values.name,
          surname: values.surname,
          mobile: `66${
            values.mobile.indexOf(0) == '0' ? values.mobile.substring(1) : values.mobile
          }`,
          address: {
            zipCode: values.zipCode,
            zone: values.province,
            district: values.district,
            region: 'NA',
            houseNumber: values.houseNo,
            subDistrict: values.subDistrict,
            village: values.villageName
          },
          sourcing: {
            salesUnit: values.source
          },
          cropInfo: {
            current: {
              name: values.crop,
              acres: values.acre
            }
          },
          additionalFields: {
            Channel: 'Form',
            activitiesType: values.activitiesType
          }
        };

        this.props.fetchAddGrowers(growers);
        this.setState({
          disabled: true,
        });
        setTimeout(() => {
          this.setState(() => ({
            disabled: false,
          }));
        }, 10000);
        // if (this.props.alertType === 'sucess') {
        // this.setState({
        //   done: true
        // });
        // }
      }
    });
  };
  onChange = (e) => {
    this.setState({ checked: !e.target.checked });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  isThaichar = (str) => {
    var isThai = true;
    var orgi_text =
      ' ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ';
    var chk_text = str.split('');
    chk_text.filter(function(s) {
      if (orgi_text.indexOf(s) == -1) {
        isThai = false;
        this.setState({ thaiValue: str.replace(RegExp(s, 'g'), '') });
        // obj.value = str.replace(RegExp(s, 'g'), '');
      }
    });
    return isThai; // ถ้าเป็น true แสดงว่าเป็นภาษาไทยทั้งหมด*/
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
  };
  validateNumber = (rule, value, callback) => {
    let mob = /^[1-9]{1}[0-9]{10}$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  validateMobileNumber = (rule, value, callback) => {
    let mob = /^[1-9]{1}[0-9]{10}$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (value.length > 10 || value.length < 10) {
      callback('เบอร์โทรศัพท์ 10 หลัก');
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('กรุณากรอกเบอร์โทรศัพท์มือถือ');
    } else {
      callback();
    }
  };
  validateMobileNumber1 = (rule, value, callback) => {
    let mob = /^[1-9]{1}[0-9]{10}$/;
    let count = 0;
    let position = String(value).indexOf('.');
    while (position !== -1) {
      count++;
      position = value.indexOf('.', position + 1);
    }
    if (value.length === '10') {
      callback('เบอร์โทรศัพท์ 10 หลัก');
    } else if (isNaN(value) || Math.sign(value) == -1 || count > 1) {
      callback('กรุณากรอกเบอร์โทรศัพท์มือถือ');
    } else {
      callback();
    }
  };
  validatorString = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    var re = new RegExp(
      '^([ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ])+$',
      'g'
    );
    if (value === '' || value === undefined) {
      callback();
    } else if (!re.test(value)) {
      callback('กรุณากรอกชื่อที่ถูกต้อง');
    } else if (value.length < 2) {
      callback('กรุณากรอกข้อมูลให้ถูกต้อง');
    } else {
      callback();
    }
  };
  isThaichar = (str, obj) => {
    var isThai = true;
    var orgi_text =
      ' ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ';
    var chk_text = str.split('');
    chk_text.filter(function(s) {
      if (orgi_text.indexOf(s) == -1) {
        isThai = false;
        obj.value = str.replace(RegExp(s, 'g'), '');
      }
    });
    return isThai; // ถ้าเป็น true แสดงว่าเป็นภาษาไทยทั้งหมด*/
  };
  validatorStringRegion = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please enter valid region');
    } else {
      callback();
    }
  };
  validatorStringProvince = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please enter valid province');
    } else {
      callback();
    }
  };
  hideSucess = () => {
    this.setState({
      done: null,
      disabled:false
    });
  };
  componentDidMount = () => {
    const { location } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    this.setState({
      formData: params
    });

    const provinceJsonData = jsonData;
    const zipCodeJsonData = newJsonData;
    let provinceData = [];
    let districtData = [];
    let zipCodeData = [];
    let isProvinceAvailable = '';
    let isRegionAvailable = '';
    let isDistrictAvailable = '';
    let checkProvince = '';
    provinceJsonData.forEach((item, index) => {
      isProvinceAvailable = get(item, 'Province', '');
      isRegionAvailable = get(item, 'Region', '');
      isDistrictAvailable = get(item, 'District', '');
      provinceData.push(isProvinceAvailable);
    });
    this.setState({
      provinceDataVal: uniq(provinceData)
    });
    zipCodeJsonData.forEach((item, index) => {
      isProvinceAvailable = get(item, 'zipcode', '');
      isRegionAvailable = get(item, 'subDistrict', '');
      isDistrictAvailable = get(item, 'district', '');
      zipCodeData.push(isProvinceAvailable);
    });
    this.setState({
      zipCodeVale: uniq(zipCodeData)
    });
  };
  validateError = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  componentDidUpdate = (previousProps, previousState) => {
    if (this.props.alertType === 'success' && this.state.done === null) {
      this.setState({
        done: true,
        message: this.props.alertMessage
      });
    } else {
      console.log('render error');
    }
  };
  callback = (key) => {
    console.log(key);
  };
  handleClick = () => {
    // props.history.push('/terms');
  };
  genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );
  getZipCodeData = (code, data) => {
    this.setState({
      getZipCode: data.filter((item) => item.zipcode == code)
    });
    data.filter((item) => item.zipcode === code);
  };
  getDistrictData = (province, data) => {
    this.setState({
      getDistrict: data.filter((item) => item.Province === province)
    });
    data.filter((item) => item.Province === province);
  };
  getRegionData = (district, data) => {
    this.setState({
      getRegion: data.filter((item) => item.District === district)
    });
  };
  onSearch = (val) => {
    console.log('val', val);
  };
  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
    const provinceJsonData = jsonData;
    const { autoCompleteResult } = this.state;

    let districtValues = [];
    let regionValues = [];
    let isDistrictAvailable = '';
    let isRegionAvailable = '';
    if (this.state.getDistrict) {
      this.state.getDistrict.forEach((item, index) => {
        isRegionAvailable = get(item, 'Region', '');
        isDistrictAvailable = get(item, 'District', '');
        districtValues.push(isDistrictAvailable);
      });
    }
    let provinceValuesCode = [];
    let districtValuesCode = [];
    let subDistrictCode = [];
    let isDistrictAvailableCode = '';
    let isProvinceAvailableCode = '';
    let isSubDistrictAvailable = '';
    let uniqDistrict = [];
    let uniqProvince = [];
    if (this.state.getZipCode) {
      this.state.getZipCode.forEach((item, index) => {
        isProvinceAvailableCode = get(item, 'province', '');
        isDistrictAvailableCode = get(item, 'district', '');
        isSubDistrictAvailable = get(item, 'subdistrict', '');
        provinceValuesCode.push(isProvinceAvailableCode);
        districtValuesCode.push(isDistrictAvailableCode);
        subDistrictCode.push(isSubDistrictAvailable);
      });
    }
    if (districtValuesCode) {
      uniqDistrict = districtValuesCode.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    }
    if (provinceValuesCode) {
      uniqProvince = provinceValuesCode.reduce(
        (unique, item) => (unique.includes(item) ? unique : [...unique, item]),
        []
      );
    }

    if (this.state.getRegion) {
      this.state.getRegion.forEach((item, index) => {
        isRegionAvailable = get(item, 'Region', '');
        // isDistrictAvailable = get(item, 'District', '');
        regionValues.push(isRegionAvailable);
      });
    }

    const zipCodeOption = this.state.zipCodeVale.map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const provinceOption = uniq(provinceValuesCode).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const districtOption = uniq(districtValuesCode).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const subDistrictOption = uniq(subDistrictCode).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const cropOption = cropValues.map((crop, index) => {
      return (
        <Option key={index} value={crop.value}>
          {crop.key}
        </Option>
      );
    });
    const landOption = landValues.map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });

    const activityTypeOption = activityTypes.map((value, index) => {
      return (
        <Option key={value.key} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const loginPageImage = {
      synLogo: require('../assets/img/syn-logo.png')
    };
    return (
      <div
        style={{ width: '100%', height: 'auto', padding: '24px', backgroundColor: '#2B9C92' }}
        className=''>
        {!this.state.done ? (
          <Form
            layout='vertical'
            // onChange={this.validateError}
            onSubmit={this.handleSubmit}
            style={{
              height: 'auto',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
            className='addGrower'>
            <div style={{ display: 'flex' }}>
             <div class='avatarImageTH'>
              <img
                src={loginPageImage.synLogo}
                alt='Avatar'
                width='80vh'
                height='100px'
                style={{ height: '75px' }}
              />
             </div>
             <div
              className='Title'
              style={{
                fontWeight: '600',
                fontSize: '1.2em',
                color: '#14151C',
                // paddingLeft: '30px',
                position: 'relative',
                top: '2px',
                left:'-32px'
              }}>
              ข้อมูลเกษตรกร
             </div>  
            </div>
            <Form.Item>พนักงานซินเจนทา {this.state.formData.source ? this.state.formData.source : ''}</Form.Item>

            <Form.Item label={<p className='loginFormLabel'>ชื่อ</p>}>
              {getFieldDecorator('name', {
                initialValue: this.state.formData.Name || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `กรุณากรอกข้อมูลให้ถูกต้อง`, whitespace: true },
                  {
                    validator: this.validatorString
                  }
                ]
              })(
                <Input
                  
                  type='text'
                  // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  
                />
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>นามสกุล</p>}>
              {getFieldDecorator('surname', {
                initialValue: this.state.formData.Surname || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `กรุณากรอกข้อมูลให้ถูกต้อง`, whitespace: true },
                  {
                    validator: this.validatorString
                  }
                ]
              })(<Input 
              // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
               />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>เบอร์โทรศัพท์</p>}>
              {getFieldDecorator('mobile', {
                initialValue: this.state.formData.MobileNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: 'โปรดกรอกข้อมูลค่ะ'
                    // whitespace: true
                  },
                  
                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                 
                  // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  maxLength={10}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 32) &&
                    e.preventDefault()
                  }
                
                />
              )}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>บ้านเลขที่</p>}>
              {getFieldDecorator('houseNo', {
                initialValue: this.state.formData.Surname || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: false, message: `กรุณากรอกข้อมูลให้ถูกต้อง`, whitespace: true }
                 
                ]
              })(<Input 
              // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง' 
              />)}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>ชื่อหมู่บ้าน</p>}>
              {getFieldDecorator('villageName', {
                initialValue: this.state.formData.Surname || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: false, message: `กรุณากรอกข้อมูลให้ถูกต้อง`, whitespace: true },
                  {
                    validator: this.validatorString
                  }
                ]
              })(<Input 
              // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
              />)}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>รหัสไปรษณีย์</p>}>
              {getFieldDecorator('zipCode', {
                initialValue: this.state.formData.zipCode || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'โปรดกรอกข้อมูลค่ะ' }
                  
                ]
              })(
               
                <Select
                 
                  // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  showSearch
                  
                  onSelect={(p) => this.getZipCodeData(p, newJsonData)}>
                  {zipCodeOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>จังหวัด</p>}>
              {getFieldDecorator('province', {
                initialValue: uniqProvince[0] || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'โปรดกรอกข้อมูลค่ะ' }
                 
                ]
              })(
                <Select
                // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  showSearch
                  disabled={!this.state.getZipCode}
                 
                >
                  {provinceOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>อำเภอ</p>}>
              {getFieldDecorator('district', {
                initialValue: uniqDistrict[0] || [],
                validateTrigger: ['onChange', 'onBlur'],
                
                rules: [
                  { required: true, message: 'โปรดกรอกข้อมูลค่ะ' }
                  
                ]
              })(
                <Select
                 
                  value={uniqDistrict[0]}
                  // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  showSearch
                  
                  disabled={!this.state.getZipCode}>
                  {districtOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>ตำบล</p>}>
              {getFieldDecorator('subDistrict', {
                initialValue: this.state.formData.subDistrict || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'โปรดกรอกข้อมูลค่ะ' }
                
                ]
              })(
                <Select
                  
                  // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                  showSearch
                  
                  disabled={!this.state.getZipCode}>
                  {subDistrictOption}
                </Select>
              )}
            </Form.Item>
            
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>พืชที่ท่านปลูก</p>} placeholder='select'>
              {getFieldDecorator('crop', {
                initialValue: 'Corn' || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'โปรดกรอกข้อมูลค่ะ' }]
              })(
                <Select  
                // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง' 
                showSearch>
                  {cropOption}
                </Select>
              )}
            </Form.Item>
          
            <Form.Item label={<p className='loginFormLabel'>จำนวนไร่ที่ท่านปลูก</p>}>
              {getFieldDecorator('acre', {
                initialValue: this.state.formData.Acre || '0',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'โปรดกรอกข้อมูลค่ะ' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(<Input type='number' 
              // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง' 
              />)}
            </Form.Item>
         

        
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>ประเภทกิจกรรม (สำหรับพนักงานซินเจนทา)</p>} placeholder='select'>
              {getFieldDecorator('activitiesType', {
                initialValue: this.state.formData.activitiesType || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'โปรดกรอกข้อมูลค่ะ' }]
              })(
                <Select 
                // placeholder='กรุณากรอกข้อมูลให้ถูกต้อง'
                 showSearch>
                  {activityTypeOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item  style={{ marginTop: '10px !important', fontSize:'14px' }}>
              <Checkbox
                onChange={this.onChange}
                disabled={
                  this.validateError(getFieldsError()) ||
                  !this.state.getZipCode ||
                  !this.props.form.isFieldsTouched(false)
                 
                }>
                <p className='loginFormLabel'>ยิมยอมให้เก็บและใช้ข้อมูลตามเงื่อนไขของบริษัทฯ</p>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <a onClick={this.showModal} style={{color:'#1890ff'}}>คลิกเพื่อดูข้อมูลเงื่อนไขของบริษัทฯ</a>
              
            </Form.Item>
            <Form.Item label='Source' hidden={true}>
              {getFieldDecorator('source', {
                initialValue: this.state.formData.source || 'direct grower'
              })(<Input />)}
            </Form.Item>
            <Form.Item
              style={{
                marginTop: '10px !important'
                
              }}>
              <Button
                htmlType='submit'
                className='buttonStyle'
                onClick={this.handleSubmit}
                disabled={this.state.checked || this.state.disabled}>
                ยืนยันข้อมูลของท่าน
              </Button>
              
            </Form.Item>
          </Form>
        ) : (
          <Result
            status='success'
            title='ลงทะเบียนข้อมูลสำเร็จ'
           
          />
        )}
        <Modal
          title=''
          visible={this.state.visible}
          onOk={this.handleOk}
          closable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button type='primary' onClick={this.handleOk}>
              ตกลง
            </Button>
          ]}>
          <p>
            ในการให้ข้อมูลข้างต้น
            ผู้เก็บรวมรวมข้อมูลได้แจ้งให้เจ้าของข้อมูลส่วนบุคคลรับทราบถึงวัตถุประสงค์การเก็บรวมรวมข้อมูล
            และเจ้าของข้อมูลยินยอมให้บริษัท ซินเจนทา ครอป โปรเทคชั่น จำกัด และ/หรือ บริษัท ซินเจนทา
            ซีดส์ (ประเทศไทย) จำกัด เก็บรวบรวม ใช้
            และเปิดเผยข้อมูลส่วนบุคคลของตนเพื่อการวางแผนและทำการตลาดสำหรับผลิตภัณฑ์ซินเจนทา
          </p>
        </Modal>

        {this.props.alertType === 'error'
          ? this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null
          : ''}
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchAddGrowers: (growers) => dispatch(fetchAddGrowers(growers))
  };
};
const mapStateToProps = function(state) {
  return {
    growerList: get(state, 'addedGrowerList.data.growerSucess', []),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(AddGrowerTH);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
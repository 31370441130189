import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchSource } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchSource extends React.Component {
  constructor(props) {
    super(props);
    this.childrenSource = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchSource();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, searchSourceMessage } = this.props;
    let sourceData = this.props.groupFilterReducer || [];
    let sourceText = sourceData.length > 0 ? sourceData[0].items : [];
   
    this.childrenSource = sourceText.map((source) => <Option key={source}>{source}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.sourceValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: searchSourceMessage && searchSourceMessage.message }]
        })(
          <Select
            name='salesUnit'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={searchSourceMessage && searchSourceMessage.placeholder}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenSource}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchSource: () => dispatch(fetchSource())
  };
};
const mapStateToProps = function(state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupSource.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchSource);

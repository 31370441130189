// theme.js
export const lightTheme = {
  // Body css
  body: '#fafbfc',
//   text: '#363537',
//   toggleBorder: '#FFF',
//   gradient: 'linear-gradient(#39598A, #79D7ED)',
  // main Header css
  headerBg: '#15563e',
  headerTxt: 'white',
  // sider css
  siderBg: '#238661',
  siderTxt: 'white',
  // Datatabel css
  tableHeaderTxt: '#000000d9',
  tableHeaderBg: '#edeff2'
};

export const darkTheme = {
  // body css
  body: '#fafbfc',
//   text: '#FAFAFA',
//   toggleBorder: '#6B8096',
//   gradient: 'linear-gradient(#091236, #1E215D)',
  // main Header css
  headerBg: '#171b27',
  headerTxt: 'white',
  // sider css
  siderBg: '#171b27',
  siderTxt: 'white',
  // Datatabel css
  tableHeaderTxt: '#000000d9',
  tableHeaderBg: '#edeff2'
};

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button, Form, Input, Col, Divider, Tag, Icon, Tooltip, Switch } from 'antd';
import { growerUpdate } from '../../../actions/growerUpdate';
import { GrowerDetailFormStyle, StyledInput, StyledForm, StyledCard } from './growerDetailStyle';
// import './growerDetailForm.jsx';
class GrowerDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      cropTags: [],
      seedVariety: [],
      productUsed: [],
      brandUsed: [],
      remarkHistory: [],
      remarkTags: [],
      personalInformationTags: [],
      inputVisible: false,
      inputSeedVisible: false,
      inputProductVisible: false,
      inputBrandVisible: false,
      visible: false,
      inputValue: '',
      inputSeedValue: '',
      inputProductValue: '',
      inputBrandValue: '',
      inputCropValue: '',
      enableEdit: true,
      isDisable: false,
      enableTagEdit: false
    };
  }
  handleClose = (removedTag) => {
    const tags = this.state.tags.filter((tag) => tag !== removedTag);

    this.setState({ tags });
  };
  handleSeedClose = (removedTag) => {
    const seedVariety = this.state.seedVariety.filter((tag) => tag !== removedTag);

    this.setState({ seedVariety });
  };
  handleProductClose = (removedTag) => {
    const productUsed = this.state.productUsed.filter((tag) => tag !== removedTag);

    this.setState({ productUsed });
  };

  handleBrandClose = (removedTag) => {
    const brandUsed = this.state.brandUsed.filter((tag) => tag !== removedTag);

    this.setState({ brandUsed });
  };

  handleCropClose = (removedTag) => {
    const cropTags = this.state.cropTags.filter((tag) => tag !== removedTag);
    this.setState({ cropTags });
  };
  handleRemarkClose = (removedTag) => {
    const remarkTags = this.state.remarkTags.filter((tag) => tag !== removedTag);
    this.setState({ remarkTags });
  };
  handlePersonalInformationClose = (removedTag) => {
    const personalInformationTags = this.state.personalInformationTags.filter(
      (tag) => tag !== removedTag
    );
    this.setState({ personalInformationTags });
  };
  showInput = () => {
    this.setState({ inputVisible: true }, () => this.input.focus());
  };
  showSeedInput = () => {
    this.setState({ inputSeedVisible: true }, () => this.input.focus());
  };
  showProductInput = () => {
    this.setState({ inputProductVisible: true }, () => this.input.focus());
  };
  showCropInput = () => {
    this.setState({ inputCropVisible: true }, () => this.input.focus());
  };
  showBrandInput = () => {
    this.setState({ inputBrandVisible: true }, () => this.input.focus());
  };
  handleInputChange = (event) => {
    // const re = /^[A-Za-z][A-Za-z0-9]*$/;

    // if (event.keyCode == 32 && event.target.selectionStart === 1) {
    //   return false;
    // }
    // if (event.keyCode === 32 && !this.value.length) event.preventDefault();
    // if (e.target.value === '' || re.test(e.target.value)) {
    this.setState({ inputValue: event.target.value });

    // }
  };
  handleInputCropChange = (event) => {
    this.setState({ inputCropValue: event.target.value });
  };
  handleInputSeedChange = (event) => {
    this.setState({ inputSeedValue: event.target.value });
  };
  handleInputProductChange = (event) => {
    this.setState({ inputProductValue: event.target.value });
  };
  handleInputBrandChange = (event) => {
    this.setState({ inputBrandValue: event.target.value });
  };
  handleInputConfirm = () => {
    const { inputValue } = this.state;
    let { tags } = this.state;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [...tags, inputValue];
    }
    this.setState({
      tags,
      inputVisible: false,
      inputValue: ''
    });
  };
  handleInputCropConfirm = () => {
    const { inputCropValue } = this.state;
    let { cropTags } = this.state;
    if (inputCropValue && cropTags.indexOf(inputCropValue) === -1) {
      cropTags = [...cropTags, inputCropValue];
    }
    this.setState({
      cropTags,
      inputCropVisible: false,
      inputCropValue: ''
    });
  };
  handleInputSeedConfirm = () => {
    const { inputSeedValue } = this.state;
    let { seedVariety } = this.state;
    if (inputSeedValue && tags.indexOf(inputSeedValue) === -1) {
      seedVariety = [...seedVariety, inputSeedValue];
    }
    this.setState({
      seedVariety,
      inputSeedVisible: false,
      inputSeedValue: ''
    });
  };
  handleInputProductConfirm = () => {
    const { inputProductValue } = this.state;
    let { productUsed } = this.state;
    if (inputProductValue && tags.indexOf(inputProductValue) === -1) {
      productUsed = [...productUsed, inputProductValue];
    }
    this.setState({
      productUsed,
      inputProductVisible: false,
      inputProductValue: ''
    });
  };
  handleInputRemarkConfirm = () => {
    const { inputProductValue } = this.state;
    let { productUsed } = this.state;
    if (inputProductValue && tags.indexOf(inputProductValue) === -1) {
      productUsed = [...productUsed, inputProductValue];
    }
    this.setState({
      productUsed,
      inputProductVisible: false,
      inputProductValue: ''
    });
  };
  handleInputBrandConfirm = () => {
    const { inputBrandValue } = this.state;
    let { brand } = this.state;
    if (inputBrandValue && tags.indexOf(inputBrandValue) === -1) {
      brand = [...brand, inputBrandValue];
    }
    this.setState({
      brand,
      inputBrandVisible: false,
      inputBrandValue: ''
    });
  };

  saveInputRef = (input) => (this.input = input);
  saveInputSeedRef = (input) => (this.input = input);
  saveInputProductRef = (input) => (this.input = input);
  saveInputBrandRef = (input) => (this.input = input);
  saveInputOtherCropsRef = (input) => (this.input = input);
  handleEdit = (e) => {
    this.setState({
      enableEdit: false,
      enableTagEdit: true
    });
  };
  handleSave = () => {
    this.setState({
      enableEdit: true,
      visible: true
    });
  };
  componentWillReceiveProps = (nextProps) => {
    console.log('nextProps', nextProps);
    if (nextProps.Rowdata.tags) {
      this.setState({
        tags: nextProps.Rowdata.tags,
        cropTags: nextProps.Rowdata.cropInfo,
        seedVariety: nextProps.Rowdata.seedVariety,
        productUsed: nextProps.Rowdata.productUsed,
        brandUsed: nextProps.Rowdata.brandUsed,
        remarkHistory: nextProps.Rowdata.remarkHistory,
        remarkTags: nextProps.Rowdata.remarkTags,
        personalInformationTags: nextProps.Rowdata.personalInformationTags
      });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const snippet = {
          title: values.Name && values.Name.length ? values.Name.trim('') : values.Name,
          // description: values.Description.length ? values.Description.trim('') : values.Description,
          description: values.Description,
          categoryId: this.props.categoryId,
          thumbnails: this.state.thumbnailData,
          growerId: this.props.Rowdata.key,
          name: values.Name && values.Name.length ? values.Name.trim('') : values.Name,
          surname: values.surname,
          mobile: values.mobile,
          classification: values.classification,
          address: {
            taluka: values.Taluka && values.Taluka.length ? values.Taluka.trim('') : values.Taluka,
            zone: values.Zone && values.Zone.length ? values.Zone.trim('') : values.Zone,
            zipCode:
              values.zipCode && values.zipCode.length ? values.zipCode.trim('') : values.zipCode,
            area: values.area && values.area.length ? values.area.trim('') : values.area,
            houseNumber:
              values.houseNumber && values.houseNumber.length
                ? values.houseNumber.trim('')
                : values.houseNumber,
            district:
              values.district && values.district.length
                ? values.district.trim('')
                : values.district,
            subDistrict:
              values.subDistrict && values.subDistrict.length
                ? values.subDistrict.trim('')
                : values.subDistrict,
            village:
              values.Village && values.Village.length ? values.Village.trim('') : values.Village,
            region: values.Region && values.Region.length ? values.Region.trim('') : values.Region,
            state: values.state && values.state.length ? values.state.trim('') : values.state,
            city: values.City && values.City.length ? values.City.trim('') : values.City,
            province:
              values.province && values.province.length
                ? values.province.trim('')
                : values.province,
            cultivationArea:
              values.cultivationArea && values.cultivationArea.length
                ? values.cultivationArea.trim('')
                : values.cultivationArea,
            addressLine:
              values.addressLine && values.addressLine.length
                ? values.addressLine.trim('')
                : values.addressLine
          },
          sourcing: {
            salesUnit: values.Unit && values.Unit.length ? values.Unit.trim('') : values.Unit,
            franchise:
              values.franchisee && values.franchisee.length
                ? values.franchisee.trim('')
                : values.franchisee,
            frnCode:
              values.frnCode && values.frnCode.length ? values.frnCode.trim('') : values.frnCode
          },
          cropInfo: {
            current: {
              name: values.Crop && values.Crop.length ? values.Crop.trim('') : values.Crop,
              acres: Number(values.land) || 0
            },
            cultivatedCrops: this.state.cropTags
          },
          additionalFields: {
            'Retailer Name':
              values.retailerName && values.retailerName.length
                ? values.retailerName.trim('')
                : values.retailerName,
            'Retailer Code':
              values.retailerCode && values.retailerCode.length
                ? values.retailerCode.trim('')
                : values.retailerCode,
            MDOHQ: values.mdohq && values.mdohq.length ? values.mdohq.trim('') : values.mdohq,
            TMHQ: values.tmhq && values.tmhq.length ? values.tmhq.trim('') : values.tmhq,
            'Crop 1 Planted':
              values.sytGrowerCorn && values.sytGrowerCorn.length
                ? values.sytGrowerCorn.trim('')
                : values.sytGrowerCorn,
            'Crop 2 Planted':
              values.sytGrowerRice && values.sytGrowerRice.length
                ? values.sytGrowerRice.trim('')
                : values.sytGrowerRice,
            'Hybrid Corn Acreage':
              values.preferredHybridCorn && values.preferredHybridCorn.length
                ? values.preferredHybridCorn.trim('')
                : values.preferredHybridCorn,
            'Hybrid Rice Acreage':
              values.preferredHybridRice && values.preferredHybridRice.length
                ? values.preferredHybridRice.trim('')
                : values.preferredHybridRice,
            'season segmentation':
              values.seasonSegementation && values.seasonSegementation.length
                ? values.seasonSegementation.trim('')
                : values.seasonSegementation,
            TVC: values.TVC && values.TVC.length ? values.TVC.trim('') : values.TVC,
            WA: values.WA && values.WA.length ? values.WA.trim('') : values.WA,
            FB: values.FB && values.FB.length ? values.FB.trim('') : values.FB,
            YB: values.YB && values.YB.length ? values.YB.trim('') : values.YB,
            IG: values.IG && values.IG.length ? values.IG.trim('') : values.IG,
            Language:
              values.language && values.language.length
                ? values.language.trim('')
                : values.language,
            Brand: this.state.brandUsed,
            'First Name':
              values.firstName && values.firstName.length
                ? values.firstName.trim('')
                : values.firstName,
            'Farmer Name':
              values.farmerName && values.farmerName.length
                ? values.farmerName.trim('')
                : values.farmerName,
            'Last Name':
              values.lastName && values.lastName.length
                ? values.lastName.trim('')
                : values.lastName,
            PIC: values.pic && values.pic.length ? values.pic.trim('') : values.pic,
            Stockist:
              values.stockist && values.stockist.length
                ? values.stockist.trim('')
                : values.stockist,
            Channel:
              values.Channel && values.Channel.length ? values.Channel.trim('') : values.Channel,
            'Crop Type':
              values.cropType && values.cropType.length
                ? values.cropType.trim('')
                : values.cropType,
            // Brand:
            //   values.brandUsed && values.brandUsed.length
            //     ? values.brandUsed.trim('')
            //     : values.brandUsed,
            'Customer Name':
              values.customerName && values.customerName.length
                ? values.customerName.trim('')
                : values.customerName,
            'SAP Code':
              values.sapCode && values.sapCode.length ? values.sapCode.trim('') : values.sapCode,
            Remark: values.remark && values.remark.length ? values.remark.trim('') : values.remark,
            Segment:
              values.segment && values.segment.length ? values.segment.trim('') : values.segment,
            'Personal Information':
              values.personalInformation && values.personalInformation.length
                ? values.personalInformation.trim('')
                : values.personalInformation,
            personalInformationHistory: this.state.personalInformationTags,
            latest: {
              'Product Used Last Season':
                values.productUsed && values.productUsed.length
                  ? values.productUsed.trim('')
                  : values.productUsed,
              'Seed Variety':
                values.seedVariety && values.seedVariety.length
                  ? values.seedVariety.trim('')
                  : values.seedVariety,
              'No# of Bags Planted':
                values.noOfBagsPlanted && values.noOfBagsPlanted.length
                  ? values.noOfBagsPlanted.trim('')
                  : values.noOfBagsPlanted
            },
            activitiesType:
              values.activitiesType && values.activitiesType.length
                ? values.activitiesType.trim('')
                : values.activitiesType,
            Email: values.email && values.email.length ? values.email.trim('') : values.email,
            Facebook:
              values.facebook && values.facebook.length
                ? values.facebook.trim('')
                : values.facebook,
            'Home Phone':
              values.homePhone && values.homePhone.length
                ? values.homePhone.trim('')
                : values.homePhone,
            'Home Phone':
              values.homePhone && values.homePhone.length
                ? values.homePhone.trim('')
                : values.homePhone,
            'Total Income':
              values.totalIncome && values.totalIncome.length
                ? values.totalIncome.trim('')
                : values.totalIncome,
            'Zalo Number':
              values.zaloNumber && values.zaloNumber.length
                ? values.zaloNumber.trim('')
                : values.zaloNumber
          },
          tags: this.state.tags
        };

        this.setState({
          visible: false
        });

        this.props.growerUpdate(snippet);
        this.props.resetPage();
        this.props.isModalVisble();
      }
    });
  };

  render() {
    const {
      Rowdata,
      growerFormData,
      tagVisible,
      hideNewTag,
      t,
      seedVarityVisible,
      brandVisible,
      productUsedLastSeasonVisible,
      removeTags
    } = this.props;
    const {
      tags,
      cropTags,
      inputVisible,
      inputCropValue,
      inputCropVisible,
      inputValue,
      cropInfo,
      seedVariety,
      productUsed,
      brandUsed,
      inputSeedVisible,
      inputProductVisible,
      inputBrandVisible,
      inputSeedValue,
      inputProductValue,
      remarkTags,
      personalInformationTags
    } = this.state;

    const { getFieldDecorator } = this.props.form;
    const validateField = (rule, value, callback) => {
      let count = 0;
      let position = String(value).indexOf('.');
      while (position !== -1) {
        count++;
        position = value.indexOf('.', position + 1);
      }
      if (value === '' || value === undefined) {
        callback();
      } else if (isNaN(value) || Math.sign(value) == -1 || count > 1) {
        callback('Please enter a number');
      } else {
        callback();
      }
    };
    return (
      <GrowerDetailFormStyle gutter={16}>
        <StyledForm layout='vertical' className='growerDetail'>
          {/* <Switch defaultChecked /> */}
          <Col span={8} className='colHeight'>
            <StyledCard title={t('Grower Details')} bordered={false} className='growerDetails'>
              {growerFormData &&
                growerFormData.growerDetail &&
                growerFormData.growerDetail.map((item) => (
                  <Form.Item label={item.label} style={item.style}>
                    {getFieldDecorator(item.fieldName, {
                      initialValue: Rowdata[item.initialValue] || '',
                      rules: [
                        { required: item.rules.required, message: item.rules.message },
                        { whitespace: item.rules['whitespace'] || '' },
                        {
                          validator: eval(item.rules['validator']) || ''
                        }
                      ]
                    })(
                      <StyledInput
                        type={item.type}
                        className='growerDetailInput'
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        disabled={item.disable}
                      />
                    )}
                  </Form.Item>
                ))}
            </StyledCard>
            {this.props.country !== 'in' ? (
              <StyledCard title={t('Crop History')} bordered={false} className='cropHistory'>
                {' '}
                <div>
                  {this.props.Rowdata.cropInfo
                    ? this.props.Rowdata.cropInfo.map((tag, index) => {
                        const isLongTag = tag.length > 20;
                        const tagElem = (
                          <Tag
                            key={tag.name}
                            closable={removeTags}
                            onClose={() => this.handleCropClose(tag)}
                            color='#238661'
                            style={{ borderRadius: '12px', marginBottom: '5px' }}>
                            {isLongTag ? `${tag.slice(0, 20)}...` : `${tag.name} ${tag.acres}`}
                          </Tag>
                        );
                        return isLongTag ? (
                          <Tooltip title={tag} key={tag}>
                            {tagElem}
                          </Tooltip>
                        ) : (
                          tagElem
                        );
                      })
                    : ''}
                </div>
              </StyledCard>
            ) : (
              ''
            )}
          </Col>
          <Col>
            <Divider
              type='vertical'
              style={{
                height: this.props.dividerHeight,
                position: 'absolute',
                margin: '0px -6px',
                padding: '1px',
                top: '15px'
              }}
            />
          </Col>
          <Col span={8} className='colHeight'>
            <StyledCard title={t('Address')} bordered={false} className='growerDetails'>
              {growerFormData &&
                growerFormData.growerAddress &&
                growerFormData.growerAddress.map((item) => (
                  <Form.Item label={item.label}>
                    {getFieldDecorator(item.fieldName, {
                      initialValue: Rowdata[item.initialValue] || '',
                      rules: [
                        { required: item.rules.required, message: item.rules.message },
                        { whitespace: item.rules['whitespace'] || '' },
                        {
                          validator: eval(item.rules['validator']) || ''
                        }
                      ]
                    })(
                      <StyledInput
                        type={item.type}
                        disabled={item.disable}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        className='growerDetailInput'
                      />
                    )}
                  </Form.Item>
                ))}
            </StyledCard>
          </Col>
          <Col>
            <Divider
              type='vertical'
              style={{
                height: this.props.dividerHeight,
                position: 'absolute',
                margin: '0px -6px',
                padding: '1px',
                top: '15px'
              }}
            />
          </Col>
          <Col span={8} className='colHeight'>
            <StyledCard
              title={
                this.props.country === 'ph'
                  ? t('Additional Information')
                  : this.props.country === 'in'
                  ? t('Others')
                  : t('Sourcing')
              }
              bordered={false}
              className='growerDetails'>
              {growerFormData &&
                growerFormData.growerSourcing &&
                growerFormData.growerSourcing.map((item) => (
                  <Form.Item label={item.label}>
                    {getFieldDecorator(item.fieldName, {
                      initialValue: Rowdata[item.initialValue] || '',
                      rules: [
                        { required: item.rules.required, message: item.rules.message },
                        { whitespace: item.rules['whitespace'] || '' },
                        {
                          validator: eval(item.rules['validator']) || ''
                        }
                      ]
                    })(
                      <StyledInput
                        type={item.type}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        className='growerDetailInput'
                        disabled={item.disable}
                      />
                    )}
                  </Form.Item>
                ))}
              {this.props.country === 'kr' ? (
                <StyledCard title={t('Remark History')} bordered={false} className='cropHistory'>
                  {' '}
                  <div>
                    {this.props.Rowdata.remarkHistory
                      ? this.props.Rowdata.remarkHistory.map((tag, index) => {
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag.name}
                              closable={false}
                              // onClose={() => this.handleCropClose(tag)}
                              color='#238661'
                              style={{ borderRadius: '12px', marginBottom: '5px' }}>
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag.trim('')}
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })
                      : ''}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}
              {this.props.country === 'kr' ? (
                <StyledCard
                  title={t('Personal Information History')}
                  bordered={false}
                  className='cropHistory'>
                  {' '}
                  <div>
                    {this.props.Rowdata.personalInformationHistory
                      ? this.props.Rowdata.personalInformationHistory.map((tag, index) => {
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag.name}
                              closable={false}
                              // onClose={() => this.handlePersonalInformationClose(tag)}
                              color='#238661'
                              style={{ borderRadius: '12px', marginBottom: '5px' }}>
                              {isLongTag ? `${tag.slice(0, 20)}...` : tag.trim('')}
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })
                      : ''}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}
              {this.props.country === 'ph' ? (
                <StyledCard
                  title={t('Product Used Last Season')}
                  bordered={false}
                  className='cropHistory'>
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      {productUsed.length > 0
                        ? productUsed.map((tag, index) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag
                                key={tag}
                                closable={removeTags}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.handleProductClose(tag);
                                }}
                                style={{ borderRadius: '12px', marginBottom: '10px' }}
                                color='#238661'>
                                {isLongTag ? `${tag.slice(0, 20).trim('')}...` : tag.trim('')}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })
                        : ''}
                    </div>
                    {inputProductVisible && (
                      <Input
                        ref={this.saveInputProductRef}
                        type='text'
                        size='small'
                        style={{ width: 78 }}
                        value={inputProductValue}
                        onChange={this.handleInputProductChange}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        onBlur={this.handleInputProductConfirm}
                        onPressEnter={this.handleInputProductConfirm}
                      />
                    )}
                    {/* {!inputProductVisible && (
                      <Tag
                        onClick={this.showProductInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type='plus' />
                        {t('New Tag')}
                      </Tag>
                    )} */}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}
              {this.props.country === 'ph' ? (
                <StyledCard title={t('Seed Variety')} bordered={false} className='cropHistory'>
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      {seedVariety.length > 0
                        ? seedVariety.map((tag, index) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag
                                key={tag}
                                closable={removeTags}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.handleSeedClose(tag);
                                }}
                                style={{ borderRadius: '12px', marginBottom: '10px' }}
                                color='#238661'>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })
                        : ''}
                    </div>
                    {inputSeedVisible && (
                      <Input
                        ref={this.saveInputSeedRef}
                        type='text'
                        size='small'
                        style={{ width: 78 }}
                        value={inputSeedValue}
                        onChange={this.handleInputSeedChange}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        onBlur={this.handleInputSeedConfirm}
                        onPressEnter={this.handleInputSeedConfirm}
                      />
                    )}
                    {/* {!inputSeedVisible && (
                      <Tag
                        onClick={this.showSeedInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type='plus' />
                        {t('New Tag')}
                      </Tag>
                    )} */}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}

              {this.props.country === 'bd' ? (
                <StyledCard title={t('Brand')} bordered={false} className='cropHistory'>
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      {Array.isArray(brandUsed) && brandUsed.length > 0
                        ? brandUsed.map((tag, index) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag
                                key={tag}
                                closable={removeTags}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.handleBrandClose(tag);
                                }}
                                style={{ borderRadius: '12px', marginBottom: '10px' }}
                                color='#238661'>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })
                        : ''}
                    </div>
                    {inputBrandVisible && (
                      <Input
                        ref={this.saveInputBrandRef}
                        type='text'
                        size='small'
                        style={{ width: 78 }}
                        value={inputBrandValue}
                        onChange={this.handleInputBrandChange}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        onBlur={this.handleInputBrandConfirm}
                        onPressEnter={this.handleInputBrandConfirm}
                      />
                    )}
                    {/* {!inputProductVisible && (
                      <Tag
                        onClick={this.showProductInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type='plus' />
                        {t('New Tag')}
                      </Tag>
                    )} */}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}
              {this.props.country === 'in' ? (
                <StyledCard title={t('Other Crops')} bordered={false} className='cropHistory'>
                  {' '}
                  <div>
                    {/* <div style={{ marginBottom: '10px' }}> */}
                    {this.props.Rowdata.cropInfo
                      ? this.props.Rowdata.cropInfo.map((tag, index) => {
                          const isLongTag = tag.length > 20;
                          const tagElem = (
                            <Tag
                              key={tag.name}
                              closable={false}
                              onClose={() => this.handleCropClose(tag)}
                              color='#238661'
                              style={{ borderRadius: '12px', marginBottom: '5px' }}>
                              {isLongTag ? `${tag.slice(0, 20)}...` : `${tag.name}`}
                            </Tag>
                          );
                          return isLongTag ? (
                            <Tooltip title={tag} key={tag}>
                              {tagElem}
                            </Tooltip>
                          ) : (
                            tagElem
                          );
                        })
                      : ''}
                    {/* </div> */}
                    {/* {inputCropVisible && (
                      <Input
                        ref={this.saveInputOtherCropsRef}
                        type='text'
                        size='small'
                        style={{ width: 78 }}
                        value={inputCropValue}
                        onChange={this.handleInputCropChange}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        onBlur={this.handleInputCropConfirm}
                        onPressEnter={this.handleInputCropConfirm}
                      />
                    )}
                    {!inputCropVisible && (
                      <Tag
                        onClick={this.showCropInput}
                        style={{ background: '#fff', borderStyle: 'dashed' }}>
                        <Icon type='plus' />
                        {t('New Crop')}
                      </Tag>
                    )} */}
                  </div>
                </StyledCard>
              ) : (
                ''
              )}
              {tagVisible ? (
                <StyledCard title={t('Tags')} bordered={false} className='cropHistory'>
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      {tags.length > 0
                        ? tags.map((tag, index) => {
                            const isLongTag = tag.length > 20;
                            const tagElem = (
                              <Tag
                                key={tag}
                                closable={removeTags}
                                onClose={(e) => {
                                  e.preventDefault();
                                  this.handleClose(tag);
                                }}
                                style={{ borderRadius: '12px', marginBottom: '10px' }}
                                color='#238661'>
                                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                              </Tag>
                            );
                            return isLongTag ? (
                              <Tooltip title={tag} key={tag}>
                                {tagElem}
                              </Tooltip>
                            ) : (
                              tagElem
                            );
                          })
                        : ''}
                    </div>
                    {inputVisible && (
                      <Input
                        ref={this.saveInputRef}
                        type='text'
                        size='small'
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onKeyDown={(evt) => {
                          if (evt.keyCode === 32 && evt.target.selectionStart === 0)
                            evt.preventDefault();
                        }}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                      />
                    )}
                    {hideNewTag
                      ? !inputVisible && (
                          <Tag
                            onClick={this.showInput}
                            style={{ background: '#fff', borderStyle: 'dashed' }}>
                            <Icon type='plus' />
                            {t('New Tag')}
                          </Tag>
                        )
                      : ''}
                  </div>
                  {this.props.hideSaveButton ? (
                    <div className={this.props.saveButtonPosition}>
                      <Button htmlType='submit' onClick={this.handleSubmit} className='buttonStyle'>
                        {t('Save')}
                      </Button>
                    </div>
                  ) : (
                    ''
                  )}
                </StyledCard>
              ) : (
                ''
              )}
            </StyledCard>
          </Col>
        </StyledForm>
      </GrowerDetailFormStyle>
    );
  }
}
const GrowerForm = Form.create({ name: 'coordinated' })(GrowerDetailForm);
const mapDispatchToProps = function(dispatch) {
  return {
    growerUpdate: (gorwerData, growerId) => dispatch(growerUpdate(gorwerData, growerId))
  };
};
const mapStateToProps = function(state) {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(GrowerForm);

import {
  FETCH_GROUP_LIST_PENDING,
  FETCH_GROUP_LIST_SUCCESS,
  FETCH_GROUP_LIST_ERROR,
  FETCH_GROUP_LIST_SUCCESS_RESULT
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchGroupListPending() {
  return {
    type: FETCH_GROUP_LIST_PENDING
  };
}
export function fetchGroupListSuccess(groupList) {
  return {
    type: FETCH_GROUP_LIST_SUCCESS,
    payload: groupList
  };
}
export function fetchGroupListSuccessResult(groupListData) {
  return {
    type: FETCH_GROUP_LIST_SUCCESS_RESULT,
    payload: groupListData
  };
}
export function fetchGroupListError(error) {
  return {
    type: FETCH_GROUP_LIST_ERROR,
    payload: error
  };
}
export const fetchGroupList = (pagenum) => {
  const apiUri = formatUrl(config.groupList, pagenum, '', ['-updated', '-created'], '5');
  return (dispatch) => {
    dispatch(fetchGroupListPending());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((grower) => {
        if (grower) dispatch(fetchGroupListSuccess(grower));
      })
      .catch((error) => {
        dispatch(fetchGroupListError(error));
      });
  };
};
export const fetchAllGroupList = (pagenum) => {
  const apiUri = formatUrl(config.groupList, pagenum, ' ', ['-updated', '-created'], '100');
  return (dispatch) => {
    dispatch(fetchGroupListPending());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((grower) => {
        if (grower) {
          dispatch(fetchGroupListSuccessResult(grower));
        } else {
        }
      })
      .catch((error) => {
        dispatch(fetchGroupListError(error));
      });
  };
};

import {
    DELETE_GROUP_PENDING,
    DELETE_GROUP_SUCCESS,
    DELETE_GROUP_FAILURE,
  } from '../constants';
  import { toggleSpinStatus } from '../actions/globalSpinner';
  import { fetchGroupListWithFetchAPI,checkGroupListData } from '../services/groupListService';
  import { formatUrl } from '../services/commonMethods';
  import { handleErrors } from '../services/handleErrors';
  import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
  import config from '../config';

export function deleteGroupPending(Progressstatus) {
  return {
    type: DELETE_GROUP_PENDING,
    payload: Progressstatus
  };
}
export function deleteGroupSuccess(gdata) {
  return {
    type: DELETE_GROUP_SUCCESS,
    payload: gdata
  };
}

export function deleteGroupFailure(gerror) {
  return {
    type: DELETE_GROUP_FAILURE,
    payload: gerror
  };
}

export const deleteGroup = (groupId, pageNumber, dataLength, groupOffset) => {
    const apiUri = formatUrl(config.deleteGroup,groupId);
    let pageCount = 0;
    if(pageNumber > 0 && dataLength.length === 1){
      pageCount = groupOffset - 1
    }
    else if(pageNumber === 0 && dataLength.length === 1){
      pageCount = groupOffset  
    }
    else {
      pageCount = groupOffset
    }
    const checkPageNumber = pageNumber <= 0 ? pageNumber : pageNumber - 1;
    return (dispatch) => {
      dispatch(deleteGroupPending());
      dispatch(toggleSpinStatus());
      fetch(apiUri, {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + config.userToken
        },
        // body: JSON.stringify(groupId)
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
            if (result && result.code === '200') {
              // dispatch(growerUpdatePending(false));
              dispatch(clearAlertState());
              dispatch(toggleSpinStatus());
              dispatch(deleteGroupSuccess(result));
              // const groupList = await checkGroupListData(checkPageNumber);
              //check groupList length === 0 
              // if(groupList.length === 0){
              // groupList = await checkGroupListData(checkPageNumber - 1);
              // }
               dispatch(fetchGroupListWithFetchAPI(pageCount));
             
              dispatch(
                successNotification({
                  type: 'success',
                  message: result.message,
                  toggleAlert: true
                })
              );
              dispatch(clearAlertState());
            } else {
              // dispatch(growerUpdatePending(false));
    
              dispatch(toggleSpinStatus());
    
              dispatch(clearAlertState());
              dispatch(deleteGroupFailure(result));
              dispatch(
                errorNotification({
                  type: 'error',
                  message: result.message,
                  toggleAlert: true
                })
              );
    
              dispatch(clearAlertState());
            }
          })
          .catch((e) => {
            dispatch(toggleSpinStatus());
            dispatch(deleteGroupFailure(e));
    
            dispatch(
              errorNotification({
                type: 'error',
                message: e.message,
                toggleAlert: true
              })
            );
    
            dispatch(clearAlertState());
          });
      };
    };


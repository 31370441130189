import React from 'react';
import { Form, Select } from 'antd';
import { fetchWA } from '../../../services/groupFilterService';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchWA extends React.Component {
  constructor(props) {
    super(props);
    this.childrenTags = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchWA();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    let tagsData = this.props.groupWA || [];
    let tagsText = tagsData.length > 0 ? tagsData[0].items : [];
    this.childrenTags = tagsText.map((tags) => <Option key={tags}>{tags}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.waValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some whatsapp') }]
        })(
          <Select
            name='tags'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some WhatsApp')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenTags}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchWA: () => dispatch(fetchWA())
  };
};
const mapStateToProps = function(state) {
  return {
    groupWA: get(state, 'groupFilterReducer.data.groupWA.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchWA);

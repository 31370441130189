import React from 'react';
import { Form, Select, Spin } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchCropHistory } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchCropHistory extends React.Component {
  constructor(props) {
    super(props);
    this.childresCropHistory = [];
  }
  handleChange = (user, event) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = event;
    this.props.callback(newUsers);
  };
  componentDidMount() {
    this.props.fetchCropHistory();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    let cropHistoryData = this.props.groupCropHistory || [];
    let cropHistoryText = cropHistoryData.length > 0 ? cropHistoryData[0].items : [];
    this.childrenCrop = cropHistoryText.map((region) => <Option key={region}>{region}</Option>);
    return (
      <Form.Item label='' className='growersCrop'>
        {getFieldDecorator(`${this.props.cropHistoryValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message:
                this.props.searchCropHistoryMessage && this.props.searchCropHistoryMessage.message
            }
          ]
        })(
          <Select
            name='cropHistory'
            size='small'
            showArrow={true}
            mode='multiple'
            placeholder={
              this.props.searchCropHistoryMessage && this.props.searchCropHistoryMessage.placeholder
            }
            maxTagCount={5}
            loading={this.props.groupCropHistory.length ? false : true}
            // notFoundContent={this.props.groupCropHistory.length ? <Spin size='small' /> : null}
            onChange={(event) => {
              this.handleChange(this.props.user, event);
            }}>
            {this.childrenCrop}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchCropHistory: () => dispatch(fetchCropHistory())
  };
};
const mapStateToProps = function(state) {
  return {
    groupCropHistory: get(state, 'groupFilterReducer.data.groupCropHistory.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchCropHistory);

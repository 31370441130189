import React from 'react';
import { Layout } from 'antd';
import './usermanage.css';
import ComminSoon from '../ComingSoon/Comingsoon';
/*** Ant Design Components */
// const { Content } = Layout;

export default function UserManage() {
  return (
    <Layout className="usermngLayout">
      <ComminSoon />
    </Layout>
  );
}

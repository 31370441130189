import fetch from 'cross-fetch';
import config from '../config';
import { formatUrl } from './commonMethods';
import {
  fetchGroupListPending,
  fetchGroupListSuccess,
  fetchGroupListError
} from '../actions/groupList';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
export const fetchGroupListWithFetchAPI = (pagenum) => {
  const apiUri = formatUrl(config.groupList, pagenum, '', ['-updated', '-created'], '10');
  return (dispatch) => {
    dispatch(fetchGroupListPending());
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchGroupListSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(fetchGroupListError(error));
      });
  };
};
export const checkGroupListData = (pagenum) => {
  const apiUri = formatUrl(config.groupList, pagenum, '', ['-updated', '-created'], '10');

  return (dispatch) => {
    dispatch(fetchGroupListPending());
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  };
};
export const fetchGroupListSearchResult = (pageNum = 0, name = '') => {
  const apiUri = formatUrl(config.growerListSearch, pageNum, name, ['-updated', '-created'], '10');
  return (dispatch) => {
    if (name === '') {
      dispatch({
        type: 'SEARCH_LIST',
        value: {
          result: [],
          searchText: name
        }
      });
      fetch(apiUri, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + config.userToken
        }
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          dispatch({
            type: 'SEARCH_LIST',
            value: {
              result: result,
              searchText: name
            }
          });
        })
        .catch((e) => {});
    } else {
      fetch(apiUri, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + config.userToken
        }
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          dispatch({
            type: 'SEARCH_LIST',
            value: {
              result: result,
              searchText: name
            }
          });
        })
        .catch((e) => {});
    }
  };
};

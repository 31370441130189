import React from 'react';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
const changeRoute = () => {
  window.location.href = '/';
};
const SocketDisconnected = () => {
  const { t, i18n } = useTranslation();
  return (
    <Result
      status='404'
      title={t('404')}
      subTitle={t('Sorry, the page you visited does not exist.')}
      extra={
        <Button type='primary' onClick={() => changeRoute()}>
          {t('Back Home')}
        </Button>
      }
    />
  );
};
export default SocketDisconnected;

import axios from 'axios';
import {
  SCHEDULE_MESSAGE_UPDATE_PENDING,
  SCHEDULE_MESSAGE_UPDATE_SUCCESS,
  SCHEDULE_MESSAGE_UPDATE_ERROR,
  UPDATE_SCHEDULE_MESSAGE_DRAWER
} from '../constants/index';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { scheduleMessageListService } from './scheduleMessageList';
import { handleErrors } from '../services/handleErrors';
import { formatUrl } from '../services/commonMethods';
import config from '../config';

export const scheduleMessageUpdatePending = (value) => {
  return {
    type: SCHEDULE_MESSAGE_UPDATE_PENDING,
    payload: value
  };
};
export const scheduleMessageUpdateSuccess = (value) => {
  return {
    type: SCHEDULE_MESSAGE_UPDATE_SUCCESS,
    payload: value
  };
};

export const scheduleMessageUpdateFailure = (error) => {
  return {
    type: SCHEDULE_MESSAGE_UPDATE_ERROR,
    payload: error
  };
};

export function toggleScheduleMessageDrawer() {
  return {
    type: UPDATE_SCHEDULE_MESSAGE_DRAWER
  };
}

export const scheduleMessageUpdate = (messageData, userId, messageId, pageNumber) => {
  const checkPageNumber = pageNumber <= 0 ? pageNumber : pageNumber - 1;
  const apiUri = formatUrl(config.updateScheduleMessage, messageId);
  return (dispatch) => {
    dispatch(scheduleMessageUpdatePending());
    dispatch(toggleSpinStatus());
    // dispatch(clearAlertState());
    return (
      fetch(apiUri, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'temp-created-by': userId,
          Authorization: 'Bearer ' + config.userToken
        },
        body: JSON.stringify(messageData)
      })
        // axios
        //   .post(apiUri, formData, {
        //     headers: {
        //       Accept: 'application/json',
        //       'Content-Type': 'multipart/form-data',
        //       'temp-created-by': userId,
        //       Authorization: 'Bearer ' + config.userToken
        //     }
        //   })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          if (result && result.code === '200') {
            dispatch(clearAlertState());
            dispatch(toggleSpinStatus());
            dispatch(toggleScheduleMessageDrawer());
            dispatch(scheduleMessageUpdateSuccess(result));
            // setTimeout(
            //   function() {
            dispatch(scheduleMessageListService(0));
            //   }.bind(),
            //   3000
            // );
            dispatch(
              successNotification({
                type: 'success',
                message: result.message,
                toggleAlert: true
              })
            );
            dispatch(clearAlertState());
          } else {
            dispatch(toggleSpinStatus());
            dispatch(clearAlertState());
            dispatch(toggleScheduleMessageDrawer());
            dispatch(scheduleMessageUpdateFailure(result));
            dispatch(
              errorNotification({
                type: 'error',
                message: result.message,
                toggleAlert: true
              })
            );

            dispatch(clearAlertState());
          }
        })
        .catch((e) => {
          dispatch(toggleSpinStatus());
          //   dispatch(toggleGroupUpdateDrawer());
          dispatch(toggleScheduleMessageDrawer());
          dispatch(scheduleMessageUpdateFailure(e));

          dispatch(
            errorNotification({
              type: 'error',
              message: e.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        })
    );
  };
};

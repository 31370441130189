import {
  UPLOAD_FACEBOOK_POST_PENDING,
  UPLOAD_FACEBOOK_POST_SUCCESS,
  UPLOAD_FACEBOOK_POST_ERROR
} from '../constants';

const initialState = {
  file: {},
  uploadPostStatus: '',
  postUploaded: '',
  progressStatus: 'active',
  progressBarStatus: false,
  processingStatus: {},
  processingError: {},

  completed: false
};

export default function uploadFacebookPost(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FACEBOOK_POST_PENDING:
      return {
        ...state,
        uploadPostStatus: action.payload,
        completed: true
      };
    case UPLOAD_FACEBOOK_POST_SUCCESS:
      return {
        ...state,
        file: action.payload,
        postUploaded: 'facebook',
        completed: false
      };
    case UPLOAD_FACEBOOK_POST_ERROR:
      return {
        ...state,
        file: action.payload,
        postUploaded: 'facebook',
        completed: false
      };

    default:
      return state;
  }
}

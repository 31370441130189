import {
  FETCH_SALESUNIT_PENDING,
  FETCH_SALESUNIT_SUCCESS,
  FETCH_SALESUNIT_ERROR,
  SESSION_TIMEOUT_ERROR
} from '../constants';

const initialState = {
  data: {
    salesUnitResult: {},
    salesUnitError: {},
    visible: false,
    completed: false
  }
};

function salesUnitList(state = initialState, action) {
  switch (action.type) {
    case FETCH_SALESUNIT_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_SALESUNIT_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          salesUnitResult: action.payload,
          completed: false
        }
      });

    case FETCH_SALESUNIT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          salesUnitError: action.payload,
          completed: false
        }
      });
    case SESSION_TIMEOUT_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          visible: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default salesUnitList;

import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import Reports from '../views/Reports/DownloadReport';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Inbox from '../views/Communication/whatsApp';
import Chat from '../views/Communication/Chat';
import Sidebar from '../views/Communication/sideBar';
import Help from '../views/Help/Help';
import { Table, Modal, Button, Tooltip, Popconfirm, Divider, Icon } from 'antd';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'idnLogin',
    loginButtonStyle: 'idnLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    sampleTemplate:
      'https://grower-console-production-assets.s3.ap-southeast-1.amazonaws.com/sample-files/Sample-kr.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      // {
      //   key: 'engagegrowers',
      //   label: t('Engage Growers')
      // },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        itemKey: 'sub1',
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },

      {
        key: 'reports',
        label: t('Reports')
      },
      // {
      //   key: 'users',
      //   label: t('Users')
      // },
      {
        key: 'help',
        label: t('Help')
      }
    ],
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      {
        pathName: '/reports',
        componentName: Reports
      },
      // {
      //   pathName: '/users',
      //   componentName: Users
      // },
      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    advaceSearch: {
      filterData: [
        { key: 'classification', value: t('Classification') },
        { key: 'province', value: t('Province') },
        { key: 'segment', value: t('Segment') },
        { key: 'Crop', value: t('Crop') },
        { key: 'CropHistory', value: t('Crop History') },
        { key: 'city', value: t('City') },
        { key: 'Acres', value: t('Cultivation Area') },
        { key: 'Tags', value: t('Tags') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'OptIn', value: t('OptIn') },
        { key: 'createdDate', value: t('Report') },
      ],
      dropDownMenu: {
        classification: [{ key: 'in', value: t('in') }],
        province: [{ key: 'in', value: t('in') }],
        segment: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        CropHistory: [{ key: 'in', value: t('in') }],
        city: [{ key: 'in', value: t('in') }],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        Tags: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ],
        createdDate: [
          { key: 'to', value: t('to') },
          { key: 'from', value: t('from') }
        ],
        OptIn: [{ key: 'in', value: t('in') }]
      },
      defaulState: [
        { id: 1, field: { name: 'classification' }, operator: { name: 'in' }, value: [] }
      ],
      addNewFilter: 'classification'
    },
    groupDrawer: [
      {
        key: 'allClassification',
        optionValue: 'childrenClassification',
        fieldName: 'GrowersClassification',
        initialValue: 'GrowersClassification',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Classification'),
        handler: 'handleClassificationSelectAll',
        rules: []
      },
      {
        key: 'allSegment',
        optionValue: 'childrenSegment',
        fieldName: 'GrowersSegment',
        initialValue: 'GrowersSegment',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Segment'),
        handler: 'handleSegmentSelectAll',
        rules: []
      },
      {
        key: 'allProvince',
        optionValue: 'childrenProvince',
        fieldName: 'GrowersProvince',
        initialValue: 'GrowersProvince',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Province'),
        handler: 'handleProvinceSelectAll',
        rules: []
      },
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: []
      },

      {
        key: 'allCropHistory',
        optionValue: 'childrenCropHistory',
        fieldName: 'GrowersCropHistory',
        initialValue: 'GrowersCropHistory',
        placeholder: t('Select Some Crop History'),
        handler: 'handleCropHistorySelectAll',
        rules: []
      },
      {
        key: 'allCity',
        optionValue: 'childrenCity',
        fieldName: 'GrowersCity',
        initialValue: 'GrowersCity',
        placeholder: t('Select Some City'),
        handler: 'handleCitySelectAll',
        rules: []
      },
      {
        key: 'allTags',
        optionValue: 'childrenTags',
        fieldName: 'GrowersTags',
        initialValue: 'GrowersTags',
        placeholder: t('Select Some Tags'),
        handler: 'handleTagsSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop Interested'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Cultivation Area'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        },
        {
          label: t('Classification'),
          fieldName: 'classification',
          initialValue: 'classification',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter classification'),
            whitespace: true
          }
        }

        // {
        //   label: t('Land Size (Hectare)'),
        //   fieldName: 'land',
        //   initialValue: 'landSize',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter land size'),
        //     validator: 'validateField',
        //     whitespace: true
        //   }
        // }
      ],
      growerAddress: [
        {
          label: t('Address Line'),
          fieldName: 'addressLine',
          initialValue: 'addressLine',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter address line'),
            whitespace: true
          }
        },
        {
          label: t('City'),
          fieldName: 'City',
          initialValue: 'city',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter city'),
            whitespace: true
          }
        },
        // {
        //   label: t('Cultivation Area'),
        //   fieldName: 'cultivationArea',
        //   initialValue: 'cultivationArea',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter Cultivation Area'),
        //     whitespace: true
        //   }
        // },
        // {
        //   label: t('Cultivation Area'),
        //   fieldName: 'land',
        //   initialValue: 'landSize',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter land size'),
        //     validator: 'validateField',
        //     whitespace: true
        //   }
        // },
        {
          label: t('Province'),
          fieldName: 'province',
          initialValue: 'province',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter province'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Customer Name'),
          fieldName: 'customerName',
          initialValue: 'customerName',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter customer name'),
            whitespace: true
          }
        },
        {
          label: t('SAP Code'),
          fieldName: 'sapCode',
          initialValue: 'sapCode',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter SAP code'),
            whitespace: true
          }
        },
        {
          label: t('Segment'),
          fieldName: 'segment',
          initialValue: 'segment',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter Segment'),
            whitespace: true
          }
        },
        {
          label: t('Remark'),
          fieldName: 'remark',
          initialValue: 'remark',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter remark'),
            whitespace: true
          }
        },
        {
          label: t('Personal Information'),
          fieldName: 'personalInformation',
          initialValue: 'personalInformation',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter Personal Information'),
            whitespace: true
          }
        }
      ]
    },
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('Cultivation Area'),
        dataIndex: 'landsize'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION(Province,City)'),
        dataIndex: 'location'
      },
      {
        title: t('CROP INTERESTED'),
        dataIndex: 'crops'
      },
      {
        title: t('UPDATED ON'),
        dataIndex: 'update'
      }
    ],
    // scheduleMessageTable = [
    //   {
    //     title: t('GROUP'),
    //     dataIndex: 'Group',
    //     render: (text, record) => {
    //       return record.deletedGroup ? (
    //         <div style={{ color: '#D3D3D3' }}>{text}</div>
    //       ) : (
    //         <div>{text}</div>
    //       );
    //     }
    //   },
    //   {
    //     title: t('TYPE'),
    //     dataIndex: 'msgType'
    //   },
    //   {
    //     title: t('MESSAGE'),
    //     dataIndex: 'message'
    //   },
    //   {
    //     title: t('SCHEDULED BY'),
    //     dataIndex: 'Sender'
    //   },
    //   {
    //     title: t('SCHEDULED FOR'),
    //     dataIndex: 'SentOn'
    //   },
    //   {
    //     title: t('PLATFORM'),
    //     dataIndex: 'Platform'
    //   },
    //   {
    //     title: t('ACTION'),
    //     dataIndex: 'action',
    //     //  fixed:'right',
    //     width: '10%',
    //     render: (text, record) => (
    //       <span style={{ display: 'flex', justifyContent: 'center' }}>
    //         {/* <Tooltip title='View'>
    //           <Button
    //             className='viewButton'
    //             type='link'
    //             icon='eye'
    //             onClick={() => this.showModal(record)}
    //           />
    //         </Tooltip>
    //         <Divider type='vertical' style={{ height: 'auto' }} /> */}
    //         <Tooltip title='Edit'>
    //           <Button
    //             className='editButton'
    //             type='link'
    //             icon='edit'
    //             onClick={() => this.showDrawerData(record)}
    //           />
    //         </Tooltip>
    //         <Divider type='vertical' style={{ height: 'auto' }} />
    //         <Popconfirm
    //           className='groupDelete'
    //           placement='left'
    //           title='Are you sure delete this group?'
    //           icon={<Icon type='question-circle-o' style={{ color: 'red' }} />}
    //           onConfirm={() => this.deleteGroupData(record)}
    //           style={{ padding: '12px 16px' }}>
    //           <Tooltip title='Delete'>
    //             <Button className='deleteButton' type='link' icon='delete' />
    //           </Tooltip>
    //         </Popconfirm>
    //       </span>
    //     )
    //   }
    // ],
    replaceTitle: ['0-20 hectares', '21-50 hectares', '51-100 hectares', '> 100 hectares'],
    saveButtonPosition: 'krButtonPosition',
    dividerHeight: '700px',
    removeTags: true,
    searchAreaMessage: {
      message: t('Please select some province'),
      placeholder: t('Select Some Province')
    },
    searchHectaresMessage: {
      message: t('Please enter cultivation area'),
      placeholder: t('Enter Cultivation area')
    },
    searchSourceMessage: {
      message: t('Please select some source'),
      placeholder: t('Select Some Source')
    },
    paiChart: 'krDistributionCard',
    hideUpdateScheduleMessage: false,
    optInOutVisible: true
    // tagVisible: true,
    // hideNewTag: false
  };
};

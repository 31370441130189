import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import './webstore.css';
import WebStoreTitleSection from './WebStoreTitleSection';
import WebstoreDrawer from './webstoreDrawer/webstoreDrawer';
import { agroServiceLists } from '../../actions/webStoreList';
import { toggleWebstoreDrawer } from '../../actions/webstoreMessageSending';
import { fetchGroupListWithFetchAPI } from '../../services/groupListService';
import { alertBox } from '../commonComponents/Notification/alertBox';
import { allGrowersCount } from '../../actions/allGrowerCount';
import { withTranslation } from 'react-i18next';

/*** Ant Design Components */
const { Content } = Layout;
const { Meta } = Card;

class Webstore extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.allGrowersCount();
    this.props.agroServiceLists();
    this.props.fetchGroupListWithFetchAPI(0);
  }

  handleService = (val) => {
    if (val.status === 'Active') {
      this.props.toggleWebstoreDrawer(val);
    } else {
      // alert("This Service will Comming Soon");
      // this.props.toggleWebstoreDrawer(val);
      // let win = window.open(val.path, "_blank");
      // win.focus();
    }
  };
  render() {
    const { agroServiceListsdata, t } = this.props;
    const title = t('GROWERS');
    // const gridStyle = {
    //   width: '30%',
    //   margin: '0',
    //   textAlign: 'center'
    // };

    const itemsdata =
      agroServiceListsdata &&
      agroServiceListsdata.map((item) => {
        return (
          <Col span={6} style={{ height: '315px' }} key={item.description}>
            <Card
              hoverable
              cover={<img style={{ height: '100%' }} alt='example' src={item.icon} />}
              className='WebstoreSingleCard'
              onClick={this.handleService.bind(this, item)}>
              <Meta
                className='singleCardMeta'
                // title={item.name}
                description={item.description}
              />
              {/* <a>Reach {10451} growers</a> */}
            </Card>
          </Col>
        );
      });
    return (
      <Layout className='webLayout'>
        <WebStoreTitleSection
          pagetitle={title}
          {...this.props}
          connetectedUsersCount={this.props.GrowersCount}
        />

        <Content className='webstoreMainContent'>
          <Card title={t('Engage Growers')} className='webstoreMainCard'>
            <Row gutter={16}> {itemsdata}</Row>
          </Card>
          <WebstoreDrawer
            webstoreDrawerVisible={this.props.webstoreDrawerVisible}
            {...this.props}
          />
          {this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null}
        </Content>
      </Layout>
      // <ComminSoon />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    agroServiceLists() {
      dispatch(agroServiceLists());
    },
    toggleWebstoreDrawer: (widgetdata) => dispatch(toggleWebstoreDrawer(widgetdata)),
    fetchGroupListWithFetchAPI: (pagenum) => dispatch(fetchGroupListWithFetchAPI(pagenum)),
    allGrowersCount: () => dispatch(allGrowersCount())
  };
};

const mapStateToProps = function(state) {
  return {
    agroServiceListsdata: _get(state, 'webStoreListReducer.agroServiceLists', []),
    error: _get(state, 'webStoreListReducer.agroServiceListError', ''),
    pending: _get(state, 'webStoreListReducer.agroServiceListPending', true),
    webstoreDrawerVisible: _get(state, 'webstoreMessageSendingReducer.webstoreDrawerVisible', true),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', ''),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', ''),
    GrowersCount: _get(state, 'allGrowersCountReducer.allGrowersCount.growerCount', 0)
  };
};
Webstore.propTypes = {
  data: PropTypes.array,
  GrowersCount: PropTypes.number,
  toggleAlert: PropTypes.bool,
  allGrowersCount: PropTypes.func,
  alertMessage: PropTypes.string,
  alertType: PropTypes.string,
  pending: PropTypes.bool,
  agroServiceListsdata: PropTypes.array,
  fetchGroupListWithFetchAPI: PropTypes.func,
  toggleWebstoreDrawer: PropTypes.func,
  agroServiceLists: PropTypes.func,
  webstoreDrawerVisible: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Webstore));

import React, { Component } from 'react';
import {
  Form,
  Icon,
  Radio,
  Button,
  Modal,
  Upload,
  Input,
  Progress,
  Card,
  Skeleton,
  Switch,
  Typography,
  Col,
  Row,
  message,
  Divider,
  Collapse,
  Select,
  Avatar
} from 'antd';
import PropTypes from 'prop-types';
import { StyledUpload, StyledUploadButton } from './mmsStyle';
import { Translation } from 'react-i18next';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import './mms.css';
const FormItem = Form.Item;
const { Meta } = Card;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
// const Link = Scroll.Link;
const { Text, Paragraph } = Typography;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class SelectMMS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      width: '320',
      fileName: '',
      visible: false,
      imageSize: false,
      imageType: false,
      imageResolution: false,
      imageHeight: '',
      imageWidth: '',
      drawerVisible: false,
      fileList: [],
      uploading: false,
      filevalid: false,
      isUpdated: false,
      footerVisible: false,
      showEmojis: false,
      locationName: '',
      accordionVisible: 'false',
      emoji: [],
      chosenEmoji: [],
      mediaType: 'youtube',
      loading: true,
      title: '',
      chars_left: 140,
      max_chars: 1000,
      snippet: {
        title: {},
        description: {}
      },
      thumbnailData: {}
    };
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };
  handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_chars;
    const charLength = maxChar - charCount;
    this.setState({ chars_left: charLength });
  };
  fileValidation = (rule, val, callback) => {
    const isJPG = val.file.type === 'image/jpeg';
    if (!isJPG) {
      callback('Please select only jpg');
      return;
    }
    if (val.file.size < 300000) {
      callback('Image must smaller than 300KB');
      return;
    }
    callback();
  };

  handleClick = (e) => {
    this.props.clearYoutubeVideoStatus();
    this.setState({
      fileList: []
    });
  };

  handleRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: [],
        filevalid: false,
        imageSize: false,
        imageType: false,
        imageResolution: false,
        previewImage: '',
        previewVideo: ''
      };
    });
  };
  handleUploadChange = (fileList) => {
    if (fileList.length > 1) {
      fileList = [fileList[fileList.length - 1]];
    }
    this.handlePreview(fileList.file);
    this.setState({ ...fileList, loading: false });
  };
  handleChange = (info) => {
    const handleMultiple = info.fileList.filter((file) => {
      return file.size > 300000;
    });
    if (handleMultiple.length > 0) {
      this.props.callback(true);
      this.setState({ imageSize: true });
    } else {
      this.props.callback(false);
      this.setState({ imageSize: false });
    }
    this.setState({
      fileName: info.file.name
    });

    const file = info.file;
    let fileList = [...info.fileList];
    // fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    if (fileList.length > 3) {
      fileList = [fileList[fileList.length - 1]];
    }
    this.setState({ fileList });
  };

  render() {
    const { uploading, fileList, fileName, mediaType } = this.state;
    const {
      thumbnail,
      uploadStatus,
      progress,
      progressStatus,
      categoryId,
      videoId,
      checkStatus,
      processingStatus
    } = this.props;

    const props2 = {
      // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
      onRemove: this.handleRemove,
      onChange: this.handleUploadChange,
      defaultFileList: [...fileList],
      className: 'upload-list-inline',
      multiple: false,
      name: 'file',
      listType: 'picture'
      // progress: {
      //   strokeColor: {
      //     '0%': '#108ee9',
      //     '100%': '#87d068'
      //   },
      //   strokeWidth: 3,
      //   format: (percent) => `${parseFloat(percent.toFixed(2))}%`
      // }
    };
    const previewFileList = [
      {
        uid: '1',
        name: 'xxx.png',
        status: 'done',
        response: 'Server Error 500', // custom error message to show
        url: 'http://www.baidu.com/xxx.png'
      }
    ];
    const props = {
      // listType: 'picture',
      accept: 'image/jpeg',
      className: 'upload-list-inline',
      onRemove: (file) => {
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
          this.props.callbackType(false);
          this.setState({ imageType: false });
        }
        const isLt2Multiple = fileList.filter((file) => {
          return file.size > 300000;
        });
        const isLt2M = file.size < 300000;
        if (!isLt2M) {
          // this.props.callback(false);
          // this.setState({ imageSize: false });
          // file.flag = true;
        }
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },
      onChange: this.handleChange,

      beforeUpload: (file, fileList) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.addEventListener('load', (event) => {
          const _loadedImageUrl = event.target.result;
          const image = document.createElement('img');
          image.src = _loadedImageUrl;
          image.addEventListener('load', () => {
            const { width, height } = image;
            // set image width and height to your state here
            this.setState({
              imageHeight: height,
              imageWidth: width
            });
          });
        });

        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
          this.setState({ imageType: true });
          this.props.callbackType(true);
        } else {
          this.setState({ imageType: false });
        }
        const isLt2Multiple = fileList.filter((file) => {
          return file.size > 300000;
        });

        const isLt2M = file.size < 300000;
        if (!isLt2M) {
          this.props.callback(true);
        } else {
          this.setState({ imageSize: false });
        }

        this.setState((state) => ({
          fileList: [...state.fileList, file]
        }));
        return false;
        // return isJPG && isLt2M;
      },

      multiple: true,
      fileList: fileList
      // accept: 'jpeg'
    };

    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className=''>
          <p>Type your message(maximum 1000 characters)</p>
          <Form.Item>
            {getFieldDecorator('mesageTxtMms', {
              rules: [
                {
                  required: true,
                  // this.state.isEnabled || this.props.messageType === 'WHATSAPP' ? false : true,
                  message: 'Please enter a valid message',
                  whitespace: true
                }
              ]
            })(
              <TextArea
                placeholder='Enter your text'
                size='large'
                maxLength={this.state.max_chars}
                // showCount
                rows={5}
                // onChange={this.handleWordCount}
                // value={this.state.postDescription}
              />
            )}
          </Form.Item>

          {/* <Form.Item>
            {getFieldDecorator('upload', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                { required: true, message: 'Select Media' },
                { filevalid: true, validator: this.fileValidation }
              ]
            })(
              <Dragger {...props} multiple={false} fileList={fileList}>
                <p className='ant-upload-drag-icon'>
                  <Icon type='upload' />
                </p>
                <p className='ant-upload-text'>
                  <Translation>{(t) => <>{t('Drag and drop files here')}</>}</Translation>{' '}
                  <span style={{ color: '#40a9ff', fontWeight: '300' }}>
                    <Translation>{(t) => <>{t('or select files to upload')}</>}</Translation>
                  </span>
                </p>
              </Dragger>
            )}
          </Form.Item> */}
          <Form.Item label='Select Images (maximum 3 images)'>
            {getFieldDecorator('upload', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                { required: false, message: 'Please Select Media' }
                // { validator: this.fileValidation }
              ]
            })(
              <StyledUpload
                {...props}
                fileList={this.state.fileList}
                accept='image/jpeg'
                // maxCount={1}
                // progress={progress}
              >
                <StyledUploadButton
                  className='imageUpload'
                  disabled={this.state.fileList.length == 3 ? true : false}
                  // onClick={(e) => {
                  //   this.setState({
                  //     fileList: []
                  //   });
                  // }}
                  // >
                >
                  <Icon type='upload' /> Click to Upload
                </StyledUploadButton>
              </StyledUpload>
            )}
            {this.state.imageType ? (
              <div style={{ color: 'red' }}>You can only upload JPG file.</div>
            ) : (
              ''
            )}
            {this.state.imageSize ? (
              <div style={{ color: 'red' }}>Image must smaller than 300KB.</div>
            ) : (
              ''
            )}
            {this.state.imageResolution ? (
              <div style={{ color: 'red' }}>Image resolution must be smaller than 1500.</div>
            ) : (
              ''
            )}
          </Form.Item>
        </div>
      </>
    );
  }
}
SelectMMS.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
const SelectMediaForm = Form.create({})(SelectMMS);

export default SelectMediaForm;

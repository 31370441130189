import React, { useState, useEffect } from 'react';
import { Offline, Online } from 'react-detect-offline';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import './Sidebar.css';
import { Avatar, Button, Icon, Input } from 'antd';
import SearchOutlined from '@ant-design/icons';
import { MyProfileSVGIcon } from '../_layouts/drawer/drawerIcons';
// import { Icon, InlineIcon } from '@iconify/react';
import connectionSignalOff from '@iconify-icons/carbon/connection-signal-off';
import facebook_page from '../../assets/img/facebook-page-icon.png';
import SidebarChat from './SidebarChat';
import wifiIcon from '../_layouts/drawer/drawerIcons';
import wifiNotConnected from '../../assets/img/MessageSvg/wifi-notConnected.svg';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getConversationResult, getUnReadMessages } from '../../actions/getConversation';
function Sidebar(props) {
  const [status, setStatus] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMoreData, setHasMoreData] = useState(true);
  useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
  }, []);
  const fetchMoreData = () => {
    setTimeout(() => {
      setPageNumber((pageNumber) => pageNumber + 1);
    }, 1500);
    props.getConversationResult(pageNumber, props.countryCode);
  };
  return (
    <div className='sidebar'>
      <div className='sidebar_header'>
        <Avatar src={facebook_page} />
        {props.countryCode === 'idn' ?
        <h3 className='header'>Syngenta Indonesia</h3>
        : <h3 className='header'>Syngenta Bangladesh</h3>
        }
        {/* <Icon type='user' />
          <MyProfileSVGIcon className='righmenuLink' /> */}
        {/* </Avatar> */}
        {/* <div className='sidebar_headerRight'> */}
          {/* <h3 className='header'>Syngenta Indonesia</h3> */}
          {/* <Button style={{ background: 'none', border: 'none' }}>
            <Icon type='message' />
          </Button>
          <Button style={{ background: 'none', border: 'none' }}>
            <Icon type='more' />
          </Button> */}
          {/* <Button>
            <Icon type='search' />
          </Button> */}
        {/* </div> */}
      </div>
      {/* <div className='sidebar_search'>
        <div className='sidebar_searchContainer'>
          <Input placeholder='Search or start new chat' type='text' />
        </div>
      </div> */}
      {!status ? (
        <div className='connection'>
          <Avatar
            style={{ top: '10px', width: '40px', height: '40px', backgroundColor: '#ffffff' }}>
            <img className='wifi-notConnect' src={wifiNotConnected} />
          </Avatar>
          <div className='connection_Right'>
            <div className='connection_Heading'>Computer not connected</div>
            <div className='connection_subHeading'>
              Make sure your computer has an active <br /> Internet connection.
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <div style={{ overflow: 'auto' }} id='scrollableDivSideBar'>
        <div className='sidebar_chats'>
          {/* <SidebarChat chatClickHandler={props.chatClickHandler} /> */}
          <InfiniteScroll
            dataLength={props.channels.length}
            next={fetchMoreData}
            // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
            // inverse={false} //
            hasMore={props.channels.length < props.totalCount && !props.loading}
            loader={<h4 style={{ color: '#000000' }}>Loading...</h4>}
            scrollableTarget='scrollableDivSideBar'>
            {props.channels.map((room) => (
              <SidebarChat
                key={room.id}
                id={room.id}
                name={room.contact.msisdn}
                conversationResult={{ ...props.channels, ...room }}
                channels={props.channels}
                chatClickHandler={props.chatClickHandler}
                getUser={props.getUser}
                history={props.history}
                count={props.count}
                dateTime={props.dateTime}
                getChatId={props.getChatId}
                conversationId={props.conversationId}
                notificationMessage={props.notificationMessage}
                countryCode={props.countryCode}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = function(dispatch) {
  return {
    getConversationResult: (pageNumber, countryCode) => dispatch(getConversationResult(pageNumber, countryCode)),
    openConversationResult: (id, page) => dispatch(openConversationResult(id, page))
  };
};
// export default OnBoard;
const mapStateToProps = function(state) {
  return {
    conversationResult: _get(state, 'getConversationList.data.conversationList.items', []),
    openConversationList: _get(state, 'openConversationList.data.openConversationList.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

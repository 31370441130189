import 'regenerator-runtime/runtime';
import React, { Component } from 'react';
import { Table, Button, Popconfirm, Row, Col, Icon, Upload, Layout, Card } from 'antd';
import TitleSection from '../commonComponents/TitleSection/titleSection';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { ExcelRenderer } from 'react-excel-renderer';
import XLSX from 'xlsx';
import { EditableFormRow, EditableCell } from './editable';
import { StyledButton, StyledUpload } from './utilityStyle';
import data from './data.json';
import './utility.css';
const { Content } = Layout;
export default class USSD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cols: [],
      rows: [],
      excelData: [],
      errorMessage: null,
      columns: [
        {
          title: 'NAME',
          dataIndex: 'Name',
          editable: false
        },
        {
          title: 'Mobile No.',
          dataIndex: 'Mobile No.',
          editable: false
        },
        {
          title: 'Retailer Name',
          dataIndex: 'Retailer Name',
          editable: false
        },
        {
          title: 'Retailer Code',
          dataIndex: 'Retailer Code',
          editable: false
        },
        {
          title: 'Region',
          dataIndex: 'Region',
          editable: false
        },
        {
          title: 'Area',
          dataIndex: 'Area',
          editable: false
        },
        {
          title: 'Province',
          dataIndex: 'Province',
          editable: false
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          editable: false
        },
        {
          title: 'Crop',
          dataIndex: 'Crop',
          editable: false
        },
        {
          title: 'Line ID',
          dataIndex: 'Line ID',
          editable: false
        },
        {
          title: 'Source',
          dataIndex: 'Source',
          editable: false
        },
        {
          title: 'Consent',
          dataIndex: 'Consent',
          editable: false
        },
        {
          title: 'BPID',
          dataIndex: 'BPID',
          editable: false
        }
      ],
      sheetColumns: [
        {
          title: 'NAME',
          dataIndex: 'Name',
          editable: false
        },
        {
          title: 'Mobile No.',
          dataIndex: 'Mobile No.',
          editable: false
        },
        {
          title: 'Retailer Name',
          dataIndex: 'Retailer Name',
          editable: false
        },
        {
          title: 'Retailer Code',
          dataIndex: 'Retailer Code',
          editable: false
        },
        {
          title: 'Region',
          dataIndex: 'Region',
          editable: false
        },
        {
          title: 'Area',
          dataIndex: 'Area',
          editable: false
        },
        {
          title: 'Province',
          dataIndex: 'Province',
          editable: false
        },
        {
          title: 'Status',
          dataIndex: 'Status',
          editable: false
        },
        {
          title: 'Crop',
          dataIndex: 'Crop',
          editable: false
        },
        {
          title: 'Line ID',
          dataIndex: 'Line ID',
          editable: false
        },
        {
          title: 'Source',
          dataIndex: 'Source',
          editable: false
        },
        {
          title: 'Consent',
          dataIndex: 'Consent',
          editable: false
        },
        {
          title: 'USSD Code',
          dataIndex: 'USSDCode'
        },
        {
          title: 'BPID',
          dataIndex: 'BPID'
        }
      ]
    };
    this.tableRef = React.createRef;
  }

  handleSave = (row) => {
    const newData = [...this.state.rows];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    this.setState({ rows: newData });
  };

  checkFile(file) {
    let errorMessage = '';
    if (!file || !file[0]) {
      return;
    }
    const isExcel =
      file[0].type === 'application/vnd.ms-excel' ||
      file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isExcel) {
      errorMessage = 'You can only upload Excel file!';
    }
    const isLt2M = file[0].size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errorMessage = 'File must be smaller than 2MB!';
    }
    return errorMessage;
  }
  findColumnValue = (headers, row, headerName, defaultValue) => {
    const isHeaderAvailable = headers.find((value) => value.value === headerName);
    return isHeaderAvailable ? row[isHeaderAvailable.index] : defaultValue;
  };

  ExportUSSDExcel = () => {
    const sheetData = Object.assign(this.state.columns, this.state.excelData);
    const ws = XLSX.utils.json_to_sheet(this.state.excelData);
    /* build a workbook */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, 'ussdSheet.xlsx');
  };

  getCropValue = (str) => {
    if (!isEmpty(str)) {
      let cropMap = new Map([
        ['1', 'Rice'],
        ['2', 'Corn'],
        ['3', 'Sugarcane'],
        ['4', 'Durian'],
        ['5', 'Mango'],
        ['6', 'Longan'],
        ['7', 'Leafy veg'],
        ['8', 'Garlic/shallot/onions'],
        ['9', 'Other fruits'],
        ['0', 'Other crops']
      ]);
      if (str.includes('#')) {
        str = str.replace('#', '');
      }

      let arr = str.split('*');
      let key;
      for (let i = 0; i < arr.length; i++) {
        let numLen = arr[i].toString().length;
        //console.log(numLen)
        if (numLen === 1) {
          key = arr[i];
        }
      }
      return cropMap.get(key) ? cropMap.get(key) : 'NOT-CAPTURED';
    }
  };
  fileHandler = (fileList) => {
    let fileObj = fileList;
    if (!fileObj) {
      this.setState({
        errorMessage: 'No file uploaded!'
      });
      return false;
    }
    if (
      !(
        fileObj.type === 'application/vnd.ms-excel' ||
        fileObj.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    ) {
      this.setState({
        errorMessage: 'Unknown file format. Only Excel files are uploaded!'
      });
      return false;
    }
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        let newRows = [];
        let headers = resp.rows[0];
        let tableColumns = this.state.sheetColumns.map((value) => value.title);

        let headerValue = headers
          .map((value, index) => ({
            index: index,
            value: value
          }))
          .filter((value, index) => tableColumns.includes(value.value));
        resp.rows.slice(1).map((row, index) => {
          if (row && row !== 'undefined') {
            let bpidSheetData = this.findColumnValue(headerValue, row, 'BPID', 'N/A');
            const provinceJsonData = data;
            let isProvinceAvailable = '';
            let isRegionAvailable = '';
            let isAreaAvailable = '';
            let isRetailerCodeAvailable = '';
            let isRetailerNameAvailable = '';
            provinceJsonData.forEach((item) => {
              if (bpidSheetData === item.BPID) {
                isProvinceAvailable = get(item, 'Province', 'NOT-CAPTURED');
                isRegionAvailable = get(item, 'Region', 'NOT-CAPTURED');
                isAreaAvailable = get(item, 'Area', '');
                isRetailerCodeAvailable = get(item, 'RetailerCode', '');
                isRetailerNameAvailable = get(item, 'RetailerName', '');
              }
            });
            newRows.push({
              Name: this.findColumnValue(headerValue, row, 'Name', 'NOT-CAPTURED'),
              'Mobile No.': this.findColumnValue(headerValue, row, 'Mobile No.', ''),
              'Retailer Name': isRetailerNameAvailable ? isRetailerNameAvailable : '',
              'Retailer Code': isRetailerCodeAvailable ? isRetailerCodeAvailable : '',
              Region: isRegionAvailable ? isRegionAvailable : 'NOT-CAPTURED',
              Area: isAreaAvailable ? isAreaAvailable : '',
              Province: isProvinceAvailable ? isProvinceAvailable : 'NOT-CAPTURED',
              Status: this.findColumnValue(headerValue, row, 'Status')
                ? this.findColumnValue(headerValue, row, 'Status').slice(0, 3)
                : this.findColumnValue(headerValue, row, 'Status', ''),
              Crop: this.getCropValue(this.findColumnValue(headerValue, row, 'USSD Code', '')),
              'Line ID': this.findColumnValue(headerValue, row, 'Line ID', ''),
              Source: this.findColumnValue(headerValue, row, 'Source', 'USSD'),
              Consent: this.findColumnValue(headerValue, row, 'Consent', ''),
              BPID: this.findColumnValue(headerValue, row, 'BPID', '')
            });
          }
        });
        this.setState({
          excelData: newRows
        });
        if (newRows.length === 0) {
          this.setState({
            errorMessage: 'No data found in file!'
          });
          return false;
        } else {
          this.setState({
            cols: resp.cols,
            rows: newRows,
            errorMessage: null
          });
        }
      }
    });
    return false;
  };

  handleSubmit = async () => {
    console.log('submitting: ', this.state.rows);
  };

  handleDelete = (key) => {
    const rows = [...this.state.rows];
    this.setState({ rows: rows.filter((item) => item.key !== key) });
  };

  render() {
    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell
      }
    };
    const columns = this.state.columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.handleSave
        })
      };
    });
    return (
      <>
        <StyledUpload
          name='file'
          className='utilityUploadButton'
          beforeUpload={this.fileHandler}
          onRemove={() => this.setState({ rows: [] })}
          multiple={false}>
          <StyledButton className='uploadButton'>
            <Icon type='upload' /> Upload File
          </StyledButton>
        </StyledUpload>
        <Button className='utilityDownloadButton' onClick={() => this.ExportUSSDExcel()}>
          <Icon type='download' /> Download
        </Button>
        <div style={{ marginTop: 14 }}>
          {this.state.rows.length ? (
            <Table
              ref={this.tableRef}
              components={components}
              bordered
              rowClassName={() => 'editable-row'}
              dataSource={this.state.rows}
              columns={columns}
            />
          ) : null}
        </div>
      </>
    );
  }
}

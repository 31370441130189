import {
  DOWNLOAD_GROUP_DATA_PENDING,
  DOWNLOAD_GROUP_DATA_SUCESS,
  DOWNLOAD_GROUP_DATA_ERROR
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';

export function downloadGroupDataPending() {
  return {
    type: DOWNLOAD_GROUP_DATA_PENDING
  };
}
export function downloadGroupDataSuccess(sourceList) {
  return {
    type: DOWNLOAD_GROUP_DATA_SUCESS,
    payload: sourceList
  };
}

export function downloadGroupDataError(error) {
  return {
    type: DOWNLOAD_GROUP_DATA_ERROR,
    payload: error
  };
}

export const downloadGroupData = (groupId) => {
  const apiUri = formatUrl(config.downloadGroupData, groupId);
  return (dispatch) => {
    dispatch(downloadGroupDataPending());

    return fetch(apiUri, {
      method: 'GET',
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken,
        'Time-Zone-Offset': new Date().getTimezoneOffset()
      }
      //   body: JSON.stringify(users)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(downloadGroupDataSuccess(result));
      })
      .catch((error) => {
        dispatch(downloadGroupDataError(error));
      });
  };
};

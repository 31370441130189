import {
  WEBSTORE_LIST_PENDING,
  WEBSTORE_LIST_SUCCESS,
  WEBSTORE_LIST_FAILURE,
  WEBSTORE_LIST_LOADING,
  REGION_LIST_PENDING,
  REGION_LIST_SUCCESS,
  REGION_LIST_FAILURE
} from '../constants/index';

const initialState = {
  agroServiceLists: [],
  agroServiceListError: [],
  regionListError: {},
  regionListData: {},
  agroServiceListPending: false,
  loading: false
};

function webStoreList(state = initialState, action) {
  switch (action.type) {
    case WEBSTORE_LIST_PENDING:
      return {
        ...state,
        agroServiceListPending: action.payload
      };
    case WEBSTORE_LIST_SUCCESS:
      return {
        ...state,
        agroServiceLists: action.payload
      };
    case WEBSTORE_LIST_FAILURE:
      return {
        ...state,
        agroServiceListError: action.payload
      };
    case REGION_LIST_PENDING:
      return {
        ...state,
        loading: true
      };
    case REGION_LIST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        regionListData: action.payload,
        loading: false
      });
    case REGION_LIST_FAILURE:
      return {
        ...state,
        regionListError: action.payload,
        loading: false
      };
    case WEBSTORE_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}

export default webStoreList;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tag, Button, Card, Avatar } from 'antd';
import { LikeOutlined, CommentOutlined, ShareAltOutlined } from '@ant-design/icons';

import { Icon, InlineIcon } from '@iconify/react';
import likeOutlined from '@iconify/icons-ant-design/like-outlined';

import PropTypes from 'prop-types';
import get from 'lodash.get';

import { facebookListWithFetchAPI } from '../../actions/uploadFacebookList';

import './socialmedia.css';
const { Meta } = Card;

class FacebookPostList extends Component {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    this.state = {
      selectedRowKeys: [],
      data: this.props.data,
      pagination: {},
      currentPage: 0
      //   prevPageToken: this.props.prevPageToken,
      //   nextPageToken: this.props.nextPageToken
    };
    this.facebookPostList = [];
  }

  handleTableChange(pagination, pageSize) {
    this.setState({
      currentPage: pagination.current
    });
    this.props.facebookListWithFetchAPI(this.props.facebookAuthToken);
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.facebookListWithFetchAPI();
  }
  //   componentWillReceiveProps() {
  //     this.props.facebookListWithFetchAPI(this.props.facebookAuthToken);
  //   }
  render() {
    const { data } = this.props;

    let facebookPostData = [];
    if (this.props.data.length > 0) {
      facebookPostData = this.props.data.map((value, index) => (
        <Card
          key={index}
          type='inner'
          style={{ width: '100%', marginBottom: '10px' }}
          bordered={true}
          className='facebookCard'
          // title='Facebook'
          extra={moment(value.created_time || '').format('lll')}
          footer={<div>foo</div>}
          actions={[
            <div style={{ color: '#1890ff' }}>
              <LikeOutlined className='likeIcon' theme='filled' />
              {value.likes.summary.total_count}
            </div>,
            <div style={{ color: '#238661' }}>
              <CommentOutlined className='commentIcon' />
              {value.comments.summary.total_count}
            </div>,
            <div style={{ color: '#3666c4' }}>
              <ShareAltOutlined className='shareIcon' />
              {value.shares ? value.shares.count : 0}
            </div>
          ]}>
          <p>{value.attachments.data[0].title ? value.attachments.data[0].title : ''}</p>{' '}
          {/* <img src={value.attachments.data[0].media.image.src} /> */}
          <img
            className='facebookPostImages'
            src={value.full_picture}
            style={{ width: '100%', height: '320px' }}
          />
        </Card>
      ));
    }
    return (
      <div> {facebookPostData}</div>

      // <Card
      //   key={index}
      //   type='inner'
      //   style={{ width: '100%' }}
      //   bordered={true}
      //   title='Facebook'
      //   extra={'27-7-2020'}
      //   footer={<div>foo</div>}
      //   // cover={<img alt='example' src='' />}
      //   actions={[
      //     <LikeOutlined type='value' />,
      //     <CommentOutlined />,
      //     <ShareAltOutlined />
      //   ]}>
      //   <p>{value.likes.summary.total_count || 'likes count'}</p>
      //   <p>
      //     <img src={value.picture} />
      //   </p>
      // </Card>
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    facebookListWithFetchAPI: (pagenum, nextPageToken) =>
      dispatch(facebookListWithFetchAPI(pagenum, nextPageToken))
  };
};
const mapStateToProps = (state) => {
  return {
    data: get(state, 'uploadFacebookList.data.uploadPostResult.data.data', [])
    // totalPages: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.totalResults', 0),
    // resultsPerPage: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.resultsPerPage'),
    // nextPageToken: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.nextPageToken'),
    // prevPageToken: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.prevPageToken'),
    // loading: get(state, 'uploadYoutubeList.loading', ''),
    // updateRowClassFlag: get(state, 'uploadFormReducer.rowHighlight', '')
  };
};

FacebookPostList.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number,
  resultsPerPage: PropTypes.number,
  nextPageToken: PropTypes.string,
  prevPageToken: PropTypes.string,
  facebookListWithFetchAPI: PropTypes.func,
  updateRowClassFlag: PropTypes.bool
};
// Datatable.displayName = 'Datatable';
export default connect(mapStateToProps, mapDispatchToProps)(FacebookPostList);

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchDistrict } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchDistrict extends React.Component {
  constructor(props) {
    super(props);
    this.childrenCity = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchDistrict();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { searchCityMessage } = this.props;
    let cityData = this.props.groupFilterReducer || [];
    let cityText = cityData.length > 0 ? cityData[0].items : [];
    this.childrenCity = cityText.map((city) => <Option key={city}>{city}</Option>);
    const { t } = this.props;
    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.districtValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: t('Please select some district')
            }
          ]
        })(
          <Select
            name='city'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some District')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenCity}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchDistrict: () => dispatch(fetchDistrict())
  };
};
const mapStateToProps = function(state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupDistrict.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchDistrict);

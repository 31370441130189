import {
  SCHEDULE_MESSAGE_LIST_PENDING,
  SCHEDULE_MESSAGE_LIST_SUCCESS,
  SCHEDULE_MESSAGE_LIST_ERROR,
  MESSAGE_LIST_LOADING
} from '../constants/index';
import config from '../config';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
export const messageListLoading = (val) => {
  return { type: MESSAGE_LIST_LOADING, payload: val };
};

export const scheduleMessageListPending = () => {
  return {
    type: SCHEDULE_MESSAGE_LIST_PENDING
  };
};
export const scheduleMessageListSuccess = (value) => {
  return {
    type: SCHEDULE_MESSAGE_LIST_SUCCESS,
    payload: value
  };
};

export const scheduleMessageListFailure = (error) => {
  return {
    type: SCHEDULE_MESSAGE_LIST_ERROR,
    payload: error
  };
};

export const scheduleMessageListService = (pagenum) => {
  const apiUri = formatUrl(config.scheduleMessageList, pagenum, '10', 'scheduleDate');
  return (dispatch) => {
    dispatch(scheduleMessageListPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(scheduleMessageListSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
        dispatch(scheduleMessageListFailure(e));
      });
  };
};

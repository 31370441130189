import React, { Component } from 'react';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { Card, Modal } from 'antd';
import Chart from 'react-apexcharts';
import { RegionalMetaContext } from '../../context/region';
class cropPieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      crop: ''
    };
  }

  render() {
    const checkTitle = ['area0to20', 'area21to50', 'area51to100', 'areaGreaterThan100'];
    const idnTitle = ['area0', 'area0to1', 'area1to5', 'areaAbove5'];
    const replaceIdnTitle = ['0 hectares', '0.1-1 hectares', '1-5 hectares', '> 5 hectares'];
    const replaceTitle = ['0-20 acres', '21-50 acres', '51-100 acres', '> 100 acres'];

    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <>
            <Card>
              <div className='donut'>
                <div>{this.props.title}</div>
                <Chart
                  options={{
                    chart: {
                      type: 'pie'
                    },
                    plotOptions: {
                      pie: {}
                    },

                    legend: {
                      position: 'bottom',
                      // fontSize: '11px'
                      horizontalAlign: 'left'
                    },
                    markers: {
                      width: 12,
                      height: 12,
                      strokeWidth: 0,
                      strokeColor: '#fff',
                      fillColors: undefined,
                      radius: 12,
                      customHTML: undefined,
                      onClick: undefined,
                      offsetX: 0,
                      offsetY: 0
                    },

                    formatter: function (val) {
                      return val;
                    },
                    theme: {
                      mode: 'light',
                      palette: 'palette1'
                    },

                    labels:
                      country === 'idn'
                        ? this.props.values.labels.map((element) =>
                            idnTitle.indexOf(element) > -1
                              ? replaceIdnTitle[idnTitle.indexOf(element)]
                              : element
                          )
                        : this.props.values.labels.map((element) =>
                            checkTitle.indexOf(element) > -1
                              ? data.replaceTitle[checkTitle.indexOf(element)]
                              : element
                          )
                  }}
                  // series={this.props.values.series.sort((a, b) => b - a)}
                  series={this.props.values.series}
                  type='pie'
                />
              </div>
            </Card>
          </>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardResult: () => dispatch(fetchDashboardResult())
  };
};

const mapStateToProps = function (state) {
  return {
    growerDistribution: _get(state, 'dashboardList.data.dashboardResult.growerDistribution', {}),
    idnCropPieChart: {
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    }
    // source: _get(state, 'salesUnitList.data.salesUnitResult', {})
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(cropPieChart);

import config from '../config';
import { formatUrl } from './commonMethods';
import {
  messageListingPending,
  messageListingSuccess,
  messageListingFailure
} from '../actions/messageList';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
export const sentMessageListWithFetchAPI = (pagenum) => {
  const apiUri = formatUrl(config.messagingList, pagenum, '10', '-created');

  return (dispatch) => {
    dispatch(messageListingPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(messageListingSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
        dispatch(messageListingFailure(e));
      });
  };
};

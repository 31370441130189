import {
  FETCH_OPTIN_PENDING,
  FETCH_OPTIN_SUCCESS,
  FETCH_OPTIN_UPDATE,
  FETCH_OPTIN_ERROR,
  CLEAR_OPTIN_RESULT,
  GET_OPTIN_STATE
} from '../constants';
const initialState = {
  data: {
    optInResult: {},
    optInError: {},
    users: [],
    completed: false
  }
};

function optInListReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_OPTIN_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_OPTIN_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optInResult: action.payload,
          completed: false
        }
      });
    }

    case CLEAR_OPTIN_RESULT:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optInResult: []
        }
      });

    case FETCH_OPTIN_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          optInError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default optInListReducer;

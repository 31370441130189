import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Layout, Modal, notification } from 'antd';
import { Translation } from 'react-i18next';
import AuthProvider from '../../provider/auth-provider';
import './loginstyle.css';
import growerconsoleLogo from '../../assets/img/grower-console-logo.svg';
import syngentaloginLogo from '../../assets/img/syngenta-logo.svg';

import { SmileOutlined } from '@ant-design/icons';
import { RegionalMetaContext } from '../../context/region';
import { AuthContext } from '../../context/auth-context';
import history from '../../utils/history';
// import Unauthorization from './views/commonComponents/errorboundry/unauthorizationHandle';
const { Footer } = Layout;
class LoginPage extends React.Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentYear: new Date().getFullYear(),
      token: {}
    };
  }
  componentDidMount() {
    if (window.location.pathname !== '/') {
      window.location.pathname = '/';
    }
  }
  openNotification = () => {
    notification.open({
      message: 'Access Denied',
      description: 'Sorry, you are not authorized to access this website.',
      onClick: () => {}
    });
  };
  openGrowerForm = () => {
    history.push('/grower-information-form');
  };
  RequestProfileData = () => {
    let loginRequest = {
      scopes: ['user.read'] // optional Array<string>
    };
    // Silently acquires an access token which is then attached to a request for MS Graph data
    this.props.instance
      .acquireTokenSilent({
        ...loginRequest
      })
      .then((response) => {
        this.setState(response.accessToken);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleLoginClicked = async () => {
    await Providers.globalProvider.login();
    Providers.globalProvider.setState(ProviderState.SignedIn);
    // if (Providers.globalProvider.state === ProviderState.SignedIn) {
    //   let me = await Provider.globalProvider.graph.client.api('/me').get();x
    // }
  };
  render() {
    const auth = this.context;
    const { Context } = this.props;

    const errorKey = localStorage.getItem('adal.error');
    if (errorKey === 'access_denied') {
      this.openNotification();
    }
    const loginPageImage = {
      pklogin: require('../../assets/img/pk-login.png'),
      inlogin: require('../../assets/img/in-login.png'),
      default: require('../../assets/img/pk-login.png'),
      idnLogin: require('../../assets/img/idn-login.png'),
      latamLogin: require('../../assets/img/latam-login.png'),
      thLogin: require('../../assets/img/th-login.png'),
      myLogin: require('../../assets/img/my-login.jpg'),
      phLogin: require('../../assets/img/ph-login.png'),
      bdLogin: require('../../assets/img/bd-login.jpg')
    };
    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          // <AuthContext>
          //   {(value) => (
          <Layout
            className='pkMainDiv'
            style={{ backgroundImage: `url(${loginPageImage[data.loginPageImage]})` }}>
            <Card bordered={false} className='LoginCard'>
              {country === 'pk' ? null : (
                <Card.Grid bordered={false} hoverable={false} className='gridStyle'>
                  <img src={growerconsoleLogo} />
                </Card.Grid>
              )}
              <Card.Grid bordered={false} hoverable={false} className='gridStyle'>
                {/* {country === 'pk' || country === 'in' ? null : (
                    <div
                      style={{
                        padding: '5% 5%',
                        borderRadius: '5px',
                        background: '#ffffff'
                      }}>
                      {' '}
                    </div>
                  )} */}
                <div className={data.loginButtonBackground}>
                  <Button
                    size='large'
                    className={data.loginButtonStyle}
                    onClick={
                      () => auth.onSignIn()
                      // this.handleLoginClicked()

                      // let eventProperties = {
                      //   count: this.props.totalPagesz
                      // };
                    }>
                    {country === 'pk' ? (
                      <Translation>
                        {(t) => <>{t('Log in with Syngenta Credentials')}</>}
                      </Translation>
                    ) : (
                      <Translation>
                        {(t) => <>{t('LOGIN WITH SYNGENTA CREDENTIALS')}</>}
                      </Translation>
                    )}
                  </Button>
                </div>
              </Card.Grid>
              {/* <Card.Grid> */}
              {country === 'idn' 
              || country === 'th' || country === 'bd' || country === 'my' || country === 'ph' ? (
                <Button
                  className='loginFormButton'
                  onClick={() =>
                    history.push({
                      pathname: '/grower-information-form',
                      state: { location: 'abc' }
                    })
                  }>
                  GROWER FORM
                </Button>
              ) : (
                ''
              )}
              {/* </Card.Grid> */}
              {country === 'pk' ? null : (
                <Card.Grid bordered={false} hoverable={false} className='gridStyle'>
                  <img src={syngentaloginLogo} />
                </Card.Grid>
              )}
            </Card>
            {country === 'pk' ? null : (
              <Footer className='Loginfooter'>
                {' '}
                {this.state.currentYear} © Syngenta Pvt. Ltd. Rights Reserved.
              </Footer>
            )}
          </Layout>
          //   )}
          // </AuthContext>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}

// LoginPage.contextType = AuthContext;
export default AuthProvider(LoginPage);

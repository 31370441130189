import React from 'react';
import { Table, Tag, Popconfirm, Button, Divider } from 'antd';
export const dataTable = ({ t }) => {
  return {
    dataTable: [
      {
        title: t('FILE NAME'),
        dataIndex: 'fname',
        render: (text, record) => {
          return record.orignalSheet ? (
            <a
              target='_blank'
              title={t('Download')}
              href={record.orignalSheet}
              style={{ textDecoration: 'underline' }}>
              {' '}
              {text}
            </a>
          ) : (
            <span title='No sheet'>{text}</span>
          );
        }
      },
      {
        title: t('UPLOADED BY'),
        dataIndex: 'uby'
      },
      {
        title: `#${t('GROWERS')}`,
        align: 'center',
        dataIndex: 'growers'
      },
      {
        title: t('SUCCESS'),
        align: 'center',
        dataIndex: 'success'
      },
      {
        title: t('FAILURE'),
        align: 'center',
        dataIndex: 'failure',
        render: (text, record) => {
          return text > 0 ? (
            <a
              target='_blank'
              title={t('Download')}
              href={record.failureSheet}
              style={{ color: 'red', textDecoration: 'underline' }}>
              {text}
            </a>
          ) : (
            <span title='No Failure' style={{ color: 'green' }}>
              {text}
            </span>
          );
        }
      },
      {
        title: t('UPLOADED ON'),
        dataIndex: 'uon'
      },
      {
        title: t('STATUS'),
        dataIndex: 'status',
        key: 'tags',
        render: (status) => (
          <span>
            {status.map((tag) => {
              let color = tag.length > 9 ? '#F6CA46' : '#72B040';
              if (tag === 'failed') {
                color = '#e02020';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
          </span>
        )
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName',
        width:'15%'
      },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy',
        width:'20%'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate',
        width:'15%'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters',
        width:'35%',
      },
      {
        title: t('Action '),
        dataIndex: 'action',
        //  fixed:'right',
        width:'15%',
        render: (text, record) =>
        <span style={{display:'flex', justifyContent:'center'}}>
        <a href="#">View</a>
        <Divider type="vertical" />
        <a href="#">Edit</a>
        <Divider type="vertical" />
          <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.key)}>
            <a href="#">Delete</a>
          </Popconfirm>
          </span>

      }
    ]
  };
};
export const youTubeTable = [
  {
    title: 'Video',
    align: 'center',
    dataIndex: 'url',
    width: '400px',
    render: (title) => {
      const splitTitle = title.split('*');
      return (
        <div className='videoContainer'>
          <img src={splitTitle[0]} />
          <div className='videoTitle'>
            <p className='videoDescription'>{splitTitle[1]}</p>
            <p>{splitTitle[2]}</p>
          </div>
        </div>
      );
    }
  },

  {
    title: 'View',
    align: 'center',
    dataIndex: 'viewCount'
  },
  {
    title: 'Like',
    align: 'center',
    dataIndex: 'likeCount'
  },
  {
    title: 'Comments',
    align: 'center',
    dataIndex: 'commentCount'
  },
  {
    title: 'Published on',
    dataIndex: 'publishedAt'
  }
];

import axios from 'axios';
import config from '../config';
import { uploadGrowerWithFetchAPI } from './uploadGrower';
import AdalConfigService from '../services/adalConfigService';
import {
  createUploadPending,
  createUploadSuccess,
  createUploadFailure,
  createUploadProgress,
  highlightTableRow,
  toggleDrawer
} from '../actions/uploadForm';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { handleErrors } from '../services/handleErrors';
export const uploadWithSchemaService = (formData) => {
  const apiUri = config.uploadFile;
  return (dispatch) => {
    dispatch(createUploadPending(true));
    axios
      .post(apiUri, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + config.userToken
        },
        onUploadProgress: function(progressEvent) {
          let percentCompleted = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader('x-decompressed-content-length');
          if (percentCompleted !== null) {
            percentCompleted = Math.round((progressEvent.loaded * 100) / percentCompleted);
          }
          dispatch(
            createUploadProgress({
              progressStatus: 'success',
              progressBarStatus: true,
              progress: percentCompleted
            })
          );
        }
      })
      .then((response) => {
        if (response && response.data.code === '200') {
          window.amplitude.getInstance().logEvent(`${AdalConfigService.countryName}_Uploads_Count`);
          dispatch(uploadGrowerWithFetchAPI(0));
          dispatch(createUploadPending({ uploadStatus: 'completed' }));

          dispatch(toggleDrawer());
          dispatch(
            createUploadProgress({
              progressStatus: 'success',
              progressBarStatus: false,
              progress: 100
            })
          );
          dispatch(
            createUploadSuccess({
              alert: true,
              res: response
            })
          );

          dispatch(highlightTableRow(true));
          dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: response.data.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          dispatch(
            createUploadProgress({
              progressStatus: 'active',
              progressBarStatus: true,
              progress: 100
            })
          );
          dispatch(createUploadFailure(response));
          dispatch(
            errorNotification({
              type: 'error',
              message: response.data.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);
        
        if (errorResponse.message.indexOf('401') > -1) {
          const response = {
            status: 401
          };
          return handleErrors(response, dispatch);
        }

        dispatch(
          createUploadPending({
            uploadStatus: 'pending',
            res: error
          })
        );
        dispatch(
          createUploadProgress({
            progressStatus: 'exception',
            progressBarStatus: true,
            progress: 100
          })
        );
        dispatch(createUploadFailure({ res: error }));
     
        dispatch( 
          errorNotification({
            type: 'error',
            message: error.response.data.message,
            toggleAlert: true
          })
        );

        dispatch(clearAlertState());
      });
  };
};

import React from 'react';
import { Form, Select } from 'antd';
import { fetchProvince } from '../../../services/groupFilterService';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchProvince extends React.Component {
  constructor(props) {
    super(props);
    this.childrenProvince = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchProvince();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    let provinceData = this.props.groupFilterReducer || [];
    let provinceText = provinceData.length > 0 ? provinceData[0].items : [];
    this.childrenProvince = provinceText.map((province) => (
      <Option key={province}>{province}</Option>
    ));

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.provinceValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some province') }]
        })(
          <Select
            name='Province'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Province')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenProvince}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    fetchProvince: () => dispatch(fetchProvince())
  };
};
const mapStateToProps = function (state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupProvince.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchProvince);

import React from 'react';
import { connect } from 'react-redux';
import { Drawer, Card, Form, Modal } from 'antd';
import PropTypes from 'prop-types';
import GlobalSpinner from '../../commonComponents/globalSpinner/globalSpinner';
import {
  toggleWebstoreDrawer,
  sendAgroServiceMessage
} from '../../../actions/webstoreMessageSending';
import { messageSchedulingService } from '../../../actions/webstoreMessageSending';
import _get from 'lodash.get';
import MsgForm from '../msgForm/msgForm';
import { communicationLists } from '../../../actions/modesOfCommunication';
import { fetchCostEstimator, fetchCostEstimatorClear } from '../../../actions/costEstimator';
import './webstoreDrawer.css';

const { confirm } = Modal;

class WebstoreDrawer extends React.Component {
  state = {
    current: 0,
    costEstimatorData: this.props.costEstimator
  };

  constructor(props) {
    super(props);
    this.DrawerOnClose = this.DrawerOnClose.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
  }
  componentDidMount = () => {
    this.props.communicationLists();
    this.props.fetchCostEstimatorClear();
  };
  DrawerOnClose() {
    this.props.toggleWebstoreDrawer();
    this.props.fetchCostEstimatorClear();
  }
  showDeleteConfirm(propsdata) {
    confirm({
      title: 'Are you sure close this task?',
      content: 'After closing you loose your data',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        propsdata.toggleWebstoreDrawer();
      },
      onCancel() {}
    });
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <Drawer
          className='webstoreDrawer'
          title={t('SEND SYNGENTA SERVICE')}
          placement='right'
          closable={true}
          onClose={this.DrawerOnClose}
          visible={this.props.webstoreDrawerVisible}
          mask={true}
          maskClosable={true}
          destroyOnClose={true}
          width={390}>
          <Card
            cover={
              <img
                alt='example'
                className='servicePreview'
                src={this.props.widgetdata ? this.props.widgetdata.icon : null}
              />
            }
            hoverable={false}
            bordered={false}
            className='WebstoreNewMessgeCard'>
            {/* <p
              style={{
                marginBottom: "1em",
                fontSize: "12px",
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              {this.props.widgetdata ? this.props.widgetdata.name : null}
            </p> */}
            {/* <p style={{ marginBottom: "0em" }}>Reach: 19,134 grower</p> */}
            <MsgForm
              {...this.props}
              sendAgroServiceMessage={this.props.sendAgroServiceMessage}
              messageSchedulingService={this.props.messageSchedulingService}
              groupList={this.props.groupList}
              widgetdata={this.props.widgetdata}
              fetchCostEstimator={this.props.fetchCostEstimator}
              costEstimatorData={this.props.costEstimator}
              costEstimatorError={this.props.costEstimatorError}
              fetchCostEstimatorClear={this.props.fetchCostEstimatorClear}
            />
          </Card>
        </Drawer>
        <GlobalSpinner />
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    toggleWebstoreDrawer: () => dispatch(toggleWebstoreDrawer()),
    sendAgroServiceMessage: (mdata, emailid, widgetId) =>
      dispatch(sendAgroServiceMessage(mdata, emailid, widgetId)),
    messageSchedulingService: () => dispatch(messageSchedulingService()),
    communicationLists: () => dispatch(communicationLists()),
    fetchCostEstimator: (costData) => dispatch(fetchCostEstimator(costData)),
    fetchCostEstimatorClear: () => dispatch(fetchCostEstimatorClear())
  };
};
const mapStateToProps = function(state) {
  return {
    groupList: _get(state, 'groupListReducer.data.groupList.items', {}),
    widgetdata: _get(state, 'webstoreMessageSendingReducer.widgetdata', {}),
    communicationModes: _get(state, 'communicationList.communicationLists.supported_channel', {}),
    costEstimator: _get(state, 'costEstimatorList.data.costEstimatorResult', {}),
    costEstimatorError: _get(state, 'costEstimatorList.data.costEstimatorError.error_msg', {})
  };
};
const WebstoreDrawerApp = Form.create({ name: 'coordinated' })(WebstoreDrawer);
WebstoreDrawer.propTypes = {
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.array,
  webstoreDrawerVisible: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(WebstoreDrawerApp);

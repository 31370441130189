import { TOGGLE_SUCCESS_NOTIFY, TOGGLE_ERROR_NOTIFY, CLEAR_ALERT_STATE } from '../constants';

const initialState = {
  toggleAlert: false,
  Alertmessage: ''
};

function alertMessage(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_ERROR_NOTIFY:
      return {
        alertType: action.payload.type,
        alertMessage: action.payload.message,
        toggleAlert: action.payload.toggleAlert
      };
    case TOGGLE_SUCCESS_NOTIFY:
      return {
        toggleAlert: action.payload.toggleAlert,
        alertType: action.payload.type,
        alertMessage: action.payload.message
      };
    case CLEAR_ALERT_STATE:
      return {
        ...state,
        toggleAlert: false,
        alertType: '',
        alertMessage: ''
      };

    default:
      return state;
  }
}
export default alertMessage;

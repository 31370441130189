import {
  REQUEST_MESSAGE_SEND,
  REQUEST_MESSAGE_SEND_SUCCESS,
  REQUEST_MESSAGE_SEND_FAILURE,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_MESSAGE_TABEL_ROW
} from '../constants';

const initialState = {
  messageData: [],
  messageDrawerVisible: false,
  selectedGroup: '',
  messageStatus: '',
  rowHighlight: false,
  msgSendingStatus: false
};

export default function messageSendingFormReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_MESSAGE_SEND:
      return {
        ...state,
        msgSendingStatus: action.payload
      };
    case REQUEST_MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        messageData: action.payload,
        messageStatus: 'sent'
      };
    case REQUEST_MESSAGE_SEND_FAILURE:
      return {
        ...state,
        messageData: action.payload,
        messageStatus: 'sent'
      };
    case TOGGLE_DRAWER_STATUS:
      return {
        ...state,
        messageDrawerVisible: !state.messageDrawerVisible
      };
    case HIGHLIGHT_MESSAGE_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };
    default:
      return state;
  }
}

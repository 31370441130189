import {
  FETCH_GROUP_LIST_PENDING,
  FETCH_GROUP_LIST_SUCCESS,
  FETCH_GROUP_LIST_ERROR,
  SEARCH_GROUP_LIST,
  FETCH_GROUP_LIST_SUCCESS_RESULT
} from '../constants';

const initialState = {
  data: {
    searchResult: {
      result: [],
      searchText: ''
    },
    groupList: {},
    groupListData: {},
    groupListError: {},
    completed: false
  }
};

function groupList(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUP_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_GROUP_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupList: action.payload,
          completed: false
        }
      });
    case FETCH_GROUP_LIST_SUCCESS_RESULT:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupListData: action.payload,
          completed: false
        }
      });
    case FETCH_GROUP_LIST_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupListError: action.payload,
          completed: false
        }
      });
    case SEARCH_GROUP_LIST:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          searchResult: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default groupList;

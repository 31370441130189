import React from 'react';
import { Form, Input, Select, Button, Modal, Layout, Result, DatePicker } from 'antd';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { fetchAddGrowers } from '../actions/addUpdatesGrowerForm';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
import { uniq } from 'lodash';
import phJsonData from './ph_hirerarchy_form.json';
import SelectDate from './selectBirthday';
import SelectEventDate from './selectEventDate';
import moment from 'moment';
import './addGrowerForm.css';
const { Option } = Select;
const { Footer } = Layout.Footer;

const cropTypeValues = [
  { key: 'Rice', value: 'Rice' },
  { key: 'Corn', value: 'Corn' },
  { key: 'Mango', value: 'Mango' },
  { key: 'Veg', value: 'Veg' },
  { key: 'Potato', value: 'Potato' },
  { key: 'Cabbage', value: 'Cabbage' },
  { key: 'Eggplant', value: 'Eggplant' },
  { key: 'Tomato', value: 'Tomato' },
  { key: 'Onion', value: 'Onion' },
  { key: 'Beans', value: 'Beans' },
  { key: 'Others', value: 'Others' }
];
const touchScreenUser = [
  { key: 'Android', value: 'Android' },
  { key: 'IOS', value: 'IOS' },
  { key: 'Keypad', value: 'Keypad/Anlougue' }
];
const socialMediaUsed = [
  { key: 'Facebook', value: 'Facebook' },
  { key: 'YouTube', value: 'YouTube' },
  { key: 'Tiktok', value: 'Tiktok' },
  { key: 'Gcash', value: 'Gcash' },
  { key: 'Others', value: 'Others' }
];
class AddGrowerPh extends React.Component {
  state = {
    formData: {},
    confirmDirty: false,
    checked: true,
    visible: false,
    done: null,
    message: '',
    isError: false,
    thaiValue: '',
    getProvince: '',
    getDistrict: '',
    getTehshil: '',
    district: '',
    upazila: '',
    disabled:false,
    eventDate: moment().format('MM-DD-YYYY'),
    birthDate: moment('01-01-1978').format('MM-DD-YYYY')
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let growers = {
          mobile: `63${
            values.mobile.indexOf(0) == '0' ? values.mobile.substring(1) : values.mobile
          }`,
          address: {
            region: values.province,
            city: values.municipality,
            district: values.district
          },
          sourcing: {
            salesUnit: values.source
          },
          cropInfo: {
            current: {
              name: values.cropName,
              acres: values.acre
            }
          },
          additionalFields: {
            'Touch screen phone user': values.phoneUser,
            'Social Media/App Used': values.socialMedia,
            'Event Attended': values.eventAttended,
            'Type of Activity': values.typeOfActivity,
            'Date of Event': this.state.eventDate,
            'Product Used Last Season': values.productUsed,
            'Seed Variety': values.seedVariety,
            'Number of Bags Planted': [values.numberOfBagsPlanted],
            'First Name': values.name,
            'Last Name': values.lastName,
            'Date of Birth(mm/dd/yyyy)': this.state.birthDate
          }
        };

        this.props.fetchAddGrowers(growers);
        this.setState({
          disabled: true,
        });
        setTimeout(() => {
          this.setState(() => ({
            disabled: false,
          }));
        }, 10000);
      }
    });
  };
  onChange = (e) => {
    this.setState({ checked: !e.target.checked });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  hideSucess = () => {
    this.setState({
      done: null,
      disabled:false
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
  };

  validateNumber = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  validateMobileNumber = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (value.length > 10 || value.length < 10) {
      callback('Mobile number should be 10 digit only');
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter mobile number');
    } else {
      callback();
    }
  };

  validatorName = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please fill in the correct information');
    } else {
      callback();
    }
  };

  componentDidMount = () => {
    const { location } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    this.setState({
      formData: params
    });
  };
  validateError = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  componentDidUpdate = () => {
    if (this.props.alertType === 'success' && this.state.done === null) {
      this.setState({
        done: true,
        message: this.props.alertMessage
      });
    } else {
      console.log('render error');
    }
  };

  callback = (key) => {
    console.log(key);
  };
  getDistrictData = (value) => {
    const districtValue = phJsonData
      .filter((val) => val.Province === value)
      .map((val) => val.Municipality);
    this.setState({ district: districtValue });
  };
  getUpazile = (value) => {
    const upazilaValue = phJsonData
      .filter((val) => val.Municipality === value)
      .map((val) => val.Barangay);
    this.setState({ upazila: upazilaValue });
  };
  handleChange = (dateString) => {
    const value = moment(dateString).format('YYYY-MM-DD');
    this.setState({
      eventDate: value
    });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const phProvinceJsonData = phJsonData;
    console.log("this.props.toggleAlert", this.props.toggleAlert);
    let provinceData = [];
    let typeOfActivity = [];
    let istypeOfActivityAvailable = '';
    let isProvinceAvailable = '';
    phProvinceJsonData.forEach((item, index) => {
      isProvinceAvailable = get(item, 'Province', '');
      istypeOfActivityAvailable = get(item, 'Activity', '');
      provinceData.push(isProvinceAvailable);
      typeOfActivity.push(istypeOfActivityAvailable);
    });
    const provinceOption = uniq(provinceData).map((value, inx) => {
      return (
        <Option key={inx} value={value}>
          {value}
        </Option>
      );
    });
    const districtOption = uniq(this.state.district).map((value, inx) => {
      return (
        <Option key={inx} value={value}>
          {value}
        </Option>
      );
    });
    const upZilaOption = uniq(this.state.upazila).map((value, inx) => {
      return (
        <Option key={inx} value={value}>
          {value}
        </Option>
      );
    });
    const activityOption = uniq(typeOfActivity).map((value, inx) => {
      return (
        <Option key={inx} value={value}>
          {value}
        </Option>
      );
    });
    const cropTypeOption = uniq(cropTypeValues).map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const touchScreenUserOption = uniq(touchScreenUser).map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const socialMediaUsedOption = uniq(socialMediaUsed).map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const loginPageImage = {
      synLogo: require('../assets/img/syn-logo.png')
    };
    return (
      <div
        style={{ width: '100%', height: 'auto', padding: '24px', backgroundColor: '#2B9C92' }}
        className=''>
        {!this.state.done ? (
          <Form
            layout='vertical'
            // onChange={this.validateError}
            onSubmit={this.handleSubmit}
            style={{
              height: 'auto',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
            className='addGrower'>
            <div style={{ display: 'flex' }}>
              <div
                className='Title'
                style={{
                  fontWeight: '600',
                  fontSize: '1.5em',
                  color: '#14151C',
                  paddingLeft: '30px',
                  position: 'relative',
                  top: '2px'
                }}>
                Grower Information Form
              </div>
              <div class='avatarImageMY'>
                <img
                  src={loginPageImage.synLogo}
                  alt='Avatar'
                  width='80vh'
                  height='100px'
                  style={{ height: '75px' }}
                />
              </div>
            </div>
            <Form.Item label={<p className='loginFormLabel'>First Name</p>}>
              {getFieldDecorator('name', {
                initialValue: this.state.formData.Name || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Please Enter First Name`, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder={'Enter First Name'} type='text' />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Last Name</p>}>
              {getFieldDecorator('lastName', {
                initialValue: this.state.formData.lastName || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Please Enter Last Name`, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder='Enter Last Name' />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Mobile Number</p>}>
              {getFieldDecorator('mobile', {
                initialValue: this.state.formData.MobileNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: 'Please Enter Mobile number'
                    // whitespace: true
                  },

                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                  addonBefore='+63'
                  maxLength={10}
                  placeholder={'Enter Mobile Number'}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 32) &&
                    e.preventDefault()
                  }
                />
              )}
            </Form.Item>

            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Primary Crop</p>}>
              {getFieldDecorator('cropName', {
                initialValue: this.state.formData.cropName || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Crop ' }]
              })(
                <Select placeholder='Select Crop' showSearch>
                  {cropTypeOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Land Size (Hectarage)</p>}>
              {getFieldDecorator('acre', {
                initialValue: this.state.formData.Acre || '0',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'Please Enter Land Size' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(<Input type='text' maxLength={3} pattern={'d{3}'} />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Birthday</p>}>
              {getFieldDecorator('birthday', {
                initialValue: this.state.birthDate || moment(),
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Enter Birthday' }]
              })(
                <SelectDate
                  form={this.props.form}
                  callback={(newDate) => {
                    this.setState({
                      birthDate: newDate
                    });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Province </p>}>
              {getFieldDecorator('province', {
                initialValue: this.state.formData.province || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Province ' }]
              })(
                <Select
                  placeholder='Select Select Province'
                  onSelect={(val) => this.getDistrictData(val)}
                  showSearch>
                  {provinceOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Municipality </p>}>
              {getFieldDecorator('municipality', {
                initialValue: this.state.formData.district || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Municipality ' }]
              })(
                <Select
                  placeholder='Select Select Municipality'
                  onSelect={(val) => this.getUpazile(val)}
                  disabled={this.state.district.length === 0}
                  showSearch>
                  {districtOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Barangay </p>}>
              {getFieldDecorator('district', {
                initialValue: this.state.formData.upazila || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Barangay ' }]
              })(
                <Select
                  placeholder='Select Select Barangay'
                  //  onSelect={(val) => this.getUpazile(val)}
                  disabled={this.state.upazila.length === 0}
                  showSearch>
                  {upZilaOption}
                </Select>
              )}
            </Form.Item>
            {/* <Form.Item label={<p className='loginFormLabel'>City</p>}>
              {getFieldDecorator('city', {
                initialValue: this.state.formData.city || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Please Enter City`, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder='Enter City' />)}
            </Form.Item> */}
            <Form.Item
              label={<p className='loginFormLabel'>Seeds or CP Product Used Last Season</p>}>
              {getFieldDecorator('productUsed', {
                initialValue: this.state.formData.productUsed || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: false,
                    message: `Please Enter Product Used Last Season`,
                    whitespace: true
                  },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder='Enter Product Used Last Season' />)}
            </Form.Item>
            <Form.Item
              label={<p className='loginFormLabel'>Seed Variety or CP Products Currently Used </p>}>
              {getFieldDecorator('seedVariety', {
                initialValue: this.state.formData.seedVariety || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: `Please Enter Seed Variety`, whitespace: true }]
              })(<Input placeholder='Enter Product Seed Variety' />)}
            </Form.Item>

            <Form.Item
              label={<p className='loginFormLabel'>Number of Liters / Kg Used Per Product</p>}>
              {getFieldDecorator('numberOfBagsPlanted', {
                initialValue: this.state.formData.numberOfBagsPlanted || '0',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: false, message: 'Please Enter Number of Bags Planted' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(<Input type='number' />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Touch Screen Phone User</p>}>
              {getFieldDecorator('phoneUser', {
                initialValue: this.state.formData.phoneUser || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Please Select Touch Sreen Phone User' }]
              })(
                <Select mode='multiple' placeholder='Select Touch screen phone user' showSearch>
                  {touchScreenUserOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Social Media App Used </p>}>
              {getFieldDecorator('socialMedia', {
                initialValue: this.state.formData.socialMedia || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Please Select Social Media App Used ' }]
              })(
                <Select mode='multiple' placeholder='Select Social Media App Used' showSearch>
                  {socialMediaUsedOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Event Attended </p>}>
              {getFieldDecorator('eventAttended', {
                initialValue: this.state.formData.eventAttended || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: false, message: `Please Enter Event Attended`, whitespace: true }
                ]
              })(<Input placeholder='Enter Product Event Attended' />)}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Type of Activity </p>}>
              {getFieldDecorator('typeOfActivity', {
                initialValue: this.state.formData.typeOfActivity || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Please Select Type of Activity' }]
              })(
                <Select placeholder='Select Type of Activity' showSearch>
                  {activityOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Date of Event </p>}>
              {getFieldDecorator('dateofEvent', {
                initialValue: this.state.eventDate || moment(),
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: `Please Enter Date of Event` }]
              })(
                <SelectEventDate
                  form={this.props.form}
                  callback={(newDate) => {
                    this.setState({
                      eventDate: newDate
                    });
                  }}
                />
              )}
            </Form.Item>
            <Form.Item>
              <a onClick={this.showModal} style={{ color: '#1890ff' }}>
                Click to view the company's terms and conditions.
              </a>
              <span>
                <br />
                By clicking "Submit", you accept and agree to the terms and conditions.
              </span>
            </Form.Item>
            <Form.Item label='Source' hidden={true}>
              {getFieldDecorator('source', {
                initialValue: this.state.formData.source || 'direct grower'
              })(<Input />)}
            </Form.Item>

            <Form.Item
              style={{
                marginTop: '10px !important'
              }}>
              <Button
                htmlType='submit'
                className='buttonStyle'
                onClick={this.handleSubmit}
                disabled={this.state.disabled}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Result
            status='success'
            title=' Information registration is successful.'
            extra={[
              <Button type='primary' key='console' onClick={this.hideSucess}>
                Add Another Grower
              </Button>
            ]}
          />
        )}
        <Modal
          title='Syngenta PH Privacy Statement'
          visible={this.state.visible}
          onOk={this.handleOk}
          closable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button type='primary' onClick={this.handleOk}>
              agree
            </Button>
          ]}>
          <p>
            <strong>Introduction.</strong> Your privacy is important to us. Syngenta has therefore
            developed this privacy statement (this “Statement” or “Privacy Statement”) to inform you
            how Syngenta collects, stores, uses and processes the personal data and information you
            provide us through our website and other channels such as telephone, email or
            face-to-face.
          </p>
          <p>
            <strong>Effective date.</strong> April 30, 2020
          </p>
          <p>
            <strong>Scope of this Statement.</strong> This Statement covers the personal data and
            information (hereinafter “Personal Information”) we collect through various channels
            such as websites, applications, social media platforms, grower meetings, launches,
            events, raffle programs, and other grower engagement activities.  Personal Information
            is data that identifies you personally, either alone or in combination with other
            information available to us.Examples of Personal Information include your name, address,
            email address, and the information you provide when you use our site to create an
            account or a personal profile or register for an event.
          </p>
          <p>
            <strong>Your consent.</strong> By registering to any of our activities or events, you
            are accepting the practices described in this Statement (including the Cookies Policy
            available on our site). If you do not agree to this Statement or the Cookies Policy,
            please do not sign in on the registration sheet or use this site. We reserve the right
            to make changes to this Statement or the Cookies Policy at any time. Any changes will be
            posted in this Statement or the Cookies Policy and material changes will generally apply
            only to activities and information collected on a going forward basis. We encourage you
            to regularly review this Statement and the Cookies Policy to make sure you are aware of
            any changes and how your information may be used.
          </p>
          <p>
            <strong>What Personal Information do we collect?</strong>
          </p>

          <p>
            Personal Information refers to information that lets us know the specifics of who you
            are. Personal Information is requested when you order a service, register for an
            account, register for an event or a promotion, enter a prize draw or competition or send
            us feedback or queries. For example, this information might include your name, address
            (both billing and registered), email address, industry and product details. Such
            information may be held on our contacts and administration databases either because of
            the work we have done with you or are currently engaged in, or because we believe you
            might be interested in receiving material from us about our business, products,
            programs, and services. This allows us to manage our relationship with you efficiently,
            improve our site and the services which we offer to you and to target items of interest
            we may send to you.{' '}
          </p>
          <p>
            Non-personally identifiable information refers to information that is statistical in
            nature only and that does not, by itself, allow to identify a specific individual or
            entity, and which depends upon the visiting you do on our site. For example, this
            information may include the domain from which you access the internet, the date and time
            you access the site, and the internet address of the website from which you linked
            directly to the site. This information is used in an aggregate form to make the site
            more useful and attractive to its users.{' '}
          </p>
          <p>
            <strong>
              Agreements governing your use of special features and password-protected areas.
            </strong>
          </p>
          <p>
            Our site may include special features and password-protected areas. When you sign up to
            use a special feature or password-protected area, you may be asked to agree to special
            terms governing your use of the special feature or password-protected area. In such
            cases, you will be asked to expressly consent to the special terms, for example, by
            checking a box or clicking on a button marked "I agree." This type of agreement is known
            as a "click-through" agreement. If any of the terms of the click-through agreement are
            different than the terms of this Statement, the terms of the click-through agreement
            will supplement or amend this Statement, but only with respect to the matters governed
            by the "click-through agreement."
          </p>
          <p>
            <strong>Cookies and similar technologies.</strong>
          </p>
          <p>
            We use "cookies" and other web technologies to collect information and support certain
            features of our sites. “Cookies” provides us with information about the ways visitors
            use our sites – which pages they visit in what order, which links they use, and how long
            they stay on each page
          </p>
          <p>We do this to:</p>

          <ul style={{ listStyleType: 'disc' }}>
            <li>analyze and improve the effectiveness of our advertising campaigns; </li>
            <li>
              support and enhance the features and functionality of our sites – for example, to save
              you the trouble of reentering information already in our database or to prompt the
              settings you established on previous visits to our sites; and
            </li>
            <li>
              personalise your experience when you use our sites. Generally, the information we
              collect using these web technologies does not identify you personally.
            </li>
          </ul>

          <p>
            If, however, you have created a user identity, for example, by signing up to use a
            password-protected area on one of our sites, we may link the information we collect
            using web technologies to other information that identifies you personally.
          </p>
          <p>
            If you do not wish to receive cookies, you may set your browser to reject cookies or to
            alert you when a cookie is placed on your computer. You may also delete our cookies as
            soon as you leave a site. Although you are not required to accept our cookies when you
            visit our sites, if you set your browser to reject cookies, you will not be able to use
            all of the features and functionality of our sites. Please see the Cookies Policy on
            this site for more details.
          </p>
          <p>
            <strong>How does Syngenta use your Personal Information? </strong>
          </p>
          <p>Generally, we use the Personal Information we collect:</p>

          <ul style={{ listStyleType: 'disc' }}>
            <li>
              To provide the products, information and services you request from us, including
              agronomy services, e-newsletters, event registration, competition entry, and trials
              seed orders.
            </li>
            <li>
              To contact you with special offers and other information we believe will be of
              interest to you where you have given us permission to do so.
            </li>
            <li>
              Based upon a legitimate interest we have:
              <ul style={{ listStyleType: 'circle', marginLeft: '10px' }}>
                <li>To provide you with effective customer service;</li>
                <li>To provide you with a personalized experience when you use our site; </li>
                <li>To display personalized advertising when you visit our site; </li>
                <li>
                  To contact you with information and notices related to your use of our site;
                </li>
                <li>To invite you to participate in surveys and provide feedback to us; </li>
                <li>To better understand your needs and interests; </li>
                <li>To improve the content, functionality and usability of our site;</li>
                <li>To improve our products and services; and</li>
                <li>To improve our marketing and promotional efforts. </li>
              </ul>
            </li>
            <li>
              For any other purpose identified in an applicable privacy notice or other agreement
              between Syngenta and you.
            </li>
          </ul>

          <p>
            <strong>How does Syngenta share my Personal Information? </strong>
          </p>
          <p>
            <u>Service providers.</u> We share Personal Information with companies and organizations
            that perform services on our behalf, for example, companies that provide support
            services to us (such as credit card processing services, data storage services, event
            registration platforms, third-party personnel, and web hosting services) or that help us
            market our products and services (such as such as third-party e-mail marketing
            companies, customer loyalty and other campaign program providers). These third parties
            are required by contract to use the Personal Information we share with them only to
            perform services on our behalf and to take commercially reasonable measures to protect
            the confidentiality and security of your Personal Information.
          </p>
          <p>
            <u>Within Syngenta.</u> We may share the Personal Information we collect through our
            Site and other channels (such as applications, social media platforms, grower meetings,
            launches, events, raffle programs, and other grower engagement activities) within
            Syngenta or with other affiliates of Syngenta for cross-marketing and other purposes. If
            you do not want us to share your Personal Information with another member of the
            Syngenta family of businesses you may "opt-out" at any time by sending an email or
            writing to our data privacy officer – see the contact details on this page.
          </p>
          <p>
            <u>Business transfers.</u> Your Personal Information may be transferred to a company
            that has acquired the stock or assets of Syngenta, one of its affiliates, or one of our
            businesses, for example, as the result of a sale, merger, reorganization or liquidation.
            If such a transfer occurs, the acquiring company's use of your Personal Information will
            still be subject to this policy and the privacy preferences you have expressed to us.
          </p>
          <p>
            <u>Compliance with laws and protection of our rights and the rights of others.</u>We may
            disclose Personal Information when we, in good faith, believe disclosure is appropriate
            to comply with the law, a court order or a subpoena. We may also disclose Personal
            Information to prevent or investigate a possible crime, such as fraud or identity theft;
            to enforce or apply our online terms of use or other agreements; or to protect our own
            rights or property or the rights, property or safety of our users or others.
          </p>
          <p>
            <u>Aggregate information.</u> We may share aggregated demographic or survey information
            with third parties, but this information is in anonymous form and does not contain any
            Personal Information. The aggregate information that we share may include anonymous
            information that is captured through the use of cookies and other similar tracking
            technology, as explained in the section entitled “cookies and similar technologies.”
          </p>
          <p>
            <u>As described in a privacy notice.</u> We reserve the right to disclose your Personal
            Information as described in any additional privacy notice posted on the web page (or
            area) where you provide that information. By providing your Personal Information on that
            web page you will be consenting to the disclosure of your Personal Information as
            described in that privacy notice.
          </p>
          <p>
            <u>As described in a click-through agreement.</u> We reserve the right to disclose your
            Personal Information as described in any click-through agreement to which you have
            agreed.
          </p>
          <p>
            <strong>Your choices </strong>
          </p>
          <p>
            <u>In general</u>
          </p>
          <p>
            We respect your right to make choices about the ways we collect, use and disclose your
            Personal Information. In most cases, we will ask you to indicate your choices at the
            time we collect your Personal Information. We will also include an "opt-out" link in
            newsletter or promotional email we send you so that you can inform us that you do not
            wish to receive such communications from us in the future. If you have created an
            account or a personal profile on our site, you may be able to update some or all of your
            privacy choices by changing the settings on your account settings or profile page.
          </p>
          <p>
            <u>Direct marketing </u>
            <br />
            Before we use your Personal Information for direct marketing purposes (or permit our
            affiliates to do so), we will offer you the opportunity to choose whether to have your
            information used in this way. If at any time you wish to be taken off our direct
            marketing mailing lists, please send an email to our Data Protection Officer at
            Data.Privacy_PH@syngenta.com. Please provide your full name, address and email address
            so that we can find you on our mailing lists. Once we have the information we need, we
            will remove you from our mailing lists. Please give us a reasonable amount of time to
            honor your request.
          </p>
          <p>
            <u>Sharing your personal information. </u>
            <br />
            Please review the section entitled "how does Syngenta share my Personal Information?" to
            learn how we share your Personal Information with third parties and what choices you
            have.
          </p>
          <p>
            <strong>
              How can you exercise your rights in relation to your Personal Information?
            </strong>
          </p>
          <p>
            We strive to maintain a high level of transparency about the data we process. As regards
            our processing of your Personal Information described in this Statement, you have the
            following rights:
          </p>
          <ul style={{ listStyleType: 'disc' }}>
            <li>
              To confirm Syngenta is processing your Personal Information, to get access to or
              receive a copy of the Personal Information we may have about you;
            </li>
            <li>
              To require us to rectify or update any inaccurate Personal Information, or complete
              any incomplete Personal Information;
            </li>
            <li>To require us to delete or erase your Personal Information;</li>
            <li>To restrict our processing of your Personal Information;</li>
            <li>
              To requireus to receive your Personal Information in a structured, commonly used and
              machine-readable format or to have it transmitted (ported) to another data controller
              (data portability); and
            </li>
            <li>
              To require that we stop processing your Personal Information for direct marketing
              purposes. Subject to other legitimate interests which we may be able to rely on (e.g.
              in the context of legal claims pending or threatened against us), we will then no
              longer be allowed to process your Personal Information
            </li>
          </ul>
          <p>
            If you wish to exercise any of your above rights, you can contact us at no cost by
            sending an email to the Data Protection Officer at{' '}
            <a href='mailto:Data.Privacy_PH@syngenta.com' style={{ color: '#1890ff' }}>
              Data.Privacy_PH@syngenta.com
            </a>
            . We will respond to your reasonably specified request as quickly as possible after
            validating the request.
          </p>
          <p>
            We will without undue delay modify your Personal Information if we agree that it is
            either inaccurate, out of date, incomplete, irrelevant or misleading, after taking into
            account the purposes for which it was collected. If we correct it and had previously
            disclosed it to a third party, we will also notify that third party if required to by
            law.
          </p>
          <p>
            If we disagree with your request to correct your Personal Information, we will explain
            the reason for refusal and the ways you can complain.
          </p>
          <p>
            <strong>How long does Syngenta store your Personal Information? </strong>
          </p>
          <p>
            We intend to keep your Personal Information accurate and up to date. We do not wish to
            store your Personal Information for any longer than necessary for the relevant purposes.
            How long we need to keep your Personal Information depends on the purpose for which we
            collect it, and therefore there is no standard retention period. Thus, the retention
            period may vary depending on the circumstances. For other legal reasons, e.g. in the
            context of legal claims, we may need hold Personal Information beyond the term required
            to meet the defined purpose for which they were collected in the first place. However,
            in these cases we will no longer actively process the Personal Information for the
            originally specified purpose.
          </p>
          <p>
            Should you wish to obtain more information on where and how long your Personal
            Information is stored, and on your right of erasure please contact our Data Protection
            Officer at{' '}
            <a href='mailto:Data.Privacy_PH@syngenta.com' style={{ color: '#1890ff' }}>
              {' '}
              Data.Privacy_PH@syngenta.com
            </a>
            .
          </p>
          <p>
            <strong>How does Syngenta protect Personal Information? </strong>
          </p>
          <p>
            We maintain a comprehensive data security programme, including commercially reasonable
            administrative, physical and technological security measures, to protect Personal
            Information from unauthorized access, unauthorized use, and unauthorized or accidental
            destruction, modification or disclosure. Although we take commercially reasonable
            precautions to provide an appropriate level of security, we cannot guarantee the
            security of Personal Information provided over the internet or stored in our databases.
          </p>
          <p>
            <strong>Links to other Syngenta websites </strong>
          </p>
          <p>
            This Site contains hyperlinks to other websites operated by Syngenta or other Syngenta
            entities ("Other Syngenta Website(s)"). These Other Syngenta Websites are separate and
            distinct websites. Each serves a different purpose. Each Other Syngenta Website is
            therefore governed by its own terms of use and its own privacy statement. If you access
            Other Syngenta Websites through links on this site, please take a few minutes to review
            the terms of use and privacy statement posted on each Other Syngenta Website you visit.
          </p>
          <p>
            <strong>Links to other web sites </strong>
          </p>
          <p>
            This site may contain links to web sites operated by other companies, including websites
            operated by our third-party service providers, our affiliates, and other third parties.
            This Statement does not apply to Personal Information collected on any of these
            third-party web sites. When you access third-party web sites through a link on this
            site, please take a few minutes to review the privacy policy posted on that site.
          </p>
          <p>
            <strong>Children</strong>
          </p>
          <p>
            Our Site is not directed nor targeted to children under the age of 13. We do not use our
            Site to knowingly solicit Personal Information from, or market to, children under the
            age of 13. If you are under the age of 13, do not provide us with any Personal
            Information. If we learn that someone under 13 has provided Personal Information through
            one of our sites, we will use reasonable efforts to remove such Personal Information
            from our databases.
          </p>
          <p>
            <strong>Special notice for visitors who reside outside the Philippines </strong>
          </p>
          <p>
            Syngenta Phils., Inc. uses web servers and stores data in Switzerland, India, or in
            other countries. We may transfer your Personal Information to our service providers and
            others located in the Philippines, Switzerland, India, or other countries for the
            purposes described in this Statement. Different countries have different privacy laws
            and requirements, and some provide less legal protection for your personal information
            than others.
          </p>
          <p>
            Please know, however, that no matter where your Personal Information is collected or
            processed, if it was collected through this Site, we will ensure that it is protected by
            the terms of this Statement and any privacy notices or click-through agreements that
            apply to you.
          </p>
          <p>
            By using this site, you agree to the collection, use, and transfer of your Personal
            Information in or to Switzerland or any other country in the world.
          </p>
          <p>
            <strong>Governing law & Jurisdiction </strong>
          </p>
          <p>
            This Statement is governed under Philippine laws without regard to its conflicts of law
            provisions. The courts in the Philippines shall have exclusive jurisdiction for any
            claims relating to these Statement.
          </p>
          <p>
            <strong>Questions, concerns and complaints concerning our privacy practices </strong>
          </p>
          <p>
            Should you have any question or concern on how Syngenta utilizes your Personal
            Information, you can contact our Data Protection Officer at{' '}
            <a href='mailto:Data.Privacy_PH@syngenta.com' style={{ color: '#1890ff' }}>
              {' '}
              Data.Privacy_PH@syngenta.com.
            </a>{' '}
            You also have a right to lodge a complaint with the relevant supervisory authority.
          </p>
        </Modal>

        { this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null
          }
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchAddGrowers: (growers) => dispatch(fetchAddGrowers(growers))
  };
};
const mapStateToProps = function(state) {
  return {
    growerList: get(state, 'addedGrowerList.data.growerSucess', []),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(AddGrowerPh);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

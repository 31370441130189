import {
  SCHEDULE_MESSAGE_DELETE_PENDING,
  SCHEDULE_MESSAGE_DELETE_SUCCESS,
  SCHEDULE_MESSAGE_DELETE_ERROR
} from '../constants';

const initialState = {
  scheduleMessageDelete: '',
  deleted: false,
  messageStatus: '',
  groupDeleteFail: null,
  loading: false,
  ProgressStatus: true,
  rowHighlight: false,
  groupDrawerVisible: false
};

function scheduleMessageDelete(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MESSAGE_DELETE_PENDING:
      return {
        ...state,
        loading: true,
        ProgressStatus: action.payload
      };

    case SCHEDULE_MESSAGE_DELETE_SUCCESS:
      return {
        ...state,
        scheduleMessageDelete: action.value,
        messageStatus: 'deleteSchedule',
        deleted: true,
        loading: false
      };

    case SCHEDULE_MESSAGE_DELETE_ERROR:
      return {
        ...state,
        groupDeleteFail: action.value,
        messageStatus: 'deleteSchedule',
        loading: false
      };
    default:
      return state;
  }
}
export default scheduleMessageDelete;

import {
  SEARCH_GROWERS_PENDING,
  SEARCH_GROWERS_SUCCESS,
  SEARCH_GROWERS_FAILURE,
  SEARCH_GROWERS_LOADING,
  FETCH_GROWER_LOADING,
  FETCH_GROWER_SUCCESS,
  FETCH_GROWER_ERROR,
  FETCH_GROWER_PENDING,
  SEARCH_GROWERS_TEXT,
  HIGHLIGHT_GROWER_TABEL_ROW
} from '../constants/index';

const initialState = {
  searchText: '',
  highlightGrowerRow: false,
  searchResults: [],
  defaultResults: [],
  loading: false,
  growersStatus: false,
  resError: []
};

export default function defaultGrowers(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROWER_PENDING:
      return {
        ...state,
        growersStatus: action.payload
      };
    case FETCH_GROWER_SUCCESS:
      return {
        ...state,
        defaultResults: action.payload
      };
    case FETCH_GROWER_ERROR:
      return {
        ...state,
        resError: action.payload
      };
    case FETCH_GROWER_LOADING:
      return {
        ...state,
        loading: action.payload
      };

    case SEARCH_GROWERS_PENDING:
      return {
        ...state,
        growersStatus: action.payload
      };
    case SEARCH_GROWERS_SUCCESS:
      return {
        ...state,
        searchResults: action.payload
      };
    case SEARCH_GROWERS_FAILURE:
      return {
        ...state,
        resError: action.payload
      };
    case SEARCH_GROWERS_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SEARCH_GROWERS_TEXT:
      return {
        ...state,
        searchText: action.payload
      };
    case HIGHLIGHT_GROWER_TABEL_ROW:
      return {
        ...state,
        highlightGrowerRow: action.payload
      };

    default:
      return state;
  }
}

import {
  COMMUNICATION_LIST_PENDING,
  COMMUNICATION_LIST_SUCCESS,
  COMMUNICATION_LIST_FAILURE,
  COMMUNICATION_LIST_LOADING
} from '../constants/index';

const initialState = {
  communicationLists: [],
  communicationListError: [],
  communicationListPending: false,
  loading: false
};

function communicationList(state = initialState, action) {
  switch (action.type) {
    case COMMUNICATION_LIST_PENDING:
      return {
        ...state,
        communicationListPending: action.payload,
        loading: true
      };
    case COMMUNICATION_LIST_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        communicationLists: action.payload
      });
    case COMMUNICATION_LIST_FAILURE:
      return {
        ...state,
        communicationListError: action.payload,
        loading: false
      };
    case COMMUNICATION_LIST_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}

export default communicationList;

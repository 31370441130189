import React, { Component } from 'react';
import { Drawer, Card, Form, Modal } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import PropTypes from 'prop-types';
import { toggleDrawer, clearYoutubeVideoStatus } from '../../../actions/uploadYouTubeVideos';
import GlobalSpinner from '../../commonComponents/globalSpinner/globalSpinner';
import { messageSendingService } from '../../../services/messageSendingService';
import SelectMediaForm from './selectMedia';
import { uploadYouTubeVideoService } from '../../../actions/uploadYouTubeVideos';
import { uploadFacebookPost, getFacebookPage } from '../../../actions/facebookPost';
import { uploadYouTubeVideoDetailService } from '../../../actions/uploadYouTubeVideoDetails';
import { uploadVideoProcessDetails } from '../../../actions/uploadYouTubeVideos';
import { alertBox } from '../../commonComponents/Notification/alertBox';
// import { fetchCostEstimator, fetchCostEstimatorClear } from '../../../actions/costEstimator';
// import StepsForm from './stepForm/stepsForm';

class studioDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerVisible: true
    };
    this.DrawerOnClose = this.DrawerOnClose.bind(this);
  }

  DrawerOnClose() {
    this.props.toggleDrawer();
  }
  componentDidMount = () => {
    this.props.getFacebookPage();
  };
  render() {
    const { uploadYouTubeVideo, progress, processingStatus } = this.props;

    return (
      <div>
        {this.props.toggleAlert
          ? alertBox({
              message: this.props.alertMessage,
              type: this.props.alertType
            })
          : null}
        <Drawer
          title='CREATE  NEW CONTENT'
          placement='right'
          closable={true}
          onClose={this.DrawerOnClose}
          visible={this.props.drawerVisible}
          mask={true}
          maskClosable={true}
          destroyOnClose={true}
          width={440}>
          <Card hoverable={false} bordered={false} className='NewMessgeCard'>
            <SelectMediaForm
              uploadYouTubeVideoService={this.props.uploadYouTubeVideoService}
              uploadYouTubeVideoDetailService={this.props.uploadYouTubeVideoDetailService}
              progress={this.props.progress}
              progressStatus={this.props.progressStatus}
              toggleDrawer={this.props.toggleDrawer}
              thumbnail={this.props.thumbnails}
              uploadStatus={this.props.uploadStatus}
              categoryId={this.props.categoryId}
              checkStatus={this.props.checkStatus}
              videoId={this.props.videoId}
              processingStatus={this.props.processingStatus}
              thumbnailStatus={this.props.thumbnailStatus}
              clearYoutubeVideoStatus={this.props.clearYoutubeVideoStatus}
              uploadFacebookPost={this.props.uploadFacebookPost}
            />
          </Card>
        </Drawer>
        <GlobalSpinner />
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    toggleDrawer: () => dispatch(toggleDrawer()),
    sendMessage: (mdata) => dispatch(messageSendingService(mdata)),
    uploadYouTubeVideoService: (videoStatus) => dispatch(uploadYouTubeVideoService(videoStatus)),
    uploadYouTubeVideoDetailService: (snippet) =>
      dispatch(uploadYouTubeVideoDetailService(snippet)),
    uploadVideoProcessDetails: (videoId) => dispatch(uploadVideoProcessDetails(videoId)),
    clearYoutubeVideoStatus: () => dispatch(clearYoutubeVideoStatus()),
    uploadFacebookPost: (postDetail, fileType) =>
      dispatch(uploadFacebookPost(postDetail, fileType)),
    getFacebookPage: () => dispatch(getFacebookPage())
  };
};
const mapStateToProps = function(state) {
  return {
    drawerVisible: _get(state, 'uploadYouTubeVideo.drawerVisible', true),
    uploadYouTubeVideo: _get(state, 'uploadYouTubeVideo.file', []),
    uploadStatus: _get(state, 'uploadYouTubeVideo.uploadStatus', []),
    checkStatus: _get(state, 'uploadYouTubeVideo.file.data.status.uploadStatus', []),
    progress: _get(state, 'uploadYouTubeVideo.progress', []),
    progressStatus: _get(state, 'uploadYouTubeVideo.progressStatus', []),
    thumbnails: _get(state, 'uploadYouTubeVideo.file.data.snippet.thumbnails', {}),
    categoryId: _get(state, 'uploadYouTubeVideo.file.data.snippet.categoryId', ''),
    videoId: _get(state, 'uploadYouTubeVideo.file.data.id', ''),
    processingStatus: _get(state, 'uploadYouTubeVideo.processingStatus.processingStatus', {}),
    thumbnailStatus: _get(state, 'uploadYouTubeVideo.processingStatus.thumbnailsAvailability', {}),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', 'info'),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true)
  };
};

studioDrawer.propTypes = {
  form: PropTypes.object,
  groupList: PropTypes.array,
  toggleDrawer: PropTypes.func,
  messageSendingService: PropTypes.func,
  messageDrawerVisible: PropTypes.bool,
  sendMessage: PropTypes.func,
  groupCrops: PropTypes.array,
  fetchCrops: PropTypes.func
};
const studioDrawerApp = Form.create({ name: 'coordinated' })(studioDrawer);
export default connect(mapStateToProps, mapDispatchToProps)(studioDrawerApp);
// export default studioDrawerApp;

import {
  FETCH_SEARCH_PENDING,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_UPDATE,
  FETCH_SEARCH_ERROR,
  CLEAR_SEARCH_RESULT,
  GET_SEARCH_STATE
} from '../constants';

const initialState = {
  data: {
    advanceSearchResult: {},
    searchListError: {},
    users: [],
    completed: false
  }
};

function searchList(state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_SEARCH_SUCCESS: {
      return Object.assign({}, state, {
        data: {
          ...state.data,
          advanceSearchResult: action.payload,
          completed: false
        }
      });
    }
    case FETCH_SEARCH_UPDATE: {
      const preSearchResult = state.data.advanceSearchResult;

      if (preSearchResult && preSearchResult.resultArr && preSearchResult.resultArr.length > 0) {
        const updatedValue = preSearchResult.resultArr.map((result) => {
          if (result.growerId === action.payload.growerId) {
            return action.payload;
          }
          return result;
        });

        preSearchResult.resultArr = updatedValue;

        return Object.assign({}, state, {
          data: {
            ...state.data,
            advanceSearchResult: preSearchResult,
            completed: false
          }
        });
      } else {
        return Object.assign({}, state, {
          data: {
            ...state.data,
            advanceSearchResult: action.payload,
            completed: false
          }
        });
      }
    }
    case CLEAR_SEARCH_RESULT:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          advanceSearchResult: []
        }
      });
    case GET_SEARCH_STATE:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          users: [...action.payload]
        }
      });
    case FETCH_SEARCH_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          searchListError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default searchList;

import { DELETE_GROUP_PENDING, DELETE_GROUP_SUCCESS, DELETE_GROUP_FAILURE } from '../constants';

const initialState = {
  groupDeleteSucess: {},
  deleted: false,
  groupDeleteFail: null,
  loading: false,
  ProgressStatus: true,
  rowHighlight: false,
  groupDrawerVisible: false
};

function deleteGroupReducer(state = initialState, action) {
  switch (action.type) {
    case DELETE_GROUP_PENDING:
      return {
        ...state,
        loading: true,
        ProgressStatus: action.payload
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        groupDeleteSucess: action.value,
        deleted: true,
        loading: false
      };

    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        groupDeleteFail: action.value,
        loading: false
      };
    default:
      return state;
  }
}
export default deleteGroupReducer;

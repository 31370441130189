import React from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Collapse,
  Card,
  Modal,
  Layout,
  Row,
  Col,
  Checkbox,
  Result
} from 'antd';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import bdJsonData from './bdDistrictCropAndBrandUpdated.json';
import bdJsonUpdated from './bd-excel-to-json.json';
import BDMasterData from './BD_Master_Data.json';
import newRetailer from './BD_Form_GrowerConsoleData.json';
import { fetchAddSingleGrowers } from '../actions/addSingleGrowerForm';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
import NumericInput from './numricInput';
import { uniq } from 'lodash';
import './addGrowerForm.css';
const { Option } = Select;
const { Footer } = Layout.Footer;

class AddGrowerBd extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    formData: {},
    confirmDirty: false,
    checked: true,
    visible: false,
    getProvince: '',
    getDistrict: '',
    getRegion: '',
    provinceDataVal: [],
    zipCodeVale: [],
    districteDataVal: [],
    getTMHQ: true,
    getMDOHQ: true,
    mdohqValue: [],
    salesUnit: [],
    upazila: [],
    done: null,
    message: '',
    isError: false,
    thaiValue: '',
    stockistCode: '',
    retailerCode: '',
    landSize: '',
    stockiestName: [],
    retailerNameData: [],
    disabled: false
  };

  onNumricChange = (e) => {
    const { value } = e.target;
    // const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    const re = /^[0-9\b]+$/;
    if (re.test(value) || value === '' || value === '-') {
      this.setState({ landSize: e.target.value });
      // this.props.form.setFieldsValue({acre:e.target.value})
    }
  };
  maxLengthCheck = (e) => {
    if (e.target.value.length > e.target.maxLength) {
      e.target.value = e.target.value.slice(0, e.target.maxLength);
    }
  };
  // '.' at the end or only '-' in the input box.
  onNumricBlur = () => {
    const { value, onNumricBlur, onNumricChange } = this.props;
    let valueTemp = value;
    const re = /^[0-9\b]+$/;
    if (e.target.value == '' || re.test(e.target.value)) {
      this.setState({ value: e.target.value });
    }
    if (value.charAt(value.length - 1) === '.' || value === '-') {
      valueTemp = value.slice(0, -1);
    }
    this.onNumricChange(valueTemp.replace(/0*(\d+)/, '$1'));
    if (this.onNumricBlur) {
      onNumricBlur();
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
   
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let growers = {
          mobile: `880${
            values.mobile.indexOf(0) == '0' ? values.mobile.substring(1) : values.mobile
          }`,
          retailerMobileNumber:
            values.retailerMobileNumber.length == '0'
              ? values.retailerMobileNumber
              : `880${
                  values.retailerMobileNumber.indexOf(0) == '0'
                    ? values.retailerMobileNumber.substring(1)
                    : values.retailerMobileNumber
                }`,
          address: {
            // zipCode: values.postCode,
            district: values.district,
            upZilla: values.upazila,
            subDistrict: values.upazila,
            village: values.villageName,
            region: values.region
          },
          sourcing: {
            salesUnit: values.salesUnit
          },
          cropInfo: {
            current: {
              name: values.cropName,
              acres: values.acre
            }
          },
          additionalFields: {
            // Brand: values.brand,
            'Retailer Name': values.retailerName,
            'Retailer Code': this.state.retailerCode,
            // TMHQ: values.tmhq,
            Stockist: values.stockistName,
            'First Name': values.name
            // 'Last Name': values.lastName,
            // MDOHQ: values.mdohq
          }
        };

        this.props.fetchAddSingleGrowers(growers);
        this.setState({
          disabled: true,
        });
        setTimeout(() => {
          this.setState(() => ({
            disabled: false,
          }));
        }, 10000);
      }
    });
  };
  onChange = (e) => {
    this.setState({ checked: !e.target.checked });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
  };

  validateNumber = (rule, value, callback) => {
    const reg = /^-?[0-9]*(\.[0-9]*)?$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  validateMobileNumber = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (value.length > 10 || value.length < 10) {
      callback('Mobile number should be 10 digit only');
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter mobile number');
    } else {
      callback();
    }
  };

  validatorStringRegion = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please enter valid region');
    } else {
      callback();
    }
  };
  validatorFirstName = (rule, value, callback) => {
    const reg = /^[A-Za-z_ .]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please fill in the correct information');
    } else {
      callback();
    }
  };
  validatorName = (rule, value, callback) => {
    const reg = /^[A-Za-z_]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please fill in the correct information');
    } else {
      callback();
    }
  };
  hideSucess = () => {
    this.setState({
      done: null,
      disabled:false
    });
  };
  getStokistCode = (value) => {
    const stockistCodeValue = this.state.mdohqValue
      .filter((val) => val.StockiestName === value)
      .map((val) => val.StockiestCode)[0];

    const retailerNameValue = this.state.mdohqValue
      .filter((val) => val.StockiestName === value)
      .map((val) => val.RetailerName);

    this.props.form.setFieldsValue({
      stockistCode: stockistCodeValue
    });
    this.setState({ stockistCode: stockistCodeValue, retailerNameData: retailerNameValue });
  };


  getStokistName = (value) => {
    const stockiestNameValue = BDMasterData
      .filter((val) => val.SalesUnit === value)
      .map((val) => val.StockiestName);
    this.setState({ stockiestName: stockiestNameValue });
  };
  getMdohqValue = (value) => {
    const mdohdValue = newRetailer.filter((val) => val.TMHQName === value);
    this.setState({ mdohqValue: mdohdValue });
  };
  getUpazile = (value) => {
    console.log("bdDistrcitJsonData",BDMasterData);
    const upazilaValue = BDMasterData
      .filter((val) => val.District === value)
      .map((val) => val.Upazila);
    this.setState({ upazila: upazilaValue });
  };
  getSalesUnitName = (value) => {
    const salesUnitValue = BDMasterData
      .filter((val) => val.Region === value)
      .map((val) => val.SalesUnit);
    this.setState({ salesUnit: salesUnitValue });
  };
  getRetailerName = (value) => {
    const retailerNameValue = BDMasterData
      .filter((val) => val.StockiestName === value)
      .map((val) => val.RetailerName);
    this.setState({ retailerNameData: retailerNameValue });
  };
  getRetailerCode = (value) => {
    const retailerCodeValue = BDMasterData
      .filter((val) => val.RetailerName === value)
      .map((val) => val.RetailerCode)[0];
    this.props.form.setFieldsValue({
      retailerCode: retailerCodeValue
    });
    this.setState({ retailerCode: retailerCodeValue });
  };
  componentDidMount = () => {
    const { location } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    this.setState({
      formData: params
    });
    const bdDistrcitJsonData = BDMasterData;
    let districtData = [];
    let cropData = [];
    let brandData = [];
    let isDistrictAvailable = '';
    let isBrandAvailable = '';
    let isCropAvailable = '';
    bdDistrcitJsonData.forEach((item, index) => {
      isDistrictAvailable = get(item, 'District', '');
      isBrandAvailable = get(item, 'Brand', '');
      isCropAvailable = get(item, 'Crop', '');
      districtData.push(isDistrictAvailable);
      brandData.push(isBrandAvailable);
      cropData.push(isCropAvailable);
    });
    this.setState({
      districteDataVal: uniq(districtData)
    });
  };
  validateError = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  componentDidUpdate = () => {
    if (this.props.alertType === 'success' && this.state.done === null) {
      this.setState({
        done: true,
        message: this.props.alertMessage
      });
    } else {
      console.log('render error');
    }
  };

  callback = (key) => {
    console.log(key);
  };
  genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        event.stopPropagation();
      }}
    />
  );
  getZipCodeData = (code, data) => {
    this.setState({
      getZipCode: data.filter((item) => item.zipcode == code)
    });
    data.filter((item) => item.zipcode === code);
  };
  getDistrictData = (province, data) => {
    this.setState({
      getDistrict: data.filter((item) => item.Province === province)
    });
    data.filter((item) => item.Province === province);
  };
  getRegionData = (district, data) => {
    this.setState({
      getRegion: data.filter((item) => item.District === district)
    });
  };
  onSearch = (val) => {
    console.log('val', val);
  };
  onSearch = (val) => {
    console.log('val', val);
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const bdDistrcitJsonData = BDMasterData;
    let isDistrictAvailable = '';
    let isRegionAvailable = '';
    let districtData = [];
    let regionData = [];
    let cropData = [];
    let brandData = [];
    let mdohqData = [];
    let tmhqData = [];
    let stockiestNameData = [];
    let stockiestCodeData = [];
    let retailerData = [];
    let isBrandAvailable = '';
    let isCropAvailable = '';
    let isMdohqAvailable = '';
    let isTmhqAvailable = '';
    let isStockiestNameAvailable = '';
    let isRetailerNameAvailable = '';
    let isStockiestCodeAvailable = '';
    bdDistrcitJsonData.forEach((item, index) => {
      isDistrictAvailable = get(item, 'District', '');
      isBrandAvailable = get(item, 'Brand', '');
      isCropAvailable = get(item, 'Crop', '');
      isMdohqAvailable = get(item, 'MDOHQName', '');
      isTmhqAvailable = get(item, 'TMHQName', '');
      isStockiestNameAvailable = get(item, 'StockiestName', '');
      isStockiestCodeAvailable = get(item, 'StockiestCode', '');
      isRetailerNameAvailable = get(item, 'RetailerName', '');
      isRegionAvailable = get(item, 'Region', '');
      districtData.push(isDistrictAvailable);
      regionData.push(isRegionAvailable);
      brandData.push(isBrandAvailable);
      cropData.push(isCropAvailable);
      mdohqData.push(isMdohqAvailable);
      tmhqData.push(isTmhqAvailable);
      stockiestNameData.push(isStockiestNameAvailable);
      stockiestCodeData.push(isStockiestCodeAvailable);
      retailerData.push(isRetailerNameAvailable);
    });

    const districtOption = uniq(districtData).map((value, inx) => {
      return (
        <Option key={inx} value={value}>
          {value}
        </Option>
      );
    });

    const regionOption = uniq(regionData).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const cropOption = uniq(cropData).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });

    const TMHQOption = uniq(tmhqData).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const mdohqDataValue = uniq(this.state.mdohqValue).map((value, index) => value.MDOHQName);
    const upazilaValue = uniq(this.state.upazila).map((value, index) => value);
    const salesUnitValue = uniq(this.state.salesUnit).map((value, index) => value);
    const stockiestNameValue = uniq(this.state.stockiestName).map((value, index) => value);
    const stockiestCodeValue = uniq(this.state.mdohqValue).map(
      (value, index) => value.StockiestCode
    );
    const retailerNameValue = uniq(this.state.retailerNameData).map((value, index) => value);
    
    const retailerCodeValue = uniq(this.state.retailerCode).map((value, index) => value);
   
    const upazilaOption = uniq(upazilaValue).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const salesUnitOption = uniq(salesUnitValue).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const stockiestNameOption = uniq(stockiestNameValue).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const stockiestCodeOption = uniq(stockiestCodeData).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const retialerOption = uniq(retailerNameValue).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const loginPageImage = {
      synLogo: require('../assets/img/syn-logo.png')
    };
    return (
      <div
        style={{ width: '100%', height: 'auto', padding: '24px', backgroundColor: '#2B9C92' }}
        className=''>
        {!this.state.done ? (
          <Form
            layout='vertical'
            onSubmit={this.handleSubmit}
            style={{
              height: 'auto',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
            className='addGrower'>
            <div style={{ display: 'flex' }}>
              <div
                className='Title'
                style={{
                  fontWeight: '600',
                  fontSize: '1.5em',
                  color: '#14151C',
                  paddingLeft: '30px',
                  position: 'relative',
                  top: '2px'
                }}>
                কৃষক নিবন্ধন
              </div>
              <div class='avatarImageMY'>
                <img
                  src={loginPageImage.synLogo}
                  alt='Avatar'
                  width='80vh'
                  height='100px'
                  style={{ height: '75px' }}
                />
              </div>
            </div>
            <Form.Item label={<p className='loginFormLabel'>Grower Name </p>}>
              {getFieldDecorator('name', {
                initialValue: this.state.formData.Name || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: `Please Enter Name`, whitespace: true }]
              })(<Input placeholder='Enter Name' type='text' />)}
            </Form.Item>

            <Form.Item label={<p className='loginFormLabel'>Mobile Number </p>}>
              {getFieldDecorator('mobile', {
                initialValue: this.state.formData.MobileNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: 'Please Enter Mobile Number'
                    // whitespace: true
                  },

                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                  type='number'
                  addonBefore='+880'
                  placeholder='Enter Mobile Number'
                  autoComplete='off'
                  maxLength={10}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 32 ||
                      e.keyCode === 189 ||
                      e.keyCode === 187) &&
                    e.preventDefault()
                  }
                  onInput={this.maxLengthCheck}
                />
              )}
            </Form.Item>

            <Form.Item label={<p className='loginFormLabel'>Village Name </p>}>
              {getFieldDecorator('villageName', {
                initialValue: this.state.formData.Surname || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: `Please Enter Village Name `, whitespace: true }]
              })(<Input placeholder='Enter Village Name' />)}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Retailer Phone Number </p>}>
              {getFieldDecorator('retailerMobileNumber', {
                initialValue: this.state.formData.retailerPhoneNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: false,
                    message: 'Please Enter Retailer Phome Number'
                    // whitespace: true
                  },

                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                  type='number'
                  addonBefore='+880'
                  placeholder='Enter Retailer Phone Number'
                  maxLength={10}
                  onInput={this.maxLengthCheck}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 190 ||
                      e.keyCode === 32 ||
                      e.keyCode === 187 ||
                      e.keyCode === 189) &&
                    e.preventDefault()
                  }
                />
              )}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Crop Name </p>}>
              {getFieldDecorator('cropName', {
                initialValue: this.state.formData.cropName || 'Rice',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Crop Name ' }]
              })(
                <Select placeholder='Select Crop Name' showSearch>
                  {cropOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>District </p>}>
              {getFieldDecorator('district', {
                initialValue: this.state.formData.district || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select District ' }]
              })(
                <Select
                  placeholder='Select Select District'
                  onChange={(val) => this.getUpazile(val)}
                  showSearch>
                  {districtOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Upazila</p>}>
              {getFieldDecorator('upazila', {
                initialValue: this.state.formData.upazila,
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Upazila ' }]
              })(
                <Select
                  placeholder='Select Select Upazila'
                  showSearch
                  disabled={this.state.upazila.length === 0}>
                  {upazilaOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Region</p>}>
              {getFieldDecorator('region', {
                initialValue: this.state.formData.region || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Region' }]
              })(
                <Select
                  placeholder='Select Region'
                  showSearch
                  onChange={(val) => this.getSalesUnitName(val)}>
                  {regionOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Sales Unit Name </p>}>
              {getFieldDecorator('salesUnit', {
                initialValue: this.state.formData.salesUnit || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Sales Unit Name' }]
              })(
                <Select
                  placeholder='Select Sales Unit Name '
                  showSearch
                  onChange={(val) => this.getStokistName(val)}
                  disabled={this.state.salesUnit.length === 0}>
                  {salesUnitOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Distributor Name</p>}>
              {getFieldDecorator('stockistName', {
                initialValue: this.state.formData.stockistName || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Distributor Name' }]
              })(
                <Select
                  placeholder='Select Distributor Name '
                  showSearch
                  onChange={(val) => this.getRetailerName(val)}
                  disabled={this.state.stockiestName.length === 0}>
                  {stockiestNameOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              className='addGrowerFrom'
              label={<p className='loginFormLabel'>Retailer Name (KSR) </p>}>
              {getFieldDecorator('retailerName', {
                initialValue: this.state.formData.retailerName || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Please Select Retailer Name' }]
              })(
                <Select
                  placeholder='Select Retailer Name'
                  showSearch
                  onChange={(val) => this.getRetailerCode(val)}
                  disabled={this.state.retailerNameData.length === 0}>
                  {retialerOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={<p className='loginFormLabel'>Land Size (decimal) </p>}>
              {getFieldDecorator('acre', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'Please Enter Land Size' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(
                <Input
                  type='number'
                  maxLength={7}
                  // pattern={'d{7}'}
                  onInput={this.maxLengthCheck}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 ||
                      e.keyCode === 32 ||
                      e.keyCode === 189 ||
                      e.keyCode === 187) &&
                    e.preventDefault()
                  }
                />
              )}
            </Form.Item>

            <Form.Item>
              <a onClick={this.showModal} style={{ color: '#1890ff' }}>
                Click to view the company's terms and conditions.
              </a>
            </Form.Item>
            <Form.Item label='Source' hidden={true}>
              {getFieldDecorator('source', {
                initialValue: this.state.formData.source || 'form'
              })(<Input />)}
            </Form.Item>
            <Form.Item
              style={{
                marginTop: '10px !important'
              }}>
              <Button htmlType='submit' className='buttonStyle' disabled={this.state.disabled} onClick={this.handleSubmit}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Result
            status='success'
            title=' Information registration is successful.'
            extra={[
              <Button type='primary' key='console' onClick={this.hideSucess}>
                Add Another Grower
              </Button>
            ]}
          />
        )}
        <Modal
          title=''
          visible={this.state.visible}
          onOk={this.handleOk}
          closable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button type='primary' onClick={this.handleOk}>
              agree
            </Button>
          ]}>
          <p>
            Personal data according to the Personal Data Protection Act B.E. 2562 <br />
            for the purpose of planning an online marketing campaign Syngenta (US) We are obliged to
            request and store your personal information for us. and those assigned by us Within the
            framework of the above objectives As you have pressed the code or provided information
            to register farmers. and has given consent herewith If you no longer wish your
            information to be used for such purposes Please contact one of our sales
            representatives.
          </p>
        </Modal>

        {this.props.alertType === 'error'
          ? this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null
          : ''}
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchAddSingleGrowers: (growers) => dispatch(fetchAddSingleGrowers(growers))
  };
};
const mapStateToProps = function(state) {
  return {
    growerList: get(state, 'addedGrowerList.data.growerSucess', []),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(AddGrowerBd);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

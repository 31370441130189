import {
  UPLOAD_FACEBOOK_PENDING,
  UPLOAD_FACEBOOK_SUCCESS,
  UPLOAD_FACEBOOK_ERROR
} from '../constants';

const initialState = {
  data: {
    uploadPostResult: {},
    uploadPostError: {},
    completed: false
  }
};

function uploadFacebookList(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FACEBOOK_PENDING:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          completed: true
        }
      });
    case UPLOAD_FACEBOOK_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          uploadPostResult: action.payload,
          completed: false
        }
      });
    case UPLOAD_FACEBOOK_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          uploadPostError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}

export default uploadFacebookList;

import fetch from 'cross-fetch';
import config from '../config';
import { formatUrl } from './commonMethods';
import { fetchUsersWithFetchAPIAsnc } from '../actions/growerList';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
export const fetchUsersWithFetchAPI = (pagenum) => {
  const apiUri = formatUrl(config.growerList, pagenum, '', ['-updated', '-created'], '10');
  return (dispatch) => {
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchUsersWithFetchAPIAsnc(result));
        dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
      });
  };
};

export const fetchSearchResult = (pageNum = 0, name = '') => {
  const apiUri = formatUrl(config.growerListSearch, pageNum, name, ['-updated', '-created'], '10');
  return (dispatch) => {
    if (name === '') {
      dispatch({
        type: 'SEARCH_LIST',
        value: {
          result: {
            limit: 10,
            totalPages: 1,
            items: []
          },
          searchText: name
        }
      });
    } else {
      fetch(apiUri, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + config.userToken
        }
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          if (result.code !== '4003') {
            dispatch({
              type: 'SEARCH_LIST',
              value: {
                result: result,
                searchText: name
              }
            });
          } else {
            dispatch({
              type: 'SEARCH_LIST',
              value: {
                result: {
                  limit: 0,
                  totalPages: 0,
                  items: []
                },
                searchText: name
              }
            });
          }
        })
        .catch((e) => {});
    }
  };
};

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Table, Tag } from 'antd';

const dataSource = [
  {
    key: '1',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '2',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '04-02-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '3',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-05-2020',
    likeCount: 100,
    commentCount: 101
  },
  {
    key: '4',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '5',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '6',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '7',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '8',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  },
  {
    key: '9',
    url: 'Mike',
    Post_Status: 'Published',
    publishedAt: '10-10-2020',
    likeCount: 100,
    commentCount: 10
  }
];
const columns = [
  {
    title: 'Post',
    align: 'center',
    dataIndex: 'url',
    render: (text) => <img src={text} />
  },
  {
    title: 'Post Status',
    align: 'center',
    dataIndex: 'Post_Status'
  },
  {
    title: 'Published On',
    align: 'center',
    dataIndex: 'publishedAt'
  },
  {
    title: 'Likes',
    align: 'center',
    dataIndex: 'likeCount'
  },
  {
    title: 'Comments',
    align: 'center',
    dataIndex: 'commentCount'
  }
];
class SocialMediaTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      data: this.props.data,
      pagination: {},
      currentPage: 0
    };
  }

  handleTableChange(pagination) {}

  render() {
    return <Table dataSource={dataSource} bordered columns={columns} />;
  }
}

// Datatable.displayName = 'Datatable';
export default SocialMediaTable;

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchArea } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchArea extends React.Component {
  constructor(props) {
    super(props);
    this.childrenArea = [];
  }
  handleChange = (user, event) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = event;
    this.props.callback(newUsers);
  };
  componentDidMount() {
    this.props.fetchArea();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t, searchAreaMessage } = this.props;
    let areaData = this.props.groupArea || [];
    let areaText = areaData.length > 0 ? areaData[0].items : [];
    this.childrenArea = areaText.map((region) => <Option key={region}>{region}</Option>);
    return (
      <Form.Item label='' className='growersCrop'>
        {getFieldDecorator(`${this.props.areaValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: this.props.searchAreaMessage && this.props.searchAreaMessage.message
            }
          ]
        })(
          <Select
            name='area'
            size='small'
            mode='multiple'
            showArrow={true}
            placeholder={this.props.searchAreaMessage && this.props.searchAreaMessage.placeholder}
            maxTagCount={5}
            onChange={(event) => {
              this.handleChange(this.props.user, event);
            }}>
            {this.childrenArea}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchArea: () => dispatch(fetchArea())
  };
};
const mapStateToProps = function(state) {
  return {
    groupArea: get(state, 'groupFilterReducer.data.groupArea.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchArea);

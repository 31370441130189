import React, { Component } from 'react';
import { Layout, Card, Row, Col, Button, Select, Icon } from 'antd';
import { uniq } from 'lodash';
// import {
// 	G2,
// 	Chart,
// 	Tooltip,
// 	Interval,
// } from "bizcharts";
import { Bar, Column } from '@ant-design/charts';

import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
const { Option } = Select;

class BizReach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
      series: [],
      year: []
    };
  }
  handleChange = (value) => {
    this.setState(
      {
        currentYear: value
      },
      () => this.createReachData(this.props.growerReach, this.props.country)
    );
  };

  createReachData = (data, country) => {
    console.log('country>', country);
    if (isEmpty(data)) {
      return;
    }
    let timeStampSms = [];
    let countSms = [];
    let timeStamp = [];
    let timeStampMms = [];
    let count = [];
    var newDateArray = [];
    const key = Object.keys(data);
    const value = Object.values(data);
    data && data.SMS
      ? data.SMS.forEach((item) => {
          timeStampSms.unshift(item.timeStamp);
          countSms.unshift(item.count);
        })
      : [];

    data && data.WHATSAPP
      ? data.WHATSAPP.forEach((item) => {
          timeStamp.unshift(item.timeStamp);
          count.unshift(item.count);
        })
      : [];
    data && data.MMS
      ? data.MMS.forEach((item) => {
          timeStampMms.unshift(item.timeStampMms);
          count.unshift(item.count);
        })
      : [];
    let dateTime =
      country === 'kr' ? [...timeStampSms, ...timeStampMms] : [...timeStampSms, ...timeStamp];
    const getCountryData = Object.entries(data).filter(([key, value]) => {
      return key == this.state.selectedCountry;
    });
    const getYearData = data[this.state.currentYear];
    // var reducedSms = getYearData && getYearData.SMS ? getYearData && getYearData.SMS.map(sms =>({name : 'SMS',month: moment(new Date(sms.timeStamp)).format('MMM YY'), data:sms.count})).reverse(): '';
    // var reducedWhatsApp = getYearData && getYearData.WHATSAPP ? getYearData && getYearData.WHATSAPP.map(sms =>({name : 'WHATSAPP',month: moment(new Date(sms.timeStamp)).format('MMM YY'), data:sms.count})).reverse(): '';
    let checkSmsLength = reducedSms > reducedWhatsApp;
    // const seriesData= checkSmsLength ? [...reducedSms, ...reducedWhatsApp] : [...reducedWhatsApp, ...reducedSms]
    var reducedSms =
      getYearData && getYearData.SMS
        ? getYearData &&
          getYearData.SMS.map((sms) => ({
            year: moment(new Date(sms.timeStamp)).format('MMM '),
            value: sms.count,
            type: 'SMS'
          })).reverse()
        : '';
    var reducedWhatsApp =
      getYearData && getYearData.WHATSAPP
        ? getYearData &&
          getYearData.WHATSAPP.map((sms) => ({
            year: moment(new Date(sms.timeStamp)).format('MMM '),
            value: sms.count,
            type: 'WHATSAPP'
          })).reverse()
        : '';
    var reducedMMS =
      getYearData && getYearData.MMS
        ? getYearData &&
          getYearData.MMS.map((mms) => ({
            year: moment(new Date(mms.timeStamp)).format('MMM '),
            value: mms.count,
            type: 'MMS'
          })).reverse()
        : '';
    const seriesData =
      country === 'kr' ? [...reducedSms, ...reducedMMS] : [...reducedSms, ...reducedWhatsApp];
    for (let i of dateTime) {
      let date = moment(new Date(i)).format('MMM YY');
      newDateArray.push(date);
    }
    this.setState({
      series: seriesData,
      year: key
    });
    return {
      series: [...reducedSms, ...reducedWhatsApp],
      categories: [...newDateArray],
      labels: key
    };
  };
  componentDidMount = () => {
    // if(nextProps.growerReach !== this.props.growerReach ){
    this.createReachData(this.props.growerReach, this.props.country);
    // }
  };
  componentDidUpdate = (preProps, nextProps) => {
    if (preProps.growerReach !== this.props.growerReach) {
      this.createReachData(this.props.growerReach, this.props.country);
    }
  };
  render() {
    const { t, growerReach } = this.props;
    console.log('series>', this.state.series);
    const optionsData = this.state.year
      ? this.state.year.map((year) => <Option key={year}>{year}</Option>)
      : [];

    var config = {
      data: this.state.series,
      isStack: true,
      xField: 'year',
      yField: 'value',
      seriesField: 'type',
      legend: { position: 'bottom' },
      label: {
        position: 'middle',
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' }
        ]
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: true
        }
      }
      // tooltip: {
      //   formatter: function formatter(datum) {
      //     return {
      //       name: ''
      //         .concat(datum.type, ' ')
      //         .concat(datum.type === 'SMS' ? <Icon type='user' /> : '\uD83D\uDC67'),
      //       value: datum.value
      //     };
      // }
    };
    return (
      <div className='trendChart'>
        <div className='headerNameTrend'>Communication Trend</div>
        <div style={{ display: 'flex', paddingBottom: '10px', fontWeight: '600' }}>
          <div style={{ textAlign: 'left' }}>
            Year
            <Select
              placeholder='select year'
              style={{
                width: 80,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '5px',
                // border: '1px solid #d9d9d9'
                // backgroundColor: 'whitesmoke'
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultValue={this.state.currentYear}
              //   value=''
              className='selectYear'
              onChange={(value) => {
                this.handleChange(value);
              }}>
              {optionsData}
            </Select>
          </div>
        </div>
        <div className='row'>
          <div className='mixed-chart'>
            {/* <Chart height={350} padding="auto" data={uniq(this.state.series)} autoFit>
			<Interval
				adjust={[
					{
						type: 'stack',
					},
				]}
				color="name"
				position="month*data"
			/>
			<Tooltip shared />
		</Chart> */}
            <Column height={350} {...config} />
          </div>
        </div>
      </div>
    );
  }
}

export default BizReach;

import {
  OPEN_CONVERSATION_PENDING,
  OPEN_CONVERSATION_SUCCESS,
  OPEN_CONVERSATION_ERROR,
  CLEAR_CONVERSATION
} from '../constants/index';

const initialState = {
  data: {
    openConversationList: [],
    totalCount: '',
    openConversationListError: {},
    openConversationListStatus: '',
    completed: false,
    loading: true
  }
};

function openConversationList(state = initialState, action) {
  switch (action.type) {
    case OPEN_CONVERSATION_PENDING:
      return {
        data: {
          ...state.data,
          completed: true,
          loading: true
        }
      };
    case OPEN_CONVERSATION_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          openConversationList: action.payload.direction
            ? [...action.payload.items, ...state.data.openConversationList]
            : [...state.data.openConversationList, ...action.payload.items],
          totalCount: action.payload,
          completed: false
        }
      });
    case OPEN_CONVERSATION_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          openConversationListError: action.payload,
          completed: false
        }
      });
    case CLEAR_CONVERSATION:
      return Object.assign({}, state, {
        data: {
          openConversationList: [],
          totalCount: '',
          openConversationListError: {},
          openConversationListStatus: '',
          completed: true
        }
      });
    default:
      return state;
  }
}

export default openConversationList;

import React, { Component } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import moment from 'moment';
import PropTypes from 'prop-types';
import '../../commonComponents/Datatabel/datatable.css';
import { sentMessageListWithFetchAPI } from '../../../services/messageListService';

class MessageListData extends Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.state = {
      selectedRowKeys: [], // Check here to configure the default column
      isFetching: false,
      users: [],
      loading: false,
      pagination: {}
    };
  }
  handleTableChange(pagination) {
    this.props.setCurrentPage(pagination.current);
    this.props.sentMessageListWithFetchAPI(pagination.current - 1);
  }

  componentDidMount = () => {
    this.props.sentMessageListWithFetchAPI(0);
  };
  componentDidUpdate(preProps, preState) {
    if (preProps.activeTab !== this.props.activeTab && this.props.activeTab === '1') {
      this.props.sentMessageListWithFetchAPI(0);
    }
  }
  render() {
    const { currentPage, columns } = this.props;
    const pagination = {
      total: this.props.totalPages * 10
    };

    let list = [];

    list = this.props.messageList;
    const data = [];
    if (list) {
      list.forEach((value, index) => {
        console.log('value>>', value);
        data.push({
          key: index,
          Group: value && value.group && value.group.name ? value.group.name : 'NA',
          msgType: value && value.type ? value.type : 'NA',
          Sender: value && value.sender ? value.sender : '',
          SentOn:
            (value && value.createdDate) || value.created
              ? moment
                  .utc(value.createdDate ? value.createdDate : value.created)
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              : '',
          Platform: value && value.channels ? value.channels[0] : value.channel,
          deletedGroup: value && value.group ? value.group.deleted : false
        });
      });
    }
    return (
      <Table
        rowKey={(record) => record.key}
        pagination={{ ...pagination, current: currentPage }}
        loading={this.state.loading}
        onChange={this.handleTableChange}
        size='Small'
        bordered={true}
        columns={columns}
        dataSource={data}
        rowClassName={(record, index) => {
          // record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : 'test1'
          return this.props.currentPage === 0 && index === 0 && this.props.updateRowClassFlag
            ? 'highLight'
            : '';
        }}
      />
    );
  }
}

// export default Datatable;
const mapDispatchToProps = function(dispatch) {
  return {
    sentMessageListWithFetchAPI: (pagenum) => dispatch(sentMessageListWithFetchAPI(pagenum))
  };
};
const mapStateToProps = function(state) {
  return {
    totalPages: _get(state, 'messageLists.data.messageList.totalPages', 0),
    updateRowClassFlag: _get(state, 'messageSendingFormReducer.rowHighlight', true),
    messageList: _get(state, 'messageLists.data.messageList.items', []),
    deletedRow: _get(state, 'messageLists.data.messageList.items.group.deleted', false)
  };
};
MessageListData.propTypes = {
  sentMessageListWithFetchAPI: PropTypes.func,
  totalPages: PropTypes.number,
  messageList: PropTypes.array,
  updateRowClassFlag: PropTypes.bool
};
export default connect(mapStateToProps, mapDispatchToProps)(MessageListData);

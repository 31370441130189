import {
  REQUEST_UPLOAD,
  REQUEST_UPLOAD_SUCCESS,
  REQUEST_UPLOAD_FAILURE,
  CLEAR_PROGRESS_STATUS,
  UPLOAD_PROGRESS,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_TABEL_ROW
} from '../constants';

const initialState = {
  file: [],
  progress: 0,
  drawerVisible: false,
  rowHighlight: false,
  selectedSchema: '',
  uploadStatus: '',
  progressStatus: 'active',
  progressBarStatus: false
};

export default function uploadFormReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_UPLOAD:
      return {
        ...state,
        uploadStatus: action.payload.uploadStatus
      };
    case REQUEST_UPLOAD_SUCCESS:
      return {
        ...state,
        file: action.payload.res
      };
    case REQUEST_UPLOAD_FAILURE:
      return {
        ...state,
        file: action.payload.res
      };
    case CLEAR_PROGRESS_STATUS:
      return {
        ...state,
        progressStatus: 'active',
        progress: 0
      };
    case UPLOAD_PROGRESS:
      return {
        ...state,
        progressStatus: action.payload.progressStatus,
        progressBarStatus: action.payload.progressBarStatus,
        progress: action.payload.progress
      };
    case TOGGLE_DRAWER_STATUS:
      return {
        ...state,
        drawerVisible: !state.drawerVisible
      };
    case HIGHLIGHT_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };

    default:
      return state;
  }
}

import React from 'react';
import { Form, Select } from 'antd';
import { fetchsytGrowerCorn } from '../../../services/groupFilterService';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchSytGrowerCorn extends React.Component {
  constructor(props) {
    super(props);
    this.childrenCorn = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchsytGrowerCorn();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;

    let cornData = this.props.groupSytGrowerCorn || [];
    let cornText = cornData.length > 0 ? cornData[0].items : [];
    this.childrenCorn = cornText.map((corn) => <Option key={corn}>{corn}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.sytCornValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some crop 1 planted') }]
        })(
          <Select
            name='corn'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Crop 1 Planted')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenCorn}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchsytGrowerCorn: () => dispatch(fetchsytGrowerCorn())
  };
};
const mapStateToProps = function(state) {
  return {
    groupSytGrowerCorn: get(state, 'groupFilterReducer.data.groupSytGrowerCorn.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchSytGrowerCorn);

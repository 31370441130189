import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import './onboard.css';
/** Import Common components */
import TitleSection from '../../commonComponents/TitleSection/titleSection';
import AddGrower from './components/addGrowers/addGrower';
import Datatable from '../../commonComponents/Datatabel/datatable';
import AutoSearch from '../../commonComponents/Autosearch/autosearch';
import GlobalSpinner from '../../commonComponents/globalSpinner/globalSpinner';
import { withTranslation } from 'react-i18next';
/*** Ant Design Components */
import { userLogin } from '../../../actions/authentication';
import { RegionalMetaContext } from '../../../context/region';
const { Content } = Layout;

class OnBoard extends React.Component {
  static contextType = RegionalMetaContext;
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    };
  }
  setCurrentPage = (current) => {
    this.setState({
      currentPage: current
    });
  };
  render() {
    const { t } = this.props;
    return (
      <RegionalMetaContext.Consumer>
        {({ data }) => (
          <Layout className='onboadrLayout'>
            <Content className='pageTitleDiv'>
              <TitleSection {...this.props} title={t('On Board Growers')} />
            </Content>
            <AddGrower
              {...this.props}
              resetPage={() => {
                this.setState({
                  currentPage: 0
                });
              }}
              sampleTemplate={data.sampleTemplate}
              uploadSheetButton={data.uploadSheetButton}
              setCurrentPage={this.setCurrentPage}
              currentPage={this.state.currentPage}
            />
            <Content className='mainContent' style={{ height: '75vh', overflow: 'auto' }}>
              <div style={{ padding: '0 0', background: 'transparent' }}>
                <Datatable
                  setCurrentPage={this.setCurrentPage}
                  currentPage={this.state.currentPage}
                  columns={data.dataTable}
                />
              </div>
            </Content>
            <GlobalSpinner />
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    userLogin(text) {
      dispatch(userLogin(text));
    }
  };
};
// export default OnBoard;
const mapStateToProps = function() {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OnBoard));

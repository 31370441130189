import axios from 'axios';
import {
  SCHEDULE_MMS_PENDING,
  SCHEDULE_MMS_SUCCESS,
  SCHEDULE_MMS_ERROR,
  CLEAR_SCHEDULE_MMS_STATUS,
  FETCH_SEARCH_UPDATE,
  CLEAR_SEND_MMS_RESULT,
  TOGGLE_DRAWER_STATUS
} from '../constants';

import { scheduleMessageListService } from '../actions/scheduleMessageList';
import { toggleDrawer, highlightMessageTableRow } from './messageSending';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
export function scheduleMMSResultPending() {
  return {
    type: SCHEDULE_MMS_PENDING
  };
}
export function scheduleMMSResultSuccess(searchList) {
  return {
    type: SCHEDULE_MMS_SUCCESS,
    payload: searchList
  };
}

export function clearScheduleMMSResult() {
  return {
    type: CLEAR_SCHEDULE_MMS_STATUS
  };
}
export function scheduleMMSResultError(error) {
  return {
    type: SCHEDULE_MMS_ERROR,
    payload: error
  };
}
export function setSearchState(users) {
  return {
    type: GET_SEARCH_STATE,
    payload: users
  };
}
export function toggleDrawerStatus() {
  return {
    type: TOGGLE_DRAWER_STATUS
  };
}
export const scheduleMMS = (formData) => {
  // const apiUri = formatUrl(config.sendMMS);
  const apiUri = config.scheduleMMS;
  return (dispatch) => {
    dispatch(scheduleMMSResultPending());
    dispatch(toggleSpinStatus());
    axios
      .post(apiUri, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + config.userToken
        }
      })

      .then((result) => {
        console.log('result>', result);
        if (result && result.data.code === '200') {
          dispatch(scheduleMessageListService(0));
          dispatch(scheduleMMSResultPending());
          dispatch(toggleSpinStatus());

          dispatch(toggleDrawerStatus());
          dispatch(highlightMessageTableRow(true));
          dispatch(clearAlertState());
          dispatch(scheduleMMSResultSuccess(result));
          // dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: result.data.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
          //  dispatch(toggleSpinStatus());
        } else {
          dispatch(scheduleMMSResultPending());
          dispatch(toggleSpinStatus());
          dispatch(toggleDrawerStatus());
          dispatch(scheduleMMSResultError({ res: error }));
          dispatch(clearAlertState());

          dispatch(
            errorNotification({
              type: 'error',
              message:
                'Message not sent because the requested channel is not supported by any group members',
              toggleAlert: true
            })
          );
        }
      })
      .catch((error) => {
        console.log('error>', error);
        dispatch(scheduleMMSResultPending());
        dispatch(toggleSpinStatus());
        dispatch(toggleDrawerStatus());
        dispatch(scheduleMMSResultError({ res: error }));
        dispatch(clearAlertState());

        dispatch(
          errorNotification({
            type: 'error',
            message:
              'Message not sent because the requested channel is not supported by any group members',
            toggleAlert: true
          })
        );
      });
  };
};

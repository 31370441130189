import {
  ALL_GROWERS_COUNT_PENDING,
  ALL_GROWERS_COUNT_SUCCESS,
  ALL_GROWERS_COUNT_FAILURE,
  ALL_GROWERS_COUNT_LOADING
} from '../constants/index';

const initialState = {
  allGrowersCount: [],
  allGrowersCountError: [],
  allGrowersCountPending: false,
  loading: false
};

function allGrowersCount(state = initialState, action) {
  switch (action.type) {
    case ALL_GROWERS_COUNT_PENDING:
      return {
        ...state,
        allGrowersCountPending: action.payload,
        loading: true
      };
    case ALL_GROWERS_COUNT_SUCCESS:
      return {
        ...state,
        allGrowersCount: action.payload,
        loading: false
      };
    case ALL_GROWERS_COUNT_FAILURE:
      return {
        ...state,
        allGrowersCountError: action.payload,
        loading: false
      };
    case ALL_GROWERS_COUNT_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
}

export default allGrowersCount;

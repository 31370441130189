import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { Layout, Card, Row, Col, Button, Select } from 'antd';
import { uniq } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { Bar, Column } from '@ant-design/charts';
import moment from 'moment';
const { Option } = Select;
class InductionTrend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentYear: new Date().getFullYear(),
      series: [],
      year: [],
      categories: []
    };
  }
  createInductedData = (data) => {
    if (isEmpty(data)) {
      return;
    }
    let timeStamp = [];
    let dataTime = [];
    let count = [];
    var newDateArray = [];
    const key = Object.keys(data);
    const getData = data[this.state.currentYear];
    // getData.length
    //   ? getData.forEach((item) => {
    //       timeStamp.unshift(item.timeStamp);
    //       count.unshift(item.count);
    //     })
    //   : [];
    var reducedData =
      getData && getData
        ? getData &&
          getData
            .map((data) => ({
              year: moment(new Date(data.timeStamp)).format('MMM'),
              value: data.count,
              type: 'Growers Inducted'
            }))
            .reverse()
        : '';
    let dateTime = [...timeStamp];

    for (let i of dateTime) {
      let date = moment(new Date(i)).format('MMM YY');
      newDateArray.push(date);
    }
    //  const seriesData= [
    //     {
    //       name: 'count',
    //       data: count
    //     }
    //   ];
    const seriesData = [...reducedData];
    this.setState({
      series: seriesData,
      categories: [...newDateArray],
      year: key
    });
  };
  handleChange = (value) => {
    this.setState(
      {
        currentYear: value
      },
      () => this.createInductedData(this.props.monthlyInducted)
    );
  };
  // componentDidMount = () =>{
  //   this.createInductedData(this.props.monthlyInducted)
  // }
  componentDidUpdate = (preProps, nextProps) => {
    if (preProps.monthlyInducted !== this.props.monthlyInducted) {
      this.createInductedData(this.props.monthlyInducted);
    }
  };

  render() {
    const { monthlyInducted } = this.props;
    const optionsData = this.state.year
      ? this.state.year.map((year) => <Option key={year}>{year}</Option>)
      : [];
    var config = {
      data: this.state.series,
      isStack: false,
      xField: 'year',
      yField: 'value',
      seriesField: 'type',
      //   color: ['#FF6B3B', '#9FB40F', '#0E8E89', '#F383A2', '#247FEA', '#D64BC0', '#FF745A', '#1CC25E', '#1AAF8B','#FE8A26',
      // '#6193FF', '#8D00A1'],
      // legend:false,
      legend: { position: 'bottom' },

      label: {
        position: 'middle',
        layout: [
          { type: 'interval-adjust-position' },
          { type: 'interval-hide-overlap' },
          { type: 'adjust-color' }
        ]
      }
    };
    return (
      <div className='trendChart'>
        <div className='headerNameTrend'>Induction Trend</div>
        <div style={{ display: 'flex', paddingBottom: '10px', fontWeight: '600' }}>
          <div style={{ textAlign: 'left' }}>
            Year
            <Select
              placeholder='select year'
              style={{
                width: 80,
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '5px',
                // border: '1px solid #d9d9d9'
                // backgroundColor: 'whitesmoke'
              }}
              getPopupContainer={(trigger) => trigger.parentNode}
              defaultValue={this.state.currentYear}
              //   value=''
              className='selectYear'
              onChange={(value) => {
                this.handleChange(value);
              }}>
              {optionsData}
            </Select>
          </div>
        </div>
        <div className='row'>
          <div className='mixed-chart'>
            {/* <Chart
              options={{
                chart: {
                  id: 'basic-bar',
                  toolbar: {
                    show:false
                  },
                },
                
                plotOptions: {
                  bar: {
                    columnWidth: '45%',
                    distributed: true,
                    rangeBarOverlap: true
                  }
                },
                dataLabels: {
                  enabled: true
                },
                legend: {
                  show: false
                },

                xaxis: {
                  type: 'category',
                  categories:(this.state.categories ? this.state.categories  : []),
                  labels: {
                    style: {
                      // colors: 'red',
                      fontSize: '12px'
                    }
                  }
                }
              }}
              series={this.state.series ? this.state.series : []}
              type='bar'
            /> */}
            <Column height={350} {...config} />
          </div>
        </div>
      </div>
    );
  }
}

export default InductionTrend;

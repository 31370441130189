import { ADD_GROWER_PENDING, ADD_GROWER_SUCCESS, ADD_GROWER_ERROR } from '../constants';

const initialState = {
  data: {
    growerSucess: {},
    growerError: {},
    completed: false
  }
};

function addedGrowerList(state = initialState, action) {
  switch (action.type) {
    case ADD_GROWER_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case ADD_GROWER_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          growerSucess: action.payload,
          completed: false
        }
      });
    case ADD_GROWER_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          growerError: action.payload
        }
      });
    default:
      return state;
  }
}
export default addedGrowerList;

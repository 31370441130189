import React, { useEffect, useState } from 'react';
import defaultConfig from '../regional_metadata/default.js';
import { getDefaultData } from '../regional_metadata/defaultData.js';
import { dataTable } from '../regional_metadata/default.js';
import { useTranslation } from 'react-i18next';
import { findCountryandEnvironment } from '../services/commonMethods.js';
// import { findCountryandEnvironment } from '../services/commonMethods.js';

export const RegionalMetaContext = React.createContext({});
const reqConfig = {
  inOld: require('../regional_metadata/inOld'),
  pk: require('../regional_metadata/pk'),
  idn: require('../regional_metadata/idn'),
  latam: require('../regional_metadata/latam'),
  th: require('../regional_metadata/th'),
  my: require('../regional_metadata/my'),
  ph: require('../regional_metadata/ph'),
  bd: require('../regional_metadata/bd'),
  kr: require('../regional_metadata/kr'),
  vn: require('../regional_metadata/vn'),
  in: require('../regional_metadata/in')
};

const RegionalMetaProvider = (props) => {
  const { t } = useTranslation();
  const [country, setCountry] = useState(null);
  const [data, setData] = useState({});
  useEffect(() => {
    const { host } = window.location;
    const countryHost = findCountryandEnvironment(host);
    console.log('countryHost>>', countryHost);
    setCountry(countryHost.country);
    if (countryHost.country === 'latam') {
      localStorage.setItem('i18nextLng', 'es');
    } else {
      localStorage.setItem('i18nextLng', 'en');
    }
    // const DefaultConfig = require('../regional_metadata/default.js');
    // const DefaultConfigJson = require('../regional_metadata/defaultData.js');
    const DefaultConfig = dataTable({ t: t });
    const DefaultConfigJson = getDefaultData({ t: t });
    const CountryConfig = reqConfig[countryHost.country];
    const CountryTest = CountryConfig && CountryConfig.getDefaultData({ t: t });
    const MergeData = Object.assign(DefaultConfig, DefaultConfigJson, CountryTest);
    setData(MergeData);
  }, []);

  return (
    <RegionalMetaContext.Provider value={{ country, data }}>
      {props.children}
    </RegionalMetaContext.Provider>
  );
};

const useRegionalMeta = () => {
  const context = React.useContext(RegionalMetaContext);

  if (context === undefined) {
    throw new Error(`useRegionalMeta must be used within a RegionalMetaContext`);
  }
  return context;
};

export { RegionalMetaProvider, useRegionalMeta };

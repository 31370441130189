import {
  FETCH_PIC_PENDING,
  FETCH_PIC_SUCCESSD,
  FETCH_PIC_ERROR,
  SESSION_TIMEOUT_ERROR
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchPicResultPending() {
  return {
    type: FETCH_PIC_PENDING
  };
}
export function fetchPicResultSuccess(result) {
  return {
    type: FETCH_PIC_SUCCESSD,
    payload: result
  };
}

export function fetchPicResultError(error) {
  return {
    type: FETCH_PIC_ERROR,
    payload: error
  };
}
export function sessionTimeoutError(value) {
  return {
    type: SESSION_TIMEOUT_ERROR,
    payload: value
  };
}
export const fetchPicResult = () => {
  const apiUri = formatUrl(config.dashboardPic);
  return (dispatch) => {
    dispatch(fetchPicResultPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchPicResultSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(fetchPicResultError(error));
      });
  };
};

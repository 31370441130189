import {
  GROWER_UPDATE_PENDING,
  GROWER_UPDATE_SUCCESS,
  GROWER_UPDATE_FAILURE,
  GROWER_UPDATE_LOADING,
  HIGHLIGHT_GROWER_TABEL_ROW,
  CREATE_GROWER_MODAL,
  CLEAR_GROWER_UPDATE
} from '../constants/index';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { downloadSourceResult } from '../actions/downloadSourceCount';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { fetchUsersWithFetchAPI } from '../services/growerListService';
import {
  fetchSearchResult,
  fetchSearchResultSuccess,
  fetchSearchResultUpdate
} from '../actions/advanceSearch';

import { handleErrors } from '../services/handleErrors';
import { formatUrl } from '../services/commonMethods';
import { defaultGrowers } from '../actions/growersAction';

import config from '../config';

export const growerUpdateLoading = (val) => {
  return { type: GROWER_UPDATE_LOADING, payload: val };
};

export const growerUpdatePending = (value) => {
  return {
    type: GROWER_UPDATE_PENDING,
    payload: value
  };
};
export const growerUpdateSuccess = (value) => {
  return {
    type: GROWER_UPDATE_SUCCESS,
    payload: value
  };
};

export const growerUpdateFailure = (error) => {
  return {
    type: GROWER_UPDATE_FAILURE,
    payload: error
  };
};
export const toggleGrowerModal = () => {
  return {
    type: CREATE_GROWER_MODAL
  };
};
export function highlightUpdateTableRow(rowStatus) {
  return {
    type: HIGHLIGHT_GROWER_TABEL_ROW,
    payload: rowStatus
  };
}
export const clearGrowerUpdate = () => {
  return {
    type: CLEAR_GROWER_UPDATE
  };
};

export const growerUpdate = (growerData) => {
  const apiUri = formatUrl(config.updateGrower);
  return (dispatch) => {
    dispatch(growerUpdatePending());
    dispatch(toggleSpinStatus());
    // dispatch(clearAlertState());
    return fetch(apiUri, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(growerData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          // dispatch(growerUpdatePending(false));
          dispatch(clearAlertState());
          dispatch(toggleSpinStatus());
          dispatch(growerUpdateSuccess(result));
          // dispatch(fetchSearchResultSuccess(growerData));
          dispatch(fetchSearchResultUpdate(growerData));
          dispatch(fetchUsersWithFetchAPI(0));
          dispatch(downloadSourceResult());
          // dispatch(fetchSearchResult(0));
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          if (result === false) {
            dispatch(toggleSpinStatus());
            dispatch(clearAlertState());
            return false;
          }
          // dispatch(growerUpdatePending(false));
          dispatch(toggleSpinStatus());

          dispatch(clearAlertState());
          dispatch(growerUpdateFailure(result));
          dispatch(
            errorNotification({
              type: 'error',
              message: result.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        }
      })
      .catch((e) => {
        if (response.status === 401) {
          return false;
        }
        // dispatch(growerUpdatePending(true));
        // dispatch(clearAlertState());
        dispatch(toggleSpinStatus());
        dispatch(growerUpdateFailure(e));

        dispatch(
          errorNotification({
            type: 'error',
            message: e.message,
            toggleAlert: true
          })
        );

        dispatch(clearAlertState());
      });
  };
};

import React from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { defaultGrowers, searchGrowersText } from '../../../actions/growersAction';
import _get from 'lodash.get';
import './findgrower.css';
import { withTranslation } from 'react-i18next';
import DrawerData from '../../commonComponents/TitleSection/advanceSearch';
import ListGrowerData from './List_Growers_DataTable';
import { fetchUsersWithFetchAPI } from '../../../services/growerListService';
import { downloadSourceResult } from '../../../actions/downloadSourceCount';
import { alertBox } from '../../commonComponents/Notification/alertBox';
import GlobalSpinner from '../../commonComponents/globalSpinner/globalSpinner';
import { RegionalMetaContext } from '../../../context/region';
import { findCountryandEnvironment } from '../../../services/commonMethods';
const { Content } = Layout;

class FindGrower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0
    };
  }

  componentDidMount() {
    const countryHostName = findCountryandEnvironment(window.location.host);
    if (countryHostName.country === 'idn') {
      this.props.fetchUsersWithFetchAPI(0);
      this.props.downloadSourceResult();
    } else this.props.fetchUsersWithFetchAPI(0);
  }

  setCurrentPage = (current) => {
    console.log('current');
    this.setState({
      currentPage: current
    });
  };
  render() {
    const { t } = this.props;
    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <Layout className='fingrowerLayout'>
            {data && data.advaceSearch && (
              <DrawerData
                {...this.props}
                filterData={data.advaceSearch.filterData}
                dropDownMenu={data.advaceSearch.dropDownMenu}
                defaulState={data.advaceSearch.defaulState}
                addNewFilter={data.advaceSearch.addNewFilter}
                country={country}
                searchZoneMessage={data.searchZoneMessage}
                searchCityMessage={data.searchCityMessage}
                searchAreaMessage={data.searchAreaMessage}
                searchTmhqMessage={data.searchTmhqMessage}
                searchCropHistoryMessage={data.searchCropHistoryMessage}
                searchSourceMessage={data.searchSourceMessage}
                searchHectaresMessage={data.searchHectaresMessage}
                downloadSourceResult={this.props.downloadSourceResult}
                resetPage={() => {
                  this.setState({
                    currentPage: 0
                  });
                }}
              />
            )}
            <Content className='mainContent' style={{ height: '85vh', overflow: 'auto' }}>
              <div className='listCompDiv'>
                <ListGrowerData
                  {...this.props}
                  setCurrentPage={this.setCurrentPage}
                  currentPage={this.state.currentPage}
                  growerFormData={data.growerDetailForm}
                  columns={data.growerListTable}
                  country={country}
                  data={data}
                  dividerHeight={data.dividerHeight}
                  hideSaveButton={data.hideSaveButton}
                  saveButtonPosition={data.saveButtonPosition}
                  seedVarityVisible={data.seedVarityVisible}
                  brandVisible={data.brandVisible}
                  productUsedLastSeasonVisible={data.productUsedLastSeasonVisible}
                  removeTags={data.removeTags}
                  tagVisible={data.tagVisible}
                  hideNewTag={data.hideNewTag}
                  optInOutVisible={data.optInOutVisible}
                  resetPage={() => {
                    this.setState({
                      currentPage: 0
                    });
                  }}
                />{' '}
              </div>
            </Content>
            {this.props.toggleAlert
              ? alertBox({
                  message: this.props.alertMessage,
                  type: this.props.alertType
                })
              : null}
            <GlobalSpinner />
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
// export default FindGrower;
const mapDispatchToProps = function(dispatch) {
  return {
    searchGrowersText(text) {
      dispatch(searchGrowersText(text));
    },
    defaultGrowers(pagenum, text) {
      dispatch(defaultGrowers(pagenum, text));
    },
    downloadSourceResult: () => dispatch(downloadSourceResult()),
    fetchUsersWithFetchAPI: (pagenum) => dispatch(fetchUsersWithFetchAPI(pagenum))
  };
};
// export default OnBoard;
const mapStateToProps = function(state) {
  return {
    searchResult: _get(state, 'serachList.data.advanceSearchList.items', []),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', '')
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FindGrower));

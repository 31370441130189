import {
  UPLOAD_GROWER_PENDING,
  UPLOAD_GROWER_SUCCESS,
  UPLOAD_GROWER_ERROR
} from '../constants/index';

export const fetchGrowerWithFetchAPIAsnc = (val) => {
  return { type: 'GROWER_LIST', value: val };
};

export const upadteLoadingStatus = (val) => {
  return { type: 'GROWER_LOADING', payload: val };
};

export const uploadGrowerPending = () => {
  return {
    type: UPLOAD_GROWER_PENDING
  };
};
export const uploadGrowerSuccess = (value) => {
  return {
    type: UPLOAD_GROWER_SUCCESS,
    payload: value
  };
};

export const uploadGrowerError = (error) => {
  return {
    type: UPLOAD_GROWER_ERROR,
    payload: error
  };
};

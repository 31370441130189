import axios from 'axios';
import {
  SCHEDULE_MMS_UPDATE_PENDING,
  SCHEDULE_MMS_UPDATE_SUCCESS,
  SCHEDULE_MMS_UPDATE_ERROR,
  CLEAR_SCHEDULE_MMS_UPDATE_STATUS,
  TOGGLE_DRAWER_STATUS
} from '../constants';

import { scheduleMessageListService } from '../actions/scheduleMessageList';
import { toggleScheduleMessageDrawer } from '../actions/scheduleMessageUpdate';
import { toggleDrawer, highlightMessageTableRow } from './messageSending';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
export function scheduleMMSUpdatePending() {
  return {
    type: SCHEDULE_MMS_UPDATE_PENDING
  };
}
export function scheduleMMSUpdateSuccess(searchList) {
  return {
    type: SCHEDULE_MMS_UPDATE_SUCCESS,
    payload: searchList
  };
}

export function clearScheduleMMSUpdate() {
  return {
    type: CLEAR_SCHEDULE_MMS_UPDATE_STATUS
  };
}
export function scheduleMMSUpdateError(error) {
  return {
    type: SCHEDULE_MMS_UPDATE_ERROR,
    payload: error
  };
}

export const scheduleMMSUpdate = (formData, userId, messageId, pageNumber) => {
  const apiUri = formatUrl(config.updateScheduleMMS, messageId);

  return (dispatch) => {
    dispatch(scheduleMMSUpdatePending());
    dispatch(toggleSpinStatus());
    axios
      .put(apiUri, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'temp-created-by': userId,
          Authorization: 'Bearer ' + config.userToken
        }
      })
      .then((result) => {
        if (result && result.data.code === '200') {
          dispatch(scheduleMessageListService(0));
          dispatch(scheduleMMSUpdatePending());
          dispatch(toggleSpinStatus());
          // if (result) {
          dispatch(toggleScheduleMessageDrawer());
          dispatch(clearAlertState());
          dispatch(scheduleMMSUpdateSuccess(result));

          //   dispatch(toggleSpinStatus());

          //   dispatch(highlightMessageTableRow(true));
          // dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: result.data.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
          //  dispatch(toggleSpinStatus());
        } else {
          dispatch(scheduleMMSUpdatePending());
          dispatch(toggleSpinStatus());
          dispatch(toggleScheduleMessageDrawer());
          dispatch(scheduleMMSUpdateError(result));
          dispatch(clearAlertState());

          dispatch(
            errorNotification({
              type: 'error',
              message: result.data.message,
              toggleAlert: true
            })
          );

          dispatch(clearAlertState());
        }
      })
      .catch((error) => {
        console.log('error>', error);

        dispatch(scheduleMMSUpdatePending());
        dispatch(toggleSpinStatus());
        dispatch(toggleScheduleMessageDrawer());

        dispatch(scheduleMMSUpdateError(error));
        dispatch(clearAlertState());
        // dispatch(toggleSpinStatus());
        dispatch(
          errorNotification({
            type: 'error',
            message: 'Message Sending Failed',
            toggleAlert: true
          })
        );
      });
  };
};

import {
  FETCH_REGION_CROP_PENDING,
  FETCH_REGION_CROP_SUCCESS,
  FETCH_CROP_SUCCESS,
  FETCH_ZONE_SUCCESS,
  FETCH_AREA_SUCCESS,
  FETCH_REGION_CROP_ERROR,
  FETCH_TAGS_SUCCESS,
  FETCH_FRANCHISE_CODE_SUCCESS,
  FETCH_CROP_HISTORY_SUCCESS,
  FETCH_PIC_SUCCESS,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_SOURCE_SUCCESS,
  FETCH_CITY_SUCCESS,
  FETCH_DISTRICT_SUCCESS,
  FETCH_RETAILER_NAME_SUCCESS,
  FETCH_RETAILER_CODE_SUCCESS,
  FETCH_BRAND_SUCCESS,
  FETCH_STOCKIST_CODE_SUCCESS,
  FETCH_LAND_SIZE_SUCCESS,
  FETCH_MOBILE_NUMBER_SUCCESS,
  FETCH_ACARES_SUCCESS,
  FETCH_TMHQ_SUCCESS,
  FETCH_CHANNEL_SUCCESS,
  FETCH_CLASSIFICATION_SUCCESS,
  FETCH_SEGMENT_SUCCESS,
  FETCH_PROVINCE_SUCCESS,
  FETCH_PRAMUKHTAG_SUCCESS,
  FETCH_STATE_SUCCESS,
  FETCH_VILLAGE_SUCCESS,
  FETCH_TALUKA_SUCCESS,
  FETCH_MDOHQ_SUCCESS,
  FETCH_TVC_SUCCESS,
  FETCH_WA_SUCCESS,
  FETCH_FB_SUCCESS,
  FETCH_YB_SUCCESS,
  FETCH_IG_SUCCESS,
  FETCH_SYT_GROWER_CORN_SUCCESS,
  FETCH_SYT_GROWER_RICE_SUCCESS,
  FETCH_PREFFERED_HYBRID_CORN_SUCCESS,
  FETCH_PREFFERED_HYBRID_RICE_SUCCESS,
  FETCH_SEASON_SEGEMENTATION_SUCCESS,
  FETCH_FACEBOOK_SUCCESS,
  FETCH_ZALO_SUCCESS,
  FETCH_SYNGENTA_PRODUCT_SUCCESS,
  FETCH_ACTIVITITY_TYPE_SUCCESS
} from '../constants/index';

const initialState = {
  data: {
    groupRegion: {},
    groupCrops: {},
    groupZone: {},
    groupArea: {},
    groupTags: {},
    groupAcares: {},
    groupFranchiseCode: {},
    groupCropHistory: {},
    groupPic: {},
    groupLanguage: {},
    groupSource: {},
    groupCity: {},
    groupDistrict: {},
    groupRetailerName: {},
    groupRetailerCode:{},
    groupBrand: {},
    groupStockistCode: {},
    groupLandSize: {},
    groupMobileNumber: {},
    groupTmhq: {},
    groupChannel: {},
    groupClassification: {},
    groupSegment: {},
    groupProvince: {},
    groupPramukhTag: {},
    groupState: {},
    groupVillage: {},
    groupTaluka: {},
    groupMdohq: {},
    groupTVC: {},
    groupWA: {},
    groupFB: {},
    groupYB: {},
    groupIG: {},
    groupFacebook: {},
    groupZalo:{},
    groupSyngentaProduct: {},
    groupSytGrowerCorn: {},
    groupSytGrowerRice: {},
    groupPrefferedCorn: {},
    groupPrefferedRice: {},
    groupSeasonSegementation: {},
    groupActivitiesType:{},
    pending: false
  }
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_REGION_CROP_PENDING:
      return {
        data: {
          ...state.data,
          pending: true
        }
      };
    case FETCH_REGION_CROP_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupRegion: action.payload
        }
      });
    case FETCH_CROP_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupCrops: action.payload
        }
      });
    case FETCH_ZONE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupZone: action.payload
        }
      });
    case FETCH_AREA_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupArea: action.payload
        }
      });
    case FETCH_ACARES_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupAcares: action.payload
        }
      });
    case FETCH_TAGS_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupTags: action.payload
        }
      });
    case FETCH_FRANCHISE_CODE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupFranchiseCode: action.payload
        }
      });
    case FETCH_REGION_CROP_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          error: action.payload
        }
      });
    case FETCH_CROP_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupCropHistory: action.payload
        }
      });
    case FETCH_PIC_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupPic: action.payload
        }
      });
    case FETCH_LANGUAGE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupLanguage: action.payload
        }
      });
    case FETCH_SOURCE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupSource: action.payload
        }
      });
    case FETCH_CITY_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupCity: action.payload
        }
      });
    case FETCH_DISTRICT_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupDistrict: action.payload
        }
      });
    case FETCH_RETAILER_NAME_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupRetailerName: action.payload
        }
      });
      case FETCH_RETAILER_CODE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupRetailerCode: action.payload
        }
      });
    case FETCH_BRAND_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupBrand: action.payload
        }
      });
    case FETCH_STOCKIST_CODE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupStockistCode: action.payload
        }
      });
    case FETCH_LAND_SIZE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupLandSize: action.payload
        }
      });
    case FETCH_MOBILE_NUMBER_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupMobileNumber: action.payload
        }
      });
    case FETCH_TMHQ_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupTmhq: action.payload
        }
      });
    case FETCH_CHANNEL_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupChannel: action.payload
        }
      });
    case FETCH_CLASSIFICATION_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupClassification: action.payload
        }
      });

    case FETCH_SEGMENT_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupSegment: action.payload
        }
      });
    case FETCH_PROVINCE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupProvince: action.payload
        }
      });
    case FETCH_PRAMUKHTAG_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupPramukhTag: action.payload
        }
      });
    case FETCH_STATE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupState: action.payload
        }
      });
    case FETCH_VILLAGE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupVillage: action.payload
        }
      });
    case FETCH_TALUKA_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupTaluka: action.payload
        }
      });
    case FETCH_MDOHQ_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupMdohq: action.payload
        }
      });
    case FETCH_TVC_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupTVC: action.payload
        }
      });
    case FETCH_WA_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupWA: action.payload
        }
      });
    case FETCH_FB_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupFB: action.payload
        }
      });
    case FETCH_YB_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupYB: action.payload
        }
      });
    case FETCH_IG_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupIG: action.payload
        }
      });
      case FETCH_FACEBOOK_SUCCESS:
        return Object.assign({}, state, {
          data: {
            ...state.data,
            pending: false,
            groupFacebook: action.payload
          }
        });
        
        case FETCH_ZALO_SUCCESS:
          return Object.assign({}, state, {
            data: {
              ...state.data,
              pending: false,
              groupZalo: action.payload
            }
          });
        case FETCH_SYNGENTA_PRODUCT_SUCCESS:
          return Object.assign({}, state, {
            data: {
              ...state.data,
              pending: false,
              groupSyngentaProduct: action.payload
            }
          });
    case FETCH_SEASON_SEGEMENTATION_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupSeasonSegementation: action.payload
        }
      });
    case FETCH_SYT_GROWER_CORN_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupSytGrowerCorn: action.payload
        }
      });
    case FETCH_SYT_GROWER_RICE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupSytGrowerRice: action.payload
        }
      });
    case FETCH_PREFFERED_HYBRID_CORN_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupPrefferedCorn: action.payload
        }
      });
    case FETCH_PREFFERED_HYBRID_RICE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          pending: false,
          groupPrefferedRice: action.payload
        }
      });
      case FETCH_ACTIVITITY_TYPE_SUCCESS:
        return Object.assign({}, state, {
          data: {
            ...state.data,
            pending: false,
            groupActivitiesType: action.payload
          }
        });
    default:
      return state;
  }
};

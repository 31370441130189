import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translation } from 'react-i18next';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { Layout, Typography, Card, Row, Col } from 'antd';
/*** Ant Design Components */
import './report.css';
import { allGrowersCount } from '../../actions/allGrowerCount';
// import ComminSoon from "../ComingSoon/Comingsoon";
import graph5 from '../../assets/img/reports/graph5.jpg';
import graph2 from '../../assets/img/reports/graph2.jpg';
import graph3 from '../../assets/img/reports/graph3.jpg';
import graph4 from '../../assets/img/reports/graph4.jpg';
import graph6 from '../../assets/img/reports/graph6.jpg';

const { Content, Header } = Layout;
const { Title } = Typography;
// const gridStyle = {
//   width: "25%",
//   textAlign: "center"
// };
class Report extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.allGrowersCount();
  }
  render() {
    return (
      <Layout className='reportLayout'>
        <Header className='webstoreTitleDiv'>
          <Title level={4} style={{ color: '#3666c4', fontWeight: 'bold' }}>
            <Translation>{(t) => <>{t('Syngenta Analytics Dashboard')}</>}</Translation>
          </Title>
        </Header>
        <Content className='reportMainContent'>
          <Card bordered={false} className='reportCard'>
            <Row>
              <Col span={18}>
                <Card
                  bordered={true}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  className='counterCard'>
                  <Row style={{ width: '100%', padding: '0 10px' }}>
                    <Col span={12} style={{ textAlign: 'start' }}>
                      <Title
                        level={4}
                        style={{
                          color: '#238661',
                          fontWeight: 'bold'
                        }}>
                        <Translation>{(t) => <>{t('Grower Connect Users')}</>}</Translation>
                      </Title>
                    </Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                      <div className='webstoreRightLink'>
                        <Title level={4} style={{ color: '#238661', fontWeight: 'bold' }}>
                          {this.props.GrowersCount}
                        </Title>
                        <Link to='/find-grower' className='viewDetailsEngage'>
                          <span>
                            <Translation>{(t) => <>{t('View Details')}</>}</Translation>
                          </span>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <Card
                  bordered={false}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  cover={<img alt='example' src={graph2} />}
                />
                <Card
                  bordered={false}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  cover={<img alt='example' src={graph6} />}
                />
              </Col>
              <Col span={6}>
                <Card
                  bordered={false}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  cover={<img alt='example' src={graph5} />}
                />
                <Card
                  bordered={false}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  cover={<img alt='example' src={graph4} />}
                />
                <Card
                  bordered={false}
                  style={{ margin: '20px' }}
                  hoverable={false}
                  cover={<img alt='example' src={graph3} />}
                />
              </Col>
            </Row>
          </Card>
        </Content>
        {/* <ComminSoon /> */}
      </Layout>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    allGrowersCount: () => dispatch(allGrowersCount())
  };
};

const mapStateToProps = function(state) {
  return {
    GrowersCount: _get(state, 'allGrowersCountReducer.allGrowersCount.growerCount', 0)
  };
};
Report.propTypes = {
  allGrowersCount: PropTypes.func,
  GrowersCount: PropTypes.number
};
export default connect(mapStateToProps, mapDispatchToProps)(Report);

import React, { Component } from 'react';
import { Icon, Card } from 'antd';
import CountUp from 'react-countup';

class GrowerDetails extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t, country } = this.props;

    return (
      <Card className='countCard'>
        <span className='growerDetails'>{t(` ${this.props.title.replace(/_/g, ' ')}`)}</span>
        <p className='numberStyle'>
          <CountUp
            start={0}
            end={this.props.numbers || 0}
            duration={2.75}
            useEasing
            useGrouping
            separator=','
          />
        </p>
      </Card>
    );
  }
}
export default GrowerDetails;

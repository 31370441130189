import {
  SEARCH_GROWERS_PENDING,
  SEARCH_GROWERS_SUCCESS,
  SEARCH_GROWERS_FAILURE,
  SEARCH_GROWERS_LOADING,
  FETCH_GROWER_LOADING,
  FETCH_GROWER_SUCCESS,
  FETCH_GROWER_ERROR,
  FETCH_GROWER_PENDING,
  SEARCH_GROWERS_TEXT
} from '../constants/index';
import { fetchUsersWithFetchAPIAsnc } from '../actions/growerList';
import { toggleSpinStatus } from './globalSpinner';
import { handleErrors } from '../services/handleErrors';
import { formatUrl } from '../services/commonMethods';

import config from '../config';

export const searchGrowersLoading = (val) => {
  return { type: SEARCH_GROWERS_LOADING, payload: val };
};

export const searchGrowersPending = (value) => {
  return {
    type: SEARCH_GROWERS_PENDING,
    payload: value
  };
};
export const searchGrowersSuccess = (value) => {
  return {
    type: SEARCH_GROWERS_SUCCESS,
    payload: value
  };
};

export const searchGrowersFailure = (error) => {
  return {
    type: SEARCH_GROWERS_FAILURE,
    payload: error
  };
};
export const searchGrowersText = (value) => {
  return {
    type: SEARCH_GROWERS_TEXT,
    payload: value
  };
};

export const defaultGrowersLoading = (val) => {
  return {
    type: FETCH_GROWER_LOADING,
    payload: val
  };
};

export const defaultGrowersPending = (value) => {
  return {
    type: FETCH_GROWER_PENDING,
    payload: value
  };
};
export const defaultGrowersSuccess = (value) => {
  return {
    type: FETCH_GROWER_SUCCESS,
    payload: value
  };
};

export const defaultGrowersFailure = (error) => {
  return {
    type: FETCH_GROWER_ERROR,
    payload: error
  };
};

export const highLightGrowerTableRow = (value) => {
  return {
    type: HIGHLIGHT_GROWER_TABEL_ROW,
    payload: value
  };
};

export const defaultGrowers = (pageNum, name = '') => {
  let apiUri;
  if (name.length > 0) {
    apiUri = formatUrl(config.growerListSearch, pageNum, name, 'created', '10');
  } else {
    apiUri = formatUrl(config.growerListSearch, pageNum, name, '-created', '10');
  }
  return (dispatch) => {
    dispatch(defaultGrowersPending(true));
    dispatch(defaultGrowersLoading(true));
    // dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchUsersWithFetchAPIAsnc(result));

        // dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(defaultGrowersFailure(e));
        // dispatch(toggleSpinStatus());
      });
  };
};

export const growersByGroupId = (pageNum, groupId) => {
  let apiUri;

  apiUri = formatUrl(config.growerMembers, groupId, pageNum, '10', ['-updated', '-created']);

  return (dispatch) => {
    dispatch(defaultGrowersPending(true));
    dispatch(defaultGrowersLoading(true));
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(defaultGrowersPending(false));
          dispatch(fetchUsersWithFetchAPIAsnc(result));
          dispatch(highLightGrowerTableRow(true));
          dispatch(defaultGrowersLoading(false));
          dispatch(toggleSpinStatus());
        }
      })
      .catch((e) => {
        dispatch(defaultGrowersLoading(false));
        dispatch(defaultGrowersPending(true));
        dispatch(defaultGrowersFailure(e));
        dispatch(toggleSpinStatus());
      });
  };
};

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchBrand } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchBrand extends React.Component {
  constructor(props) {
    super(props);
    this.childrenBrand = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchBrand();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { searchCityMessage, t } = this.props;
    let brandData = this.props.groupBrand || [];
    let brandText = brandData.length > 0 ? brandData[0].items : [];
    this.childrenBrand = brandText.map((brand) => <Option key={brand}>{brand}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.brandValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: t('Please select some brand')
            }
          ]
        })(
          <Select
            name='brand'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Brand')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenBrand}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchBrand: () => dispatch(fetchBrand())
  };
};
const mapStateToProps = function(state) {
  return {
    groupBrand: get(state, 'groupFilterReducer.data.groupBrand.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchBrand);

import React, { Component } from 'react';
import { Input, Form, Select, Button, Modal, Card, Avatar, Icon, Image } from 'antd';
const { Meta } = Card;
import PropTypes from 'prop-types';
import './secondStep.css';
import SelectMMS from './mms';
import whatsappImg from '../../../../../../assets/icons/flagIcons/ind.png';
// import { Button } from 'react-scroll';
const { TextArea } = Input;
const Option = Select.Option;
const FormItem = Form.Item;
class Second extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textEditing: false,
      isEnabled: true,
      visible: false,
      templateAccessName: ''
    };
  }

  handleChange = () => {
    this.props.form.validateFields((error, value) => {
      this.setState(
        { templateAccessName: value.template, textEditing: true, isEnabled: false },
        () => {
          this.props.fetchWhatsAppTemplateData(this.state.templateAccessName);
        }
      );

      if (!error) {
        const mdata = {
          channels: [value.SocialPlatform],
          groupId: value.groupType
        };
      }
    });
  };
  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleClick = () => {
    this.showModal();
  };
  render() {
    const { t, templateList, messageType, templateListContent } = this.props;
    console.log('messageType>', messageType);
    const templateName =
      templateList && templateList.length
        ? templateList.map((item) => <Option value={item.name}>{item.name}</Option>)
        : '';
    const { getFieldDecorator } = this.props.form;
    const loginPageImage = {
      idnImage: require('../../../../../../assets/img/facebook-page-icon.png')
    };
    return (
      <div className='thirdDiv'>
        {messageType === 'WHATSAPP' ? (
          <FormItem className='urlLabel'>
            {getFieldDecorator('template', {
              validateTrigger: ['onChange', 'onBlur'],
              rules: [{ required: true, message: t('Please select whatsApp template') }]
            })(
              <Select
                placeholder={t('Select WhatsApp Template')}
                onSelect={this.handleChange}
                style={{ width: '74%' }}
                className='messagegroupType'
                showSearch>
                {templateName}
              </Select>
            )}
            <Button
              style={{ left: '10px' }}
              onClick={this.handleClick}
              disabled={Object.keys(templateListContent).length === 0}>
              Preview
            </Button>
          </FormItem>
        ) : this.props.editData.Platform === 'MMS' ? (
          <SelectMMS form={this.props.form} {...this.props} />
        ) : (
          <>
            <p>
              Type your message(maximum {this.props.countryData === 'kr' ? '45' : '500'} characters)
            </p>
            <FormItem>
              {getFieldDecorator('mesageTxt', {
                initialValue: this.props.editData.message || '',
                rules: [
                  {
                    required: true,
                    // this.state.isEnabled || this.props.messageType === 'WHATSAPP' ? false : true,
                    message: t('Please enter a valid message'),
                    whitespace: true
                  }
                ]
              })(
                <TextArea
                  maxLength={this.props.countryData === 'kr' ? '45' : '500'}
                  rows={5}
                  // disabled={this.state.textEditing}
                  autoFocus={false}
                />
              )}
            </FormItem>
          </>
        )}

        <>
          <Modal
            title='WhatsApp Preview'
            visible={this.state.visible}
            footer={false}
            onCancel={this.handleCancel}
            style={{ width: '420px', float: 'right', paddingLeft: '90px', top: '30px' }}>
            <img
              src={this.props.templateListContent.previewUrl}
              alt={this.props.templateListContent.name}
            />
          </Modal>
        </>
      </div>
    );
  }
}
Second.propTypes = {
  getFieldDecorator: PropTypes.object,
  form: PropTypes.any
};
export default Second;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card, Row, Col, Button } from 'antd';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { withTranslation } from 'react-i18next';
import { GrowerMetricsStyle } from './growerMetricsStyle';
import TitleSection from '../commonComponents/TitleSection/titleSection';
import InductionTrend from './inductionTrend';
import BizReach from './bizChart';
import RecentCampigns from './campaigns';
import CountDetails from './growerDetails';
import PieChart from './pieChart';
import IdnPieChart from './idnPieChart';
import { fetchDashboardResult } from '../../actions/dashboard';
import { fetchUpdateStatistics } from '../../actions/updateStatistics';
import { fetchSalesUnitResult } from '../../actions/salesUnit';
import { getMessageCountList } from '../../actions/messageCountList';
import { fetchPicResult } from '../../actions/pic';
import { fetchBrandResult } from '../../actions/brand';
import { alertBox } from '../commonComponents/Notification/alertBox';
import { RegionalMetaContext } from '../../context/region';
import { findCountryandEnvironment } from '../../services/commonMethods';
import moment from 'moment';
 import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import GrowerReach from './growerReach';
const { Meta } = Card;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: new Date().getFullYear(),
      disableRefresh: false,
      startDate: '2017-01-01',
      endDate: moment(new Date()).format('YYYY-MM-DD')
    };
    this.child = React.createRef();
  }
  createReachData = (data) => {
    let timeStampSms = [];
    let countSms = [];
    let timeStamp = [];
    let dataTime = [];
    let count = [];
    var newDateArray = [];
    const key = Object.keys(data);
    const value = Object.values(data);
    data && data.SMS
      ? data.SMS.forEach((item) => {
          timeStampSms.unshift(item.timeStamp);
          countSms.unshift(item.count);
        })
      : [];
    data && data.WHATSAPP
      ? data.WHATSAPP.forEach((item) => {
          timeStamp.unshift(item.timeStamp);
          count.unshift(item.count);
        })
      : [];
    let dateTime = [...timeStampSms, ...timeStamp];
    const getCountryData = Object.entries(data).filter(([key, value]) => {
      return key == this.state.selectedCountry;
    });
    var reducedSms =
      data && data.SMS
        ? data &&
          data.SMS.map((sms) => ({
            name: 'SMS',
            month: moment(new Date(sms.timeStamp)).format('MMM YY'),
            data: sms.count
          }))
        : '';
    var reducedWhatsApp =
      data && data.WHATSAPP
        ? data &&
          data.WHATSAPP.map((sms) => ({
            name: 'WHATSAPP',
            month: moment(new Date(sms.timeStamp)).format('MMM YY'),
            data: sms.count
          }))
        : '';
    var reducedMMS =
      data && data.MMS
        ? data &&
          data.MMS.map((sms) => ({
            name: 'MMS',
            month: moment(new Date(sms.timeStamp)).format('MMM YY'),
            data: sms.count
          }))
        : '';
    for (let i of dateTime) {
      let date = moment(new Date(i)).format('MMM YY');

      newDateArray.push(date);
    }
    return {
      // series: [
      //   { name: 'SMS', data: countSms },
      //   { name: 'WHATSAPP', data: count }
      // ],
      series: [...reducedSms, ...reducedWhatsApp],
      categories: [...newDateArray],
      labels: key
    };
  };
  createInductedData = (data) => {
    let timeStamp = [];
    let dataTime = [];
    let count = [];
    var newDateArray = [];
    const key = Object.keys(data);
    const value = Object.values(data);
    data.length
      ? data.forEach((item) => {
          timeStamp.unshift(item.timeStamp);
          count.unshift(item.count);
        })
      : [];
    let dateTime = [...timeStamp];

    for (let i of dateTime) {
      let date = moment(new Date(i)).format('MMM YY');
      newDateArray.push(date);
    }
    return {
      series: [
        {
          name: 'count',
          data: count
        }
      ],
      categories: [...newDateArray],
      labels: key
    };
  };
  createSeriesData = (data, obj) => {
    let key;
    let value;
    if (obj === 'area' || obj === 'land_size') {
      key = Object.keys(data);
      value = Object.values(data);
    } else {
      key = Object.keys(data).sort(function(a, b) {
        return data[b] - data[a];
      });
      value = Object.values(data).sort(function(a, b) {
        return b - a;
      });
    }
    return {
      series: value,
      labels: key
    };
  };
  createBarSeriesData = (data, props) => {
    var months = [
      props.t('January'),
      props.t('February'),
      props.t('March'),
      props.t('April'),
      props.t('May'),
      props.t('June'),
      props.t('July'),
      props.t('August'),
      props.t('September'),
      props.t('October'),
      props.t('November'),
      props.t('December')
    ];

    const sortObject = (obj) => {
      var arr = [];
      var prop;
      for (prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          arr.push({
            key: prop,
            value: obj[prop]
          });
        }
      }
      arr.sort(function(a, b) {
        return months.indexOf(a.key) - months.indexOf(b.key);
      });
      return arr; // returns array
    };
    const sortData = sortObject(data);
    const key = sortData.map((entries) => entries.key);
    const value = sortData.map((entries) => entries.value);

    return {
      series: [
        {
          name: 'data',
          data: value
        }
      ],
      labels: key
    };
  };
  createTableData = (data) => {
    const key = Object.keys(data);
    const value = Object.values(data);
    return {
      series: [{ current: data }],
      labels: key
    };
  };
  componentDidMount = () => {
    const countryHostName = findCountryandEnvironment(window.location.host);
    if (countryHostName.country === 'bd') {
      this.props.fetchDashboardResult();
      this.props.fetchBrandResult();
    } else if (countryHostName.country === 'th') {
      this.props.fetchDashboardResult();
      this.props.fetchSalesUnitResult();
    } else if (countryHostName.country === 'my') {
      this.props.fetchDashboardResult();
      this.props.fetchPicResult();
    } else if (countryHostName.country === 'idn') {
      this.props.getMessageCountList(this.state.startDate, this.state.endDate);
      this.props.fetchDashboardResult();
    } else this.props.fetchDashboardResult();
  };
  componentWillReceiveProps = (nextProps) => {
    if (this.props.updateRefreshResult !== nextProps.updateRefreshResult) {
      this.setState({ disableRefresh: false });
    }
  };
  capitalize = (str) => {
    var i,
      splitValues = str.split('_');
    for (i = 0; i < splitValues.length; i++) {
      splitValues[i] = splitValues[i].charAt(0).toUpperCase() + splitValues[i].slice(1);
    }
    return splitValues.join(' ');
    // return str[0].toUpperCase() + str.slice(1);
  };
  updateStatistics = () => {
    if (this.state.disableRefresh) {
      return;
    }
    this.setState({
      disableRefresh: true
    });
    this.props.fetchUpdateStatistics();
    // this.props.fetchDashboardResult();
  };
  getPaiChart = (country) => {
    switch (country) {
      case country === 'th':
        return salesUnitCard;
      case country === 'picCard':
        return salesUnitCard;
      case country === 'ph':
        return phDistributionCard;
      case country === 'bd':
        return bdDistributionCard;
      case country === 'in':
        return inDistributionCard;
      case country === 'vn':
        return vnDistributionCard;

      default:
        return paiChartCards;
    }
  };
  render() {
    const { t } = this.props;

    const title = t('Dashboard');
    const {
      growerDistribution,
      growerInducted,
      growerCampaign,
      counts,
      whatsappCounts,
      monthlyReact,
      salesUnitDistribution,
      monthlyInductedGrower,
      picDistribution,
      phDistribution,
      bdDistribution,
      idnDistribution,
      krDistribution,
      inDistribution,
      vnDistribution,
      getMessageCount
    } = this.props;
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3
    };

    const picCard = Object.entries(picDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const inDistributionCard = Object.entries(inDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(
            key === 'area'
              ? `Grower Acreage Distribution `
              : `Grower Distribution by ${this.capitalize(key)}`
          )}
        />
      </Col>
    ));
    const salesUnitCard = Object.entries(salesUnitDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const phDistributionCard = Object.entries(phDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const bdDistributionCard = Object.entries(bdDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const idnDistributionCard = Object.entries(idnDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const krDistributionCard = Object.entries(krDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const vnDistributionCard = Object.entries(vnDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(`Grower Distribution by ${this.capitalize(key)}`)}
        />
      </Col>
    ));
    const idnCropDistributionCard = Object.entries(this.props.idnCropPieChart).map(
      ([key, value]) => (
        <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
          <IdnPieChart
            values={this.createSeriesData(value, key)}
            index={key}
            title={t(`Grower Distribution by ${this.capitalize(key)}`)}
          />
        </Col>
      )
    );
    const paiChartCards = Object.entries(growerDistribution).map(([key, value]) => (
      <Col key={key} lg={8} md={12} style={{ marginBottom: '20px' }}>
        <PieChart
          values={this.createSeriesData(value, key)}
          index={key}
          title={t(
            `Grower Distribution by ${
              this.props.country === 'ph' && this.key === 'city' ? 'Province' : this.capitalize(key)
            }`
          )}
        />
      </Col>
    ));

    const countCards = Object.entries(counts).map(([key, value]) => (
      <Col key={key} lg={6} md={12} style={{ marginBottom: '20px' }}>
        <CountDetails numbers={Number(value)} index={key} title={key} {...this.props} />
      </Col>
    ));

    const whatsappCountCard = Object.entries(whatsappCounts).map(([key, value]) => (
      <Col key={key} lg={6} md={12} style={{ marginBottom: '20px' }}>
        <CountDetails numbers={Number(value)} index={key} title={key} {...this.props} />
      </Col>
    ));
    const tableGraphCards = this.createTableData(growerCampaign);
    const barGraphCards = this.createBarSeriesData(growerInducted, this.props);
    const growerReach = this.createReachData(monthlyReact);
    const monthlyInducted = this.createInductedData(monthlyInductedGrower);
    return (
      <RegionalMetaContext.Consumer>
        {({ data, country }) => (
          <GrowerMetricsStyle className='MetricsLayout'>
            <div>
              <TitleSection title={title} style={{ width: 'fitContent' }} />
              <Button
                className='updateButton'
                disabled={this.state.disableRefresh}
                onClick={this.updateStatistics}>
                {/* {t('Refresh')} */}
                {this.state.disableRefresh ? 'Refreshing...' : 'Refresh'}
              </Button>
              {this.props.toggleAlert
                ? alertBox({
                    message: this.props.alertMessage,
                    type: this.props.alertType
                  })
                : null}
            </div>
            <Row
              gutter={24}
              style={{
                paddingTop: '10px',
                marginLeft: '5px'
              }}>
              {/* {country !== 'th' ? paiChartCards : salesUnitCard} */}
              {country === 'th' ? (
                <>
                  <style>{cssstyle}</style>
                  <Slider {...settings}>
                  {salesUnitCard}
                  </Slider>
                </>
              ) : (
                ''
              )}
              {country !== 'th' ? eval(data.paiChart) : ''}
              {country == 'idn' ? eval(data.idnCropchart) : ''}
              {/* {this.getPaiChart(country)} */}
            </Row>
            <Row
              gutter={24}
              style={{
                marginLeft: '5px'
              }}>
              {countCards}
              {/* {country === 'idn' ? whatsappCountCard : ''} */}
              <Col lg={12} md={24} style={{ paddingBottom: '20px' }}>
                <Card bordered={false}>
                  <BizReach growerReach={monthlyReact} country={country} />
                </Card>
              </Col>
              <Col lg={12} md={24} style={{ paddingBottom: '20px' }}>
                <Card bordered={false}>
                  {/* {this.props.monthlyInductedGrower.length  ? */}
                  <InductionTrend monthlyInducted={this.props.monthlyInductedGrower} />
                  {/* : ''} */}
                </Card>
              </Col>
            </Row>
          </GrowerMetricsStyle>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchDashboardResult: () => dispatch(fetchDashboardResult()),
    fetchUpdateStatistics: () => dispatch(fetchUpdateStatistics()),
    fetchSalesUnitResult: () => dispatch(fetchSalesUnitResult()),
    fetchPicResult: () => dispatch(fetchPicResult()),
    fetchBrandResult: () => dispatch(fetchBrandResult()),
    getMessageCountList: (startDate, endDate) => dispatch(getMessageCountList(startDate, endDate))
  };
};

const mapStateToProps = function(state) {
  return {
    growerDistribution: _get(state, 'dashboardList.data.dashboardResult.growerDistribution', {}),
    updateRefreshResult: _get(state, 'updateStatisticsData.data.updateResult', {}),
    alertMessage: _get(state, 'alertMessage.alertMessage', ''),
    alertType: _get(state, 'alertMessage.alertType', ''),
    toggleAlert: _get(state, 'alertMessage.toggleAlert', true),
    growerInducted: _get(
      state,
      'dashboardList.data.dashboardResult.sortedMonthlyInductedGrowers',
      []
    ),
    growerCampaign: _get(
      state,
      'dashboardList.data.dashboardResult.recentAgroServiceCampaigns',
      {}
    ),
    counts: {
      Total_Growers_Inducted: _get(
        state,
        'dashboardList.data.dashboardResult.totalGrowerCount',
        {}
      ),
      Total_Growers_Inducted_This_Month: _get(
        state,
        'dashboardList.data.dashboardResult.RecentInductedGrowers',
        {}
      ),
      // Agronomy_Services_Sent: _get(
      //   state,
      //   'dashboardList.data.dashboardResult.totalAgroServiceCount' || 0,
      //   {}
      // ),
      Total_Communication_Sent: _get(state, 'dashboardList.data.dashboardResult.totalReach', {}),
      Total_Communication_Sent_This_Month: _get(
        state,
        'dashboardList.data.dashboardResult.totalReachInCurrentMonth',
        {}
      )
    },
    whatsappCounts: {
      WhatsApp_Messages_Delivered: _get(
        state,
        'messageCountListReducer.data.messageCountList.delivered',
        {}
      ),
      WhatsApp_Messages_Not_Delivered: _get(
        state,
        'messageCountListReducer.data.messageCountList.notDelivered',
        {}
      ),
      WhatsApp_Messages_Accepted: _get(
        state,
        'messageCountListReducer.data.messageCountList.lanjutCount',
        {}
      ),
      WhatsApp_Messages_Not_Accepted: _get(
        state,
        'messageCountListReducer.data.messageCountList.tidakCount',
        {}
      )
    },
    error: _get(state, 'dashboardList.data.dashboardError', {}),
    monthlyReact: _get(state, 'dashboardList.data.dashboardResult.monthlyReach', {}),
    completed: _get(state, 'dashboardList.data.completed.', {}),
    monthlyInductedGrower: _get(
      state,
      'dashboardList.data.dashboardResult.sortedMonthlyInductedGrowers',
      {}
    ),
    totalReach: _get(state, 'dashboardList.data.dashboardResult.totalReach', {}),
    totalReachInCurrentYear: _get(
      state,
      'dashboardList.data.dashboardResult.totalReachInCurrentYear',
      {}
    ),
    totalReachInCurrentMonth: _get(
      state,
      'dashboardList.data.dashboardResult.totalReachInCurrentMonth',
      {}
    ),
    salesUnitDistribution: {
      channel: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.channel', {}),
      region: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.city', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {}),
      source: _get(state, 'salesUnitList.data.salesUnitResult', {})
    },
    picDistribution: {
      PIC: _get(state, 'picList.data.picResult', {}),
      city: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.city', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    },
    phDistribution: {
      area: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.area', {}),
      province: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.city', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    },
    inDistribution: {
      area: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.area', {}),
      state: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.state', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    },
    bdDistribution: {
      area: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.area', {}),
      brand: _get(state, 'brandList.data.brandResult', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    },
    idnDistribution: {
      land_size: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.area', {}),
      region: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.city', {})
      // crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {}),
      // source: _get(state, 'salesUnitList.data.salesUnitResult', {})
    },
    idnCropPieChart: {
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    },
    getMessageCount: _get(state, 'messageCountListReducer.data.messageCountList', {}),
    krDistribution: {
      classification: _get(
        state,
        'dashboardList.data.dashboardResult.growerDistribution.classification',
        {}
      ),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {}),
      province: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.province', {})
    },
    vnDistribution: {
      area: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.area', {}),
      region: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.city', {}),
      crop: _get(state, 'dashboardList.data.dashboardResult.growerDistribution.crop', {})
    }
  };
};
Dashboard.propTypes = {
  data: PropTypes.array,
  GrowersCount: PropTypes.number,
  toggleAlert: PropTypes.bool,
  allGrowersCount: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Dashboard));

const cssstyle = `
.slick-slider{
 padding-bottom:25px
}
.slick-prev{
  display:none
}
.slick-next{
  display:none
}
.slick-dots {
  bottom:3px
}
.slick-dots li button:before {
  color: orange;
}
.slick-dots li.slick-active button:before {
  color:green !important;
}
.button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  
  border: none;
  border-radius: 100%;
  background-color: orange;
  
  text-indent: -9999px;
}
`;

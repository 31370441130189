import {
  SCHEDULE_MESSAGE_LIST_PENDING,
  SCHEDULE_MESSAGE_LIST_SUCCESS,
  SCHEDULE_MESSAGE_LIST_ERROR,
  MESSAGE_LIST_LOADING
} from '../constants/index';

const initialState = {
  data: {
    scheduleMessageList: {},
    schedulemessageListError: {},
    schedulemessageListStatus: '',
    completed: false,
    loading: true
  }
};

function scheduleMessageList(state = initialState, action) {
  switch (action.type) {
    case SCHEDULE_MESSAGE_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case SCHEDULE_MESSAGE_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          scheduleMessageList: action.payload,
          completed: false
        }
      });
    case SCHEDULE_MESSAGE_LIST_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          schedulemessageListError: action.payload,
          completed: false
        }
      });
    case MESSAGE_LIST_LOADING:
      return {
        data: {
          ...state.data,
          loading: action.payload
        }
      };
    default:
      return state;
  }
}

export default scheduleMessageList;

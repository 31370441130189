import React from 'react';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import Utility from '../views/utility/utility';
import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';  
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import GrowerInformationForm from '../GrowerForm/growerInformationForm';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'dark',
    loginPageImage: 'thLogin',
    loginButtonStyle: 'thLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    drawerWidth: '280',
    sampleTemplate:
      'https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/sampl-file/Sample-th.xlsx',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },
      // {
      //   pathName: '/users',
      //   componentName: Users
      // },
      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      },
      {
        pathName: '/utility',
        componentName: Utility
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '/grower-information-form',
        componentName: GrowerInformationForm
      },
      // {
      //   pathName: '/grower-information-form',
      //   componentName: GrowerInformationForm
      // },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },
      // {
      //   key: 'users',
      //   label: t('Users')
      // },
      {
        key: 'help',
        label: t('Help')
      },
      {
        key: 'utility',
        label: t('USSD/BuzzeBee Transformation')
      }
    ],
    amplitude: 'IN',
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },
        { key: 'Zone', value: t('Province') },
        { key: 'city', value: t('Sub District') },
        { key: 'Crop', value: t('Crop') },
        { key: 'CropHistory', value: t('Crop History') },
        { key: 'Tags', value: t('Tags') },
        { key: 'salesUnit', value: t('Source') },
        { key: 'retailerName', value: t('Retailer Name') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'channel', value: t('Channel') },
        { key: 'updatedDate', value: t('Updated Date') },
        { key: 'createdDate', value: t('Created Date') },
        { key: 'activitiesType', value: t('Activitity Type ') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Zone: [{ key: 'in', value: t('in') }],
        city: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        Tags: [{ key: 'in', value: t('in') }],
        activitiesType: [{ key: 'in', value: t('in') }],
        CropHistory: [{ key: 'in', value: t('in') }],
        salesUnit: [{ key: 'in', value: t('in') }],
        retailerName: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'equals to', value: t('equals to') },
          { key: 'contains', value: t('contains') }
        ],
        updatedDate: [
          { key: 'to', value: t('to') },
          { key: 'from', value: t('from') }
        ],
        createdDate: [
          { key: 'to', value: t('to') },
          { key: 'from', value: t('from') }
        ],
        channel: [{ key: 'in', value: t('in') }]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some crops')
          }
        ]
      },
      {
        key: 'allZone',
        optionValue: 'childrenZone',
        fieldName: 'GrowersZone',
        initialValue: 'GrowersZone',
        placeholder: t('Select Some Province'),
        handler: 'handleZoneSelectAll',
        rules: []
      },
      {
        key: 'allCropHistory',
        optionValue: 'childrenCropHistory',
        fieldName: 'GrowersCropHistory',
        initialValue: 'GrowersCropHistory',
        placeholder: t('Select Some Crop History'),
        handler: 'handleCropHistorySelectAll',
        rules: []
      },
      {
        key: 'allCity',
        optionValue: 'childrenCity',
        fieldName: 'GrowersCity',
        initialValue: 'GrowersCity',
        placeholder: t('Select Some Sub-District'),
        handler: 'handleCitySelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Surname'),
          fieldName: 'surname',
          initialValue: 'surname',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter surname'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Acres)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Zip Code'),
          fieldName: 'zipCode',
          initialValue: 'zipCode',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter zip code'),
            whitespace: true
          }
        },
        {
          label: t('Province'),
          fieldName: 'Zone',
          initialValue: 'zone',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter province'),
            whitespace: true
          }
        },
        {
          label: t('Area'),
          fieldName: 'area',
          initialValue: 'area',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter area'),
            whitespace: true
          }
        },
        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        },
        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter District'),
            whitespace: true
          }
        },
        {
          label: t('Sub District'),
          fieldName: 'subDistrict',
          initialValue: 'subDistrict',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter Sub District'),
            whitespace: true
          }
        },
        {
          label: t('Village Name'),
          fieldName: 'village',
          initialValue: 'village',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter Village Name'),
            whitespace: true
          }
        },
        {
          label: t('House Number'),
          fieldName: 'houseNumber',
          initialValue: 'houseNumber',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter House Number'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Channel'),
          fieldName: 'Channel',
          initialValue: 'Channel',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter channel'),
            whitespace: true
          }
        },
        {
          label: t('Source'),
          fieldName: 'Unit',
          initialValue: 'unit',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter sales unit'),
            whitespace: true
          }
        },
        {
          label: t('Retailer Name'),
          fieldName: 'retailerName',
          initialValue: 'retailerName',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter retailer name'),
            whitespace: true
          }
        },
        {
          label: t('Retailer Code'),
          fieldName: 'retailerCode',
          initialValue: 'retailerCode',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter retailer code'),
            whitespace: true
          }
        },
        {
          label: t('Activity Type'),
          fieldName: 'activitiesType',
          initialValue: 'activitiesType',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter Activities Type'),
            whitespace: true
          }
        }
      ]
    },
    tagVisible: true,
    cropHistoryVisible: true,
    campignTable: [
      {
        title: t('MODE'),
        dataIndex: 'mode'
      },
      {
        title: t('CAMPAIGN NAME'),
        dataIndex: 'name'
      },
      {
        title: t('REACH'),
        dataIndex: 'reach'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION(region, province)'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('UPDATED ON'),
        dataIndex: 'update'
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName'
      },
      // {
      //   title: t('#MEMBERS'),
      //   dataIndex: 'noOfMembers'
      // },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters'
      }
    ],
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION(region, province)'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      }
    ],
    interactTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('SENDER'),
        dataIndex: 'Sender'
      },
      {
        title: t('SENT ON'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    scheduleMessageTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    searchZoneMessage: {
      message: t('Please select some province'),
      placeholder: t('Select Some Province')
    },
    searchCityMessage: {
      message: t('Please select some sub-district'),
      placeholder: t('Select Some Sub-District')
    },
    searchSourceMessage: {
      message: t('Please select some source'),
      placeholder: t('Select Some Source')
    },
    saveButtonPosition: 'thButtonPosition',
    dividerHeight: '640px'
  };
};

import {
  TOGGLE_SUCCESS_NOTIFY,
  TOGGLE_ERROR_NOTIFY,
  CLEAR_ALERT_STATE
} from '../constants';

export function errorNotification(errordata) {
  return {
    type: TOGGLE_ERROR_NOTIFY,
    payload: errordata
  };
}

export function successNotification(successdata) {
  return {
    type: TOGGLE_SUCCESS_NOTIFY,
    payload: successdata
  };
}

export function clearAlertState() {
  return {
    type: CLEAR_ALERT_STATE
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import './datatable.css';
import { uploadGrowerWithFetchAPI } from '../../../services/uploadGrower';
import { highlightTableRow } from '../../../actions/uploadForm';

class Datatable extends Component {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    this.state = {
      selectedRowKeys: [],
      data: this.props.data,
      pagination: {},
      currentPage: 0
    };
  }

  handleTableChange(pagination) {
    this.props.setCurrentPage(pagination.current);
    this.props.uploadGrowerWithFetchAPI(pagination.current - 1);
    this.props.highlightTableRow(false);
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.uploadGrowerWithFetchAPI(0);
  }

  render() {
    const { currentPage, columns } = this.props;
    const pagination = {
      total: this.props.totalPages * 10
    };

    const data = [];

    if (this.props.data) {
      this.props.data.map((value, index) => {
        data.push({
          dataIndex: index,
          key: index,
          fname: value.fileName,
          uby: value.uploadedBy,
          growers: value.noOfRows,
          success: value.successCount,
          failure: value.failureCount,
          uon: moment(value.created).format('lll'),
          status: [value.status || ''],
          failureSheet: value.failureSheet,
          orignalSheet: value.orignalSheet
        });
      });
    }
    return (
      <Table
        rowKey={(row) => {
          return row.id;
        }}
        pagination={{ ...pagination, current: currentPage }}
        loading={false}
        handleTableChange
        onChange={this.handleTableChange}
        size='Small'
        bordered={true}
        columns={columns}
        dataSource={data}
        rowClassName={(record) => {
          return record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : '';
        }}
      />
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    uploadGrowerWithFetchAPI: (pagenum) => dispatch(uploadGrowerWithFetchAPI(pagenum)),
    highlightTableRow: (clearHighligt) => dispatch(highlightTableRow(clearHighligt))
  };
};
const mapStateToProps = (state) => {
  // if (state.uploadList.data.length > 0) {

  return {
    data: get(state, 'uploadList.data[0].uploadUserList.items', []),
    totalPages: get(state, 'uploadList.data[0].uploadUserList.totalPages', 0),
    loading: get(state, 'uploadList.loading', ''),
    updateRowClassFlag: get(state, 'uploadFormReducer.rowHighlight', '')
  };
  // }
};

Datatable.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number,
  uploadGrowerWithFetchAPI: PropTypes.func,
  updateRowClassFlag: PropTypes.bool
};
// Datatable.displayName = 'Datatable';
export default connect(mapStateToProps, mapDispatchToProps)(Datatable);

import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import { uniq } from 'lodash';
import './growerReach.css';
const monthlyReach = {
  SMS: [
    {
      timeStamp: '2020',
      count: 16
    },
    {
      timeStamp: '2021',
      count: 1
    },
    {
      timeStamp: '2022',
      count: 9
    }
  ],
  WHATSAPP: [
    {
      timeStamp: '2020',
      count: 4
    }
  ]
};

class GrowerReach extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: [
        {
          name: 'SMS',
          data: [44, 55, 41, 67, 10]
        },
        {
          name: 'WHATSAPP',
          data: [13, 23, 20, 8, 5]
        }
      ]
    };
  }
  render() {
    const { t } = this.props;

    return (
      <div className='trendChart'>
        <div className='headerNameTrend'>Communication Trend</div>
        <div className='row'>
          <div className='mixed-chart'>
            <Chart
              options={{
                chart: {
                  type: 'bar',
                  height: 350,
                  stacked: true,
                  toolbar: {
                    show: true
                  },
                  zoom: {
                    enabled: true
                  }
                },
                colors: ['#40E0D0', '#99EEBB'],
                // responsive: [
                //   {
                //     breakpoint: 480,
                //     options: {
                //       legend: {
                //         position: 'bottom',
                //         offsetX: -10,
                //         offsetY: 0
                //       }
                //     }
                //   }
                // ],
                plotOptions: {
                  bar: {
                    horizontal: false
                  }
                },
                xaxis: {
                  type: 'category',
                  // labels: {
                  //   format: 'MMM yy'
                  // },

                  categories: uniq(this.props.growerReach.categories)
                },
                legend: {
                  position: 'right',
                  horizontalAlign: 'center',
                  offsetY: 40
                },
                fill: {
                  opacity: 1
                }
              }}
              series={this.props.growerReach.series}
              type='bar'
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GrowerReach;

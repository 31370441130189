import styled from 'styled-components';
import { Row, Card, Modal, Input, Form } from 'antd';
export const GroupModelStyle = styled(Modal)`
  .ant-modal-title {
    font-weight: 500;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;



import {
  MESSAGE_LIST_PENDING,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAILURE,
  MESSAGE_LIST_LOADING
} from '../constants/index';

export const messageListLoading = val => {
  return { type: MESSAGE_LIST_LOADING, payload: val };
};

export const messageListingPending = () => {
  return {
    type: MESSAGE_LIST_PENDING
  };
};
export const messageListingSuccess = value => {
  return {
    type: MESSAGE_LIST_SUCCESS,
    payload: value
  };
};

export const messageListingFailure = error => {
  return {
    type: MESSAGE_LIST_FAILURE,
    payload: error
  };
};

import React, { Component } from 'react';
import './whatsapp.css';
import { connect } from 'react-redux';
import { Layout, message } from 'antd';
import config from '../../config';
import Sidebar from './sideBar';
import Chat from './Chat';
import Connected from './connected';
import { getConversationResult, getUnReadMessages } from '../../actions/getConversation';
import { openConversationResult, openConversationSuccess } from '../../actions/openConversation';
import { clearUnReadMessages } from '../../actions/getConversation';
import GlobalSpinner from '../commonComponents/globalSpinner/globalSpinner';
import AdalConfigService from '../../services/adalConfigService';
import SocketDisconnected from './handleError';
import _get from 'lodash.get';
import _groupBy from 'lodash';
import cloneDeep from 'lodash';
const { Content } = Layout;
var stompClient = null;

class Inbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Pravin',
      messages: [],
      ReceiverName: '',
      checkConnect: false,
      items: [],
      conversation: [],
      dateTime: '',
      conversationId: '',
      chatNumber: '',
      chatId: '',
      updated: false,
      bellRing: false
    };
  }

  connect = () => {
    const Stomp = require('stompjs');
    var SockJS = require('sockjs-client');
    SockJS = new SockJS(config.socketConnection);
    stompClient = Stomp.over(SockJS);
    stompClient.connect(
      {},
      setTimeout(() => {
        this.onConnected();
      }, 5000),
      setTimeout(() => {
        this.onError();
      }, 5000)
    );
  };
  getMessages = (messages) => {
    var count = 0;
    let contactId = this.props.conversationResult.map((id) => id.id);
    // let messages = this.props.getUnReadMessages.map((id) => message.conversationId);
    if (contactId === messages.conversationId) {
      let UnReadData = {
        conversationId: messages.conversationId,
        message: messages.message,
        unRead: true,
        count: count + 1
      };
    }
  };
  onConnected = () => {
    const countryCode =  AdalConfigService.countryName.toLowerCase();
    if (stompClient.connected === true) {
      stompClient.subscribe(`/topic/chat/${countryCode}`, this.onMessageReceived);
    } else {
      console.log('not subscribed');
      // alert('not subscribed');
      // alert('connection lost! Please reload and try again');
    }
  };
  onError = (err) => {
    // <SocketDisconnected />;
    // alert('connection lost! Please reload and try again');
    console.log('error', err);
  };
  onMessageReceived = (msg) => {
    const message = JSON.parse(msg.body);
    if (message.message.direction === 'received') {
      // this.props.getConversationResult();
      this.props.getUnReadMessages(message);

      this.setState({
        dateTime: message.message.createdDatetime,
        conversationId: message.conversation.id,
        updated: true
      });
    }
    if (message.message.direction === 'received' && message.conversation.id === this.state.chatId) {
      this.props.openConversationSuccess({ items: [message.message], direction: 'top' });
      this.props.clearUnReadMessages(message.conversation.id);
    }
    if (message.message.status === 'pending' && message.conversation.id === this.state.chatId) {
      this.props.openConversationSuccess({ items: [message.message], direction: 'top' });
    }
  };

  getChatId = (id) => {
    this.setState({
      chatId: id
    });
  };
  componentDidMount() {
    const countryCode = AdalConfigService.countryName.toLowerCase();
    this.connect();
    this.props.getConversationResult(0, countryCode);
    // this.onmessage = (evt) => {
    //   const message = JSON.parse(evt.data);
    //   stompClient.send('/inbox/chat', {}, JSON.stringify(message));
    // };
  }
  chatClickHandler = (msg) => {
    this.setState({
      conversation: msg
    });
  };
  getUser = (user) => {
    this.setState({
      chatNumber: user
    });
  };

  addMessage = (message) => this.setState((state) => ({ messages: [message, ...state.messages] }));
  submitHandler = (msg) => {
    messages: msg;
  };

  render() {
    const {
      conversationResult,
      openConversationList,
      getTotalCount,
      checkLoading,
      getOffsetCount,
      getCount,
      unReadMessages,
      unReadMessagesList
    } = this.props;
    return (
      <Layout className='whatsapp'>
        <div className='whatsapp_body'>
          <Sidebar
            channels={conversationResult}
            chatClickHandler={this.chatClickHandler}
            dateTime={this.state.dateTime}
            getUser={this.getUser}
            totalCount={this.props.getCount}
            history={this.props.history}
            getChatId={this.getChatId}
            conversationId={this.state.conversationId}
            countryCode={AdalConfigService.countryName.toLowerCase()}
          />
          {this.state.chatNumber && this.state.chatNumber.length ? (
            <Chat
              conversation={openConversationList}
              conversationResult={conversationResult}
              loading={checkLoading}
              totalCount={getTotalCount}
              offsetCount={getOffsetCount}
              stompClient={stompClient}
              chatNumber={this.state.chatNumber}
              getChatId={this.getChatId}
              getUser={this.getUser}
              chatClickHandler={this.chatClickHandler}
              openConversation={this.openConversation}
              countryCode={AdalConfigService.countryName.toLowerCase()}
            />
          ) : (
            <Connected />
          )}
          <GlobalSpinner />
        </div>
      </Layout>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    getConversationResult: (id, countryCode) => dispatch(getConversationResult(id, countryCode)),
    openConversationResult: () => dispatch(openConversationResult()),
    getUnReadMessages: (msg) => dispatch(getUnReadMessages(msg)),
    openConversationSuccess: (message, direction) =>
      dispatch(openConversationSuccess(message, direction)),
    clearUnReadMessages: (id) => dispatch(clearUnReadMessages(id))
  };
};
// export default OnBoard;
const mapStateToProps = function(state) {
  return {
    conversationResult: _get(state, 'getConversationList.data.conversationList', []),
    getCount: _get(state, 'getConversationList.data.totalCount.totalCount', []),
    getLoading: _get(state, 'getConversationList.data.completed', []),
    unReadMessages: _get(state, 'getConversationList.data.unReadMessage', []),
    unReadMessagesList: _get(state, 'getConversationList.data.unReadMessagesList', []),
    openConversationList: _get(state, 'openConversationList.data.openConversationList', []),
    getTotalCount: _get(state, 'openConversationList.data.totalCount.totalCount', []),
    checkLoading: _get(state, 'openConversationList.data.completed', []),
    getOffsetCount: _get(state, 'openConversationList.data.totalCount.offset', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Inbox);

import {
  WhATSAP_TEMPLATEDATA_PENDING,
  WhATSAP_TEMPLATEDATA_SUCESS,
  WhATSAP_TEMPLATEDATA_ERROR,
  WhATSAP_TEMPLATEDATA_CLEAR
} from '../constants';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchwhatsAppTemplateDataPending() {
  return {
    type: WhATSAP_TEMPLATEDATA_PENDING
  };
}
export function fetchwhatsAppTemplateDataSuccess(updateList) {
  return {
    type: WhATSAP_TEMPLATEDATA_SUCESS,
    payload: updateList
  };
}

export function fetchwhatsAppTemplateDataError(error) {
  return {
    type: WhATSAP_TEMPLATEDATA_ERROR,
    payload: error
  };
}
export function fetchwhatsAppTemplateDataClear() {
  return {
    type: WhATSAP_TEMPLATEDATA_CLEAR
  };
}
export const fetchWhatsAppTemplateData = (name) => {
  const apiUri = formatUrl(config.whatsAppTemplateData, name);
  return (dispatch) => {
    dispatch(fetchwhatsAppTemplateDataPending());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchwhatsAppTemplateDataSuccess(result));
      })
      .catch((error) => {
        dispatch(fetchwhatsAppTemplateDataError(error));
      });
  };
};

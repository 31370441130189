import {
  WhATSAP_TEMPLATE_PENDING,
  WhATSAP_TEMPLATE_SUCESS,
  WhATSAP_TEMPLATE_ERROR,
  FETCH_TEMPLATE_CLEAR
} from '../constants';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchwhatsAppTemplatePending() {
  return {
    type: WhATSAP_TEMPLATE_PENDING
  };
}
export function fetchwhatsAppTemplateSuccess(updateList) {
  return {
    type: WhATSAP_TEMPLATE_SUCESS,
    payload: updateList
  };
}

export function fetchwhatsAppTemplateError(error) {
  return {
    type: WhATSAP_TEMPLATE_ERROR,
    payload: error
  };
}
export function fetchwhatsAppTemplateClear() {
  return {
    type: FETCH_TEMPLATE_CLEAR
  };
}
export const fetchWhatsAppTemplate = () => {
  const apiUri = formatUrl(config.whatsAppTemplate);
  return (dispatch) => {
    dispatch(fetchwhatsAppTemplatePending());
    return fetch(config.whatsAppTemplate, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(fetchwhatsAppTemplateSuccess(result));
      })
      .catch((error) => {
        dispatch(fetchwhatsAppTemplateError(error));
      });
  };
};

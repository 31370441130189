import {
  WEBSTORE_LIST_PENDING,
  WEBSTORE_LIST_SUCCESS,
  WEBSTORE_LIST_FAILURE,
  WEBSTORE_LIST_LOADING,
  REGION_LIST_PENDING,
  REGION_LIST_SUCCESS,
  REGION_LIST_FAILURE
} from '../constants/index';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export const agroServiceListLoading = (val) => {
  return { type: WEBSTORE_LIST_LOADING, payload: val };
};

export const agroServiceListingPending = (value) => {
  return {
    type: WEBSTORE_LIST_PENDING
  };
};
export const agroServiceListingSuccess = (value) => {
  return {
    type: WEBSTORE_LIST_SUCCESS,
    payload: value
  };
};

export const agroServiceListingFailure = (error) => {
  return {
    type: WEBSTORE_LIST_FAILURE,
    payload: error
  };
};
export function getRegionPending() {
  return {
    type: REGION_LIST_PENDING
  };
}
export const getRegionSuccess = (value) => {
  return {
    type: REGION_LIST_SUCCESS,
    payload: value
  };
};

export const getRegionFailure = (value) => {
  return {
    type: REGION_LIST_FAILURE,
    payload: value
  };
};
export const agroServiceLists = () => {
  const apiUri = config.agroServices;

  return (dispatch) => {
    dispatch(agroServiceListingPending(true));
    dispatch(agroServiceListLoading(true));
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result) {
          dispatch(agroServiceListingPending(false));
          dispatch(agroServiceListingSuccess(result.items.reverse()));
          dispatch(agroServiceListLoading(false));
        }
      })
      .catch((e) => {
        dispatch(agroServiceListLoading(false));
        dispatch(agroServiceListingPending(false));
        dispatch(agroServiceListingFailure(e));
      });
  };
};

export const getRegions = () => {
  const apiUri = config.regionsList;
  return (dispatch) => {
    dispatch(getRegionPending(true));
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(getRegionSuccess(result));
        dispatch(getRegionPending(false));
      })
      .catch((e) => {
        dispatch(getRegionPending(true));
        dispatch(getRegionFailure(e));
      });
  };
};

import {
    GROUP_UPDATE_PENDING,
    GROUP_UPDATE_SUCCESS,
    GROUP_UPDATE_FAILURE,
    UPDATE_GROUP_DRAWER,
    HIGHLIGHT_GROWER_TABEL_ROW,
    CREATE_GROWER_MODAL,
    CLEAR_GROWER_UPDATE
  } from '../constants/index';
  
  const initialState = {
    groupUpdateSucess: [],
    groupUpdateError: [],
    groupUpdatePending: false,
    loading: false,
    highlightGrowerRow: false,
    visible: false,
    updateDrawerVisible:false
  };
  
  function groupUpdate(state = initialState, action) {
    switch (action.type) {
      case GROUP_UPDATE_PENDING:
        return {
          ...state,
          groupUpdatePending: action.payload
        };
      case GROUP_UPDATE_SUCCESS:
        return {
          ...state,
          groupUpdateSucess: action.payload
        };
      case GROUP_UPDATE_FAILURE:
        return {
          ...state,
          groupUpdateError: action.payload
        };
      case UPDATE_GROUP_DRAWER:
        return {
          ...state,
          updateDrawerVisible: !state.updateDrawerVisible
        };
      case GROUP_UPDATE_FAILURE:
        return {
          ...state,
          loading: action.payload
        };
      case HIGHLIGHT_GROWER_TABEL_ROW:
        return {
          ...state,
          highlightGrowerRow: action.payload
        };
      case CLEAR_GROWER_UPDATE:
        return {
          ...state,
          growerUpdateSucess: []
        };
  
      default:
        return state;
    }
  }
  
  export default groupUpdate;
  
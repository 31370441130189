import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import { connect } from 'react-redux';
import _get from 'lodash.get';
import { sessionTimeoutError } from '../../../actions/dashboard';
import { withTranslation } from 'react-i18next';
class SessionTimeOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visibleModel
    };
  }
  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = (e) => {
    this.setState({
      visible: false
    });
    localStorage.clear();
    window.location.href = '/';
  };

  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Modal
          title={t('Session Expired. Please Sign in')}
          visible={this.props.visibleModel}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closable={false}
          footer={[
            <Button key='submit' type='primary' size='small' onClick={this.handleOk}>
              {t('OK')}
            </Button>
          ]}>
          <p>{t('Your session has expired. Click OK to return on login page')}...</p>
        </Modal>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    sessionTimeoutError: () => dispatch(sessionTimeoutError())
  };
};

const mapStateToProps = function(state) {
  return {
    visibleModel: _get(state, 'dashboardList.data.visible', null)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SessionTimeOut));

import React, { Component } from 'react';
import { Table, Modal, Card, Row, Col, Descriptions, List, Tag, Button, Switch } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import { fetchOptInResult } from '../../../actions/optIn';

import { fetchOptOutResult } from '../../../actions/optOut';

class OptInOptOutSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: JSON.parse(this.props.Rowdata.modeSwitch.toLowerCase())
    };
  }
  //   static getDerivedStateFromProps(nextProps, prevState) {
  //     if (prevState.Rowdata !== nextProps.Rowdata) {
  //       return { switch: nextProps.Rowdata };
  //     }

  //     return null;
  //   }
  toggleSwitch = () => {
    this.setState({ switch: !this.state.switch });
    if (this.state.switch) {
      //   const modeSwitch = this.state.Rowdata.modeSwitch;
      //   console.log('modeSwitch>', modeSwitch);
      const mobileNumbers = {
        mobileNumbers: [Number(this.props.Rowdata.contactNo)]
      };

      console.log('mobileNumbers>', mobileNumbers);
      this.props.fetchOptOutResult(
        mobileNumbers,
        this.props.currentPage,
        this.props.userList,
        this.props.userListOffset,
        this.props.searchResultArr,
        this.props.usersData
      );
      console.log('Opt Out called');
    } else {
      // const mobileNumbers = this.state.Rowdata.contactNo;
      const mobileNumbers = {
        mobileNumbers: [Number(this.props.Rowdata.contactNo)]
      };
      console.log('mobileNumbers in>', mobileNumbers);
      this.props.fetchOptInResult(
        mobileNumbers,
        this.props.currentPage,
        this.props.userList,
        this.props.userListOffset,
        this.props.searchResultArr,
        this.props.usersData
      );
      console.log('Opt in called');
    }
  };

  render() {
    console.log('this.props.Rowdata.modeSwitch>', this.props.Rowdata);
    console.log('opt state>', this.state.switch);
    console.log('searchResultArr', this.props.searchResultArr);
    return (
      <Switch
        // defaultChecked={this.props.Rowdata.modeSwitch}
        checkedChildren='OPT-IN'
        unCheckedChildren='OPT-OUT'
        checked={this.state.switch}
        onChange={this.toggleSwitch}
      />
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    fetchOptInResult: (user, pageNumber, dataLength, groupOffset) => {
      dispatch(fetchOptInResult(user, pageNumber, dataLength, groupOffset));
    },
    fetchOptOutResult: (user, pageNumber, dataLength, groupOffset) => {
      dispatch(fetchOptOutResult(user, pageNumber, dataLength, groupOffset));
    }
  };
};
const mapStateToProps = function(state) {
  return {
    totalPages: get(state, 'growerList.data.userList.totalPages', 0)
    // userList: get(state, 'growerList.data.userList.items', []),
    // userListOffset: get(state, 'growerList.data.userList.offset', [])
  };
};
OptInOptOutSwitch.propTypes = {
  // alertType: PropTypes.string,
};
export default connect(mapStateToProps, mapDispatchToProps)(OptInOptOutSwitch);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, Tag, Button } from 'antd';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { youTubeListWithFetchAPI } from '../../actions/uploadYouTubeList';

import './youTubeTable.css';
class YouTubeDatatable extends Component {
  constructor(props) {
    super(props);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);

    this.state = {
      selectedRowKeys: [],
      data: this.props.data,
      pagination: {},
      currentPage: 0,
      prevPageToken: this.props.prevPageToken,
      nextPageToken: this.props.nextPageToken
    };
  }

  handleTableChange(pagination, pageSize) {
    this.setState({
      currentPage: pagination.current
    });

    this.props.youTubeListWithFetchAPI(this.props.nextPageToken);
  }

  onSelectChange(selectedRowKeys) {
    this.setState({ selectedRowKeys });
  }

  componentDidMount() {
    this.props.youTubeListWithFetchAPI();
  }

  render() {
    const columns = [
      {
        title: 'Video',
        align: 'center',
        dataIndex: 'url',
        width: '400px',
        render: (title) => {
          const splitTitle = title.split('*');
          return (
            <div className='videoContainer'>
              <img src={splitTitle[0]} />
              <div className='videoTitle'>
                <p className='videoDescription'>{splitTitle[1]}</p>
                <p>{splitTitle[2]}</p>
              </div>
            </div>
          );
        }
      },

      {
        title: 'View',
        align: 'center',
        dataIndex: 'viewCount'
      },
      {
        title: 'Like',
        align: 'center',
        dataIndex: 'likeCount'
      },
      {
        title: 'Comments',
        align: 'center',
        dataIndex: 'commentCount'
      },
      {
        title: 'Published on',
        dataIndex: 'publishedAt'
      }
    ];

    const pagination = {
      total: this.props.totalPages,
      pageSize: this.props.resultsPerPage,
      current: this.state.currentPage
    };

    // const { selectedRowKeys } = this.state;
    const data = [];
    if (this.props.data) {
      this.props.data.forEach((value, index) => {
        data.push({
          dataIndex: index,
          key: index,
          url:
            `${value.snippet.thumbnails.default.url}*${value.snippet.localized.title}*${value.snippet.localized.description}` ||
            '',
          viewCount: value.statistics.viewCount || '',
          likeCount: value.statistics.likeCount || '',
          commentCount: value.statistics.commentCount || '',
          publishedAt: moment(value.snippet.publishedAt || '').format('lll')
        });
      });
    }

    return (
      // <>
      <Table
        rowKey={data.key}
        pagination={{ ...pagination }}
        loading={false}
        handleTableChange
        onChange={this.handleTableChange}
        size='Small'
        bordered={true}
        columns={columns}
        dataSource={data}
        className='youtubeTable'
        rowClassName={(record) =>
          record.key === 0 && this.props.updateRowClassFlag ? 'highLight' : ''
        }
      />
    );
  }
}

const mapDispatchToProps = function(dispatch) {
  return {
    youTubeListWithFetchAPI: (pagenum, nextPageToken) =>
      dispatch(youTubeListWithFetchAPI(pagenum, nextPageToken))
  };
};
const mapStateToProps = (state) => {
  return {
    data: get(state, 'uploadYoutubeList.data[0].uploadVideoList.items', []),
    totalPages: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.totalResults', 0),
    resultsPerPage: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.resultsPerPage'),
    nextPageToken: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.nextPageToken'),
    prevPageToken: get(state, 'uploadYoutubeList.data[0].uploadVideoList.pageInfo.prevPageToken'),
    loading: get(state, 'uploadYoutubeList.loading', ''),
    updateRowClassFlag: get(state, 'uploadFormReducer.rowHighlight', '')
  };
  // }
};

YouTubeDatatable.propTypes = {
  data: PropTypes.array,
  totalPages: PropTypes.number,
  resultsPerPage: PropTypes.number,
  nextPageToken: PropTypes.string,
  prevPageToken: PropTypes.string,
  youTubeListWithFetchAPI: PropTypes.func,
  updateRowClassFlag: PropTypes.bool
};
// Datatable.displayName = 'Datatable';
export default connect(mapStateToProps, mapDispatchToProps)(YouTubeDatatable);

import {
  CREATE_GROUP,
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_DRAWER,
  HIGHLIGHT_GROUP_TABEL_ROW
} from '../constants';

const initialState = {
  groupdata: {},
  error: null,
  loading: false,
  ProgressStatus: true,
  rowHighlight: false,
  groupDrawerVisible: false
};

function creatGroupReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_GROUP:
      return {
        ...state,
        loading: true,
        ProgressStatus: action.payload
      };

    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groupdata: action.value,
        loading: false
      };

    case CREATE_GROUP_FAILURE:
      return {
        ...state,
        error: action.value,
        loading: false
      };
    case CREATE_GROUP_DRAWER:
      return {
        ...state,
        groupDrawerVisible: !state.groupDrawerVisible
      };
    case HIGHLIGHT_GROUP_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };

    default:
      return state;
  }
}
export default creatGroupReducer;

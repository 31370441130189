import {
    SINGLE_GROWER_UPDATE_PENDING,
    SINGLE_GROWER_UPDATE_SUCCESS,
    SINGLE_GROWER_UPDATE_FAILURE,
    SINGLE_GROWER_UPDATE_LOADING,
    CLEAR_SINGLE_GROWER_UPDATE
  } from '../constants/index';
  import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';

  import { toggleSpinStatus } from '../actions/globalSpinner';


  
  import { handleErrors } from '../services/handleErrors';
  import { formatUrl } from '../services/commonMethods';
  import { defaultGrowers } from '../actions/growersAction';
  
  import config from '../config';
  
  export const growerUpdateLoading = (val) => {
    return { type: SINGLE_GROWER_UPDATE_LOADING, payload: val };
  };
  
  export const growerUpdatePending = (value) => {
    return {
      type: SINGLE_GROWER_UPDATE_PENDING,
      payload: value
    };
  };
  export const growerUpdateSuccess = (value) => {
    return {
      type: SINGLE_GROWER_UPDATE_SUCCESS,
      payload: value
    };
  };
  
  export const growerUpdateFailure = (error) => {
    return {
      type: SINGLE_GROWER_UPDATE_FAILURE,
      payload: error
    };
  };


  export const clearGrowerUpdate = () => {
    return {
      type: CLEAR_SINGLE_GROWER_UPDATE
    };
  };
  
  export const updateSingleGrower = (growerData) => {
    const apiUri = formatUrl(config.updateSingleGrower);
    return (dispatch) => {
      dispatch(growerUpdatePending());
      // dispatch(toggleSpinStatus());
     
      return fetch(apiUri, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify(growerData)
      })
        .then((response) => {
          return handleErrors(response, dispatch);
        })
        .then((result) => {
          if (result && result.code === '200') {
         
            dispatch(clearAlertState());
             dispatch(toggleSpinStatus());
            dispatch(growerUpdateSuccess(result));
      
            dispatch(
              successNotification({
                type: 'success',
                message: result.message,
                toggleAlert: true
              })
            );
            dispatch(clearAlertState());
          } else {
            if (result === false) {
              dispatch(toggleSpinStatus());
              dispatch(clearAlertState());
              return false;
            }
       
            dispatch(toggleSpinStatus());
  
            dispatch(clearAlertState());
            dispatch(growerUpdateFailure(result));
            dispatch(
              errorNotification({
                type: 'error',
                message: result.message,
                toggleAlert: true
              })
            );
  
            dispatch(clearAlertState());
          }
        })
        .catch((e) => {
          if (response.status === 401) {
            return false;
          }
    
          dispatch(toggleSpinStatus());
          dispatch(growerUpdateFailure(e));
  
          dispatch(
            errorNotification({
              type: 'error',
              message: e.message,
              toggleAlert: true
            })
          );
  
          dispatch(clearAlertState());
        });
    };
  };
  
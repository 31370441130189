import React from 'react';
import { Form, Select, Input } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchMobileNumber extends React.Component {
  constructor(props) {
    super(props);
    this.childrenMobileNumber = [];
  }
  handleChange = (user, event) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    if (event.target.value.length == 0) {
      currentUser.value = [];
    } else {
      currentUser.value[0] = event.target.value;
    }
    this.props.callback(newUsers);
  };
  validateField = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Please enter a number');
    }
    else if (value.length < 5) {
      callback('Please enter at least 5 digit');
    } else {
      callback();
    }
  };
  validateMobileNumber = (evt)=>{
    if(evt.target.value.length > 13){
      evt.preventDefault();
      return false
    }
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { searchMobileNumber, t } = this.props;
   

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.mobileNumber}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              message: t('Please enter mobile number')
            },
            {
              validator: this.validateField
            }
          ]
        })(
          <Input
          name='mobile'
          type='number'
          size='small'
          min='0'
          // step='0.01'
          placeholder='Please Enter Mobile Number'
          onKeyDown={(evt) => (evt.key === 'e' || evt.key === '.') && evt.preventDefault()}
          onKeyPress={(evt) => this.validateMobileNumber(evt)}
          value={this.props.user.value[0] || ''}  
          onChange={(event) => {
            this.handleChange(this.props.user, event);
          }}
        />
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchMobileNumber: () => dispatch(fetchMobileNumber())
  };
};
const mapStateToProps = function(state) {
  return {
    groupMobileNumber: get(state, 'groupFilterReducer.data.groupMobileNumber.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchMobileNumber);

import config from '../config';
import {
  YOUTUBE_LIST_PENDING,
  YOUTUBE_LIST_SUCCESS,
  YOUTUBE_LIST_FAILURE,
  YOUTUBE_LIST_LOADING
} from '../constants/index';
import { handleErrors } from '../services/handleErrors';
import { youTubeListWithFetchAPI } from '../actions/uploadYouTubeList';
export const youTubeListLoading = (val) => {
  return { type: YOUTUBE_LIST_LOADING, payload: val };
};

export const youTubeListingPending = () => {
  return {
    type: YOUTUBE_LIST_PENDING
  };
};
export const youTubeListingSuccess = (value) => {
  return {
    type: YOUTUBE_LIST_SUCCESS,
    payload: value
  };
};

export const youTubeListingFailure = (error) => {
  return {
    type: YOUTUBE_LIST_FAILURE,
    payload: error
  };
};
export const youTubeAuthAPI = () => {
  const authUri = config.youTubeAuthAPI;
  return (dispatch) => {
    return fetch(authUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        localStorage.setItem('youTubeAuthToken', result.accessToken);

        dispatch(youTubeListingSuccess(result));
        setTimeout(
          function() {
            dispatch(youTubeListWithFetchAPI());
          }.bind(),
          3000
        );
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);

        if (errorResponse.message.indexOf('403') > -1) {
          const response = {
            status: 403
          };
          return handleErrors(response, dispatch);
        }
      });
  };
};

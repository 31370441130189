import React, { Component } from 'react';
import { Table } from 'antd';
import _get from 'lodash.get';

class RecentCampigns extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { tableData, t, CampaignColumns } = this.props;
    const list = tableData.length ? tableData : [];
    const data = [];
    if (list) {
      list.map((value, index) => {
        data.push({
          key: index,
          mode: value.mode || [],
          name: value.groupName || '',
          reach: value.growerReach || ''
        });
      });
    }
    return (
      <div className='campaignTable'>
        <div className='headerName'>{t('Recent Agronomy Service Distributions')}</div>
        <Table
          rowKey={data.key}
          size='large'
          bordered={true}
          columns={CampaignColumns}
          dataSource={data}
          pagination={false}
        />
      </div>
    );
  }
}

export default RecentCampigns;

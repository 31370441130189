export var formatUrl = (url, ...args) =>
  url.replace(/{(\d+)}/g, (match, number) => {
    if (!args[number]) {
      return args[number];
    }
    return args[number] ? args[number] : match;
  });

export const findCountryandEnvironment = (host) => {
  if (host.includes('.grower')) {
    if (host.includes('staging.grower') || host.includes('stag.grower')) {
      return { country: 'in', hostName: 'staging' };
    } else if (host.includes('staging-')) {
      const newHost = host.split('staging-');
      return { country: newHost[1].split('.grower')[0], hostName: 'staging' };
    }
    {
      return { country: host.split('.grower')[0], hostName: 'production' };
    }
  } else {
    return { country: 'bd', hostName: 'localhost' };
  }
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SignIn from '../views/Login/Loginpage';
import OnBoard from '../views/Growers/OnBoard/onboard';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import SocialMedia from '../views/SocialMedia/SocialMedia';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import Inbox from '../views/Communication/whatsApp';
import Chat from '../views/Communication/front-end/Chat';
import ProtectedRoute from './Route';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import Unauthorization from '../views/commonComponents/errorboundry/unauthorizationHandle';
import { useRegionalMeta } from '../context/region';
export default function Routes(props) {
  const regionData = useRegionalMeta();
  return (
    <Switch>
      {/* <ProtectedRoute exact path='/' component={Dashboard} Context={props} /> */}
      {/* <Route path='/' exact={true} component={SignIn} /> */}
      {/* <ProtectedRoute
        path='/on-board-growers'
        component={(componentProps) => <OnBoard {...componentProps} />}
        isPrivate
        Context={props}
      />
      {regionData.data && regionData.data.protectedRoutes
        ? regionData.data.protectedRoutes.map((route) => (
            <ProtectedRoute
              path={route.pathName}
              component={route.componentName}
              isPrivate
              Context={props}
            />
          ))
        : null} */}
      {/* <ProtectedRoute path='/find-grower' component={FindGrower} isPrivate Context={props} />
      <ProtectedRoute path='/interact' component={Interact} isPrivate Context={props} />
      <ProtectedRoute path='/groups' component={Groups} isPrivate Context={props} />
      <ProtectedRoute path='/dashboard' component={Dashboard} isPrivate Context={props} />
       <ProtectedRoute path='/social-media' component={SocialMedia} isPrivate Context={props} />
      <ProtectedRoute path='/reports' component={Reports} isPrivate Context={props} />
      <ProtectedRoute path='/users' component={Users} isPrivate Context={props} />
      <ProtectedRoute path='/engagegrowers' component={WebStore} isPrivate Context={props} />
      <ProtectedRoute path='/help' component={Help} isPrivate Context={props} />
       <ProtectedRoute path='/comingsoon' component={ComingSoon} isPrivate Context={props} /> */}
      {/* <ProtectedRoute path='/error-message' component={ErrorMessage} isPrivate Context={props} /> */}
      {/* <ProtectedRoute path='/dashboard' component={Dashboard} isPrivate Context={props} /> */}
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      {regionData.data && regionData.data.routes
        ? regionData.data.routes.map((route) => (
            <Route path={route.pathName} exact={true} component={route.componentName} />
          ))
        : null}
      {/* <Route path='/error-message' component={ErrorMessage} /> */}
      {/* <Route path='/login' component={SignIn} />
      <Route path='/error-message' component={ErrorMessage} />
      <Route path='/access-denied' component={Unauthorization} />
      <Route path='*' component={PageNotFound} /> */}
    </Switch>
  );
}

import React, { Component } from 'react';
import {
  Form,
  Icon,
  Radio,
  Button,
  Modal,
  Upload,
  Input,
  Progress,
  Card,
  Skeleton,
  Switch,
  Typography,
  Col,
  Row,
  message,
  Divider,
  Collapse,
  Select,
  Avatar
} from 'antd';
import PropTypes from 'prop-types';
import { StyledUpload, StyledUploadButton } from './mmsStyle';
import { Translation } from 'react-i18next';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import './mms.css';
const FormItem = Form.Item;
const { Meta } = Card;
const { Dragger } = Upload;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Option } = Select;
// const Link = Scroll.Link;
const { Text, Paragraph } = Typography;

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

class SelectMMS extends Component {
  constructor(props) {
    super(props);
    this.props.callbackFile(this.props.editData.files);
    this.state = {
      name: '',
      width: '320',
      fileName: '',
      visible: false,
      imageSize: false,
      imageType: false,
      imageResolution: false,
      imageHeight: '',
      imageWidth: '',
      drawerVisible: false,
      fileList: this.props.editData.files.length ? this.props.editData.files : [],
      uploading: false,
      filevalid: false,
      loading: true,
      title: '',
      chars_left: 140,
      max_chars: 1000
    };
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   console.log('nextProps>>', nextProps.editData ? nextProps.editData.files : nextProps);
  //   if (prevState.fileList !== nextProps.editData.files) {
  //     return { fileList: nextProps.editData.files };
  //   }
  // }
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };
  handleWordCount = (event) => {
    const charCount = event.target.value.length;
    const maxChar = this.state.max_chars;
    const charLength = maxChar - charCount;
    this.setState({ chars_left: charLength });
  };
  fileValidation = (rule, val, callback) => {
    const isJPG = val.file.type === 'image/jpeg';
    if (!isJPG) {
      callback('Please select only jpeg');
      return;
    }
    if (val.file.size < 300000) {
      callback('Image must smaller than 300KB');
      return;
    }
    callback();
  };

  handleRemove = (file) => {
    this.setState((state) => {
      const index = state.fileList.indexOf(file);
      const newFileList = state.fileList.slice();
      newFileList.splice(index, 1);
      return {
        fileList: [],
        filevalid: false,
        imageSize: false,
        imageType: false,
        imageResolution: false,
        previewImage: '',
        previewVideo: ''
      };
    });
  };

  handleChange = (info) => {
    const handleMultiple = info.fileList.filter((file) => {
      return file.size > 300000;
    });
    if (handleMultiple.length > 0) {
      this.props.callback(true);
      this.setState({ imageSize: true });
    } else {
      this.props.callback(false);
      this.setState({ imageSize: false });
    }
    this.setState({
      fileName: info.file.name
    });

    const file = info.file;
    let fileList = [...info.fileList];
    // fileList = fileList.slice(-1);

    // 2. Read from response and show file link
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    // if (fileList.length > 3) {
    fileList = fileList.slice(-3);
    // fileList = [fileList[fileList.length - 3]];
    // }
    console.log('handle fileList>', fileList);
    this.props.callbackFile(fileList);
    this.setState({ fileList });
  };

  render() {
    const { uploading, fileList, fileName, mediaType } = this.state;
    console.log('modified files>', this.props.editData.files);
    console.log('fileList>>', fileList);
    const { editData } = this.props;

    const props = {
      // listType: 'picture',
      accept: 'image/jpeg',
      className: 'upload-list-inline',

      onRemove: (file) => {
        console.log('removed file', file);

        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
          this.props.callbackType(false);
          this.setState({ imageType: false });
        }

        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          this.props.callbackFile(newFileList);
          return {
            fileList: newFileList,
            filevalid: false
          };
        });
      },
      onChange: this.handleChange,
      beforeUpload: (file, fileList) => {
        const reader = new FileReader();
        //const image = '';
        reader.readAsDataURL(file);
        reader.addEventListener('load', (event) => {
          const _loadedImageUrl = event.target.result;
          const image = document.createElement('img');
          image.src = _loadedImageUrl;
          image.addEventListener('load', () => {
            const { width, height } = image;
            // set image width and height to your state here
            console.log(width, height);
            this.setState({
              imageHeight: height,
              imageWidth: width
            });
          });
        });

        console.log('files', file);
        console.log('fileList>1', fileList);
        const isJPG = file.type === 'image/jpeg';
        if (!isJPG) {
          // message.error('You can only upload JPG file!');
          this.setState({ imageType: true });

          this.props.callbackType(true);
          // return false;
        } else {
          this.setState({ imageType: false });
        }
        // const isLt2Multiple = fileList.filter((file) => {
        //   return file.size > 300000;
        // });
        // console.log('isLt2Multiple>', isLt2Multiple);
        // const isLt2M = file.size < 300000;
        // if (!isLt2M) {
        //   // message.error('Image must smaller than 300KB!');
        //   this.props.callback(true);
        //   this.setState({ imageSize: true });
        //   // file.flag = true;
        // } else {
        //   // return isLt2M;
        //   this.setState({ imageSize: false });
        // }

        this.setState((state) => ({
          fileList: [state.fileList, file]
        }));
        return false;
        // return isJPG && isLt2M;
      },

      multiple: true,
      fileList: fileList
      // accept: 'jpeg'
    };

    const { getFieldDecorator } = this.props.form;
    console.log('fileList>', this.state.fileList);
    return (
      <>
        <div className=''>
          <p>Type your message(maximum 1000 characters)</p>
          <Form.Item>
            {getFieldDecorator('mesageTxtMms', {
              initialValue: this.props.editData.message || '',
              rules: [
                {
                  required: true,
                  // this.state.isEnabled || this.props.messageType === 'WHATSAPP' ? false : true,
                  message: 'Please enter a valid message',
                  whitespace: true
                }
              ]
            })(
              <TextArea
                placeholder='Enter your text'
                size='large'
                maxLength={this.state.max_chars}
                // showCount
                rows={5}
                // onChange={this.handleWordCount}
                // value={this.state.postDescription}
              />
            )}
          </Form.Item>

          <Form.Item label='Select Images (maximum 3 images)'>
            {getFieldDecorator('upload', {
              initialValue: this.state.fileList || '',
              validateTrigger: ['onChange', 'onBlur'],
              rules: [
                { required: false, message: 'Please Select Media' }
                // { validator: this.fileValidation }
              ]
            })(
              <StyledUpload
                {...props}
                // initialValues={{ fileList: previewFileList }}
                fileList={this.state.fileList}
                accept='image/jpeg'
                // maxCount={1}
                // progress={progress}
              >
                <StyledUploadButton
                  className='imageUpload'
                  disabled={this.state.fileList.length > 2 ? true : false}
                  // onClick={(e) => {
                  //   this.setState({
                  //     fileList: []
                  //   });
                  // }}
                  // >
                >
                  <Icon type='upload' /> Click to Upload
                </StyledUploadButton>
              </StyledUpload>
            )}
            {this.state.imageType ? (
              <div style={{ color: 'red' }}>You can only upload JPG or PNG file.</div>
            ) : (
              ''
            )}
            {this.state.imageSize ? (
              <div style={{ color: 'red' }}>Image must smaller than 300KB.</div>
            ) : (
              ''
            )}
            {this.state.imageResolution ? (
              <div style={{ color: 'red' }}>Image resolution must be smaller than 1500.</div>
            ) : (
              ''
            )}
          </Form.Item>
        </div>
      </>
    );
  }
}
SelectMMS.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object
};
const SelectMediaForm = Form.create({})(SelectMMS);

export default SelectMediaForm;

import {
  OPEN_CONVERSATION_PENDING,
  OPEN_CONVERSATION_SUCCESS,
  OPEN_CONVERSATION_ERROR,
  CLEAR_CONVERSATION
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function openConversationPending() {
  return {
    type: OPEN_CONVERSATION_PENDING
  };
}
export function openConversationSuccess(result) {
  return {
    type: OPEN_CONVERSATION_SUCCESS,
    payload: result
  };
}

export function openConversationError(error) {
  return {
    type: OPEN_CONVERSATION_ERROR,
    payload: error
  };
}
export function clearData() {
  return {
    type: CLEAR_CONVERSATION
  };
}
export const openConversationResult = (conversationId, pageNumber, countryCode) => {
  const apiUri = formatUrl(config.openConversation, conversationId, pageNumber, countryCode);
  return (dispatch) => {
    dispatch(openConversationPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(openConversationSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(openConversationError(error));
      });
  };
};

import {
  FETCH_GROUP_MEMBER_LIST_PENDING,
  FETCH_GROUP_MEMBER_LIST_SUCCESS,
  FETCH_GROUP_MEMBER_LIST_ERROR,
  CLEAR_GROUP_MEMBER_LIST
} from '../constants';

const initialState = {
  data: {
    groupMemberList: [],
    groupMemberListError: [],
    completed: false
  }
};

function groupMemberList(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUP_MEMBER_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_GROUP_MEMBER_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupMemberList: action.payload,
          completed: false
        }
      });
    case FETCH_GROUP_MEMBER_LIST_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupMemberListError: action.payload,
          completed: false
        }
      });
    case CLEAR_GROUP_MEMBER_LIST:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          groupMemberList: []
        }
      });
    default:
      return state;
  }
}
export default groupMemberList;

import {
  GET_CONVERSATION_PENDING,
  GET_CONVERSATION_SUCCESS,
  GET_CONVERSATION_ERROR,
  GET_UN_READ_MESSAGES,
  CLEAR_UN_READ_MESSAGES
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function getConversationPending() {
  return {
    type: GET_CONVERSATION_PENDING
  };
}
export function getConversationSuccess(result) {
  return {
    type: GET_CONVERSATION_SUCCESS,
    payload: result
  };
}

export function getConversationError(error) {
  return {
    type: GET_CONVERSATION_ERROR,
    payload: error
  };
}
export function getUnReadMessages(msg) {
  return {
    type: GET_UN_READ_MESSAGES,
    payload: msg
  };
}
export function clearUnReadMessages(msg) {
  return {
    type: CLEAR_UN_READ_MESSAGES,
    payload: msg
  };
}
export const getConversationResult = (pageNumber, countryCode) => {
  const apiUri = formatUrl(config.getConversation, pageNumber, countryCode);
  return (dispatch) => {
    dispatch(getConversationPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(getConversationSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(getConversationError(error));
      });
  };
};

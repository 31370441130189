import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import '../translations/i18n';
import App from './App';
import { AuthContext } from './context/auth-context';
import LoginPage from './views/Login/Loginpage';
import { RegionalMetaProvider } from './context/region';
import AdalConfigService from './services/adalConfigService';
import AuthProvider from './provider/auth-provider';
import AppWrapper from './AppWrapper';
import { msalApp } from './utils/auth-utils';

// AuthContext.handleRedirectPromise();
const accessTokenRequest = {
  scopes: ['user.read']
};

// Extra callback logic, only in the actual application, not in iframes in the app
if (window === window.parent && window === window.top) {
  console.log('AuthContext>>', AuthContext);
  console.log('msalApp index ???', msalApp);
  // Having both of these checks is to prevent having a token in localstorage, but no user.

  ReactDOM.render(
    <RegionalMetaProvider>
      {/* <AuthProvider instance={AuthContext}> */}
      <AppWrapper />
      {/* </AuthProvider> */}
    </RegionalMetaProvider>,
    document.getElementById('root')
  );

  // AuthContext.login();

  // or render something that everyone can see
  // else {

  // AuthContext.acquireTokenSilent(
  //   AdalConfigService.AdalConfig.endpoints.api,
  //   (message, token, msg) => {
  //     ReactDOM.render(<App AuthContext={AuthContext} />, document.getElementById('root'));
  //   }
  // );
  // AuthContext.acquireTokenSilent({
  //   scopes: ['user.read']
  // })
  //   .then((response) => {
  //     // this.setState(response.accessToken);
  //     let token = response.accessToken;
  //     ReactDOM.render(<App AuthContext={AuthContext} />, document.getElementById('root'));
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //   });
  // }
}

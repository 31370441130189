const initialState = {
    data: [],
    loading: true
  };
  
  function uploadYoutubeList(state = initialState, action) {
    switch (action.type) {
      case 'UPLOAD_YOUTUBE_PENDING':
        return Object.assign({}, state, {
          ...state,
          loading: action.payload
        });
      case 'UPLOAD_YOUTUBE_SUCCESS':
        return Object.assign({}, state, {
          ...state,
          data: [
            {
              uploadVideoList: action.payload,
              completed: false
            }
          ]
        });
      default:
        return state;
    }
  }
  
  export default uploadYoutubeList;
  
import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { Translation } from 'react-i18next';

const changeRoute = () => {
  localStorage.clear();
  window.location.href = '/';
};
const ErrorMessage = () => {
  return (
    <Result
      status='403'
      title='403'
      subTitle='Your session has expired. Please login again.'
      extra={
        <Button type='primary' onClick={() => changeRoute()}>
          <Translation>{(t) => <>{t('Login')}</>}</Translation>
        </Button>
      }
    />
  );
};
export default ErrorMessage;

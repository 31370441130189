import React, { Component } from 'react';
import { connect } from 'react-redux';
import jwtDecode from 'jwt-decode';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { Drawer, Card, Form, Select, Input, InputNumber, Button, Modal } from 'antd';
import { toggleGroupUpdateDrawer } from '../../../../actions/groupUpdate';
import NewGroupCreatBg from '../../../../assets/img/new-group-illustration.svg';
import GlobalSpinner from '../../../commonComponents/globalSpinner/globalSpinner';

import './groupdrawer.css';
import {
  fetchRegionAndCrops,
  fetchCrops,
  fetchCropHistory,
  fetchTags,
  fetchZone,
  fetchArea,
  fetchLanguage,
  fetchCity,
  fetchBrand,
  fetchLandSize,
  fetchSource,
  fetchDistrict,
  fetchAcares,
  fetchRetailerName,
  fetchTMHQ,
  fetchClassification,
  fetchSegment,
  fetchProvince,
  fetchMDOHQ,
  fetchState,
  fetchVillage,
  fetchTaluka,
  fetchTVC,
  fetchWA,
  fetchFB,
  fetchYB,
  fetchIG,
  fetchSeasonSegmentation,
  fetchprefferedHybridCorn,
  fetchsytGrowerRice,
  fetchsytGrowerCorn,
  fetchprefferedHybridRice,
  fetchPramukhTag
} from '../../../../services/groupFilterService';
import { createGroup } from '../../../../services/groupCreateService';
import { groupUpdate } from '../../../../actions/groupUpdate';
import { toggleGroupDrawer } from '../../../../actions/groupCreate';

const { Option } = Select;
const { confirm } = Modal;

class UpdateGroupDrawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCount: 0,
      showError: false,
      snapshot: '',
      acresValue: '',
      opetatorValue: ''
    };
    this.DrawerOnClose = this.DrawerOnClose.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
  }
  handleChange = (value) => {
    this.setState({
      selectedCount: value.target.value
    });
    this.props.form.setFieldsValue({
      GrowersCount: value.target.value
    });
  };

  validateFieldData = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value)) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  handleSubmit = (e, props) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.form.setFieldsValue('10');
        const emailId = props.Context.auth.account.userName;
        // const emailId = props.Context.AuthContext._user.userName;
        const groupId = this.props.editData.key;
        const groupData = {
          name: values.Name,
          type: 'Dynamic',

          filters: [
            {
              name: 'crops',
              items: values.GrowersCrop || []
            },
            {
              name: 'regions',
              items: values.GrowersRegion || []
            },
            {
              name: 'acres',
              operator: {
                name: values.operator || ''
              },
              value: values.acresValue
            },
            {
              name: 'tags',
              items: values.GrowersTags || []
            },
            {
              name: 'cropHistory',
              items: values.GrowersCropHistory || []
            },
            {
              name: 'zone',
              items: values.GrowersZone || []
            },
            {
              name: 'area',
              items: values.GrowersArea || []
            },
            {
              name: 'language',
              items: values.GrowerLanguage || []
            },
            {
              name: 'city',
              items: values.GrowersCity || []
            },
            {
              name: 'brand',
              items: values.GrowersBrand || []
            },
            {
              name: 'district',
              items: values.GrowersDistrict || []
            },
            {
              name: 'retailerName',
              items: values.GrowersRetailerName || []
            },
            {
              name: 'salesUnit',
              items: values.GrowersStockistCode || []
            },
            {
              name: 'tmhq',
              items: values.GrowersTmhq || []
            },
            {
              name: 'classification',
              items: values.GrowersClassification || []
            },
            {
              name: 'segment',
              items: values.GrowersSegment || []
            },
            {
              name: 'province',
              items: values.GrowersProvince || []
            },
            {
              name: 'pramukhTag',
              items: values.GrowersPramukhTag || []
            },
            {
              name: 'mdohq',
              items: values.GrowersMdohq || []
            },
            {
              name: 'block',
              items: values.GrowersTaluka || []
            },
            {
              name: 'village',
              items: values.GrowersVillage || []
            },
            {
              name: 'state',
              items: values.GrowersState || []
            },
            {
              name: 'TVC',
              items: values.GrowersTVC || []
            },
            {
              name: 'WA',
              items: values.GrowersWA || []
            },
            {
              name: 'FB',
              items: values.GrowersFB || []
            },
            {
              name: 'YB',
              items: values.GrowersYB || []
            },
            {
              name: 'IG',
              items: values.GrowersIG || []
            },
            {
              name: 'sytGrowerCorn',
              items: values.GrowersSytGrowerCorn || []
            },
            {
              name: 'sytGrowerRice',
              items: values.GrowersSytGrowerRice || []
            },
            {
              name: 'prefferedHybridCorn',
              items: values.GrowersPrefferedCorn || []
            },
            {
              name: 'prefferedHybridRice',
              items: values.GrowersPrefferedRice || []
            },
            {
              name: 'seasonSegmentation',
              items: values.GrowersSeasonSegementation || []
            }
          ]
        };
        if (
          !isEmpty(values.GrowersCrop) ||
          !isEmpty(values.GrowersRegion) ||
          !isEmpty(values.operator) ||
          !isEmpty(values.acresValue) ||
          !isEmpty(values.GrowersTags) ||
          !isEmpty(values.GrowersCropHistory) ||
          !isEmpty(values.GrowersZone) ||
          !isEmpty(values.GrowersArea) ||
          !isEmpty(values.GrowerLanguage) ||
          !isEmpty(values.GrowersCity) ||
          !isEmpty(values.GrowersRetailerName) ||
          !isEmpty(values.GrowersStockistCode) ||
          !isEmpty(values.GrowersBrand) ||
          !isEmpty(values.GrowersDistrict) ||
          !isEmpty(values.GrowersTmhq) ||
          !isEmpty(values.GrowersClassification) ||
          !isEmpty(values.GrowersSegment) ||
          !isEmpty(values.GrowersProvince) ||
          !isEmpty(values.GrowersPramukhTag) ||
          !isEmpty(values.GrowersMdohq) ||
          !isEmpty(values.GrowersState) ||
          !isEmpty(values.GrowersVillage) ||
          !isEmpty(values.GrowersTaluka) ||
          !isEmpty(values.GrowersTVC) ||
          !isEmpty(values.GrowersWA) ||
          !isEmpty(values.GrowersFB) ||
          !isEmpty(values.GrowersYB) ||
          !isEmpty(values.GrowersIG) ||
          !isEmpty(values.GrowersSytGrowerRice) ||
          !isEmpty(values.GrowersSytGrowerCorn) ||
          !isEmpty(values.GrowersPrefferedCorn) ||
          !isEmpty(values.GrowersPrefferedRice) ||
          !isEmpty(values.GrowersSeasonSegementation)
        ) {
          this.props.groupUpdate(groupData, emailId, groupId, this.props.pageNumber);
          this.props.resetPage();
          this.setState({
            acresValue: '',
            opetatorValue: ''
          });
        } else {
          this.setState({
            showError: true
          });
        }
      }
    });
  };
  toCamelCase = (str) => {
    const string = str.toLowerCase().replace(/(?:(^.)|([-_\s]+.))/g, function(match) {
      return match.charAt(match.length - 1).toUpperCase();
    });
    return string.charAt(0).toLowerCase() + string.substring(1);
  };

  handleRegionSelectChange = (Rvalue) => {
    this.props.form.setFieldsValue({
      GrowersCount: Rvalue
    });
  };

  handleCropSelectChange = (Cvalue) => {
    this.props.form.setFieldsValue({
      GrowersCount: Cvalue
    });
  };
  editData = (id, data) => {};
  componentWillReceiveProps(preProps) {
    if (preProps.editData !== this.props.editData) {
      this.editData(this.props.groupId, this.props.editData);
    }
  }
  componentDidUpdate(preProps, preState) {
    if (this.state.showError) {
      let filterValue = this.props.form.getFieldsValue();
      for (const key in filterValue) {
        if (key !== 'Name' && filterValue[key] && filterValue[key].length > 0) {
          this.setState({
            showError: false
          });
        }
      }
    }
    if (preProps.editData !== this.props.editData) {
      this.editData(this.props.groupId, this.props.editData);
    }
  }
  handleAcaresSelectAll = (value) => {
    let cropsData = this.props.groupCrops || [];
    let cropText = cropsData.length > 0 ? cropsData[0].items : [];
    const allCrop = [];
    for (let i = 0; i < cropText.length; i++) {
      if (cropText[i]) {
        allCrop.push(cropText[i]);
      }
    }
    if (value && value.length && value.includes('allCrop')) {
      if (value.length === allCrop.length + 1) {
        return [];
      }
      return [...allCrop];
    } else {
      return value;
    }
  };

  DrawerOnClose() {
    this.props.toggleGroupUpdateDrawer();
    this.setState({
      acresValue: '',
      opetatorValue: ''
    });
    // this.showDeleteConfirm(this.props);
  }
  showDeleteConfirm(propsdata) {
    confirm({
      title: 'Are you sure close this task?',
      content: 'After closing you loose your data',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        propsdata.toggleGroupDrawer();
      },
      onCancel() {}
    });
  }
  getOperatorValue = (value) => {
    this.setState({
      opetatorValue: value
    });
  };
  getAcresValue = (e) => {
    this.setState({
      acresValue: value
    });
  };
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    return value.toString().split('.')[1].length || 0;
  };
  onKeyDownEvent = (e) => {
    var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
    if (this.countDecimals(e.target.value) > 1) {
      e.preventDefault();
      return false;
    }
  };
  bdGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchDistrict();
    this.props.fetchBrand();
    this.props.fetchRetailerName();
    this.props.fetchTMHQ();
    this.props.fetchTags();
    this.props.fetchSource();
  };
  inGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchDistrict();
    this.props.fetchPramukhTag();
    this.props.fetchTMHQ();
    this.props.fetchMDOHQ();
    this.props.fetchIG();
    this.props.fetchYB();
    this.props.fetchFB();
    this.props.fetchWA();
    this.props.fetchTVC();
    this.props.fetchTaluka();
    this.props.fetchVillage();
    this.props.fetchState();
    this.props.fetchSeasonSegmentation();
    this.props.fetchprefferedHybridRice();
    this.props.fetchsytGrowerRice();
    this.props.fetchsytGrowerCorn();
    this.props.fetchprefferedHybridCorn();
  };
  idnGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchRegionAndCrops();
    this.props.fetchArea();
    this.props.fetchCropHistory();
    this.props.fetchTags();
  };
  phGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchRegionAndCrops();
    this.props.fetchCity();
    this.props.fetchTags();
  };
  myGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchRegionAndCrops();
    this.props.fetchLanguage();
  };
  thGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchZone();
    this.props.fetchCropHistory();
    this.props.fetchCity();
  };
  krGroupFilterApi = () => {
    this.props.fetchClassification();
    this.props.fetchSegment();
    this.props.fetchProvince();
    this.props.fetchCrops();
    this.props.fetchCropHistory();
    this.props.fetchCity();
    this.props.fetchTags();
  };
  defaultGroupFilterApi = () => {
    this.props.fetchCrops();
    this.props.fetchCropHistory();
    this.props.fetchRegionAndCrops();
    this.props.fetchTags();
  };
  renderGroupFilter = (country) => {
    switch (country) {
      case 'bd':
        return this.bdGroupFilterApi();
      case 'idn':
        return this.idnGroupFilterApi();
      case 'th':
        return this.thGroupFilterApi();
      case 'my':
        return this.myGroupFilterApi();
      case 'ph':
        return this.phGroupFilterApi();
      case 'in':
        return this.inGroupFilterApi();
      case 'kr':
        return this.krGroupFilterApi();
      default:
        return this.defaultGroupFilterApi();
    }
  };
  componentDidMount() {
    this.renderGroupFilter(this.props.country);
    // this.props.fetchRegionAndCrops();
    // this.props.fetchCrops();
    // this.props.fetchTags();
    // this.props.fetchCropHistory();
    // this.props.fetchZone();
    // this.props.fetchArea();
    // this.props.fetchAcares();
    // this.props.fetchLanguage();
    // this.props.fetchCity();
    // this.props.fetchBrand();
    // this.props.fetchDistrict();
    // this.props.fetchLandSize();
    // this.props.fetchSource();
    // this.props.fetchRetailerName();
    // this.props.fetchTMHQ();
    // this.props.fetchClassification();
    // this.props.fetchSegment();
    // this.props.fetchProvince();
    // this.props.fetchMDOHQ();
    // this.props.fetchIG();
    // this.props.fetchYB();
    // this.props.fetchFB();
    // this.props.fetchWA();
    // this.props.fetchTVC();
    // this.props.fetchTaluka();
    // this.props.fetchVillage();
    // this.props.fetchState();
    // this.props.fetchSeasonSegmentation();
    // this.props.fetchprefferedHybridRice();
    // this.props.fetchsytGrowerRice();
    // this.props.fetchsytGrowerCorn();
    // this.props.fetchprefferedHybridCorn();
  }
  validateField = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value)) {
      callback('Please enter a number');
    } else {
      callback();
    }
  };
  validateFieldGroup = (rule, value, callback) => {
    if (value === '' || value === undefined || value === 'undefined') {
      callback('Please Enter Group Name');
    }
  };
  render() {
    const {
      groupFilterReducer,
      groupTags,
      groupCrops,
      groupCropHistory,
      groupZone,
      groupArea,
      drawerData,
      groupLanguage,
      groupCity,
      groupBrand,
      groupLandSize,
      groupDistrict,
      groupAcares,
      groupRetailerName,
      groupStockistCode,
      groupTmhq,
      groupList,
      groupClassification,
      groupProvince,
      groupSegment,
      pramukhTags,
      groupMdohq,
      groupState,
      groupVillage,
      groupTaluka,
      groupTVC,
      groupWA,
      groupFB,
      groupYB,
      groupIG,
      groupSeasonSegementation,
      groupSytGrowerCorn,
      groupSytGrowerRice,
      groupPrefferedCorn,
      groupPrefferedRice,
      t,
      acresVisible
    } = this.props;
    let regionData = groupFilterReducer || [];
    let tagsData = groupTags || [];
    let cropsData = groupCrops || [];
    let zoneData = groupZone || [];
    let areaData = groupArea || [];
    let cropHistoryData = groupCropHistory || [];
    let pramukhTagData = pramukhTags || [];
    let cityData = groupCity || [];
    let brandData = groupBrand || [];
    let acresData = groupAcares || [];
    let retailerData = groupRetailerName || [];
    let districtData = groupDistrict || [];
    let landSizeData = groupLandSize || [];
    let stockistCodeData = groupStockistCode || [];
    let classificationData = groupClassification || [];
    let provinceData = groupProvince || [];
    let segmentData = groupSegment || [];
    let tmhqData = groupTmhq || [];
    let mdohqData = groupMdohq || [];
    let stateData = groupState || [];
    let villageData = groupVillage || [];
    let talukaData = groupTaluka || [];
    let tvcData = groupTVC || [];
    let waData = groupWA || [];
    let fbData = groupFB || [];
    let ybData = groupYB || [];
    let igData = groupIG || [];
    let sytGrowerCornData = groupSytGrowerCorn || [];
    let sytGrowerRiceData = groupSytGrowerRice || [];
    let prefferedCornData = groupPrefferedCorn || [];
    let prefferedRiceData = groupPrefferedRice || [];
    let seasonSegementationData = groupSeasonSegementation || [];
    let regionText = regionData.length > 0 ? regionData[0].items : [];
    let tagsText = tagsData.length > 0 ? tagsData[0].items : [];
    let handler = 'handleCropSelectAll';
    let pramukhTagText = pramukhTagData.length > 0 ? pramukhTagData[0].items : [];
    let cropText = cropsData.length > 0 ? cropsData[0].items : [];
    let classificationText = classificationData.length > 0 ? classificationData[0].items : [];
    let provinceText = provinceData.length > 0 ? provinceData[0].items : [];
    let segmentText = segmentData.length > 0 ? segmentData[0].items : [];
    let cropHistoryText = cropHistoryData.length > 0 ? cropHistoryData[0].items : [];
    let zoneText = zoneData.length > 0 ? zoneData[0].items : [];
    let cityText = cityData.length > 0 ? cityData[0].items : [];
    let brandText = brandData.length > 0 ? brandData[0].items : [];
    let districtText = districtData.length > 0 ? districtData[0].items : [];
    let acaresText = acresData.length > 0 ? acresData[0].items : [];
    let retailerText = retailerData.length > 0 ? retailerData[0].items : [];
    let landSizeText = landSizeData.length > 0 ? landSizeData[0].items : [];
    let stockistCodeText = stockistCodeData.length > 0 ? stockistCodeData[0].items : [];
    let tmhqText = tmhqData.length > 0 ? tmhqData[0].items : [];
    let areaText = areaData.length > 0 ? areaData[0].items : [];
    let mdohqText = mdohqData.length > 0 ? mdohqData[0].items : [];
    let stateText = stateData.length > 0 ? stateData[0].items : [];
    let villageText = villageData.length > 0 ? villageData[0].items : [];
    let talukaText = talukaData.length > 0 ? talukaData[0].items : [];
    let tvcText = tvcData.length > 0 ? tvcData[0].items : [];
    let waText = waData.length > 0 ? waData[0].items : [];
    let fbText = fbData.length > 0 ? fbData[0].items : [];
    let ybText = ybData.length > 0 ? ybData[0].items : [];
    let igText = igData.length > 0 ? igData[0].items : [];
    let sytGrowerCornText = sytGrowerCornData.length > 0 ? sytGrowerCornData[0].items : [];
    let sytGrowerRiceText = sytGrowerRiceData.length > 0 ? sytGrowerRiceData[0].items : [];
    let prefferedCornText = prefferedCornData.length > 0 ? prefferedCornData[0].items : [];
    let prefferedRiceText = prefferedRiceData.length > 0 ? prefferedRiceData[0].items : [];
    let seasonSegementationText =
      seasonSegementationData.length > 0 ? seasonSegementationData[0].items : [];
    let languageText = groupLanguage.length > 0 ? groupLanguage[0].items : [];
    console.log('classificationText>', classificationText);
    const { getFieldDecorator } = this.props.form;

    const handleRegionSelectAll = (value) => {
      let regionData = this.props.groupFilterReducer || [];
      let regionText = regionData.length > 0 ? regionData[0].items : [];
      const allRegion = [];
      for (let i = 0; i < regionText.length; i++) {
        if (regionText[i]) {
          allRegion.push(regionText[i]);
        }
      }
      if (value && value.length && value.includes('allRegion')) {
        if (value.length === allRegion.length + 1) {
          return [];
        }
        return [...allRegion];
      } else {
        return value;
      }
    };

    const handleTagsSelectAll = (value) => {
      let tagsData = this.props.groupTags || [];
      let tagsText = tagsData.length > 0 ? tagsData[0].items : [];
      const allTags = [];
      for (let i = 0; i < tagsText.length; i++) {
        if (tagsText[i]) {
          allTags.push(tagsText[i]);
        }
      }
      if (value && value.length && value.includes('allTags')) {
        if (value.length === allTags.length + 1) {
          return [];
        }
        return [...allTags];
      } else {
        return value;
      }
    };

    const handleCropHistorySelectAll = (value) => {
      let cropHistoryData = this.props.groupCropHistory || [];
      let cropHistoryDataText = cropHistoryData.length > 0 ? cropHistoryData[0].items : [];
      const allCropHistory = [];
      for (let i = 0; i < cropHistoryDataText.length; i++) {
        if (cropHistoryDataText[i]) {
          allCropHistory.push(cropHistoryDataText[i]);
        }
      }
      if (value && value.length && value.includes('allCropHistory')) {
        if (value.length === allCropHistory.length + 1) {
          return [];
        }
        return [...allCropHistory];
      } else {
        return value;
      }
    };
    const handleZoneSelectAll = (value) => {
      let zoneData = this.props.groupZone || [];
      let zoneDataText = zoneData.length > 0 ? zoneData[0].items : [];
      const allZone = [];
      for (let i = 0; i < zoneDataText.length; i++) {
        if (zoneDataText[i]) {
          allZone.push(zoneDataText[i]);
        }
      }
      if (value && value.length && value.includes('allZone')) {
        if (value.length === allZone.length + 1) {
          return [];
        }
        return [...allZone];
      } else {
        return value;
      }
    };
    const handleAreaSelectAll = (value) => {
      let areaData = this.props.groupArea || [];
      let areaDataText = areaData.length > 0 ? areaData[0].items : [];
      const allArea = [];
      for (let i = 0; i < areaDataText.length; i++) {
        if (areaDataText[i]) {
          allArea.push(areaDataText[i]);
        }
      }
      if (value && value.length && value.includes('allArea')) {
        if (value.length === allArea.length + 1) {
          return [];
        }
        return [...allArea];
      } else {
        return value;
      }
    };
    const handleCropSelectAll = (value) => {
      let cropsData = this.props.groupCrops || [];
      let cropText = cropsData.length > 0 ? cropsData[0].items : [];
      const allCrop = [];
      for (let i = 0; i < cropText.length; i++) {
        if (cropText[i]) {
          allCrop.push(cropText[i]);
        }
      }
      if (value && value.length && value.includes('allCrop')) {
        if (value.length === allCrop.length + 1) {
          return [];
        }
        return [...allCrop];
      } else {
        return value;
      }
    };
    const handleLanguageSelectAll = (value) => {
      let languageData = this.props.groupLanguage || [];
      let languageText = languageData.length > 0 ? languageData[0].items : [];
      const allLanguage = [];
      for (let i = 0; i < languageText.length; i++) {
        if (languageText[i]) {
          allLanguage.push(languageText[i]);
        }
      }
      if (value && value.length && value.includes('allLanguage')) {
        if (value.length === allLanguage.length + 1) {
          return [];
        }
        return [...allLanguage];
      } else {
        return value;
      }
    };

    const handleCitySelectAll = (value) => {
      let cityData = this.props.groupCity || [];
      let cityDataText = cityData.length > 0 ? cityData[0].items : [];
      const allCity = [];
      for (let i = 0; i < cityDataText.length; i++) {
        if (cityDataText[i]) {
          allCity.push(cityDataText[i]);
        }
      }
      if (value && value.length && value.includes('allCity')) {
        if (value.length === allCity.length + 1) {
          return [];
        }
        return [...allCity];
      } else {
        return value;
      }
    };
    const handleBrandSelectAll = (value) => {
      let brandData = this.props.groupBrand || [];
      let brandDataText = brandData.length > 0 ? brandData[0].items : [];
      const allBrand = [];
      for (let i = 0; i < brandDataText.length; i++) {
        if (brandDataText[i]) {
          allBrand.push(brandDataText[i]);
        }
      }
      if (value && value.length && value.includes('allBrand')) {
        if (value.length === allBrand.length + 1) {
          return [];
        }
        return [...allBrand];
      } else {
        return value;
      }
    };
    const handlePramukhTagSelectAll = (value) => {
      let pramukhTagData = this.props.PramukhTags || [];
      let pramukhTagText = pramukhTagData.length > 0 ? pramukhTagData[0].items : [];
      const allPramukhTag = [];
      for (let i = 0; i < pramukhTagText.length; i++) {
        if (pramukhTagText[i]) {
          allPramukhTag.push(pramukhTagText[i]);
        }
      }
      if (value && value.length && value.includes('allPramukhTag')) {
        if (value.length === allPramukhTag.length + 1) {
          return [];
        }
        return [...allPramukhTag];
      } else {
        return value;
      }
    };
    const handleAcresSelectAll = (value) => {
      let acaresData = this.props.groupAcares || [];
      let acaresDataText = acaresData.length > 0 ? acaresData[0].items : [];
      const allAcares = [];
      for (let i = 0; i < acaresDataText.length; i++) {
        if (acaresDataText[i]) {
          allAcares.push(acaresDataText[i]);
        }
      }
      if (value && value.length && value.includes('allAcares')) {
        if (value.length === allAcares.length + 1) {
          return [];
        }
        return [...allAcares];
      } else {
        return value;
      }
    };
    const handleDistrictSelectAll = (value) => {
      let districtData = this.props.groupDistrict || [];
      let districtDataText = districtData.length > 0 ? districtData[0].items : [];
      const allDistrict = [];
      for (let i = 0; i < districtDataText.length; i++) {
        if (districtDataText[i]) {
          allDistrict.push(districtDataText[i]);
        }
      }
      if (value && value.length && value.includes('allDistrict')) {
        if (value.length === allDistrict.length + 1) {
          return [];
        }
        return [...allDistrict];
      } else {
        return value;
      }
    };
    const handleRetailerNameSelectAll = (value) => {
      let retailerData = this.props.groupRetailerName || [];
      let retailerDataText = retailerData.length > 0 ? retailerData[0].items : [];
      const allRetailerName = [];
      for (let i = 0; i < retailerDataText.length; i++) {
        if (retailerDataText[i]) {
          allRetailerName.push(retailerDataText[i]);
        }
      }
      if (value && value.length && value.includes('allRetailerName')) {
        if (value.length === allRetailerName.length + 1) {
          return [];
        }
        return [...allRetailerName];
      } else {
        return value;
      }
    };
    const handleStockistCodeSelectAll = (value) => {
      let stockistCodeData = this.props.groupStockistCode || [];
      let stockistCodeDataText = stockistCodeData.length > 0 ? stockistCodeData[0].items : [];
      const allStockistCode = [];
      for (let i = 0; i < stockistCodeDataText.length; i++) {
        if (stockistCodeDataText[i]) {
          allStockistCode.push(stockistCodeDataText[i]);
        }
      }
      if (value && value.length && value.includes('allStockistCode')) {
        if (value.length === allStockistCode.length + 1) {
          return [];
        }
        return [...allStockistCode];
      } else {
        return value;
      }
    };
    const handleTmqhSelectAll = (value) => {
      let tmhqData = this.props.groupTmhq || [];
      let tmhqDataText = tmhqData.length > 0 ? tmhqData[0].items : [];
      const allTmhq = [];
      for (let i = 0; i < tmhqDataText.length; i++) {
        if (tmhqDataText[i]) {
          allTmhq.push(tmhqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allTmhq')) {
        if (value.length === allTmhq.length + 1) {
          return [];
        }
        return [...allTmhq];
      } else {
        return value;
      }
    };
    const handleClassificationSelectAll = (value) => {
      let classificationData = this.props.groupClassification || [];
      let classificationText = classificationData.length > 0 ? classificationData[0].items : [];
      const allClassification = [];
      for (let i = 0; i < classificationText.length; i++) {
        if (classificationText[i]) {
          allClassification.push(classificationText[i]);
        }
      }
      if (value && value.length && value.includes('allClassification')) {
        if (value.length === allClassification.length + 1) {
          return [];
        }
        return [...allClassification];
      } else {
        return value;
      }
    };
    const handleProvinceSelectAll = (value) => {
      let provinceData = this.props.groupProvince || [];
      let provinceText = provinceData.length > 0 ? provinceData[0].items : [];
      const allProvince = [];
      for (let i = 0; i < provinceText.length; i++) {
        if (provinceText[i]) {
          allProvince.push(provinceText[i]);
        }
      }
      if (value && value.length && value.includes('allProvince')) {
        if (value.length === allProvince.length + 1) {
          return [];
        }
        return [...allProvince];
      } else {
        return value;
      }
    };
    const handleSegmentSelectAll = (value) => {
      let segmentData = this.props.groupSegment || [];
      let segmentText = segmentData.length > 0 ? segmentData[0].items : [];
      const allSegment = [];
      for (let i = 0; i < segmentText.length; i++) {
        if (segmentText[i]) {
          allSegment.push(segmentText[i]);
        }
      }
      if (value && value.length && value.includes('allSegment')) {
        if (value.length === allSegment.length + 1) {
          return [];
        }
        return [...allSegment];
      } else {
        return value;
      }
    };
    const handleMdohqSelectAll = (value) => {
      let mdohqData = this.props.groupMdohq || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allMdohq = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allMdohq.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allMdohq')) {
        if (value.length === allMdohq.length + 1) {
          return [];
        }
        return [...allMdohq];
      } else {
        return value;
      }
    };
    const handleStateSelectAll = (value) => {
      let mdohqData = this.props.groupState || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allState = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allState.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allState')) {
        if (value.length === allState.length + 1) {
          return [];
        }
        return [...allState];
      } else {
        return value;
      }
    };
    const handleTalukaSelectAll = (value) => {
      let mdohqData = this.props.groupTaluka || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allTaluka = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allTaluka.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allTaluka')) {
        if (value.length === allTaluka.length + 1) {
          return [];
        }
        return [...allTaluka];
      } else {
        return value;
      }
    };
    const handleVillageSelectAll = (value) => {
      let mdohqData = this.props.groupVillage || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allVillage = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allVillage.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allVillage')) {
        if (value.length === allVillage.length + 1) {
          return [];
        }
        return [...allVillage];
      } else {
        return value;
      }
    };
    const handleTVCSelectAll = (value) => {
      let mdohqData = this.props.groupTVC || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allTVC = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allTVC.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allTVC')) {
        if (value.length === allTVC.length + 1) {
          return [];
        }
        return [...allTVC];
      } else {
        return value;
      }
    };
    const handleWASelectAll = (value) => {
      let mdohqData = this.props.groupWA || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allWA = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allWA.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allWA')) {
        if (value.length === allWA.length + 1) {
          return [];
        }
        return [...allWA];
      } else {
        return value;
      }
    };
    const handleFBSelectAll = (value) => {
      let mdohqData = this.props.groupFB || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allFB = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allFB.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allFB')) {
        if (value.length === allFB.length + 1) {
          return [];
        }
        return [...allFB];
      } else {
        return value;
      }
    };
    const handleYBSelectAll = (value) => {
      let mdohqData = this.props.groupYB || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allYB = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allYB.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allYB')) {
        if (value.length === allYB.length + 1) {
          return [];
        }
        return [...allYB];
      } else {
        return value;
      }
    };
    const handleIGSelectAll = (value) => {
      let mdohqData = this.props.groupIG || [];
      let mdohqDataText = mdohqData.length > 0 ? mdohqData[0].items : [];
      const allIG = [];
      for (let i = 0; i < mdohqDataText.length; i++) {
        if (mdohqDataText[i]) {
          allIG.push(mdohqDataText[i]);
        }
      }
      if (value && value.length && value.includes('allIG')) {
        if (value.length === allIG.length + 1) {
          return [];
        }
        return [...allIG];
      } else {
        return value;
      }
    };
    const handleSytGrowerCornSelectAll = (value) => {
      let sytGrowerCornData = this.props.groupSytGrowerCorn || [];
      let sytGrowerCornDataText = sytGrowerCornData.length > 0 ? sytGrowerCornData[0].items : [];
      const allSytGrowerCorn = [];
      for (let i = 0; i < sytGrowerCornDataText.length; i++) {
        if (sytGrowerCornDataText[i]) {
          allSytGrowerCorn.push(sytGrowerCornDataText[i]);
        }
      }
      if (value && value.length && value.includes('allSytGrowerCorn')) {
        if (value.length === allSytGrowerCorn.length + 1) {
          return [];
        }
        return [...allSytGrowerCorn];
      } else {
        return value;
      }
    };
    const handleSytGrowerRiceSelectAll = (value) => {
      let sytGrowerRiceData = this.props.groupSytGrowerRice || [];
      let sytGrowerRiceDataText = sytGrowerRiceData.length > 0 ? sytGrowerRiceData[0].items : [];
      const allSytGrowerRice = [];
      for (let i = 0; i < sytGrowerRiceDataText.length; i++) {
        if (sytGrowerRiceDataText[i]) {
          allSytGrowerRice.push(sytGrowerRiceDataText[i]);
        }
      }
      if (value && value.length && value.includes('allSytGrowerRice')) {
        if (value.length === allSytGrowerRice.length + 1) {
          return [];
        }
        return [...allSytGrowerRice];
      } else {
        return value;
      }
    };
    const handlePrefferedCornSelectAll = (value) => {
      let prefferedCornData = this.props.groupPrefferedCorn || [];
      let prefferedCornDataText = prefferedCornData.length > 0 ? prefferedCornData[0].items : [];
      const allPrefferedCorn = [];
      for (let i = 0; i < prefferedCornDataText.length; i++) {
        if (prefferedCornDataText[i]) {
          allPrefferedCorn.push(prefferedCornDataText[i]);
        }
      }
      if (value && value.length && value.includes('allPrefferedCorn')) {
        if (value.length === allPrefferedCorn.length + 1) {
          return [];
        }
        return [...allPrefferedCorn];
      } else {
        return value;
      }
    };
    const handlePrefferedRiceSelectAll = (value) => {
      let prefferedRiceData = this.props.groupPrefferedRice || [];
      let prefferedRiceDataText = prefferedRiceData.length > 0 ? prefferedRiceData[0].items : [];
      const allPrefferedRice = [];
      for (let i = 0; i < prefferedRiceDataText.length; i++) {
        if (prefferedRiceDataText[i]) {
          allPrefferedRice.push(prefferedRiceDataText[i]);
        }
      }
      if (value && value.length && value.includes('allPrefferedRice')) {
        if (value.length === allPrefferedRice.length + 1) {
          return [];
        }
        return [...allPrefferedRice];
      } else {
        return value;
      }
    };
    const handleSeasonSegementationSelectAll = (value) => {
      let seasonSegementationData = this.props.groupSeasonSegementation || [];
      let seasonSegementationDataText =
        seasonSegementationData.length > 0 ? seasonSegementationData[0].items : [];
      const allSeasonSegementation = [];
      for (let i = 0; i < seasonSegementationDataText.length; i++) {
        if (seasonSegementationDataText[i]) {
          allSeasonSegementation.push(seasonSegementationDataText[i]);
        }
      }
      if (value && value.length && value.includes('allSeasonSegementation')) {
        if (value.length === allSeasonSegementation.length + 1) {
          return [];
        }
        return [...allSeasonSegementation];
      } else {
        return value;
      }
    };
    const childrenRegion = [];
    for (let i = 0; i < regionText.length; i++) {
      if (regionText[i]) {
        childrenRegion.push(<Option key={regionText[i]}>{regionText[i]}</Option>);
      } else {
      }
    }

    const childrenCrop = [];
    for (let i = 0; i < cropText.length; i++) {
      if (cropText[i]) {
        childrenCrop.push(<Option key={cropText[i]}>{cropText[i]}</Option>);
      } else {
      }
    }

    const childrenTags = [];
    for (let i = 0; i < tagsText.length; i++) {
      if (tagsText[i]) {
        childrenTags.push(<Option key={tagsText[i]}>{tagsText[i]}</Option>);
      } else {
      }
    }

    const childrenCropHistory = [];
    for (let i = 0; i < cropHistoryText.length; i++) {
      if (cropHistoryText[i]) {
        childrenCropHistory.push(<Option key={cropHistoryText[i]}>{cropHistoryText[i]}</Option>);
      } else {
      }
    }
    const childrenZone = [];
    for (let i = 0; i < zoneText.length; i++) {
      if (zoneText[i]) {
        childrenZone.push(<Option key={zoneText[i]}>{zoneText[i]}</Option>);
      } else {
      }
    }
    const childrenPramukhTag = [];
    for (let i = 0; i < pramukhTagText.length; i++) {
      if (pramukhTagText[i]) {
        childrenPramukhTag.push(<Option key={pramukhTagText[i]}>{pramukhTagText[i]}</Option>);
      } else {
      }
    }
    const childrenArea = [];
    for (let i = 0; i < areaText.length; i++) {
      if (areaText[i]) {
        childrenArea.push(<Option key={areaText[i]}>{areaText[i]}</Option>);
      } else {
      }
    }
    const childrenLanguage = [];
    for (let i = 0; i < languageText.length; i++) {
      if (languageText[i]) {
        childrenLanguage.push(<Option key={languageText[i]}>{languageText[i]}</Option>);
      } else {
      }
    }
    const childrenCity = [];
    for (let i = 0; i < cityText.length; i++) {
      if (cityText[i]) {
        childrenCity.push(<Option key={cityText[i]}>{cityText[i]}</Option>);
      } else {
      }
    }
    const childrenBrand = [];
    for (let i = 0; i < brandText.length; i++) {
      if (brandText[i]) {
        childrenBrand.push(<Option key={brandText[i]}>{brandText[i]}</Option>);
      } else {
      }
    }
    const childrenAcares = [];
    for (let i = 0; i < acaresText.length; i++) {
      if (acaresText[i]) {
        childrenAcares.push(<Option key={acaresText[i]}>{acaresText[i]}</Option>);
      } else {
      }
    }
    const childrenRetailerName = [];
    for (let i = 0; i < retailerText.length; i++) {
      if (retailerText[i]) {
        childrenRetailerName.push(<Option key={retailerText[i]}>{retailerText[i]}</Option>);
      } else {
      }
    }
    const childrenDistrict = [];
    for (let i = 0; i < districtText.length; i++) {
      if (districtText[i]) {
        childrenDistrict.push(<Option key={districtText[i]}>{districtText[i]}</Option>);
      } else {
      }
    }
    const childrenStockistCode = [];
    for (let i = 0; i < stockistCodeText.length; i++) {
      if (stockistCodeText[i]) {
        childrenStockistCode.push(<Option key={stockistCodeText[i]}>{stockistCodeText[i]}</Option>);
      } else {
      }
    }
    const childrenTmhq = [];
    for (let i = 0; i < tmhqText.length; i++) {
      if (tmhqText[i]) {
        childrenTmhq.push(<Option key={tmhqText[i]}>{tmhqText[i]}</Option>);
      } else {
      }
    }
    const childrenLandSize = [];
    for (let i = 0; i < landSizeText.length; i++) {
      if (landSizeText[i]) {
        childrenLandSize.push(<Option key={landSizeText[i]}>{landSizeText[i]}</Option>);
      } else {
      }
    }
    const childrenClassification = [];
    for (let i = 0; i < classificationText.length; i++) {
      if (classificationText[i]) {
        childrenClassification.push(
          <Option key={classificationText[i]}>{classificationText[i]}</Option>
        );
      } else {
      }
    }
    const childrenProvince = [];
    for (let i = 0; i < provinceText.length; i++) {
      if (provinceText[i]) {
        childrenProvince.push(<Option key={provinceText[i]}>{provinceText[i]}</Option>);
      } else {
      }
    }
    const childrenSegment = [];
    for (let i = 0; i < segmentText.length; i++) {
      if (segmentText[i]) {
        childrenSegment.push(<Option key={segmentText[i]}>{segmentText[i]}</Option>);
      } else {
      }
    }
    const childrenMdohq = [];
    for (let i = 0; i < mdohqText.length; i++) {
      if (mdohqText[i]) {
        childrenMdohq.push(<Option key={mdohqText[i]}>{mdohqText[i]}</Option>);
      } else {
      }
    }
    const childrenTaluka = [];
    for (let i = 0; i < talukaText.length; i++) {
      if (talukaText[i]) {
        childrenTaluka.push(<Option key={talukaText[i]}>{talukaText[i]}</Option>);
      } else {
      }
    }
    const childrenVillage = [];
    for (let i = 0; i < villageText.length; i++) {
      if (villageText[i]) {
        childrenVillage.push(<Option key={villageText[i]}>{villageText[i]}</Option>);
      } else {
      }
    }
    const childrenState = [];
    for (let i = 0; i < stateText.length; i++) {
      if (stateText[i]) {
        childrenState.push(<Option key={stateText[i]}>{stateText[i]}</Option>);
      } else {
      }
    }
    const childrenTVC = [];
    for (let i = 0; i < tvcText.length; i++) {
      if (tvcText[i]) {
        childrenTVC.push(<Option key={tvcText[i]}>{tvcText[i]}</Option>);
      } else {
      }
    }
    const childrenWA = [];
    for (let i = 0; i < waText.length; i++) {
      if (waText[i]) {
        childrenWA.push(<Option key={waText[i]}>{waText[i]}</Option>);
      } else {
      }
    }
    const childrenFB = [];
    for (let i = 0; i < fbText.length; i++) {
      if (fbText[i]) {
        childrenFB.push(<Option key={fbText[i]}>{fbText[i]}</Option>);
      } else {
      }
    }
    const childrenYB = [];
    for (let i = 0; i < ybText.length; i++) {
      if (ybText[i]) {
        childrenYB.push(<Option key={ybText[i]}>{ybText[i]}</Option>);
      } else {
      }
    }
    const childrenIG = [];
    for (let i = 0; i < igText.length; i++) {
      if (igText[i]) {
        childrenIG.push(<Option key={igText[i]}>{igText[i]}</Option>);
      } else {
      }
    }
    const childrenSeasonSegementation = [];
    for (let i = 0; i < seasonSegementationText.length; i++) {
      if (seasonSegementationText[i]) {
        childrenSeasonSegementation.push(
          <Option key={seasonSegementationText[i]}>{seasonSegementationText[i]}</Option>
        );
      } else {
      }
    }
    const childrenPrefferedRice = [];
    for (let i = 0; i < prefferedRiceText.length; i++) {
      if (prefferedRiceText[i]) {
        childrenPrefferedRice.push(
          <Option key={prefferedRiceText[i]}>{prefferedRiceText[i]}</Option>
        );
      } else {
      }
    }
    const childrenPrefferedCorn = [];
    for (let i = 0; i < prefferedCornText.length; i++) {
      if (prefferedCornText[i]) {
        childrenPrefferedCorn.push(
          <Option key={prefferedCornText[i]}>{prefferedCornText[i]}</Option>
        );
      } else {
      }
    }
    const childrenSytGrowerRice = [];
    for (let i = 0; i < sytGrowerRiceText.length; i++) {
      if (sytGrowerRiceText[i]) {
        childrenSytGrowerRice.push(
          <Option key={sytGrowerRiceText[i]}>{sytGrowerRiceText[i]}</Option>
        );
      } else {
      }
    }
    const childrenSytGrowerCorn = [];
    for (let i = 0; i < sytGrowerCornText.length; i++) {
      if (sytGrowerCornText[i]) {
        childrenSytGrowerCorn.push(
          <Option key={sytGrowerCornText[i]}>{sytGrowerCornText[i]}</Option>
        );
      } else {
      }
    }
    return (
      <div>
        <Drawer
          title={t('EDIT GROUP')}
          placement='right'
          closable={true}
          onClose={this.DrawerOnClose}
          visible={this.props.groupDrawerVisible}
          mask={true}
          maskClosable={false}
          className='groupDrawer'
          destroyOnClose={true}>
          <Card
            hoverable={false}
            bordered={false}
            style={{ width: 235, padding: '10 0' }}
            cover={<img style={{ height: '130px' }} alt={t('Edit Group')} src={NewGroupCreatBg} />}
            className='NewGroupCard'>
            <Form className='groupcreateForm' onSubmit={(e) => this.handleSubmit(e, this.props)}>
              <Form.Item>
                {getFieldDecorator('Name', {
                  initialValue: this.props.editData.groupName || '',
                  validateTrigger: ['onChange'],
                  rules: [
                    { required: true, message: t('Please enter group name') },
                    { whitespace: true }
                  ]
                })(
                  <Input
                    placeholder={t('Enter Group Name')}
                    size='small'
                    value='value.editData.name'
                  />
                )}
              </Form.Item>
              <Form.Item label={t('Group by')}></Form.Item>
              {this.state.showError && (
                <Form.Item style={{ color: 'red', fontSize: '12px' }}>
                  Please select at least one filter
                </Form.Item>
              )}
              {drawerData &&
                drawerData.groupDrawer &&
                drawerData.groupDrawer.map((item) => (
                  <Form.Item label='' className='growersCrop'>
                    {getFieldDecorator(`${item.fieldName}`, {
                      initialValue: this.props.editData[item.initialValue] || '',
                      validateTrigger: ['onChange'],
                      getValueFromEvent: eval(item.handler),
                      rules: item.rules
                    })(
                      <Select
                        name='regions'
                        size='small'
                        mode='multiple'
                        placeholder={item.placeholder}
                        showArrow={true}
                        maxTagCount={2}
                        onChange={eval(item.handler)}>
                        <Option key={item.key} value={item.key}>
                          ---{t('SELECT ALL')}---
                        </Option>
                        {eval(item.optionValue)}
                      </Select>
                    )}
                  </Form.Item>
                ))}
              {this.props.acresVisible ? (
                <Form.Item style={{ marginBottom: 0 }} className='AcresItems'>
                  <Form.Item
                    style={{ display: 'inline-block', width: 'calc(25% - 5px)', marginRight: 8 }}>
                    {getFieldDecorator('operator', {
                      initialValue: this.props.editData.OperatorValue || '',
                      validateTrigger: ['onChange'],
                      rules: [
                        {
                          required: this.state.acresValue.length ? true : false,
                          message: 'Please select operator'
                        }
                      ]
                      // initialValue: 'lessThanEqualTo'
                    })(
                      <Select className='groupType' size='small' onChange={this.getOperatorValue}>
                        <Option key='lessthan' value='lessThanEqualTo' className='operatorSize'>
                          &lt;=
                        </Option>
                        <Option
                          key='greater than '
                          value='greaterThanEqualsTo'
                          className='operatorSize'>
                          &gt;=
                        </Option>
                      </Select>
                    )}
                  </Form.Item>

                  <Form.Item style={{ display: 'inline-block', width: 'calc(74% - 5px)' }}>
                    {getFieldDecorator(`acresValue`, {
                      initialValue: this.props.editData.acresValue,
                      validateTrigger: ['onChange'],
                      rules: [
                        {
                          required: this.state.opetatorValue.length ? true : false,
                          message: 'Please enter land size'
                        },
                        {
                          validator: this.validateField
                        }
                      ]
                    })(
                      <Input
                        name='acres'
                        type='number'
                        size='small'
                        placeholder='Please Enter Land Size'
                        onChange={(e) => this.setState({ acresValue: e.target.value })}
                        onKeyPress={(evt) => this.onKeyDownEvent(evt)}
                        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                        value={(e) => e.target.value}
                      />
                    )}
                  </Form.Item>
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item>
                <Button
                  className='creatGroupBtn'
                  htmlType='submit'
                  disabled={!this.props.form.isFieldsTouched(false)}>
                  {t('UPDATE GROUP')}
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Drawer>
        <GlobalSpinner />
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    toggleGroupUpdateDrawer: () => dispatch(toggleGroupUpdateDrawer()),
    fetchRegionAndCrops: () => dispatch(fetchRegionAndCrops()),
    fetchCrops: () => dispatch(fetchCrops()),
    fetchCropHistory: () => dispatch(fetchCropHistory()),
    fetchTags: () => dispatch(fetchTags()),
    fetchZone: () => dispatch(fetchZone()),
    fetchArea: () => dispatch(fetchArea()),
    fetchAcares: () => dispatch(fetchAcares()),
    fetchLanguage: () => dispatch(fetchLanguage()),
    fetchCity: () => dispatch(fetchCity()),
    fetchBrand: () => dispatch(fetchBrand()),
    fetchLandSize: () => dispatch(fetchLandSize()),
    fetchSource: () => dispatch(fetchSource()),
    fetchDistrict: () => dispatch(fetchDistrict()),
    fetchRetailerName: () => dispatch(fetchRetailerName()),
    fetchTMHQ: () => dispatch(fetchTMHQ()),
    fetchClassification: () => dispatch(fetchClassification()),
    fetchSegment: () => dispatch(fetchSegment()),
    fetchProvince: () => dispatch(fetchProvince()),
    createGroup: (gdata, userd) => dispatch(createGroup(gdata, userd)),
    fetchMDOHQ: () => dispatch(fetchMDOHQ()),
    fetchState: () => dispatch(fetchState()),
    fetchVillage: () => dispatch(fetchVillage()),
    fetchTaluka: () => dispatch(fetchTaluka()),
    fetchTVC: () => dispatch(fetchTVC()),
    fetchWA: () => dispatch(fetchWA()),
    fetchFB: () => dispatch(fetchFB()),
    fetchYB: () => dispatch(fetchYB()),
    fetchIG: () => dispatch(fetchIG()),
    fetchSeasonSegmentation: () => dispatch(fetchSeasonSegmentation()),
    fetchprefferedHybridRice: () => dispatch(fetchprefferedHybridRice()),
    fetchprefferedHybridCorn: () => dispatch(fetchprefferedHybridCorn()),
    fetchsytGrowerCorn: () => dispatch(fetchsytGrowerCorn()),
    fetchsytGrowerRice: () => dispatch(fetchsytGrowerRice()),
    fetchPramukhTag: () => dispatch(fetchPramukhTag()),
    groupUpdate: (gdata, userid, groupId, pageNumber) =>
      dispatch(groupUpdate(gdata, userid, groupId, pageNumber))
  };
};
const mapStateToProps = function(state) {
  return {
    groupDrawerVisible: _get(state, 'groupUpdateReducer.updateDrawerVisible', true),
    groupList: _get(state, 'groupListReducer.data.groupList.items', []),
    groupFilterReducer: _get(state, 'groupFilterReducer.data.groupRegion.items', []),
    groupZone: _get(state, 'groupFilterReducer.data.groupZone.items', []),
    groupArea: _get(state, 'groupFilterReducer.data.groupArea.items', []),
    groupCrops: _get(state, 'groupFilterReducer.data.groupCrops.items', []),
    groupAcares: _get(state, 'groupFilterReducer.data.groupAcares.items', []),
    groupTags: _get(state, 'groupFilterReducer.data.groupTags.items', []),
    groupLanguage: _get(state, 'groupFilterReducer.data.groupLanguage.items', []),
    groupCropHistory: _get(state, 'groupFilterReducer.data.groupCropHistory.items', []),
    groupCity: _get(state, 'groupFilterReducer.data.groupCity.items', []),
    groupBrand: _get(state, 'groupFilterReducer.data.groupBrand.items', []),
    groupRetailerName: _get(state, 'groupFilterReducer.data.groupRetailerName.items', []),
    groupDistrict: _get(state, 'groupFilterReducer.data.groupDistrict.items', []),
    groupStockistCode: _get(state, 'groupFilterReducer.data.groupSource.items', []),
    groupTmhq: _get(state, 'groupFilterReducer.data.groupTmhq.items', []),
    groupLandSize: _get(state, 'groupFilterReducer.data.groupLandSize.items', []),
    groupClassification: _get(state, 'groupFilterReducer.data.groupClassification.items', []),
    groupSegment: _get(state, 'groupFilterReducer.data.groupSegment.items', []),
    groupProvince: _get(state, 'groupFilterReducer.data.groupProvince.items', []),
    pramukhTags: _get(state, 'groupFilterReducer.data.groupPramukhTag.items', []),
    groupMdohq: _get(state, 'groupFilterReducer.data.groupMdohq.items', []),
    groupState: _get(state, 'groupFilterReducer.data.groupState.items', []),
    groupVillage: _get(state, 'groupFilterReducer.data.groupVillage.items', []),
    groupTaluka: _get(state, 'groupFilterReducer.data.groupTaluka.items', []),
    groupTVC: _get(state, 'groupFilterReducer.data.groupTVC.items', []),
    groupWA: _get(state, 'groupFilterReducer.data.groupWA.items', []),
    groupFB: _get(state, 'groupFilterReducer.data.groupFB.items', []),
    groupYB: _get(state, 'groupFilterReducer.data.groupYB.items', []),
    groupIG: _get(state, 'groupFilterReducer.data.groupIG.items', []),
    groupSytGrowerCorn: _get(state, 'groupFilterReducer.data.groupSytGrowerCorn.items', []),
    groupSytGrowerRice: _get(state, 'groupFilterReducer.data.groupSytGrowerRice.items', []),
    groupPrefferedCorn: _get(state, 'groupFilterReducer.data.groupPrefferedCorn.items', []),
    groupPrefferedRice: _get(state, 'groupFilterReducer.data.groupPrefferedRice.items', []),
    groupSeasonSegementation: _get(
      state,
      'groupFilterReducer.data.groupSeasonSegementation.items',
      []
    )
  };
};

const UpdateGroupsApp = Form.create({ name: 'coordinated' })(UpdateGroupDrawer);
// GroupDrawer.propTypes = {
//   form: PropTypes.object,
//   createGroup: PropTypes.func,
//   toggleGroupDrawer: PropTypes.func,
//   fetchRegionAndCrops: PropTypes.func,
//   groupDrawerVisible: PropTypes.bool,
//   groupFilterReducer: PropTypes.array,
//   groupCrops: PropTypes.array,
//   fetchCrops: PropTypes.func,
//   fetchTags: PropTypes.func,
//   groupTags: PropTypes.array,
//   fetchCropHistory: PropTypes.func,
//   groupCropHistory: PropTypes.array,
//   setFieldsValue: PropTypes.func
// };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateGroupsApp);

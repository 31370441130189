import { FETCH_UPDATE_PENDING, FETCH_UPDATE_SUCCESS, FETCH_UPDATE_ERROR } from '../constants';

const initialState = {
  data: {
    updateResult: {},
    updateError: {},
    completed: false
  }
};

function updateStatisticsData(state = initialState, action) {
  switch (action.type) {
    case FETCH_UPDATE_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FETCH_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          updateResult: action.payload,
          completed: false
        }
      });

    case FETCH_UPDATE_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          updateError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}
export default updateStatisticsData;

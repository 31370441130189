import {
    SINGLE_GROWER_UPDATE_PENDING,
    SINGLE_GROWER_UPDATE_SUCCESS,
    SINGLE_GROWER_UPDATE_FAILURE,
    SINGLE_GROWER_UPDATE_LOADING,
    CLEAR_SINGLE_GROWER_UPDATE
  } from '../constants/index';
  
  const initialState = {
    growerUpdateSucess: [],
    growerUpdateError: [],
    growerUpdatePending: false,
    loading: false,
    visible: false,

  };
  
  function growerSingleUpdate(state = initialState, action) {
    switch (action.type) {
      case SINGLE_GROWER_UPDATE_PENDING:
        return {
          ...state,
          growerUpdatePending: action.payload
        };
      case SINGLE_GROWER_UPDATE_SUCCESS:
        return {
          ...state,
          growerUpdateSucess: action.payload
        };
      case SINGLE_GROWER_UPDATE_FAILURE:
        return {
          ...state,
          growerUpdateError: action.payload
        };
   
      case SINGLE_GROWER_UPDATE_LOADING:
        return {
          ...state,
          loading: action.payload
        };
   
      case CLEAR_SINGLE_GROWER_UPDATE:
        return {
          ...state,
          growerUpdateSucess: []
        };
  
      default:
        return state;
    }
  }
  
  export default growerSingleUpdate;
  
import styled from 'styled-components';
import { Row, Card, Modal, Input, Form } from 'antd';
export const StyledCard = styled(Card)`
  .ant-card {
    background-color: #ffffff !important;
  }
  .ant-card-body{
   
    max-height : ${props => props.overflow ? '92px' : '92px'};
    overflow : ${props => props.overflow ? 'hidden' : 'hidden'};
 }
`



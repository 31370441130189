import React, { Component } from 'react';
import { Layout, Card, Row, Col, Button, Input, Icon } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash.get';
import SocialMediaTable from './socialMediaTable';
import YouTubeDatatable from './youtubeTable';
import FacebookTable from './facebookTable';
import FacebookPostList from './facebookPostList';
import { facebookAuthAPI } from '../../actions/facebookAuth';
import { youTubeAuthAPI } from '../../actions/youTubeList';
import NewGroupSVG from '../../assets/icons/svg/new_group.svg';
import TitleSection from '../commonComponents/TitleSection/titleSection';
import { toggleDrawer } from '../../actions/uploadYouTubeVideos';
import { RegionalMetaContext } from '../../context/region';
import { Translation } from 'react-i18next';
import './socialmedia.css';
import ComminSoon from '../ComingSoon/Comingsoon';
import AddPost from './component/addPost/addPost';

import {
  facebookIconSVG,
  youtubeIconSVG,
  instagramIconSVG,
  twitterIconSVG,
  imageSVG
} from './messageSvg/messageSvgIcons';
import StudioDrawer from './studioDrawer/studioDrawer';

/*** Ant Design Components */
const { Content, Header } = Layout;
const { Meta } = Card;
const { Search } = Input;
const tabList = [
  {
    key: 'youtube',
    tab: (
      <span style={{ color: '#c4302b' }}>
        <Icon type='youtube' />
        <Translation>{(t) => <>{t('YouTube')}</>}</Translation>
      </span>
    )
  },
  {
    key: 'facebook',
    tab: (
      <span style={{ color: '#3b5998' }}>
        <Icon type='facebook' />
        <Translation>{(t) => <>{t('Facebook')}</>}</Translation>
      </span>
    )
  }
];

class SocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'youtube',
      noTitleKey: 'app',
      drawerVisible: false
    };
  }

  onTabChange = (key, type) => {
    this.setState({ [type]: key });
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.fbPostUploaded === 'facebook') {
      this.setState({
        key: 'facebook'
      });
    } else if (nextProps.ytPostUploaded === 'youtube') {
      this.setState({
        key: 'youtube'
      });
    }
  }
  componentDidMount() {
    this.props.youTubeAuthAPI();
    this.props.facebookAuthAPI();
  }
  drawerStatus = () => {
    this.setState({
      drawerVisible: true
    });
  };
  render() {
    const { youTubeAuthToken, facebookAuthToken } = this.props;
    const contentList = {
      youtube: <YouTubeDatatable youTubeAuthToken={youTubeAuthToken} />,
      facebook: <FacebookPostList facebookAuthToken={facebookAuthToken} />
      // instagram: <SocialMediaTable />,
      // twitter: <YouTubeDatatable />,
    };

    // localStorage.setItem('youTubeAuthToken', youTubeAuthToken);
    localStorage.setItem('facebookAuthToken', facebookAuthToken);

    return (
      <RegionalMetaContext.Consumer>
        {({ data }) => (
          <Layout className='socialLayout'>
            <>
              {/* <AddPost /> */}
              <Card
                style={{ width: '100%', height: '100vh' }}
                bordered={false}
                title='Social Media'
                tabList={tabList}
                activeTabKey={this.state.key}
                onTabChange={(key) => {
                  this.onTabChange(key, 'key');
                }}>
                {contentList[this.state.key]}
              </Card>
              <a className='creaGroup' onClick={this.props.toggleDrawer} title='New'>
                <img src={NewGroupSVG} id='Messagefixedbutton' />
              </a>
              <StudioDrawer />
            </>
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    youTubeAuthAPI: () => dispatch(youTubeAuthAPI()),
    facebookAuthAPI: () => dispatch(facebookAuthAPI()),
    toggleDrawer: () => dispatch(toggleDrawer())
  };
};
// export default OnBoard;
const mapStateToProps = (state) => {
  return {
    youTubeAuthToken: get(state, 'youTubeLists.data.youTubeList.accessToken', ''),
    facebookAuthToken: get(state, 'facebookList.data.facebookList.accessToken', ''),
    fbPostUploaded: get(state, 'facebookPost.postUploaded'),
    ytPostUploaded: get(state, 'uploadYouTubeVideoDetails.postUploaded')
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SocialMedia);

import {
  COMMUNICATION_LIST_PENDING,
  COMMUNICATION_LIST_SUCCESS,
  COMMUNICATION_LIST_FAILURE,
  COMMUNICATION_LIST_LOADING
} from '../constants/index';
import config from '../config';
import { formatUrl } from '../services/commonMethods';
import { handleErrors } from '../services/handleErrors';
import { toggleSpinStatus } from './globalSpinner';
export const communicationListLoading = (val) => {
  return { type: COMMUNICATION_LIST_LOADING, payload: val };
};

export const communicationListingPending = (value) => {
  return {
    type: COMMUNICATION_LIST_PENDING,
    payload: value
  };
};
export const communicationListingSuccess = (value) => {
  return {
    type: COMMUNICATION_LIST_SUCCESS,
    payload: value
  };
};

export const communicationListingFailure = (error) => {
  return {
    type: COMMUNICATION_LIST_FAILURE,
    payload: error
  };
};

export const communicationLists = (regionCode) => {
  const apiUri = formatUrl(config.communicationService, regionCode);
  return (dispatch) => {
    dispatch(communicationListingPending());
    // dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(communicationListingSuccess(result));
        // dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(communicationListingPending());
        // dispatch(toggleSpinStatus());
        dispatch(communicationListingFailure(e));
      });
  };
};

import React, { useState } from 'react';
import { Radio } from 'antd';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import Routes from './routes';
import GlobalStyles from './styles/global';
import { store } from './store';
import history from './utils/history';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './views/_layouts/theme';
import { RegionalMetaProvider } from './context/region';
import AdalConfigService from './services/adalConfigService';
import AuthProvider from './provider/auth-provider';
function App(props) {
  window.amplitude.getInstance().logEvent(`${AdalConfigService.countryName}_Users_Visit`);
  const [theme, setTheme] = useState('light');

  // The function that toggles between themes
  const toggleTheme = () => {
    // if the theme is not light, then set it to dark
    if (theme === 'light') {
      setTheme('dark');
      // otherwise, it should be light
    } else {
      setTheme('light');
    }
  };
  return (
    <Provider store={store}>
      <RegionalMetaProvider>
        <Router history={history}>
          <Routes {...props} />
          <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <GlobalStyles />
            {/* <Radio.Group
            className='toggleTheme'
            onChange={toggleTheme}
            defaultValue='light'
            size='small'>
            <Radio.Button value='light'>Light</Radio.Button>
            <Radio.Button value='dark'>Dark</Radio.Button>           
          </Radio.Group> */}
          </ThemeProvider>
        </Router>
      </RegionalMetaProvider>
    </Provider>
  );
}

export default App;

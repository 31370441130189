import styled from 'styled-components';
import { Layout, Card } from 'antd';
export const GrowerMetricsStyle = styled(Layout)`
padding: 10px;
height:100vh;
overflow-y:scroll
min-height:70vh;
max-height:100vh;
.ant-layout-content{
  min-height:70vh;
  max-height:100vh;
  height :100vh
}
min-height: calc(100% - 72px);
  .ant-card-body {
    padding: 5px;
    text-align : center;
    font-weight :bold;
    font-color : gray;
  }
  .ant-card{
    background-color : #ffffff;
    border-radius: 0;
  }
  .ant-card-head-title {
    padding: 5px 0;
    color: #1890ff;
  }
  .ant-card-bordered {
    border: 0px;
  }
  .campaignTable{
    text-align:center;
    padding: 10px;
    // font-size : 18px;
    // margin-bottom: 5px;
  }
  .ant-table-thead > tr > th {
    // background: #fff;
    // border-bottom: solid 1px gray;
    text-align:center;
  }
  .ant-table-tbody > tr > td{
    color: gray;
    text-align:center;
    font-weight:normal;
  }
  .growerDetails {
    text-align:center
    padding:10px;
    display:flex;
    flex-direction:column;
  }
  .countCard {
    // height:105px;
  }
  .apexcharts-toolbar{
    // display:none;
  }
  .trendChart{
    padding: 10px;
  }
  .headerName {
    margin-bottom: 15px;
  }
  .apexcharts-legend-marker{
    border-radius : 0px !important;
    height: 10px !important;
    width: 10px !important;
  }
  .apexcharts-legend-text{
    text-transform:capitalize !important;
  }
  .updateButton{
    text-align: center;
    width:fit-content;
    float:right;
    border-radius: 6px;
    color:#40a9ff;
    border-color:#40a9ff;
  }
 .numberStyle{
   font-size:20px;
   font-weight:normal;
   margin-bottom: 5px;
 }
  .apexcharts-legend.apexcharts-align-center.position-bottom{
    // right:-7px !important;
  }
  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 400px) and (max-width: 799px) {
  }
  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 800px) and (max-width: 999px) {
    .MetricsLayout {
      height: 100vh;
      min-height:70vh;
      max-height:100vh;
    }
  }

  /* Media Query for Laptops and Desktops */
  @media (min-width: 1000px) and (max-width: 1199px) {
    .MetricsLayout {
      height: 100vh;
      min-height:70vh;
      max-height:100vh;
    }
  }

  @media (min-width: 1200px) and (max-width: 1259px) {
    .MetricsLayout {
      height: 100vh;
      min-height:70vh;
      max-height:100vh;
    }
  }
  @media (min-width: 1260px) and (max-width: 1399px) {
    .MetricsLayout {
      height: 100vh;
      min-height:70vh;
      max-height:100vh;
    }
  }
  @media (min-width: 1400px) and (max-width: 1450px) {
    .MetricsLayout {
      height: 100vh;
    }
  }
  /* Media Query for Large screens */
  @media (min-width: 1451px) and (max-width: 1550px) {
    .MetricsLayout {
      height: 100vh;
    }
  }
  /* Media Query for Large screens MYSELF Desk Screen(hp bang & olufsen) */
  @media (min-width: 1900px) and (max-width: 2000px) {
    .MetricsLayout {
      height: 100vh;
    }
  }

  /* Media Query for Large screens */
  @media (min-width: 2000px) and (max-width: 9000px) {
    .MetricsLayout {
      height: 100vh;
    }
  }
`;

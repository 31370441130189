import styled from 'styled-components';
import { Row, Card, Modal, Input, Form, Button, Upload } from 'antd';
export const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 10px;
  }
`;
export const StyledButton = styled(Button)`
  ..ant-btn-primary:hover {
    color: #fff;
    background-color: #238661;
    border-color: #238661;
  }
`;
export const StyledUpload = styled(Upload)`
  ..ant-btn-primary:hover {
    color: #fff;
    background-color: #238661;
    border-color: #238661;
  }
`;

import React from 'react';
import ReactDOM from 'react-dom';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { Steps, Button, Form, Modal, DatePicker } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';
import moment from 'moment';
import FirstStep from './formSteps/firstStep';
import SecondStep from './formSteps/secondStep';
import ThirdStep from './formSteps/thirdStep';
import isEmpty from 'lodash/isEmpty';
import './stepForm.css';
import { fetchCostEstimator, fetchCostEstimatorClear } from '../../../../../actions/costEstimator';
import { fetchwhatsAppTemplateDataClear } from '../../../../../actions/whatsAppTemplateData';
import OutsideAlerter from './OutsideAlerter';
import Truncate from '../../../Groups/Truncate';

const Step = Steps.Step;
const { MonthPicker, RangePicker } = DatePicker;

class StepsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      current: 0,
      messageType: 'SMS',
      newMessageData: {},
      formData: {},
      pickerOpen: false,
      selectedDate: null,
      checkDate: null,
      scheduleMessageData: {},
      costEstimatorData: this.props.costEstimatorData,
      fetchCostEstimator: this.props.fetchCostEstimator,
      visible: false,
      selectedMedia: '',
      channel: '',
      disableButton: false,
      showError: false,
      imageSize: false,
      imageType: false,
      isButtonDisabled: false
    };
    this.wrapperRef = React.createRef();
    //  this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  onDateChange = (value, dateString) => {
    if (moment(dateString).isValid()) {
      this.setState({
        selectedDate: moment(dateString)
          .utc()
          .format('YYYY-MM-DD HH:mm:ss'),
        checkDate: dateString
      });
    } else {
      this.setState({
        selectedDate: '',
        checkDate: dateString
      });
    }
  };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Alert if clicked on outside of element
   */
  myRef = React.createRef();
  handleClickOutside = (e) => {
    if (!this.myRef.current.contains(e.target)) {
      this.setState({ pickerOpen: false });
    }
  };
  handleClickInside = () => this.setState({ pickerOpen: true });
  onOk = (value) => {
    this.setState({
      pickerOpen: false
    });
  };
  togglePicker = () => {
    this.setState({ pickerOpen: !this.state.pickerOpen });
  };
  next() {
    if (this.state.current === 0) {
      this.props.form.validateFieldsAndScroll(['SocialPlatform'], (err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(
        ['mesageTxt', 'template', 'upload', 'mesageTxtMms'],

        (err, values) => {
          // console.log('message values1>>', values);
          console.log('message values1>>', values);
          if (values.mesageTxtMms && values.upload) {
            this.setState({ showError: true });
          }
          if (!err) {
            const current = this.state.current + 1;

            this.setState({ current });
          }
        }
      );
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(['template'], (err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(['mesageTxtMms', 'upload'], (err, values) => {
        console.log('err', err);
        if (!err) {
          const current = this.state.current + 1;
          this.setState({ current, showError: true });
        }
      });
    } else if (this.state.current === 1) {
      this.props.form.validateFieldsAndScroll(['upload'], (err, values) => {
        console.log('message values2>>', values);
        console.log('err', err);
        if (!err) {
          const current = this.state.current + 1;
          this.setState({ current, showError: true });
        }
      });
    } else if (this.state.current === 2) {
      this.props.form.validateFields((err) => {
        if (!err) {
          const current = this.state.current + 1;

          this.setState({ current });
        }
      });
    }
  }
  prev() {
    const current = this.state.current - 1;
    // this.props.fetchCostEstimatorClear();
    // this.props.fetchwhatsAppTemplateDataCleared();
    this.setState({ current, selectedDate: null });
  }
  replaceSpecialChar = (value) => {
    value
      .replace(/\\'/g, "\\'")
      .replace(/\\n/g, '\\n')
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, '\\&')
      .replace(/\\r/g, '\\r')
      .replace(/\\t/g, '\\t')
      .replace(/\\b/g, '\\b')
      .replace(/\\f/g, '\\f');
    return value;
  };
  handleSubmit = (e, props) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // const emailId = props.Context.AuthContext._user.userName;
        const emailId = props.Context.auth.account.userName;
        if (values.SocialPlatform) {
          // const emailId = props.Context.AuthContext._user.userName;
          const emailId = props.Context.auth.account.userName;
          const newMessageData = {
            subject: values.subject,
            content:
              values.SocialPlatform === 'MMS'
                ? values.mesageTxtMms
                    .replace(/\n/g, '\\n')
                    .replace(/\'/g, "\\'")
                    .replace(/\"/g, '\\"')
                    .replace(/\&/g, '\\&')
                    .replace(/\r/g, '\\r')
                    .replace(/\t/g, '\\t')
                    .replace(/\\b/g, '\\b')
                    .replace(/\f/g, '\\f')
                    .replace(
                      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                      ''
                    )
                : props.countryData === 'kr'
                ? values.mesageTxt
                    .replace(/\n/g, '\\n')
                    .replace(/\'/g, "\\'")
                    .replace(/\"/g, '\\"')
                    .replace(/\&/g, '\\&')
                    .replace(/\r/g, '\\r')
                    .replace(/\t/g, '\\t')
                    .replace(/\\b/g, '\\b')
                    .replace(/\f/g, '\\f')
                    .replace(
                      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                      ''
                    )
                : values.mesageTxt,
            messageMode:values.sendAdvertisement ? 'advertisement': '',
            mediaURL: values.mediaURL,
            whatsappTemplateLanguageCode: this.props.templateListContent
              .whatsappTemplateLanguageCode,
            whatssappTemplateName: values.template,
            templateId: this.props.templateListContent.templateId,
            whatssappTemplateParams: {
              image:
                this.props.templateListContent &&
                this.props.templateListContent.whatssappTemplateParams
                  ? this.props.templateListContent.whatssappTemplateParams.image
                  : '',
              text:
                this.props.templateListContent &&
                this.props.templateListContent.whatssappTemplateParams
                  ? this.props.templateListContent.whatssappTemplateParams.text
                  : ''
            },
            group: {
              id: values.groupType
            },
            channel: values.SocialPlatform,
            sender: emailId,
            type: values.template ? 'Image' : values.SocialPlatform === 'MMS' ? 'Image' : 'Text'
          };
          if (values.SocialPlatform === 'MMS') {
            const checkFileList =
              values.upload && values.upload.fileList ? values.upload.fileList : '';
            const formData = new FormData();
            // const messageParse = JSON.parse(newMessageData);
            for (let i = 0; i < checkFileList.length; i++) {
              formData.append('files', checkFileList[i].originFileObj);
            }
            // formData.append('files', values.upload.file);
            formData.append(
              'message',
              new Blob([JSON.stringify(newMessageData)], { type: 'application/json' })
            );
            // console.log('message>', message);
            this.setState({ formData });
          }

          this.setState({ newMessageData });
          const mdata = {
            channel: values.SocialPlatform,
            groupId: values.groupType
          };
          if (props.countryData === 'ph' && !this.state.selectedDate) {
            this.props.sendMessage(newMessageData);
            this.props.clearScheduleMessageStatus();
          }
          // if (props.countryData === 'kr' && !this.state.selectedDate) {
          //   this.props.sendMMS(formData);
          // }
          this.props.resetPage();
          // this.props.changeKey();
          // this.props.fetchCostEstimator(mdata);
          if (this.state.selectedDate) {
            const scheduleMessageData = {
              message: {
                subject: 'Test Syngenta',
                content:
                  values.SocialPlatform === 'MMS'
                    ? values.mesageTxtMms
                        .replace(/\n/g, '\\n')
                        .replace(/\'/g, "\\'")
                        .replace(/\"/g, '\\"')
                        .replace(/\&/g, '\\&')
                        .replace(/\r/g, '\\r')
                        .replace(/\t/g, '\\t')
                        .replace(/\\b/g, '\\b')
                        .replace(/\f/g, '\\f')
                        .replace(
                          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                          ''
                        )
                    : props.countryData === 'kr'
                    ? values.mesageTxt
                        .replace(/\n/g, '\\n')
                        .replace(/\'/g, "\\'")
                        .replace(/\"/g, '\\"')
                        .replace(/\&/g, '\\&')
                        .replace(/\r/g, '\\r')
                        .replace(/\t/g, '\\t')
                        .replace(/\\b/g, '\\b')
                        .replace(/\f/g, '\\f')
                        .replace(
                          /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
                          ''
                        )
                    : values.mesageTxt,
                messageMode:values.sendAdvertisement ? 'advertisement': '',
                whatsappTemplateLanguageCode: this.props.templateListContent
                  .whatsappTemplateLanguageCode,
                whatssappTemplateName: values.template,
                templateId: this.props.templateListContent.templateId,
                whatssappTemplateParams: {
                  image:
                    this.props.templateListContent &&
                    this.props.templateListContent.whatssappTemplateParams
                      ? this.props.templateListContent.whatssappTemplateParams.image
                      : '',
                  text:
                    this.props.templateListContent &&
                    this.props.templateListContent.whatssappTemplateParams
                      ? this.props.templateListContent.whatssappTemplateParams.text
                      : ''
                },
                group: {
                  id: values.groupType
                },
                channel: values.SocialPlatform,
                sender: emailId,
                type: values.template ? 'Image' : values.SocialPlatform === 'MMS' ? 'Image' : 'Text'
              },
              scheduleAt: this.state.selectedDate
            };
            this.setState({ scheduleMessageData });
            if (values.SocialPlatform === 'MMS') {
              const checkFileList =
                values.upload && values.upload.fileList ? values.upload.fileList : '';

              const formData = new FormData();
              for (let i = 0; i < checkFileList.length; i++) {
                formData.append('files', checkFileList[i].originFileObj);
              }

              // const messageParse = JSON.parse(newMessageData);
              // formData.append('files', JSON.stringify(checkFileList));
              formData.append(
                'message',
                new Blob([JSON.stringify(scheduleMessageData)], { type: 'application/json' })
              );
              // console.log('message>', message);
              this.setState({ formData });
              this.props.scheduleMMS(formData);
              if (this.state.isButtonDisabled) {
                return;
              }
              this.setState({
                isButtonDisabled: true
              });
            } else {
              this.props.messageSchedulingService(scheduleMessageData);
              this.props.fetchwhatsAppTemplateDataCleared();
              this.props.changeKey();
              if (this.state.disableButton) {
                return;
              }
              this.setState({ disableButton: true });
            }
          } else {
            this.showModal();
          }
        } else {
        }
      }
    });
  };
  handleScheduleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.SocialPlatform) {
          const emailId = props.Context.auth.account.userName;
          // const emailId = props.Context.AuthContext._user.userName;
          const newMessageData = {
            subject: values.subject,
            content: values.mesageTxt,
            whatsappTemplateLanguageCode: '5ff803879bb3c55d441174d9',
            whatssappTemplateName: values.template,
            whatssappTemplateParams: {
              image: this.props.templateListContent.mediaUrl,
              text: 'Customer'
            },
            group: {
              id: values.groupType
            },
            channel: values.SocialPlatform,
            sender: emailId,
            type: values.template ? 'Image' : 'Text'
          };

          this.setState({ newMessageData });
        } else {
        }
      }
    });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e, props) => {
    this.setState({
      visible: false
    });

    // const emailId = props.Context.AuthContext._user.userName;
    const emailId = props.Context.auth.account.userName;
    if (this.state.messageType === 'MMS') {
      this.props.sendMMS(this.state.formData);
    } else {
      this.props.sendMessage(this.state.newMessageData);
      this.props.clearScheduleMessageStatus();
      this.props.fetchwhatsAppTemplateDataCleared();
    }
  };
  handleCancel = (e) => {
    this.setState({
      visible: false
    });
  };

  range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  onOutsideClick = () => {
    this.setState({
      pickerOpen: false
    });
  };
  onFocusClick = () => {
    this.setState({
      pickerOpen: false
    });
  };
  disabledDateTime = (current) => {
    // console.log('current>>>', moment(current));
    let currentDateTime = new Date();
    let date = moment(currentDateTime);
    let currentDate = moment(current);
    // console.log('date>>', date);
    let now = moment(this.state.checkDate);
    // console.log('now>>', now);
    let hours = currentDateTime.getHours();
    // console.log('hours', hours);
    let minutes = currentDateTime.getMinutes();
    // console.log('minutes', minutes);
    // console.log('check currrent>>', current > date);
    // console.log('check now>>', now > date);
    if (currentDate > date) {
      return {
        // disabledHours: () => this.range(24, 0),
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(60, 0)
        // disabledHours: () => this.range(0, hours),
        // disabledMinutes: () => this.range(0, minutes)
      };
    } else {
      return {
        // disabledHours: () => this.range(24, 0),
        //  disabledMinutes: () => this.range(60, 0)
        disabledHours: () => this.range(0, hours),
        disabledMinutes: () => this.range(0, minutes)
      };
    }
  };
  render() {
    const { current, selectedMedia, messageType } = this.state;
    const { t, templateListContent, communicationModes } = this.props;
    let steps = [
      {
        title: '1',
        content: (
          <FirstStep
            form={this.props.form}
            {...this.props}
            countryData={this.props.countryData}
            messageType={this.state.messageType}
            checkMessageType={(value) => {
              this.setState({
                messageType: value
              });
            }}
          />
        )
      },
      {
        title: '2',
        content: (
          <SecondStep
            form={this.props.form}
            {...this.props}
            callback={(value) => {
              this.setState({
                imageSize: value
              });
            }}
            callbackType={(value) => {
              this.setState({
                imageType: value
              });
            }}
            countryData={this.props.countryData}
            messageType={this.state.messageType}
            fetchWhatsAppTemplateData={this.props.fetchWhatsAppTemplateData}
            templateList={this.props.templateList}
            templateListContent={this.props.templateListContent}
            fetchwhatsAppTemplateDataClear={this.props.fetchwhatsAppTemplateDataClear}
          />
        )
      },
      {
        title: '3',
        content: (
          <ThirdStep
            groupList={this.props.groupList}
            form={this.props.form}
            callback={(value) => {
              this.setState({
                imageSize: value
              });
            }}
            {...this.props}
            countryData={this.props.countryData}
            messageType={this.state.messageType}
            fetchCostEstimator={this.state.fetchCostEstimator}
            costEstimatorData={this.props.costEstimatorData}
            costEstimatorError={this.props.costEstimatorError}
            fetchCostEstimatorClear={this.props.fetchCostEstimatorClear}
            checkMediaType={(value) => {
              this.setState({
                selectedMedia: value
              });
            }}
          />
        )
      }
    ];

    return (
      <div>
        <Steps current={current}>
          {steps.map((item) => (
            <Step key={item.title} />
          ))}
        </Steps>

        <div className='steps-content'>
          {steps.map(({ title, content }, i) => (
            <div key={title} className={i === this.state.current ? 'foo fade-in' : 'foo'}>
              {content}
            </div>
          ))}
        </div>
        <div className='steps-action'>
          {this.state.current > 0 && (
            <Button type='default' style={{}} className='StepsprevBtn' onClick={() => this.prev()}>
              {t('Previous')}
            </Button>
          )}
          {this.state.current < steps.length - 1 && (
            <Button
              className='interactNxt'
              disabled={
                isEmpty(this.props.communicationModes) ||
                this.state.imageSize ||
                this.state.imageType
              }
              style={{}}
              onClick={() => this.next()}>
              {t('Next')}
            </Button>
          )}

          {this.state.current === steps.length - 1 &&
            (this.state.selectedDate ? (
              <Button
                className='schedule'
                onClick={(e) => this.handleSubmit(e, this.props)}
                disabled={
                  this.props.countryData === 'ph'
                    ? false
                    : Object.keys(this.props.costEstimatorData).length === 0 ||
                      this.state.disableButton ||
                      this.state.isButtonDisabled
                }>
                {t('Schedule')}
              </Button>
            ) : (
              <Button
                className='sendNow'
                onClick={(e) => this.handleSubmit(e, this.props)}
                disabled={
                  this.props.countryData === 'ph'
                    ? false
                    : this.props.costEstimatorData.length === 0
                    ? this.props.costEstimatorData.length === 0
                    : this.props.costEstimatorData.length === 0 || this.props.countryData === 'idn'
                    ? this.props.costEstimatorData.target_audience > 200000 &&
                      this.state.messageType === 'WHATSAPP'
                    : this.props.costEstimatorData.length === 0 || this.props.countryData === 'bd'
                    ? this.props.costEstimatorData.target_audience > 500 &&
                      this.state.messageType === 'WHATSAPP'
                    : false
                }>
                {t('Send Now')}
              </Button>
            ))}

          {this.state.current === steps.length - 1 && (
            <div
              style={{
                position: 'relative',
                top: '-32px',
                left: '112px',
                width: '195px'
              }}>
              {/* <OutsideClickHandler
                onOutsideClick={(event) => {
                  event.preventDefault();
                  this.setState({
                    pickerOpen: false
                  });
                }}> */}
              {/* <div ref={this.myRef}> */}
              <DatePicker
                type='primary'
                open={this.state.pickerOpen}
                disabledTime={this.disabledDateTime}
                onMouseLeave={this.onOutsideClick}
                // onFocus={this.onFocusClick}
                disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current;
                }}
                showTime={{ format: 'HH:mm' }}
                showNow={false}
                showToday={false}
                format='YYYY-MM-DD HH:mm'
                use12Hours
                placeholder={t('Schedule for later')}
                onChange={this.onDateChange}
                onOk={this.onOk}
              />
              {/* </div> */}
              {/* </OutsideClickHandler> */}
              {this.state.selectedDate ? (
                ''
              ) : (
                <Button
                  className='scheduleLater'
                  onClick={this.togglePicker}
                  disabled={
                    this.props.countryData === 'ph'
                      ? false
                      : this.props.costEstimatorData.length === 0
                      ? this.props.costEstimatorData.length === 0
                      : this.props.costEstimatorData.length === 0 ||
                        this.props.countryData === 'idn'
                      ? this.props.costEstimatorData.target_audience > 200000 &&
                        this.state.messageType === 'WHATSAPP'
                      : this.props.costEstimatorData.length === 0 || this.props.countryData === 'bd'
                      ? this.props.costEstimatorData.target_audience > 500 &&
                        this.state.messageType === 'WHATSAPP'
                      : false
                  }
                  style={{ width: '195px', top: '-32px', left: '0px' }}>
                  {t('Schedule for later')}
                </Button>
              )}
            </div>
          )}
          <Modal
            visible={this.props.costEstimatorData ? this.state.visible : false}
            onOk={(e) => this.handleOk(e, this.props)}
            onCancel={this.handleCancel}
            closable={false}
            footer={[
              <Button
                key='submit'
                type='primary'
                size='small'
                onClick={(e) => this.handleOk(e, this.props)}>
                {t('Confirm')}
              </Button>,
              <Button key='back' size='small' onClick={this.handleCancel}>
                {t('Cancel')}
              </Button>
            ]}>
            {this.props.countryData === 'ph' ? (
              false
            ) : Object.keys(this.props.costEstimatorData).length > 0 ? (
              <p>
                {t('Are you sure you want to proceed ? This message will be sent to')}{' '}
                {this.props.costEstimatorData.target_audience || ''}{' '}
                {t('growers and it would cost')} {this.props.costEstimatorData.cost || ''}{' '}
                {this.props.costEstimatorData.cost_unit || ''}
              </p>
            ) : Object.keys(this.props.costEstimatorError).length > 0 ? (
              <p>{this.props.costEstimatorError} </p>
            ) : null}
          </Modal>
        </div>
      </div>
    );
  }
}
StepsForm.propTypes = {
  form: PropTypes.any,
  groupList: PropTypes.array,
  toggleWebstoreDrawer: PropTypes.func,
  sendAgroServiceMessage: PropTypes.func,
  widgetdata: PropTypes.object,
  sendMessage: PropTypes.func
};

const MainStepsForm = Form.create({})(StepsForm);

export default MainStepsForm;

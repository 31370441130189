import {
  REQUEST_UPLOAD,
  REQUEST_UPLOAD_SUCCESS,
  REQUEST_UPLOAD_FAILURE,
  CLEAR_PROGRESS_STATUS,
  UPLOAD_PROGRESS,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_TABEL_ROW
} from '../constants';

export function createUploadPending(Progressstatus) {
  return {
    type: REQUEST_UPLOAD,
    payload: Progressstatus
  };
}
export function createUploadSuccess(gdata) {
  return {
    type: REQUEST_UPLOAD_SUCCESS,
    payload: gdata
  };
}

export function createUploadFailure(gerror) {
  return {
    type: REQUEST_UPLOAD_FAILURE,
    payload: gerror
  };
}
export function clearProgressStatus() {
  return {
    type: CLEAR_PROGRESS_STATUS
  };
}
export function createUploadProgress(progressdata) {
  return {
    type: UPLOAD_PROGRESS,
    payload: progressdata
  };
}

export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER_STATUS
  };
}

export function highlightTableRow(rowStatus) {
  return {
    type: HIGHLIGHT_TABEL_ROW,
    payload: rowStatus
  };
}

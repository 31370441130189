import { facebookAuthAPI } from './actions/facebookAuth';
import AdalConfigService from './services/adalConfigService';
AdalConfigService.setAdalConfig();

const config = {
  // access token for all api
  // userToken: localStorage.getItem(`adal.access.token.key${AdalConfigService.ClientId}`),
  userToken: localStorage.getItem(`msal.idtoken`),
  //Api call to show growers
  growerList: `${AdalConfigService.GrowerAPI}/growers?pageNo={0}&name={1}&sortBy={2}&pageSize={3}`,
  growerListSearch: `${AdalConfigService.GrowerAPI}/growers?pageNo={0}&name={1}&sortBy={2}&pageSize={3}`,
  uploadedGrowerList: `${AdalConfigService.GrowerAPI}/uploads?pageNo={0}&pageSize={1}&sortBy={2}`,
  uploadFile: `${AdalConfigService.GrowerAPI}/uploads/file`,
  growerRegion: `${AdalConfigService.GrowerAPI}/filters/options?field={0}`,
  growerMembers: `${AdalConfigService.GrowerAPI}/group/{0}/growers?pageNo={1}&pageSize={2}&sortBy={3}`,
  //Api call to show group
  createGroup: `${AdalConfigService.GrowerAPI}/groups`,
  deleteGroup: `${AdalConfigService.GrowerAPI}/groups/{0}`,
  updateGroup: `${AdalConfigService.GrowerAPI}/groups/{0}`,
  deleteScheduleMessage: `${AdalConfigService.GrowerAPI}/messages/schedule/{0}`,
  updateScheduleMessage: `${AdalConfigService.GrowerAPI}/messages/schedule/{0}`,
  optIn: `${AdalConfigService.GrowerAPI}/growers/communication/optinAll`,
  optOut: `${AdalConfigService.GrowerAPI}/growers/communication/optoutAll`,
  groupList: `${AdalConfigService.GrowerAPI}/groups?pageNo={0}&name={1}&sortBy={2}&pageSize={3}`,
  advanceSearch: `${AdalConfigService.GrowerAPI}/growers/filter?pageNo={0}&pageSize=10&sortBy=-created`,
  downloadSearchResult: `${AdalConfigService.GrowerAPI}/growers/download`,
  downloadSourceResult: `${AdalConfigService.GrowerAPI}/grower-excel-report?reportName=GrowerCountBySource`,
  downloadGroupData: `${AdalConfigService.GrowerAPI}/group/{0}/grower-excel-report`,
  downloadReport: `https://qa-msg-api.grower-console.syn-dpe.com/v1/communications/status/date/country/kr?fromDate={0}`,
  /*** API Call for webstore services */
  agroServices: `${AdalConfigService.GrowerAPI}/widgets`,
  regionsList: `${AdalConfigService.GrowerAPI}/regions`,
  communicationService: `${AdalConfigService.GrowerAPI}/channels?region={0}`,

  /*** API Call for webstore message services */
  sendMessageFromWebstore: `${AdalConfigService.GrowerAPI}/widgets/{0}/publish/`,

  /*** API Call for interact message sending */
  sendMessageFromInteract: `${AdalConfigService.GrowerAPI}/messages`,
  sendMMS: `${AdalConfigService.GrowerAPI}/messages/mms`,
  scheduleMessage: `${AdalConfigService.GrowerAPI}/messages/schedule/`,
  scheduleMMS: `${AdalConfigService.GrowerAPI}/messages/schedule/mms`,
  updateScheduleMMS: `${AdalConfigService.GrowerAPI}/messages/schedule/mms/{0}`,
  scheduleMessageList: `${AdalConfigService.GrowerAPI}/messages/schedule?pageNo={0}&pageSize={1}&sortBy={2}`,
  messagingList: `${AdalConfigService.GrowerAPI}/messages?pageNo={0}&pageSize={1}&sortBy={2}`,
  allGrowersCount: `${AdalConfigService.GrowerAPI}/growers/count`,

  updateGrower: `${AdalConfigService.GrowerAPI}/growers`,
  updateSingleGrower: `${AdalConfigService.GrowerAPI}/update/grower`,
  dashboard: `${AdalConfigService.GrowerAPI}/growers/statistics`,
  dashboardSaleUnit: `${AdalConfigService.GrowerAPI}/growers/salesUnit/statistics`,
  dashboardPic: `${AdalConfigService.GrowerAPI}/growers/PIC/statistics`,
  dashboardBrand: `${AdalConfigService.GrowerAPI}/growers/Brand/statistics`,
  updateStatistics: `${AdalConfigService.GrowerAPI}/growers/updateStatistics`,
  costEstimator: `${AdalConfigService.GrowerAPI}/messages/cost`,
  youTubeToken: 'Bearer ' + localStorage.getItem('youTubeAuthToken'),
  facebookToken: localStorage.getItem('facebookAuthToken'),
  youTubeAuthAPI: `${AdalConfigService.GrowerAPI}/auth/youtube`,
  facebookAuthAPI: `${AdalConfigService.GrowerAPI}/auth/facebook`,
  playListId: 'UURImA_-K27shYlVovEN8NqQ',
  uploadYouTubeVideo: `https://www.googleapis.com/upload/youtube/v3/videos`,
  uploadYouTubeVideoDetail: `https://www.googleapis.com/youtube/v3/videos`,
  whatsAppTemplate: `${AdalConfigService.GrowerAPI}/messages/whatsapp/templates?pageNo=1&pageSize=10&sortBy=-CREATED`,
  whatsAppTemplateData: `${AdalConfigService.GrowerAPI}/messages/whatsapp/templates/{0}`,
  whatsAppLimit: `${AdalConfigService.GrowerAPI}/messages/whatsapp/limit/{0}`,
  getConversation: `${AdalConfigService.getConversation}/conversations?pageNo={0}&countryCode={1}`,
  openConversation: `${AdalConfigService.getConversation}/conversations/{0}?pageNo={1}&countryCode={2}`,
  socketConnection: `${AdalConfigService.socketConnection}/ws`,
  messageCountList: `${AdalConfigService.GrowerAPI}/count/messages?startDate={0}&endDate={1}`,
  addGrower: `${AdalConfigService.GrowerAPI}/grower`
  // getConversation: `https://dev-msg-api.grower-console.syn-dpe.com/v1/conversations?pageNo={0}`,
  // openConversation: `https://qa-msg-api.grower-console.syn-dpe.com/v1/conversations/{0}?pageNo={1}`
};
export default config;

import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Inbox from '../views/Communication/whatsApp';
import Chat from '../views/Communication/Chat';
import Sidebar from '../views/Communication/sideBar';
import GrowerInformationForm from '../GrowerForm/growerInformationForm';
import Help from '../views/Help/Help';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'idnLogin',
    loginButtonStyle: 'idnLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    sampleTemplate:
      'https://grower-console-prod-assets.s3-ap-southeast-1.amazonaws.com/sample-files/Sample-idn.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        itemKey: 'sub1',
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      {
        key: 'inbox',
        label: t('Inbox'),
        itemKey: 'sub2',
        children: [
          {
            key: 'inbox',
            label: t('WhatsApp')
          },
          {
            key: 'comingsoon',
            label: t('Line')
          }
        ]
      },
      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },
      // {
      //   key: 'users',
      //   label: t('Users')
      // },
      {
        key: 'help',
        label: t('Help')
      }
    ],
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },
      // {
      //   pathName: '/users',
      //   componentName: Users
      // },
      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      },
      {
        pathName: '/inbox',
        componentName: Inbox
      },
      {
        pathName: '/inbox/whatsapp',
        componentName: Inbox
      },
      {
        pathName: '/inbox/:id',
        componentName: Chat
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '/grower-information-form',
        componentName: GrowerInformationForm
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },
        { key: 'Acres', value: t('Hectares') },
        { key: 'Crop', value: t('Crop') },
        { key: 'CropHistory', value: t('Crop History') },
        { key: 'Tags', value: t('Tags') },
        { key: 'Area', value: t('Province') },
        { key: 'salesUnit', value: t('Source') },
        { key: 'mobileNumber', value: t('Mobile Number') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        Crop: [{ key: 'in', value: t('in') }],
        CropHistory: [{ key: 'in', value: t('in') }],
        Tags: [{ key: 'in', value: t('in') }],
        Area: [{ key: 'in', value: t('in') }],
        salesUnit: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: []
      },
      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        fieldName: 'GrowersRegion',
        initialValue: 'GrowersRegion',
        placeholder: t('Select Some Regions'),
        handler: 'handleRegionSelectAll',
        rules: []
      },
      {
        key: 'allArea',
        optionValue: 'childrenArea',
        fieldName: 'GrowersArea',
        initialValue: 'GrowersArea',
        placeholder: t('Select Some Province'),
        handler: 'handleAreaSelectAll',
        rules: []
      },
      {
        key: 'allCropHistory',
        optionValue: 'childrenCropHistory',
        fieldName: 'GrowersCropHistory',
        initialValue: 'GrowersCropHistory',
        placeholder: t('Select Some Crop History'),
        handler: 'handleCropHistorySelectAll',
        rules: []
      },
      {
        key: 'allTags',
        optionValue: 'childrenTags',
        fieldName: 'GrowersTags',
        initialValue: 'GrowersTags',
        placeholder: t('Select Some Tags'),
        handler: 'handleTagsSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Hectare)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Area'),
          fieldName: 'Zone',
          initialValue: 'zone',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter area'),
            whitespace: true
          }
        },
        {
          label: t('Province'),
          fieldName: 'area',
          initialValue: 'area',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter province'),
            whitespace: true
          }
        },

        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Source'),
          fieldName: 'Unit',
          initialValue: 'unit',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter source'),
            whitespace: true
          }
        },
        {
          label: t('Syngenta Product'),
          fieldName: 'syngentaProduct',
          initialValue: 'syngentaProduct',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter '),
            whitespace: true
          }
        }
      ]
    },
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (hectare)'),
        dataIndex: 'landsize'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (hectare)'),
        dataIndex: 'landSize'
      }
    ],
    replaceTitle: ['0-20 hectares', '21-50 hectares', '51-100 hectares', '> 100 hectares'],
    saveButtonPosition: 'idnButtonPosition',
    searchAreaMessage: {
      message: t('Please select some province'),
      placeholder: t('Select Some Province')
    },
    searchHectaresMessage: {
      message: t('Please enter hectares value'),
      placeholder: t('Enter Hectares Value')
    },
    searchSourceMessage: {
      message: t('Please select some source'),
      placeholder: t('Select Some Source')
    },
    paiChart: 'idnDistributionCard',
    idnCropchart: 'idnCropDistributionCard',
    tagVisible: true
  };
};

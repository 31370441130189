/** Upload Actions */
export const REQUEST_UPLOAD = 'REQUEST_UPLOAD';
export const REQUEST_UPLOAD_SUCCESS = 'REQUEST_UPLOAD_SUCCESS';
export const REQUEST_UPLOAD_FAILURE = 'REQUEST_UPLOAD_FAILURE';
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
export const TOGGLE_DRAWER_STATUS = 'TOGGLE_DRAWER_STATUS';
export const HIGHLIGHT_TABEL_ROW = 'HIGHLIGHT_TABEL_ROW';
export const CLEAR_ALERT_STATE = 'CLEAR_ALERT_STATE';
export const CLEAR_PROGRESS_STATUS = 'CLEAR_PROGRESS_STATUS';
export const GET_UN_READ_MESSAGES = 'GET_UN_READ_MESSAGES';
export const CLEAR_UN_READ_MESSAGES = 'CLEAR_UN_READ_MESSAGES';
/** Alert Message Actions */
export const TOGGLE_SUCCESS_NOTIFY = 'TOGGLE_SUCCESS_NOTIFY';
export const TOGGLE_ERROR_NOTIFY = 'TOGGLE_ERROR_NOTIFY';
export const FETCH_SEARCH_DRAWER = 'FETCH_SEARCH_DRAWER';
/******* Grower page Action */
export const UPDATE_GROWER_DRAWER = 'UPDATE_GROWER_DRAWER';
export const FETCH_GROWER_LOADING = 'FETCH_GROWER_LOADING';
export const FETCH_GROWER_PENDING = 'FETCH_GROWER_PENDING';
export const FETCH_GROWER_SUCCESS = 'FETCH_GROWER_SUCCESS';
export const FETCH_GROWER_ERROR = 'FETCH_GROWER_ERROR';
export const FETCH_GROUP_LIST_SUCCESS_RESULT = 'FETCH_GROUP_LIST_SUCCESS_RESULT';
export const FETCH_REGION_CROP_PENDING = 'FETCH_REGION_CROP_PENDING';
export const FETCH_ACTIVITITY_TYPE_SUCCESS = 'FETCH_ACTIVITITY_TYPE_SUCCESS';
export const FETCH_REGION_CROP_SUCCESS = 'FETCH_REGION_CROP_SUCCESS';
export const FETCH_CROP_SUCCESS = 'FETCH_CROP_SUCCESS';
export const FETCH_ZONE_SUCCESS = 'FETCH_ZONE_SUCCESS';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_AREA_SUCCESS = 'FETCH_AREA_SUCCESS';
export const FETCH_RETAILER_NAME_SUCCESS = 'FETCH_RETAILER_NAME_SUCCESS';
export const FETCH_RETAILER_CODE_SUCCESS = 'FETCH_RETAILER_CODE_SUCCESS';
export const FETCH_FRANCHISE_CODE_SUCCESS = 'FETCH_FRANCHISE_CODE_SUCCESS';
export const FETCH_STOCKIST_CODE_SUCCESS = 'FETCH_STOCKIST_CODE_SUCCESS';
export const FETCH_LAND_SIZE_SUCCESS = 'FETCH_LAND_SIZE_SUCCESS';
export const FETCH_MOBILE_NUMBER_SUCCESS = 'FETCH_MOBILE_NUMBER_SUCCESS';
export const FETCH_CLASSIFICATION_SUCCESS = 'FETCH_CLASSIFICATION_SUCCESS';
export const FETCH_SEGMENT_SUCCESS = 'FETCH_SEGMENT_SUCCESS';
export const FETCH_PROVINCE_SUCCESS = 'FETCH_PROVINCE_SUCCESS';
export const FETCH_PRAMUKHTAG_SUCCESS = 'FETCH_PRAMUKHTAG_SUCCESS';
export const FETCH_STATE_SUCCESS = 'FETCH_STATE_SUCCESS';
export const FETCH_VILLAGE_SUCCESS = 'FETCH_VILLAGE_SUCCESS';
export const FETCH_TALUKA_SUCCESS = 'FETCH_TALUKA_SUCCESS';
export const FETCH_MDOHQ_SUCCESS = 'FETCH_MDOHQ_SUCCESS';
export const FETCH_TVC_SUCCESS = 'FETCH_TVC_SUCCESS';
export const FETCH_WA_SUCCESS = 'FETCH_WA_SUCCESS';
export const FETCH_FB_SUCCESS = 'FETCH_FB_SUCCESS';
export const FETCH_YB_SUCCESS = 'FETCH_YB_SUCCESS';
export const FETCH_IG_SUCCESS = 'FETCH_IG_SUCCESS';
export const FETCH_FACEBOOK_SUCCESS = 'FETCH_FACEBOOK_SUCCESS';
export const  FETCH_SYNGENTA_PRODUCT_SUCCESS = 'FETCH_SYNGENTA_PRODUCT_SUCCESS';
export const FETCH_ZALO_SUCCESS ='FETCH_ZALO_SUCCESS';
export const FETCH_SYT_GROWER_CORN_SUCCESS = 'FETCH_SYT_GROWER_CORN_SUCCESS';
export const FETCH_SYT_GROWER_RICE_SUCCESS = 'FETCH_SYT_GROWER_RICE_SUCCESS';
export const FETCH_PREFFERED_HYBRID_CORN_SUCCESS = 'FETCH_PREFFERED_HYBRID_CORN_SUCCESS';
export const FETCH_PREFFERED_HYBRID_RICE_SUCCESS = 'FETCH_PREFFERED_HYBRID_RICE_SUCCESS';
export const FETCH_SEASON_SEGEMENTATION_SUCCESS = 'FETCH_SEASON_SEGEMENTATION_SUCCESS';
export const FETCH_CROP_HISTORY_SUCCESS = 'FETCH_CROP_HISTORY_SUCCESS';
export const FETCH_PIC_SUCCESS = 'FETCH_PIC_SUCCESS';
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS';
export const FETCH_SOURCE_SUCCESS = 'FETCH_SOURCE_SUCCESS';
export const FETCH_CHANNEL_SUCCESS = ' FETCH_CHANNEL_SUCCESS';
export const FETCH_CITY_SUCCESS = 'FETCH_CITY_SUCCESS';
export const FETCH_DISTRICT_SUCCESS = 'FETCH_DISTRICT_SUCCESS';
export const FETCH_REGION_CROP_ERROR = 'FETCH_REGION_CROP_ERROR';
export const FETCH_ACARES_SUCCESS = 'FETCH_ACARES_SUCCESS';
export const FETCH_TMHQ_SUCCESS = 'FETCH_TMHQ_SUCCESS';
export const UPLOAD_GROWER_PENDING = 'UPLOAD_GROWER_PENDING';
export const UPLOAD_GROWER_SUCCESS = 'UPLOAD_GROWER_SUCCESS';
export const UPLOAD_GROWER_ERROR = 'UPLOAD_GROWER_ERROR';
export const GROWER_UPDATE_PENDING = 'GROWER_UPDATE_PENDING';
export const GROWER_UPDATE_SUCCESS = 'GROWER_UPDATE_SUCCESS';
export const GROWER_UPDATE_FAILURE = 'GROWER_UPDATE_FAILURE';
export const GROWER_UPDATE_LOADING = 'GROWER_UPDATE_LOADING';
export const CLEAR_GROWER_UPDATE = 'CLEAR_GROWER_UPDATE';
export const CREATE_GROWER_MODAL = 'CREATE_GROWER_MODAL';
export const HIGHLIGHT_GROWER_TABEL_ROW = 'HIGHLIGHT_GROWER_TABEL_ROW';
export const SEARCH_GROWERS_PENDING = 'SEARCH_GROWERS_PENDING';
export const SEARCH_GROWERS_SUCCESS = 'SEARCH_GROWERS_SUCCESS';
export const SEARCH_GROWERS_FAILURE = 'SEARCH_GROWERS_FAILURE';
export const SEARCH_GROWERS_LOADING = 'SEARCH_GROWERS_LOADING';
export const SEARCH_GROWERS_TEXT = 'SEARCH_GROWERS_TEXT';
export const CLEAR_SEARCH_RESULT = 'CLEAR_SEARCH_RESULT';

/* Update Single grower new Api */

export const SINGLE_GROWER_UPDATE_PENDING = 'SINGLE_GROWER_UPDATE_PENDING';
export const SINGLE_GROWER_UPDATE_SUCCESS = 'SINGLE_GROWER_UPDATE_SUCCESS';
export const SINGLE_GROWER_UPDATE_FAILURE = 'SINGLE_GROWER_UPDATE_FAILURE';
export const SINGLE_GROWER_UPDATE_LOADING = 'SINGLE_GROWER_UPDATE_LOADING';
export const CLEAR_SINGLE_GROWER_UPDATE = 'CLEAR_SINGLE_GROWER_UPDATE';
export const CREATE_SINGLE_GROWER_MODAL = 'CREATE_SINGLE_GROWER_MODAL';
export const HIGHLIGHT_SINGLE_GROWER_TABEL_ROW = 'HIGHLIGHT_SINGLE_GROWER_TABEL_ROW';

/***** Grower Group Actions *****/

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILURE = 'CREATE_GROUP_FAILURE';
export const CREATE_GROUP_DRAWER = 'CREATE_GROUP_DRAWER';
export const FETCH_GROUP_LIST_PENDING = 'FETCH_GROUP_LIST_PENDING';
export const FETCH_GROUP_LIST_SUCCESS = 'FETCH_GROUP_LIST_SUCCESS';
export const FETCH_GROUP_LIST_ERROR = 'FETCH_GROUP_LIST_ERROR';

/**** Delete Group */
export const DELETE_GROUP_PENDING = 'DELETE_GROUP_PENDING';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
/**** Update Group */
export const UPDATE_GROUP_DRAWER = 'UPDATE_GROUP_DRAWER';
export const GROUP_UPDATE_PENDING = 'GROUP_UPDATE_PENDING';
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS';
export const GROUP_UPDATE_FAILURE = 'GROUP_UPDATE_FAILURE';

/* fetch search result */

export const FETCH_SEARCH_PENDING = 'FETCH_SEARCH_PENDING';
export const FETCH_SEARCH_SUCCESS = 'FETCH_SEARCH_SUCCESS';
export const FETCH_SEARCH_UPDATE = 'FETCH_SEARCH_UPDATE';
export const FETCH_SEARCH_ERROR = 'FETCH_SEARCH_ERROR';
export const SEARCH_GROUP_LIST = 'SEARCH_GROUP_LIST';
export const HIGHLIGHT_GROUP_TABEL_ROW = 'HIGHLIGHT_GROUP_TABEL_ROW';
export const GROUP_MEMBERS_LIST_PENDING = 'GROUP_MEMBERS_LIST_PENDING';
export const GROUP_MEMBERS_LIST_SUCCESS = 'GROUP_MEMBERS_LIST_SUCCESS';
export const GROUP_MEMBERS_LIST_FAILURE = 'GROUP_MEMBERS_LIST_FAILURE';
export const GROUP_MEMBERS_LIST_LOADING = 'GROUP_MEMBERS_LIST_LOADING';
export const FETCH_GROUP_MEMBER_LIST_PENDING = ' FETCH_GROUP_MEMBER_LIST_PENDING';
export const FETCH_GROUP_MEMBER_LIST_SUCCESS = 'FETCH_GROUP_MEMBER_LIST_SUCCESS';
export const FETCH_GROUP_MEMBER_LIST_ERROR = 'FETCH_GROUP_MEMBER_LIST_ERROR';
export const CLEAR_GROUP_MEMBER_LIST = 'CLEAR_GROUP_MEMBER_LIST';
export const GET_SEARCH_STATE = 'GET_SEARCH_STATE';
/***** Grower Message Actions *****/

export const REQUEST_MESSAGE_SEND = 'CREATE_GROUP';
export const REQUEST_MESSAGE_SEND_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const REQUEST_MESSAGE_SEND_FAILURE = 'CREATE_GROUP_FAILURE';
export const MESSAGE_LIST_PENDING = 'MESSAGE_LIST_PENDING';
export const MESSAGE_LIST_SUCCESS = 'MESSAGE_LIST_SUCCESS';
export const MESSAGE_LIST_FAILURE = 'MESSAGE_LIST_FAILURE';
export const MESSAGE_LIST_LOADING = 'MESSAGE_LIST_LOADING';
export const HIGHLIGHT_MESSAGE_TABEL_ROW = 'HIGHLIGHT_MESSAGE_TABEL_ROW';
export const HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW = 'HIGHLIGHT_SCHEDULE_MESSAGE_TABEL_ROW';
/** Global Spinner action */
export const TOGGLE_SPIN_STATUS = 'TOGGLE_SPIN_STATUS';

/* Message Count List */
export const MESSAGE_COUNT_LIST_PENDING = 'MESSAGE_COUNT_LIST_PENDING';
export const MESSAGE_COUNT_LIST_SUCCESS = 'MESSAGE_COUNT_LIST_SUCCESS';
export const MESSAGE_COUNT_LIST_FAILURE = 'MESSAGE_COUNT_LIST_FAILURE';
export const MESSAGE_COUNT_LIST_LOADING = 'MESSAGE_COUNT_LIST_LOADING';
/******* Webstore actions */
export const WEBSTORE_LIST_PENDING = 'WEBSTORE_LIST_PENDING';
export const WEBSTORE_LIST_SUCCESS = 'WEBSTORE_LIST_SUCCESS';
export const WEBSTORE_LIST_FAILURE = 'WEBSTORE_LIST_FAILURE';
export const WEBSTORE_LIST_LOADING = 'WEBSTORE_LIST_LOADING';
export const COMMUNICATION_LIST_PENDING = 'COMMUNICATION_LIST_PENDING';
export const COMMUNICATION_LIST_SUCCESS = 'COMMUNICATION_LIST_SUCCESS';
export const COMMUNICATION_LIST_FAILURE = 'COMMUNICATION_LIST_FAILURE';
export const COMMUNICATION_LIST_LOADING = 'COMMUNICATION_LIST_LOADING';
export const REGION_LIST_PENDING = 'REGION_LIST_PENDING';
export const REGION_LIST_SUCCESS = 'REGION_LIST_SUCCESS';
export const REGION_LIST_FAILURE = 'REGION_LIST_FAILURE';
/****** webstore service message sending actions */

export const REQUEST_WEBSTORE_MESSAGE_SEND = 'REQUEST_WEBSTORE_MESSAGE_SEND';
export const REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS = 'REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS';
export const REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE = 'REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE';

/****** webstore all growers counts api actions */

export const ALL_GROWERS_COUNT_PENDING = 'ALL_GROWERS_COUNT_PENDING';
export const ALL_GROWERS_COUNT_SUCCESS = 'ALL_GROWERS_COUNT_SUCCESS';
export const ALL_GROWERS_COUNT_FAILURE = 'ALL_GROWERS_COUNT_FAILURE';
export const ALL_GROWERS_COUNT_LOADING = 'ALL_GROWERS_COUNT_LOADING';

export const FETCH_DASHBOARD_PENDING = 'FETCH_DASHBOARD_PENDING';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_ERROR = 'FETCH_DASHBOARD_ERROR';
export const FETCH_UPDATE_PENDING = 'FETCH_UPDATE_PENDING';
export const FETCH_UPDATE_SUCCESS = 'FETCH_UPDATE_SUCCESS';
export const FETCH_UPDATE_ERROR = 'FETCH_UPDATE_ERROR';

export const FETCH_ESTIMATOR_PENDING = 'FETCH_ESTIMATOR_PENDING';
export const FETCH_ESTIMATOR_SUCCESS = 'FETCH_ESTIMATOR_SUCCESS';
export const FETCH_ESTIMATOR_ERROR = 'FETCH_ESTIMATOR_ERROR';
export const FETCH_ESTIMATOR_CLEAR = 'FETCH_ESTIMATOR_CLEAR';
export const FETCH_ESTIMATOR_LOADER = 'FETCH_ESTIMATOR_LOADER';

/** Youtube Listinng */

export const YOUTUBE_LIST_PENDING = 'YOUTUBE_LIST_PENDING';
export const YOUTUBE_LIST_SUCCESS = 'YOUTUBE_LIST_SUCCESS';
export const YOUTUBE_LIST_FAILURE = 'YOUTUBE_LIST_FAILURE';
export const YOUTUBE_LIST_LOADING = 'YOUTUBE_LIST_LOADING';

export const UPLOAD_YOUTUBE_PENDING = 'UPLOAD_YOUTUBE_PENDING';
export const UPLOAD_YOUTUBE_SUCCESS = 'UPLOAD_YOUTUBE_SUCCESS';
export const UPLOAD_YOUTUBE_ERROR = 'UPLOAD_YOUTUBE_ERROR';
export const SESSION_TIMEOUT_ERROR = 'SESSION_TIMEOUT_ERROR';

export const UPLOAD_YOUTUBE_VIDEO_PENDING = 'UPLOAD_YOUTUBE_VIDEO_PENDING';
export const UPLOAD_YOUTUBE_VIDEO_SUCESS = 'UPLOAD_YOUTUBE_VIDEO_SUCESS';
export const UPLOAD_YOUTUBE_VIDEO_ERROR = 'UPLOAD_YOUTUBE_VIDEO_ERROR';
export const UPLOAD_YOUTUBE_VIDEO_PROGRESS = 'UPLOAD_YOUTUBE_VIDEO_PROGRESS';
export const CLEAR_YOUTUBE_VIDEO_PROGRESS = 'CLEAR_YOUTUBE_VIDEO_PROGRESS';
export const UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING = 'UPLOAD_YOUTUBE_VIDEO_DETAILS_PENDING';
export const UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS = 'UPLOAD_YOUTUBE_VIDEO_DETAILS_SUCESS';
export const UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR = 'UPLOAD_YOUTUBE_VIDEO__DETAILS_ERROR';
export const CLEAR_YOUTUBE_VIDEO_STATUS = 'CLEAR_YOUTUBE_VIDEO_STATUS';

export const VIDEO_PROGRESS_SUCESS = 'VIDEO_PROGRESS_SUCESS';
export const VIDEO_PROGRESS_ERROR = 'VIDEO_PROGRESS_ERROR';

/* Facebook Listinng */
export const FACEBOOK_LIST_PENDING = 'FACEBOOK_LIST_PENDING';
export const FACEBOOK_LIST_SUCCESS = 'FACEBOOK_LIST_SUCCESS';
export const FACEBOOK_LIST_FAILURE = 'FACEBOOK_LIST_FAILURE';

export const UPLOAD_FACEBOOK_PENDING = 'UPLOAD_FACEBOOK_PENDING';
export const UPLOAD_FACEBOOK_SUCCESS = 'UPLOAD_FACEBOOK_SUCCESS';
export const UPLOAD_FACEBOOK_ERROR = 'UPLOAD_FACEBOOK_ERROR';

/* Facebook Post */
export const UPLOAD_FACEBOOK_POST_PENDING = 'UPLOAD_FACEBOOK_POST_PENDING';
export const UPLOAD_FACEBOOK_POST_SUCCESS = 'UPLOAD_FACEBOOK_POST_SUCCESS';
export const UPLOAD_FACEBOOK_POST_ERROR = 'UPLOAD_FACEBOOK_POST_ERROR';
export const UPLOAD_FACEBOOK_POST_CLICKED = 'UPLOAD_FACEBOOK_POST_CLICKED';

/* Schedule Message Post */
export const SCHEDULE_MESSAGE_PENDING = 'SCHEDULE_MESSAGE_PENDING';
export const SCHEDULE_MESSAGE_SUCCESS = 'SCHEDULE_MESSAGE_SUCCESS';
export const SCHEDULE_MESSAGE_ERROR = 'SCHEDULE_MESSAGE_ERROR';
export const CLEAR_SCHEDULE_MESSAGE_STATUS = 'CLEAR_SCHEDULE_MESSAGE_STATUS';
/* Schedule Message List */
export const SCHEDULE_MESSAGE_LIST_PENDING = 'SCHEDULE_MESSAGE_LIST_PENDING';
export const SCHEDULE_MESSAGE_LIST_SUCCESS = 'SCHEDULE_MESSAGE_LIST_SUCCESS';
export const SCHEDULE_MESSAGE_LIST_ERROR = 'SCHEDULE_MESSAGE_LIST_ERROR';

/* Download Seach Result */
export const DOWNLOAD_SEARCH_RESULT_PENDING = 'DOWNLOAD_SEARCH_RESULT_PENDING';
export const DOWNLOAD_SEARCH_RESULT_SUCESS = 'DOWNLOAD_SEARCH_RESULT_SUCESS';
export const DOWNLOAD_SEARCH_RESULT_ERROR = 'DOWNLOAD_SEARCH_RESULT_ERROR';

/* get whatsApp  Template */
export const WhATSAP_TEMPLATE_PENDING = 'WhATSAP_TEMPLATE_PENDING';
export const WhATSAP_TEMPLATE_SUCESS = 'WhATSAP_TEMPLATE_SUCESS';
export const WhATSAP_TEMPLATE_ERROR = 'WhATSAP_TEMPLATE_ERROR';
export const FETCH_TEMPLATE_CLEAR = 'FETCH_TEMPLATE_CLEAR';

/* GET WHATSAPP TEMPLATE DATA */
export const WhATSAP_TEMPLATEDATA_PENDING = 'WhATSAP_TEMPLATEDATA_PENDING';
export const WhATSAP_TEMPLATEDATA_SUCESS = 'WhATSAP_TEMPLATEDATA_SUCESS';
export const WhATSAP_TEMPLATEDATA_ERROR = 'WhATSAP_TEMPLATEDATA_ERROR';
export const WhATSAP_TEMPLATEDATA_CLEAR = 'WhATSAP_TEMPLATEDATA_CLEAR';

/* Get Sales Unit */
export const FETCH_SALESUNIT_PENDING = 'FETCH_SALESUNIT_PENDING';
export const FETCH_SALESUNIT_SUCCESS = 'FETCH_SALESUNIT_SUCCESS';
export const FETCH_SALESUNIT_ERROR = 'FETCH_SALESUNIT_ERROR';

/* Get Pic */
export const FETCH_PIC_PENDING = 'FETCH_PIC_PENDING';
export const FETCH_PIC_SUCCESSD = 'FETCH_PIC_SUCCESSD';
export const FETCH_PIC_ERROR = 'FETCH_PIC_ERROR';
/* Get Brand */
export const FETCH_BRAND_PENDING = 'FETCH_BRAND_PENDING';
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS';
export const FETCH_BRAND_ERROR = 'FETCH_BRAND_ERROR';

/* Get Whatsapp Limit */
export const FETCH_LIMIT_PENDING = 'FETCH_LIMIT_PENDING';
export const FETCH_LIMIT_SUCCESS = 'FETCH_LIMIT_SUCCESS';
export const FETCH_LIMIT_ERROR = 'FETCH_LIMIT_ERROR';
export const FETCH_LIMIT_CLEAR = 'FETCH_LIMIT_CLEAR';
export const FETCH_LIMIT_LOADER = 'FETCH_LIMIT_LOADER';

/* Get conversation  */
export const GET_CONVERSATION_PENDING = 'GET_CONVERSATION_PENDING';
export const GET_CONVERSATION_SUCCESS = 'GET_CONVERSATION_SUCCESS';
export const GET_CONVERSATION_ERROR = 'GET_CONVERSATION_ERROR';
export const GET_CONVERSATION_CLEAR = 'GET_CONVERSATION_CLEAR';
export const GET_CONVERSATION_LOADER = 'GET_CONVERSATION_LOADER';

/* Open conversation  */
export const OPEN_CONVERSATION_PENDING = 'OPEN_CONVERSATION_PENDING';
export const OPEN_CONVERSATION_SUCCESS = 'OPEN_CONVERSATION_SUCCESS';
export const OPEN_CONVERSATION_ERROR = 'OPEN_CONVERSATION_ERROR';
export const OPEN_CONVERSATION_CLEAR = 'OPEN_CONVERSATION_CLEAR';
export const OPEN_CONVERSATION_LOADER = 'OPEN_CONVERSATION_LOADER';
export const CLEAR_CONVERSATION = 'CLEAR_CONVERSATION';

/* Send MMS */
export const SEND_MMS_PENDING = 'SEND_MMS_PENDING';
export const SEND_MMS_SUCCESS = 'SEND_MMS_SUCCESS';
export const SEND_MMS_ERROR = 'SEND_MMS_ERROR';
export const CLEAR_SEND_MMS_RESULT = 'CLEAR_SEND_MMS_RESULT';

/* Schedule MMS */
export const SCHEDULE_MMS_PENDING = 'SCHEDULE_MMS_PENDING';
export const SCHEDULE_MMS_SUCCESS = 'SCHEDULE_MMS_SUCCESS';
export const SCHEDULE_MMS_ERROR = 'SCHEDULE_MMS_ERROR';
export const CLEAR_SCHEDULE_MMS_STATUS = 'CLEAR_SCHEDULE_MMS_STATUS';

/* Schedule Message Update */
export const SCHEDULE_MESSAGE_UPDATE_PENDING = 'SCHEDULE_MESSAGE_UPDATE_PENDING';
export const SCHEDULE_MESSAGE_UPDATE_SUCCESS = 'SCHEDULE_MESSAGE_UPDATE_SUCCESS';
export const SCHEDULE_MESSAGE_UPDATE_ERROR = 'SCHEDULE_MESSAGE_UPDATE_ERROR';
export const UPDATE_SCHEDULE_MESSAGE_DRAWER = 'UPDATE_SCHEDULE_MESSAGE_DRAWER';

/* Schedule Message Delete */

export const SCHEDULE_MESSAGE_DELETE_PENDING = 'SCHEDULE_MESSAGE_DELETE_PENDING';
export const SCHEDULE_MESSAGE_DELETE_SUCCESS = 'SCHEDULE_MESSAGE_DELETE_SUCCESS';
export const SCHEDULE_MESSAGE_DELETE_ERROR = 'SCHEDULE_MESSAGE_DELETE_ERROR';
export const DELETE_SCHEDULE_MESSAGE_DRAWER = 'DELETE_SCHEDULE_MESSAGE_DRAWER';

/* Schedule MMS Update */
export const SCHEDULE_MMS_UPDATE_PENDING = 'SCHEDULE_MMS_UPDATE_PENDING';
export const SCHEDULE_MMS_UPDATE_SUCCESS = 'SCHEDULE_MMS_UPDATE_SUCCESS';
export const SCHEDULE_MMS_UPDATE_ERROR = 'SCHEDULE_MMS_UPDATE_ERROR';
export const CLEAR_SCHEDULE_MMS_UPDATE_STATUS = 'CLEAR_SCHEDULE_MMS_UPDATE_STATUS';

/* Opt In growers */
export const FETCH_OPTIN_PENDING = 'FETCH_OPTIN_PENDING';
export const FETCH_OPTIN_SUCCESS = 'FETCH_OPTIN_SUCCESS';
export const FETCH_OPTIN_UPDATE = 'FETCH_OPTIN_UPDATE';
export const FETCH_OPTIN_ERROR = 'FETCH_OPTIN_ERROR';
export const CLEAR_OPTIN_RESULT = 'CLEAR_OPTIN_RESULT';
export const GET_OPTIN_STATE = 'GET_OPTIN_STATE';

/* Opt Out Growers */
export const FETCH_OPTOUT_PENDING = 'FETCH_OPTOUT_PENDING';
export const FETCH_OPTOUT_SUCCESS = 'FETCH_OPTOUT_SUCCESS';
export const FETCH_OPTOUT_UPDATE = 'FETCH_OPTOUT_UPDATE';
export const FETCH_OPTOUT_ERROR = 'FETCH_OPTOUT_ERROR';
export const CLEAR_OPTOUT_RESULT = 'CLEAR_OPTOUT_RESULT';
export const GET_OPTOUT_STATE = 'GET_OPTOUT_STATE';

/* Download Source By Count */
export const DOWNLOAD_SOURCE_RESULT_PENDING = 'DOWNLOAD_SOURCE_RESULT_PENDING';
export const DOWNLOAD_SOURCE_RESULT_SUCESS = 'DOWNLOAD_SOURCE_RESULT_SUCESS';
export const DOWNLOAD_SOURCE_RESULT_ERROR = 'DOWNLOAD_SOURCE_RESULT_ERROR';

/* Download Group Data */
export const DOWNLOAD_GROUP_DATA_PENDING = 'DOWNLOAD_GROUP_DATA_PENDING';
export const DOWNLOAD_GROUP_DATA_SUCESS = 'DOWNLOAD_GROUP_DATA_SUCESS';
export const DOWNLOAD_GROUP_DATA_ERROR = 'DOWNLOAD_GROUP_DATA_ERROR';

/** Add Grower Form Actions */
export const ADD_GROWER_PENDING = 'ADD_GROWER_PENDING';
export const ADD_GROWER_SUCCESS = 'ADD_GROWER_SUCCESS';
export const ADD_GROWER_ERROR = 'ADD_GROWER_ERROR';

export const DOWNLOAD_REPORT_PENDING = 'DOWNLOAD_REPORT_PENDING';
export const DOWNLOAD_REPORT_SUCESS = 'DOWNLOAD_REPORT_SUCESS';
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR';

import axios from 'axios';
import config from '../config';
import {
  UPLOAD_YOUTUBE_VIDEO_PENDING,
  UPLOAD_YOUTUBE_VIDEO_SUCESS,
  UPLOAD_YOUTUBE_VIDEO_ERROR,
  CLEAR_YOUTUBE_VIDEO_PROGRESS,
  UPLOAD_YOUTUBE_VIDEO_PROGRESS,
  TOGGLE_DRAWER_STATUS,
  VIDEO_PROGRESS_SUCESS,
  CLEAR_YOUTUBE_VIDEO_STATUS,
  VIDEO_PROGRESS_ERROR
} from '../constants';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
import { youTubeListWithFetchAPI } from '../actions/uploadYouTubeList';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
export function uploadVideoPending(Progressstatus) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_PENDING,
    payload: Progressstatus
  };
}
export function uploadVideoSuccess(response) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_SUCESS,
    payload: response
  };
}

export function uploadVideoFailure(gerror) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_ERROR,
    payload: gerror
  };
}
export function clearUploadVideoStatus() {
  return {
    type: CLEAR_YOUTUBE_VIDEO_PROGRESS
  };
}

export function uploadVideoProgress(progressdata) {
  return {
    type: UPLOAD_YOUTUBE_VIDEO_PROGRESS,
    payload: progressdata
  };
}

export function videoProgressSucess(progressStatus) {
  return {
    type: VIDEO_PROGRESS_SUCESS,
    payload: progressStatus
  };
}
export function videoProgressError(progressSucess) {
  return {
    type: VIDEO_PROGRESS_ERROR,
    payload: progressSucess
  };
}
export function clearYoutubeVideoStatus() {
  return {
    type: CLEAR_YOUTUBE_VIDEO_STATUS
  };
}
export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER_STATUS
  };
}

export const uploadYouTubeVideoService = (videoStatus) => {
  const resource = {
    status: {
      privacyStatus: 'private',
      embeddable: false,
      license: 'youtube'
    }
  };

  const formData = new FormData();
  formData.append('resource', JSON.stringify(resource));
  formData.append('media', videoStatus.file);
  const apiUri = config.uploadYouTubeVideo;
  return (dispatch) => {
    dispatch(uploadVideoPending(true));

    axios
      .post(apiUri, formData, {
        params: {
          part: 'snippet,status'
        },
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + localStorage.getItem('youTubeAuthToken')
        },
        onUploadProgress: function(progressEvent) {
          let currentTime = Date.now();
          let bytesUploaded = progressEvent.loaded;
          let totalBytes = progressEvent.total;
          let bytesPerSecond = bytesUploaded / ((currentTime - this.uploadStartTime) / 1000);
          let estimatedSecondsRemaining = (totalBytes - bytesUploaded) / bytesPerSecond;
          let percentageComplete = Math.round((bytesUploaded * 100) / totalBytes);

          dispatch(
            uploadVideoProgress({
              progressStatus: 'success',
              progressBarStatus: true,
              progress: percentageComplete
            })
          );
        }
      })

      .then((response) => {
        dispatch(uploadVideoProcessDetails(response.data.id));
        dispatch(uploadVideoPending({ uploadStatus: 'completed' }));

        dispatch(uploadVideoSuccess(response));
        dispatch(
          uploadVideoProgress({
            progressStatus: 'success',
            progressBarStatus: false,
            progress: 100
          })
        );
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);

        if (errorResponse.message.indexOf('403') > -1) {
          const response = {
            status: 403
          };
          return handleErrors(response, dispatch);
        }

        dispatch(
          uploadVideoPending({
            uploadStatus: 'pending'
          })
        );
        dispatch(
          uploadVideoProgress({
            progressStatus: 'exception',
            progressBarStatus: true,
            progress: 100
          })
        );

        dispatch(uploadVideoFailure(error));
        dispatch(
          errorNotification({
            type: 'error',
            message: error.response.data.error.message.replace(/<a.*?>/g, '').replace(/<\/a>/g, ''),
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};

export const uploadVideoProcessDetails = (videoId) => {
  return (dispatch) => {
    axios
      .get(`https://www.googleapis.com/youtube/v3/videos?part=processingDetails&id=${videoId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('youTubeAuthToken')
        }
      })
      .then((response) => {
        let processingStatus = response.data.items[0].processingDetails.processingStatus;
        let processingDetails = response.data.items[0].processingDetails;

        if (processingStatus === 'processing') {
          dispatch(uploadVideoProcessDetails(videoId));
        } else if (processingStatus === 'succeeded') {
          dispatch(videoProgressSucess(processingDetails));
        }
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        // const errorResponse = JSON.parse(parseData);
        // if (errorResponse.message.indexOf('403') > -1) {
        //   const response = {
        //     status: 403
        //   };
        //   return handleErrors(response, dispatch);
        // }
        dispatch(videoProgressError(error));
      });
  };
};

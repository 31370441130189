import {
  MESSAGE_LIST_PENDING,
  MESSAGE_LIST_SUCCESS,
  MESSAGE_LIST_FAILURE,
  MESSAGE_LIST_LOADING
} from '../constants/index';

const initialState = {
  data: {
    messageList: {},
    messageListError: {},
    messageListStatus: '',
    completed: false,
    loading: true
  }
};

function messageLists(state = initialState, action) {
  switch (action.type) {
    case MESSAGE_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case MESSAGE_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageList: action.payload,
          completed: false
        }
      });
    case MESSAGE_LIST_FAILURE:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageListError: action.payload,
          completed: false
        }
      });
    case MESSAGE_LIST_LOADING:
      return {
        data: {
          ...state.data,
          loading: action.payload
        }
      };
    default:
      return state;
  }
}

export default messageLists;

import React from 'react';
import { Form, Select } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { fetchTMHQ } from '../../../services/groupFilterService';
const { Option } = Select;
class SearchTHMQ extends React.Component {
  constructor(props) {
    super(props);
    this.childrenTmhq = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchTMHQ();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { searchCityMessage, t } = this.props;
    let tmhqData = this.props.groupTmhq || [];
    let tmhqCodeText = tmhqData.length > 0 ? tmhqData[0].items : [];
    this.childrenTmhq = tmhqCodeText.map((tmhq) => <Option key={tmhq}>{tmhq}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.tmhqValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              // message: t('Please select some sales unit')

              message: this.props.searchTmhqMessage && this.props.searchTmhqMessage.message
            }
          ]
        })(
          <Select
            name='brand'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={this.props.searchTmhqMessage && this.props.searchTmhqMessage.placeholder}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenTmhq}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchTMHQ: () => dispatch(fetchTMHQ())
  };
};
const mapStateToProps = function(state) {
  return {
    groupTmhq: get(state, 'groupFilterReducer.data.groupTmhq.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchTHMQ);

import React, { Component } from 'react';
import { Result, Button } from 'antd';
import { useTranslation } from 'react-i18next';
const changeRoute = () => {
  localStorage.clear();
  return (window.location.href = '/');
};
const Unauthorization = () => {
  const { t, i18n } = useTranslation();
  return (
    <Result
      status='401'
      title={t('Access Denied')}
      subTitle={t('Sorry, you are not authorized to access this page.')}
      extra={<Button type='primary'>{t('Go Back')}</Button>}
    />
  );
};
export default Unauthorization;

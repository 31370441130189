import {
    DOWNLOAD_REPORT_PENDING,
    DOWNLOAD_REPORT_SUCESS,
    DOWNLOAD_REPORT_ERROR
  } from '../constants';
  
  const initialState = {
    data: {
      downloadReport: {},
      reportError: {},
      users: [],
      completed: false
    }
  };
  
  function downloadReportResult(state = initialState, action) {
    switch (action.type) {
      case DOWNLOAD_REPORT_PENDING:
        return {
          data: {
            ...state.data,
            completed: true
          }
        };
      case DOWNLOAD_REPORT_SUCESS:
        return Object.assign({}, state, {
          data: {
            ...state.data,
            downloadReport: action.payload,
            completed: false
          }
        });
      case DOWNLOAD_REPORT_ERROR:
        return Object.assign({}, state, {
          data: {
            ...state.data,
            reportError: action.payload,
            completed: false
          }
        });
      default:
        return state;
    }
  }
  export default downloadReportResult;
  
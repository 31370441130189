import {
  FETCH_GROUP_MEMBER_LIST_PENDING,
  FETCH_GROUP_MEMBER_LIST_SUCCESS,
  FETCH_GROUP_MEMBER_LIST_ERROR,
  CLEAR_GROUP_MEMBER_LIST
} from '../constants/index';
import { toggleSpinStatus } from './globalSpinner';
import { handleErrors } from '../services/handleErrors';
import { formatUrl } from '../services/commonMethods';

import config from '../config';

export const groupMemberPending = (value) => {
  return {
    type: FETCH_GROUP_MEMBER_LIST_PENDING,
    payload: value
  };
};
export const groupMemberSuccess = (value) => {
  return {
    type: FETCH_GROUP_MEMBER_LIST_SUCCESS,
    payload: value
  };
};
export const groupMemberError = (val) => {
  return {
    type: FETCH_GROUP_MEMBER_LIST_ERROR,
    payload: val
  };
};
export const clearGroupMember = (val) => {
  return {
    type: CLEAR_GROUP_MEMBER_LIST
  };
};
export const fetchGroupMemberList = (pageNum, groupId) => {
  let apiUri;
  apiUri = formatUrl(config.growerMembers, groupId, pageNum, '10', ['-updated', '-created']);
  return (dispatch) => {
    dispatch(groupMemberPending());
    dispatch(toggleSpinStatus());
    fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(groupMemberSuccess(result));
        dispatch(toggleSpinStatus());
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
        dispatch(groupMemberError(e));
      });
  };
};

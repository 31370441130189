import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { Layout, Menu, Icon, Select, Popover, Drawer, Radio } from 'antd';
import './header.css';
import GCLogo from '../../../assets/img/gclogo.png';
import { MyProfileSVGIcon } from '../drawer/drawerIcons';
import { getRegions } from '../../../actions/webStoreList';
import { userLogin } from '../../../actions/authentication';
import SessionTimeOut from '../../commonComponents/errorboundry/showModel';
import { Translation } from 'react-i18next';
const { Header } = Layout;
const { Option } = Select;

class MainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    this.props.getRegions();
  }
  onClose() {
    this.setState({ visible: false });
  }
  handleClick() {
    this.setState({ visible: true });
  }

  render() {
    const { region, Context } = this.props;
    console.log('account>>', Context);
    const Usermenu = (
      <Menu className='popupmenuList'>
        <Menu.Item key='1' title={this.userDetails}>
          <Icon type='user' />
          <span>{Context.auth.account.name}</span>
        </Menu.Item>
        <Menu.Item key='2' title='Supported Language'>
          <Radio.Group defaultValue='eng'>
            <Radio.Button value='eng'>
              <Translation>{(t) => <>{t('English (UK)')}</>}</Translation>
            </Radio.Button>
            {/* <Radio.Button value='thai'>ไทย (Thai)</Radio.Button> */}
          </Radio.Group>
        </Menu.Item>
        <Menu.Item
          key='3'
          onClick={() => {
            Context.auth.onSignOut();
          }}>
          <a className='logoutLink'>
            <Icon type='poweroff' />
            <span>
              <Translation>{(t) => <>{t('Logout')}</>}</Translation>
            </span>
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <Header className='mainHeader'>
        <div className='logo'>
          <img src={GCLogo} />
        </div>
        <div className='rightMenu'>
          {region && (
            <Select defaultValue='in' showArrow={false}>
              <Option value='in' style={{ padding: 5 }}>
                <img style={{ width: '26px', height: '26px', margin: '0' }} src={region.flag_Url} />
                &nbsp;&nbsp;&nbsp;
                <span style={{ padding: '0', textTransform: 'uppercase' }}>
                  {region.country_code}
                </span>
              </Option>
            </Select>
          )}
          <Popover content={Usermenu} className='popoverContent' placement='bottomRight'>
            <MyProfileSVGIcon className='righmenuLink' />
          </Popover>
        </div>
        <Drawer
          title='My Profile'
          placement='right'
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        />
        <SessionTimeOut />
      </Header>
    );
  }
}

// export default MainHeader;
const mapDispatchToProps = function(dispatch) {
  return {
    userLogin(text) {
      dispatch(userLogin(text));
    },
    getRegions: () => dispatch(getRegions())
  };
};

const mapStateToProps = function(state) {
  return {
    userInfo: _get(state, 'authentication.user || {}'),
    region: _get(state, 'webStoreListReducer.regionListData.current_region', '{}')
  };
};
MainHeader.propTypes = {
  Context: PropTypes.object,
  connetectedUsersCount: PropTypes.number,
  getRegions: PropTypes.func
};
export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);

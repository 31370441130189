import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _get from 'lodash.get';
import { Spin } from 'antd';
import './globalSpinnerStyle.css';

class globalSpinner extends Component {
  render() {
    return (
      <Spin className='globalSpinner' spinning={this.props.loading} tip='Loading...' size='large' />
    );
  }
}

// const mapDispatchToProps = function(dispatch) {
//   return {
//     onCloseGroupDrawer() {
//       dispatch(ToggeleGroupDrawer());
//     }
//   };
// };

const mapStateToProps = function(state) {
  return {
    loading: _get(state, 'globalSpinner.loading', true)
  };
};
globalSpinner.propTypes = {
  loading: PropTypes.bool
};
export default connect(mapStateToProps)(globalSpinner);

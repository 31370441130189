import React from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  AutoComplete,
  Collapse,
  Card,
  Modal,
  Layout,
  Row,
  Col,
  Checkbox,
  Result
} from 'antd';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
import sortBy from 'lodash/sortBy';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { get, indexOf, keys } from 'lodash';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import jsonData from './data.json';
import regionData from './region.json';
import { fetchAddGrowers } from '../actions/addUpdatesGrowerForm';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
// import SucessMessage from './showSucess';
import { uniq } from 'lodash';
import './addGrowerForm.css';
const { Option } = Select;
const { Panel } = Collapse;
const { Footer } = Layout;
const AutoCompleteOption = AutoComplete.Option;
const cropValues = [
  { key: 'Padi', value: 'Rice' },
  { key: 'Jagung', value: 'Corn' },
  { key: 'Jagung Hibrida', value: 'Hybrid Corn' },
  { key: 'Kelapa sawit', value: 'Oil Plam' },
  { key: 'Kakao', value: 'Cocoa ' },
  { key: 'Kopi', value: 'Coffee' },
  { key: 'Bawang Merah', value: 'Shallot' },
  { key: 'Daun Bawang', value: 'Leek' },
  { key: 'Karet', value: 'Rubber' },
  { key: 'Timun', value: 'Cucumber' },
  { key: 'Timun-Timunan', value: 'Cucurbit' },
  { key: 'Teh', value: 'Tea' },
  { key: 'Tebu', value: 'Sugarcane' },
  { key: 'Kentang', value: 'Potato' },
  { key: 'Umbi-Umbian', value: 'Tubers' },
  { key: 'Cabai', value: 'Chili' },
  { key: 'Cengkeh', value: 'Clove' },
  { key: 'Tembakau', value: 'Tobacco' },
  { key: 'Tanaman Rimpang', value: 'Rhizome' },
  { key: 'Kacang Kedelai', value: 'Soy Bean' },
  { key: 'Kacang Kacangan', value: 'Beans' },
  { key: 'Apel', value: 'Apple' },
  { key: 'Mangga', value: 'Mango' },
  { key: 'Pisang', value: 'Banana' },
  { key: 'Jeruk', value: 'Citrus' },
  { key: 'Buah Buahan', value: 'Fruits' },
  { key: 'Kelapa', value: 'Coconut' },
  { key: 'Sayuran Hijau', value: 'Green Leaf Vegetable' },
  { key: 'Bunga-bungaan', value: 'Flowers' },
  { key: 'Tomat', value: 'Tomato' }
];
const locationValues = [
  { key: 'Aceh', value: 'Aceh' },
  { key: 'Sumatra Utara', value: 'Sumatra Utara' },
  { key: 'Riau', value: 'Riau' },
  { key: 'Kepulauan Riau', value: 'Kepulauan Riau' },
  { key: 'Sumatra Barat', value: 'Sumatra Barat' },
  { key: 'Jambi', value: 'Jambi' },
  { key: 'Bengkulu', value: 'Bengkulu' },
  { key: 'Sumatra Selatan', value: 'Sumatra Selatan' },
  { key: 'Bangka Belitung', value: 'Bangka Belitung' },
  { key: 'Lampung', value: 'Lampung' },
  { key: 'Jawa Barat', value: 'Jawa Barat' },
  { key: 'Banten', value: 'Banten' },
  { key: 'DKI Jakarta', value: 'DKI Jakarta' },
  { key: 'Jawa Tengah', value: 'Jawa Tengah' },
  { key: 'DIY', value: 'DIY' },
  { key: 'Jawa Timur', value: 'Jawa Timur' },
  { key: 'Bali', value: 'Bali' },
  { key: 'NTT', value: 'NTT' },
  { key: 'NTB', value: 'NTB' },
  { key: 'Kalimantan Utara', value: 'Kalimantan Utara' },
  { key: 'Kalimantan Tengah', value: 'Kalimantan Tengah' },
  { key: 'Kalimantan Selatan', value: 'Kalimantan Selatan' },
  { key: 'Kalimantan Barat', value: 'Kalimantan Barat' },
  { key: 'Kalimantan Timur', value: 'Kalimantan Timur' },
  { key: 'Sulawesi Utara', value: 'Sulawesi Utara' },
  { key: 'Sulawesi Selatan', value: 'Sulawesi Selatan' },
  { key: 'Sulawesi Tenggara', value: 'Sulawesi Tenggara' },
  { key: 'Sulawesi Barat', value: 'Sulawesi Barat' },
  { key: 'Sulawesi Tengah', value: 'Sulawesi Tengah' },
  { key: 'Gorontalo', value: 'Gorontalo' },
  { key: 'Maluku', value: 'Maluku' },
  { key: 'Maluku Utara ', value: 'Maluku Utara' },
  { key: 'Papua', value: 'Papua' },
  { key: 'Papua Barat', value: 'Papua Barat' }
];
const newLocationValues = [
  { key: 'Aceh', value: 'Aceh' },
  { key: 'Bali', value: 'Bali' },
  { key: 'Bangka Belitung', value: 'Bangka Belitung' },
  { key: 'Banten', value: 'Banten' },
  { key: 'Bengkulu', value: 'Bengkulu' },
  { key: 'Jawa Tengah', value: 'Jawa Tengah' },
  { key: 'Kalimantan Tengah', value: 'Kalimantan Tengah' },
  { key: 'Sulawesi Tengah', value: 'Sulawesi Tengah' },
  { key: 'Jawa timur', value: 'Jawa timur' },
  { key: 'Kalimantan Timur', value: 'Kalimantan Timur' },
  { key: 'Nusa Tenggara Timur', value: 'Nusa Tenggara Timur' },
  { key: 'Gorontalo', value: 'Gorontalo' },
  { key: 'Daerah Khusus Ibukota Jakarta', value: 'Daerah Khusus Ibukota Jakarta' },
  { key: 'Jambi', value: 'Jambi' },
  { key: 'Lampung', value: 'Lampung' },
  { key: 'Maluku', value: 'Maluku' },
  { key: 'Sulawesi Utara', value: 'Sulawesi Utara' },
  { key: 'Sumatera Utara', value: 'Sumatera Utara' },
  { key: 'Papua', value: 'Papua' },
  { key: 'Riau', value: 'Riau' },
  { key: 'Kepulauan Riau', value: 'Kepulauan Riau' },
  { key: 'Sulawesi Tenggara', value: 'Sulawesi Tenggara' },
  { key: 'Kalimantan Selatan', value: 'Kalimantan Selatan' },
  { key: 'Sulawesi Selatan', value: 'Sulawesi Selatan' },
  { key: 'Sumatera Selatan', value: 'Sumatera Selatan' },
  { key: 'Jawa Barat', value: 'Jawa Barat' },
  { key: 'Kalimantan Barat', value: 'Kalimantan Barat' },
  { key: 'Nusa tenggara Barat', value: 'Nusa tenggara Barat' },
  { key: 'Papua Barat', value: 'Papua Barat' },
  { key: 'Sulawesi Barat', value: 'Sulawesi Barat' },
  { key: 'Sumatera Barat', value: 'Sumatera Barat' },
  { key: 'Daerah Istimewa Yogyakarta ', value: 'Daerah Istimewa Yogyakarta' },
  { key: 'Kalimantan Utara', value: 'Kalimantan Utara' },
  { key: 'Maluku Utara', value: 'Maluku Utara' }
];
const landValues = ['1-5', '5-10', '10-20', '20-50', '>50 rai'];
class AddGrower extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      confirmDirty: false,
      autoCompleteResult: [],
      checked: true,
      visible: false,
      getProvince: '',
      getDistrict: '',
      getRegion: '',
      provinceDataVal: [],
      districteDataVal: [],
      done: null,
      message: '',
      thaiValue: '',
      provinceValue: '',
      checkProduct: false,
      disabled: false,
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let growers = {
          name: values.name.trim(''),
          surname: values.surname,
          mobile: `62${
            values.mobile.indexOf(0) == '0' ? values.mobile.substring(1) : values.mobile
          }`,
          address: {
            zone: 'NA',
            district: values.district,
            region: values.location,
            province: values.province,
            area: this.state.provinceValue[0].key
          },
          sourcing: {
            salesUnit: values.source
          },
          additionalFields: {
            syngentaProduct: values.checkProduct ? 'Yes' : 'No'
          },
          cropInfo: {
            current: {
              name: values.crop,
              acres: values.acre
            }
          }
        };

        this.props.fetchAddGrowers(growers);
        this.setState({
          disabled: true,
        });
        setTimeout(() => {
          this.setState(() => ({
            disabled: false,
          }));
        }, 10000);
      }
    });
  };
  onChange = (e) => {
    this.setState({ checked: !e.target.checked });
  };
  onChangeProduct = (e) => {
    this.setState({ checkProduct: e.target.checked });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
  };
  validateNumber = (rule, value, callback) => {
    let mob = /^[1-9]{1}[0-9]{10}$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Silakan masukkan nomor');
    } else {
      callback();
    }
  };
  validateMobileNumber = (rule, value, callback) => {
    let mob = /^[1-9]{1}[0-9]{12}$/;
    if (value === '' || value === undefined) {
      callback();
    }
    // else if (value.length > 12 || value.length < 12) {
    //   callback('12 digit nomor telepon');
    // }
    else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Harap masukkan nomor ponsel');
    } else {
      callback();
    }
  };

  validatorString = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    var re = new RegExp(
      '^([Bhutan Phuttha Phuttha Phuttha Phuttha Phatthana Phatthanawut 1234 ฿ 567890 ฦ])+$',
      'g'
    );
    if (value === '' || value === undefined) {
      callback();
    } else if (!re.test(value)) {
      callback('Harap masukkan nama yang valid');
    } else if (value.length < 2) {
      callback('Mohon isi informasi yang benar');
    } else {
      callback();
    }
  };
  isThaichar = (str, obj) => {
    var isThai = true;
    var orgi_text =
      ' Bhutan Phuttha Phuttha Phuttha Phuttha Phatthana Phatthanawut 1234 ฿ 567890 ฦ';
    var chk_text = str.split('');
    chk_text.filter(function(s) {
      if (orgi_text.indexOf(s) == -1) {
        isThai = false;
        obj.value = str.replace(RegExp(s, 'g'), '');
      }
    });
    return isThai; // ถ้าเป็น true แสดงว่าเป็นภาษาไทยทั้งหมด*/
  };
  validatorStringRegion = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please enter valid region');
    } else {
      callback();
    }
  };
  validatorName = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Mohon isi informasi yang benar.');
    } else {
      callback();
    }
  };
  validatorStringProvince = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please enter valid province');
    } else {
      callback();
    }
  };
  hideSucess = () => {
    this.setState({
      done: null,
      disabled:false
    });
  };

  componentDidMount = () => {
    const { history } = this.props;
    console.log('location', history.location);
    console.log('history>>', history);
    const params = queryString.parse(this.props.history.location.search);
    this.setState({
      formData: params
    });

    const provinceJsonData = jsonData;
    let provinceData = [];
    let districtData = [];
    let isProvinceAvailable = '';
    let isRegionAvailable = '';
    let isDistrictAvailable = '';
    let checkProvince = '';
    provinceJsonData.forEach((item, index) => {
      isProvinceAvailable = get(item, 'Province', '');
      isRegionAvailable = get(item, 'Region', '');
      isDistrictAvailable = get(item, 'District', '');
      provinceData.push(isProvinceAvailable);
    });
    this.setState({
      provinceDataVal: uniq(provinceData)
    });
  };
  componentDidUpdate = (previousProps, previousState) => {
    if (this.props.alertType === 'success' && this.state.done === null) {
      this.setState({
        done: true,
        message: this.props.alertMessage
      });
    } else {
      console.log('render error');
    }
  };
  callback = (key) => {
    console.log(key);
  };
  handleClick = () => {
    // props.history.push('/terms');
  };
  genExtra = () => (
    <SettingOutlined
      onClick={(event) => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.stopPropagation();
      }}
    />
  );
  getProvinceValue = (region, data) => {
    // const selectedIndex = event.target.options.selectedIndex;
    // console.log(event.target.options[selectedIndex].getAttribute('key'));
    // const selectedKey = r
    this.setState({
      provinceValue: data.filter((item) => item.value === region)
    });
  };
  getDistrictData = (province, data) => {
    this.setState({
      getDistrict: data.filter((item) => item.Province === province)
    });
    data.filter((item) => item.Province === province);
  };
  getRegionData = (district, data) => {
    this.setState({
      getRegion: data.filter((item) => item.District === district)
    });
  };
  onSearch = (val) => {
    console.log('val', val);
  };
  render() {
    const { getFieldDecorator } = this.props.form;
    const provinceJsonData = jsonData;
    const { autoCompleteResult } = this.state;
    console.log('checkproduct', this.state.checkProduct);

    let districtValues = [];
    let regionValues = [];
    let isDistrictAvailable = '';
    let isRegionAvailable = '';

    if (this.state.getDistrict) {
      this.state.getDistrict.forEach((item, index) => {
        isRegionAvailable = get(item, 'Region', '');
        isDistrictAvailable = get(item, 'District', '');
        districtValues.push(isDistrictAvailable);
      });
    }
    if (this.state.getRegion) {
      this.state.getRegion.forEach((item, index) => {
        isRegionAvailable = get(item, 'Region', '');
        // isDistrictAvailable = get(item, 'District', '');
        regionValues.push(isRegionAvailable);
      });
    }

    const provinceOption = this.state.provinceDataVal.map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });

    const districtOption = uniq(districtValues).map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const cropSort = sortBy(cropValues, 'key');
    const cropOption = cropSort.map((crop, index) => {
      return (
        <Option key={index} value={crop.value}>
          {crop.key}
        </Option>
      );
    });
    const locationSort = sortBy(locationValues, 'key');
    const newLocationSort = sortBy(newLocationValues, 'key');
    const regionSort = sortBy(regionData, 'value');
    const regionOption = regionSort.map((item, index) => {
      return (
        <Option key={item.key} value={item.value}>
          {item.value}
        </Option>
      );
    });
    const locationOption = locationSort.map((location, index) => {
      return (
        <Option key={index} value={location.value}>
          {location.key}
        </Option>
      );
    });
    const newLocationOption = newLocationSort.map((location, index) => {
      return (
        <Option key={index} value={location.value}>
          {location.key}
        </Option>
      );
    });
    const landOption = landValues.map((value, index) => {
      return (
        <Option key={index} value={value}>
          {value}
        </Option>
      );
    });
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };
    const loginPageImage = {
      synLogo: require('../assets/img/syn-logo.png')
    };
    return (
      <div
        style={{
          width: '100%',
          height: 'auto',
          padding: '24px',
          backgroundColor: '#2B9C92'
        }}
        className=''>
        {!this.state.done ? (
          <Form
            layout='vertical'
            onSubmit={this.handleSubmit}
            style={{
              height: 'auto',
              margin: '0 auto',
              padding: '20px',
              fontSize: '14px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
            className='addGrower'>
              <div style={{ display: 'flex' }}>
            <div class='avatarImage'>
              
              <img
                src={loginPageImage.synLogo}
                alt='Avatar'
                width='80vh'
                height='100px'
                style={{ height: '75px' }}
              />
            </div>
            <div
              className='Title'
              style={{
                fontWeight: '600',
                fontSize: '1.2em',
                color: '#14151C',
                paddingLeft: '30px',
                position: 'relative',
                left: '-30px',
                top: '4px'
              }}>
              Formulir Data Petani
            </div>
            </div>
            <Form.Item label={<p className='loginFormLabel'>Nama</p>}>
              {getFieldDecorator('name', {
                initialValue: this.state.formData.Name || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Mohon isi informasi yang benar.`, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder='Masukkan Nama' type='text' />)}
            </Form.Item>

            <Form.Item label={<p className='loginFormLabel'>Nomor Telepon</p>}>
              {getFieldDecorator('mobile', {
                initialValue: this.state.formData.MobileNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: 'Mohon isi informasinya'
                    // whitespace: true
                  },

                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                  // pattern='[0-9]'
                  placeholder='Nomor Telepon'
                  addonBefore='+62'
                  maxLength={14}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 32) &&
                    e.preventDefault()
                  }
                  // step='1'
                  // style={{ width: '100%' }}
                />
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Jenis Panen</p>} placeholder='select'>
              {getFieldDecorator('crop', {
                initialValue: this.state.formData.Crop || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Mohon isi informasinya' }]
              })(
                <Select
                placeholder='Pilih Jenis Panen'
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {cropOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Lokasi</p>}>
              {getFieldDecorator('location', {
                initialValue: this.state.formData.Province || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Mohon isi informasinya' }]
              })(
                <Select
                  placeholder='Pilih Lokasi'
                  showSearch
                  onSelect={(e) => this.getProvinceValue(e, regionData)}>
                  {regionOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Provinsi</p>}>
              {getFieldDecorator('province', {
                initialValue: this.state.formData.province || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Mohon isi informasinya' }]
              })(
                <Select placeholder='Pilih Lokasi' showSearch>
                  {newLocationOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Luas Lahan (hektar)</p>}>
              {getFieldDecorator('acre', {
                initialValue: this.state.formData.Acre || '0',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'Mohon isi informasinya' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(
                <Input
                  type='number'
                  min={0}
                  onKeyDown={(evt) =>
                    (evt.keyCode === 69 || evt.keyCode === 187 || evt.keyCode === 189) &&
                    evt.preventDefault()
                  }
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginTop: '10px !important' }}>
              {getFieldDecorator('checkProduct', {
                initialValue: this.state.formData.checkProduct || ''
              })(
                <Checkbox checked={this.state.checkProduct} onChange={this.onChangeProduct}>
                  <p className='loginFormLabel'>
                    Apakah saat ini Anda menggunakan produk Syngenta?
                  </p>
                </Checkbox>
              )}
            </Form.Item>

            <Form.Item style={{ marginTop: '10px !important' }}>
              <Checkbox onChange={this.onChange}>
                <p className='loginFormLabel'>
                  {' '}
                  Dengan mengklik, Anda setuju dengan{' '}
                  <a
                    style={{ color: '#1890ff' }}
                    href='https://www.syngenta.co.id/kebijakan-privasi-0'
                    target='_blank'>
                    Kebijakan Privasi
                  </a>{' '}
                  dan{' '}
                  <a
                    style={{ color: '#1890ff' }}
                    href='https://www.syngenta.co.id/syarat-dan-ketentuan-0'
                    target='_blank'>
                    Syarat & Ketentuan
                  </a>{' '}
                  Syngenta.
                </p>
              </Checkbox>
            </Form.Item>

            <Form.Item label='Source' hidden={true}>
              {getFieldDecorator('source', {
                initialValue: this.state.formData.source || 'form'
              })(<Input />)}
            </Form.Item>
            <Form.Item
              style={{
                marginTop: '10px !important'
              }}>
              <Button
                htmlType='submit'
                className='buttonStyle'
                onClick={this.handleSubmit}
                disabled={this.state.checked || this.state.disabled}>
                Kirimkan
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Result status='success' title='Data berhasil ditambahkan.' />
        )}
        <Modal
          title=''
          visible={this.state.visible}
          onOk={this.handleOk}
          closable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button type='primary' onClick={this.handleOk}>
              Setuju
            </Button>
          ]}>
          <p>
            Informasi pribadi menurut Undang-Undang Perlindungan Informasi Pribadi tahun 2019 <br />
            Untuk tujuan merencanakan kampanye pemasaran online dari Syngenta (kami) Sangat penting
            bagi kami untuk mendapatkan dan menyimpan informasi pribadi Anda untuk kami. Dan mereka
            yang ditugaskan oleh saya Dalam kerangka tujuan di atas Seperti Anda telah menekan kode
            atau memberikan informasi untuk mendaftarkan petani Dan telah memberikan persetujuan ini
            Jika Anda tidak lagi ingin informasi Anda digunakan untuk tujuan itu. Silakan hubungi
            staf penjualan kami atau tekan * 766 * 600 #
          </p>
        </Modal>

        {this.props.alertType === 'error'
          ? this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null
          : ''}
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchAddGrowers: (growers) => dispatch(fetchAddGrowers(growers))
  };
};
const mapStateToProps = function(state) {
  return {
    growerList: get(state, 'addedGrowerList.data.growerSucess', []),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(AddGrower);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);

import config from '../config';
import {
  FACEBOOK_LIST_PENDING,
  FACEBOOK_LIST_SUCCESS,
  FACEBOOK_LIST_FAILURE
} from '../constants/index';
import { facebookListWithFetchAPI } from './uploadFacebookList';
import { handleErrors } from '../services/handleErrors';
export const facebookListingPending = () => {
  return {
    type: FACEBOOK_LIST_PENDING
  };
};
export const facebookListingSuccess = (value) => {
  return {
    type: FACEBOOK_LIST_SUCCESS,
    payload: value
  };
};

export const facebookListingFailure = (error) => {
  return {
    type: FACEBOOK_LIST_FAILURE,
    payload: error
  };
};
export const facebookAuthAPI = () => {
  const authUri = config.facebookAuthAPI;
  return (dispatch) => {
    return fetch(authUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(facebookListingSuccess(result));
      })
      .catch((e) => {});
  };
};

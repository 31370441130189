import { UserAgentApplication } from 'msal';
import AdalConfigService from '../services/adalConfigService';
AdalConfigService.setAdalConfig();

export const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') > -1 ||
    errorMessage.indexOf('interaction_required') > -1 ||
    errorMessage.indexOf('login_required') > -1
  );
};

export const fetchMsGraph = async (url, accessToken) => {
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });

  return response.json();
};

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const AUTH_SCOPES = {
  OPENID: 'openid',
  OFFLINE_ACCESS: 'offline_access',
  PROFILE: 'profile'
};
export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  MAIL: 'https://graph.microsoft.com/v1.0/me/messages'
};
export const AUTH_REQUESTS = {
  LOGIN: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE]
  },
  EMAIL: {
    scopes: []
  },
  REFRESH_TOKEN: {
    scopes: [AdalConfigService.ClientId]
  }
};

export const msalApp = new UserAgentApplication(AdalConfigService.AdalConfig);
//     {
//   auth: {
//     clientId: process.env.CLIENT_ID,
//     authority: `https://login.microsoftonline.com/${process.env.TENANT_ID}`,
//     redirectUri: process.env.DOMAIN,
//     postLogoutRedirectUri: process.env.DOMAIN,
//     navigateToLoginRequestUrl: true,
//     scopes: ['User.Read']
//   },
//   cache: {
//     cacheLocation: 'localStorage',
//     storeAuthStateInCookie: false
//   }
// });

// import { formatUrl } from "../services/commonMethods";
// import config from "../config";

export const fetchUsersWithFetchAPIAsnc = val => {
  return { type: 'USERS_LIST', value: val };
};

// function fetchGrowerPending() {
//   return {
//     type: FETCH_GROWER_PENDING
//   };
// }
// function fetchGrowerSuccess(grower) {
//   return {
//     type: FETCH_GROWER_SUCCESS,
//     payload: grower
//   };
// }

// function fetchGrowerError(error) {
//   return {
//     type: FETCH_GROWER_ERROR,
//     payload: error
//   };
// }
// export const fetchGrower = pagenum => {
//   const api_url = formatUrl(config.growerList, pagenum, "", "-CREATED", "10");
//   return dispatch => {
//     dispatch(fetchGrowerPending());
//     fetch(api_url)
//       .then(response => response.json())
//       .then(grower => {
//         dispatch(fetchGrowerSuccess(grower));
//       })
//       .catch(error => {
//         dispatch(fetchGrowerError(error));
//       });
//   };
// };

import {
  REQUEST_WEBSTORE_MESSAGE_SEND,
  REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS,
  REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE,
  TOGGLE_DRAWER_STATUS
} from '../constants';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
import { fetchCostEstimatorClear } from '../actions/costEstimator';
import { formatUrl } from '../services/commonMethods';
import { handleErrors } from '../services/handleErrors';
import AdalConfigService from '../services/adalConfigService';
import config from '../config';

import { toggleSpinStatus } from '../actions/globalSpinner';

export function sendWebstoreMessagePending(MessageStatus) {
  return {
    type: REQUEST_WEBSTORE_MESSAGE_SEND,
    payload: MessageStatus
  };
}
export function sendWebstoreMessageSuccess(mdata) {
  return {
    type: REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS,
    payload: mdata
  };
}

export function sendWebstoreMessageFailure(merror) {
  return {
    type: REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE,
    payload: merror
  };
}

export function toggleWebstoreDrawer(widgetdata) {
  return {
    type: TOGGLE_DRAWER_STATUS,
    payload: widgetdata
  };
}
export function sendAgroServiceMessage(mdata, emailID, widgetId) {
  const apiUri = formatUrl(config.sendMessageFromWebstore, widgetId);
  return (dispatch) => {
    dispatch(toggleSpinStatus());
    dispatch(sendWebstoreMessagePending(true));
    dispatch(clearAlertState());
    fetch(apiUri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'temp-created-by': emailID,
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(mdata)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result && result.code === '200') {
          window.amplitude.getInstance().logEvent(`${AdalConfigService.countryName}_Service_Sent`);
          dispatch(sendWebstoreMessagePending(false));
          dispatch(sendWebstoreMessageSuccess(result));
          dispatch(toggleWebstoreDrawer(''));
          dispatch(toggleSpinStatus());
          dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: result.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
        } else {
          dispatch(sendWebstoreMessagePending(false));
          dispatch(sendWebstoreMessageSuccess(result));
          dispatch(toggleWebstoreDrawer(''));
          dispatch(toggleSpinStatus());
          dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'error',
              message: 'Message sending failed',
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
          dispatch(sendWebstoreMessageFailure(result));
        }
      })
      .catch((e) => {
        dispatch(sendWebstoreMessagePending(true));
        dispatch(clearAlertState());
        dispatch(sendWebstoreMessageFailure(e));
        // dispatch(toggleWebstoreDrawer(""));
        dispatch(toggleSpinStatus());
        dispatch(
          errorNotification({
            type: 'error',
            message: 'Message Sending Failure',
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
}

import {
  REQUEST_MESSAGE_SEND,
  REQUEST_MESSAGE_SEND_SUCCESS,
  REQUEST_MESSAGE_SEND_FAILURE,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_MESSAGE_TABEL_ROW
} from '../constants';

export function sendMessagePending(MessageStatus) {
  return {
    type: REQUEST_MESSAGE_SEND,
    payload: MessageStatus
  };
}
export function sendMessageSuccess(mdata) {
  return {
    type: REQUEST_MESSAGE_SEND_SUCCESS,
    payload: mdata
  };
}

export function sendMessageFailure(merror) {
  return {
    type: REQUEST_MESSAGE_SEND_FAILURE,
    payload: merror
  };
}

export function toggleDrawer() {
  return {
    type: TOGGLE_DRAWER_STATUS
  };
}
export function highlightMessageTableRow(rowStatus) {
  return {
    type: HIGHLIGHT_MESSAGE_TABEL_ROW,
    payload: rowStatus
  };
}

import React from 'react';
import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import Unauthorization from '../views/commonComponents/errorboundry/unauthorizationHandle';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import SocialMedia from '../views/SocialMedia/SocialMedia';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import Utility from '../views/utility/utility';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'dark',
    loginPageImage: 'pklogin',
    loginButtonStyle: 'pkLoginbtn',
    loginButtonBackground: 'pkLoginButtonBackground',
    drawerWidth: '200',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },

      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },

      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/login',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    uploadSheetButton: [
      {
        title: `+${t('UPLOAD A FILE (BULK UPLOAD)')}`,
        key: 'bulkUpload'
      }
    ],
    sampleTemplate:
      'https://grower-console-prod-assets.s3-ap-southeast-1.amazonaws.com/sample-files/Sample-pk.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },

      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },

      {
        key: 'help',
        label: t('Help')
      }
    ],
    amplitude: 'IN',
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },
        { key: 'Crop', value: t('Crop') },
        { key: 'mobileNumber', value: t('Mobile Number') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        mobileNumber: [{ key: 'contains', value: t('contains') }, { key: 'equals to', value: t('equals to') }]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        fieldName: 'GrowersRegion',
        initialValue:'GrowersRegion',
        placeholder: t('Select Some Regions'),
        handler: 'handleRegionSelectAll',
        rules: []
      },
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue:'GrowersCrop',
        message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some crops')
          }
        ]
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter name'),
            whitespace: true
          }
        },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (Acres)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Taluka'),
          fieldName: 'Taluka',
          initialValue: 'taluka',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter taluka'),
            whitespace: true
          }
        },
        {
          label: t('Zone'),
          fieldName: 'Zone',
          initialValue: 'zone',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter zone'),
            whitespace: true
          }
        },
        {
          label: t('Area'),
          fieldName: 'area',
          initialValue: 'area',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter area'),
            whitespace: true
          }
        },
        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter district'),
            whitespace: true
          }
        },
        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Sales Unit'),
          fieldName: 'Unit',
          initialValue: 'unit',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter sales unit'),
            whitespace: true
          }
        },
        {
          label: t('Franchisee'),
          fieldName: 'franchisee',
          initialValue: 'franchisee',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter franchisee'),
            whitespace: true
          }
        },
        {
          label: t('Franchisee Code'),
          fieldName: 'frnCode',
          initialValue: 'frnCode',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter franchisee code'),
            whitespace: true
          }
        }
      ]
    },
    tagVisible: true,
    cropHistoryVisible: true,
    hideNewTag:true,
    campignTable: [
      {
        title: t('MODE'),
        dataIndex: 'mode'
      },
      {
        title: t('CAMPAIGN NAME'),
        dataIndex: 'name'
      },
      {
        title: t('REACH'),
        dataIndex: 'reach'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (acres)'),
        dataIndex: 'landSize'
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName'
      },
      // {
      //   title: `#${t('MEMBERS')}`,
      //   dataIndex: 'noOfMembers'
      // },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters'
      }
    ],
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (acres)'),
        dataIndex: 'landsize'
      }
    ],
    interactTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) =>{
          return record.deletedGroup ? <div style={{color:'#D3D3D3'}}>{text}</div>  : <div>{text}</div>
          }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('SENDER'),
        dataIndex: 'Sender'
      },
      {
        title: t('SENT ON'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    scheduleMessageTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) =>{
          return record.deletedGroup ? <div style={{color:'#D3D3D3'}}>{text}</div>  : <div>{text}</div>
          }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    searchZoneMessage: {
      message: t('Please select some zone'),
      placeholder: t('Select Some Zone')
    },
    replaceTitle: ['0-20 acres', '21-50 acres', '51-100 acres', '> 100 acres'],
    paiChart: 'paiChartCards'
  };
};

import React, { Component } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

class ChatInput extends Component {
  static propTypes = {
    onSubmitMessage: PropTypes.func.isRequired
  };
  state = {
    message: ''
  };

  render() {
    return (
      <Form
        style={{ display: 'flex' }}
        action='.'
        onSubmit={(e) => {
          e.preventDefault();
          this.props.onSubmitMessage(this.state.message);
          this.setState({ message: '' });
        }}>
        <Input
          type='text'
          placeholder={'Enter message...'}
          value={this.state.message}
          onChange={(e) => this.setState({ message: e.target.value })}
        />
        <Input type='submit' value={'Send'} />
      </Form>
    );
  }
}

export default ChatInput;

const initialState = {
  user: {}
};

function authentication(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return {
        user: action.payload
      };
    default:
      return state;
  }
}

export default authentication;

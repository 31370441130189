import {
  FETCH_LIMIT_PENDING,
  FETCH_LIMIT_SUCCESS,
  FETCH_LIMIT_ERROR,
  FETCH_LIMIT_CLEAR,
  FETCH_LIMIT_LOADER
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus, estamatingCostStatus } from './globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
export function fetchLimitPending() {
  return {
    type: FETCH_LIMIT_PENDING
  };
}
export function fetchLimitSuccess(result) {
  return {
    type: FETCH_LIMIT_SUCCESS,
    payload: result
  };
}

export function fetchLimitError(error) {
  return {
    type: FETCH_LIMIT_ERROR,
    payload: error
  };
}
export function fetchLimitLoader() {
  return {
    type: FETCH_LIMIT_LOADER
  };
}
export function fetchLimitClear() {
  return {
    type: FETCH_LIMIT_CLEAR
  };
}

export const fetchWhatsappLimit = (groupData) => {
  const apiUri = formatUrl(config.whatsAppLimit);

  return (dispatch) => {
    dispatch(fetchLimitPending());
    // dispatch(toggleSpinStatus());
    dispatch(fetchLimitLoader());
    fetch(apiUri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      },
      body: JSON.stringify(groupData)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        if (result.error_msg) {
          dispatch(fetchLimitError(result));
        } else {
          dispatch(fetchLimitSuccess(result));
        }

        // dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(fetchLimitError(error));
      });
  };
};

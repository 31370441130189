import {
  SEND_MMS_PENDING,
  SEND_MMS_SUCCESS,
  SEND_MMS_ERROR,
  CLEAR_SEND_MMS_RESULT,
  TOGGLE_DRAWER_STATUS,
  HIGHLIGHT_MESSAGE_TABEL_ROW
} from '../constants/index';

const initialState = {
  data: {
    mmsData: {},
    mmsDrawerVisible: false,
    mmsStatus: '',
    messageStatus: '',
    rowHighlight: false,
    completed: false,
    loading: true
  }
};

function sendMMS(state = initialState, action) {
  switch (action.type) {
    case SEND_MMS_PENDING:
      return {
        data: {
          ...state.data,
          completed: true,
          mmsStatus: action.payload
        }
      };
    case SEND_MMS_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          mmsData: action.payload,
          messageStatus: 'sentMms',
          completed: false
        }
      });
    case SEND_MMS_ERROR:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          mmsData: action.payload,
          messageStatus: 'sentMms',
          completed: false
        }
      });
    case CLEAR_SEND_MMS_RESULT:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          messageStatus: '',
          completed: false
        }
      });
    case TOGGLE_DRAWER_STATUS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          mmsDrawerVisible: !state.mmsDrawerVisible
        }
      });
    case HIGHLIGHT_MESSAGE_TABEL_ROW:
      return {
        ...state,
        rowHighlight: action.payload
      };
    default:
      return state;
  }
}

export default sendMMS;

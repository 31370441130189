import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'latamLogin',
    loginButtonStyle: 'latamLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    drawerWidth: '250',
    sampleTemplate:
      'https://grower-console-prod-assets.s3-ap-southeast-1.amazonaws.com/sample-files/Sample-latam.xlsx',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },
      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/login',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },
      {
        key: 'help',
        label: t('Help')
      }
    ],

    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },
        { key: 'Zone', value: t('Zone') },
        { key: 'Crop', value: t('Crop') },
        { key: 'mobileNumber', value: t('Mobile Number') }
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Zone: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        mobileNumber: [ { key: 'contains', value: t('contains') }, { key: 'equals to', value: t('equals to') }]
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },
    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: [
          {
            required: false,
            message: t('Please select some crops')
          }
        ]
      },
      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        initialValue: 'GrowersRegion',
        placeholder: t('Select Some Regions'),
        handler: 'handleRegionSelectAll',
        rules: []
      }
    ],
    replaceTitle: ['0-20 hectares', '21-50 hectares', '51-100 hectares', '> 100 hectares']
  };
};

import config from '../config';
import axios from 'axios';
import {
  UPLOAD_YOUTUBE_PENDING,
  UPLOAD_YOUTUBE_SUCCESS,
  UPLOAD_YOUTUBE_ERROR
} from '../constants/index';
import { toggleSpinStatus } from './globalSpinner';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
import { youTubeAuthAPI } from './youTubeList';
export const upadteLoadingStatus = (val) => {
  return { type: 'GROWER_LOADING', payload: val };
};

export const uploadYouTubePending = () => {
  return {
    type: UPLOAD_YOUTUBE_PENDING
  };
};
export const uploadYouTubeSuccess = (value) => {
  return {
    type: UPLOAD_YOUTUBE_SUCCESS,
    payload: value
  };
};

export const uploadYouTubeError = (error) => {
  return {
    type: UPLOAD_YOUTUBE_ERROR,
    payload: error
  };
};
export const youTubeListWithFetchAPI = (nextPageToken) => {
  let videoIdArray = [];

  const channelUri = 'https://www.googleapis.com/youtube/v3/playlistItems';
  return (dispatch) => {
    dispatch(uploadYouTubePending());
    dispatch(toggleSpinStatus());
    axios
      .get(channelUri, {
        params: {
          part: 'contentDetails',
          playlistId: config.playListId,
          pageToken: nextPageToken,
          maxResults: 3
        },
        headers: {
          'content-type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('youTubeAuthToken')
        }
      })
      .then((response) => {
        let resultsPerPage1 = response.data.pageInfo.resultsPerPage;
        let totalResults1 = response.data.pageInfo.totalResults;
        let nextPageToken = response.data.nextPageToken;
        let prevPageToken = response.data.prevPageToken;

        if (response || response.status === 200)
          for (var i = 0; i < response.data.items.length; i++) {
            videoIdArray.push(response.data.items[i].contentDetails.videoId);
          }

        const apiUri =
          'https://www.googleapis.com/youtube/v3/videos?part=statistics,snippet&id=' +
          videoIdArray.toString();
        // return (dispatch) => {
        return fetch(apiUri, {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('youTubeAuthToken')
          }
        })
          .then((response) => response.json())
          .then((result) => {
            result.pageInfo.resultsPerPage = resultsPerPage1;
            result.pageInfo.totalResults = totalResults1;
            result.pageInfo.nextPageToken = nextPageToken;
            result.pageInfo.prevPageToken = prevPageToken;
            dispatch(uploadYouTubeSuccess(result));
            dispatch(toggleSpinStatus());
            setTimeout(
              function() {
                dispatch(upadteLoadingStatus(false));
              }.bind(),
              3000
            );
          })
          .catch((error) => {
            const parseData = JSON.stringify(error);
            const errorResponse = JSON.parse(parseData);

            if (errorResponse.message.indexOf('403') > -1) {
              const response = {
                status: 403
              };
              return handleErrors(response, dispatch);
            }
            dispatch(
              errorNotification({
                type: 'error',
                message: error.message,
                toggleAlert: true
              })
            );
            dispatch(uploadYouTubeError(error));
            dispatch(clearAlertState());
          });
        // };
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);

        if (errorResponse.message.indexOf('401') > -1) {
          dispatch(youTubeAuthAPI());
        }
        // dispatch(
        //   errorNotification({
        //     type: 'error',
        //     message: error.message,
        //     toggleAlert: true
        //   })
        // );
        dispatch(uploadYouTubeError(error));
        // dispatch(clearAlertState());
      });
  };
};

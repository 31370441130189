import { FACEBOOK_LIST_PENDING, FACEBOOK_LIST_SUCCESS, FACEBOOK_LIST_FAILURE } from '../constants';

const initialState = {
  data: {
    facebookList: {},
    facebookListError: {},
    completed: false
  }
};

function facebookList(state = initialState, action) {
  switch (action.type) {
    case FACEBOOK_LIST_PENDING:
      return {
        data: {
          ...state.data,
          completed: true
        }
      };
    case FACEBOOK_LIST_SUCCESS:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          facebookList: action.payload,
          completed: false
        }
      });
    case FACEBOOK_LIST_FAILURE:
      return Object.assign({}, state, {
        data: {
          ...state.data,
          facebookListError: action.payload,
          completed: false
        }
      });
    default:
      return state;
  }
}

export default facebookList;

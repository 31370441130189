import config from '../config';
import { formatUrl } from '../services/commonMethods';
import {
  uploadGrowerPending,
  uploadGrowerSuccess,
  uploadGrowerError,
  upadteLoadingStatus
} from '../actions/uploadGrowersList';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { handleErrors } from '../services/handleErrors';
export const uploadGrowerWithFetchAPI = (pagenum) => {
  const apiUri = formatUrl(config.uploadedGrowerList, pagenum, '10', '-created');
  return (dispatch) => {
    dispatch(uploadGrowerPending());
    dispatch(upadteLoadingStatus(true));
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer ' + config.userToken
      }
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        dispatch(uploadGrowerSuccess(result));
        // let sucessCount = result.items.map((count) => {
        //   return {
        //     sucess: count.successCount
        //   };
        // });

        // let eventProperties = {
        //   sucessCount
        // };
        // let eventProperties1 = {
        //   Failure_Count: result.items.failureCount
        // };
        // window.amplitude.getInstance().logEvent('Sucess_Per_Region', eventProperties);
        // window.amplitude.getInstance().logEvent('Failure_Per_Region', eventProperties1);
        dispatch(toggleSpinStatus());
        setTimeout(
          function () {
            dispatch(upadteLoadingStatus(false));
          }.bind(),
          3000
        );
      })
      .catch((e) => {
        dispatch(toggleSpinStatus());
        dispatch(uploadGrowerError(e));
      });
  };
};

import React from 'react';
import { Icon } from 'antd';

export const textualSVG = () => (
  <svg width='40' height='40' viewBox='0 0 81 46'>
    <g fill='#171B27' fillRule='nonzero'>
      <path d='M.578 39.03L16.246 4.129c1.092-2.407 3.082-3.864 5.78-3.864h.578c2.696 0 4.624 1.457 5.715 3.864l15.669 34.903c.321.697.514 1.33.514 1.963a4.655 4.655 0 0 1-4.688 4.688c-2.312 0-3.853-1.33-4.752-3.358l-3.018-6.967H12.266l-3.147 7.285c-.835 1.9-2.504 3.04-4.56 3.04C1.99 45.682 0 43.655 0 41.12c0-.697.257-1.393.578-2.09zm27.806-12.288l-6.23-14.633-6.228 14.633h12.458zM49.187 35.547v-.127c0-7.411 5.716-10.832 13.87-10.832 3.469 0 5.974.57 8.414 1.394v-.57c0-3.991-2.505-6.208-7.385-6.208-2.697 0-4.88.38-6.743.95-.578.19-.963.253-1.413.253-2.247 0-4.045-1.71-4.045-3.927 0-1.71 1.091-3.167 2.632-3.737 3.082-1.14 6.422-1.774 10.981-1.774 5.33 0 9.183 1.394 11.624 3.8 2.568 2.534 3.724 6.272 3.724 10.833v15.455c0 2.598-2.12 4.625-4.752 4.625-2.826 0-4.688-1.964-4.688-3.991v-.064c-2.376 2.597-5.651 4.308-10.403 4.308-6.486 0-11.816-3.673-11.816-10.388zM71.6 33.329v-1.71c-1.67-.76-3.853-1.267-6.23-1.267-4.174 0-6.742 1.647-6.742 4.688v.126c0 2.598 2.184 4.118 5.33 4.118 4.56 0 7.642-2.47 7.642-5.955z' />
    </g>
  </svg>
);

export function textualSVGIcon(props) {
  return <Icon component={textualSVG} {...props} />;
}

export const imageSVG = () => (
  <svg width='40' height='40' viewBox='0 0 50 50'>
    <path
      fill='#171B27'
      fillRule='nonzero'
      d='M50 48.67V1.33C50 .596 49.404 0 48.67 0H1.33C.596 0 0 .596 0 1.33v47.34C0 49.404.596 50 1.33 50h47.34c.734 0 1.33-.596 1.33-1.33zM36.394 9.822a5.08 5.08 0 1 1 0 10.161 5.08 5.08 0 0 1 0-10.161zM4.34 45.783c.261-5.745 3.263-16.907 7.637-21.019 3.916-3.655 7.246-.457 9.857 3.264 2.415 3.458 4.517 6.519 6.287 8.442 2.916 2.915 5.406-1.504 8.463-2.96 4.456-2.12 8.127 8.911 9.074 12.286L4.34 45.782z'
    />
  </svg>
);

export function imageSVGIcon(props) {
  return <Icon component={imageSVG} {...props} />;
}

export const messageFirstBgSVG = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='367' height='210' viewBox='0 0 367 210'>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#FAF9F7'
        d='M170.577 208.23c-13.33.043-26.66.027-39.99-.097-28.555-.264-57.114-.999-85.623-2.685-12.343-.73-56.245-.079-35.46-26.256 7.024-8.839 22.015-12.307 31.86-12.47 10.788-.18 21.268.788 32.072 1.358 10.04.529 20.054 1.282 30.11 1.255 20.537-.054 41.077 0 61.578 1.659 19.261 1.558 38.57 1.023 57.874 1.023 19.795 0 39.455-.188 59.107-3.369 8.562-1.385 17.285-1.87 25.89-2.842 7.708-.87 15.114.018 22.79.821a47.219 47.219 0 0 1 18.705 6.06c4.636 2.678 12.9 11 14.32 17.392 3.986 17.923-56.16 17.492-65.397 17.452-42.613-.186-85.224.561-127.836.699'
      />
      <path
        fill='#F0CDBA'
        d='M258.294 85.714l-1.295-2.675 1.054-2.393-.44-.463-.643.474.255-2.216-1.093-1.506-2.624-.08-2.195 1.46.189 2.397 1.75 2.365 1.6 3.732 3.442-1.095'
      />
      <path
        fill='#238661'
        d='M257.094 82.827l9.152 15.988 16.978 2.98-2.023 9.956-19.068 1.934-10.82-30.085 5.781-.773'
      />
      <path
        fill='#5C6575'
        d='M266.16 185.357l-4.875 3.596v2.472l4.266.9 6.704-3.597-.61-3.371h-5.485'
      />
      <path
        fill='#F0CDBA'
        d='M280.624 101.134l6.869.606c.422-1.303.7-4.475.832-5.81 2.014-.175 4.39-.541 5.152-1.91.24-.43.274-.905.304-1.37l.383-5.76c.044-.663.084-1.347-.226-1.973-.544-1.097-2.041-1.781-3.523-2.226-3.78-1.136-8.255-1.211-11.879.169-.964.367-1.881.848-2.462 1.517-.896 1.033-.863 2.339-.8 3.56l.174 3.38c.066 1.295.277 2.79 1.757 3.512.786.384 1.823.478 2.492.964 1.179.86.538 4.16.927 5.341'
      />
      <path
        fill='#2F3B4B'
        d='M271.114 75.201c-.539-1.084-1.278-2.075-1.649-3.227-.37-1.152-.274-2.58.652-3.358.597-.502 1.415-.637 2.192-.689 2.561-.172 5.166.36 7.457 1.523 2.181 1.108 1.654.688 4.176.336 2.37-.33 8.085.532 10.19 1.862 2.276 1.438 3.722 4.077 5.684 5.828.819.731 1.384 1.714 1.765 2.745 1.321 3.572.375 7.879-2.32 10.564-.605.602-1.285 1.124-1.985 1.612-1.45 1.008-3.195 2.264-4.905 2.375.282-1.45 2.601-5.7 1.76-7.126-1.334-2.266-5.437-2.498-7.712-2.522-2.48-.026-4.966 1.184-7.394 1.018-1.748-.12-3.72-1.433-4.962-2.63-1.533-1.479-1.657-4.818-2.315-6.758a12.423 12.423 0 0 0-.634-1.553z'
      />
      <path
        fill='#5C6575'
        d='M313.206 183.882l-2.06 3.613.258 2.322 3.088.517 4.633-2.84-1.544-9.806-4.375-1.29v7.484'
      />
      <path
        fill='#2F3B4B'
        d='M310.878 152.799s4.765 8.798-1.756 14.329c-6.52 5.53-17.806 14.329-17.806 14.329l21.318-4.776 1.505 7.541s-32.354 10.81-33.106 10.056c-.753-.755-8.778-7.793-8.778-7.793l17.305-37.96 19.06 2.263 2.258 2.01'
      />
      <path
        fill='#238661'
        d='M259.773 146.81v-.004c1.579-1.679 3.044-3.452 4.318-5.347a29.672 29.672 0 0 0 4.93-13.594c.254-2.441.25-4.895.136-7.334-.062-1.321-.159-2.64-.271-3.957-.064-.748-.792-3.448-.387-4.036 4.102-5.96 4.597-10.359 11.311-11.079 15.32-1.643 14.452-.717 19.66 5.575 2.095 2.53 5.963 9.789 7.3 12.752 1.62 3.587 3.196 7.195 4.65 10.85 1.623 4.078 3.097 8.217 4.307 12.433.134.468.564 1.766 1.023 3.335.868 2.967 1.842 6.9 1.133 8.039-.368.948-1.34 1.96-3.694 2.427-5.063 1.006-9.113 1.006-14.681-1.76-5.57-2.768-14.795-.804-14.795-.804-1.467.4-3 .793-4.632 1.154-11.87 2.631-18.004-4.592-19.93-5.904-1.682-1.147-.378-2.747-.378-2.747'
      />
      <path
        fill='#2F3B4B'
        d='M286.026 154.069l-27.566-12.511-7.147 9.007 12.252 36.782 8.167-1-2.297-22.02s6.892 5.755 19.143 5.255c5.616-5.505 5.616-5.254 5.616-5.254l-8.168-10.26'
      />
      <path fill='#1B4987' d='M132.643 2.197l142.604 46.935-86.01 20.754-56.594-67.69' />
      <path
        fill='#1B4987'
        d='M279.235 53.559l-30.468 91.98-134.053-44.3c-10.139-3.35-15.452-14.84-11.866-25.665l23.977-72.382L187.31 75.83l91.925-22.271'
      />
      <path
        fill='#D0CEC9'
        d='M151.417 24.096l-18.774-21.9 18.774 21.9m7.154-13.577l-25.928-8.322 25.928 8.322M118.682 28.078l7.977-24.886-7.978 24.886'
      />
      <path
        fill='#EECAB6'
        d='M110.704 39.137l1.323-1.664 1.995-3.5 2.74-.918 2.917 1.645s-.684 1.182-1.713 1.05l-1.543-.198-1.084 3.268s-.02.07-.072.181l-.423 1.225s-1.484.996-1.731.755a4.1 4.1 0 0 0-.385-.295l-.428.18-1.596-1.729'
      />
      <path
        fill='#2F3B4B'
        d='M42.58 179.277l-.685 2.878 5.298 4.197 3.677-2.22-2.308-3.538-5.298-4.196-.685 2.879'
      />
      <path
        fill='#238661'
        d='M53.912 89.795l-2.017 8.759 3.014 40.626-12.017 38.747 5.495 2.452 24.366-44.823 2.815-14.276 1.23-30.14-22.886-1.345'
      />
      <path
        fill='#238661'
        d='M94.748 89.739L92.66 127.38l-10.395 48.022-5.488-.363-.461-44.976-4.504-45.245 22.936 4.921'
      />
      <path
        fill='#2F3B4B'
        d='M76.798 177.206l5.783 3.173 3.192-2.612-2.792-2.987-5.984-.373-.2 2.8'
      />
      <path
        fill='#B7B9B7'
        d='M67.01 35.138c-3.55.908-6.69 2.84-8.813 6.806-1.85 3.454-2.533 7.428-3.187 11.317-1.87 11.137-2.67 23.372-6.864 33.955-1.823 4.6-3.524 7.318-3.225 12.614.081 1.446 3.466 14.897 3.44 14.913 4.673-2.994 10.35-4.398 15.885-3.93 4.963.421 37.685 10.512 41.39 5.424.807-1.109-4.602-16.847-5.09-19.155-1.598-7.577-3.197-15.155-4.82-22.728-1.793-8.406-3.606-16.848-6.655-24.886a85.187 85.187 0 0 0-3.506-7.986c-.824-1.643-2.46-6.353-4.173-7.154-1.415-.66-4.72.066-6.331.06-2.7-.01-5.422.076-8.051.75'
      />
      <path
        fill='#EECAB6'
        d='M66.6 18.922c3.32-7.14 14.365-8.426 15.835.519.44 2.687.565 5.497-.26 8.09-.826 2.594-2.735 4.948-5.315 5.739l-.044.012.042 2.102c.003.151 0 .317-.097.43-.11.13-.301.146-.47.15l-2.487.072c-.41.012-.838.02-1.212-.152-.817-.379-1.049-1.452-.98-2.358.018-.217.045-.434.075-.652-4.62-2.253-7.258-9.285-5.088-13.952'
      />
      <path
        fill='#2F3B4B'
        d='M77.988 6.22c1.982-.36 3.708 1.71 5.558 2.78 1.54.89 3.274 1.1 4.866 1.831 1.594.732 3.173 2.292 3.238 4.282 1.587-.942 3.307-1.91 5.108-1.566 1.801.343 3.505 2.651 2.822 4.594-.613 1.74-2.67 2.186-3.329 3.9-1.042 2.711 1.962 6.832-.013 8.655-.475.438-1.129.554-1.595 1.005-.579.56-.752 1.505-1 2.348-1.125 3.818-4.588 6.365-8.057 5.925-2.17-1.06-3.922-3.3-4.733-5.89-.977-3.12.543-4.246.754-7.197.231-3.225-.761-6.617-2.658-9.081-1.234-1.604-2.985-2.973-4.814-3.128-.744-.063-.5.609-1.345-.003-.363-.262-.869-1.271-.978-1.757 1.97-1.794 3.57-6.222 6.176-6.698'
      />
      <path
        fill='#B7B9B7'
        d='M77.309 34.05l18.715 17.8.136.064 15.115-14.652 3.418 3.33L98.997 64.41l-.254.5-19.525-8.87-8.404-17.987 6.495-4.001'
      />
      <path
        fill='#EECAB6'
        d='M106.81 15.086l1.9-4.997-2.054-.924-2.041 3.127-.773-.897-1.116 4.479 2.006 1.254 2.077-2.042'
      />
      <path
        fill='#B7B9B7'
        d='M66.894 35.638c2.51-1.119 5.867-.859 9.27-.963l11.997-.369 14.493-20.164 4.06 2.787L94.86 44.543 79.149 48.6c-2.888.746-6.238 2.19-9.221 2.365-4.39.258-7.643-5.018-7.013-9.187.527-3.496 1.987-5.252 3.979-6.14'
      />
      <path
        fill='#2F3B4B'
        d='M57.778 11.536c-1.207.345-2.525.32-3.671.827-1.723.76-1.586 2.49-2.05 4.001-.513 1.674-1.263 3.922-2.381 5.237-.807.946-1.95 1.498-2.94 2.235-.99.737-1.893 1.816-1.848 3.113.043 1.23.924 2.318 1.072 3.544.187 1.828-.125 2.772.497 4.633.321.96.68 1.977 1.446 2.622 1.01.851 2.425.81 3.665.59 1.24-.221 2.52-.575 3.753-.199 2.674.814 2.226 3.406 2.974 5.56 1.137 3.277 5.15 5.133 8.172 3.909 2.561-1.037 4.001-3.941 3.832-6.78-.084-1.421-.698-2.737-.767-4.16-.066-1.372.704-3.287-.611-4.355-.32-.26-.728-.393-1.016-.69-.235-.24-.368-.567-.453-.893-.297-1.13-.06-2.372.62-3.253.49-.633 1.198-1.1 1.491-1.86.335-.869.039-1.874-.038-2.826-.186-2.304 1.085-4.588 3.068-5.512 1.307-.609 2.488-.975 3.623-1.888.173-.138 2.752-2.407 2.829-1.928-.134-.834-.655-1.558-1.183-2.22-.867-1.09-1.827-2.149-3.032-2.79-3.896-2.072-3.991 2.9-7.099 2.298-.75-.144-1.324-.793-2.003-1.174-1.284-.72-2.839-.428-4.127.15-1.288.577-2.463 1.42-3.823 1.81l38.964-20.29'
      />
      <path
        fill='#F0CDBA'
        d='M255.64 132.063l-2.794.82-2.23-1.28-.509.339.388.648-2.152-.528-1.617.793-.4 2.378 1.173 2.193 2.385.15 2.543-1.277 3.872-.955-.66-3.28'
      />
      <path
        fill='#238661'
        d='M252.32 131.683l17.564-8.31 2.051-13.705 13.284-4.942-2.658 23.7-30.25 9.15.01-5.893'
      />
    </g>
  </svg>
);

export function messageFirstBgSVGImage(props) {
  return <Icon component={messageFirstBgSVG} {...props} />;
}

export const fbMessangerSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='96 93 322 324'>
      <path
        d='M257 93c-88.918 0-161 67.157-161 150 0 47.205 23.412 89.311 60 116.807V417l54.819-30.273C225.449 390.801 240.948 393 257 393c88.918 0 161-67.157 161-150S345.918 93 257 93zm16 202l-41-44-80 44 88-94 42 44 79-44-88 94z'
        fill='#0084ff'
      />
    </svg>
  );
};
export const facebookSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <circle cx='29.787' cy='29.787' r='29.787' fill='#3B5998' />
        <path
          fill='#FFF'
          d='M37.275 30.953H31.96v19.471h-8.053V30.953h-3.83v-6.844h3.83v-4.428c0-3.167 1.504-8.125 8.125-8.125l5.964.025v6.642h-4.328c-.71 0-1.708.355-1.708 1.865v4.028h6.018l-.703 6.837z'
        />
      </g>
    </svg>
  );
};

export const whatsappSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <path
          fill='#4CAF50'
          d='M30.008 0h-.015C13.45 0 0 13.455 0 30a29.778 29.778 0 0 0 5.711 17.584L1.972 58.729l11.532-3.687A29.74 29.74 0 0 0 30.008 60C46.547 60 60 46.541 60 30S46.549 0 30.008 0z'
        />
        <path
          fill='#FAFAFA'
          d='M47.464 42.364c-.724 2.044-3.596 3.739-5.888 4.233-1.567.334-3.615.6-10.507-2.257-8.816-3.653-14.494-12.611-14.936-13.193-.424-.58-3.563-4.743-3.563-9.048s2.186-6.402 3.067-7.302c.724-.738 1.92-1.076 3.068-1.076.371 0 .705.019 1.005.034.881.037 1.324.09 1.905 1.481.724 1.744 2.486 6.049 2.696 6.491.214.443.428 1.043.128 1.624-.282.6-.529.867-.971 1.377-.443.51-.863.9-1.305 1.447-.405.476-.863.986-.353 1.867.51.863 2.273 3.74 4.867 6.05 3.35 2.98 6.064 3.933 7.035 4.338.724.3 1.587.229 2.115-.334.672-.723 1.5-1.923 2.344-3.105.6-.847 1.358-.952 2.153-.652.81.281 5.096 2.4 5.977 2.839.881.442 1.463.652 1.676 1.023.21.371.21 2.115-.513 4.163z'
        />
      </g>
    </svg>
  );
};

export const smsappSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <circle cx='30' cy='30' r='30' fill='#324A5E' />
        <g fill='#FFD05B'>
          <path d='M48.905 42.601v-20.54h-36.81V42.6c0 2.428 1.926 4.387 4.314 4.387h28.182c2.388 0 4.314-1.96 4.314-4.387z' />
          <path d='M48.905 22.06v20.541c0 1.25-.52 2.38-1.335 3.173a4.244 4.244 0 0 1-2.98 1.214H16.41a4.223 4.223 0 0 1-2.98-1.214 4.399 4.399 0 0 1-1.335-3.173v-20.54h36.81z' />
        </g>
        <g fill='#FF7058'>
          <path d='M28.03 10.877l-15.935 11.15L30.5 34.904l18.394-12.879-15.923-11.15a4.294 4.294 0 0 0-4.942 0z' />
          <path d='M48.894 22.026l-4.74 3.318L30.5 34.905l-13.677-9.561-4.728-3.318 4.728-3.317 6.667-4.669 4.54-3.175a4.294 4.294 0 0 1 4.94 0l4.54 3.175 6.667 4.669 4.717 3.317z' />
        </g>
        <path
          fill='#FFF'
          d='M43 15.873v9.498L29.506 35 16 25.371v-9.486c0-1.038.829-1.885 1.844-1.885h23.312C42.17 14 43 14.835 43 15.873z'
        />
        <path
          fill='#F9B54C'
          d='M47 45.807A4.256 4.256 0 0 1 44.033 47H15.967A4.235 4.235 0 0 1 13 45.807l14.127-12.709a4.29 4.29 0 0 1 5.734 0L47 45.808z'
        />
      </g>
    </svg>
  );
};
export const lineappSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <circle cx='30' cy='30' r='30' fill='#6DC02D' />
        <path
          fill='#FFF'
          d='M46.854 29.162c.078-.409.116-.753.134-1.023.03-.445-.004-1.107-.016-1.314C46.56 19.687 39.117 14 29.995 14 20.61 14 13 20.022 13 27.45c0 6.828 6.428 12.467 14.756 13.334a.92.92 0 0 1 .82 1.017l-.354 3.175c-.08.721.668 1.246 1.325.93 7.036-3.378 11.243-6.854 13.756-9.865.457-.549 1.948-2.627 2.252-3.172a11.285 11.285 0 0 0 1.299-3.707z'
        />
        <g fill='#6FC32E'>
          <path d='M20.827 30.09v-6.05c0-.51-.398-.924-.889-.924-.49 0-.888.414-.888.924v6.975c0 .51.397.923.888.923h3.554c.49 0 .888-.413.888-.923s-.398-.924-.888-.924h-2.665zM26.307 31.938h-.389a.709.709 0 0 1-.694-.722v-7.378c0-.4.31-.722.694-.722h.389c.383 0 .694.323.694.722v7.378c0 .4-.311.722-.694.722zM33.486 24.04v4.26s-3.548-4.812-3.602-4.875a.869.869 0 0 0-.697-.308c-.483.019-.854.458-.854.96v6.938c0 .51.398.923.889.923.49 0 .888-.413.888-.923V26.78s3.602 4.854 3.655 4.906c.157.155.368.25.601.252.493.005.897-.449.897-.962V24.04c0-.51-.398-.924-.889-.924-.49 0-.888.414-.888.924zM41.925 24.04c0-.51-.398-.924-.888-.924h-3.554c-.49 0-.888.414-.888.924v6.975c0 .51.397.923.888.923h3.554c.49 0 .888-.413.888-.923s-.398-.924-.888-.924H38.37v-1.64h2.666c.49 0 .888-.414.888-.924s-.398-.924-.888-.924H38.37v-1.64h2.666c.49 0 .888-.413.888-.923z' />
        </g>
      </g>
    </svg>
  );
};

export const chatappSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 60 60'>
      <g fill='none' fillRule='nonzero'>
        <path
          fill='#0391FD'
          d='M25.862 8.893C11.58 8.893 0 19.286 0 32.107c0 4.725 1.577 9.118 4.278 12.784C3.923 49.127 2.902 55.094 0 58c0 0 8.762-1.234 14.765-4.817.006-.004.01-.01.015-.014 1.856-1.145 3.94-2.482 4.45-2.882.311-.432.91-.562 1.374-.28.308.187.504.53.505.89.003.603-.009.77-3.777 3.126a28.438 28.438 0 0 0 8.529 1.299c14.283 0 25.862-10.394 25.862-23.215S40.145 8.893 25.863 8.893z'
        />
        <path
          fill='#0F71D3'
          d='M60 23.215C60 10.393 48.421 0 34.138 0 23.824 0 14.922 5.42 10.77 13.261c4.249-2.745 9.457-4.368 15.092-4.368 14.283 0 25.862 10.393 25.862 23.214 0 4.904-1.698 9.45-4.59 13.198C52.926 48.107 60 49.107 60 49.107c-2.902-2.905-3.923-8.872-4.278-13.109C58.423 32.332 60 27.94 60 23.215z'
        />
        <path
          fill='#FFF'
          d='M33.62 25.893H19.139a1.035 1.035 0 0 1 0-2.072H33.62a1.035 1.035 0 0 1 0 2.072zM39.31 33.143H13.448a1.035 1.035 0 0 1 0-2.072H39.31a1.035 1.035 0 0 1 0 2.072zM39.31 40.393H13.448a1.035 1.035 0 0 1 0-2.072H39.31a1.035 1.035 0 0 1 0 2.072z'
        />
      </g>
    </svg>
  );
};

export const viberappSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' width='40px' height='40px'>
      <path
        fill='#fff'
        d='M24,5C21.361,5,13.33,5,8.89,9.054C6.246,11.688,5,15.494,5,21v3c0,5.506,1.246,9.312,3.921,11.976 c1.332,1.215,3.148,2.186,5.368,2.857L15,39.047v5.328C15,45,15.181,45,15.241,45c0.123,0,0.32-0.039,0.694-0.371 c0.09-0.089,0.75-0.803,3.96-4.399l0.324-0.363l0.485,0.031C21.779,39.965,22.888,40,24,40c2.639,0,10.67,0,15.11-4.055 C41.753,33.311,43,29.505,43,24v-3c0-5.506-1.246-9.312-3.921-11.976C34.67,5,26.639,5,24,5z'
      />
      <path
        fill='#7e57c2'
        d='M33.451 28.854c-1.111-.936-1.624-1.219-3.158-2.14C29.654 26.331 28.68 26 28.169 26c-.349 0-.767.267-1.023.523C26.49 27.179 26.275 28 25.125 28c-1.125 0-3.09-1.145-4.5-2.625C19.145 23.965 18 22 18 20.875c0-1.15.806-1.38 1.462-2.037C19.718 18.583 20 18.165 20 17.816c0-.511-.331-1.47-.714-2.109-.921-1.535-1.203-2.048-2.14-3.158-.317-.376-.678-.548-1.056-.549-.639-.001-1.478.316-2.046.739-.854.637-1.747 1.504-1.986 2.584-.032.147-.051.295-.057.443-.046 1.125.396 2.267.873 3.234 1.123 2.279 2.609 4.485 4.226 6.455.517.63 1.08 1.216 1.663 1.782.566.582 1.152 1.145 1.782 1.663 1.97 1.617 4.176 3.103 6.455 4.226.958.472 2.086.906 3.2.874.159-.005.318-.023.477-.058 1.08-.238 1.947-1.132 2.584-1.986.423-.568.74-1.406.739-2.046C33.999 29.532 33.827 29.171 33.451 28.854zM34 24c-.552 0-1-.448-1-1v-1c0-4.962-4.038-9-9-9-.552 0-1-.448-1-1s.448-1 1-1c6.065 0 11 4.935 11 11v1C35 23.552 34.552 24 34 24zM27.858 22c-.444 0-.85-.298-.967-.748-.274-1.051-1.094-1.872-2.141-2.142-.535-.139-.856-.684-.718-1.219.138-.534.682-.855 1.219-.718 1.748.453 3.118 1.822 3.575 3.574.139.535-.181 1.08-.715 1.22C28.026 21.989 27.941 22 27.858 22z'
      />
      <path
        fill='#7e57c2'
        d='M31,23c-0.552,0-1-0.448-1-1c0-3.188-2.494-5.818-5.678-5.986c-0.552-0.029-0.975-0.5-0.946-1.051 c0.029-0.552,0.508-0.976,1.051-0.946C28.674,14.241,32,17.748,32,22C32,22.552,31.552,23,31,23z'
      />
      <path
        fill='#7e57c2'
        d='M24,4C19.5,4,12.488,4.414,8.216,8.316C5.196,11.323,4,15.541,4,21c0,0.452-0.002,0.956,0.002,1.5 C3.998,23.043,4,23.547,4,23.999c0,5.459,1.196,9.677,4.216,12.684c1.626,1.485,3.654,2.462,5.784,3.106v4.586 C14,45.971,15.049,46,15.241,46h0.009c0.494-0.002,0.921-0.244,1.349-0.624c0.161-0.143,2.02-2.215,4.042-4.481 C21.845,40.972,22.989,41,23.999,41c0,0,0,0,0,0s0,0,0,0c4.5,0,11.511-0.415,15.784-4.317c3.019-3.006,4.216-7.225,4.216-12.684 c0-0.452,0.002-0.956-0.002-1.5c0.004-0.544,0.002-1.047,0.002-1.5c0-5.459-1.196-9.677-4.216-12.684C35.511,4.414,28.5,4,24,4z M41,23.651l0,0.348c0,4.906-1.045,8.249-3.286,10.512C33.832,38,26.437,38,23.999,38c-0.742,0-1.946-0.001-3.367-0.1 C20.237,38.344,16,43.083,16,43.083V37.22c-2.104-0.505-4.183-1.333-5.714-2.708C8.045,32.248,7,28.905,7,23.999l0-0.348 c0-0.351-0.001-0.73,0.002-1.173C6.999,22.078,6.999,21.7,7,21.348L7,21c0-4.906,1.045-8.249,3.286-10.512 C14.167,6.999,21.563,6.999,24,6.999c2.437,0,9.832,0,13.713,3.489c2.242,2.263,3.286,5.606,3.286,10.512l0,0.348 c0,0.351,0.001,0.73-0.002,1.173C41,22.922,41,23.3,41,23.651z'
      />
    </svg>
  );
};

export const zaloappSVG = () => {
  return (
    <svg
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 460.1 436.6'
      width='35'
      height='35'>
      <path
        fill='#fdfefe'
        d='M82.6 380.9c-1.8-.8-3.1-1.7-1-3.5 1.3-1 2.7-1.9 4.1-2.8 13.1-8.5 25.4-17.8 33.5-31.5 6.8-11.4 5.7-18.1-2.8-26.5C69 269.2 48.2 212.5 58.6 145.5 64.5 107.7 81.8 75 107 46.6c15.2-17.2 33.3-31.1 53.1-42.7 1.2-.7 2.9-.9 3.1-2.7-.4-1-1.1-.7-1.7-.7-33.7 0-67.4-.7-101 .2C28.3 1.7.5 26.6.6 62.3c.2 104.3 0 208.6 0 313 0 32.4 24.7 59.5 57 60.7 27.3 1.1 54.6.2 82 .1 2 .1 4 .2 6 .2H290c36 0 72 .2 108 0 33.4 0 60.5-27 60.5-60.3v-.6-58.5c0-1.4.5-2.9-.4-4.4-1.8.1-2.5 1.6-3.5 2.6-19.4 19.5-42.3 35.2-67.4 46.3-61.5 27.1-124.1 29-187.6 7.2-5.5-2-11.5-2.2-17.2-.8-8.4 2.1-16.7 4.6-25 7.1-24.4 7.6-49.3 11-74.8 6zm72.5-168.5c1.7-2.2 2.6-3.5 3.6-4.8 13.1-16.6 26.2-33.2 39.3-49.9 3.8-4.8 7.6-9.7 10-15.5 2.8-6.6-.2-12.8-7-15.2-3-.9-6.2-1.3-9.4-1.1-17.8-.1-35.7-.1-53.5 0-2.5 0-5 .3-7.4.9-5.6 1.4-9 7.1-7.6 12.8 1 3.8 4 6.8 7.8 7.7 2.4.6 4.9.9 7.4.8 10.8.1 21.7 0 32.5.1 1.2 0 2.7-.8 3.6 1-.9 1.2-1.8 2.4-2.7 3.5-15.5 19.6-30.9 39.3-46.4 58.9-3.8 4.9-5.8 10.3-3 16.3s8.5 7.1 14.3 7.5c4.6.3 9.3.1 14 .1 16.2 0 32.3.1 48.5-.1 8.6-.1 13.2-5.3 12.3-13.3-.7-6.3-5-9.6-13-9.7-14.1-.1-28.2 0-43.3 0zm116-52.6c-12.5-10.9-26.3-11.6-39.8-3.6-16.4 9.6-22.4 25.3-20.4 43.5 1.9 17 9.3 30.9 27.1 36.6 11.1 3.6 21.4 2.3 30.5-5.1 2.4-1.9 3.1-1.5 4.8.6 3.3 4.2 9 5.8 14 3.9 5-1.5 8.3-6.1 8.3-11.3.1-20 .2-40 0-60-.1-8-7.6-13.1-15.4-11.5-4.3.9-6.7 3.8-9.1 6.9zm69.3 37.1c-.4 25 20.3 43.9 46.3 41.3 23.9-2.4 39.4-20.3 38.6-45.6-.8-25-19.4-42.1-44.9-41.3-23.9.7-40.8 19.9-40 45.6zm-8.8-19.9c0-15.7.1-31.3 0-47 0-8-5.1-13-12.7-12.9-7.4.1-12.3 5.1-12.4 12.8-.1 4.7 0 9.3 0 14v79.5c0 6.2 3.8 11.6 8.8 12.9 6.9 1.9 14-2.2 15.8-9.1.3-1.2.5-2.4.4-3.7.2-15.5.1-31 .1-46.5z'
      />
      <path
        fill='#0180c7'
        d='M139.5 436.2c-27.3 0-54.7.9-82-.1-32.3-1.3-57-28.4-57-60.7 0-104.3.2-208.6 0-313C.5 26.7 28.4 1.8 60.5.9c33.6-.9 67.3-.2 101-.2.6 0 1.4-.3 1.7.7-.2 1.8-2 2-3.1 2.7-19.8 11.6-37.9 25.5-53.1 42.7-25.1 28.4-42.5 61-48.4 98.9-10.4 66.9 10.5 123.7 57.8 171.1 8.4 8.5 9.5 15.1 2.8 26.5-8.1 13.7-20.4 23-33.5 31.5-1.4.8-2.8 1.8-4.2 2.7-2.1 1.8-.8 2.7 1 3.5.4.9.9 1.7 1.5 2.5 11.5 10.2 22.4 21.1 33.7 31.5 5.3 4.9 10.6 10 15.7 15.1 2.1 1.9 5.6 2.5 6.1 6.1z'
      />
      <path
        fill='#0172b1'
        d='M139.5 436.2c-.5-3.5-4-4.1-6.1-6.2-5.1-5.2-10.4-10.2-15.7-15.1-11.3-10.4-22.2-21.3-33.7-31.5-.6-.8-1.1-1.6-1.5-2.5 25.5 5 50.4 1.6 74.9-5.9 8.3-2.5 16.6-5 25-7.1 5.7-1.5 11.7-1.2 17.2.8 63.4 21.8 126 19.8 187.6-7.2 25.1-11.1 48-26.7 67.4-46.2 1-1 1.7-2.5 3.5-2.6.9 1.4.4 2.9.4 4.4v58.5c.2 33.4-26.6 60.6-60 60.9h-.5c-36 .2-72 0-108 0H145.5c-2-.2-4-.3-6-.3z'
      />
      <path
        fill='#0180c7'
        d='M155.1 212.4c15.1 0 29.3-.1 43.4 0 7.9.1 12.2 3.4 13 9.7.9 7.9-3.7 13.2-12.3 13.3-16.2.2-32.3.1-48.5.1-4.7 0-9.3.2-14-.1-5.8-.3-11.5-1.5-14.3-7.5s-.8-11.4 3-16.3c15.4-19.6 30.9-39.3 46.4-58.9.9-1.2 1.8-2.4 2.7-3.5-1-1.7-2.4-.9-3.6-1-10.8-.1-21.7 0-32.5-.1-2.5 0-5-.3-7.4-.8-5.7-1.3-9.2-7-7.9-12.6.9-3.8 3.9-6.9 7.7-7.8 2.4-.6 4.9-.9 7.4-.9 17.8-.1 35.7-.1 53.5 0 3.2-.1 6.3.3 9.4 1.1 6.8 2.3 9.7 8.6 7 15.2-2.4 5.7-6.2 10.6-10 15.5-13.1 16.7-26.2 33.3-39.3 49.8-1.1 1.3-2.1 2.6-3.7 4.8z'
      />
      <path
        fill='#0180c7'
        d='M271.1 159.8c2.4-3.1 4.9-6 9-6.8 7.9-1.6 15.3 3.5 15.4 11.5.3 20 .2 40 0 60 0 5.2-3.4 9.8-8.3 11.3-5 1.9-10.7.4-14-3.9-1.7-2.1-2.4-2.5-4.8-.6-9.1 7.4-19.4 8.7-30.5 5.1-17.8-5.8-25.1-19.7-27.1-36.6-2.1-18.3 4-33.9 20.4-43.5 13.6-8.1 27.4-7.4 39.9 3.5zm-35.4 36.5c.2 4.4 1.6 8.6 4.2 12.1 5.4 7.2 15.7 8.7 23 3.3 1.2-.9 2.3-2 3.3-3.3 5.6-7.6 5.6-20.1 0-27.7-2.8-3.9-7.2-6.2-11.9-6.3-11-.7-18.7 7.8-18.6 21.9zM340.4 196.9c-.8-25.7 16.1-44.9 40.1-45.6 25.5-.8 44.1 16.3 44.9 41.3.8 25.3-14.7 43.2-38.6 45.6-26.1 2.6-46.8-16.3-46.4-41.3zm25.1-2.4c-.2 5 1.3 9.9 4.3 14 5.5 7.2 15.8 8.6 23 3 1.1-.8 2-1.8 2.9-2.8 5.8-7.6 5.8-20.4.1-28-2.8-3.8-7.2-6.2-11.9-6.3-10.8-.6-18.4 7.6-18.4 20.1zM331.6 177c0 15.5.1 31 0 46.5.1 7.1-5.5 13-12.6 13.2-1.2 0-2.5-.1-3.7-.4-5-1.3-8.8-6.6-8.8-12.9v-79.5c0-4.7-.1-9.3 0-14 .1-7.7 5-12.7 12.4-12.7 7.6-.1 12.7 4.9 12.7 12.9.1 15.6 0 31.3 0 46.9z'
      />
      <path
        fill='#fdfefe'
        d='M235.7 196.3c-.1-14.1 7.6-22.6 18.5-22 4.7.2 9.1 2.5 11.9 6.4 5.6 7.5 5.6 20.1 0 27.7-5.4 7.2-15.7 8.7-23 3.3-1.2-.9-2.3-2-3.3-3.3-2.5-3.5-3.9-7.7-4.1-12.1zM365.5 194.5c0-12.4 7.6-20.7 18.4-20.1 4.7.1 9.1 2.5 11.9 6.3 5.7 7.6 5.7 20.5-.1 28-5.6 7.1-16 8.3-23.1 2.7-1.1-.8-2-1.8-2.8-2.9-3-4.1-4.4-9-4.3-14z'
      />
      <path
        fill='none'
        stroke='#0180c7'
        strokeWidth='2'
        strokeMiterlimit='10'
        d='M66 1h328.1c35.9 0 65 29.1 65 65v303c0 35.9-29.1 65-65 65H66c-35.9 0-65-29.1-65-65V66C1 30.1 30.1 1 66 1z'
      />
    </svg>
  );
};

export const wechatSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 300 300'>
      <path
        fill='#2DC100'
        d='M300 255c0 24.854-20.147 45-45 45H45c-24.854 0-45-20.146-45-45V45C0 20.147 20.147 0 45 0h210c24.853 0 45 20.147 45 45v210z'
      />
      <g fill='#FFF'>
        <path d='M200.803 111.88c-24.213 1.265-45.268 8.605-62.362 25.188-17.271 16.754-25.155 37.284-23 62.734-9.464-1.172-18.084-2.462-26.753-3.192-2.994-.252-6.547.106-9.083 1.537-8.418 4.75-16.488 10.113-26.053 16.092 1.755-7.938 2.891-14.889 4.902-21.575 1.479-4.914.794-7.649-3.733-10.849-29.066-20.521-41.318-51.232-32.149-82.85 8.483-29.25 29.315-46.989 57.621-56.236 38.635-12.62 82.054.253 105.547 30.927 8.485 11.08 13.688 23.516 15.063 38.224zm-111.437-9.852c.223-5.783-4.788-10.993-10.74-11.167-6.094-.179-11.106 4.478-11.284 10.483-.18 6.086 4.475 10.963 10.613 11.119 6.085.154 11.186-4.509 11.411-10.435zm58.141-11.171c-5.974.11-11.022 5.198-10.916 11.004.109 6.018 5.061 10.726 11.204 10.652 6.159-.074 10.83-4.832 10.772-10.977-.051-6.032-4.981-10.79-11.06-10.679z' />
        <path d='M255.201 262.83c-7.667-3.414-14.7-8.536-22.188-9.318-7.459-.779-15.3 3.524-23.104 4.322-23.771 2.432-45.067-4.193-62.627-20.432-33.397-30.89-28.625-78.254 10.014-103.568 34.341-22.498 84.704-14.998 108.916 16.219 21.129 27.24 18.646 63.4-7.148 86.284-7.464 6.623-10.15 12.073-5.361 20.804.884 1.612.985 3.653 1.498 5.689zm-87.274-84.499c4.881.005 8.9-3.815 9.085-8.636.195-5.104-3.91-9.385-9.021-9.406-5.06-.023-9.299 4.318-9.123 9.346.166 4.804 4.213 8.69 9.059 8.696zm56.261-18.022c-4.736-.033-8.76 3.844-8.953 8.629-.205 5.117 3.772 9.319 8.836 9.332 4.898.016 8.768-3.688 8.946-8.562.19-5.129-3.789-9.364-8.829-9.399z' />
      </g>
    </svg>
  );
};

export const kakaoSVG = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 256 256'>
      <path
        fill='#FFE812'
        d='M256 236c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20V20C0 8.954 8.954 0 20 0h216c11.046 0 20 8.954 20 20v216z'
      />
      <path d='M128 36C70.562 36 24 72.713 24 118c0 29.279 19.466 54.97 48.748 69.477-1.593 5.494-10.237 35.344-10.581 37.689 0 0-.207 1.762.934 2.434s2.483.15 2.483.15c3.272-.457 37.943-24.811 43.944-29.04 5.995.849 12.168 1.29 18.472 1.29 57.438 0 104-36.712 104-82 0-45.287-46.562-82-104-82z' />
      <path
        fill='#FFE812'
        d='M70.5 146.625c-3.309 0-6-2.57-6-5.73V105.25h-9.362c-3.247 0-5.888-2.636-5.888-5.875s2.642-5.875 5.888-5.875h30.724c3.247 0 5.888 2.636 5.888 5.875s-2.642 5.875-5.888 5.875H76.5v35.645c0 3.16-2.691 5.73-6 5.73zM123.112 146.547c-2.502 0-4.416-1.016-4.993-2.65l-2.971-7.778-18.296-.001-2.973 7.783c-.575 1.631-2.488 2.646-4.99 2.646a9.155 9.155 0 0 1-3.814-.828c-1.654-.763-3.244-2.861-1.422-8.52l14.352-37.776c1.011-2.873 4.082-5.833 7.99-5.922 3.919.088 6.99 3.049 8.003 5.928l14.346 37.759c1.826 5.672.236 7.771-1.418 8.532a9.176 9.176 0 0 1-3.814.827c-.001 0 0 0 0 0zm-11.119-21.056L106 108.466l-5.993 17.025h11.986zM138 145.75c-3.171 0-5.75-2.468-5.75-5.5V99.5c0-3.309 2.748-6 6.125-6s6.125 2.691 6.125 6v35.25h12.75c3.171 0 5.75 2.468 5.75 5.5s-2.579 5.5-5.75 5.5H138zM171.334 146.547c-3.309 0-6-2.691-6-6V99.5c0-3.309 2.691-6 6-6s6 2.691 6 6v12.896l16.74-16.74c.861-.861 2.044-1.335 3.328-1.335 1.498 0 3.002.646 4.129 1.772 1.051 1.05 1.678 2.401 1.764 3.804.087 1.415-.384 2.712-1.324 3.653l-13.673 13.671 14.769 19.566a5.951 5.951 0 0 1 1.152 4.445 5.956 5.956 0 0 1-2.328 3.957 5.94 5.94 0 0 1-3.609 1.211 5.953 5.953 0 0 1-4.793-2.385l-14.071-18.644-2.082 2.082v13.091a6.01 6.01 0 0 1-6.002 6.003z'
      />
    </svg>
  );
};

import { createGlobalStyle } from 'styled-components';
//  const isMobile = navigator.userAgentData.mobile;
var isMobile =   
  navigator.userAgent.indexOf( "Mobile" ) !== -1 || 
  navigator.userAgent.indexOf( "iPhone" ) !== -1 || 
  navigator.userAgent.indexOf( "Android" ) !== -1 || 
  navigator.userAgent.indexOf( "BlackBerry" ) !== -1 || 
  navigator.userAgent.indexOf( "webOS" ) !== -1 ||
  navigator.userAgent.indexOf( "iPad" ) !== -1 ||
  navigator.userAgent.indexOf( "iPod" ) !== -1 ||
  navigator.userAgent.indexOf( "Opera Mini" ) !== -1 ||
  navigator.userAgent.indexOf( "Nokia" ) !== -1 ||
  navigator.userAgent.indexOf( "Symbian" ) !== -1 ||
  navigator.userAgent.indexOf( "IEMobile" ) !== -1 ||
  navigator.userAgent.indexOf( "Windows Phone" ) !== -1 ;
export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    border: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }
  .growerFormLayout{
    overflow: ${isMobile ? 'unset' : 'auto'};;
}
  html, body, #root {
    height: 100%;
     overflow: ${isMobile ? 'visible' : 'hidden'};
    background: ${({ theme }) => theme.body};
  }


  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body, input, button {
    font: 14px 'Lato';
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
  .ant-drawer-close{
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .toggleTheme{
    position: absolute;
    top: 1.5%;
    z-index: 99;
    right: 12%;
  }
  .mainHeader{
    background: ${({ theme }) => theme.headerBg};
    color: ${({ theme }) => theme.headerTxt};
  }
  .mainDrawer{
    background: ${({ theme }) => theme.siderBg};
    color: ${({ theme }) => theme.siderTxt};
  }
  .ant-table-thead > tr > th{
    color: ${({ theme }) => theme.tableHeaderTxt};
    background: ${({ theme }) => theme.tableHeaderBg};

  }
`;

import React from 'react';
import SignIn from '../views/Login/Loginpage';
import ErrorMessage from '../views/commonComponents/errorboundry/error';
import PageNotFound from '../views/commonComponents/errorboundry/pageNotFound';
import Unauthorization from '../views/commonComponents/errorboundry/unauthorizationHandle';
import FindGrower from '../views/Growers/FindGrowers/FindGrowers';
import Interact from '../views/Growers/Interact/Interact';
import Groups from '../views/Growers/Groups/Groups';
import ComingSoon from '../views/ComingSoon/Comingsoon';
import SocialMedia from '../views/SocialMedia/SocialMedia';
import Reports from '../views/Reports/Reports';
import Users from '../views/UsersManage/UsersManage';
import WebStore from '../views/WebStore/Webstore';
import Dashboard from '../views/GrowerMetrics/growerMetrics';
import Help from '../views/Help/Help';
import Utility from '../views/utility/utility';
export const getDefaultData = ({ t }) => {
  return {
    theme: 'light',
    loginPageImage: 'idnLogin',
    loginButtonStyle: 'idnLoginbtn',
    loginButtonBackground: 'loginButtonBackground',
    drawerWidth: '200',
    protectedRoutes: [
      {
        pathName: '/find-grower',
        componentName: FindGrower
      },
      {
        pathName: '/interact',
        componentName: Interact
      },
      {
        pathName: '/groups',
        componentName: Groups
      },
      {
        pathName: '/dashboard',
        componentName: Dashboard
      },

      // {
      //   pathName: '/reports',
      //   componentName: Reports
      // },

      {
        pathName: '/engagegrowers',
        componentName: WebStore
      },
      {
        pathName: '/help',
        componentName: Help
      },
      {
        pathName: '/comingsoon',
        componentName: ComingSoon
      }
    ],
    routes: [
      {
        pathName: '/',
        componentName: SignIn
      },
      {
        pathName: '/error-message',
        componentName: ErrorMessage
      },
      {
        pathName: '*',
        componentName: PageNotFound
      }
    ],
    uploadSheetButton: [
      {
        title: `+${t('UPLOAD A FILE (BULK UPLOAD)')}`,
        key: 'bulkUpload'
      }
    ],
    sampleTemplate:
      'https://grower-console-qa-assets.s3.ap-southeast-1.amazonaws.com/sampl-file/vietnam-sample.xlsx',
    navigation: [
      {
        key: 'dashboard',
        label: t('Dashboard')
      },
      {
        key: 'engagegrowers',
        label: t('Engage Growers')
      },
      {
        key: 'on-board-growers',
        label: t('Growers'),
        children: [
          {
            key: 'on-board-growers',
            label: t('On Board Growers')
          },
          {
            key: 'find-grower',
            label: t('Find Growers')
          },
          {
            key: 'interact',
            label: t('Interact')
          },
          {
            key: 'groups',
            label: t('Groups')
          }
        ]
      },

      // {
      //   key: 'reports',
      //   label: t('Reports')
      // },

      {
        key: 'help',
        label: t('Help')
      }
    ],
    amplitude: 'VN',
    advaceSearch: {
      filterData: [
        { key: 'Region', value: t('Region') },

        { key: 'Crop', value: t('Crop') },
        { key: 'mobileNumber', value: t('Mobile Number') },
        { key: 'Acres', value: t('Land Size') },
        { key: 'facebook', value: t('Facebook') },
        { key: 'zalo', value: t('Zalo') },
        { key: 'syngentaProductUsing', value: t('Syngenta Product Using') },
      ],
      dropDownMenu: {
        Region: [{ key: 'in', value: t('in') }],
        Crop: [{ key: 'in', value: t('in') }],
        mobileNumber: [
          { key: 'contains', value: t('contains') },
          { key: 'equals to', value: t('equals to') }
        ],
        Acres: [
          { key: 'equals to', value: t('equals to') },
          { key: 'greater than', value: t('greater than') },
          { key: 'less than', value: t('less than') }
        ],
        facebook: [{ key: 'in', value: t('in') }],
        zalo: [{ key: 'in', value: t('in') }],
        syngentaProductUsing: [{ key: 'in', value: t('in') }],
      },
      defaulState: [{ id: 1, field: { name: 'Region' }, operator: { name: 'in' }, value: [] }],
      addNewFilter: 'Region'
    },

    groupDrawer: [
      {
        key: 'allCrop',
        optionValue: 'childrenCrop',
        fieldName: 'GrowersCrop',
        initialValue: 'GrowersCrop',
        // message: t('Please select some crops'),
        placeholder: t('Select Some Crops'),
        handler: 'handleCropSelectAll',
        rules: []
      },
      {
        key: 'allRegion',
        optionValue: 'childrenRegion',
        fieldName: 'GrowersRegion',
        initialValue: 'GrowersRegion',
        placeholder: t('Select Some Regions'),
        handler: 'handleRegionSelectAll',
        rules: []
      }
    ],
    growerDetailForm: {
      growerDetail: [
        {
          label: t('Name'),
          fieldName: 'Name',
          initialValue: 'growerName',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter first name'),
            whitespace: true
          }
        },
        // {
        //   label: t('Last Name'),
        //   fieldName: 'lastName',
        //   initialValue: 'lastName',
        //   type: 'text',
        //   rules: {
        //     required: false,
        //     message: t('Please enter last name'),
        //     whitespace: true
        //   }
        // },
        {
          label: t('Mobile Number'),
          fieldName: 'mobile',
          initialValue: 'contactNo',
          type: 'number',
          disable: true,
          rules: {
            required: true,
            message: t('Please enter mobile number')
          }
        },
        {
          label: t('Crop'),
          fieldName: 'Crop',
          initialValue: 'crops',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter crop'),
            whitespace: true
          }
        },
        {
          label: t('Land Size (HA)'),
          fieldName: 'land',
          initialValue: 'landSize',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter land size'),
            validator: 'validateField',
            whitespace: true
          }
        }
      ],
      growerAddress: [
        {
          label: t('Address Line'),
          fieldName: 'addressLine',
          initialValue: 'addressLine',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter Address Line'),
            whitespace: true
          }
        },
        {
          label: t('Area'),
          fieldName: 'area',
          initialValue: 'area',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter area'),
            whitespace: true
          }
        },
        {
          label: t('Province'),
          fieldName: 'province',
          initialValue: 'province',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter province'),
            whitespace: true
          }
        },

        {
          label: t('Region'),
          fieldName: 'Region',
          initialValue: 'region',
          type: 'text',
          rules: {
            required: true,
            message: t('Please enter region'),
            whitespace: true
          }
        },
        {
          label: t('District'),
          fieldName: 'district',
          initialValue: 'district',
          type: 'text',
          rules: {
            required: false,
            message: t('Please enter district'),
            whitespace: true
          }
        }
      ],
      growerSourcing: [
        {
          label: t('Email'),
          fieldName: 'email',
          initialValue: 'email',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Email'),
            whitespace: true
          }
        },

        {
          label: t('Facebook'),
          fieldName: 'facebook',
          initialValue: 'facebook',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Facebook'),
            whitespace: true
          }
        },
        {
          label: t('Home Phone'),
          fieldName: 'homePhone',
          initialValue: 'homePhone',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Home Phone'),
            whitespace: true
          }
        },
        {
          label: t('Total Income'),
          fieldName: 'totalIncome',
          initialValue: 'totalIncome',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Total income'),
            whitespace: true
          }
        },
        {
          label: t('Zalo Number'),
          fieldName: 'zaloNumber',
          initialValue: 'zaloNumber',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Zalo number'),
            whitespace: true
          }
        },
        {
          label: t('Syngenta Product Using'),
          fieldName: 'syngentaProduct',
          initialValue: 'syngentaProduct',
          type: 'text',
          disable: false,
          rules: {
            required: false,
            message: t('Please enter Syngenta Product Using'),
            whitespace: true
          }
        }
      ]
    },
    tagVisible: true,
    cropHistoryVisible: true,
    hideNewTag: true,
    campignTable: [
      {
        title: t('MODE'),
        dataIndex: 'mode'
      },
      {
        title: t('CAMPAIGN NAME'),
        dataIndex: 'name'
      },
      {
        title: t('REACH'),
        dataIndex: 'reach'
      }
    ],
    growerListTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (HA)'),
        dataIndex: 'landSize'
      }
    ],
    groupListTable: [
      {
        title: t('GROUP'),
        dataIndex: 'groupName'
      },
      // {
      //   title: `#${t('MEMBERS')}`,
      //   dataIndex: 'noOfMembers'
      // },
      {
        title: t('CREATED BY'),
        dataIndex: 'createdBy'
      },
      {
        title: t('CREATED ON'),
        dataIndex: 'creationDate'
      },
      {
        title: t('GROUPED BY'),
        dataIndex: 'filters'
      }
    ],
    groupMemberTable: [
      {
        title: t('GROWER NAME'),
        dataIndex: 'growerName'
      },
      {
        title: t('CONTACT NUMBER'),
        dataIndex: 'contactNo'
      },
      {
        title: t('LOCATION'),
        dataIndex: 'location'
      },
      {
        title: t('CROP'),
        dataIndex: 'crops'
      },
      {
        title: t('LAND SIZE (HA)'),
        dataIndex: 'landsize'
      }
    ],
    interactTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('SENDER'),
        dataIndex: 'Sender'
      },
      {
        title: t('SENT ON'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    scheduleMessageTable: [
      {
        title: t('GROUP'),
        dataIndex: 'Group',
        render: (text, record) => {
          return record.deletedGroup ? (
            <div style={{ color: '#D3D3D3' }}>{text}</div>
          ) : (
            <div>{text}</div>
          );
        }
      },
      {
        title: t('TYPE'),
        dataIndex: 'msgType'
      },
      {
        title: t('MESSAGE'),
        dataIndex: 'message'
      },
      {
        title: t('SCHEDULED BY'),
        dataIndex: 'Sender'
      },
      {
        title: t('SCHEDULED FOR'),
        dataIndex: 'SentOn'
      },
      {
        title: t('PLATFORM'),
        dataIndex: 'Platform'
      }
    ],
    searchZoneMessage: {
      message: t('Please select some zone'),
      placeholder: t('Select Some Zone')
    },

    searchTmhqMessage: {
      message: t('Please select some TMHQ'),
      placeholder: t('Select Some TMHQ')
    },
    replaceTitle: ['0-20 hectare', '21-50 hectare', '51-100 hectare', '> 100 hectare'],
    paiChart: 'vnDistributionCard',
    acresVisible: false,
    optInOutVisible: true,
    dividerHeight: '590px',
    saveButtonPosition: 'inButtonPosition'
  };
};

import React, { Component } from 'react';
import { Layout, Card, Row, Col, Button, Input, Icon, Select, Form } from 'antd';
import { connect } from 'react-redux';
import get from 'lodash.get';
import BUZZBee from './buzeeBee';
import { toggleDrawer } from '../../actions/uploadYouTubeVideos';
import { RegionalMetaContext } from '../../context/region';
import { Translation } from 'react-i18next';
import USSD from './ussd';
import TitleSection from '../commonComponents/TitleSection/titleSection';
import { StyledCard } from './utilityStyle';
const { Option } = Select;
/*** Ant Design Components */
const { Content, Header } = Layout;
const { Meta } = Card;
const { Search } = Input;

class Utility extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 'ussd',
      value: 'ussd'
    };
  }
  handleChange = (val) => this.setState({ value: val });
  render() {
    const contentList = {
      ussd: <USSD />,
      buzzebees: <BUZZBee />
    };

    return (
      <RegionalMetaContext.Consumer>
        {({ data }) => (
          <Layout className='utilityLayout'>
            <>
              <Content>
                <TitleSection title=' USSD/BuzzBee Transformation' />
              </Content>
              <StyledCard bordered={false}>
                <Form>
                  <Form.Item>
                    <Select
                      showSearch
                      className='schemaSelect'
                      name='schema'
                      style={{ width: '20%' }}
                      placeholder='Select file type'
                      onChange={this.handleChange}
                      setFieldsValue={this.state.selectedSchema}>
                      <Option value='ussd'>USSD</Option>
                      <Option value='buzzebees'>BUZZEBEES</Option>
                    </Select>
                    <Content
                      style={{
                        position: 'relative',
                        top: '-65px',
                        height: '0'
                      }}>
                      {contentList[this.state.value]}
                    </Content>
                  </Form.Item>
                </Form>
              </StyledCard>
            </>
          </Layout>
        )}
      </RegionalMetaContext.Consumer>
    );
  }
}

export default Utility;

import config from '../config';
import axios from 'axios';
import {
  UPLOAD_FACEBOOK_POST_PENDING,
  UPLOAD_FACEBOOK_POST_SUCCESS,
  UPLOAD_FACEBOOK_POST_ERROR,
  UPLOAD_FACEBOOK_POST_CLICKED
} from '../constants/index';
import { toggleSpinStatus } from './globalSpinner';
import { errorNotification, successNotification, clearAlertState } from './alertMessage';
import { youTubeAuthAPI } from './youTubeList';
import { handleErrors } from '../services/handleErrors';
export const upadteLoadingStatus = (val) => {
  return { type: 'GROWER_LOADING', payload: val };
};

export const uploadFacebookPostPending = () => {
  return {
    type: UPLOAD_FACEBOOK_POST_PENDING
  };
};
export const uploadFacebookPostSuccess = (value) => {
  return {
    type: UPLOAD_FACEBOOK_POST_SUCCESS,
    payload: value
  };
};

export const uploadFacebookPostError = (error) => {
  return {
    type: UPLOAD_FACEBOOK_POST_ERROR,
    payload: error
  };
};
export const uploadFacebookPostClicked = (click) => {
  return {
    type: UPLOAD_FACEBOOK_POST_ERROR,
    payload: click
  };
};
export const uploadFacebookPost = (postDetail, fileType) => {
  let endPoint = '';
  if (fileType.includes('image')) {
    endPoint = 'photos';
  } else if (fileType.includes('video')) {
    endPoint = 'videos';
  } else {
    endPoint = 'feed';
  }

  const channelUri = `https://graph.facebook.com/2254913651400212/${endPoint}?`;
  return (dispatch) => {
    dispatch(uploadFacebookPostPending());
    axios
      .post(channelUri, postDetail, {
        params: {
          published: 'false',
          access_token: localStorage.getItem('facebookAuthToken')
        },
        headers: {
          'content-type': 'multipart/form-data',
          Authorization: localStorage.getItem('facebookAuthToken')
        }
      })

      .then((response) => {
        dispatch(uploadFacebookPostSuccess(response));
        dispatch(clearAlertState());
        dispatch(
          successNotification({
            type: 'success',
            message: 'Posted Successfully',
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
        // dispatch(publishFacebookPost(response.data.id));
      })

      .catch((error) => {
        // const parseData = JSON.stringify(error);
        // const errorResponse = JSON.parse(parseData);
        // if (errorResponse.message.indexOf('403') > -1) {
        //   const response = {
        //     status: 403
        //   };
        //   return handleErrors(response, dispatch);
        // }

        dispatch(uploadFacebookPostError(error));
        dispatch(clearAlertState());
        dispatch(
          errorNotification({
            type: 'error',
            message: error.message,
            toggleAlert: true
          })
        );
        dispatch(clearAlertState());
      });
  };
};
export const getFacebookPage = () => {
  return (dispatch) => {
    axios
      .get(`https://graph.facebook.com/2254913651400212?`, {
        params: {
          access_token: localStorage.getItem('facebookAuthToken')
        }
      })
      .then((response) => {})
      .catch((error) => {});
  };
};

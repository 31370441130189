import React from 'react';
import { Form, Select } from 'antd';
import { fetchSegment } from '../../../services/groupFilterService';
import { connect } from 'react-redux';
import { get } from 'lodash';
const { Option } = Select;
class SearchSegment extends React.Component {
  constructor(props) {
    super(props);
    this.childrenSegment = [];
  }
  handleChange = (user, value) => {
    const newUsers = [...this.props.users];
    const currentUser = newUsers.find((element) => element.id === user.id);
    currentUser.value = value;
    this.props.errorCallback(user);
    this.props.callback(newUsers);
  };

  componentDidMount() {
    this.props.fetchSegment();
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    const { t } = this.props;
    let segmentData = this.props.groupFilterReducer || [];
    let segmentText = segmentData.length > 0 ? segmentData[0].items : [];
    this.childrenSegment = segmentText.map((segment) => <Option key={segment}>{segment}</Option>);

    return (
      <Form.Item label='' className='acares'>
        {getFieldDecorator(`${this.props.segmentValue}`, {
          validateTrigger: ['onChange', 'onBlur'],
          rules: [{ required: true, message: t('Please select some segment') }]
        })(
          <Select
            name='Segment'
            showArrow={true}
            size='small'
            mode='multiple'
            placeholder={t('Select Some Segment')}
            maxTagCount={5}
            onChange={(value) => {
              this.handleChange(this.props.user, value);
            }}>
            {this.childrenSegment}
          </Select>
        )}
      </Form.Item>
    );
  }
}
const mapDispatchToProps = function (dispatch) {
  return {
    fetchSegment: () => dispatch(fetchSegment())
  };
};
const mapStateToProps = function (state) {
  return {
    groupFilterReducer: get(state, 'groupFilterReducer.data.groupSegment.items', [])
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchSegment);

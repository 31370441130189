import SessionTimeOut from '../views/commonComponents/errorboundry/showModel';
import { sessionTimeoutError } from '../actions/dashboard';
import { toggleSpinStatus } from '../actions/globalSpinner';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';

export let handleErrors = (response, dispatch) => {
  if (response.status === 401) {
    // dispatch(toggleSpinStatus());
    dispatch(sessionTimeoutError(true));
    // dispatch(toggleSpinStatus());
    //alert('Your session has expired. Please login again.');
    //localStorage.clear();
    //window.location.href = '/';
    dispatch(clearAlertState());
    return false;
  } else if (response.status === 403) {
    alert('You are not authorized to use this .');
    window.location.href = '/';
  }
  return response.json();
};

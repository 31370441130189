import axios from 'axios';
import {
  SEND_MMS_PENDING,
  SEND_MMS_SUCCESS,
  FETCH_SEARCH_UPDATE,
  SEND_MMS_ERROR,
  CLEAR_SEND_MMS_RESULT,
  GET_SEARCH_STATE
} from '../constants';
import { sentMessageListWithFetchAPI } from '../services/messageListService';
import { toggleDrawer, highlightMessageTableRow } from './messageSending';
import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';
import { errorNotification, successNotification, clearAlertState } from '../actions/alertMessage';
export function sendMMSResultPending() {
  return {
    type: SEND_MMS_PENDING
  };
}
export function sendMMSResultSuccess(searchList) {
  return {
    type: SEND_MMS_SUCCESS,
    payload: searchList
  };
}

export function clearSendMMSResult() {
  return {
    type: CLEAR_SEND_MMS_RESULT
  };
}
export function sendMMSResultError(error) {
  return {
    type: SEND_MMS_ERROR,
    payload: error
  };
}
export function setSearchState(users) {
  return {
    type: GET_SEARCH_STATE,
    payload: users
  };
}
export const sendMMS = (formData) => {
  // const apiUri = formatUrl(config.sendMMS);
  const apiUri = config.sendMMS;

  return (dispatch) => {
    dispatch(sendMMSResultPending(true));
    dispatch(toggleSpinStatus());
    axios
      .post(apiUri, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: 'Bearer ' + config.userToken
        }
      })
      .then((result) => {
        if (result && result.data.code === '200') {
          dispatch(sentMessageListWithFetchAPI(0));
          dispatch(sendMMSResultPending(false));
          dispatch(toggleSpinStatus());

          dispatch(toggleDrawer());
          dispatch(highlightMessageTableRow(true));
          dispatch(clearAlertState());
          dispatch(sendMMSResultSuccess(result));
          // dispatch(clearAlertState());
          dispatch(
            successNotification({
              type: 'success',
              message: result.data.message,
              toggleAlert: true
            })
          );
          dispatch(clearAlertState());
          //  dispatch(toggleSpinStatus());
        } else {
          dispatch(sendMMSResultPending());
          dispatch(toggleSpinStatus());
          dispatch(sendMMSResultError(error));
          dispatch(clearAlertState());
          // dispatch(toggleSpinStatus());
          dispatch(
            errorNotification({
              type: 'error',
              message:
                'Message not sent because the requested channel is not supported by any group members',
              toggleAlert: true
            })
          );
        }
      })
      .catch((error) => {
        const parseData = JSON.stringify(error);
        const errorResponse = JSON.parse(parseData);

        dispatch(sendMMSResultPending());
        dispatch(toggleSpinStatus());
        dispatch(sendMMSResultError(error));
        dispatch(clearAlertState());
        // dispatch(toggleSpinStatus());
        dispatch(
          errorNotification({
            type: 'error',
            message:
              'Message not sent because the requested channel is not supported by any group members',
            toggleAlert: true
          })
        );
      });
  };
};

import {
  REQUEST_WEBSTORE_MESSAGE_SEND,
  REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS,
  REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE,
  TOGGLE_DRAWER_STATUS
} from '../constants';

const initialState = {
  WebstoreMessageData: [],
  webstoreDrawerVisible: false,
  selectedGroup: '',
  webstoreMsgSendingStatus: false,
  WebstoreMessageError: [],
  widgetdata: []
};

export default function webstoreMessageSendingReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case REQUEST_WEBSTORE_MESSAGE_SEND:
      return {
        ...state,
        webstoreMsgSendingStatus: action.payload
      };
    case REQUEST_WEBSTORE_MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        WebstoreMessageData: action.payload
      };
    case REQUEST_WEBSTORE_MESSAGE_SEND_FAILURE:
      return {
        ...state,
        WebstoreMessageError: action.payload
      };
    case TOGGLE_DRAWER_STATUS:
      return {
        ...state,
        webstoreDrawerVisible: !state.webstoreDrawerVisible,
        widgetdata: action.payload
      };

    default:
      return state;
  }
}

import React from 'react';
import { Layout } from 'antd';
import { Translation } from 'react-i18next';
import './comingsoon.css';
/*** Ant Design Components */
const { Content } = Layout;

export default function ComingSoon() {
  return (
    <Content className='ComSonmainContent'>
      <div className='comingson'>
        <h1 className='inprogressh1'>
          <Translation>{(t) => <>{t('Work in progress')}</>}</Translation>
        </h1>
      </div>
    </Content>
  );
}

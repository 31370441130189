import React from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Modal,
  Layout,
  Result
} from 'antd';
const LanguageDetect = require('languagedetect');
const lngDetector = new LanguageDetect();
import { connect } from 'react-redux';
import { get } from 'lodash';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import { fetchAddGrowers } from '../actions/addUpdatesGrowerForm';
import { alertBox } from '../views/commonComponents/Notification/alertBox';
import { uniq } from 'lodash';
import './addGrowerForm.css';
const { Option } = Select;
const { Footer } = Layout.Footer;

const languageValues = [
  { key: 'Malay', value: 'Malay' },
  { key: 'English', value: 'English' },
  { key: 'Mandarin', value: 'Mandarin' }
];

const stateValues = [
  { key: 'PAHANG', value: 'PAHANG' },
  { key: 'PERAK', value: 'PERAK' },
  { key: 'TERENGGANU', value: 'TERENGGANU' },
  { key: 'PERLIS', value: 'PERLIS' },
  { key: 'KUALA LUMPUR', value: 'KUALA LUMPUR' },
  { key: 'SELANGOR', value: 'SELANGOR' },
  { key: 'NEGERI SEMBILAN', value: 'NEGERI SEMBILAN' },
  { key: 'JOHOR', value: 'JOHOR' },
  { key: 'KELANTAN', value: 'KELANTAN' },
  { key: 'KEDAH', value: 'KEDAH' },
  { key: 'PULAU PINANG', value: 'PULAU PINANG' },
  { key: 'MELAKA', value: 'MELAKA' },
  { key: 'SABAH', value: 'SABAH' },
  { key: 'SARAWAK', value: 'SARAWAK' }
];

const cropTypeValues = [
  { key: 'Padi', value: 'Padi' },
  { key: 'Durian', value: 'Durian' },
  { key: 'Sawit', value: 'Sawit' },
  { key: 'Jagung', value: 'Jagung' },
  { key: 'Cili', value: 'Cili' },
  { key: 'Buahan', value: 'Buahan' },
  { key: 'Sayuran', value: 'Sayuran' },
  { key: 'Tomato', value: 'Tomato' },
  { key: 'Flower', value: 'Flower' }
];
const cropLandSizeValues = [
  { key: 'Acre', value: 'Acre' },
  { key: 'HA', value: 'HA' },
  { key: 'Relung', value: 'Relung ' }
];
class AddGrowerMy extends React.Component {
  state = {
    formData: {},
    confirmDirty: false,
    checked: true,
    visible: false,
    done: null,
    message: '',
    isError: false,
    thaiValue: '',
    disabled:false
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let growers = {
          mobile: `60${
            values.mobile.indexOf(0) == '0' ? values.mobile.substring(1) : values.mobile
          }`,
          name: values.name,
          address: {
            district: values.district,
            state: values.state,
            zone: values.zone,
            region: values.region
          },
          // sourcing: {
          //   salesUnit: values.source
          // },
          cropInfo: {
            current: {
              name: values.cropName,
              acres: values.acre
            }
          },
          additionalFields: {
            'Retailer Name': values.retailerName,
            'Crop Type': values.cropType,
            // 'Land Type': values.landSizeType,

            Language: values.language,
            PPK: values.ppk,
            KP: values.kp,
            PIC: values.source
          }
        };

        this.props.fetchAddGrowers(growers);
        this.setState({
          disabled: true,
        });
        setTimeout(() => {
          this.setState(() => ({
            disabled: false,
          }));
        }, 10000);
      }
    });
  };
  onChange = (e) => {
    this.setState({ checked: !e.target.checked });
  };
  showModal = () => {
    this.setState({
      visible: true
    });
  };
  handleOk = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false
    });
  };
  handleReset = () => {
    this.props.form.resetFields();
  };

  validateNumber = (rule, value, callback) => {
    console.log('values>>', value.length);

    if (value === '' || value === undefined) {
      callback();
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Sila Masukkan a number');
    } else {
      callback();
    }
  };
  validateMobileNumber = (rule, value, callback) => {
    if (value === '' || value === undefined) {
      callback();
    } else if (value.length > 10) {
      callback('Mobile number should be 9 to 10 digit only');
    } else if (value.length < 9) {
      callback('Mobile number should be 9 to 10 digit only');
    } else if (isNaN(value) || Math.sign(value) == -1) {
      callback('Sila Masukkan mobile number');
    } else {
      callback();
    }
  };


  validatorName = (rule, value, callback) => {
    const reg = /^[A-Za-z_ ]+$/;
    if (value === '' || value === undefined) {
      callback();
    } else if (!reg.test(value)) {
      callback('Please fill in the correct information');
    } else {
      callback();
    }
  };

  hideSucess = () => {
    this.setState({
      done: null,
      disabled:false
    });
  };

 

  componentDidMount = () => {
    const { location } = this.props;
    const params = queryString.parse(this.props.history.location.search);
    this.setState({
      formData: params
    });
  };
  validateError = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  componentDidUpdate = () => {
    if (this.props.alertType === 'success' && this.state.done === null) {
      this.setState({
        done: true,
        message: this.props.alertMessage
      });
    } else {
      console.log('render error');
    }
  };

  callback = (key) => {
    console.log(key);
  };



  render() {
    console.log('formData', this.state.formData);
    const { getFieldDecorator, getFieldsError } = this.props.form;

    const cropTypeOption = uniq(cropTypeValues).map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const cropLandSizeOption = cropLandSizeValues.map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const stateOption = uniq(stateValues).map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });
    const languageOption = languageValues.map((value, index) => {
      return (
        <Option key={index} value={value.value}>
          {value.value}
        </Option>
      );
    });

    const loginPageImage = {
      synLogo: require('../assets/img/syn-logo.png')
    };
    return (
      <div
        style={{ width: '100%', height: 'auto', padding: '24px', backgroundColor: '#2B9C92' }}
        className=''>
        {!this.state.done ? (
          <Form
            layout='vertical'
            // onChange={this.validateError}
            onSubmit={this.handleSubmit}
            style={{
              height: 'auto',
              margin: '0 auto',
              padding: '20px',
              backgroundColor: '#ffffff',
              borderRadius: '8px'
            }}
            className='addGrower'>
            <div style={{ display: 'flex' }}>
              <div
                className='Title'
                style={{
                  fontWeight: '600',
                  fontSize: '1.5em',
                  color: '#14151C',
                  paddingLeft: '30px',
                  position: 'relative',
                  top: '2px'
                }}>
                Maklumat Petani
              </div>
              <div class='avatarImageMY'>
                <img
                  src={loginPageImage.synLogo}
                  alt='Avatar'
                  width='80vh'
                  height='100px'
                  style={{ height: '75px' }}
                />
              </div>
            </div>
            <Form.Item label={<p className='loginFormLabel'>Nama</p>}>
              {getFieldDecorator('name', {
                initialValue: this.state.formData.Name || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Sila Masukkan Name`, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(
                <Input
                placeholder={'Sila Masukkan Name'}
                  type='text'
                 
                />
              )}
            </Form.Item>

            <Form.Item  label={<p className='loginFormLabel'>Telefon</p>}> 
              {getFieldDecorator('mobile', {
                initialValue: this.state.formData.MobileNumber || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    message: 'Sila Masukkan Mobile Number'
                    // whitespace: true
                  },
                 
                  {
                    validator: this.validateMobileNumber
                  }
                ]
              })(
                <Input
                  addonBefore='+60'
                  maxLength={10}
                  placeholder={'Sila Masukkan Mobile Number'}
                  onKeyDown={(e) =>
                    (e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 32) &&
                    e.preventDefault()
                  }
                />
              )}
            </Form.Item>

            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Bahasa</p>}>
              {getFieldDecorator('language', {
                initialValue: this.state.formData.language || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Sila Pilih Bahasa' }]
              })(
                <Select
                  placeholder='Sila Pilih Bahasa'
                >
                  {languageOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>Zon</p>}>
              {getFieldDecorator('zone', {
                initialValue: this.state.formData.postCode || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'Sila Masukkan Zon' }
                ]
              })(<Input placeholder='Sila Masukkan Zon' showSearch />)}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>Daerah</p>}>
              {getFieldDecorator('district', {
                initialValue: this.state.formData.Surname || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: `Sila Masukkan Daerah `, whitespace: true },
                  {
                    validator: this.validatorName
                  }
                ]
              })(<Input placeholder='Sila Masukkan Daerah' />)}
            </Form.Item>
            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Negeri</p>}>
              {getFieldDecorator('state', {
                initialValue: this.state.formData.state || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Sila Pilih Negeri ' }]
              })(
                <Select placeholder='Sila Pilih Negeri' showSearch>
                  {stateOption}
                </Select>
              )}
            </Form.Item>

            <Form.Item className='addGrowerFrom' label={<p className='loginFormLabel'>Jenis Tanaman</p>}>
              {getFieldDecorator('cropType', {
                initialValue: this.state.formData.cropType || [],
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Sila Pilih Jenis Tanaman ' }]
              })(
                <Select placeholder='Sila Pilih Jenis Tanaman' showSearch>
                  {cropTypeOption}
                </Select>
              )}
            </Form.Item>
            <Form.Item label={<p className='loginFormLabel'>Nama Tanaman</p>}>
              {getFieldDecorator('cropName', {
                initialValue: this.state.formData.cropName || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: true, message: 'Sila Masukkan Nama Tanaman' }]
              })(<Input placeholder='Sila Masukkan Nama Tanaman' showSearch />)}
            </Form.Item>

            <Form.Item label={<p className='loginFormLabel'>No K/P</p>}>
              {getFieldDecorator('kp', {
                initialValue: this.state.formData.ppk || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Sila Masukkan No K/P' }]
              })(<Input placeholder='Sila Masukkan No K/P' showSearch />)}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>Nama Kedai Racun</p>}>
              {getFieldDecorator('retailerName', {
                initialValue: this.state.formData.retailerName || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Sila Masukkan Nama Kedai Racun' }]
              })(<Input placeholder='Sila Masukkan Nama Kedai Racun' showSearch />)}
            </Form.Item>
            <Form.Item  label={<p className='loginFormLabel'>Nama PPK</p>}>
              {getFieldDecorator('ppk', {
                initialValue: this.state.formData.ppk || '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ required: false, message: 'Sila Masukkan Nama PPK' }]
              })(<Input placeholder='Sila Masukkan Nama PPK' showSearch />)}
            </Form.Item>
  
            <Form.Item  label={<p className='loginFormLabel'>Saiz Tanaman</p>}>
              {getFieldDecorator('acre', {
                initialValue: this.state.formData.Acre || '0',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  { required: true, message: 'Sila Masukkan Land Size' },
                  {
                    validator: this.validateNumber
                  }
                ]
              })(<Input type='text' maxLength={3} pattern={'d{3}'} />)}
            </Form.Item>

            <Form.Item>
              <a onClick={this.showModal} style={{color:'#1890ff'}}>Klik untuk melihat terma dan syarat syarikat.</a>
            </Form.Item>
            <Form.Item label='Source' hidden={true}>
              {getFieldDecorator('source', {
                initialValue: this.state.formData.source || 'direct grower'
              })(<Input />)}
            </Form.Item>
            <Form.Item label='Region' hidden={true}>
              {getFieldDecorator('region', {
                initialValue: this.state.formData.region || 'direct grower'
              })(<Input />)}
            </Form.Item>
            <Form.Item
              style={{
                marginTop: '10px !important'
              }}>
              <Button
                htmlType='submit'
                className='buttonStyle'
                onClick={this.handleSubmit}
               disabled={this.state.disabled}
              >
                Hantar
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Result status='success' title=' Information registration is successful.' />
        )}
        <Modal
          title=''
          visible={this.state.visible}
          onOk={this.handleOk}
          closable={false}
          onCancel={this.handleCancel}
          footer={[
            <Button type='primary' onClick={this.handleOk}>
              setuju
            </Button>
          ]}>
          <p>
            Data peribadi mengikut Akta Perlindungan Data Peribadi B.E. 2562 <br />
            untuk tujuan merancang kempen pemasaran dalam talian Syngenta (AS) Kami diwajibkan untuk
            meminta dan menyimpan maklumat peribadi anda untuk kami. dan mereka yang ditugaskan oleh
            kami Dalam rangka kerja objektif di atas Seperti yang anda telah menekan kod atau
            memberikan maklumat untuk mendaftar petani. dan telah memberikan persetujuan dengan ini
            Jika anda tidak lagi ingin anda maklumat yang akan digunakan untuk tujuan tersebut Sila
            hubungi salah satu jualan kami wakil rakyat.
          </p>
        </Modal>

        {this.props.alertType === 'error'
          ? this.props.toggleAlert
            ? alertBox({
                message: this.props.alertMessage,
                type: this.props.alertType
              })
            : null
          : ''}
      </div>
    );
  }
}
const mapDispatchToProps = function(dispatch) {
  return {
    fetchAddGrowers: (growers) => dispatch(fetchAddGrowers(growers))
  };
};
const mapStateToProps = function(state) {
  return {
    growerList: get(state, 'addedGrowerList.data.growerSucess', []),
    alertMessage: get(state, 'alertMessage.alertMessage', ''),
    alertType: get(state, 'alertMessage.alertType', ''),
    toggleAlert: get(state, 'alertMessage.toggleAlert', true)
  };
};

const WrappedRegistrationForm = Form.create({ name: 'register' })(AddGrowerMy);
export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
import { combineReducers } from 'redux';
import authentication from './authentication';
import growerList from './growerList';
import uploadList from './uploadedGrowersList';
import uploadFormReducer from './uploadForm';
import groupFilterReducer from './groupFilter';
import creatGroupReducer from './groupCreate';
import groupListReducer from './groupList';
import messageLists from './messageList';
import globalSpinner from './globalSpinner';
import alertMessage from './alertMessage';
import messageSendingFormReducer from './messageSending';
import webStoreListReducer from './webStoreList';
import webstoreMessageSendingReducer from './webstoreMessageSending';
import allGrowersCountReducer from './allGrowersCount';
import growerUpdateReducer from './growerUpdate';
import searchList from './advanceSearch';
import GrowersReducer from './defaultGrowers';
import communicationList from './modesOfCommunication';
import groupMemberList from './groupMember';
import dashboardList from './dashboard';
import updateStatisticsData from './updateStatistics';
import costEstimatorList from './costEstimator';
import youTubeLists from './youTubeList';
import uploadYoutubeList from './uploadedVideoList';
import facebookList from './facebookAuth';
import uploadFacebookList from './uploadFacebookList';
import uploadYouTubeVideo from './uploadYouTubeVideos';
import uploadYouTubeVideoDetails from './uploadYouTubeVideoDetails';
import facebookPost from './facebookPost';
import scheduleMessage from './scheduleMessage';
import scheduleMessageList from './scheduleMessageList';
import downloadSearchResult from './downloadSearchResult';
import whatsAppTemplate from './whatsAppTemplate';
import whatsAppTemplateData from './whatsAppTemplateData';
import salesUnitList from './salesUnit';
import picList from './pic';
import brandList from './brand';
import getConversationList from './getConversation';
import openConversationList from './openConversation';
import deleteGroupReducer from './groupDelete';
import groupUpdateReducer from './groupUpdate';
import sendMMSReducer from './sendMMS';
import scheduleMMSReducer from './scheduleMMS';
import scheduleMessageUpdateReducer from './scheduleMessageUpdate';
import scheduleMessageDelete from './scheduleMessageDelete';
import scheduleMMSUpdate from './scheduleMMSUpdate';
import optInListReducer from './optIn';
import optOutReducer from './optOut';
import downloadSourceResult from './downloadSourceResult';
import downloadGroupData from './downloadGroupData';
import messageCountListReducer from './messageCountList';
import addedGrowerList from './addGrowerForm';
import updateSingleGrower from './updateSingleGrower';
import downloadReportResult from './downloadReport';
export default combineReducers({
  authentication,
  growerList,
  uploadList,
  messageLists,
  uploadFormReducer,
  groupFilterReducer,
  creatGroupReducer,
  groupListReducer,
  globalSpinner,
  alertMessage,
  messageSendingFormReducer,
  webStoreListReducer,
  webstoreMessageSendingReducer,
  allGrowersCountReducer,
  growerUpdateReducer,
  searchList,
  GrowersReducer,
  communicationList,
  groupMemberList,
  dashboardList,
  updateStatisticsData,
  costEstimatorList,
  youTubeLists,
  uploadYoutubeList,
  facebookList,
  uploadFacebookList,
  uploadYouTubeVideo,
  uploadYouTubeVideoDetails,
  facebookPost,
  scheduleMessage,
  scheduleMessageList,
  downloadSearchResult,
  whatsAppTemplate,
  whatsAppTemplateData,
  salesUnitList,
  picList,
  getConversationList,
  openConversationList,
  brandList,
  deleteGroupReducer,
  groupUpdateReducer,
  sendMMSReducer,
  scheduleMMSReducer,
  scheduleMessageUpdateReducer,
  scheduleMessageDelete,
  scheduleMMSUpdate,
  optInListReducer,
  optOutReducer,
  downloadSourceResult,
  downloadGroupData,
  messageCountListReducer,
  addedGrowerList,
  updateSingleGrower,
  downloadReportResult
});

const initialState = {
  data: [],
  loading: true
};

function uploadList(state = initialState, action) {
  switch (action.type) {
    case 'UPLOAD_GROWER_PENDING':
      return Object.assign({}, state, {
        ...state,
        loading: action.payload
      });
    case 'UPLOAD_GROWER_SUCCESS':
      return Object.assign({}, state, {
        ...state,
        data: [
          {
            uploadUserList: action.payload,
            completed: false
          }
        ]
      });
    default:
      return state;
  }
}

export default uploadList;

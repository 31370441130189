import {
  FETCH_SEARCH_PENDING,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_UPDATE,
  FETCH_SEARCH_ERROR,
  CLEAR_SEARCH_RESULT,
  GET_SEARCH_STATE
} from '../constants';

import { formatUrl } from '../services/commonMethods';
import { toggleSpinStatus } from '../actions/globalSpinner';
import config from '../config';
import { handleErrors } from '../services/handleErrors';

export function fetchSearchResultPending() {
  return {
    type: FETCH_SEARCH_PENDING
  };
}
export function fetchSearchResultSuccess(searchList) {
  return {
    type: FETCH_SEARCH_SUCCESS,
    payload: searchList
  };
}
export function fetchSearchResultUpdate(searchList) {
  return {
    type: FETCH_SEARCH_UPDATE,
    payload: searchList
  };
}
export function clearSearchResult() {
  return {
    type: CLEAR_SEARCH_RESULT
  };
}
export function fetchSearchResultError(error) {
  return {
    type: FETCH_SEARCH_ERROR,
    payload: error
  };
}
export function setSearchState(users) {
  return {
    type: GET_SEARCH_STATE,
    payload: users
  };
}
export const fetchSearchResult = (pagenum, users) => {
  const apiUri = formatUrl(config.advanceSearch, pagenum, '10', '-created');
  return (dispatch) => {
    dispatch(fetchSearchResultPending());
    dispatch(toggleSpinStatus());
    return fetch(apiUri, {
      method: 'POST',
      headers: {
        // Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + config.userToken,
        'Time-Zone-Offset': new Date().getTimezoneOffset()
      },
      body: JSON.stringify(users)
    })
      .then((response) => {
        return handleErrors(response, dispatch);
      })
      .then((result) => {
        let totalPagesSearch = result && result.totalPages ? result.totalPages : 0;
        let resultArr = result && result.items ? result.items : [];
        let status = result && result.message ? result.message : null;
        let growersCount = result && result.growerCount ? result.growerCount : null;
        let resultObj = {
          resultArr,
          status,
          totalPagesSearch,
          growersCount
        };
        dispatch(fetchSearchResultSuccess(resultObj));
        dispatch(fetchSearchResultUpdate(resultObj));
        dispatch(toggleSpinStatus());
      })
      .catch((error) => {
        dispatch(toggleSpinStatus());
        dispatch(fetchSearchResultError(error));
      });
  };
};
